import React, { useEffect, useState } from "react";
import styles from "./RentacarOrdertable.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import CustomRentCarOrder from "./CustomRenCarSelectOrder";
import { rentCarChangeStatus } from "shared/services/RentaCar";
import { useLocation } from "react-router-dom";
interface Order {
  _id: string;
  name: string;
  phone: string;
  cnic: string;
  vehicleModel: string;
  pickupDateTime?: string;
  dropoffDateTime?: string;
  status: string;
  type?: any;
}

interface Props {
  orders: Order[];
  onStatusChange: () => void;
  type: any;
}

function RentacarOrdertable(props: Props) {
  const [loading, setLoading] = useState(false);

  const { orders, onStatusChange, type } = props;
  const navigate = useNavigate();
  const [localOrders, setLocalOrders] = useState<Order[]>(orders);
  const handleGoToDetail = (id: string) => {
    navigate("/rentacar/orderDetails", { state: { id, type } });
  };
  useEffect(() => {
    setLocalOrders(orders);
  }, [orders]);
  const location = useLocation();
  const isOrderPage = location.pathname.includes("rentacar/Order");
  const uploadStatus = (id: string, status: string, index: number) => {
    const params = { status: status };

    rentCarChangeStatus(id, params)
      .then(() => {
        setLocalOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === id ? { ...order, status } : order
          )
        );
        onStatusChange();
      })
      .catch((err) => {
        console.error("Error:", err?.response?.data);
        setTimeout(() => {
          onStatusChange();
        }, 1000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.App)}>
      <div className={styles.payment}>
        <div className="style.tablecontainer">
          <table className={classNames(styles.tableparents)}>
            <thead>
              <tr className={styles.tableHeader}>
                <th className={styles.headerclass}>Customer Name</th>
                <th className={styles.headerclass}>Mobile Number</th>
                <th className={styles.headerclass}>Id Card No</th>
                <th className={styles.headerclass}>Vehicle Name</th>
                <th className={styles.headerclass}>Pick up Date</th>
                <th className={styles.headerclass}>Drop off Date</th>
                {isOrderPage && <th className={styles.headerclass}>Status</th>}
                <th className={styles.headerclass}>Details</th>
              </tr>
            </thead>
            <tbody className={styles.wapper}>
              {localOrders.map((val, key) => (
                <tr className={styles.tableRow} key={key}>
                  <td
                    className={styles.w20}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100px",
                    }}
                  >
                    {val.name}
                  </td>

                  <td className={styles.w20}>{val.phone}</td>
                  <td className={styles.w20}>{val.cnic}</td>
                  <td className={styles.w20}>{val.vehicleModel}</td>
                  <td className={styles.w20}>
                    {val.pickupDateTime
                      ? new Date(val.pickupDateTime).toLocaleDateString(
                          "en-US",
                          {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )
                      : "__"}
                  </td>
                  <td className={styles.w20}>
                    {val.dropoffDateTime
                      ? new Date(val.dropoffDateTime).toLocaleDateString(
                          "en-US",
                          {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )
                      : "__"}
                  </td>
                  {isOrderPage && (
                    <td
                      className={styles.w20}
                      style={{
                        width: "12%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        {
                          <CustomRentCarOrder
                            setSelectedValue={(d) =>
                              uploadStatus(val._id, d, key)
                            }
                            initialValue={val.status}
                          />
                        }
                      </div>
                    </td>
                  )}
                  <td
                    className={styles.w20}
                    onClick={() => handleGoToDetail(val._id)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Click
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RentacarOrdertable;
