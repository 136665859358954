import React, { useEffect, useRef, useState } from "react";
import style from "./detail.module.css";
import {
  CustomModal,
  GreenCard,
  InputField,
  RingLoader,
} from "shared/components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  add_hotel_branch,
  getDetailHotel,
  getHotelProperties,
  upload_multi_files,
} from "shared/services";
import { TbRefresh } from "react-icons/tb";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import TableNew from "shared/components/A_New_Components/Table_new";
import dayjs from "dayjs";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
import Picker from "assets/images/imagePPicker.png";
import NewMultiSelector from "shared/components/A_New_Components/NewMultiSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import LocationInput from "shared/components/LocationInput";
import { IoMdClose } from "react-icons/io";
import PropertiesCard from "shared/components/A_New_Components/PropertiesCard";

const HotelDetails = () => {
  const location = useLocation();
  const { id } = location?.state;
  const [data, setData] = useState<any>({});
  const [agencydata, setAgencyData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const [properties, setProperties] = useState<any>([]);
  console.log("🚀 ~ HotelDetails ~ properties:", properties);
  const HandelOpenmodel = () => {
    setOpenModel(true);
  };
  const totalItems = length;
  useEffect(() => {
    detailHandle(1);
    fetchProperties();
  }, []);
  const detailHandle = (pageno: any) => {
    setLoading(true);
    getDetailHotel(id, pageno)
      .then((res: any) => {
        setData(res?.data?.hotel);
        setLength(res?.data?.hotelBooking?.total);
        handleTableData(res?.data?.hotelBooking?.data);
      })

      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchProperties = () => {
    setLoading(true);
    getHotelProperties(id)
      .then((res: any) => {
        console.log("🚀 ~ .then ~ res:", res);
        setProperties(res?.data?.properties);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // getHotelProperties

  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data?.map((v: any, ind: any) => {
        tempData.push([
          v?.bookingId,
          v?.userId?.name,
          v?.userId?.phone,
          `${dayjs(v?.createdAt).format("DD/MM/YYYY hh:mm A")}`,
          "Booked",
          v?.type === "AppartmentInfo" ? "Apartment" : v?.type,
          v?.noOfGuest,
        ]);
      });

      setAgencyData(tempData);
    } else {
      setAgencyData([]);
    }
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.BtnWrapper}>
        <div>
          <div className={style.text}>{data?.name}</div>
          <p className={style.Property}>Properties</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <button className={style.SaveBtn} onClick={HandelOpenmodel}>
            {" "}
            Edit Hotel{" "}
          </button>
          <button
            className={style.SaveBtn}
            onClick={() =>
              navigate("/greenFlag/AddProperty", { state: { id: id } })
            }
          >
            {" "}
            + Add Property
          </button>
        </div>
      </div>
      <CustomModal showModal={openModel}>
        <UpdateHotel
          setAddHotel={setOpenModel}
          fetchList={detailHandle}
          data={data}
        />
      </CustomModal>
      <div className={style.mt24}>
        {/* <GreenCard data={data} detail /> */}
        {properties?.length > 0 ? (
          properties?.map((v: any, ind: any) => <PropertiesCard data={v} />)
        ) : (
          <PhysiotheristsEmpty />
        )}
        {loading && <CustomLoader />}
      </div>
    </div>
  );
};

const TableValue = ({
  loading,
  setData,
  fetchHotel,
  data,
  length,
  totalItems,
}: {
  loading?: any;
  setData?: any;
  fetchHotel?: any;
  data?: any;
  totalItems?: any;
  length?: any;
}) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const handleRefresh = () => {
    setData([]);
    fetchHotel(1);
    setPageno(1);
    setCurrentPage(1);
  };
  const titles = [
    "Booking ID",
    "Guest Name",
    "Contact Number",
    "Booking Date",
    "Status",
    "Type",
    "No of Guest",
  ];

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchHotel(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchHotel(currentPage - 1);
    }
  };
  return (
    <div className={style.mainHotelTable}>
      <div className={style.hotelTable}>
        <div className={style.rowBase}>
          <div className={style.lableText}>Bookings</div>
          <div className={style.loading}>
            {loading ? (
              <RingLoader color={"#0D47A1"} size={30} />
            ) : (
              <TbRefresh className={style.refresh} onClick={handleRefresh} />
            )}
          </div>
        </div>
        <NewPagination2
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          startItem={(currentPage - 1) * itemsPerPage + 1}
          endItem={Math.min(currentPage * itemsPerPage, totalItems)}
          totalItems={totalItems}
        />
      </div>
      <TableNew titles={titles} data={data} headerWidth="10%" itemWidth="10%" />
    </div>
  );
};
export default HotelDetails;

const UpdateHotel = ({ setAddHotel, fetchList, data }: any) => {
  console.log("🚀 ~ UpdateHotel ~ data:", data);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    data?.features
  );

  const feacturelist = [
    "Room Service",
    "Restaurant",
    "Internet",
    "Parking",
    "Outdoor",
    "Activities",
  ];

  const validation_Schema = {
    hotelName: Yup.string().required("Email is required"),
    UAN: Yup.string(),
    phone: Yup.string().required("Phone is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    images: Yup.array().min(1, "At least one image is required"),
    lat: Yup.string().required("lat is required"),
    lng: Yup.string().required("lng is required"),
  };
  const formik: any = useFormik({
    initialValues: {
      hotelName: data?.name,
      UAN: data?.uan,
      phone: data?.phoneNumber,
      address: data?.location?.address,
      city: data?.location?.city,
      images: [],
      lat: data?.location?.lat,
      lng: data?.location?.lng,
    },
    validationSchema: Yup.object(validation_Schema),
    onSubmit: (values) => {
      console.log("🚀 ~ AddHotel ~ values:", values);
      handleSaveHotel(values);
    },
  });

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      processFiles(Array.from(files));
    }
  };

  const processFiles = async (files: File[]) => {
    console.log("🚀 ~ processFiles ~ files:", files);
    setUploading(true);

    const data: FormData = new FormData();

    // Append each file individually to FormData
    files.forEach((file, index) => {
      data.append("files", file);
    });

    console.log("🚀 ~ processFiles ~ data:", data);

    try {
      const res = await upload_multi_files(data);

      formik.setFieldValue("images", [
        ...formik.values.images,
        ...res?.data?.fileUrls,
      ]);
    } catch (err: any) {
      console.log("🚀 ~ processFiles ~ err:", err?.response?.data);
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      processFiles(Array.from(files));
    }
  };

  const closeaddHotel = () => {
    setAddHotel(false);
  };

  const handleSaveHotel = (values: any) => {
    setAddLoading(true);
    let loc = {
      lng: values.lng,
      lat: values.lat,
      address: values.address,
      city: values.city,
    };
    let params: any = {
      propertyName: values.hotelName,
      phone: values.phone,
      uan: values.UAN,
      location: loc,
      images: values.images,
      features: selectedOptions,
    };

    add_hotel_branch(params)
      .then((res: any) => {
        fetchList();
        notifySuccess("Hotel added successfully!");
        closeaddHotel();
      })
      .catch((err: any) => {
        console.log("🚀 ~ handleSaveHotel ~ err:", err?.response?.data);
      })
      .finally(() => setAddLoading(false));
  };

  const handleLocation = (location: any) => {
    formik.setFieldValue("address", location.label);
    formik.setFieldValue("city", location.city);
    formik.setFieldValue("state", location.state);
    formik.setFieldValue("lat", location.lat);
    formik.setFieldValue("lng", location.lng);
  };
  const handleRemoveImage = (index: number) => {
    formik.setFieldValue(
      "images",
      formik.values.images.filter((_: any, i: number) => i !== index)
    );
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={style.modalouter}>
        <p className={style.textHeadingModel}>Edit Hotel</p>
        <InputField
          height={20}
          placeholder="Hotel Name"
          id="hotelName"
          value={formik.values.hotelName}
          onChange={formik?.handleChange("hotelName")}
        />
        {formik.touched.hotelName && formik.errors.hotelName && (
          <p className={style.errorText}>{formik.errors.hotelName}</p>
        )}
        <div className={style.flxBtwn}>
          {" "}
          <div style={{ width: "100%" }}>
            <InputField
              height={20}
              placeholder="UAN"
              id="UAN"
              onChange={formik?.handleChange("UAN")}
              value={formik.values.UAN}
            />
            {formik.touched.UAN && formik.errors.UAN && (
              <p className={style.errorText}>{formik.errors.UAN}</p>
            )}
          </div>{" "}
          <div style={{ width: "100%" }}>
            {" "}
            <InputField
              height={20}
              placeholder="Phone"
              value={formik.values.phone}
              id="phone"
              onChange={formik?.handleChange("phone")}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className={style.errorText}>{formik.errors.phone}</p>
            )}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <LocationInput
            type={"box"}
            placeholder={formik.values.address || "Address"}
            setData={handleLocation}
            defaultValue={formik.values.address}
          />

          {formik.touched.address && formik.errors.address && (
            <p className={style.errorText}>{formik.errors.address}</p>
          )}
        </div>
        <div className={style.flxBtwn}>
          <div style={{ width: "100%" }}>
            <InputField
              height={20}
              placeholder="City"
              value={formik.values.city}
              id="city"
              onChange={formik?.handleChange("city")}
            />
            {formik.touched.city && formik.errors.city && (
              <p className={style.errorText}>{formik.errors.city}</p>
            )}
          </div>{" "}
        </div>
        <div style={{ marginBottom: "16px" }}>
          <label>Features</label>
          <NewMultiSelector
            options={feacturelist}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
        <div>
          <div
            aria-disabled={uploading}
            className={style.imagePicker}
            onClick={handleImageClick}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            style={{ cursor: "pointer" }}
          >
            <img src={Picker} className={style.pickIMAGE} />
            <p className={style.pickerText}>
              <strong style={{ color: "#F69A1D" }}>Upload Photos</strong>
            </p>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>
        </div>
        {formik.touched.images && formik.errors.images && (
          <p className={style.errorText}>{formik.errors.images}</p>
        )}

        <div className={style.selectedImagesContainer}>
          {uploading && <RingLoader />}
          {formik?.values?.images.map((image: any, index: number) => (
            <div className={style.imageWrapper} key={index}>
              <img
                src={image}
                alt={`Selected ${index + 1}`}
                className={style.selectedImages}
              />
              <div
                className={style.outerclose}
                onClick={() => handleRemoveImage(index)}
              >
                <IoMdClose className={style.iconclose} />
              </div>
            </div>
          ))}
        </div>

        <div className={style.flxBtwn}>
          <button className={style.BackBtn} onClick={closeaddHotel}>
            Back
          </button>
          <button type="submit" className={style.SaveBtn} disabled={addLoading}>
            {addLoading ? <RingLoader color="#fff" /> : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};
