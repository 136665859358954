import React from "react";
import styles from "./style.module.css";
import cute from "assets/images/cute.png";
import cute1 from "assets/images/cute3.png";
import cute2 from "assets/images/cute2.png";
import { useNavigate } from "react-router-dom";
const AddPropertyCard = () => {
  const navigate=useNavigate()
  return (
    <div className={styles.maincontainer}>
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <p>It’s Easy to get started on</p>
        <p className={styles.brandName}>Meditour Global</p>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.step}>
          <div className={styles.stepText}>
            <p className={styles.stepText1}>1 Tell us about your place</p>
            <p className={styles.stepText2}>Share some basic info, like where it is and how many guests can stay</p>
          </div>
          <img src={cute1} className={styles.stepimage} />
        </div>
        <div className={styles.step}>
          <div className={styles.stepText}>
            <p className={styles.stepText1}>2 Make it stand out</p>
            <p className={styles.stepText2}>Add photos and a title plus description will help others</p>
          </div>
          <img src={cute2}  className={styles.stepimage} />
        </div>
        <div className={styles.step}>
          <div className={styles.stepText}>
            <p className={styles.stepText1}>3 Finish up and publish</p>
            <p className={styles.stepText2}>Choosing a starting price, verify a few details, then publish</p>
          </div>
          <img src={cute}  className={styles.stepimage} />
        </div>
      </div>
    </div>
    <button className={styles.getstartedbtn} onClick={()=>navigate("/hotel/AddProperty")}>Get Started</button>
    </div>
  );
};

export default AddPropertyCard;
