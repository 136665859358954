import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Physioappointment.module.css";
import { CustomModal, DeleteModal, RingLoader } from "shared/components";
import { MdPhone } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { IoChatboxEllipses, IoVideocam } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";

import NewPagination from "shared/components/NewPagination/NewPagination";
import ZegoCloudComponent from "shared/components/Zegocloud";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import {
  getAllAppointment,
  SendAppointmentLink,
} from "shared/services/DoctorService";
import dayjs from "dayjs";
import { setPateintData, setStartCall } from "shared/redux";
import Room from "pages/Room";
const request = ["idioe", "udiwnui", "bdiwojo"];
function DoctorAppointments() {
  const [currentPage, setCurrentPage] = useState(1);
  const [appointments, setAppointments] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [videomodel, setVideomodel] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { systemType, pateintData } = useSelector(
    (state: any) => state.root.common
  );

  const itemsPerPage = 10;
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.root.common);

  const handleVideoModal = (item: any) => {
    // setVideomodel(true);
    // dispatch(setPateintData({ name: item?.patientId?.name, id: item?._id }));
  };
  const handleSend = () => {
    // setVideomodel(false);
    // dispatch(setStartCall(true));
    // window.open("/Meeting/Room", "_blank");
  };
  const onCancel = () => {
    setVideomodel(false);
    dispatch(setPateintData({}));
  };

  // const handleSend = () => {
  //   setVideomodel(false);
  //   const patientQuery = encodeURIComponent(JSON.stringify(pateintData));
  //   const newTab = window.open(
  //     `/Meeting/Room?patientData=${patientQuery}`,
  //     "_blank"
  //   );
  //   if (newTab) {
  //     newTab.focus();
  //   }
  // };

  const handleGoAppDetail = (id: string, index: number) => {
    navigate(`/${systemType}/appointmentDetails`, { state: { id, index } });
  };

  const fetchAllAppointments = (pagenum: number) => {
    setLoading(true);
    getAllAppointment(pagenum)
      .then((res: any) => {
        setAppointments(res?.data?.Appointments);
        setTotalItems(res?.data?.totalAppoints);
      })
      .catch((err: any) => {
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchAllAppointments(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    fetchAllAppointments(currentPage - 1);
  };

  useEffect(() => {
    setLoading(true);
    fetchAllAppointments(currentPage);
  }, []);

  const handleRefresh = (): void => {
    setCurrentPage(1);
    fetchAllAppointments(1);
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      <>
        <div className={commonstyles.mr87}>
          <div className={styles.outerContainer}>
            <div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb32
                )}
              >
                <div className={commonstyles.flx}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold,
                      commonstyles.colorBlue
                    )}
                  >
                    Appointment
                  </p>
                  {loading ? (
                    <div className={commonstyles.loader}>
                      <RingLoader color={"#0D47A1"} size={30} />
                    </div>
                  ) : (
                    <TbRefresh
                      className={styles.Refresh}
                      onClick={handleRefresh}
                    />
                  )}
                </div>
                <NewPagination
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  startItem={(currentPage - 1) * itemsPerPage + 1}
                  endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                  totalItems={totalItems}
                />
              </div>
              {appointments && appointments?.length > 0 ? (
                <div className={styles.payment}>
                  <div className={styles.headerOuter}>
                    <p className={styles.headerclassStart}>Patient Name</p>
                    {/* <p className={styles.headerclass}>Patient No.</p> */}
                    <p className={styles.headerclass}>Time</p>
                    <p className={styles.headerclass}>Date</p>
                    <p className={styles.headerclass}>Type</p>
                    <p className={styles.headerclass}>Action</p>
                  </div>
                  <div className={styles.tableData}>
                    <table style={{ margin: "0%" }}>
                      <tbody className={styles.wapper}>
                        {appointments.map((apt: any, index: any) => (
                          <tr
                            className={styles.tableRow}
                            key={index}
                            onClick={() => handleGoAppDetail(apt?._id, index)}
                          >
                            <td className={styles.w20}>
                              <div className={commonstyles.flx}>
                                <Avatar
                                  className={classNames(
                                    styles.aavaatr,
                                    styles.hide
                                  )}
                                  src={apt.patientId?.userImage}
                                />
                                <p>{apt.patientId?.name}</p>
                              </div>
                            </td>
                            {/* <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                <MdPhone className={styles.iconss} />
                                <p>{apt.patientId?.phone}</p>
                              </div>
                            </td> */}
                            <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                <FaRegClock className={styles.iconss} />
                                <p>
                                  {new Date(
                                    apt.appointmentDateAndTime
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                              </div>
                            </td>
                            <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                <LuCalendarDays className={styles.iconss} />
                                <p>
                                  {dayjs(apt.appointmentDateAndTime).format(
                                    "MM-DD-YYYY"
                                  )}
                                </p>
                              </div>
                            </td>
                            <td
                              className={styles.w20}
                              style={{ textTransform: "capitalize" }}
                            >
                              {apt.appointmentType}
                            </td>
                            <td className={styles.w20}>
                              <div className={commonstyles.flxCenter}>
                                {apt?.appointmentType == "video" ? (
                                  <div
                                    className={styles.iconBox}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleVideoModal(apt);
                                    }}
                                  >
                                    <IoVideocam className={styles.Icccons} />
                                  </div>
                                ) : (
                                  <h1>--</h1>
                                )}

                                {/* <div className={styles.iconBox}>
                                  <IoChatboxEllipses
                                    className={styles.Icccons}
                                  />
                                </div> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div>{!loading && <PhysiotheristsEmpty />}</div>
              )}
            </div>
          </div>
        </div>

        <DeleteModal
          handleDelete={handleSend}
          handleCancel={onCancel}
          content={`You want to send the link to ${pateintData?.name}`}
          modalVisible={videomodel}
          confirmText={"Yes Send"}
        />
      </>
    </div>
  );
}

export default DoctorAppointments;

// export const VideoCall = () => {
//   return (
//     <div>
//       {" "}
//       <ZegoCloudComponent />{" "}
//     </div>
//   );
// };

// const handleVideoModal = (item: any) => {
//   // setVideomodel(true);
//   // dispatch(setPateintData({ name: item?.patientId?.name, id: item?._id }));
// };
// const handleSend = () => {
//   // setVideomodel(false);
//   // dispatch(setStartCall(true));
//   // window.open("/Meeting/Room", "_blank");
// };
