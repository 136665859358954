import React, { useEffect, useState } from "react";
import style from "./detail.module.css";
import { GreenCard, RingLoader } from "shared/components";
import { useLocation } from "react-router-dom";
import { agencyDetails } from "shared/services";
import { TbRefresh } from "react-icons/tb";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import TableNew from "shared/components/A_New_Components/Table_new";
import dayjs from "dayjs";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
const DetailTravelAgency = () => {
  const location = useLocation();
  const { id } = location.state;
  const [data, setData] = useState({});
  const [agencydata, setAgencyData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(0);
  const totalItems = length;
  useEffect(() => {
    detailHandle(1);
  }, []);
  const detailHandle = (pageno: any) => {
    setLoading(true);
    agencyDetails(id, pageno)
      .then((res: any) => {
        console.log(res?.data);
        setData(res?.data?.agency);
        setLength(res?.data?.agencyBooking?.total);
        handleTableData(res?.data?.agencyBooking?.data);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data?.map((v: any, ind: any) => {
        tempData.push([
          v?.bookingId,
          v?.userId?.name,
          v?.userId?.phone,
          `${dayjs(v?.createdAt).format("DD/MM/YYYY hh:mm A")}`,
          v?.status||"Booked",
          v?.requestType,
          v?.numberOfTravelers|| v?.totalUser,
        ]);
      });

      setAgencyData(tempData);
    } else {
      setAgencyData([]);
    }
  };

  
  return (
    <div className={style.mainContainer}>
      <div className={style.text}>Travel Agencies Details</div>
      <GreenCard data={data} type={"travel"} detail />
      {agencydata?.length > 0 ? (
        <TableValue
          data={agencydata}
          loading={loading}
          setData={setAgencyData}
          fetchHotel={detailHandle}
          totalItems={totalItems}
          length={length}
        />
      ) : (
        <PhysiotheristsEmpty />
      )}
      {loading &&<CustomLoader />}
    </div>
  );
};

const TableValue = ({
  loading,
  setData,
  fetchHotel,
  data,
  length,
  totalItems,
}: {
  loading?: any;
  setData?: any;
  fetchHotel?: any;
  data?: any;
  totalItems?: any;
  length?: any;
}) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const handleRefresh = () => {
    setData([]);
    fetchHotel(1);
    setPageno(1);
    setCurrentPage(1);
  };
  const titles = [
    "Booking ID",
    "Guest Name",
    "Contact Number",
    "Booking Date",
    "Status",
    "Type",
    "No of Guest",
  ];

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchHotel(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchHotel(currentPage - 1);
    }
  };
  return (
    <div className={style.mainHotelTable}>
      <div className={style.hotelTable}>
        <div className={style.rowBase}>
          <div className={style.lableText}>Bookings</div>
          <div className={style.loading}>
            {loading ? (
              <RingLoader color={"#0D47A1"} size={30} />
            ) : (
              <TbRefresh className={style.refresh} onClick={handleRefresh} />
            )}
          </div>
        </div>
        <NewPagination2
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          startItem={(currentPage - 1) * itemsPerPage + 1}
          endItem={Math.min(currentPage * itemsPerPage, totalItems)}
          totalItems={totalItems}
        />
      </div>
      <TableNew titles={titles} data={data} headerWidth="10%" itemWidth="10%" />
    </div>
  );
};
export default DetailTravelAgency;
