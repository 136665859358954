import React, { useState } from "react";
import "./styles.css";
import { Checkbox } from "@mui/material";
const NewTermCondition = ({ formik }: any) => {
  const handleCheckboxChange = (method: any) => {
    const currentMethods = formik.values.paymentMethods;
    const newMethods = currentMethods.includes(method)
      ? currentMethods.filter((m: any) => m !== method)
      : [...currentMethods, method];
    formik.setFieldValue("paymentMethods", newMethods);
  };

  const handleSelectorChange = (option: any) => {
    formik.setFieldValue("cancellationWindow", option);
  };
  const paymentArray = [
    { title: "All", value: "all" },
    { title: "Cash", value: "cash" },
    { title: "Online Payments", value: "online" },
    { title: "Credit/debit cards", value: "credit/debit" },
  ];

  return (
    <>
      <p className="Heading">Terms and conditions</p>
      <p className="subheading">AGREEMENT TO TERMS</p>

      <p className="title">1. Age Requirement</p>
      <p className="subTitle">
        The primary guest must be at least 18 years old to make a reservation.
        Age restrictions may apply for certain room types or services. Guests
        must present a valid government-issued ID or passport at check-in.
      </p>

      <p className="title">2. Reservation Confirmation:</p>
      <p className="subTitle">
        A booking is only confirmed once the guest receives a confirmation email
        or notification from the hotel or booking platform.
      </p>

      <p className="title">3. Payment Methods:</p>
      <p className="subTitle">
        Select payment methods (e.g., credit/debit cards, online payment
        gateways) should be clearly stated.
      </p>
      <div className="checkbox-group">
        {paymentArray.map(
          (method:any,index:any) => (
            <div key={index} className={"checkedbox"}>
              <Checkbox
                checked={formik.values.paymentMethods.includes(method?.value)}
                onChange={() => handleCheckboxChange(method?.value)}
                className={"radioMark"}
              />
              <p className={"textSelected"}>{method?.title}</p>
            </div>
          )
        )}
      </div>
      {formik.errors.paymentMethods && formik.touched.paymentMethods && (
        <p className="error-text">{formik.errors.paymentMethods}</p>
      )}
      <p className="title">4. Cancellation Window:</p>
      <p className="subTitle">
        Define the time frame within which cancellations are allowed without
        penalty (e.g., 24–48 hours before check-in).
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="selector-container">
          {["24hr", "36hr", "48hr"].map((option) => (
            <button
              key={option}
              className={`selector-button ${
                formik.values.cancellationWindow === option ? "selected" : ""
              }`}
              onClick={() => handleSelectorChange(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
      {formik.errors.cancellationWindow &&
        formik.touched.cancellationWindow && (
          <p className="error-text">{formik.errors.cancellationWindow}</p>
        )}

      <p className="title">5. Hotel Policies</p>
      <p className="subTitle">
        1. Clearly state if the hotel is smoke-free or if smoking is allowed in
        designated areas.
      </p>
      <p className="subTitle">
        2. Specify if pets are allowed, any restrictions, and additional
        charges.
      </p>
      <p className="subTitle">
        3. Guests will be held responsible for any damage caused to the hotel
        property during their stay.
      </p>
      <p className="subTitle">
        4. The hotel is not responsible for lost, stolen, or damaged personal
        belongings.
      </p>
      <p className="subTitle">
        5. The hotel is not liable for any injuries, illnesses, or accidents
        that occur on the premises, except in cases of gross negligence by the
        hotel.
      </p>

      <div className="important-note">
        <p className="title">Important things to know</p>
        <p className="subTitle">
          Security cameras that monitor indoor spaces are not allowed even if
          they’re turned off. All exterior security cameras must be disclosed.
          Be sure to comply with laws and review meditour{" "}
          <a href="#">anti-discrimination policy</a> and
          <a href="#"> guest and host fees</a>.
        </p>
      </div>
      {/* <p className="agreement">
        By clicking finish, you agree to meditour <a href="#">Terms of use</a> &{" "}
        <a href="#">privacy policy</a>.
      </p> */}
    </>
  );
};

export default NewTermCondition;
