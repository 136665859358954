import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack, IoClose, IoSearch } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { Radio } from "@mui/material";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { GoDot } from "react-icons/go";
import iconselct from "assets/images/icons_select.png";
import { FaAngleUp } from "react-icons/fa";
import {
  addTreatmentdoctor,
  fetchingHospital,
  fetchtreatment,
  getonlyId,
} from "shared/services";
import {
  notifyError,
  notifySuccess,
} from "shared/components/A_New_Components/ToastNotification";
import { RingLoader } from "shared/components";
import { setOpen } from "shared/redux";

function AddTreatment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state: any) => state?.root.common);
  const editdata = location.state?.treatment || {};
  const [hospitalTreatment, setHospitalTreatment] = useState<any>([]);
  const { systemType } = useSelector((state: any) => state.root.common);
  const [showOther, setShowOther] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    editdata?.hospitalId ? "hospital" : "Clinic"
  );
  const [medicince, setMedicine] = useState<any>(false);
  const [labtest, setLabTest] = useState<any>(false);
  const [other, setOther] = useState<any>("");
  const [isChecked, setIsChecked] = useState(false);
  const [chargeOption, setChargeOption] = useState("");
  const [checkHospital, setCheckHospital] = useState(false);
  const [hospitalCharge, setHospitalCharge] = useState("");
  const [acCharge, SetAcCharge] = useState("");
  const [categoryId, setCategoryId] = useState<any>("");
  const [selectedOptions, setSelectedOptions] = useState<any>("");
  const [error, setEror] = useState("");
  const [amount, settotalAmount] = useState<any>(editdata?.totalAmount);
  const [errorTreatment, setTreatmentEror] = useState<any>("");
  const [errorpackge, setPackageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [hospitalData, setHospitalData] = useState([]);
  const [hospitalid, setHospitalId] = useState<any>("");
  const [hospitalEror, setHospitalError] = useState("");
  const handleSelect = (option: any) => {
    setSelectedOption((prev) => (prev === option ? null : option));
  };
  const handleGoback = () => {
    navigate(`/${systemType}/treatments`);
  };
  useEffect(() => {
    if (selectedOption === "Clinic") {
      fetch();
    }
    fetchHospital();
  }, [selectedOption]);
  console.log(selectedOptions, ".......selectedOptionsselectedOptions");
  const fetch = () => {
    fetchtreatment()
      .then((res: any) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, ".....");
      })
      .finally(() => {});
  };

  const fetchHospital = () => {
    fetchingHospital()
      .then((res: any) => {
        setHospitalData(res?.data?.data?.hospitalIds);
      })
      .catch(() => {
        console.log(".....errrrrrr");
      })
      .finally(() => {});
  };
  useEffect(() => {
    if (hospitalid?._id) {
      fetchonlyId();
    }
  }, [hospitalid?._id]);
  const fetchonlyId = () => {
    getonlyId(hospitalid?._id)
      .then((res: any) => {
        console.log(res?.data, ".......response");
        setHospitalTreatment(res?.data?.data);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const add = () => {
    if (
      (selectedOption === "hospital" && !hospitalid?._id) ||
      editdata?.hospitalId?._id
    ) {
      setHospitalError("Please Select Hospital");
      return;
    }
    if (
      selectedOption === "hospital"
        ? !selectedOptions.treatmentId
        : !selectedOptions._id
    ) {
      setTreatmentEror("Please Select Treatment");
      return;
    }
    if (isChecked && !chargeOption) {
      setEror("Please select either Yes or No");
      return;
    }
    if (checkHospital) {
      if (!hospitalCharge) {
        setEror("Please select either Room or Ward");
        return;
      }
      if (hospitalCharge === "Room" && !acCharge) {
        setEror("Please select either AC or Non-AC");
        return;
      }
    }
    if (selectedOption === "hospital") {
      if (!checkHospital) {
        setEror("Please select hospitalization");
        return;
      }

      if (!hospitalCharge) {
        setEror("Please select either Room or Ward");
        return;
      }

      if (hospitalCharge === "Room" && !acCharge) {
        setEror("Please select either AC or Non-AC");
        return;
      }
    }
    if (showOther && !other) {
      setEror("Please enter a value in the 'Other' field.");
      return;
    }
    if (!amount) {
      setPackageError("Please add package cost.");
      return;
    }
    const ac = {
      ac: true,
    };
    const nonAc = {
      nonAc: true,
    };
    setLoading(true);
    const params = {
      categoryFound: true,
      treatmentId:
        selectedOption === "hospital"
          ? selectedOptions.treatmentId
          : selectedOptions._id,
      treatment: {
        ...(chargeOption && { appointmentCharges: chargeOption === "Yes" }),
        ...(medicince && { medicines: medicince }),
        ...(labtest && { labService: labtest }),
        ...(hospitalCharge && {
          hospitalization:
            hospitalCharge === "Room"
              ? acCharge === "AC"
                ? ac
                : nonAc
              : hospitalCharge?.toLowerCase(),
        }),
        ...(showOther && { other: other }),
      },
      categoryId:
        selectedOption === "hospital" ? categoryId?._id?._id : categoryId?._id,
      subCategory: selectedOptions?.subCategory,
      isPersonal: selectedOption === "hospital" ? false : true,
      totalAmount: amount,
      ...(selectedOption === "hospital" &&
        hospitalid?._id && { hospitalId: hospitalid?._id }),
    };
    addTreatmentdoctor(params)
      .then((res: any) => {
        notifySuccess("Treatment Add SuccessFully");
        navigate("/doctor/treatments");
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const editCase = () => {
    if (editdata?.hospitalId && !checkHospital) {
      setEror("Hospitalization must be checked for this hospital.");
      return;
    }
    if (isChecked && !chargeOption) {
      setEror("Please select either Yes or No");
      return;
    }
    if (checkHospital && !hospitalCharge) {
      setEror("Please select either Room or Ward");
      return;
    }
    if (hospitalCharge === "Room" && !acCharge) {
      setEror("Please select either AC or Non-AC");
      return;
    }
    const ac = {
      ac: true,
    };
    const nonAc = {
      nonAc: true,
    };
    setLoading(true);
    const params = {
      categoryFound: true,
      treatmentId: editdata?.treatmentId?._id,
      treatment: {
        ...(isChecked
          ? { appointmentCharges: chargeOption === "Yes" ? true : false }
          : isChecked === false
          ? null
          : editdata?.treatment?.appointmentCharges),
        ...(medicince
          ? { medicines: medicince }
          : editdata?.treatment?.medicines),
        ...(labtest
          ? { labService: labtest }
          : editdata?.treatment?.labService),
        ...(checkHospital
          ? {
              hospitalization:
                hospitalCharge === "Room"
                  ? acCharge === "AC"
                    ? ac
                    : nonAc
                  : hospitalCharge?.toLowerCase(),
            }
          : editdata?.treatment?.hospitalization),
        ...(showOther ? { other: other } : editdata.treatment.other),
      },
      categoryId: editdata?.treatmentId?.categoryId?._id,
      subCategory: editdata?.treatmentId?.subCategory,
      isPersonal: editdata?.hospitalId ? false : true,
      totalAmount: amount,
      ...(editdata?.hospitalId &&
        editdata?.hospitalId?._id && { hospitalId: editdata?.hospitalId?._id }),
    };
    addTreatmentdoctor(params)
      .then((res: any) => {
        notifySuccess("Treatment Updated SuccessFully");
        navigate("/doctor/treatments");
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAmountChange = (newAmount: any) => {
    if (newAmount) {
      settotalAmount(newAmount);
      setPackageError("");
    } else {
      settotalAmount(amount);
      setPackageError("");
    }
  };
  useEffect(() => {
    if (editdata?.treatment) {
      setMedicine(editdata.treatment.medicines);
      setLabTest(editdata.treatment.labService);
      setIsChecked(editdata?.treatment?.appointmentCharges !== undefined);
      // setIsChecked(
      //   (editdata?.treatment?.appointmentCharges && true) ||
      //     (!editdata?.treatment?.appointmentCharges && true)
      // );
      setChargeOption(editdata?.treatment?.appointmentCharges ? "Yes" : "No");
      setCheckHospital(editdata?.treatment?.hospitalization && true);
      setShowOther(!!editdata.treatment.other);
      SetAcCharge(editdata?.treatment?.hospitalization?.ac ? "AC" : "NonAC");
      setHospitalCharge(
        (editdata?.treatment?.hospitalization?.ac && "Room") ||
          (editdata?.treatment?.hospitalization?.nonAc && "Room") ||
          (editdata?.treatment?.hospitalization && "Ward")
      );
      setOther(editdata.treatment.other || "");
    }
  }, [editdata]);

  console.log(categoryId, "....setCategoryId", "....hospitalid");
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <div className={styles.backOuter}>
            <IoArrowBack className={styles.addIcon} onClick={handleGoback} />
          </div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Add Treatment Package
          </p>
        </div>
      </div>
      <div className={commonstyles.mr87}>
        <div
          className={classNames(
            commonstyles.col6,
            commonstyles.colsm12,
            commonstyles.colmd6
          )}
          style={{
            marginBottom: "36px",
          }}
        >
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold
            )}
          >
            Where would you like to treat your patient?
          </p>
          <div className={styles.buttonconatiner}>
            {!editdata?.hospitalId && (
              <button
                className={classNames(styles.Privatebtn, {
                  [styles.Selected]: selectedOption === "Clinic",
                })}
                disabled={editdata?._id ? true : false}
                onClick={() => handleSelect("Clinic")}
              >
                Clinic
              </button>
            )}
            {!editdata?.isPersonal == true && (
              <button
                className={classNames(styles.Privatebtn, {
                  [styles.Selected]: selectedOption === "hospital",
                })}
                disabled={editdata._id ? true : false}
                onClick={() => handleSelect("hospital")}
              >
                Hospital
              </button>
            )}
          </div>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold
            )}
          >
            Available Treatments
          </p>
        </div>

        <div
          className={classNames(
            commonstyles.col7,
            commonstyles.md12,
            commonstyles.colsm12
          )}
        >
          {selectedOption === "hospital" && (
            <>
              <CustomSelector
                options={hospitalData}
                type={"hospital"}
                setHospitalId={setHospitalId}
                hospitalId={hospitalid}
                setCategoryId={setCategoryId}
                setEror={setHospitalError}
                editdat={editdata?.hospitalId?.name}
              />
              {hospitalEror && (
                <div style={{ color: "red" }}>{hospitalEror}</div>
              )}
            </>
          )}

          <div
            className={styles.datePickerContainer}
            style={{
              margin: "36px 0",
            }}
          >
            <CustomSelector
              options={selectedOption === "hospital" ? hospitalTreatment : data}
              setEror={setTreatmentEror}
              selectedOptions={selectedOptions}
              selectedOption={selectedOption}
              id={hospitalid}
              setSelectedOptions={setSelectedOptions}
              setCategoryId={setCategoryId}
              setHospitalId={setHospitalId}
              editdat={editdata?.treatmentId?.subCategory}
            />
            {errorTreatment && (
              <div style={{ color: "red" }}>{errorTreatment}</div>
            )}
          </div>

          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold
            )}
            style={{ margin: "36px 0" }}
          >
            What’s included
          </p>

          <AppointmentCharges
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            chargeOption={chargeOption}
            setChargeOption={setChargeOption}
            setError={setEror}
          />
          <AppointmentCharges
            isChecked={checkHospital}
            setIsChecked={setCheckHospital}
            chargeOption={hospitalCharge}
            setChargeOption={setHospitalCharge}
            acCharge={acCharge}
            setAcCharge={SetAcCharge}
            type={"hospital"}
            setError={setEror}
          />

          <div className={styles.checkboxStyle}>
            <Checkbox
              checked={medicince}
              onChange={(e) => setMedicine(e.target.checked)}
              className={styles.radioMark}
            />
            <p className={styles.titletext}>Medicine</p>
          </div>

          <div className={styles.checkboxStyle}>
            <Checkbox
              checked={labtest}
              onChange={(e) => setLabTest(e.target.checked)}
              className={styles.radioMark}
            />
            <p className={styles.titletext}>Lab Test</p>
          </div>

          <div className={styles.checkboxStyle}>
            <Checkbox
              checked={showOther}
              onChange={(e) => setShowOther(e.target.checked)}
              className={styles.radioMark}
            />
            <p className={styles.titletext}>Others</p>
          </div>

          {showOther === true && (
            <textarea
              className={styles.input_container}
              placeholder="Enter details..."
              value={other || editdata?.treatment?.other || ""}
              onChange={(e) => {
                setOther(e.target?.value);
                setEror("");
              }}
              rows={3}
              style={{
                border: "1px solid #7D7D7D",
                resize: "none",
              }}
            />
          )}
          {error && <div style={{ color: "red" }}>{error}</div>}

          <div
            style={{
              marginTop: "24px",
            }}
          >
            <input
              type="number"
              className={styles.input_container}
              value={amount || editdata?.totalAmount}
              // onChange={(e) => {
              //   settotalAmount(e.target.value);

              // }}
              onChange={(e) => handleAmountChange(e.target.value)}
              placeholder="Rs Set package cost"
              style={{
                border: "1px solid #7D7D7D ",
              }}
            />
            {errorpackge && (
              <div style={{ color: "red", marginTop: "4px" }}>
                {errorpackge}
              </div>
            )}
            <button
              className={styles.saveClick}
              onClick={editdata?._id ? editCase : add}
              disabled={loading}
            >
              {loading ? <RingLoader /> : editdata?._id ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTreatment;

const AppointmentCharges = ({
  isChecked,
  setIsChecked,
  chargeOption,
  setChargeOption,
  type,
  acCharge,
  setAcCharge,
  setError,
}: {
  isChecked?: any;
  setIsChecked?: any;
  chargeOption?: any;
  setChargeOption?: any;
  type?: any;
  acCharge?: any;
  setAcCharge?: any;
  setError?: any;
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
        <Checkbox
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          className={styles.radioMark}
        />
        <p className={styles.titletext}>
          {type == "hospital" ? "Hospitalization" : "Appointment Charges"}
        </p>
      </div>

      {isChecked && (
        <>
          <div style={{ gap: "14px", marginLeft: "16px" }}>
            <label>
              <Radio
                name="appointmentCharge"
                value={type == "hospital" ? "Room" : "Yes"}
                checked={
                  chargeOption === (type === "hospital" ? "Room" : "Yes")
                }
                onChange={(e) => {
                  setChargeOption(e.target.value);
                  setError("");
                }}
              />
              {type == "hospital" ? "Room" : "Yes"}
            </label>
            <label>
              <Radio
                name="appointmentCharge"
                value={type == "hospital" ? "Ward" : "No"}
                checked={chargeOption === (type === "hospital" ? "Ward" : "No")}
                onChange={(e) => {
                  setChargeOption(e.target.value);
                  setError("");
                }}
              />
              {type == "hospital" ? "Ward" : "No"}
            </label>
          </div>
          {chargeOption === "Room" && (
            <div style={{ marginLeft: "16px", gap: "14px", display: "flex" }}>
              <label>
                <Radio
                  name="roomType"
                  value="AC"
                  checked={acCharge === "AC"}
                  onChange={(e) => {
                    setAcCharge(e.target.value);
                    setError("");
                  }}
                />
                AC
              </label>
              <label>
                <Radio
                  name="roomType"
                  value="NonAC"
                  checked={acCharge === "NonAC"}
                  onChange={(e) => {
                    setAcCharge(e.target.value);
                    setError("");
                  }}
                />
                Non-AC
              </label>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const CustomSelector = ({
  options,
  selectedOptions,
  setSelectedOptions,
  setCategoryId,
  setEror,
  selectedOption,
  type,
  setHospitalId,
  hospitalId,
  id,
  editdat,
}: {
  options: any;
  selectedOptions?: any;
  setSelectedOptions?: any;
  setCategoryId?: any;
  setEror?: any;
  selectedOption?: any;
  setHospitalId?: any;
  id?: any;
  type?: any;
  hospitalId?: any;
  editdat?: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});
  useEffect(() => {
    if (selectedOption === "hospital" || selectedOption === "Clinic") {
      setIsOpen(false);
      setHospitalId("");
      setSelectedOptions("");
    }
  }, [selectedOption]);
  console.log(options, "......hospitalIdhospitalId");
  const toggleDropdown = () => {
    if (editdat) return;
    if (selectedOption === "hospital" && !id?._id) {
      notifyError("Please Select Hospital");
      return;
    }
    setIsOpen((prev) => !prev);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleOptionClick = (event: React.MouseEvent, option: any) => {
    event.stopPropagation();
    setSelectedOptions(option);
    setEror("");
    setIsOpen(false);
  };
  const handleCategoryClick = (
    event: React.MouseEvent,
    index: number,
    option: any
  ) => {
    event.stopPropagation();
    if (type == "hospital") {
      setHospitalId(option?.hospitalId);
      setIsOpen(false);
      setEror("");
    } else {
      setCategoryId(option);
      setExpandedItems((prev) => ({
        [index]: !prev[index],
      }));
    }
  };
  const filteredOptions = options
    ?.map((option: any) => {
      const hospitalMatches = option?.hospitalId?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const categoryMatches =
        option?.categoryName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        option?._id?.categoryName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());
      const filteredSubcategories = option?.treatments?.filter((subtype: any) =>
        subtype?.subCategory
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
      );
      if (type === "hospital" && hospitalMatches) {
        return option;
      }
      if (
        type !== "hospital" &&
        (categoryMatches || filteredSubcategories?.length > 0)
      ) {
        return { ...option, treatments: filteredSubcategories };
      }
      return null;
    })
    .filter(Boolean);
  const title = type == "hospital" ? "Select Hospital" : "Select Treatment";
  return (
    <div
      className={`${styles.input_container} ${isOpen ? styles.active : ""} ${
        editdat ? styles.disabled : ""
      }`}
      // || (selectedOption === "hospital" && !hospitalId?._id)
      onClick={toggleDropdown}
    >
      <div className={styles.select_input}>
        <div style={{ display: "flex", alignItems: "center", gap: "0 10px" }}>
          {!selectedOptions?.subCategory && (
            <img
              src={iconselct}
              alt=""
              style={{ width: "24px", height: "24px" }}
            />
          )}
          <span className={styles.placeholder}>
            {editdat
              ? editdat
              : hospitalId?.name
              ? hospitalId?.name
              : selectedOptions?.subCategory
              ? selectedOptions?.subCategory
              : title}
          </span>
        </div>
      </div>
      <span className={styles.dropdown_icon}>
        {isOpen ? <FaAngleUp size={12} /> : <FaAngleDown size={12} />}
      </span>
      {isOpen && (
        <div className={styles.dropdown_menu}>
          <div className={styles.dropdown_header}>
            <span>{title}</span>
            <button className={styles.close_button} onClick={toggleDropdown}>
              <IoClose size={20} onClick={() => setIsOpen(false)} />
            </button>
          </div>
          <div className={styles.search_bar}>
            <IoSearch size={20} className={styles.search_icon} />
            <input
              type="text"
              placeholder="What are you looking for?"
              value={searchQuery}
              onChange={handleSearchChange}
              onClick={(e) => e.stopPropagation()}
              className={styles.search_input}
            />
          </div>
          <div className={styles.menu_items}>
            {filteredOptions?.map((option: any, index: any) => (
              <div key={index} className={styles.menu_item}>
                <div
                  className={styles.main_item}
                  onClick={(e) => handleCategoryClick(e, index, option)}
                >
                  <p
                    style={{
                      display: "flex",
                      gap: "0 10px",
                      alignItems: "center",
                    }}
                  >
                    {expandedItems[index] ? (
                      <FaAngleDown size={12} />
                    ) : (
                      type !== "hospital" && <FaAngleRight size={12} />
                    )}
                    {type === "hospital"
                      ? option?.hospitalId?.name
                      : option?._id?.categoryName
                      ? option?._id?.categoryName
                      : option?.categoryName}
                  </p>
                </div>

                {type !== "hospital" &&
                  expandedItems[index] &&
                  option?.treatments?.length > 0 && (
                    <div className={styles.subtypes}>
                      {option?.treatments?.map(
                        (subtype: any, subIndex: any) => (
                          <div
                            key={subIndex}
                            className={`${styles.subtype} ${
                              selectedOptions?.subCategory ===
                              subtype?.subCategory
                                ? styles.selected
                                : ""
                            }`}
                            onClick={(e) => handleOptionClick(e, subtype)}
                          >
                            <GoDot size={10} className={styles.dot} />
                            <span>{subtype?.subCategory}</span>
                          </div>
                        )
                      )}
                    </div>
                  )}
              </div>
            ))}
            {filteredOptions?.length === 0 && (
              <p className={styles.no_results}>
                {type === "hospital"
                  ? "No hospital found"
                  : "No Treatments found"}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
