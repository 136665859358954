import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./style.module.css";
import TableNew from "shared/components/A_New_Components/Table_new";
import { RingLoader } from "shared/components";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import { getHospitalBranches } from "shared/services";
import { useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import LoginModel from "shared/components/HospitalBranch";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

const Hospital_Branch = () => {
  const titles = [
    "Name",
    "Address",
    "Mobile",
    "Phone",
    "Email",
    // "Settings"
  ];
  const [open, setOpen] = useState(false);
  const [hospitalBranch, setHospitalBranch] = useState<any>();
  const [length, setLength] = useState(0);
  const { user } = useSelector((state: any) => state?.root?.common);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageno, setPageno] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const totalItems = length;
  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const id = user?._id;
  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data.map((v: any, ind: any) => {
        tempData.push([
          v?.name,
          v?.location?.address,
          v?.emergencyNo,
          v?.phoneNumber,
          v?.email,
          // <div className={style.dropdownWrapper} style={{ gap: "8px" }}>
          //   <IoMdSettings size={24} />
          // </div>,
        ]);
      });

      setHospitalBranch(tempData);
    } else {
      setHospitalBranch([]);
    }
  };
  useEffect(() => {
    allHospitalBranch(1, "");
  }, []);
  const allHospitalBranch = (pageno: number, searchWord: any) => {
    setLoading(true);
    getHospitalBranches(id, pageno, searchWord)
      .then((res: any) => {
        handleTableData(res?.data?.branches);
        setLength(res?.data?.totalBranches);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      allHospitalBranch(currentPage + 1, search);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      allHospitalBranch(currentPage - 1, search);
    }
  };
  const handleRefresh = () => {
    setSearch("");
    setPageno(1);
    setCurrentPage(1);
    allHospitalBranch(1, "");
  };

  const handleSearch = () => {
    setCurrentPage(1);
    allHospitalBranch(1, search);
  };
  return (
    <div className={classNames(style.container)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <p className={classNames(style.Account)}>Account Management</p>
        <div className={style.accountbtn} onClick={handleClick}>
          <p className={style.btntext}>+ Create Account</p>
        </div>
      </div>

      <div
        className={classNames(commonstyles.mt16)}
        style={{
          backgroundColor: "white",
          borderRadius: "16px ",
          margin: "10px 0",
          // width: "96%",
          padding: "24px",
          // maxWidth: "100%",
          boxSizing: "border-box",
          height: "65vh",
        }}
      >
        <div
          // className={classNames(
          //   commonstyles.flx,
          //   commonstyles.flxBetween,
          //   commonstyles.flxWrap
          // )}
          style={{
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className={classNames(commonstyles.flx, commonstyles.flxWrap)}>
            <p
              className={classNames(
                commonstyles.colorBlack,
                commonstyles.fs14,
                commonstyles.semiBold
              )}
            >
              {/* Branches  */} Account Management
            </p>
            {loading ? (
              <div style={{ marginLeft: "32px" }}>
                <RingLoader color={"#0D47A1"} size={30} />
              </div>
            ) : (
              <TbRefresh className={style.refresh} onClick={handleRefresh} />
            )}
            <div>
              <SearchFilter
                vender={false}
                search={search}
                title={"Search"}
                setSearch={setSearch}
                handleSearch={handleSearch}
              />
            </div>
          </div>

          <div>
            <NewPagination2
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
        </div>
        {hospitalBranch?.length > 0 ? (
          <TableNew
            titles={titles}
            data={hospitalBranch}
            headerWidth="20%"
            itemWidth="20%"
          />
        ) : (
          <PhysiotheristsEmpty />
        )}
      </div>

      <LoginModel
        setOpen={setOpen}
        type={"branch"}
        showModal={open}
        hitApi={allHospitalBranch}
      />
    </div>
  );
};

export default Hospital_Branch;
