import React, { useEffect, useState } from "react";
import style from "../DoctarServices/Doctor.module.css";
import { useLocation, useParams } from "react-router-dom";
import Vector from "assets/images/Vector.png";
import CardStyless from "./HospitalDeatilCards.module.css";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import CommonStyless from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import classNames from "classnames";
import Footerr from "pages/Home/HomeNavBar/Footer";
import GoogleMapComponent from "shared/components/GoogleMap/GoogleMap";
import detailStyle from "./HospitalDetail.module.css";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LoadingModal } from "shared/components";
import {
  AddRemovedFev,
  getHospital_Departments,
  getHospital_Doctors,
  getSingle_UserHospital,
} from "shared/services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setHospitalId, set_User } from "shared/redux";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import toast from "react-hot-toast";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import ContinueButton from "shared/components/ContinueButton";
import RatingStar from "shared/RatingStar";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { HOSPITAL_DETAILS } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import DoctorCard from "shared/components/A_New_Components/DoctorCard";
import Special from "../../../assets/images/treatment 3.png";
import Time from "../../../assets/images/mingcute_time-line.png";
import Services from "../../../assets/images/maki_doctor.png";
import Bed from "../../../assets/images/mdi_bed-outline.png";
import Star from "../../../assets/images/solar_star-bold.png";
import Dummy from "../../../assets/images/AvailabilityClinic.png";
import Location from "../../../assets/images/tdesign_location-filled.png";

import { FaLocationDot } from "react-icons/fa6";
import { Card } from "@mui/material";
import Doctor_Card from "shared/components/Doctor_Card";

const DoctorDetail: React.FC = () => {
  const { state } = useLocation();
  let serviceName = state.serviceName;
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [hospitalDoc, setHospitalDoc] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [indicator, setIndicator] = useState(false);
  const [cardType, setCardType] = useState("departments");
  const [visibleCards, setVisibleCards] = useState(12);
  const [doctorCards, setDoctorCards] = useState(4);
  const [showNumber, setShowNumber] = useState(false);
  const [showNumber2, setShowNumber2] = useState(false);

  const [hospitalDepartment, setHospitalDepartments] = useState<any>();

  const { user } = useSelector((state: any) => state.root.common);

  const isFavorite = user?.favourites?.some(
    (fav: any) => fav.itemId === id && fav.favModel === "hospitals"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 12);
  };
  const handleDoctor = () => {
    setDoctorCards((prevVisibleCards) => prevVisibleCards + 12);
  };
  let params = {
    hospitalId: id,
  };

  useEffect(() => {
    getHospitalDetails();
  }, []);

  const getHospitalDetails = async () => {
    setLoading(true);
    try {
      await dispatch(setHospitalId(id));
      await getSingleHospital();
      await getHospitalDepartments();
      await getHospitalDoctors();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSingleHospital = async () => {
    try {
      const res = await getSingle_UserHospital(params);
      setData(res?.data);
      getHospitalDepartments();
    } catch (error) {}
  };

  const getHospitalDepartments = async () => {
    try {
      const res = await getHospital_Departments(params);
      console.log(res?.data, "........data.....department");
      setHospitalDepartments(res?.data?.departments);
    } catch (error) {}
  };

  const getHospitalDoctors = async () => {
    try {
      const res = await getHospital_Doctors(params);
      setHospitalDoc(res?.data?.doctors);
    } catch (error) {}
  };

  const handleDepartemntClick = (doc: any) => {
    navigate(`/services/hospital/HospitalDetail/${id}/HospitalDepartments`, {
      state: { serviceName: "departmentDoc", doc },
    });
  };

  const handleViewMoreClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName, doc, hospitalId: data?.hospital?._id },
    });
  };

  const handlefavourite = () => {
    setIndicator(true);
    const params = {
      type:
        serviceName === "Hospital" ? "hospitals" : serviceName?.toLowerCase(),
      itemId: id,
    };

    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {})
      .finally(() => {
        setIndicator(false);
      });
  };

  const handleLoadMore = () => {
    setDoctorCards((prevCount) => prevCount + 4);
  };

  if (id === undefined) {
    return <div>Doctor ID is missing</div>;
  }

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <div>
        <NavBreadCrumbs {...HOSPITAL_DETAILS} />
      </div>

      <div
        className={classNames(
          detailStyle.mainconatiner,
          CommonStyless.container,
          CommonStyless.mt24
        )}
      >
        <div className={classNames(CommonStyless.mt24)}>
          <div>
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={data?.hospital?.logo}
                  alt="card img"
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div className={classNames()}>
                  <p
                    style={{ textTransform: "capitalize" }}
                    className={classNames(CardStyless.cardName)}
                  >
                    {data?.hospital?.name}
                  </p>
                  <p className={classNames(CardStyless.hopTitle)}>
                    PMC Verified
                  </p>
                </div>
                <div className={classNames(CardStyless.flxB, CardStyless.mt16)}>
                  <div
                    className={classNames(CardStyless.w75, CardStyless.flx)}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div className={CardStyless.w_45}>
                      <div className={CardStyless.cardtime}>
                        <img src={Bed} className={CardStyless.Iconns} />
                        <p className={CardStyless.texts}>32 Beds Hospital</p>
                      </div>
                      {/* <div className={CardStyless.cardtime}>
                        <img src={Star} className={CardStyless.Iconns} />
                        <p className={CardStyless.texts}>97% Rating</p>
                      </div> */}
                      <div
                        className={CardStyless.cardtime}
                        style={{ alignItems: "start" }}
                      >
                        <img src={Location} className={CardStyless.Iconns} />{" "}
                        <p className={CardStyless.texts}>
                          {data?.hospital?.location?.address}
                        </p>
                      </div>
                    </div>
                    <div className={CardStyless.w_45}>
                      <div className={CardStyless.cardtime}>
                        <img src={Special} className={CardStyless.Iconns} />
                        <p className={CardStyless.texts}>
                          {" "}
                          {data?.treatmentDoctorCount} Specialties
                        </p>
                      </div>
                      <div className={CardStyless.cardtime}>
                        <img src={Time} className={CardStyless.Iconns} />

                        <p className={CardStyless.texts}>
                          {data?.hospital?.openTime} -{" "}
                          {data?.hospital?.closeTime}
                        </p>
                      </div>{" "}
                      <div className={CardStyless.cardtime}>
                        <img src={Services} className={CardStyless.Iconns} />
                        <p className={CardStyless.texts}>
                          {data?.doctorCount} Panel Doctors
                        </p>
                      </div>
                    </div>
                  </div>
                  {!showNumber2 && (
                    <button
                      className={CardStyless.CallBtn}
                      onClick={() => setShowNumber2(!showNumber2)}
                    >
                      {" "}
                      Call Helpline
                    </button>
                  )}
                  {showNumber2 && (
                    <p className={CardStyless.Number}>+92-42-37885101-4</p>
                  )}
                </div>
                {/* <div className={CardStyless.cardFooter}>
                  <RatingStar rating={5} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            padding: "2px",
            borderRadius: "16px",
            border: "0.5px solid #13A89E",
            boxShadow: "0px 5px 8px 0px #B2B2B229",
          }}
        >
          <GoogleMapComponent
            location={{
              lat: data?.hospital?.location?.lat,
              lng: data?.hospital?.location?.lng,
            }}
          />
        </div> */}
        <div className={classNames(CommonStyless.mt24)}>
          <div>
            <p
              className={classNames(
                CommonStyless.fs24,
                CommonStyless.semiBold,
                CardStyless.mt40
              )}
            >
              Available Departments
            </p>
            {data?.treatments?.length == 0 ? <PhysiotheristsEmpty /> : null}

            <div className={CardStyless.departmentCardsContainer}>
              {data?.treatments?.length > 0 ? (
                data?.treatments
                  ?.slice(0, visibleCards)
                  .map((dep: any, index: any) => (
                    <div
                      key={index}
                      className={CardStyless.departmentCardWrapper}
                    >
                      <button className={CardStyless.departmentCards}>
                        {data?.treatments?.[index]?.length > 10
                          ? `${data?.treatments?.[index].substring(0, 15)}...`
                          : data?.treatments?.[index] || "No Name"}
                      </button>
                    </div>
                  ))
              ) : (
                <p>No treatments available</p>
              )}
            </div>

            {/* {visibleCards < departmentData.length && ( */}
            {/* {hospitalDepartment?.length > visibleCards && (
              <ContinueButton
                buttonText="View Details"
                onClick={handleShowMore}
              />
            )} */}
          </div>
        </div>

        <div className={classNames(CommonStyless.mt24)}>
          <p
            className={classNames(
              CardStyless.mt40,
              CommonStyless.fs24,
              CommonStyless.semiBold
            )}
            style={{ textTransform: "capitalize" }}
          >
            {data?.hospital?.name} Doctor’s List
          </p>
          {hospitalDoc?.length === 0 || hospitalDepartment?.length === 0 ? (
            <PhysiotheristsEmpty />
          ) : (
            <>
              <div className={CardStyless.mt40}>
                {hospitalDoc
                  ?.slice(0, doctorCards)
                  .map((hospDoc: any, index: number) => (
                    <Doctor_Card
                      key={index}
                      data={hospDoc}
                      goToDetails={() => handleViewMoreClick(hospDoc)}
                    />
                  ))}
                {/* {doctorCards < hospitalDoc?.length && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className={CardStyless.LoadBtn}
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  </div>
                )} */}
              </div>
            </>
          )}
        </div>
        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}> About {data?.hospital?.name}</p>
          <p className={CardStyless.ttext}>{data?.hospital?.description}</p>
        </div>

        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}>
            {" "}
            Top Doctors at {data?.hospital?.name}
          </p>
          <ul className={CardStyless.TopDoctorList}>
            {hospitalDoc?.length > 0 ? (
              hospitalDoc
                .slice(0, 5) // Limit to 5 doctors
                .map((doctor: { name: string }, index: number) => (
                  <li key={index} className={CardStyless.ttext}>
                    {doctor?.name || "Unknown Doctor"}
                  </li>
                ))
            ) : (
              <li className={CardStyless.ttext}>No doctors available</li>
            )}
          </ul>
        </div>

        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}>
            {" "}
            {data?.hospital?.name} Fee Structure
          </p>
          <p className={CardStyless.ttext}>
            The consultation fee of a doctor usually varies. But at{" "}
            {data?.hospital?.name}, {data?.hospital?.location?.city}, the
            consultation fees range from Rs 1,500 PKR - 5,000 PKR.
          </p>
        </div>

        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}>{data?.hospital?.name} Timing</p>
          <p className={CardStyless.ttext}>
            You can check the appointment timings and availability of the
            doctors or services by calling Meditour helpline at
            +92-42-37885101-4.
          </p>
        </div>

        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}> {data?.hospital?.name} Address</p>
          <p className={CardStyless.ttext}>
            The address of {data?.hospital?.name},{" "}
            {data?.hospital?.location?.address}
          </p>
        </div>

        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}>
            {" "}
            {data?.hospital?.name} Contact Number
          </p>
          <p className={CardStyless.ttext}>
            You can seek any information about {data?.hospital?.name},{" "}
            {data?.hospital?.location?.city}. by calling +92-42-37885101-4
          </p>
        </div>
        <div className={classNames(CardStyless.mt40, CommonStyless.fs24)}>
          <p className={CardStyless.heading}> Appointment Details</p>
          <p className={CardStyless.ttext}>
            If you want to book an appointment at {data?.hospital?.name},{" "}
            {data?.hospital?.location?.city}. with any doctor, then call
            Meditour helpline at 0300-1122334 or you can also book online.
          </p>
          <p style={{ margin: "0px" }} className={CardStyless.ttext}>
            Moreover, you can also call our helpline to know the availability of
            doctors and services or to schedule your consultation at{" "}
            {data?.hospital?.name}, {data?.hospital?.location?.city}.
          </p>
          <p style={{ margin: "0px" }} className={CardStyless.ttext}>
            Furthermore, you can also download our app on{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.meditourapp&hl=en_US&pli=1"
            >
              {" "}
              Android
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/meditour-global/id6738271103"
            >
              IOS
            </a>{" "}
            by clicking here to find more information regarding the service or
            doctor you’re searching for.
          </p>
          <p style={{ margin: "0px" }} className={CardStyless.ttext}>
            We look forward to being a partner in your journey to perfect
            health!
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "40px 0 ",
          }}
        >
          {!showNumber && (
            <button
              className={CardStyless.helpLine}
              onClick={() => setShowNumber(!showNumber)}
            >
              {" "}
              Call Helpline
            </button>
          )}
          {showNumber && (
            <p className={CardStyless.Number}>+92-42-37885101-4</p>
          )}
        </div>
      </div>

      <Footerr />
    </>
  );
};

export default DoctorDetail;

{
  /* <div className={classNames(CommonStyless.mt32, CommonStyless.mb32)}>
          <p
            className={classNames(
              CommonStyless.colorBlue,
              CommonStyless.fs24,
              CommonStyless.semiBold
            )}
          >
            Similar Hospitals
          </p>
          <div className={CardStyless.cardContainer}>
            {cardData.slice(0, visibleCards).map((card) => (
              <div key={card.id} className={CardStyless.cardWrapper}>
                <div className={CardStyless.cardImageWrapper}>
                  <img
                    src={img}
                    alt="card img"
                    className={CardStyless.cardImage}
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  >
                    <div
                      className={classNames(
                        CardStyless.cardName,
                        CommonStyless.colorBlue
                      )}
                    >
                      {card.name}
                    </div>
                    <div>
                      <img
                        src={card.vector}
                        alt="Vector icon"
                        className={CardStyless.vectorIcon}
                      />
                    </div>
                  </div>
                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoTimeOutline />
                    </span>
                    <span>{card.time}</span>
                  </div>

                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoLocationOutline />
                    </span>
                    <span>{card.location}</span>
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <span className={CardStyless.cardRating}>*****</span>
                    <span className={CardStyless.cardRatingValue}>
                      {card.rating}
                    </span>
                    <span
                      className={classNames(
                        CommonStyless.flx,
                        CardStyless.viewMore
                      )}
                      // onClick={() => handleViewMoreClick(card.id)}
                    >
                      <Link to="#" className={CardStyless.cardViewMore}>
                        View more
                      </Link>
                      <span className={CardStyless.cardArrow}>
                        <IoMdArrowForward />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */
}
