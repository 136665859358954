import React from "react";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import styles from "./payment.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";

const Payments = () => {
  const { user } = useSelector((state: any) => state.root.common);
  return (
    <div className={classNames(commonstyles.col12, styles.outer)}>
      <Vendor_Payments type={"Laboratory"} id={user?._id} />
    </div>
  );
};
export default Payments;
