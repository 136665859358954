import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { NavBar, Notifications, SideBar } from "shared/components";
import {
  Dashboard,
  Tests,
  Orders,
  Results,
  Payments,
  LaborteryProfile,
} from "pages/Laboratory";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import {
  Hospital_sidebarData,
  Lab_sidebarData,
  laboratoryMenu,
} from "shared/utils";
import Setting from "pages/Laboratory/LabSetting";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import Chat from "shared/components/Chat";
import { LabTestDetails } from "shared/services";
import TestDetail from "pages/Laboratory/Tests/TestDetails";
import OrderDetail from "pages/Laboratory/Orders/OrderDetail";
import SearchBar from "shared/components/Searchbar";
import Profile from "shared/components/Profile";
import ResultDetail from "pages/Laboratory/Results/resultDetails";
import { AddTest, Laboratory_Payments_Details } from "pages";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";
import SideBar_New from "shared/components/A_New_Components/SideBar_New";
import { useSelector } from "react-redux";
import style from "./labRoute.module.css";
import Filter from "../../assets/images/filterHos.png";
import { IoSearchOutline } from "react-icons/io5";
import Branches from "pages/Laboratory/Branches";
const LaboratoryMainRoutes = () => {
  const { systemType, user, token } = useSelector(
    (state: any) => state.root.common
  );
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/laboratory/dashboard");
  }, []);
  return (
    <div className={style.parents}>
      <div className={style.sidebarContainer}>
        <SideBar_New data={Lab_sidebarData(systemType, user)} />
      </div>
      <div className={style.tabbarCard} style={{ width: "100%" }}>
        <div className={style.tabbar}>
          <div
            style={{
              marginBottom: "16px",
              gap: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={style.welcomeText}>Welcome</div>
            <div className={style.mainText}>{user?.name}</div>
          </div>
          <div
            className={style.gap32}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <div className={style.SearchBarInput}>
              <IoSearchOutline className={style.SearchIcon} />
              <input placeholder="Search" />
              <img src={Filter} className={style.searchImg} />
            </div> */}
            <div
              className={style.gap32}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Notifications />
              <div className={style.greenTourism}>
                <img
                  src={user?.logo}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <Routes>
          <Route path="laboratory/dashboard" Component={Dashboard} />
          <Route path="laboratory/branches" Component={Branches} />
          <Route path="laboratory/test" Component={Tests} />
          <Route path="laboratory/AddTest" Component={AddTest} />
          <Route path="/testDetail/:id" Component={TestDetail} />
          <Route path="laboratory/order" Component={Orders} />
          <Route path="/laboratory/order/Detail/:id" Component={OrderDetail} />
          <Route path="laboratory/result" Component={Results} />
          <Route
            path="/laboratory/Result/Detail/:id"
            Component={ResultDetail}
          />
          <Route path="laboratory/payment" Component={Payments} />
          <Route
            path="laboratory/LaborteryProfile"
            Component={LaborteryProfile}
          />
          <Route
            path="laboratory/paymentDetails"
            Component={Laboratory_Payments_Details}
          />
          <Route path="laboratory/setting" Component={Setting} />
          <Route path="laboratory/policy" Component={PrivacyPolicy} />
          <Route path="chat/message" Component={Chat} />
          <Route path="/profile" Component={Profile} />
          <Route
            path={`/laboratory/paymentDetail`}
            element={<PaymentNavbarRoutes />}
          />
        </Routes>
      </div>{" "}
    </div>
  );
};

export default LaboratoryMainRoutes;
