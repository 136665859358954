import React, { useEffect, useState } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import CustomLoader from "shared/components/New_Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SendAppointmentLink } from "shared/services/DoctorService";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import roomStyle from "./roomStyle.module.css";
import { setStartCall } from "shared/redux";
const Room = ({
  setShowRoom,
  isScrolled,
  setScrollPosition,
  goback,
}: {
  setShowRoom?: any;
  isScrolled?: any;
  setScrollPosition?: any;
  goback?: any;
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [zpInstance, setZpInstance] = useState<any>(null);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, pateintData, systemType } = useSelector(
    (state: any) => state?.root?.common
  );
  const roomID = getUrlParams().get("roomID") || randomID(5);
  const searchParams = new URLSearchParams(location.search.replace("?", "&")); // Correct the malformed query
  const nameParam = searchParams.get("name"); // Extract the 'name' parameter
  const patientId = searchParams.get("patientId"); // Extract the 'name' parameter
  const doctorName = searchParams.get("doctorName");
  const callerName = searchParams.get("callerName");
  const pateintName = callerName ? callerName : pateintData.name;
  let handleName = doctorName ? doctorName : nameParam ? nameParam : user?.name;

  const requestMediaPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      return stream;
    } catch (error) {
      console.error("Error getting media permissions:", error);
      return null;
    }
  };

  const onLeaveRoom = () => {
    if (location.pathname === "/Meeting/Room") {
      return;
    } else {
      setShowRoom(false);
      dispatch(setStartCall(false));
    }
  };

  const myMeeting = async (element: HTMLDivElement) => {
    if (meetingStarted) return;
    const userID = randomID(5);
    const userName = randomID(5);
    const mediaStream = await requestMediaPermissions();
    if (!mediaStream) {
      alert("Microphone and Camera access is required for the call.");
      return;
    }

    generateToken("https://nextjs-token.vercel.app/api", userID).then((res) => {
      const token = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        1484647939,
        res.token,
        roomID,
        userID,
        handleName
      );

      const zp = ZegoUIKitPrebuilt.create(token);
      const personalLink =
        // window.location.origin +
        // window.location.pathname +
        "https://meditour.global/Meeting/Room?roomID=" +
        roomID +
        "&name=" +
        pateintName;

      zp.joinRoom({
        container: element,
        // showPreJoinView: false,
        showTextChat: false,
        showPreJoinView: false,
        // backgroundUrl: Plane,
        autoHideFooter: true,
        onLeaveRoom: onLeaveRoom,
        maxUsers: 2,
        sharedLinks: [
          {
            name: "Personal link",
            url: personalLink,
          },
        ],
        // branding: {
        //   logoURL: Plane,
        // },
        scenario: {
          mode: ZegoUIKitPrebuilt.VideoConference,
        },
      });

      setZpInstance(zp);
      setMeetingStarted(true);

      if (personalLink) {
        if (nameParam) {
          return;
        } else {
          handleSendLInk(personalLink);
        }
      } else {
      }
    });
  };

  const handleSendLInk = (link: any) => {
    let body = {
      appointmentLink: link,
    };
    let params = patientId ? patientId : pateintData.id;

    SendAppointmentLink(params, body)
      .then((res: any) => {
        notifySuccess(res?.data?.message);
      })
      .catch((err: any) => {})
      .finally(() => {});
  };

  useEffect(() => {
    return () => {
      if (zpInstance) {
        setLoading(true);

        zpInstance.destroy();
      }
    };
  }, [zpInstance]);

  const handleVideo = () => {
    if (location.pathname === "/Meeting/Room") {
      return;
    } else {
      setScrollPosition(0);
      setVideoStyle({
        width: "100%",
        height: "60vh",
        transition: "all 0.5s ease-in-out",
      });
    }
  };

  const [videoStyle, setVideoStyle] = useState<any>({
    width: "100%",
    height:
      location.pathname === "/Meeting/Room" || patientId ? "100vh" : "50vh",
    zIndex: 999,
    transition: "all 0.5s ease-in-out",
  });
  useEffect(() => {
    if (isScrolled > 50) {
      setVideoStyle({
        position: "absolute",
        zIndex: 999,
        top: "50%",
        left: 0,
        width: "30%",
        height: "40vh",
        transition: "all 0.5s ease-in-out",
      });
    }
  }, [isScrolled]);

  useEffect(() => {
    if (goback === true) {
      navigate(`/${systemType}/appointment`);
      setShowRoom(false);
      dispatch(setStartCall(false));
      zpInstance.destroy();
    }
  }, [goback]);

  return (
    <>
      <div
        onClick={handleVideo}
        ref={(element) => {
          if (element && !meetingStarted) {
            myMeeting(element);
          }
        }}
        style={{
          ...videoStyle,
          display: "flex",
        }}
      ></div>
      {loading && <CustomLoader />}
    </>
  );
};

export default Room;

async function generateToken(tokenServerUrl: any, userID: any) {
  const res = await fetch(
    `${tokenServerUrl}/access_token?userID=${userID}&expired_ts=7200`,
    {
      method: "GET",
    }
  );
  return await res.json();
}

function randomID(len: any) {
  let result = "";
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(
  url: string = window.location.href
): URLSearchParams {
  let urlStr = url.split("?")[1];
  return new URLSearchParams(urlStr);
}
