import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import commomstyles from "shared/utils/common.module.css";
import styles from "./ambulanceRequest.module.css";
import { LuCalendarDays, LuUser2 } from "react-icons/lu";
import {
  CustomModal,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import {
  ambulanceAcceptBookingRequest,
  ambulanceRejectBookingRequest,
  getAllBookingRequest,
} from "shared/services/Ambulance";
import { TbRefresh } from "react-icons/tb";
import { LuPhone } from "react-icons/lu";
import NewPagination from "shared/components/NewPagination/NewPagination";
import {
  setAmbulanceOnrouteRenderFlag,
  setAmbulanceRequestFlag,
  setAmbulanceRequestLength,
  setAmbulanceRequests,
} from "shared/redux";
import { FaCheckCircle } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";
import AmblanceEmpty from "shared/components/AmblanceEmpty";
import { useNavigate } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

interface Booking {
  address: string;
  ambulanceId: string;
  createdAt: string;
  customerId: string;
  dateAndTime: string;
  description: string;
  phoneNo: string;
  status: string;
  updatedAt: string;
  __v: number;
  _id: string;
}
export default function AmbulanceRequest() {
  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/ambulance/request/Detail/${id}`);
  };
  const { ambulanceRequests, ambulanceRequestFlag, ambulanceRequestLength } =
    useSelector((state: any) => state.root.ambulance);

  const [acceptmodal, setAcceptModal] = useState(false);
  const [rejectMmodal, setRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState<Booking[]>([]);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = ambulanceRequestLength;
  const dispatch = useDispatch();

  const fechAllBookingRequest = (pageno: number) => {
    setLoading(true);
    getAllBookingRequest(pageno)
      .then((res: any) => {
        dispatch(setAmbulanceRequests(res?.data?.userRequests));
        dispatch(setAmbulanceRequestLength(res?.data?.totalRequests));
        // const receivedData = res?.data?.requests || [];
        //
        // setBooking(receivedData);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // const AcceptRequest = (id: string) => {
  //   setAcceptModal(true);
  //   ambulanceAcceptBookingRequest(id)
  //     .then((res: any) => {
  //       if (res?.status === 200) {
  //         setLoading(true);
  //         dispatch(setAmbulanceRequestFlag(true));
  //         dispatch(setAmbulanceOnrouteRenderFlag(true));
  //
  //       }
  //     })
  //     .catch((err: any) => {
  //
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const RejectRequest = (rejectId: string) => {
  //   setLoading(true);
  //
  //   ambulanceRejectBookingRequest(rejectId)
  //     .then((res: any) => {
  //       if (res?.status === 200) {
  //         dispatch(setAmbulanceRequestFlag(true));
  //
  //       }
  //     })
  //     .catch((err: any) => {
  //
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setRejectId("");
  //       setRejectModal(false);
  //     });
  // };
  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    fechAllBookingRequest(1);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;
    if (ambulanceRequestLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      fechAllBookingRequest(currentPage + 1);
      setPageno(pageno + 10);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fechAllBookingRequest(currentPage - 1);
      setPageno(pageno - 10);
    }
  };
  useEffect(() => {
    setLoading(true);
    fechAllBookingRequest(1);
    dispatch(setAmbulanceRequestFlag(false));
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Request
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={styles.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                </div>
              )}
              {/* <div>
                <VideoCall />
              </div> */}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {/* <CustomModal showModal={acceptmodal}>
            <div style={{ width: "420px" }}>
              <FaCheckCircle className={styles.done} />
              <p
                className={classNames(
                  commonStyles.fs16,
                  commonStyles.semiBold,
                  commonStyles.colorBlue,
                  styles.textcenter
                )}
              >
                Request Accepted
              </p>
            </div>
          </CustomModal>
          <CustomModal showModal={rejectMmodal}>
            <div style={{ width: "300px" }}>
              <p
                className={classNames(
                  commomstyles.fs14,
                  commomstyles.semiBold,
                  commonStyles.colorBlue,
                  styles.textAlignCenter
                )}
              >
                Are you sure to Reject This Request?
              </p>
              <div className={commonStyles.flxBetween}>
                <div className={styles.btnwidth}>
                  <PrimaryButton
                    children={"Cancel"}
                    colorType={"blue"}
                    onClick={() => setRejectModal(false)}
                  />{" "}
                </div>
                <div className={styles.btnwidth}>
                  <PrimaryButton
                    children={"Reject"}
                    colorType={"Red"}
                    onClick={() => {
                      RejectRequest(rejectId);
                    }}
                  />
                </div>
              </div>
            </div>
          </CustomModal> */}
          {ambulanceRequests && ambulanceRequests.length > 0 ? (
            ambulanceRequests.map((val: any, key: any) => (
              <>
                <div
                  className={styles.card}
                  key={key}
                  onClick={() => handleGoToDetail(val?._id)}
                >
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      style={{
                        width: "15%",
                        textAlign: "center",
                      }}
                      className={commonstyles.flx}
                    >
                      <LuUser2 className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val?.userId?.name}
                      </p>
                    </div>

                    <div
                      style={{
                        width: "15%",
                        textAlign: "center",
                      }}
                      className={commonstyles.flx}
                    >
                      <LuCalendarDays className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val?.createdAt
                          ? new Date(val.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "__"}
                      </p>
                    </div>

                    <div
                      style={{
                        width: "15%",
                        textAlign: "center",
                      }}
                      className={commonstyles.flx}
                    >
                      <BsClock className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {val &&
                          val.createdAt &&
                          new Date(val.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "35%",
                        textAlign: "start",
                      }}
                    >
                      <p
                        className={classNames(commonstyles.fs16)}
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {val?.pickUp?.address}
                      </p>
                    </div>

                    <div
                      className={classNames(commonstyles.flx)}
                      style={{
                        width: "15%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.medium,
                          {
                            [styles.reject]: val.bidSent === "Bid Sent",
                            [styles.booked]: val.bidSent === "Pending",
                          }
                        )}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {val.bidSent === true ? "Bid Sent" : "Pending"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>{" "}
    </div>
  );
}
