import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import Styles from "./BasicInfo.module.css";
import "react-datepicker/dist/react-datepicker.css";
import LocationInput from "shared/components/LocationInput";
import Modal from "shared/components/ModelTermsAndCondition/Model";
import TermsAndConditions from "shared/components/ModelTermsAndCondition";
import DatepickerNew from "shared/components/DatePicker/DatePickerNew";
import * as Yup from "yup";
import { doctor_BasicInfoSchema } from "shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { setDoctorFormData } from "shared/redux";
import { getAllSpecialities } from "shared/services/DoctorService";
import {
  ImagNewPicker,
  InputField,
  PhoneNumberInputNew,
  TextInputWithDropdown,
} from "shared/components";
import { Checkbox } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { selection } from "shared/services";
interface Props {
  setCurrentStep: any;
  handleBack?: any;
  state: any;
}
const BasicInfo = (props: Props) => {
  const { setCurrentStep, state, handleBack } = props;
  const dispatch = useDispatch();
  const [isAgreed, setAgree] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const [country, setCountry] = useState<any>([]);
  const [verifybutton, setVerifyEror] = useState("");
  const [length, setLength] = useState(0);
  const [specialitiesForModal, setsetSpecialitiesForModal] = useState<any>([]);
  const { doctorFormData, systemType, user } = useSelector(
    (state: any) => state.root.common
  );
  console.log(error, "....error");
  const handleNext = (data: any) => {
    if (!isAgreed) {
      setVerifyEror("You must agree to the terms and conditions.");
      return;
    }
    dispatch(setDoctorFormData(data));
    setCurrentStep(1);
  };
  const savedFormData = doctorFormData;
  const formik: any = useFormik({
    initialValues: {
      doctorType: savedFormData?.doctorType || "",
      name: savedFormData?.name || "",
      email: state || "",
      phoneNumber: savedFormData?.phoneNumber || "",
      cnicOrPassportNo: savedFormData?.cnicOrPassportNo || "",
      cnicOrPassportExpiry: savedFormData?.cnicOrPassportExpiry || "",
      cnicImage: savedFormData?.cnicImage || "",
      qualifications: savedFormData?.qualifications || "",
      speciality: savedFormData?.speciality || [],
      clinicExperience: savedFormData?.clinicExperience || "",
      pmdcNumber: savedFormData?.pmdcNumber || "",
      gender: savedFormData?.gender || "",
      pmdcImage: savedFormData?.pmdcImage || "",
      pmdcExpiry: savedFormData?.pmdcExpiry || "",
      address: savedFormData?.address || "",
      lat: savedFormData?.lat || "",
      lng: savedFormData?.lng || "",
      city: savedFormData?.city || "",
      cnicName: savedFormData?.cnicName || "",
      nicName: savedFormData?.nicName || "",
      countrySelection: savedFormData?.countrySelection || "",
      country: savedFormData?.country || "",
    },
    validationSchema: Yup.object(doctor_BasicInfoSchema),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values: any) => {
    try {
      await handleNext(values);
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  console.log(formik?.values?.speciality, ".....speciality");
  const handleLocationChange = async (newLocation: any) => {
    const labelParts: any = newLocation?.label.split(", ");
    const country = labelParts[labelParts.length - 1];
    formik?.setFieldValue("address", newLocation?.label);
    formik?.setFieldValue("city", newLocation?.city);
    formik?.setFieldValue("country", country);
    const address = newLocation?.label;
    const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A";
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        formik?.setFieldValue("lat", location.lat);
        formik?.setFieldValue("lng", location.lng);
      } else {
        console.error("Geocoding error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data: ", error);
    }
  };
  const handleAgreementChange = () => {
    setAgree((prev) => {
      const newAgreeState = !prev;
      return newAgreeState;
    });
  };
  const cnicImageUrl = formik.values?.cnicName;
  const nicName = formik.values?.nicName;
  const handleCnicUrl = (url: any) => {
    formik.setFieldValue("cnicImage", url);
  };
  const handlePmdc = (url: any) => {
    formik.setFieldValue("pmdcImage", url);
  };
  const handleCnicName = (name: any) => {
    formik.setFieldValue("cnicName", name);
  };
  const handleNicName = (name: any) => {
    formik.setFieldValue("nicName", name);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModalTerms = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    fetchSpeciality();
    fetchSelection();
  }, []);
  const fetchSpeciality = () => {
    getAllSpecialities("", "")
      .then((res: any) => {
        const specialtyTitles = res?.data?.specialities?.map(
          (specialty: any) => specialty?.specialityTitle
        );
        setsetSpecialitiesForModal(specialtyTitles);
      })
      .catch((err: any) => {
        console.error("Error fetching specialities:", err);
      });
  };
  const fetchSelection = () => {
    selection()
      .then((res: any) => {
        console.log(res?.data, "....res data");
        setCountry(res?.data?.countries);
      })
      .catch(() => {})
      .finally(() => {});
  };
  const handleSpecialityChange = useCallback(
    (v: any) => {
      formik.setFieldValue("speciality", v);
    },
    [formik]
  );

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        console.log(position, "...position");
        const { latitude, longitude } = position.coords;
        const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A";
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        try {
          const response = await fetch(geocodeUrl);
          const data = await response.json();
          if (data.results.length > 0) {
            const address = data.results[0].formatted_address;
            const location = data.results[0].geometry.location;
            let city = "";
            for (const component of data.results[0].address_components) {
              if (component.types.includes("locality")) {
                city = component.long_name;
                break;
              }
            }
            formik?.setFieldValue("address", address);
            formik?.setFieldValue("city", city);
            formik?.setFieldValue("lat", location.lat);
            formik?.setFieldValue("lng", location.lng);
          } else {
          }
        } catch (error) {
          console.error("Geocoding API Error:", error);
        }
      });
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <InputField
            value={formik.values.name}
            id="name"
            formik={formik}
            placeholder="Full Name*"
            onChange={formik?.handleChange("name")}
            height="28px"
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.datePickerContainer}>
            <CustomSelector
              options={["Consultant", "General Physician"]}
              placeholder="Select Type*"
              onChange={(v: any) => formik.setFieldValue("doctorType", v)}
              value={formik.values.doctorType}
            />
          </div>
          {formik.touched.doctorType && formik.errors.doctorType ? (
            <div className={Styles.error}>{formik.errors.doctorType}</div>
          ) : null}
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <InputField
            id="email"
            formik={formik}
            value={formik.values.email}
            readOnly
            placeholder="Email*"
            onChange={formik?.handleChange("email")}
            height="48px"
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <PhoneNumberInputNew
            value={formik.values.phoneNumber}
            setValue={(newValue: string) => {
              formik.setFieldValue("phoneNumber", newValue);
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className={Styles.error}>{formik.errors.phoneNumber}</div>
          ) : null}
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.selectContainer}>
            <div className={Styles.datePickerContainer}>
              <CustomSelector
                options={["Male", "Female", "Confidential"]}
                placeholder="Select Gender*"
                onChange={(v: any) => {
                  formik.setFieldValue("gender", v);
                }}
                value={formik.values.gender}
              />
            </div>
            {formik.touched.gender && formik.errors.gender ? (
              <div className={Styles.error}>{formik.errors.gender}</div>
            ) : null}
          </div>
        </div>
        <div className={Styles.inputGroupBasic}>
          <InputField
            id="cnicOrPassportNo"
            formik={formik}
            placeholder="NIC / Passport"
            value={formik.values.cnicOrPassportNo}
            onChange={formik?.handleChange("cnicOrPassportNo")}
            height="48px"
          />
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.widthDatePicker}>
            <DatepickerNew
              setData={(date) =>
                formik.setFieldValue("cnicOrPassportExpiry", date)
              }
              value={formik.values.cnicOrPassportExpiry}
              placeholder="NIC / Passport Expiry "
              className={Styles.dateInput}
            />
          </div>
        </div>

        <div className={Styles.inputGroupBasic}>
          <ImagNewPicker
            placeholder="NIC / Passport Image"
            setData={handleCnicUrl}
            setName={handleNicName}
            initialValue={nicName}
          />
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <InputField
            id="qualifications"
            formik={formik}
            placeholder="Qualification*"
            value={formik.values.qualifications}
            onChange={formik?.handleChange("qualifications")}
            height="48px"
          />
        </div>

        <div className={Styles.inputGroupBasic}>
          <TextInputWithDropdown
            options={specialitiesForModal || []}
            placeholder="Add Specialities*"
            onChange={handleSpecialityChange}
            value={formik.values.speciality || []}
            // onKeyDown={(e:any) => {
            //   if (e.key === "Enter") {
            //     e.preventDefault();
            //   }
            // }}
          />
          {formik.touched.speciality && formik.errors.speciality ? (
            <div className={Styles.error}>{formik.errors.speciality}</div>
          ) : null}
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <InputField
            id="clinicExperience"
            formik={formik}
            type="number"
            value={formik.values.clinicExperience}
            placeholder="Experience in Years*"
            onChange={formik?.handleChange("clinicExperience")}
            height="48px"
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <InputField
            id="pmdcNumber"
            formik={formik}
            type="text"
            placeholder="PMDC Number"
            value={formik.values.pmdcNumber}
            onChange={formik?.handleChange("pmdcNumber")}
            height="48px"
          />
        </div>
      </div>
      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.widthDatePicker}>
            <DatepickerNew
              setData={(date) => formik.setFieldValue("pmdcExpiry", date)}
              value={formik.values.pmdcExpiry}
              placeholder="PMDC Expiry "
              className={Styles.dateInput}
            />
          </div>
        </div>
        <div className={Styles.inputGroupBasic}>
          <ImagNewPicker
            placeholder="PMDC Image"
            setData={handlePmdc}
            setName={handleCnicName}
            initialValue={cnicImageUrl}
          />
        </div>
      </div>
      <div className={Styles.rowBasic}>
        <div className={Styles.addressWidthSet}>
          <LocationInput
            placeholder={formik.values.address || "Residential Address*"}
            type={"box"}
            setData={handleLocationChange}
            defaultValue={formik.values.address}
          />
          {/* <div className={Styles.locationButton} onClick={fetchLocation}>
              📍 Get Current Location
            </div> */}
          {formik.touched.address && formik.errors.address ? (
            <div className={Styles.error}>{formik.errors.address}</div>
          ) : null}
        </div>
      </div>
      <div className={Styles.rowBasic}>
        <div className={Styles.addressWidthSet}>
          <CountrySelection
            data={country}
            countrySelection={formik?.values?.countrySelection}
            setCountrySelection={formik?.setFieldValue}
          />
          {formik.errors.countrySelection && (
            <div className={Styles.error}>{formik.errors.countrySelection}</div>
          )}
        </div>
      </div>
      <div className={Styles.agreementContainer}>
        <Checkbox
          checked={isAgreed}
          onChange={handleAgreementChange}
          className={Styles.radioMark}
        />
        <span className={Styles.agreementText}>
          This I agree to MediTour{" "}
          <span
            className={Styles.linkText}
            onClick={handleOpenModal}
            style={{ cursor: "pointer" }}
          >
            Terms & Conditions
          </span>{" "}
          {systemType === "hospital" ? null : (
            <>
              and{" "}
              <a
                className={Styles.linkText}
                href="/privactpolicys"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </>
          )}
        </span>
        {/* </label> */}
      </div>
      {verifybutton && <div className={Styles.error}>{verifybutton}</div>}
      {systemType === "hospital" ? (
        <div className={Styles.branchContainer}>
          <button
            className={Styles.backButton}
            onClick={handleBack}
            type="button"
          >
            Back
          </button>

          <button className={Styles.nextButton} type="submit">
            Next →
          </button>
        </div>
      ) : (
        <div className={Styles.buttonContainer}>
          <button className={Styles.continueButton} type="submit">
            Next →
          </button>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={handleCloseModalTerms}>
        <TermsAndConditions />
      </Modal>
    </form>
  );
};

const CountrySelection = ({
  data,
  countrySelection,
  setCountrySelection,
}: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={Styles.selectionDrop} onClick={() => setOpen(!open)}>
        <div
          className={Styles.customText}
          style={{ color: countrySelection ? "#000000" : "#7D7D7D" }}
        >
          {countrySelection || "Select Country*"}
        </div>
        <FaAngleDown color="#7d7d7d" size={16} />
      </div>
      {open && (
        <div className={Styles.dropdown}>
          {data.map((country: any) => (
            <div
              key={country}
              className={
                countrySelection === country
                  ? Styles.countrySelected
                  : Styles.countryText
              }
              onClick={() => {
                setCountrySelection("countrySelection", country);
                setOpen(false);
              }}
            >
              {country}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BasicInfo;

export const CustomSelector = ({
  options,
  placeholder,
  value = "",
  onChange,
  type,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(value);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleOptionClick = (option: any) => {
    if (options.length <= 3) {
      setSelectedOptions(option);
      onChange(option);
      setIsOpen(false);
    } else {
      const updatedSelection = selectedOptions.includes(option)
        ? selectedOptions.filter((item: any) => item !== option)
        : [...selectedOptions, option];
      setSelectedOptions(updatedSelection);
      onChange(updatedSelection);
    }
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleRemoveOption = (e: React.MouseEvent, option: any) => {
    e.stopPropagation();
    const updatedSelection = selectedOptions.filter(
      (item: any) => item !== option
    );
    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <div className={Styles.custom_selector}>
      <div
        className={`${Styles.input_container} ${isOpen ? Styles.active : ""}`}
        onClick={toggleDropdown}
      >
        <div className={Styles.select_input}>
          {selectedOptions.length > 0 ? (
            options.length <= 3 ? (
              <span className={Styles.singleSelection}>{selectedOptions}</span>
            ) : (
              <div className={Styles.truncatedSelection}>
                {selectedOptions.slice(0, 2).map((option: any, index: any) => (
                  <span key={index} className={Styles.selectedOption}>
                    <span className={Styles.optionText}>{option}</span>
                    <button
                      className={Styles.removeOption}
                      onClick={(e) => handleRemoveOption(e, option)}
                    >
                      ×
                    </button>
                  </span>
                ))}
                {selectedOptions.length > 2 && (
                  <span className={Styles.ellipsis}>
                    +{selectedOptions.length - 2} more
                  </span>
                )}
              </div>
            )
          ) : (
            <span className={Styles.placeholder}>{placeholder}</span>
          )}
        </div>

        {type == "department" ? (
          <span
            style={{
              backgroundColor: "#F2F2F2",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              position: "absolute",
              right: "10px",
            }}
          >
            <IoIosArrowDown size={32} color={isOpen ? "#FF9500" : "#CCCCCC"} />
          </span>
        ) : (
          <span className={`${Styles.dropdown_icon} ${isOpen ? "open" : ""}`}>
            ▼
          </span>
        )}
      </div>

      {isOpen && (
        <div className={Styles.options}>
          {options.map((option: any, index: any) => (
            <div
              key={index}
              className={`${Styles.option} ${
                selectedOptions.includes(option) ? Styles.selected : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <span className={Styles.optionText}>{option}</span>
              {selectedOptions.includes(option) && (
                <span className={Styles.tick}>✔</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
