import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Wrapper } from "shared/components";
import { Home, Room } from "pages";
import LaboratoryAuthRoutes from "./Laboratory/authRoutes";
import LaboratoryMainRoutes from "./Laboratory/mainRoutes";
import PharmacyAuthRoutes from "./Pharmacy/authRoutes";
import PharmacyMainRoutes from "./Pharmacy/MainRoutes";
import HomeServicesAuthRoutes from "./HomeServices";
import TravelTourismAuthRoutes from "./TravelTourism/traveltourism";
import DonationAuthRoutes from "./Donation/Donationauthroutes";
import InsuranceAuthRoutes from "./Insurance/InsuranAuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import HospitalMainRoutes from "./Hospital_Routes/hospital_main";
import AmbulanceMainRoutes from "./HomeServices/AmbulanceRoutes/AmbulanceMainRoutes";
import InsuranceMainRoutes from "./Insurance/InsuranceMianRoutes";
import DonationMainRoutes from "./Donation/DonationMainRoutes";
import RentACarMainRoutes from "./TravelTourism/RentCar/RentCarMainRoutes";
import TravelAgencyMainRoutes from "./TravelTourism/TravelAgency/TravelAgencyMain";
import Vender from "pages/Home/HomeNavBar/JoinVender";
import AdminLogin from "pages/AdminPanel/Login";
import AdminMianRoutes from "pages/AdminPanel";
import AboutUs from "pages/Home/HomeNavBar/AboutUs";
import ContactUs from "pages/Home/ContactUs";
import Treatment from "pages/Home/HomeNavBar/Treatment";
import PatientGuide from "pages/Home/HomeNavBar/PatientGuide";
import TreatmentDetails from "pages/Home/HomeNavBar/Treatment/TreatmentDetails";
import FreeOpd from "pages/Home/FreeOpd";
import PrivactPolicys from "pages/Home/HomeNavBar/PrivactPolicy";
import FAQpage from "pages/Home/HomeNavBar/FAQ'Spage";
import Doc_Auth_Routes from "./Doctor_Routes/doc_auth";
import Doc_Main_Routes from "./Doctor_Routes/doc_main";
import {
  set_User,
  setIsLoggedIn,
  setToken,
  setUser,
  setExchangeRate,
  setOpen,
  setSystemType,
  set_query,
} from "shared/redux";
import Hospital_Auth_Routes from "./Hospital_Routes/hospital_auth";
import PharmaceuticalAuthRoutes from "./Pharmaceutical/authRoutes";
import Pharmaceutical_MainRoutes from "./Pharmaceutical/MainRoutes";
import UserAuthRoutes from "./UserLogin/UserLoginAuth";
import ServiceRoutes from "./Services_Routes/ServiceRoutes";
import CenteredLayout from "pages/Home/HomeNavBar/OurServices/NewServicesCarts";
import AmbulanceLoginAuth from "./HomeServices/AmbulanceRoutes/AmbulanceLoginAuth";
import TravelAgencyLoginAuth from "./TravelTourism/TravelAgency/TravelAgencyLoginAuth";
import RentACarLoginAuth from "./TravelTourism/RentCar/RentACarLoginAuth";
import HotelLoginAuthRoute from "./TravelTourism/Hotel/HotelLoginAuthRoute";
import axios from "axios";
import CongratsScreen from "pages/CongratsScreen";
import CompanyLogin from "pages/CompanyPanel/Login";
import GreenFlagLogin from "pages/GreenFlagPanel/Login";
import MainGreenFlagPanelRoutes from "pages/GreenFlagPanel";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Questionnaire from "pages/Questionnaire";
import WhyPakistan from "pages/Home/HomeNavBar/WhyPakistan";
import HotelMainAuth from "./TravelTourism/Hotel/HotelMainAuth";
import ExploreAll from "pages/Home/HomeNavBar/ExploreAll";

const AppRoutes = (props: any) => {
  const { isLoggedIn, systemType } = useSelector(
    (state: any) => state.root.common
  );

  const { logOutFlag, setLogOutFlag } = props;
  useEffect(() => {
    // window.scrollTo(0, 0);
  });

  return (
    <Router future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
      {isLoggedIn ? (
        <>
          {systemType == "user" ? (
            <BeforLogin logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
          ) : (
            <AfterLogin logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
          )}
        </>
      ) : (
        <BeforLogin />
      )}
    </Router>
  );
};

export default AppRoutes;
const BeforLogin = (props: any) => {
  const { systemType, isLoggedIn, open } = useSelector(
    (state: any) => state.root.common
  );
  const { logOutFlag, setLogOutFlag } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fetchAndStoreExchangeRate = async () => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/437c285364c166fcafc883c0/pair/PKR/USD`
      );
      const rate = response.data.conversion_rate;
      localStorage.setItem("exchangeRate", JSON.stringify(rate));
      localStorage.setItem("lastFetched", new Date().toISOString());
      dispatch(setExchangeRate(JSON.stringify(rate)));
    } catch (err: any) {}
  };

  useEffect(() => {
    const checkAndFetchExchangeRate = () => {
      try {
        const lastFetched = localStorage.getItem("lastFetched");
        const currentDate = new Date();
        let fetchNewRate = true;

        if (lastFetched) {
          const lastFetchedDate = new Date(lastFetched);
          const hoursDifference =
            (currentDate.getTime() - lastFetchedDate.getTime()) /
            (1000 * 60 * 60);
          if (hoursDifference < 24) {
            fetchNewRate = false;
          }
        }

        if (fetchNewRate) {
          fetchAndStoreExchangeRate();
        }
      } catch (err) {}
    };

    checkAndFetchExchangeRate();
  }, []);

  useEffect(() => {
    if (logOutFlag && systemType == "user") {
      handleLogout();
    }
  }, [logOutFlag]);

  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(set_User(null));
    setLogOutFlag(false);
    navigate("/");
  };
  const [showNavBar, setShowNavBar] = useState(true);
  const location = useLocation();

  const hiddenRoutes = [
    "admin/login",
    "company/login",
    "user/*",
    "laboratory/*",
    "pharmacy/*",
    "pharmaceutical/*",
    "paramedic/*",
    "traveltourism/",
    "hotel/*",
    "travelagency/*",
    "donation/*",
    "insurance/*",
    "rentacar/*",
    "ambulance/*",
    "greentourism/login",
    "traveltourism/travelAgency/signup",
    "traveltourism/hotel/signup",
    "traveltourism/rentAcar/signup",
    "homeservices/ambulanceservices/signup",
    "traveltourism/travelAgency/forgot-password",
    "traveltourism/rentAcar/forgot-password",
    "traveltourism/hotel/forgot-password",
    "homeservices/ambulanceservices/forgot-password",
    "questionnaire",
    "congratsScreen",
    `${systemType}/signup`,
    `${systemType}/*`,
    "traveltourism/rentacar/ResetPassword",
    `traveltourism/travelAgency/ResetPassword`,
    `traveltourism/hotel/ResetPassword`,
    `${systemType}/ResetPassword`,
  ];

  const shouldHideNavBar = (path: string) => {
    return hiddenRoutes.some((route) => {
      const normalizedRoute = `/${route}`;
      const regex = new RegExp(`^${normalizedRoute.replace(/\*/g, ".*")}$`);
      return regex.test(path);
    });
  };

  useEffect(() => {
    setShowNavBar(!shouldHideNavBar(location.pathname));
  }, [location.pathname]);

  const navRef = useRef<any>(null);
  const [navHeight, setNavHeight] = useState<number>(0);
  useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef.current.offsetHeight);
    }
  }, []);
  useEffect(() => {
    const type = searchParams.get("type");
    const companyId = searchParams.get("companyId");
    const hospitalId = searchParams.get("hospitalId");
    const travelCompanyId = searchParams.get("travelCompanyId");
    const travelCompanyName = searchParams.get("travelCompanyName");
    const departmentId = searchParams.get("departmentId");
    let name: any = "";
    let query = {};
    if (travelCompanyId) {
      name = travelCompanyName;
      query = {
        travelCompanyId,
        name,
      };
    } else {
      name = companyId
        ? searchParams.get("companyName")
        : searchParams.get("hospitalName");
      query = {
        companyId,
        hospitalId,
        name,
        departmentId,
      };
    }
    if (type) {
      dispatch(setSystemType(type));
      dispatch(set_query(query));
    }
    if (!isLoggedIn && type) {
      navigate(`/${type}/login`);
      return;
    }
  }, [searchParams, isLoggedIn, systemType]);
  return (
    <Wrapper>
      {showNavBar && (
        <div
          ref={navRef}
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9999,
            width: "100%",
            margin: "0 auto",
          }}
        >
          <NavBarr />
        </div>
      )}
      <div
        style={
          showNavBar
            ? {
                position: "relative",
                top: "0px",
                marginTop: `-${navHeight}px`,
              }
            : {}
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="laboratory/*" element={<LaboratoryAuthRoutes />} />
          <Route path="pharmacy/*" element={<PharmacyAuthRoutes />} />
          <Route
            path="pharmaceutical/*"
            element={<PharmaceuticalAuthRoutes />}
          />

          {systemType === "doctor" && (
            <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
          )}
          {systemType === "physiotherapist" && (
            <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
          )}
          {systemType === "nutritionist" && (
            <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
          )}

          {systemType === "psychologist" && (
            <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
          )}
          {systemType === "hospital" && (
            <Route
              path={`${systemType}/*`}
              element={<Hospital_Auth_Routes />}
            />
          )}

          <Route path="homeservices/*" element={<HomeServicesAuthRoutes />} />
          <Route path="paramedic/*" element={<Doc_Auth_Routes />} />
          {/* <Route path="paramedic/*" element={<ParamedicStaffLoginAuth />} /> */}

          <Route path="traveltourism/*" element={<TravelTourismAuthRoutes />} />
          <Route path="hotel/*" element={<HotelLoginAuthRoute />} />
          <Route path="rentacar/*" element={<RentACarLoginAuth />} />
          <Route path="travelagency/*" element={<TravelAgencyLoginAuth />} />
          <Route path="donation/*" element={<DonationAuthRoutes />} />
          {systemType == "insurance" && (
            <Route path="insurance/*" element={<InsuranceAuthRoutes />} />
          )}
          <Route path={`Meeting/Room`} element={<Room />} />
          {/* <Route path={`Meeting/Room`} element={<Room />} /> */}
          <Route path="ambulance/*" element={<AmbulanceLoginAuth />} />
          <Route path="joinVender/" element={<Vender />} />
          <Route path="ourServices/*" element={<CenteredLayout />} />
          <Route path="services/*" element={<ServiceRoutes />} />
          <Route path="aboutUs/" element={<AboutUs />} />
          <Route path="FAQs/" element={<FAQpage />} />
          <Route path="contactUs/" element={<ContactUs />} />
          <Route path="congratsScreen/" element={<CongratsScreen />} />
          <Route path="questionnaire/" element={<Questionnaire />} />
          <Route path="user/*" element={<UserAuthRoutes />} />
          <Route path="treatment/" element={<Treatment />} />
          <Route path="privactpolicys/" element={<PrivactPolicys />} />
          <Route path="freeOPD/" element={<FreeOpd />} />
          <Route path="tourism/" element={<WhyPakistan />} />
          <Route path="ExploreAll/" element={<ExploreAll />} />

          <Route path="treatment/Details" element={<TreatmentDetails />} />
          <Route path="patientGuide/" element={<PatientGuide />} />
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="company/login" element={<CompanyLogin />} />
          <Route path="greentourism/login" element={<GreenFlagLogin />} />
        </Routes>
      </div>
    </Wrapper>
  );
};

const AfterLogin = (props: any) => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { logOutFlag, setLogOutFlag } = props;

  const [showNavBar, setShowNavBar] = useState(true);
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (logOutFlag) {
      handleLogout();
    }
  }, [logOutFlag]);

  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate(`/${systemType}/login`);
    setLogOutFlag(false);
  };

  return (
    <>
      <div>
        {systemType === "laboratory" && <LaboratoryMainRoutes />}
        {systemType === "pharmacy" && <PharmacyMainRoutes />}
        {systemType === "hospital" && <HospitalMainRoutes />}
        {systemType === "doctor" && <Doc_Main_Routes systemType={systemType} />}
        {systemType === "ambulance" && <AmbulanceMainRoutes />}

        {systemType === "physiotherapist" && (
          <Doc_Main_Routes systemType={systemType} />
        )}
        {systemType === "nutritionist" && (
          <Doc_Main_Routes systemType={systemType} />
        )}

        {/* {systemType === "paramedic" && <ParamedicStaffMainRoutes />} */}
        {systemType === "paramedic" && (
          <Doc_Main_Routes systemType={systemType} />
        )}
        {systemType === "psychologist" && (
          <Doc_Main_Routes systemType={systemType} />
        )}

        {/* {systemType === "hotel" && <HotelMainRoutes />} */}
        {systemType === "hotel" && <HotelMainAuth />}
        {systemType === "rentacar" && <RentACarMainRoutes />}
        {systemType === "travelagency" && <TravelAgencyMainRoutes />}
        {systemType === "donation" && <DonationMainRoutes />}
        {systemType === "insurance" && <InsuranceMainRoutes />}
        {systemType === "admin" && <AdminMianRoutes />}
        {systemType === "pharmaceutical" && <Pharmaceutical_MainRoutes />}
        {systemType === "company" && <HospitalMainRoutes />}
        {systemType === "greentourism" && <MainGreenFlagPanelRoutes />}
      </div>
    </>
  );
};
