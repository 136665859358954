import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import Styles from "./styles.module.css";
import { MdDeleteForever } from "react-icons/md";

export const Medicine_Table = (props: any) => {
  const { data, type, handleDelete } = props;

  // Define table headers and row rendering dynamically based on type
  const headers =
    type === "Bid"
      ? ["Name", "Dosage", "Instruction", "Frequency", "Quantity", "Route", "Availability"]
      : ["Name", "Strength", "Items", "Amount"];

  const renderRow = (val: any, rowIndex: number) => {
    if (type === "Bid") {
      let value = val?.id ? val?.id : val;
      return (
        <tr className={Styles.tableRow} key={rowIndex}>
          <td className={Styles.w20}>{value?.medicineName}</td>
          <td className={Styles.w20}>{value?.dosage}</td>
          <td className={Styles.w20}>{value?.instruction}</td>
          <td className={Styles.w20}>{value?.frequency}</td>
          <td className={Styles.w20}>{value?.quantity}</td>
          <td className={Styles.w20}>{value?.route}</td>
          <td className={Styles.w20}>
            <MdDeleteForever
              className={Styles.deleteIcon}
              onClick={() => handleDelete(rowIndex)}
            />
          </td>
        </tr>
      );
    } else {
      return (
        <tr className={Styles.tableRow} key={rowIndex}>
          <td className={Styles.w20}>{val?.id?.productName}</td>
          <td className={Styles.w20}>{val?.id?.strength}</td>
          <td className={Styles.w20}>{val?.quantity}</td>
          <td className={Styles.w20}>{val?.id?.tpPrice}</td>

        </tr>
      );
    }
  };

  return (
    <div className={Styles.payment}>
      <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
        {headers.map((header, index) => (
          <p key={index} className={Styles.headerclass}>
            {header}
          </p>
        ))}
      </div>
      <div className={Styles.tableData}>
        <table
          style={{
            margin: "0px",
            borderCollapse: "separate",
            borderSpacing: "0 4px",
            tableLayout: "fixed",
          }}
        >
          <tbody className={Styles.wapper}>
            {data?.map((val: any, rowIndex: number) => renderRow(val, rowIndex))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Medicine_Table;
