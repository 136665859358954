import React, { useState, useEffect, memo } from "react";
import LabDash from "../Dashboard/dashborad.module.css";
import commonstyle from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import style from "./dashborad.module.css";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "shared/components/Searchbar";
import { FaRegUser } from "react-icons/fa";
import LabPieChart from "shared/components/LabCharts/LabPieChart";
import LabLineChart from "shared/components/LabCharts/LabLineChart";
import {
  LabGRAPH,
  LabGetNotifications,
  LabGraphDETAILSUpperPortion,
} from "shared/services";
import { FaArrowDown, FaArrowRightLong } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import { FaArrowUp } from "react-icons/fa6";
import { LoadingModal, RingLoader } from "shared/components";
import {
  setLabGraphDetails,
  setLabNotication,
  setLabdayArray,
  setLabdayArray2,
  setrenderLabdashboardFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import CustomLoader from "shared/components/New_Loader/Loader";
import Branches from "assets/images/lab_Branches.png";
import Test from "assets/images/lab_Test.png";
import Order from "assets/images/lab_Order.png";
import Result from "assets/images/lab_Result.png";
import Payment from "assets/images/lab_Payment.png";
import { useNavigate } from "react-router-dom";

const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  // Add more data as needed
];
function Dashboard() {
  const { lab, dayArray, dayArray2, labGraphDetails } = useSelector(
    (state: any) => state.root.lab
  );
  const { user } = useSelector((state: any) => state.root.common);
  const lab_ID = user?._id;
  const dispatch = useDispatch();
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [loading, setLoading] = useState(false);
  const [carddata, setCardData] = useState<any>({});

  dayArray.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value1 = item.ordersCount;
    }
  );
  dayArray2.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value2 = item.ordersCount;
    }
  );
  const GraphDetails = () => {
    setLoading(true);
    LabGraphDETAILSUpperPortion()
      .then((res: any) => {
        if (res?.data) {
          let data = res?.data;
          console.log("🚀 ~ .then ~ data:", data);
          setCardData(data);

          dispatch(
            setLabGraphDetails({
              comOrdersPercentageChange: res.data.comOrdersPercentageChange,
              completeTodayOrdersCount: res.data.completeTodayOrdersCount,
              newOrdersPercentageChange: res.data.newOrdersPercentageChange,
              pendingPercentageChange: res.data.pendingPercentageChange,
              pendingYesOrdersCount: res.data.pendingYesOrdersCount,
              todayOrdersCount: res.data.todayOrdersCount,
              testCounts: res.data.testCounts,
            })
          );
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  interface WeekDataItem {
    date: string;
    ordersCount: number;
  }
  const GraphOnly = () => {
    setLoading(true);

    LabGRAPH()
      .then((res: any) => {
        if (res?.status === 200) {
          const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
          const previousWeekData = res.data.previousWeekData;
          const dayArray = currentWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          let popped = dayArray.pop();
          dispatch(setLabdayArray(dayArray));

          const dayArray2 = previousWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          dispatch(setLabdayArray2(dayArray2));
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  //

  const Notification = () => {
    setLoading(true);
    LabGetNotifications(lab_ID)
      .then((res: any) => {
        dispatch(setLabNotication(res.data.notifications));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });

    //
  };

  useEffect(() => {
    GraphOnly();
    GraphDetails();
    Notification();
  }, []);

  // Handler for manual refresh (optional)
  const handleRotate = () => {
    setRotation((prevRotation) => prevRotation - rotationIncrement);
    GraphOnly();
    GraphDetails();
    Notification();
  };
  const navigate = useNavigate();
  const handelgotobranches = () => {
    navigate("/laboratory/branches");
  };

  const handelgotoTest = () => {
    navigate("/laboratory/test");
  };

  const handelgotoOrders = () => {
    navigate("/laboratory/order");
  };

  const handelgotoResults = () => {
    navigate("/laboratory/result");
  };

  const handelgotoPayments = () => {
    navigate("/laboratory/payment");
  };
  return (
    <>
      <div className={LabDash.conatiner}>
        <div className={LabDash.Card_Wrapper}>
          <div className={LabDash.Dash_Card} onClick={handelgotobranches}>
            <div style={{ gap: "6px" }} className={LabDash.flx}>
              <img src={Branches} className={LabDash.Card_Icon} />
              <div>
                <p className={LabDash.Card_Values}>
                  {" "}
                  {carddata?.labCount ? carddata.labCount : 0}
                </p>{" "}
                <p className={LabDash.Card_Label}>Branches</p>
              </div>
            </div>
            <div className={LabDash.fleEnd}>
              <p className={LabDash.Card_Detail}>Details</p>
              <FaArrowRightLong className={LabDash.Arrow} />
            </div>
          </div>
          <div className={LabDash.Dash_Card} onClick={handelgotoTest}>
            <div style={{ gap: "6px" }} className={LabDash.flx}>
              <img src={Branches} className={LabDash.Card_Icon} />
              <div>
                <p className={LabDash.Card_Values}>
                  {" "}
                  {carddata?.testCounts ? carddata?.testCounts : 0}
                </p>{" "}
                <p className={LabDash.Card_Label}>Total Tests</p>
              </div>
            </div>
            <div className={LabDash.fleEnd}>
              <p className={LabDash.Card_Detail}>Details</p>
              <FaArrowRightLong className={LabDash.Arrow} />
            </div>
          </div>
          <div className={LabDash.Dash_Card} onClick={handelgotoOrders}>
            <div style={{ gap: "6px" }} className={LabDash.flx}>
              <img src={Branches} className={LabDash.Card_Icon} />
              <div>
                <p className={LabDash.Card_Values}>
                  {carddata?.todayOrdersCount ? carddata?.todayOrdersCount : 0}
                </p>{" "}
                <p className={LabDash.Card_Label}>Orders</p>
              </div>
            </div>
            <div className={LabDash.fleEnd}>
              <p className={LabDash.Card_Detail}>Details</p>
              <FaArrowRightLong className={LabDash.Arrow} />
            </div>
          </div>
          <div className={LabDash.Dash_Card} onClick={handelgotoResults}>
            <div style={{ gap: "6px" }} className={LabDash.flx}>
              <img src={Branches} className={LabDash.Card_Icon} />
              <div>
                <p className={LabDash.Card_Values}>
                  {carddata?.resultCount ? carddata?.resultCount : 0}
                </p>{" "}
                <p className={LabDash.Card_Label}>Results</p>
              </div>
            </div>
            <div className={LabDash.fleEnd}>
              <p className={LabDash.Card_Detail}>Details</p>
              <FaArrowRightLong className={LabDash.Arrow} />
            </div>
          </div>
          <div className={LabDash.Dash_Card} onClick={handelgotoPayments}>
            <div style={{ gap: "6px" }} className={LabDash.flx}>
              <img src={Branches} className={LabDash.Card_Icon} />
              <div>
                <p className={LabDash.Card_Values}>
                  {" "}
                  {carddata?.totalPayableAmount
                    ? carddata?.totalPayableAmount
                    : 0}
                </p>{" "}
                <p className={LabDash.Card_Label}>Payments</p>
              </div>
            </div>
            <div className={LabDash.fleEnd}>
              <p className={LabDash.Card_Detail}>Details</p>
              <FaArrowRightLong className={LabDash.Arrow} />
            </div>
          </div>
        </div>
        <div className={LabDash.graph}>
          <LabLineChart data={data} />
        </div>
      </div>
    </>
  );
}

export default memo(Dashboard);
