import DoctorAppoinmentPay from "pages/Services/DoctarServices/DoctorAppoinment";
import DoctorDetails from "pages/Services/DoctarServices/DoctorDetails";
import Nav_SelectionCards from "pages/Services/Nav_SelectionCard/Nav_SelectionCard";
import { Routes, Route } from "react-router-dom";
import Doctorbg from "assets/images/Group4567890098.png";
import DoctorCover from "assets/images/etactics-inc-2qiXVelOgyw-unsplash copy 1.png";
import DoctorIcon from "assets/images/DoctorIconselection.png";

import Nursesbg from "assets/images/Group 1597883599 (1).png";
import NursesCover from "assets/images/NursesCoverd.png";
import NursesIcon from "assets/images/NursesIvonss.png";

import Physiobg from "assets/images/PhysioBGG.png";
import PhysioCover from "assets/images/PhysioCOverd.png";
import PhysioIcon from "assets/images/PhysioIconss.png";

import Psychobg from "assets/images/PsychoBBG.png";
import PsychoCover from "assets/images/PsychoCOverd.png";
import PsychoIcon from "assets/images/PsychoIcoons.png";

import Nutritbg from "assets/images/NutritBGG.png";
import NutritCover from "assets/images/NutritCover.png";
import NutritIcon from "assets/images/NutritIcon.png";

import Ambulancebg from "assets/images/AmbulanceBGG.png";
import AmbulanceCover from "assets/images/AmbulanceCoverrd.png";
import AmbulanceIcon from "assets/images/AmbulanceIcons.png";
const HomeService_Cards = [
  {
    title: "Doctor",
    content:
      "Meditour stands out as a premier online doctor platform, connecting patients with highly qualified and experienced physicians across various specialties.",
    color: "#13a89e",
    icon: DoctorIcon,
    infoBg: Doctorbg,
    coverImg: DoctorCover,
    top: "-140px",
  },
  {
    title: "Nurses",
    content:
      "Connect with highly skilled and compassionate nurses for quality healthcare support. Meditour platform links you with experienced professionals for home care, hospital assistance, and specialized nursing services. Reliable, professional, and dedicated to your well-being!",
    color: "#E5A200",

    top: "-95px",
    BgColor: "#FCF2DA",
    coverImg: NursesCover,
    icon: NursesIcon,
    infoBg: Nursesbg,
  },
  {
    title: "Physiotherapist",
    content:
      "Connect with experienced and skilled physiotherapists for personalized rehabilitation and pain management. Meditour platform links you with professionals specializing in mobility, recovery, and overall well-being.",
    color: "#A2968E",
    top: "-95px",
    BgColor: "#EFE8DE",
    coverImg: PhysioCover,
    icon: PhysioIcon,
    infoBg: Physiobg,
  },

  {
    title: "Psychologist",
    content:
      "Connect with qualified and compassionate psychologists for expert mental health support. Meditour platform links you with professionals specializing in therapy, counseling, and personal growth.",
    color: "#DE987C",
    top: "-77px",
    BgColor: "#F9E8E1",
    coverImg: PsychoCover,
    icon: PsychoIcon,
    infoBg: Psychobg,
  },
  {
    title: "Nutritionist",
    content:
      "Connect with expert nutritionists for personalized diet and wellness plans. Meditour platform links you with professionals specializing in healthy eating, weight management, and overall well-being. Get the right guidance for a healthier lifestyle!",
    color: "#BCC3A0",
    top: "-95px",
    BgColor: "#F6FAE6",
    coverImg: NutritCover,
    icon: NutritIcon,
    infoBg: Nutritbg,
  },
  {
    title: "Ambulance",
    content:
      "Meditour provides fast and reliable ambulance services, ensuring emergency care when you need it most. Our network connects you with well-equipped ambulances and trained medical professionals for safe and efficient transport. Available 24/7 for your urgent medical needs!",
    color: "#FF7979",
    top: "-90px",
    BgColor: "#FDE7E7",
    coverImg: AmbulanceCover,
    icon: AmbulanceIcon,
    infoBg: Ambulancebg,
  },
];

const HomeServicesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Nav_SelectionCards data={HomeService_Cards} />}
      />
      <Route path="/DoctorDetail" element={<DoctorDetails />} />
      <Route
        path="/DoctorAppoinmentPay/:id"
        element={<DoctorAppoinmentPay />}
      />
    </Routes>
  );
};

export default HomeServicesRoutes;
