import React, { useEffect } from "react";
import classes from "./LogoSlider.module.css";

const LogoSlider = (props: any) => {
  useEffect(() => {
    const container: any = document.querySelector(`.${classes.container}`);
    const slide: any = document.querySelector(`.${classes.logosSlide}`);

    const cloneSlide = slide.cloneNode(true);
    container.appendChild(cloneSlide);
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.logosSlide}>
        {props.clientsContent?.map((client: any, i: number) => (
          <img
            key={i}
            src={client.source}
            alt={client.alt || `Client Logo ${i + 1}`}
            className={classes.logoImage}
          />
        ))}
      </div>
    </section>
  );
};

export default LogoSlider;
