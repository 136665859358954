import React, { useState, useEffect } from "react";
import { useFilePicker } from "use-file-picker";
import ImageUpload from "assets/images/upload.png";
import ImageFile from "assets/images/file.png";
import { uploadFile } from "shared/services";
import commonStyles from "shared/utils/common.module.css";
interface ImgPickerProps {
  setData?: any;
  className?: string;
  useImagePicker?: boolean;
  placeholder?: string;
  initialValue?: string;
  formik?: any;
}

export default function PropertyPhotoImgPicker({
  className,
  useImagePicker = true,
  setData,
  placeholder = "Select files",
  initialValue,
}: ImgPickerProps) {
  const [uploading, setUploading] = useState(false);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    initialValue || placeholder
  );

  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    multiple: true,
    onFilesSelected: ({ plainFiles }) => {
      if (plainFiles.length > 0) {
        setCurrentPlaceholder(plainFiles[0].name);
      }
    },
    onFilesRejected: ({ errors }) => {},
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      uploadImage(plainFiles, filesContent);
    },
  });

  const uploadImage = async (plainFiles: any, filesContent: any) => {
    setUploading(true);
    const data: any = new FormData();
    const imagedata = plainFiles[0];
    await data.append("file", imagedata);

    uploadFile(data)
      .then((res: any) => {
        if (res.status === 200 && res.statusText === "OK") {
          setData(res?.data?.fileUrl);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setUploading(false);
      });
  };

  const handlePickerClick = () => {
    openFilePicker();
  };

  const buttonImage = useImagePicker ? ImageUpload : ImageFile;

  useEffect(() => {
    if (initialValue) {
      // If there's an initial value, update the currentPlaceholder
      setCurrentPlaceholder(initialValue);
      // You might also want to set filesContent based on the initial value
      // For simplicity, let's assume it's an image URL
      // You might need to handle different types of initial values accordingly
      const initialFile = {
        name: "Initial Image",
        type: "image/png", // Assuming it's an image
        content: initialValue,
      };
      uploadImage([initialFile], [initialFile]);
    }
  }, [initialValue]);

  return (
    <div>
      <div>
        {uploading ? (
          <div>loading....</div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {filesContent.map((file, index) => (
              <div key={index} style={{ display: "flex" }}>
                {file.type && file.type.startsWith("image/") && (
                  <img alt={file.name} src={file.content}></img>
                )}
                {file.type && !file.type.startsWith("image/") && (
                  <a href={file.content} download={file.name}>
                    Download {file.name}
                  </a>
                )}
                <br />
              </div>
            ))}
            <div>
              <div style={{ width: "100%" }} className={commonStyles.end}>
                <div
                  style={{
                    color: "white",
                    backgroundColor: "#00276d",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "162px",
                    height: "36px",
                    border: "1px solid #00276d",
                    marginRight: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlePickerClick}
                >
                  <p style={{ margin: "8px 0px" }}>Browse</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
