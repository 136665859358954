import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const pharmacySignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_SIGNUP, params);
};

export const pharmacySendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_SEND_CODE_TO_EMAIL, params);
};

export const pharmacyConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_CONFIRM_EMAIL, params);
};

export const pharmacyCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.PH_COMPLETE_SIGNUP}?id=${id}`, params);
};

export const pharmacyLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_LOGIN, params);
};
export const resetLinkpharmacy = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_RESET_LINK, params);
};

export const resetPasswordpharmacy = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PH_RESET_PASSWORD}?token=${token}`,
    params
  );
};

export const getAllMedicines = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_MEDICINES}?page=${pageno}`);
};
export const getAllPharmOrders = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_ORDERS}?page=${pageno}`);
};

export const pharmacyAddMedicine = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.GET_ADD_MEDICINE, params);
};

export const pharmacyLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_LOGOUT);
};

export const PharmacyGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.PH_GRAPH);
};
export const PharmacyGraphDETAILSUpperPortion = () => {
  return HTTP_CLIENT.get(ENDPOINTS.PH_DASHBOARDDETAILS);
};

export const PharmacyMedicineDetails = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PH_GETMEDICINE_DETAILS}?medId=${id}`);
};
export const PharmacyMedicineDELETE = (id: string) => {
  return HTTP_CLIENT.delete(`${ENDPOINTS.PH_DELETE_MEDICINE}?medId=${id}`);
};
export const PharmacyMedicineEdit = (id: string, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.PH_EDIT_MEDICINE}?medId=${id}`, params);
};

export const PharmacyUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.PH_UPDATEPROFILE, params);
};

export const PharmacyChangeStatus = (id: string, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.PH_CHANGESTATUS}?id=${id}`, params);
};
export const PharmacyGetOrderDetail = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PH_GETORDERDETAILS}?medicineRequestId=${id}`);
};
export const PharmacyGetNotifications = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PH_NOTIFICATION}?id=${id}`);
};

export const PharmacyGetRequest = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.PH_GET_REQUESTS}?page=${pageno}`);
};

export const pharmacyAddBid = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.PH_ADD_BID, params);
};
