import {
  LuBadgePercent,
  LuCalendarPlus,
  LuClipboardList,
} from "react-icons/lu";
import { PiTestTube, PiUsers } from "react-icons/pi";
import styles from "../components/SideBar/sidebar.module.css";
import { BsCapsulePill } from "react-icons/bs";
import classNames from "classnames";
import { TbGripVertical } from "react-icons/tb";
import { FaUserDoctor } from "react-icons/fa6";
import { FcDepartment } from "react-icons/fc";
import * as Yup from "yup";
import { TbUsersPlus } from "react-icons/tb";
import { IoCardOutline, IoPersonCircleOutline } from "react-icons/io5";
import { AiOutlineCodeSandbox, AiOutlineUserAdd } from "react-icons/ai";
import { BsCardChecklist } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { BsCalendarCheck } from "react-icons/bs";
import { PiClipboardText } from "react-icons/pi";
import { GrHomeRounded } from "react-icons/gr";
import { GoCreditCard } from "react-icons/go";
import { BsClipboardPulse } from "react-icons/bs";
import { BsFileEarmarkText } from "react-icons/bs";
import { BsCardList } from "react-icons/bs";
import { BsCalendar2Check } from "react-icons/bs";
import { BiDonateHeart, BiHomeAlt2 } from "react-icons/bi";
import { LuStethoscope } from "react-icons/lu";
import Room from "assets/images/room.png";
import setting from "assets/images/iconSetting.png";
import Booking from "assets/images/booking.png";
import Payment from "assets/images/Paymentssss.png";
import {
  MdOutlinePrivacyTip,
  MdOutlineAirplaneTicket,
  MdOutlineFlightTakeoff,
  MdFavoriteBorder,
  MdOutlineHelpCenter,
} from "react-icons/md";
import {} from "react-icons/md";
import { BiCheckShield } from "react-icons/bi";
import { VscPersonAdd } from "react-icons/vsc";
import { FaRegCalendarAlt, FaCalendarCheck } from "react-icons/fa";
import { TbNotes, TbLogout2 } from "react-icons/tb";
import { GrNotes } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { CgCreditCard } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";

import vendor01 from "assets/images/Vendor/01 copy.webp";
import vendor02 from "assets/images/Vendor/02 copy.webp";
import vendor03 from "assets/images/Vendor/03 copy.webp";
import vendor04 from "assets/images/Vendor/04 copy.webp";
import vendor05 from "assets/images/Vendor/05 copy.webp";
import vendor06 from "assets/images/Vendor/06 copy.webp";
import vendor07 from "assets/images/Vendor/07 copy.webp";
import vendor08 from "assets/images/Vendor/08 copy.webp";
import vendor09 from "assets/images/Vendor/09 copy.webp";
import vendor10 from "assets/images/Vendor/10 copy.webp";
import vendor11 from "assets/images/Vendor/11 copy.webp";
import vendor12 from "assets/images/Vendor/12 copy.webp";
import vendor13 from "assets/images/Vendor/13 copy.webp";
import vendor14 from "assets/images/Vendor/14 copy.webp";
import vendor15 from "assets/images/Vendor/15 copy.webp";

import icon1 from "assets/images/VendorIcon/01.png";
import icon2 from "assets/images/VendorIcon/02.png";
import icon3 from "assets/images/VendorIcon/03.png";
import icon4 from "assets/images/VendorIcon/04.png";
import icon5 from "assets/images/VendorIcon/05.png";
import icon6 from "assets/images/VendorIcon/06.png";
import icon7 from "assets/images/VendorIcon/07.png";
import icon8 from "assets/images/VendorIcon/08.png";
import icon9 from "assets/images/VendorIcon/09.png";
import icon10 from "assets/images/VendorIcon/10.png";
import icon11 from "assets/images/VendorIcon/11.png";
import icon12 from "assets/images/VendorIcon/12.png";
import icon13 from "assets/images/VendorIcon/13.png";
import icon14 from "assets/images/VendorIcon/14.png";
import icon15 from "assets/images/VendorIcon/15.png";
import reserve from "assets/images/reserve.png";
import manegBranch from "assets/images/HospitalDashboard/manageBranch.png";
import manegDoctor from "assets/images/HospitalDashboard/manegDoctor.png";
import manegLab from "assets/images/HospitalDashboard/manegLab.png";
import manegPharmacy from "assets/images/HospitalDashboard/manegPharmacy.png";
import treatmentSurgery from "assets/images/HospitalDashboard/treatmentSurgery.png";
import manegpatient from "assets/images/HospitalDashboard/manegpatient.png";
import AppointmentBooking from "assets/images/HospitalDashboard/AppointmentBooking.png";
import dashboard from "assets/images/HospitalDashboard/dashboard.png";
import department from "assets/images/HospitalDashboard/department.png";

import logo1 from "assets/companyLogo/1.png";
import logo2 from "assets/companyLogo/2.png";
import logo3 from "assets/companyLogo/3.png";
import logo4 from "assets/companyLogo/4.png";
import logo5 from "assets/companyLogo/5.png";
import logo6 from "assets/companyLogo/6.png";
import logo7 from "assets/companyLogo/7.png";
import logo8 from "assets/companyLogo/8.png";
import logo9 from "assets/companyLogo/9.png";
import logo10 from "assets/companyLogo/10.png";
import logo11 from "assets/companyLogo/11.png";
import logo12 from "assets/companyLogo/12.png";
import logo13 from "assets/companyLogo/13.png";
import logo14 from "assets/companyLogo/14.png";
import logo15 from "assets/companyLogo/15.png";
import logo16 from "assets/companyLogo/16.png";
import logo17 from "assets/companyLogo/17.png";
import logo18 from "assets/companyLogo/18.png";
import logo19 from "assets/companyLogo/19.png";
import logo20 from "assets/companyLogo/20.png";

import slider1 from "assets/images/slide1 (1).png";
import slider2 from "assets/images/slide1 (2).png";
import slider3 from "assets/images/slide1 (3).png";
import slider4 from "assets/images/slide1 (4).png";
import slider6 from "assets/images/slide1 (6).png";
import slider7 from "assets/images/slide1 (7).png";
import slider8 from "assets/images/slide1 (8).png";

import slider9 from "assets/images/slide1 (9).png";

import slider10 from "assets/images/slide1 (10).png";

import { RiLogoutBoxRLine } from "react-icons/ri";
import { Settings } from "@mui/icons-material";
import { CiSettings } from "react-icons/ci";

//lab
import Test from "assets/images/test.png";
import Order from "assets/images/order.png";
import Result from "assets/images/result.png";
import Manges from "assets/images/branch.png";
import LabDash from "assets/images/labDash.png";
export const MenuData = [
  {
    id: 0,
    title: "My Appointments",
    screen: "/services/myappointment",
    icon: FaRegCalendarAlt,
  },
  {
    id: 1,
    title: "My Bookings",
    screen: "/services/booking",
    icon: FaCalendarCheck,
  },
  {
    id: 2,
    title: "My Requests",
    screen: "/services/myRequest",
    icon: TbNotes,
  },
  {
    id: 3,
    title: "Order History",
    screen: "/services/orderHistory",
    icon: GrNotes,
  },
  {
    id: 4,
    title: "My Profile",
    screen: "/services/myProfile",
    icon: CgProfile,
  },
  {
    id: 5,
    title: "My Favorites",
    screen: "/services/favorite",
    icon: MdFavoriteBorder,
  },
  {
    id: 6,
    title: "Privacy Policy",
    screen: "/privactpolicys",
    icon: MdOutlinePrivacyTip,
  },
  {
    id: 7,
    title: "Help Center",
    screen: "/contactUs",
    icon: MdOutlineHelpCenter,
  },
  {
    id: 8,
    title: "Logout",
    icon: TbLogout2,
  },
];

export const logos_data = [
  { id: 1, source: logo1, alt: "Client 1" },
  { id: 2, source: logo2, alt: "Client 2" },
  { id: 3, source: logo3, alt: "Client 3" },
  { id: 4, source: logo4, alt: "Client 4" },
  { id: 5, source: logo5, alt: "Client 5" },
  { id: 6, source: logo6, alt: "Client 6" },
  { id: 7, source: logo7, alt: "Client 7" },
  { id: 8, source: logo8, alt: "Client 8" },
  { id: 9, source: logo9, alt: "Client 9" },
  { id: 10, source: logo10, alt: "Client 10" },
  { id: 11, source: logo11, alt: "Client 11" },
  { id: 12, source: logo12, alt: "Client 12" },
  { id: 13, source: logo13, alt: "Client 13" },
  { id: 14, source: logo14, alt: "Client 14" },
  { id: 15, source: logo15, alt: "Client 15" },
  { id: 16, source: logo16, alt: "Client 16" },
  { id: 17, source: logo17, alt: "Client 17" },
  { id: 18, source: logo18, alt: "Client 18" },
  { id: 19, source: logo19, alt: "Client 19" },
  { id: 20, source: logo20, alt: "Client 20" },
];
export const TopMenuList = [
  { id: 0, title: "home", active: "/" },
  { id: 2, title: "aboutUs", active: "/aboutUs" },
  { id: 3, title: "services", active: "/ourServices" },
  { id: 4, title: "treatments", active: "/treatment" },
  { id: 5, title: "patientGuide", active: "/patientGuide" },
  { id: 6, title: "contactUs", active: "/contactUs" },
];

export const onOpenMenuData = [
  { id: 0, title: "Home", to: "/" },
  { id: 2, title: "About us", to: "/aboutUs" },
  { id: 3, title: "Services", to: "/ourServices" },
  { id: 4, title: "Treatments", to: "/treatment" },
  { id: 5, title: "Patient Guide", to: "/patientGuide" },
  { id: 6, title: "Contact us", to: "/contactUs" },
  { id: 7, title: "Join As a Vendor", to: "/joinVender" },
  { id: 8, title: "UserLogin", to: "/" },
];

export const laboratoryMenu = [
  {
    path: "laboratory/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/test",
    name: "Tests",
    icon: <PiTestTube className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/Order",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/Result",
    name: "Results",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/LaborteryProfile",
    name: "Profile",
    icon: <IoPersonCircleOutline className={classNames(styles.menuIcons)} />,
  },
  {
    path: "laboratory/Payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];

//  .....................................

export const countryData = [
  { img: slider1, country: "PAKISTAN" },
  { img: slider2, country: "TURKEY" },
  { img: slider3, country: "ENGLAND" },
  { img: slider4, country: "CAMBODIA" },
  { img: slider6, country: "USA" },
  { img: slider7, country: "CANADA" },
  { img: slider8, country: "CANADA" },
  { img: slider9, country: "CANADA" },
  { img: slider10, country: "CANADA" },
];

export const daysOfWeek: any = [
  { id: 1, day: "Sunday" },
  { id: 2, day: "Monday" },
  { id: 3, day: "Tuesday" },
  { id: 4, day: "Wednesday" },
  { id: 5, day: "Thursday" },
  { id: 6, day: "Friday" },
  { id: 7, day: "Saturday" },
];

export const pharmacyMenu = [
  {
    path: "pharmacy/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },

  {
    path: "pharmacy/Request",
    name: "Request",
    icon: <BsCapsulePill className={classNames(styles.menuIcons)} />,
  },

  {
    path: "pharmacy/order",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "pharmacy/payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
//................
export const pharmaceutical_Menu = [
  {
    path: "pharmaceutical/dashboard",
    name: "Medicines",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
];
//................
export const DoctorMenu = [
  {
    path: "doctor/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/AvailabilityCategory",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/appointment",
    name: "Appointments",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "doctor/payment",
    name: "Payments",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const HospitalMenu = [
  {
    path: "hospital/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/departments",
    name: "Departments",
    icon: <FcDepartment className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/doctors ",
    name: "Doctors",
    icon: <FaUserDoctor className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hospital/appointment ",
    name: "Appointment",
    icon: <LuClipboardList className={classNames(styles.menuIcons)} />,
  },
];

export const Lab_sidebarData = (systemType: any, user: any) => [
  {
    id: 1,
    label: "Dashboard",
    img: LabDash,
    path: `laboratory/dashboard`,
  },
  {
    id: 2,
    label: "Manage Branches",
    img: Manges,
    path: "laboratory/branches",
  },
  {
    id: 3,
    label: "Tests",
    img: Test,
    path: "/laboratory/test",
  },
  {
    id: 4,
    label: "Orders",
    img: Order,
    path: "laboratory/Order",
  },
  {
    id: 5,
    label: "Results",
    img: Result,
    path: "laboratory/Result",
  },

  {
    id: 5,
    label: "Payments",
    img: Payment,
    path: "laboratory/Payment",
  },
  // {
  //   id: 6,
  //   path: "laboratory/Setting",
  //   label: "Setting",
  //   img: setting,
  // },
];
export const Hospital_sidebarData = (systemType: any, user: any) => [
  ...(systemType === "company"
    ? []
    : [
        {
          id: 1,
          label: "Dashboard",
          path: `${systemType}/dashboard`,
          img: dashboard,
        },
        ...(user?.mainHospitalId
          ? []
          : [
              {
                id: 2,
                label: "Manage Branches",
                img: manegBranch,
                path: `${systemType}/branch`,
              },
            ]),
        {
          id: 3,
          label: "Appointment Booking",
          img: AppointmentBooking,
          path: `${systemType}/AppointmentBooking`,
        },
        {
          id: 4,
          label: "Manage Departments",
          img: department,
          path: `${systemType}/Departments`,
        },
      ]),

  {
    id: 5,
    label: "Manage Doctors",
    img: manegDoctor,
    path: `${systemType}/ManageDoctor`,
  },
  {
    id: 6,
    label: "Manage Patients",
    img: manegpatient,
    path: `${systemType}/doctoPatient`,
  },

  // {
  //   id: 11,
  //   label: "Payment detail",
  //   img: manegPharmacy,
  //   path: `${systemType}/PaymentDetails`,
  // },
  ...(systemType === "company"
    ? []
    : [
        {
          id: 7,
          label: "Treatments or Surgery",
          img: treatmentSurgery,
          path: `${systemType}/SurgeryTreatmnets`,
        },
        {
          id: 8,
          label: "Manage Laboratory",
          img: manegLab,
          path: `${systemType}/Managelaboratory`,
        },
        {
          id: 9,
          label: "Manage Pharmacy",
          img: manegPharmacy,
          path: `${systemType}/ManagePharmacy`,
        },
        {
          id: 13,
          label: "Setting",
          img: setting,
          path: `${systemType}/HospitalSetting`,
        },
        {
          id: 14,
          label: "Payment",
          img: Payment,
          path: `${systemType}/ComapnyPayment`,
        },
      ]),
];
export const Hotel_sidebarData = (systemType: any) => [
  {
    id: 1,
    label: "Dashboard",
    img: dashboard,
    path: `${systemType}/dashboard`,
  },
  {
    id: 2,
    label: "Booking",
    img: Booking,
    path: "hotel/booking",
  },
  {
    id: 3,
    label: "Property",
    img: Room,
    path: "hotel/Property",
  },
  {
    id: 4,
    label: "Hotel Reservation",
    img: reserve,
    path: "hotel/reserve",
  },
  {
    id: 5,
    label: "Payment",
    img: Payment,
    path: "hotel/payment",
  },
];
export const AmbulanceMenu = [
  {
    path: "ambulance/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },

  {
    path: "ambulance/request",
    name: "Request",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "ambulance/onroutes",
    name: "On Route",
    icon: <LuClipboardList className={classNames(styles.menuIcons)} />,
  },

  {
    path: "ambulance/payments",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];

export const Doctor_Menu = (systemType: any) => {
  return [
    {
      path: `${systemType}/dashboard`,
      name: "Dashboard",
      icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/availability`,
      name: "Availability",
      icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/treatments`,
      name: "Treatments",
      icon: <FaUserDoctor className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/appointment`,
      name: "Appointment",
      icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/patientHistory`,
      name: "Patient History",
      icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
    },
    {
      path: `${systemType}/payments`,
      name: "Payments",
      icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
    },
  ];
};

export const PhysiotherapistMenu = [
  {
    path: "physiotherapist/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/availability",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },

  {
    path: "physiotherapist/appointment",
    name: "Appointment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "physiotherapist/payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const NutritionistMenu = [
  {
    path: "nutritionist/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "nutritionist/availabilityCategory",
    name: "Availability",
    icon: <BsCalendar2Check className={classNames(styles.menuIcons)} />,
  },

  {
    path: "nutritionist/appointment",
    name: "Appiontment",
    icon: <BsClipboardPulse className={classNames(styles.menuIcons)} />,
  },
  {
    path: "nutritionist/history",
    name: "Patient History",
    icon: <BsFileEarmarkText className={classNames(styles.menuIcons)} />,
  },

  {
    path: "nutritionist/payment",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const ParamedicStaffMenu = (systemType: any) => {
  return [
    {
      path: "paramedicStaff/dashboard",
      name: "Request",
      icon: <FiUsers className={classNames(styles.menuIcons)} />,
    },
    {
      path: "paramedicStaff/availabilityCategory",
      name: "Completed Request",
      icon: <AiOutlineUserAdd className={classNames(styles.menuIcons)} />,
    },

    {
      path: `${systemType}/payments`,
      name: "Payments",
      icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
    },
  ];
};

export const CarARentMenu = [
  {
    path: "rentacar/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/vehicleDetail",
    name: "Vehicle Details",
    icon: <FaCar className={classNames(styles.menuIcons)} />,
  },

  {
    path: "rentacar/Order",
    name: "Order",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/history",
    name: "History",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "rentacar/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const HotelMenu = [
  {
    path: "hotel/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/property/Main/Page",
    name: "Property",
    icon: <MdOutlineHomeWork className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/reserve",
    name: "Reserve",
    icon: <BsCalendarCheck className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/booking",
    name: "Booking",
    icon: <PiClipboardText className={classNames(styles.menuIcons)} />,
  },
  {
    path: "hotel/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const TravelMenu = [
  {
    path: "travelAgency/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/tourPakages",
    name: "Tour plan",
    icon: <MdOutlineFlightTakeoff className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/ticketRequests",
    name: "Ticket Requests",
    icon: <AiOutlineUserAdd className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/Booking",
    name: "Booking",
    icon: <MdOutlineAirplaneTicket className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/profile",
    name: "Profile",
    icon: <IoPersonCircleOutline className={classNames(styles.menuIcons)} />,
  },
  {
    path: "travelAgency/Payment",
    name: "Payment",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const InsuranceMenu = [
  {
    path: "insurance/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/Category",
    name: "Insurances",
    icon: <BiCheckShield className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/request",
    name: "Request",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/insuredperson",
    name: "Insured person",
    icon: <VscPersonAdd className={classNames(styles.menuIcons)} />,
  },
  {
    path: "insurance/payments",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];
export const DonationMenu = [
  {
    path: "donation/dashboard",
    name: "Dashboard",
    icon: <GrHomeRounded className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/criteria",
    name: "Criteria",
    icon: <BsCardChecklist className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/packages",
    name: "Packages",
    icon: <AiOutlineCodeSandbox className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/donors",
    name: "Donors",
    icon: <TbUsersPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "donation/payment",
    name: "Payment ",
    icon: <GoCreditCard className={classNames(styles.menuIcons)} />,
  },
];

export const AdminMenu = [
  {
    path: "admin/dashboard",
    name: "Dashboard",
    icon: <BiHomeAlt2 className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/OPD",
    name: "OPD",
    icon: <LuStethoscope className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Resquests",
    name: "Apt. Requests",
    icon: <LuCalendarPlus className={classNames(styles.menuIcons)} />,
  },
  {
    path: "AdminPanel/AdminPharmacyRequest",
    name: "Pharmacy Requests",
    icon: <LuCalendarPlus className={classNames(styles.menuIcons)} />,
  },

  {
    path: "admin/appointments",
    name: "Appointments",
    icon: <FaRegCalendarAlt className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Orders",
    name: "Orders",
    icon: <TbGripVertical className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/hotelBookingRequests",
    name: "Hotel Requests",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/booking",
    name: "Booking",
    icon: <BsCardList className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/donation",
    name: "Donation",
    icon: <BiDonateHeart className={classNames(styles.menuIcons)} />,
  },

  {
    path: "admin/PaymentsOrders",
    name: "Payments",
    icon: <IoCardOutline className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/Users",
    name: "Users",
    icon: <PiUsers className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/percentage",
    name: "Percentage",
    icon: <LuBadgePercent className={classNames(styles.menuIcons)} />,
  },
  {
    path: "admin/UserActivation",
    name: "User Activation",
    icon: <LuBadgePercent className={classNames(styles.menuIcons)} />,
  },
];

export const userInfo = (user?: any) => [
  { label: "Your Name", value: user?.name || "------" },
  { label: "Father's Name", value: user?.spouseOrGuardianName || "------" },
  { label: "Email", value: user?.email || "------" },
  { label: "Phone Number", value: user?.phone || "------" },
  {
    label: "Date of Birth",
    value: user?.dateOfBirth || "------",
  },
  { label: "Gender", value: user?.gender || "------" },
  { label: "NIC/Passport", value: user?.cnicOrPassNo || "------" },
  { label: "Blood Group", value: user?.bloodGroup || "------" },
  { label: "Qualification", value: user?.qualification || "------" },
  { label: "No of Children", value: user?.childCount || "------" },
  { label: "Country", value: user?.country || "------" },
  { label: "City", value: user?.city || "------" },
  { label: "Address", value: user?.address?.address || "------" },
];

export const socialInfo = (user?: any) => [
  { label: "Facebook", value: user?.facebook || "------" },
  { label: "Instagram", value: user?.instagram || "------" },
  { label: "LinkedIn", value: user?.linkedin || "------" },
  { label: "Youtube", value: user?.youtube || "------" },
];

export const bankInfo = (user?: any) => [
  { label: "Bank Name", value: user?.bankName || "------" },
  { label: "IBAN / Account No", value: user?.accountNumber || "------" },
  { label: "Account Title", value: user?.accountHolderName || "------" },
  { label: "NTN", value: user?.ntnNo || "------" },
];

//..................Validations..........................//

export const speciality = [
  "Gynecologists",
  "Cosmetic Surgery",
  "Bariatric Surgery",
  "Transplants",
  "Ophthalmology",
  "Cancer",
  "Orthopedics",
  "Stem Cell",
  "Pediatrics",
  "Dental",
  "Aesthetic Treatments",
  "Psychiatry",
  "Lungs",
  "Urology",
  "Gastroenterology",
  "Neurology",
  "Fertility",
  "Nephrology",
  "E.N.T",
  "Cardiovascular & Metabolic",
];

export const Experiences = [
  "1 year",
  "2 year",
  "3 year",
  "4 year",
  "5 year",
  "6 year",
  "7 year",
  "8 year",
  "9 year",
  "10 year",
  "11 year",
  "12 year",
  "13 year",
  "14 year",
  "15 year",
  "16 year",
  "17 year",
  "18 year",
  "19 year",
  "20 year",
];

//............Treatment........//
export const FreeCosultancy_Form = {
  name: Yup.string().required("name is required"),
  phone: Yup.string().required("phone is required"),
  email: Yup.string().required("email is required"),
};

//...........Laboratory...........//
const dateFormat = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;

export const labInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labLogo: Yup.string().required("Required"),
  labLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labLicenseImage: Yup.string().required("Required"),
  labLicenseExpiry: Yup.string().required("Required"),
  labOwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labOwnerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  address: Yup.string().required("Required"),
  labDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  labOpenTime: Yup.string().required("Required"),
  labCloseTime: Yup.string().required("Required"),
};

const url =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const labSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const labBankSchema = {};

export const user_ResetPassword = {
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
  verificationCode: Yup.string()
    .min(6, "Enter verification code")
    .required("Please enter verification code"),
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,25}$/;

const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+{}\[\]:;<>,.?/\\|-])[A-Za-z\d!@#$%*^&()_+{}\[\]:;<>,.?/\\|-]{8,25}$/;

export const labVerifySchema = {
  phoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
};

export const labLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
};

export const genericLoginSchema = {
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
};

export const labResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const labConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const labLabInfoUpdateProfileSchema = {
  companyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const labBankUpdateProfileSchema = {
  bankName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  accountHolderName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  accountNumber: Yup.string()
    .min(9, "Must be at least 9 Digits long")
    .required("Required"),
};
export const labSocialUpdateeProfileSchema = {
  website: Yup.string().matches(url, "URL is not valid"),
  instagram: Yup.string().matches(url, "URL is not valid"),
  twitter: Yup.string().matches(url, "URL is not valid"),
  facebook: Yup.string().matches(url, "URL is not valid"),
};

export const allUserUpdateProfile = {
  oldPassword: Yup.string().required("Enter your current password"),
  newPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Enter new password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Enter your new password again"),
};

export const labPasswordUpdateProfileSchema = {
  currentPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  NewPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword")], "Passwords must match")
    .required("Required"),
};
//..................Validations..........................//

//.................Test-Addtest..........................//

export const labAddTestInfo = {
  testName: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .max(64, "Test name should not exceed 64 characters.")
    .required("Required"),
  testDescription: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .max(1000, "Description should not exceed 1000 characters.")
    .required("Required"),
  testPrice: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .matches(/^\d+$/, "Please enter a valid number")
    .required("Required"),
  testDuration: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .required("Required"),
  priceForMeditour: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { testPrice } = this.parent;
        return value < testPrice;
      }
    ),
};

export const labAddTestPrice = {
  testprice_TestPrice: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .matches(/^\d+$/, "Please enter a valid number")
    .required("Required"),
  testprice_TestDuration: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .required("Required"),
  testprice_PriceForMeditour: Yup.string()
    // .min(2, "Must be at least 2 characters long")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { testprice_TestPrice } = this.parent;
        return value < testprice_TestPrice;
      }
    ),
};

//..................Validations..........................//

//...........Pharmacy...........//

export const pharmacyInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyLogo: Yup.string().required("Required"),
  pharmacyLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyLicenseImg: Yup.string().required("Required"),
  pharmacyLicenseExpiry: Yup.string().required("Required"),
  pharmacyOwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyOwnerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pharmacyEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
  pharmacyDescription: Yup.string()
    .min(2, "Must be at least 2 numbers long")
    .required("Required"),
  pharamcyOpenTime: Yup.string().required("Required"),
  pharmacyCloseTime: Yup.string().required("Required"),
};

export const pharmaceuticalInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  countrySelection: Yup.string().required("Required"),
  // .test(
  //   "match-country",
  //   "Selected country does not match the location's country.",
  //   function (value) {
  //     const { country } = this.parent;
  //     return !country || value === country;
  //   }
  // ),
};

export const AddFormulaSchema = {
  productType: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  genericId: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  brand: Yup.string().trim().required("Required"),
  strength: Yup.string()
    .trim()
    .min(2, "Please enter at least 2 characters.")
    .required("Required"),
  content: Yup.string().trim().required("Required"),
  packSize: Yup.string().trim().required("Required"),
  tpPrice: Yup.string().trim().required("Required"),
  mrpPrice: Yup.string().trim().required("Required"),
};
export const pharmacyBankSchema = {};
export const pharmacyVerifySchema = {
  pharmacyPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  pharmacyEmail: Yup.string().email().required("Required"),
  pharmacyPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  pharmacyConfirmPassword: Yup.string()
    .oneOf([Yup.ref("pharmacyPassword")], "Passwords must match")
    .required("Required"),
};
export const pharmacyLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const pharmacyResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const pharmacyConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const pharmAddMedicineMedicineInfo = {
  pharmMedicineGeneric: Yup.string().required("Required"),
  pharmMedicineImage: Yup.string().required("Required"),
  pharmMedicineBrand: Yup.string().required("Required"),
  pharmMedicineType: Yup.string().required("Required"),
  pharmMedicineStrength: Yup.string().required("Required"),
  pharmMedicinePackSize: Yup.string().required("Required"),
};

export const pharmAddMedicineMedicinePrice = {
  actualPrice: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required"),
  priceForMediTour: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required")
    .test(
      "is-less-than-actualPrice",
      "MediTour price should be less than actual price",
      function (value) {
        const { actualPrice } = this.parent;
        return value < actualPrice;
      }
    ),
};

export const pharmPasswordUpdateProfileSchema = {
  currentPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  NewPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ConfirmPassword: Yup.string()
    .oneOf([Yup.ref("NewPassword")], "Passwords must match")
    .required("Required"),
};

export const pharmLabInfoUpdateProfileSchema = {
  companyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const pharTaxUpdateProfileSchema = {
  incomeTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  salesTaxNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const donationBasicInfoValidation = (
  systemType: any,
  types?: any,
  type?: any,
  selectedType?: any
) => {
  const selectCompany =
    selectedType === "Attach with Company" &&
    (systemType === "hotel" || systemType === "travelagency");
  return {
    email: Yup.string().email().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    logo: Yup.string().required("Required"),
    name: Yup.string()
      .min(2, "Must be at least 2 characters long")
      .required("Required"),
    companyLicenseNo: Yup.string(),
    companyLicenseExpiry: Yup.string(),
    licenseImage: Yup.string(),
    address:
      type === "labs" || type === "pharmacy"
        ? Yup.string()
        : Yup.string().required("Required"),
    companyEmergencyNo: Yup.string().required("Required"),
    ownerFirstName: Yup.string().required("Required"),
    ownerLastName: Yup.string().required("Required"),
    experience:
      systemType === "hotel" ||
      systemType === "travelagency" ||
      type === "hotel" ||
      type === "travel"
        ? Yup.string().required("Required")
        : Yup.string(),
    features:
      systemType === "hotel" ||
      systemType === "travelagency" ||
      type === "hotel" ||
      type === "travel"
        ? Yup.array().of(Yup.string()).min(1, "Select at least one feature")
        : Yup.array(),
    closeTime:
      systemType === "hospital" ||
      systemType === "laboratory" ||
      systemType === "pharmacy"
        ? Yup.string().required("Required")
        : Yup.string(),
    openTime:
      systemType === "hospital" ||
      systemType === "laboratory" ||
      systemType === "pharmacy"
        ? Yup.string().required("Required")
        : Yup.string(),
    labDescription:
      systemType === "laboratory" || systemType === "pharmacy" || types
        ? Yup.string().required("Required")
        : Yup.string(),
    selectedType:
      systemType === "hotel" || systemType === "travelagency"
        ? Yup.string().required("Required Type")
        : Yup.string(),
    travelCompanyId: selectCompany
      ? Yup.object().required("Select Company")
      : Yup.object(),
    country:
      type === "labs" || type === "pharmacy"
        ? Yup.string()
        : Yup.string().required("Required"),
    countrySelection:
      type === "labs" || type === "pharmacy"
        ? Yup.string()
        : Yup.string().required("Required"),
    // .test(
    //   "match-country",
    //   "Selected country does not match the detected country.",
    //   function (value) {
    //     const { country } = this.parent;
    //     const countryMappings = {
    //       Brazil: ["Brazil", "Brasil"],
    //       Canada: ["Canada", "Ontario", "Quebec", "British Columbia"],
    //       Cambodia: ["Cambodia", "Kingdom of Cambodia"],
    //       "Costa Rica": ["Costa Rica"],
    //       UK: [
    //         "UK",
    //         "United Kingdom",
    //         "Britain",
    //         "Great Britain",
    //         "England",
    //       ],
    //       Germany: ["Germany", "Deutschland"],
    //       Malaysia: ["Malaysia"],
    //       Mexico: ["Mexico", "México", "Estados Unidos Mexicanos"],
    //       Pakistan: ["Pakistan", "Islamic Republic of Pakistan", "PK"],
    //       Switzerland: [
    //         "Switzerland",
    //         "Schweiz",
    //         "Suisse",
    //         "Svizzera",
    //         "Svizra",
    //       ],
    //       Spain: ["Spain", "España"],
    //       "South Korea": ["South Korea", "Republic of Korea"],
    //       Singapore: ["Singapore"],
    //       Türkiye: ["Türkiye", "Turkey"],
    //       Thailand: ["Thailand", "Kingdom of Thailand"],
    //       USA: [
    //         "USA",
    //         "United States",
    //         "United States of America",
    //         "America",
    //       ],
    //       "United Arab Emirates": [
    //         "UAE",
    //         "United Arab Emirates",
    //         "Emiratos Árabes Unidos",
    //       ],
    //     };
    //     for (const [standardName, possibleNames] of Object.entries(
    //       countryMappings
    //     )) {
    //       if (
    //         possibleNames?.some((keyword) => country?.includes(keyword))
    //       ) {
    //         return value === standardName;
    //       }
    //     }
    //     return value === country;
    //   }
    // ),
  };
};
//..................Validations..........................//

//...........Medicalservice-Doctor...........//
export const doctor_BasicInfoSchema = {
  doctorType: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  gender: Yup.string().required("Gender is required"),
  phoneNumber: Yup.string().required("Required"),
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  qualifications: Yup.string().required("Required"),
  speciality: Yup.array()
    .min(1, "Please select at least one speciality.")
    .required("Speciality is required"),
  clinicExperience: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
  country: Yup.string().required("Required"),
  countrySelection: Yup.string().required("Required"),
  // .test(
  //   "match-country",
  //   "Selected country does not match the detected country.",
  //   function (value) {
  //     const { country } = this.parent;
  //     const countryMappings = {
  //       Brazil: ["Brazil", "Brasil"],
  //       Canada: ["Canada", "Ontario", "Quebec", "British Columbia"],
  //       Cambodia: ["Cambodia", "Kingdom of Cambodia"],
  //       "Costa Rica": ["Costa Rica"],
  //       UK: ["UK", "United Kingdom", "Britain", "Great Britain", "England"],
  //       Germany: ["Germany", "Deutschland"],
  //       Malaysia: ["Malaysia"],
  //       Mexico: ["Mexico", "México", "Estados Unidos Mexicanos"],
  //       Pakistan: ["Pakistan", "Islamic Republic of Pakistan", "PK"],
  //       Switzerland: [
  //         "Switzerland",
  //         "Schweiz",
  //         "Suisse",
  //         "Svizzera",
  //         "Svizra",
  //       ],
  //       Spain: ["Spain", "España"],
  //       "South Korea": ["South Korea", "Republic of Korea"],
  //       Singapore: ["Singapore"],
  //       Türkiye: ["Türkiye", "Turkey"],
  //       Thailand: ["Thailand", "Kingdom of Thailand"],
  //       USA: ["USA", "United States", "United States of America", "America"],
  //       "United Arab Emirates": [
  //         "UAE",
  //         "United Arab Emirates",
  //         "Emiratos Árabes Unidos",
  //       ],
  //     };
  //     for (const [standardName, possibleNames] of Object.entries(
  //       countryMappings
  //     )) {
  //       if (possibleNames?.some((keyword) => country?.includes(keyword))) {
  //         return value === standardName;
  //       }
  //     }
  //     return value === country;
  //   }
  // ),
};

export const doctorVerifySchema = {
  doctorPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  doctorEmail: Yup.string().email().required("Required"),
  doctorPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  doctorConfirmPassword: Yup.string()
    .oneOf([Yup.ref("doctorPassword")], "Passwords must match")
    .required("Required"),
};

export const doctor_VerifySchema = {
  phoneNo: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
};
export const doctor_VerifySchemaNew = {
  password: Yup.string()
    .matches(
      passwordPattern,
      "Must include 1 uppercase, 1 special character and 1 digit."
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
};
export const doctorLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string().required("Required"),
};

export const doctorResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const doctorConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(
      passwordPattern,
      "*Must include 1 uppercase and 1 special character."
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Confirm password is required"),
};
export const doctorOnsiteAvailability = {
  onsiteDay: Yup.string().required("Required"),
  morningfrom: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be between 02:00-14:00",
      function (value) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
        const upperLimitTime = new Date(`2000-01-01T14:00:00`);
        return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
      }
    ),
  morningto: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be after Morning From",
      function (value, { parent }) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
        const isAfterMorningFrom = fromTime < enteredTime;
        const isNotEqualMorningFrom =
          fromTime.getTime() !== enteredTime.getTime();
        return isAfterMorningFrom && isNotEqualMorningFrom;
      }
    ),

  eveningfrom: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be between 14:00 and 02:00",
      function (value) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
        const upperLimitTime = new Date(`2000-01-01T02:00:00`);
        return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
      }
    ),
  eveningto: Yup.string()
    .required("Required")
    .test(
      "is-valid-time",
      "Time must be after evening From",
      function (value, { parent }) {
        if (!value) return true;
        const enteredTime = new Date(`2000-01-01T${value}`);
        const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
        const isAfterEveningFrom = fromTime < enteredTime;
        const isNotEqualEveningFrom =
          fromTime.getTime() !== enteredTime.getTime();
        return isAfterEveningFrom && isNotEqualEveningFrom;
      }
    ),
};
export const doctorAvailibilityPrice = {
  actualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Please enter price."),
};
export const doctor_Availability = {
  onsiteDay: Yup.string().required("Please select a day."),
  morningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 02:00-14:00",
    function (value) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
      const upperLimitTime = new Date(`2000-01-01T14:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),

  morningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true;
      // if (value === "00:00") return true; // Allow "00:00" time
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
      const isAfterMorningFrom = fromTime < enteredTime;
      const isNotEqualMorningFrom =
        fromTime.getTime() !== enteredTime.getTime();
      return isAfterMorningFrom && isNotEqualMorningFrom;
    }
  ),

  eveningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 14:00 and 24:00",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (!value === "00:00" && parent.eveningto === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
      const upperLimitTime = new Date(`2000-01-01T24:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  eveningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (value === "00:00" && parent.eveningfrom === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
      return fromTime < enteredTime;
    }
  ),
};
export const doctorInhouseAvailability = {
  onsiteDay: Yup.string().required("Please select a day."),
  morningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 02:00-14:00",
    function (value) {
      if (!value) return true;
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T02:00:00`);
      const upperLimitTime = new Date(`2000-01-01T14:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  morningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true;
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.morningfrom}`);
      const isAfterMorningFrom = fromTime < enteredTime;
      const isNotEqualMorningFrom =
        fromTime.getTime() !== enteredTime.getTime();
      return isAfterMorningFrom && isNotEqualMorningFrom;
    }
  ),

  eveningfrom: Yup.string().test(
    "is-valid-time",
    "Time must be between 14:00 and 24:00",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (!value === "00:00" && parent.eveningto === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const lowerLimitTime = new Date(`2000-01-01T14:00:00`);
      const upperLimitTime = new Date(`2000-01-01T24:00:00`);
      return lowerLimitTime <= enteredTime && enteredTime <= upperLimitTime;
    }
  ),
  eveningto: Yup.string().test(
    "is-valid-time",
    "End time must be after the Start time.",
    function (value, { parent }) {
      if (!value) return true; // Allow empty value
      // if (value === "00:00" && parent.eveningfrom === "00:00") {
      //   return true; // Both evening from and to are "00:00", no validation needed
      // }
      const enteredTime = new Date(`2000-01-01T${value}`);
      const fromTime = new Date(`2000-01-01T${parent.eveningfrom}`);
      return fromTime < enteredTime;
    }
  ),
  actualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};

export const doctorOnsitePrice = {
  onsiteActualPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
  onsiteMeditourPrice: Yup.string()
    .matches(/^\d+$/, "Please enter a valid number")
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};
export const doctorVideoConsultAvailability = {
  videoConsultDay: Yup.string().required("Required"),
};

export const doctorVideoConsultPrice = {
  videoConsultActualPrice: Yup.string()
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
  videoConsultMeditourPrice: Yup.string()
    .min(2, "Must be at least 2 Digit long")
    .max(15, "Must be 15 Digit or less")
    .required("Required"),
};
export const doctorAppointmentHistory = {
  systolicPressure: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  diastolicPressure: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  weight: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  symptoms: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  description: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  diseases: Yup.array()
    .of(Yup.string().trim().min(2, "Must be at least 2 characters long"))
    .min(1, "At least one disease is required")
    .required("Required"),
  // temperature:Yup.string()
  // .trim()
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  // heartRate: Yup.string()
  // .trim()
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
  // sugar: Yup.string()
  // .trim()
  // .min(2, "Must be at least 2 characters long")
  // .required("Required"),
};
export const doctorAppointmentAddmedicineSchema = {
  // type: Yup.string().trim().required("Required"),
  medicineName: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  medicineBrand: Yup.string().required("Required"),
  medicineStrength: Yup.string()
    .trim()
    .required("Required")
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  dosage: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const doctorAppointmentAddTestSchema = {
  testName: Yup.string()
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  testId: Yup.string().trim().required("Required"),
};
//..................Validations..........................//

//...........Medicelservice-Hospitals...........//

export const hospital_BasicInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  registrationNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  registrationImage: Yup.string(),
  registrationExpiry: Yup.string().required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
  openTime: Yup.string().required("Required"),
  closeTime: Yup.string().required("Required"),
};

export const hospitalVerifySchema = {
  hospitalPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  hospitalEmail: Yup.string().email().required("Required"),
  hospitalPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  hospitalConfirmPassword: Yup.string()
    .oneOf([Yup.ref("hospitalPassword")], "Passwords must match")
    .required("Required"),
};
export const hospitalLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const hospitalResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const hospitalConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const hospitalAddDepartmentSchema = {
  departmentName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  departmentLogo: Yup.string().required("Required"),
};

export const hospitalHospitalInfoUpdateProfileSchema = {
  hospitalName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  // state: Yup.string()
  //   .min(2, "Must be at least 2 characters long")
  //   .required("Required"),
};

export const hospitalAddDoctorEnterCode = {
  code: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(25, "Must be 25 characters or less")
    .required("Required"),
};

//..................Validations..........................//

//...........Homeservice-Ambulance...........//

export const ambulanceBasicInfoSchema = {
  ambulanceAmbulanceName: Yup.string()
    .min(3, "Must be at least 3 characters long")
    .required("Required"),
  ambulanceLogo: Yup.string().required("Required"),
  ambulanceRegistrationNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ambulanceRegistrationExpiry: Yup.string().required("Required"),
  ambulanceRegistrationImage: Yup.string().required("Required"),
  ambulanceFirstOwnerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ambulanceLastOwnerName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ambulanceEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};
export const ambulanceVerifySchema = {
  ambulancePhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  ambulanceEmail: Yup.string().email().required("Required"),
  ambulancePassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  ambulanceConfirmPassword: Yup.string()
    .oneOf([Yup.ref("ambulancePassword")], "Passwords must match")
    .required("Required"),
};

export const ambulanceLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const ambulanceInfoSchema = {
  vehicleType: Yup.string().required("Required"),
  vehicleName: Yup.string()
    .matches(/^[^0-9]*$/, "Vehicle Name cannot contain numbers")
    .trim()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleModel: Yup.string()
    .trim()
    // .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleYear: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(4, "Must be 4 Digits or less")
    .required("Required"),
  vehicleColor: Yup.string()
    .trim()
    .matches(/^[^0-9]*$/, "Color cannot contain numbers")
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleRegistrationNumber: Yup.string()
    .trim()
    .min(5, "Must be at least 5 characters long")
    .required("Required"),
  vehicleRegistrationDate: Yup.string().required("Required"),
};

export const ambulancePriceSchema = {
  actualPrice: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  priceForMeditour: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
};
export const ambulanceResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const ambulanceConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const ambulanceInfoUpdateProfileSchema = {
  ambulanceName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  cnic: Yup.string()
    .min(13, "Must be at least 13 Digits long")
    .max(13, "Must be 13 Digits")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const ambulanceAddbidSchema = {
  ambulancename: Yup.string().required("Required"),
  ambulanceno: Yup.string().required("Required"),
  price: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
};

//..................Validations..........................//

//...........Homeservice-Physiotherapist...........//
export const Login_Schema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};
export const paramedicStaffBasicInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  qualifications: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  countrySelection: Yup.string().required("Required"),
  // .test(
  //   "match-country",
  //   "Selected country does not match the location's country.",
  //   function (value) {
  //     const { country } = this.parent;
  //     return !country || value === country;
  //   }
  // ),
};
export const hotelInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  logo: Yup.string().required("Required"),

  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const hotelSocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const hotelVerifySchema = {
  hotelPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  hotelEmail: Yup.string().email().required("Required"),
  hotelPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  hotelConfirmPassword: Yup.string()
    .oneOf([Yup.ref("hotelPassword")], "Passwords must match")
    .required("Required"),
};

export const hotelLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const hotelResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const hotelConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const hotelAddRoomHotelInfo = {
  propertyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  startRating: Yup.string().required("Required"),
  userName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  contactNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  alternativeCellNo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  postCode: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  propertyAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const hotelAddRoom = {
  roomType: Yup.string().required("Required"),
  roomName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  smokingPolicy: Yup.string().required("Required"),
  roomThisType: Yup.string().required("Required"),
  kindOfBeds: Yup.string().required("Required"),
  numberOfBeds: Yup.string().required("Required"), ///

  numberOfGuest: Yup.string().required("Required"),
  roomSize: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  breakfastInclude: Yup.string().required("Required"),
  pricePerNight: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pricePerNightforMeditour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  roomImage: Yup.string().required("Required"),
  roomDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
export const hotelFacilitites = {
  parkingAvailability: Yup.string().required("Required"),
  priceForParking: Yup.string(),
  spokenLanguageYourStaff: Yup.string().required("Required"),
};
export const hotelAmenities = {
  provideExtraBed: Yup.string().required("Required"),
  numberofExtraBed: Yup.string().required("Required"),
};
export const hotelPrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  switch: Yup.boolean().required("Required"),
  checkinFrom: Yup.string().required("Required"),
  checkinTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  children: Yup.string().required("Required"),
  childrenAgeFrom: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  childrenAgeTo: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  chargesMayApplyorNot: Yup.string().required("Required"),
  chargesofChild: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  pets: Yup.string().required("Required"),
  chargesofPetApply: Yup.string().required("Required"),
  chargesofPets: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const hotelAddHomesHomeInfo = {
  propertyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  customName: Yup.string().required("Required"),
  contactNo: Yup.string().required("Required"),
  alternativeContactNo: Yup.string().required("Required"),
  starRating: Yup.string().required("Required"),
  postCode: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  partOfCompany: Yup.string().required("Required"),
  channelManager: Yup.string().required("Required"),
  companyName: Yup.string(),
  channelManagerName: Yup.string(),
};
export const hotelAddHomeLayout = {
  homeType: Yup.string().required("Required"),
  customName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  noofBedRoom: Yup.number().required("Required"),
  noofDiningRoom: Yup.number().required("Required"),
  noofBathRoom: Yup.number().required("Required"),
  noofKitchens: Yup.number().required("Required"),
  noofFloor: Yup.number().required("Required"),

  guestno: Yup.number().required("Required"),
  privateBatroom: Yup.boolean().required("Required"),
  commonSofaBed: Yup.number().required("Required"),
  commonGuest: Yup.number().required("Required"),
  appartmentSize: Yup.string(),
  basicpricePerNight: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
  pricepernightformeditour: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
};

export const hotelAddHomefecServices = {
  parking: Yup.string().required("Required"),
  LanguageSpoken: Yup.string().required("Required"),
  private: Yup.string(),
  onSite: Yup.string(),
  reservationNeeded: Yup.string(),
  price: Yup.string().min(2, "Must be at least 2 characters long"),
};
export const hotelAddHomePrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  accidentalBookingPolicy: Yup.boolean().required("Required"),
  checkInFrom: Yup.string().required("Required"),
  checkInTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  smoking: Yup.string().required("Required"),
  accomodateChildren: Yup.string().required("Required"),
  childrenAgeTo: Yup.string(),
  childrenAgeFrom: Yup.string(),
  chargesMayApply: Yup.string(),
  pets: Yup.string().required("Required"),
  chargesOfPets: Yup.string(),

  minimumStay: Yup.string().required("Required"),
};
export const hotelAddAppartmentPrivacy = {
  advanceCancelfreeofCharge: Yup.string().required("Required"),
  accidentalBookingPolicy: Yup.boolean().required("Required"),
  checkInFrom: Yup.string().required("Required"),
  checkInTo: Yup.string().required("Required"),
  checkOutFrom: Yup.string().required("Required"),
  checkOutTo: Yup.string().required("Required"),
  smoking: Yup.string().required("Required"),
  accomodateChildren: Yup.string().required("Required"),
  childrenAgeTo: Yup.string(),
  childrenAgeFrom: Yup.string(),
  chargesMayApply: Yup.string(),
  pets: Yup.string().required("Required"),
  stayeofPets: Yup.string(),
  chargesOfPets: Yup.number(),

  minimumStay: Yup.string().required("Required"),
};

export const hotelAddAppartmentFacilities = {
  parking: Yup.string().required("Required"),
  PriceOfParking: Yup.string(),
  LanguageSpoken: Yup.string().required("Required"),
};

export const hotelAddAppartmentSchema = {
  appartmentNo: Yup.string().required("Required"),
  customName: Yup.string().required("Required"),
  numberofBedRoom: Yup.string().required("Required"),
  numberofDiningRoom: Yup.string().required("Required"),
  numberofBathRoom: Yup.string().required("Required"),
  howmanyKitchens: Yup.string().required("Required"),
  noofApartments: Yup.string().required("Required"),
  guestStayinthisAppartment: Yup.number().required("Required"),
  breakfastInclude: Yup.string().required("Required"),
  privateBathroom: Yup.boolean().required("Required"),
  numberofSofabedinAppartment: Yup.string().required("Required"),
  totalGuestStay: Yup.string().required("Required"),
  appartmentSize: Yup.string(),
  basicpricePerNight: Yup.string()
    .matches(/^[0-9]+$/, "Must contain only numbers")

    .required("Required"),
  // pricepernightformeditour: Yup.string()
  //   .matches(/^[0-9]+$/, "Must contain only numbers")

  //   .required("Required"),
};
//...........Rent A Car................//

export const rentACarInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  // //cnicNumber: Yup.string()
  // //min(2, "Must be at least 2 Digits long")
  // //.required("Required"),

  // // cnicExpiry: Yup.string().required("Required"),

  // // cnicImage: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};

export const rentACarLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const rentACarResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const rentACarConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const rentACarAddVahicleSchema = {
  vahicleType: Yup.string().required("Required"),
  vehicleName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleModal: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleYear: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleColour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleVIN: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  perDayRent: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleRegistrationNum: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vahicleRegistrationDate: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  vehicleImages: Yup.array()
    .min(1, "At least one image is required.")
    .max(3, "A maximum of 3 images are allowed.")
    .required("Vehicle images are required."),
};
export const rentCarPriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  priceForMediTour: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};
//..............TRAVEL AGENCY.........//

export const travelAgencyInfoSchema = {
  AgencyName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  AgencyLogo: Yup.string().required("Required"),

  AgencyLicenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  LicenseImage: Yup.string().required("Required"),
  // licenseExpiry: Yup.string().required("Required"),
  AgencyEmergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  OwnerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  cnicNumber: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  cnicExpiry: Yup.string().required("Required"),

  cnicImage: Yup.string().required("Required"),

  AgencyAddress: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
};

export const travelAgencySocialSchema = {
  fbUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  instaUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  twitterUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
  webUrl: Yup.string().matches(url, "URL is not valid"),
  // .required("Required"),
};

export const travelAgencyBankSchema = {
  saleTaxNo: Yup.string().min(2, "Must be at least 2 characters long"),
  // .required("Required"),
  bankName: Yup.string().min(2, "Must be at least 2 characters long"),
  // .required("Required"),
  incomeTaxNo: Yup.string().min(2, "Must be at least 2 characters long"),
  // .required("Required"),
  accountHolderName: Yup.string().min(2, "Must be at least 2 characters long"),
  // .required("Required"),
  accountNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  TaxFile: Yup.string(),
  // .required("Required"),
};

export const travelAgencyVerifySchema = {
  travelAgencyPhoneNo: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  travelAgencyEmail: Yup.string().email().required("Required"),
  travelAgencyPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  travelAgencyConfirmPassword: Yup.string()
    .oneOf([Yup.ref("travelAgencyPassword")], "Passwords must match")
    .required("Required"),
};

export const travelAgencyLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string().required("Required"),
};

export const travelAgencyResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const travelAgencyConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
//..........Donation.........../////

export const donationInfoSchema = {
  name: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  logo: Yup.string().required("Required"),
  licenseNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  licenseExpiry: Yup.string().required("Required"),
  licenseImage: Yup.string().required("Required"),
  emergencyNumber: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerFirstName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  ownerLastName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  address: Yup.string().required("Required"),
  lat: Yup.number(),
  lng: Yup.number(),
  city: Yup.string(),
};
export const donationLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    // .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const donationResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const donationConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};

export const donationAddCriteriaSchema = {
  criteriaName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),

  image: Yup.string().required("Required"),

  description: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(45, "Must be 45 characters or less")
    .required("Required"),
};

export const donationAddPackageSchema = {
  donationTitle: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(45, "Must be 45 characters or less")
    .required("Required"),
  targetAudience: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(500, "Must be 500 characters or less")
    .required("Required"),
  totalRequiredAmount: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .required("Required"),
  totalDays: Yup.string().required("Required"),
  description: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .max(500, "Must be 500 characters or less")
    .required("Required"),
};
export const insuranceLoginSchema = {
  Email: Yup.string().email().required("Required"),
  Password: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
};

export const insuranceResetSchema = {
  Email: Yup.string().email().required("Required"),
};

export const insuranceConfirmPasswordSchema = {
  desiredPassword: Yup.string()
    .matches(passwordPattern, "Password is not valid")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("desiredPassword")], "Passwords must match")
    .required("Required"),
};
export const insuranceMySelfPackageSchema = {
  insuranceAgestart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  insuranceAgeend: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  gender: Yup.string().required("Required"),
};

export const insuranceFamilyPackageSchema = {
  insuranceAgestart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .required("Required"),
  insuranceAgeend: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .required("Required"),
  spouseAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digis long")
    .required("Required"),
  spouseAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .required("Required"),
  kidsAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")

    .required("Required"),
  kidsAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")

    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .required("Required"),
};

export const insuranceParentsPackageSchema = {
  parentsAgeStart: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  parentsAgeEnd: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationStartLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  hospitalizationEndLimit: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
};
export const insurancePackageBasicinfo = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  packageLogo: Yup.string().required("Required"),
  hospitalizationPerPerson: Yup.string().required("Required"),
  dailyRoomBoardLimit: Yup.string().required("Required"),
  claimPayoutRatio: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  packageDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
};

export const insuranceMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  coverageSpecializedInvestigation: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  maternity: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
  description: Yup.string()
    .min(10, "Must be at least 10 characters long")

    .required("Required"),
};

export const insurancePriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(5, "Must be 5 Digits or less")
    .required("Required"),
  // meditourPrice: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(5, "Must be 5 Digits or less")
  //   .required("Required"),
  perYear: Yup.string()
    .min(2, "Must be at least 2 Digits long")

    .required("Required"),
};

export const insuranceHealthMySelfPackageSchema = {
  yourAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  yourAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  spouseAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  spouseAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  kidAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  kidAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  hospitalizationAgeStart: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  hospitalizationAgeEnd: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
};

export const insuranceHealthFamilyMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  opd: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};
export const insuranceHealthParentsMedicalBenefits = {
  icu: Yup.string().required("Required"),
  additionalLimit: Yup.string().required("Required"),
  ambulanceService: Yup.string().required("Required"),
  maternity: Yup.string().required("Required"),
  investigation: Yup.string().required("Required"),
  weeks: Yup.string().required("Required"),
  policyDocument: Yup.string().required("Required"),
  claimProcess: Yup.string().required("Required"),
  heading: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};

export const insuranceTravelIndividualSchema = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  packageLogo: Yup.string().required("Required"),
  medicalCover: Yup.string().required("Required"),
  coveringUpTo: Yup.string().required("Required"),
  packageDescription: Yup.string()
    .min(2, "Must be at least 2 characters long")
    .required("Required"),
  CountryName: Yup.string().required("Required"),
  packageCategory: Yup.string().required("Required"),
};

export const insuranceTravelIndividualMedicalInfoSchema = {
  medicalExpense: Yup.string().required("Required"),
  repatriationofMortalRemains: Yup.string().required("Required"),
  repatriationInCaseIllness: Yup.string().required("Required"),
  returnofDependentChildren: Yup.string().required("Required"),
  deliveryOfMedicine: Yup.string().required("Required"),
  emergencyReturnHome: Yup.string().required("Required"),
};

export const insuranceTravelFamilyMedicalInfoSchema = {
  accidentalDisability: Yup.string().required("Required"),
  repatriationofMortalRemains: Yup.string().required("Required"),
  expensesHospitalization: Yup.string().required("Required"),
  emergencyReturnHome: Yup.string().required("Required"),
};

export const insuranceTravelIndividualBenefitsSchema = {
  flghtDelay: Yup.string().required("Required"),
  lossofPassport: Yup.string().required("Required"),
  delayinArrival: Yup.string().required("Required"),
  lossOfBaggage: Yup.string().required("Required"),
};

export const insuranceTravelFamilyBenefitsSchema = {
  tripCancel: Yup.string().required("Required"),
  delayinArrival: Yup.string().required("Required"),
  flightDelay: Yup.string().required("Required"),
  travelandStay: Yup.string().required("Required"),
  lossofPassport: Yup.string().required("Required"),
  lossOfBaggage: Yup.string().required("Required"),
};
export const insuranceTravelPriceSchema = {
  actualPrice: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  // meditourPrice: Yup.string()
  //   .min(2, "Must be at least 2 Digits long")
  //   .max(15, "Must be 15 Digits or less")
  //   .required("Required"),
  perYear: Yup.string()
    .min(2, "Must be at least 2 characters long")

    .required("Required"),
};
export const insuranceTravelPolicyDocuments = {
  PolicyDocument: Yup.string().required("Required"),
};

export const travelAgencyAddTourSchema = {
  packageName: Yup.string()
    .min(2, "Must be at least 2 Digits long")

    .required("Required"),
  packageDuration: Yup.string().required("Required"),
  from: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  to: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  departDate: Yup.string().required("Required"),
  departTime: Yup.string().required("Required"),
  returnDate: Yup.string().required("Required"),
  returnTime: Yup.string().required("Required"),
  seatLimit: Yup.string()
    .min(2, "Must be at least 2 Digits long")
    .max(15, "Must be 15 Digits or less")
    .required("Required"),
  class: Yup.string().required("Required"),
};

export const travelAgencyAddTourFacilitiesSchema = {
  breakfast: Yup.string().required("Required"),
  lunch: Yup.string().required("Required"),
  dinner: Yup.string().required("Required"),
  dayByDay: Yup.string()
    .min(2, "Must be at least 2 Character long")

    .required("Required"),
};

export const travelAgencyAddTourPoliciesSchema = {
  tourPolicy: Yup.string()
    .min(2, "Must be at least 2 Character long")

    .required("Required"),
};

export const travelAgencyAddTourPriceSchema = {
  priceperHead: Yup.string()
    .min(2, "Must be at least 2 Character long")
    .max(15, "Must be 15 Character or less")
    .required("Required"),
  priceperCouple: Yup.string()
    .min(2, "Must be at least 2 Character long")
    .max(15, "Must be 15 Character or less")
    .required("Required"),
};
export const AddminLoginSchema = {
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
};
// ...........B2C ............/
export const AppointmentSchema = Yup.object({
  appointmentType: Yup.string().required("Please select an appointment type."),
  // ppointmentType: Yup.string().required("Appointment type is required"),
  // paymentCountry: Yup.string().required("Payment country is required"),
  // paymentType: Yup.string().required("Payment type is required"),
});

export const cityData = [
  "Abbottabad",
  "Ahmedpur East",
  "Alipur",
  "Alipur Chattha",
  "Arifwala",
  "Attock",
  "Awaran",
  "Badin",
  "Bahawalnagar",
  "Bahawalpur",
  "Bannu",
  "Bhan Saeedabad",
  "Bhawana",
  "Burewala",
  "Chachro",
  "Chagai",
  "Chak Jhumra",
  "Chakwal",
  "Charsadda",
  "Chawinda",
  "Chichawatni",
  "Chilas",
  "Chiniot",
  "Chishtian",
  "Chitral",
  "Chor",
  "Dadu",
  "Daharki",
  "Dalbandin",
  "Darya Khan",
  "Daska",
  "Daultala",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Digri",
  "Dinga",
  "Dunyapur",
  "Faisalabad",
  "Farooqabad",
  "Fateh Jang",
  "Fort Abbas",
  "Gambat",
  "Ghotki",
  "Gilgit",
  "Gojra",
  "Golarchi",
  "Gujar Khan",
  "Gujranwala",
  "Gujrat",
  "Gwadar",
  "Hafizabad",
  "Hangu",
  "Haripur",
  "Hasilpur",
  "Hassan Abdal",
  "Hub",
  "Hujra Shah Muqeem",
  "Hyderabad",
  "Islamabad",
  "Islamkot",
  "Jacobabad",
  "Jahanian",
  "Jalalpur Pirwala",
  "Jamshoro",
  "Jand",
  "Jaranwala",
  "Jatoi",
  "Jauharabad",
  "Jhang",
  "Jhelum",
  "Jiwani",
  "Kabirwala",
  "Kadhan",
  "Kalat",
  "Kalabagh",
  "Kalat",
  "Kamoke",
  "Kandhkot",
  "Kandiaro",
  "Karachi",
  "Kasur",
  "Kashmore",
  "Khairpur",
  "Khairpur Nathan Shah",
  "Khanewal",
  "Kharan",
  "Kharian",
  "Khushab",
  "Khuzdar",
  "Kohat",
  "Kohlu",
  "Kot Addu",
  "Kot Diji",
  "Kot Momin",
  "Kot Radha Kishan",
  "Kotli",
  "Kotli Sattian",
  "Kunri",
  "Lahore",
  "Lakki Marwat",
  "Lalian",
  "Larkana",
  "Layyah",
  "Liaquatpur",
  "Lodhran",
  "Mailsi",
  "Malakand",
  "Mansehra",
  "Mandi Bahauddin",
  "Mandi Faizabad",
  "Manga Mandi",
  "Mardan",
  "Mashkay",
  "Mastung",
  "Matli",
  "Matiari",
  "Mianwali",
  "Minchinabad",
  "Mingora",
  "Mirpur Bathoro",
  "Mirpur Khas",
  "Mirpur Mathelo",
  "Mirpur Sakro",
  "Mirwah",
  "Mithi",
  "Multan",
  "Muridke",
  "Murree",
  "Muzaffargarh",
  "Muzaffarabad",
  "Nagarparkar",
  "Nankana Sahib",
  "Narowal",
  "Nawabshah",
  "New Saeedabad",
  "Nowshera",
  "Nushki",
  "Okara",
  "Pabbi",
  "Pakpattan",
  "Pano Aqil",
  "Parachinar",
  "Pasni",
  "Pasrur",
  "Pattoki",
  "Peshawar",
  "Phalia",
  "Pind Dadan Khan",
  "Pir Jo Goth",
  "Pir Mahal",
  "Pishin",
  "Qila Abdullah",
  "Qila Didar Singh",
  "Quetta",
  "Rahim Yar Khan",
  "Rajanpur",
  "Rangpur",
  "Renala Khurd",
  "Risalpur",
  "Rawalpindi",
  "Rohri",
  "Rojhan",
  "Sadiqabad",
  "Sahiwal",
  "Sakrand",
  "Samaro",
  "Sambrial",
  "Sanghar",
  "Sangla Hill",
  "Sarai Alamgir",
  "Sargodha",
  "Sehwan",
  "Shahdadkot",
  "Shahdadpur",
  "Shahkot",
  "Shahpur Chakar",
  "Shakargarh",
  "Sheikhupura",
  "Shikarpur",
  "Shorkot",
  "Shujaabad",
  "Sibi",
  "Sialkot",
  "Sinjhoro",
  "Sohbatpur",
  "Sukkur",
  "Swabi",
  "Swat",
  "Taunsa",
  "Taxila",
  "Thal",
  "Thal",
  "Thari Mirwah",
  "Tharparkar",
  "Thatta",
  "Toba Tek Singh",
  "Turbat",
  "Umerkot",
  "Vehari",
  "Wah",
  "Wana",
  "Wazirabad",
  "Zahir Pir",
  "Zhob",
  "Ziarat",
];
// ...........Vendor Screen ............//

export const Vendor_cards = [
  {
    id: 1,
    name: "Doctor & Consultants",
    description:
      "Register to offer your medical services and connect with patients easily.",
    img: vendor01,
    color: "#00BDAF",
    type: "doctor",
    route: "/doctor/login",
    no: "01",
  },
  {
    id: 2,
    name: "Hospitals & Clinics",
    description:
      "Join our network to provide quality care and reach more patients.",
    img: vendor02,
    color: "#13A89E",
    type: "hospital",
    route: "/hospital/login",
    no: "02",
  },
  {
    id: 3,
    name: "Laboratory",
    description: "Register to offer testing services and support patient care.",
    img: vendor03,
    color: "#F19E69",
    type: "laboratory",
    route: "/laboratory/login",
    no: "03",
  },
  {
    id: 4,
    name: "Paramedic Staff",
    description:
      "Join to provide emergency care and support patients in critical situations.",
    img: vendor04,
    color: "#FFC537",
    type: "paramedic",
    route: "/paramedic/login",
    no: "04",
  },
  {
    id: 5,
    name: "Pharmacy",
    description:
      "Register to offer medications and health products to patients in need. ",
    img: vendor05,
    color: "#2BB2FE",
    type: "pharmacy",
    route: "/pharmacy/login",
    no: "05",
  },
  {
    id: 6,
    name: "Pharmaceutical",
    description:
      "Register to supply quality medicines and health products to healthcare providers.",
    img: vendor06,
    color: "#099BED",
    type: "pharmaceutical",
    route: "/pharmaceutical/login",
    no: "06",
  },
  {
    id: 7,
    name: "Physiotherapist",
    description:
      "Join to offer rehabilitation services and help patients recover and improve mobility.",
    img: vendor07,
    color: "#A2968E",
    type: "physiotherapist",
    route: "/physiotherapist/login",
    no: "07",
  },
  {
    id: 8,
    name: "Psychologist",
    description:
      "Register to provide mental health support and help patients achieve well-being..",
    img: vendor08,
    color: "#DE987C",
    type: "psychologist",
    route: "/psychologist/login",
    no: "08",
  },
  {
    id: 9,
    name: "Nutritionist",
    description:
      "Join to offer personalized nutrition advice and help clients achieve their health goals.",
    img: vendor09,
    color: "#BCC3A0",
    type: "nutritionist",
    route: "/nutritionist/login",
    no: "09",
  },
  {
    id: 10,
    name: "Ambulance",
    description:
      "Register to provide emergency transport services and ensure quick patient care.",
    img: vendor10,
    color: "#FF7979",
    type: "ambulance",
    route: "/ambulance/login",
    no: "10",
  },
  {
    id: 11,
    name: "Hotel",
    description:
      "Join to offer comfortable accommodations and provide a welcoming stay for travelers.",
    img: vendor11,
    color: "#336BD1",
    type: "hotel",
    route: "/hotel/login",
    no: "11",
  },
  {
    id: 12,
    name: "Travel Agency",
    description:
      "Register to offer travel packages and help clients plan their perfect getaway.",
    img: vendor12,
    color: "#829EFA",
    type: "travelagency",
    route: "/travelagency/login",
    no: "12",
  },
  {
    id: 13,
    name: "Rent a Car",
    description:
      "Register to offer convenient car rentals and help travelers explore with ease.",
    img: vendor13,
    color: "#829FFF",
    type: "rentacar",
    route: "/rentacar/login",
    no: "13",
  },
  {
    id: 14,
    name: "Insurance",
    description:
      "Register to provide insurance solutions and help clients secure their future.",
    img: vendor14,
    color: "#A196E1",
    type: "insurance",
    route: "/insurance/login",
    no: "14",
  },
  {
    id: 15,
    name: "Donation",
    description:
      "Join us to support important causes and make a positive impact through your generous contributions.",
    img: vendor15,
    color: "#ED9393",
    type: "donation",
    route: "/donation/login",
    no: "15",
  },
];
