import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./style.module.css";
import logo from "assets/images/screenlogo.png";
import cardimg from "assets/images/celebrate 1.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setUser } from "shared/redux";
import { activationAccount, IncreaseLimit } from "shared/services/AuthService";
import CustomLoader from "shared/components/New_Loader/Loader";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "shared/components";
import toast from "react-hot-toast";
import gridlayout from "assets/images/congrats.jpg";
import { striptPaid } from "shared/services";

const CongratsScreen = () => {
  const { user, paymentParams, systemType } = useSelector(
    (state: any) => state?.root?.common
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  console.log(paymentParams, ".....paymentParams");
  useEffect(() => {
    let vendorType =
      systemType === "paramedic" ||
      systemType === "nutritionist" ||
      systemType === "psychologist" ||
      systemType === "physiotherapist"
        ? "doctor"
        : systemType === "greentourism"
        ? "travel company"
        : systemType;
    if (vendorType && paymentParams?.gatewayName === "blinq") {
      activateVendor();
      return;
    }
    if (paymentParams?.gatewayName === "stripe") {
      // payActivation();
      return;
    }
  }, [paymentParams]);

  const activateVendor = () => {
    setLoading(true);
    let params = {
      vendorType:
        systemType === "paramedic" ||
        systemType === "nutritionist" ||
        systemType === "psychologist" ||
        systemType === "physiotherapist"
          ? "doctor"
          : systemType === "greentourism"
          ? "travel company"
          : systemType,
      vendorId: user?._id,
      paymentId: paymentParams?.paymentId,
      gatewayName: paymentParams?.gatewayName,
    };

    activationAccount(params)
      .then((res: any) => {})
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      })
      .finally(() => setLoading(false));
  };

  // const increaseLimit = () => {
  //   setLoading(true);
  //   let params = {
  //     numberOfDocs: paymentParams?.numberOfDocs,
  //   };
  //   IncreaseLimit(params)
  //     .then((res: any) => {
  //       console.log(res, '.......increase limit')
  //     })
  //     .catch((err: any) => {
  //       toast.error(err?.response?.data.message);
  //     })
  //     .finally(() => setLoading(false));
  // };

  const removeStorage = () => {
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));
    navigate("/");
  };

  return (
    <div className={classNames(style.parent)}>
      <div className={classNames(style.logocontainer)}>
        <img src={logo} alt="Logo" />
      </div>

      <div className={classNames(style.card)}>
        <div className={classNames(style.cardimgconatiner)}>
          <img src={gridlayout} alt="" className={style.cardimg} />
        </div>
        <div className={style.textcontainer}>
          <div className={style.heading}>Congratulations!</div>
          <div className={style.title}>
            Your account activation request has been sent. You will receive an
            email in 24 Hours.
          </div>

          <div className={style.buttoncontainer}>
            <PrimaryButton
              children={"Back to Home"}
              colorType={"admin"}
              onClick={removeStorage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongratsScreen;
