import React, { useRef, useState } from "react";
import styles from "./Offer.module.css";
import v1 from "assets/images/v3.png";
import v2 from "assets/images/v2.png";
import v3 from "assets/images/v1.png";
import v4 from "assets/images/v4.png";
import v5 from "assets/images/v5.png";
import v6 from "assets/images/v6.png";
import { FaChevronDown } from "react-icons/fa6";
import { Checkbox, Radio } from "@mui/material";
import { BsUpload } from "react-icons/bs";
import { add_File } from "shared/services";
import { MdOutlineDelete } from "react-icons/md";
import CheckIn from "../NewTermCondition/CheckIn";
const OfferHotel = ({ formik }: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const options = ["Room Services", "Air Conditioning", "Laundry"];
  const resturent = ["⁠Dine In", "Room Service", "⁠Not Available"];
  const internet = ["Wifi", "LAN", "No Internet"];
  const activity = ["TV in a room", "GYM", "Swimming"];
  const outdoor = [" ⁠Outdoor Sitting", "Barbecue", "Not Available"];
  const security = [
    " Exterior security camera",
    "Weapons on the property",
    "Security guards",
  ];

  const handleFeatureSelect = (category: string, option: string) => {
    formik.setValues((prevValues: any) => {
      const existingFeature = prevValues.features.find(
        (f: any) => f.name === category
      );
      if (existingFeature) {
        if (existingFeature.options.includes(option)) {
          existingFeature.options = existingFeature.options.filter(
            (o: any) => o !== option
          );
        } else {
          existingFeature.options.push(option);
        }
      } else {
        prevValues.features.push({ name: category, options: [option] });
      }
      return { ...prevValues };
    });
  };
  const handleDelete = (index: any) => {
    const newPhotos = [...formik.values.propertyphotos];
    newPhotos.splice(index, 1);
    formik?.setFieldValue("propertyphotos", newPhotos);
  };
  const onUploadImage = (event: any) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      add_File(formData)
        .then((response: any) => {
          const updatedSafetyDetails = formik.values.propertyphotos;
          formik.setFieldValue("propertyphotos", [
            ...updatedSafetyDetails,
            response.data.fileUrl,
          ]);
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleHospitalOpenTime = (time: any) => {
    formik.setFieldValue("checkInTime", time);
  };
  const handleHospitalTime = (time: any) => {
    formik.setFieldValue("checkOutTime", time);
  };
  const handleSaftyDetails = (option: string) => {
    const updatedSafetyDetails = formik.values.safetyDetails;
    if (updatedSafetyDetails.includes(option)) {
      formik.setFieldValue(
        "safetyDetails",
        updatedSafetyDetails.filter((item: string) => item !== option)
      );
    } else {
      formik.setFieldValue("safetyDetails", [...updatedSafetyDetails, option]);
    }
  };

  return (
    <>
      <h2 className={styles.heading}>
        Tell guests what your place has to offer
      </h2>
      <div className={styles.selectProperty}>
        <div className={styles.selectText}>Select Features</div>
        <div className={styles.selectionbox}>
          <SelectionCheckBox
            source={v1}
            title={"Services"}
            options={options}
            selectedOptions={formik.values.features}
            handleFeatureSelect={handleFeatureSelect}
          />
          <SelectionCheckBox
            source={v3}
            title={"Restaurant"}
            options={resturent}
            selectedOptions={formik.values.features}
            handleFeatureSelect={handleFeatureSelect}
          />
          <SelectionCheckBox
            source={v2}
            title={"Internet"}
            options={internet}
            selectedOptions={formik.values.features}
            handleFeatureSelect={handleFeatureSelect}
          />
          <ParkingSelection source={v6} formik={formik} />
          <SelectionCheckBox
            source={v4}
            title={"Outdoor"}
            options={outdoor}
            selectedOptions={formik.values.features}
            handleFeatureSelect={handleFeatureSelect}
          />
          <SelectionCheckBox
            source={v5}
            title={"Activities"}
            options={activity}
            selectedOptions={formik.values.features}
            handleFeatureSelect={handleFeatureSelect}
          />
        </div>

        {formik.errors.parking && formik.touched.parking && (
          <div className={styles.error}>{formik.errors.parking}</div>
        )}
      </div>
      {formik.errors.features && formik.touched.features && (
        <div className={styles.error}>{formik.errors.features}</div>
      )}
      <div className={styles.infoSection}>
        <div className={styles.inputStyle}>
          <div>
            <input
              placeholder="Count of this property"
              className={styles.input}
              value={formik?.values?.propertyCount}
              onChange={formik.handleChange("propertyCount")}
            />
            {formik.errors.propertyCount && formik.touched.propertyCount && (
              <div className={styles.error}>{formik.errors.propertyCount}</div>
            )}
          </div>
          <div>
            <CheckIn
              placeholder={"Check-In Time"}
              AM={"hh:mm A"}
              setData={handleHospitalOpenTime}
              type={"box"}
              value={formik.values.checkInTime}
            />
            {formik.errors.checkInTime && formik.touched.checkInTime && (
              <div className={styles.error}>{formik.errors.checkInTime}</div>
            )}
          </div>
          <div>
            <CheckIn
              placeholder={"Check-Out Time"}
              AM={"hh:mm A"}
              setData={handleHospitalTime}
              type={"box"}
              value={formik.values.checkOutTime}
            />
            {formik.errors.checkOutTime && formik.touched.checkOutTime && (
              <div className={styles.error}>{formik.errors.checkOutTime}</div>
            )}
          </div>
          <div>
            <input
              placeholder="Rs. Set property rent"
              className={styles.input}
              value={formik?.values?.propertyRent}
              onChange={formik.handleChange("propertyRent")}
            />
            {formik.errors.propertyRent && formik.touched.propertyRent && (
              <div className={styles.error}>{formik.errors.propertyRent}</div>
            )}
          </div>
          <div>
            <input
              placeholder="Rs. Meditour service fee"
              className={styles.input}
              value={formik?.values?.meditourFee}
              onChange={formik.handleChange("meditourFee")}
            />
            {formik.errors.meditourFee && formik.touched.meditourFee && (
              <div className={styles.error}>{formik.errors.meditourFee}</div>
            )}
          </div>
          <div>
            <SomokingBox
              title={"Smoking"}
              chargeOption={formik?.values?.smoking}
              formik={formik}
              field={"smoking"}
            />
            {formik.errors.smoking && formik.touched.smoking && (
              <div className={styles.error}>{formik.errors.smoking}</div>
            )}
          </div>
          <div>
            <SomokingBox
              title={"Pets "}
              chargeOption={formik?.values?.pets}
              formik={formik}
              field={"pets"}
            />
            {formik.errors.pets && formik.touched.pets && (
              <div className={styles.error}>{formik.errors.pets}</div>
            )}
          </div>
          <div>
            <SomokingBox
              title={"Provide extra beding"}
              chargeOption={formik?.values?.extraBeds}
              formik={formik}
              field={"extraBeds"}
            />
            {formik.errors.extraBeds && formik.touched.extraBeds && (
              <div className={styles.error}>{formik.errors.extraBeds}</div>
            )}
          </div>
        </div>
        <div className={styles.secoundColumn}>
          <div>
            <SaftyDetail
              title={"Select safety details"}
              options={security}
              selectedOptions={formik.values.safetyDetails}
              handleOptionChange={handleSaftyDetails}
            />
            {formik.errors.safetyDetails && formik.touched.safetyDetails && (
              <div className={styles.error}>{formik.errors.safetyDetails}</div>
            )}
          </div>
          <div>
            <input
              placeholder="Surrounding Property"
              className={styles.input}
              value={formik?.values?.sorroundingProperty}
              onChange={formik.handleChange("sorroundingProperty")}
            />
            {formik.errors.sorroundingProperty &&
              formik.touched.sorroundingProperty && (
                <div className={styles.error}>
                  {formik.errors.sorroundingProperty}
                </div>
              )}
          </div>
          <div>
            <div className={styles.photoClass} onClick={handleClick}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                placeholder="Upload Photos"
                onChange={onUploadImage}
                multiple
              />
              <p className={styles.textSelected3}>Upload Photos</p>
              <BsUpload size={24} color="#7D7D7D" />
            </div>
            <p className={styles.redPhoto}>Min 1 Uploads</p>
            {formik.errors.propertyphotos && formik.touched.propertyphotos && (
              <div className={styles.error}>{formik.errors.propertyphotos}</div>
            )}
            {loading ? (
              <div className={styles.loaderContainer}>
                <div className={styles.spinner}></div>
              </div>
            ) : (
              <div className={styles.uploadedImages}>
                {formik?.values?.propertyphotos.map(
                  (photo: any, index: any) => (
                    <div key={index} className={styles.imageContainer}>
                      <img
                        key={index}
                        src={photo}
                        alt={`Uploaded ${index}`}
                        className={styles.uploadedImage}
                      />
                      <MdOutlineDelete
                        size={24}
                        color="red"
                        className={styles.deleteIcon}
                        onClick={() => handleDelete(index)}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {loading && <CustomLoader />} */}
    </>
  );
};

const SelectionCheckBox = ({
  title,
  options,
  handleFeatureSelect,
  selectedOptions,
  source,
}: any) => {
  const [open, setOpen] = useState(false);
  const feature = selectedOptions?.find((f: any) => f.name === title);
  const isSelected = feature && feature.options.length > 0;
  return (
    <div
      className={styles.dropDown}
      onClick={() => setOpen(!open)}
      style={{ backgroundColor: isSelected ? "#0E54A3" : "transparent" }}
    >
      <div className={styles.gap}>
        <img
          src={source}
          className={styles.imageStyle}
          style={{ filter: isSelected ? "brightness(0) invert(1)" : "none" }}
        />
        <p
          className={styles.textSelected}
          style={{ color: isSelected ? "#fff" : "#131313" }}
        >
          {title}
        </p>
      </div>
      <div>
        <FaChevronDown size={16} color={isSelected ? "#fff" : "#131313"} />
      </div>
      {open && (
        <div className={styles.modalDrop}>
          <p className={styles.textSelected2}>Select</p>
          {options.map((option: any) => {
            const feature = selectedOptions.find((f: any) => f.name === title);
            const isChecked = feature
              ? feature.options.includes(option)
              : false;
            return (
              <div key={option} className={styles.checkedbox}>
                <Checkbox
                  checked={isChecked}
                  onChange={() => handleFeatureSelect(title, option)}
                  className={styles.radioMark}
                />
                <p className={styles.textSelected}>{option}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
const ParkingSelection = ({ formik, source }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={styles.dropDown}
      style={{
        backgroundColor: formik?.values?.parking ? "#0E54A3" : "transparent",
      }}
      onClick={() => setOpen(!open)}
    >
      <div className={styles.gap}>
        <img
          src={source}
          style={{
            filter: formik?.values?.parking
              ? "brightness(0) invert(1)"
              : "none",
          }}
          className={styles.imageStyle}
        />
        <p
          className={styles.textSelected}
          style={{ color: formik?.values?.parking ? "#fff" : "#131313" }}
        >
          Parking
        </p>
      </div>
      <div>
        <FaChevronDown
          size={16}
          color={formik?.values?.parking ? "#fff" : "#131313"}
        />
      </div>
      {open && (
        <div className={styles.modalDrop}>
          <p className={styles.textSelected2}>Select</p>
          <div className={styles.checkedbox}>
            <Radio
              value={"Available"}
              checked={formik?.values?.parking === "Available"}
              onChange={() => formik.setFieldValue("parking", "Available")}
              className={styles.radioMark}
            />
            <p className={styles.textSelected}>Available</p>
          </div>
          <div className={styles.checkedbox}>
            <Radio
              value={"Not Available"}
              checked={formik?.values?.parking === "Not Available"}
              onChange={() => formik.setFieldValue("parking", "Not Available")}
              className={styles.radioMark}
            />
            <p className={styles.textSelected}>Not Available</p>
          </div>
        </div>
      )}
    </div>
  );
};
const SomokingBox = ({ title, chargeOption, formik, field }: any) => {
  return (
    <>
      <p className={styles.textSelected}>{title}</p>
      <div className={styles.allowed}>
        <div className={styles.checkedbox1}>
          <Radio
            value={"Allowed"}
            checked={chargeOption === "Allowed"}
            onChange={() => formik.setFieldValue(field, "Allowed")}
            className={styles.radioMark}
          />
          <p className={styles.textSelected3}>Allowed</p>
        </div>
        <div className={styles.checkedbox1}>
          <Radio
            value={"Not Allowed"}
            checked={chargeOption === "Not Allowed"}
            onChange={() => formik.setFieldValue(field, "Not Allowed")}
            className={styles.radioMark}
          />
          <p className={styles.textSelected3}>Not Allowed</p>
        </div>
      </div>
    </>
  );
};

const SaftyDetail = ({
  title,
  options,
  handleOptionChange,
  selectedOptions,
}: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={styles.dropDownbox}
      onClick={() => setOpen(!open)}
      style={{
        backgroundColor: selectedOptions.length > 0 ? "#0E54A3" : "transparent",
      }}
    >
      <div className={styles.gap}>
        <p
          className={styles.textSelected3}
          style={{ color: selectedOptions.length > 0 ? "#fff" : "none" }}
        >
          {title}
        </p>
      </div>
      <div>
        <FaChevronDown
          size={16}
          color={selectedOptions.length > 0 ? "#fff" : "#131313"}
        />
      </div>
      {open && (
        <div className={styles.modalDrop}>
          <p className={styles.textSelected2}>Select</p>
          {options.map((option: any) => {
            return (
              <div key={option} className={styles.checkedbox}>
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionChange(option)}
                  className={styles.radioMark}
                />
                <p className={styles.textSelected}>{option}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OfferHotel;
