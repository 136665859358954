import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./companyLogin.module.css";
import commonStyles from "shared/utils/common.module.css";
import { InputField, PrimaryButton, RingLoader } from "shared/components";
import { Checkbox } from "@mui/material";
import Logo from "assets/images/loginLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddminLoginSchema } from "shared/utils";
import { adminLogin, companyLogin } from "shared/services";
import {
  set_User,
  setIsLoggedIn,
  setSystemType,
  setToken,
  setUser,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CompanyLogin = () => {
  useEffect(() => {
    document.title = "MediTour Global | Admin";
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object(AddminLoginSchema),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });
  const handleLogin = async (values: any) => {
    let params = {
      email: values.email,
      password: values.password,
      ...(fcmToken && { fcmToken }),
    };
    console.log("Login request params:", params);
    companyLogin(params)
      .then((res: any) => {
        console.log("Login response:", res);
        if (res?.data?.auth) {
          dispatch(setToken(res?.data?.token));
          dispatch(setSystemType("company"));
          dispatch(setIsLoggedIn(true));
          dispatch(set_User(res?.data?.docCompany));
          // navigate("/admin/dashboard");
        } else {
          setError("Invalid login credentials. Please try again.");
        }
      })
      .catch((err: any) => {
        console.error("Login error:", err);
        setError(
          err?.response?.data?.message || "An unexpected error occurred."
        );
      })
      .finally(() => setLoading(false));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <div className={classNames(styles.OuterLogin)}>
      <div className={styles.LoginBox}>
        <div className={styles.Outerlogo}>
          <img src={Logo} className={styles.logo} />
        </div>
        <div style={{ textAlign: "center" }}>
          <p
            className={classNames(
              commonStyles.fs40,
              commonStyles.bold,
              styles.mt16,
              styles.primarycolor
            )}
          >
            Login
          </p>
          <p
            className={classNames(
              commonStyles.fs16,
              commonStyles.regular,
              styles.colorgray,
              styles.mt
            )}
            style={{ fontWeight: "400" }}
          >
            Login to access at Company Panel
          </p>
        </div>
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.mt24}>
            <InputField
              id="email"
              formik={formik}
              placeholder="Email"
              onChange={formik?.handleChange("email")}
            />
          </div>
          <div className={styles.mt24}>
            <InputField
              id="password"
              password={true}
              formik={formik}
              placeholder="Password"
              showPassword={passwordVisible}
              togglePassword={() => setPasswordVisible(!passwordVisible)}
              type={passwordVisible ? "text" : "password"}
              onChange={formik?.handleChange("password")}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={classNames(commonStyles.flx, styles.mt8)}>
            <Checkbox
              sx={{ width: "24px", height: "24px" }}
              className={styles.checkBoxx}
            />
            <p
              className={classNames(
                styles.primarycolor,
                commonStyles.fs14,
                commonStyles.semiBold
              )}
            >
              Remember me
            </p>
          </div>
          <div className={classNames(commonStyles.mt56)}>
            <PrimaryButton
              disabled={loading}
              onClick={formik.handleSubmit}
              children={
                loading ? <RingLoader size={35} color={"#fff"} /> : "Login"
              }
              colorType={"admin"}
              type="submit"
            />
          </div>{" "}
        </form>
      </div>
    </div>
  );
};

export default CompanyLogin;
