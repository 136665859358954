import { DoctorLogin } from "pages";
import AmbulanceForget from "pages/HomeServices/AmbulanceServices/Forgetpassword/AmbulanceForget";
import AmbulanceUpdatePassword from "pages/HomeServices/AmbulanceServices/Forgetpassword/AmbulanceUpdatePassword";
import Ambulancelogin from "pages/HomeServices/AmbulanceServices/login";
import AmbulanceSignup from "pages/HomeServices/AmbulanceServices/signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const AmbulanceLoginAuth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<DoctorLogin />} />
    </Routes>
  );
};

export default AmbulanceLoginAuth;
