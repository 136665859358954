import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import style from "./Barchart.module.css";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";

interface Props {
  data3: { name1: string; Profit: number }[];
}

class RentAcarBarChart extends Component<Props> {
  render() {
    const { data3 } = this.props;

    return (
      <div>
        <div style={{ borderBottom: "2px solid #E4E5E7", marginBottom: "8px" }}>
          <p
            style={{ marginBottom: "8px" }}
            className={classNames(
              commonstyle.fs16,
              commonstyle.semiBold,
              commonstyle.colorBlue
            )}
          >
            Total Customer
          </p>
        </div>
        <ResponsiveContainer width="95%" height={280}>
          <BarChart data={data3} stackOffset="sign" barCategoryGap={16}>
            <XAxis dataKey="name1" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            {/* <Tooltip /> */}
            <Bar
              dataKey="Profit"
              stackId="b"
              fill="#1B59F8"
              barSize={15}
              radius={[16, 16, 16, 16]}
              background={{ fill: "#F2F7FF", radius: "16" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default RentAcarBarChart;
