import React from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./HotalPayment.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";
const HotalPayment = () => {
  const { user } = useSelector((state: any) => state.root.common);
  return (
      <div style={{marginTop:"24px"}}>
      <Vendor_Payments type={"Hotel"} id={user?._id} />
    </div>
  );
};

export default HotalPayment;
