import React, { useState } from "react";
import style from "./style.module.css";
import { CardComponent, Cultural } from "shared/components";
import Footerr from "../Footer";
import { useLocation } from "react-router-dom";

const WhyPakistan = () => {
  const { state } = useLocation();
  console.log("🚀 ~ WhyPakistan ~ state:", state);

  return (
    <div>
      <div className={style.scroolContentView}>
        <span className={style.MainHeading}>
          MEDICAL HEALTH TOURISM IN{" "}
          <span className={style.paklistan}>{state?.title} </span>
        </span>
        <div className={style.headingText}>{state?.details?.h1}</div>
        <p className={style.subText}>{state?.details?.h2}</p>
      </div>
      <div className={style.marginCard}>
        {state?.details?.topCards?.map((i: any) => (
          <Cardlayout i={i} />
        ))}
      </div>
      <CardComponent title={state?.title} img={state?.details?.Eco} />
      {state?.details?.culturaldata?.map((item: any) => (
        <Cultural data={item} title={state?.title} />
      ))}
      <div className={style.footerWrapper}>
        <Footerr />
      </div>
    </div>
  );
};

export default WhyPakistan;

const Cardlayout = ({ i }: any) => {
  return (
    <div className={style.cardLayout}>
      <img src={i?.img} className={style.c1} />
      <p className={style.customText}>{i?.title}</p>
      <p className={style.customTextpara}>{i?.subtitle}</p>
    </div>
  );
};
