import React, { useState, useEffect } from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import {
  insuranceHealthMySelfPackageSchema,
  insurancePackageBasicinfo,
} from "shared/utils";
import { setInsuranceHealthParentsPackage } from "shared/redux";
const Medical = [
  "Hospitalization Per person ",
  "Karachi",
  "Multan",
  "Islambad",
];
const Covid = ["Claim Payout Ratio", "Karachi", "Multan", "Islambad"];
interface Props {
  handleClickNext: any;
}
export default function BasicInfoLimits(props: Partial<Props>) {
  const { handleClickNext } = props;
  const { insuranceHealthParentPackage } = useSelector(
    (state: any) => state.root.insurance
  );

  const [logo, setLogo] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      packageName: "",
      packageLogo: "",
      hospitalizationPerPerson: "",
      dailyRoomBoardLimit: "",
      claimPayoutRatio: "",
      packageDescription: "",
    },
    validationSchema: Yup.object(insurancePackageBasicinfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    handleClickNext();
    dispatch(
      setInsuranceHealthParentsPackage({
        ...insuranceHealthParentPackage,
        ...formik.values,
      })
    );
  };
  const handlePackageUrl = (url: any) => {
    setLogo(false);
    formik.setFieldValue("packageLogo", url);
  };
  // useEffect(() => {
  //   formik.setValues(insuranceHealthParentPackage);
  //
  // }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col6)}>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
              <CustomInput
                placeholder="Package Name"
                id="packageName"
                name="packageName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.packageName}
              />
              {formik.touched.packageName && formik.errors.packageName ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.packageName}
                </div>
              ) : null}
            </div>
            <div className={classNames(commonStyles.col6)}>
              <ImgPicker
                placeholder="Package Logo"
                setData={handlePackageUrl}
              />
              {formik.touched.packageLogo && formik.errors.packageLogo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.packageLogo}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Hospitalization Per person "
                id="hospitalizationPerPerson"
                name="hospitalizationPerPerson"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.hospitalizationPerPerson}
              />
              {formik.touched.hospitalizationPerPerson &&
              formik.errors.hospitalizationPerPerson ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.hospitalizationPerPerson}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Daily Room & Board Limit "
                id="dailyRoomBoardLimit"
                name="dailyRoomBoardLimit"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.dailyRoomBoardLimit}
              />
              {formik.touched.dailyRoomBoardLimit &&
              formik.errors.dailyRoomBoardLimit ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.dailyRoomBoardLimit}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Claim Payout Ratio "
                id="claimPayoutRatio"
                name="claimPayoutRatio"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.claimPayoutRatio}
              />
              {formik.touched.claimPayoutRatio &&
              formik.errors.claimPayoutRatio ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.claimPayoutRatio}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Package Description "
                id="packageDescription"
                name="packageDescription"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.packageDescription}
              />
              {formik.touched.packageDescription &&
              formik.errors.packageDescription ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.packageDescription}
                </div>
              ) : null}
            </div>
          </div>
          <div className={commonStyles.BtnWidth}>
            <PrimaryButton
              children={"Submit"}
              colorType={"blue"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
