import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./appointment.module.css";
import { RingLoader } from "shared/components";
import { MdPhone } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

import { TbRefresh } from "react-icons/tb";
import { useSelector } from "react-redux";
import { FaRegClock } from "react-icons/fa6";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { hospitalgetAppointments } from "shared/services/HospitalService";
import dayjs from "dayjs";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

const Hospital_Appointments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [appointments, setAppointments] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);

  const itemsPerPage = 10;
  const [videomodel, setVideomodel] = useState(false);
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.root.common);
  //

  const handleVideoModal = () => {
    setVideomodel(true);
  };
  const handleGoAppDetail = (id: string, index: number) => {
    // navigate(`/${systemType}/appointmentDetails`, { state: { id, index } });
  };

  const [loading, setLoading] = useState(false);

  const fetchAllAppointments = (pagenum: number) => {
    setLoading(true);
    hospitalgetAppointments(pagenum)
      .then((res: any) => {
        setAppointments(res?.data?.Appointments);

        setTotalItems(res?.data?.totalAppoints);
      })
      .catch((err: any) => {
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchAllAppointments(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    fetchAllAppointments(currentPage - 1);
  };

  useEffect(() => {
    setLoading(true);
    fetchAllAppointments(currentPage);
  }, []);

  const handleRefresh = (): void => {
    setCurrentPage(1);
    fetchAllAppointments(1);
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      <>
        <div className={commonstyles.mr87}>
          <div className={styles.outerContainer}>
            <div>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.flxWrap,
                  commonstyles.mb32
                )}
              >
                <div className={commonstyles.flx}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold,
                      commonstyles.colorBlue
                    )}
                  >
                    Appointment
                  </p>
                  {loading ? (
                    <div className={commonstyles.loader}>
                      <RingLoader color={"#0D47A1"} size={30} />
                    </div>
                  ) : (
                    <TbRefresh
                      className={styles.Refresh}
                      onClick={handleRefresh}
                    />
                  )}
                </div>
                <NewPagination
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  startItem={(currentPage - 1) * itemsPerPage + 1}
                  endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                  totalItems={totalItems}
                />
              </div>
              {appointments && appointments?.length > 0 ? (
                <div className={styles.payment}>
                  <div className={styles.headerOuter}>
                    <p className={styles.headerclassStart}>Patient Name</p>
                    <p className={styles.headerclass}>Doctor Name</p>
                    <p className={styles.headerclass}>Time</p>
                    <p className={styles.headerclass}>Date</p>
                    <p className={styles.headerclass}>Type</p>
                    <p className={styles.headerclass}>Status</p>
                  </div>
                  <div className={styles.tableData}>
                    <table style={{ margin: "0%" }}>
                      <tbody className={styles.wapper}>
                        {appointments?.map((apt: any, index: any) => (
                          <tr
                            className={styles.tableRow}
                            key={index}
                            onClick={() => handleGoAppDetail(apt?._id, index)}
                          >
                            <td className={classNames(styles.w20)}>
                              <div className={styles.flxStart}>
                                <Avatar
                                  className={classNames(
                                    styles.aavaatr,
                                    styles.displaynone
                                  )}
                                  src={apt.patientId?.userImage}
                                />
                                <p>{apt.patientId?.name}</p>
                              </div>
                            </td>
                            <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                {/* <MdPhone className={styles.iconss} /> */}
                                <Avatar
                                  className={classNames(
                                    styles.aavaatr,
                                    styles.displaynone
                                  )}
                                  src={apt.doctorId?.doctorImage}
                                />
                                <p>{apt.doctorId?.name}</p>
                              </div>
                            </td>
                            <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                <FaRegClock
                                  className={classNames(
                                    styles.iconss,
                                    styles.displaynone
                                  )}
                                />
                                <p>
                                  {new Date(
                                    apt.appointmentDateAndTime
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                              </div>
                            </td>
                            <td className={styles.w20}>
                              <div className={styles.flxCenter}>
                                <LuCalendarDays
                                  className={classNames(
                                    styles.iconss,
                                    styles.displaynone
                                  )}
                                />
                                <p>
                                  {dayjs(apt.appointmentDateAndTime).format(
                                    "MM-DD-YYYY"
                                  )}
                                </p>
                              </div>
                            </td>
                            <td
                              className={styles.w20}
                              style={{ textTransform: "capitalize" }}
                            >
                              {apt.appointmentType}
                            </td>
                            <td className={styles.w20}>
                              <div className={commonstyles.flxCenter}>
                                {apt?.status}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div>{!loading && <PhysiotheristsEmpty />}</div>
              )}
            </div>
          </div>
        </div>

        {/* <CustomModal showModal={videomodel} children={<VideoCall />} /> */}
      </>
    </div>
  );
};

export default Hospital_Appointments;

// export const VideoCall = () => {
//   return (
//     <div>
//       {" "}
//       <ZegoCloudComponent />{" "}
//     </div>
//   );
// };
