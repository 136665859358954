import React, { useState } from "react";
import classNames from "classnames";
import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import profile from "assets/images/HospitalDashboard/Rectangle 1.png";
import CustomSelectOrder from "pages/Laboratory/Orders/CustomSelectOrder";
import { CustomSelector } from "pages/Doctor/signup/Component/BasicInfo";
import { InputField, PrimaryButton, RingLoader } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { add_File, settignUPdate, updateProfile } from "shared/services";
import toast from "react-hot-toast";
import image1 from "assets/images/ProfileDownload.png";
import { set_User } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
const HospitalSetting = () => {
  const { user } = useSelector((state: any) => state?.root?.common);
  const [profileImage, setProfileImage] = useState<string>("");
  const [valueName, setName] = useState("");
  const [url, setUrl] = useState<any>("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurentPassword, setShowCurentPassword] = useState(false);
  const handleFileChange = (e: any) => {
    const file = e.target.files?.[0];
    console.log(file, ".....");
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        setProfileImage(reader.result as string);
        try {
          const formData = new FormData();
          if (file) {
            formData.append("file", file);
          }
          const response = await add_File(formData);
          setUrl(response?.data?.fileUrl);
          let params = {
            logo: response?.data?.fileUrl,
          };
          console.log("🚀 ~ params ~ file:", params);
          settignUPdate(params)
            .then((res: any) => {
              notifySuccess("Profile Updated Successfully");
              dispatch(
                set_User({
                  ...user,
                  logo: response?.data?.fileUrl,
                })
              );
            })
            .catch((err: any) => {
              console.log("🚀 ~ reader.onloadend= ~ err:", err);
            });
          setLoading(false);
        } catch (error) {
          console.error("Upload error:", error);
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveChange = () => {
    if (!valueName && !currentPassword) {
      toast.error("Please update at least one field.");
      return;
    }
    if (currentPassword) {
      if (!newPassword) {
        toast.error("New Password is required");
        return;
      }
      if (!confirmPassword) {
        toast.error("Confirm Password is required");
        return;
      }
      if (newPassword === currentPassword) {
        toast.error("New Password should not be the same as Current Password");
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error("New Password and Confirm Password must match");
        return;
      }
    }
    setLoading(true);
    let params = {
      ...(valueName && { name: valueName }),
      ...(currentPassword &&
        newPassword && {
          currentPassword: currentPassword,
          password: newPassword,
        }),
    };
    console.log(params, "....params");
    settignUPdate(params)
      .then((res: any) => {
        notifySuccess(res?.data?.message);
        dispatch(set_User(res?.data?.hospital));
      })
      .catch((err: any) => {
        console.log(err, "...error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(style.container)}>
      <div className={classNames(commonstyle.mt16, commonstyle.mb16)}>
        <p className={classNames(style.heading)}>Settings</p>
      </div>
      <div className={classNames(style.profileconatiner)}>
        <div>
          <p className={style.profileName}>Profile Picture</p>
          <div className={style.profilePage__imageWrapper}>
            <img
              src={
                user?.logo ||
                profileImage ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
              }
              // alt={user?.name}
              className={style.profilePage__image}
            />
            <div className={style.profilePage__smallImageWrapper}>
              <img
                src={image1}
                alt="Edit"
                className={style.profilePage__editIcon}
                onClick={() => document.getElementById("fileInput")?.click()}
              />
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className={style.profileView}>
            <p className={style.profileName}>Profile Info</p>

            <div
              className={classNames(
                commonstyle.flx,
                commonstyle.flxWrap,
                commonstyle.flxBetween
              )}
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                gap: "18px",
                // alignItems: "center",
                marginTop: "24px",
                // width: "100%",
              }}
            >
              <div
                className={classNames(
                  commonstyle.col5,
                  commonstyle.colmd12,
                  commonstyle.colsm12
                )}
              >
                <InputField
                  id="valueName"
                  value={valueName}
                  placeholder={user?.name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </div>
              <div
                className={classNames(
                  commonstyle.col5,
                  commonstyle.colmd12,
                  commonstyle.colsm12
                )}
              >
                <InputField
                  readOnly
                  id="email"
                  value={user?.email}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className={style.changePassword}>Change Password</div>
            <div
              className={classNames(
                commonstyle.col5,
                commonstyle.colmd12,
                commonstyle.colsm12
              )}
            >
              <InputField
                id="currentPassword"
                password={true}
                placeholder="Curent Password"
                showPassword={showCurentPassword}
                togglePassword={() =>
                  setShowCurentPassword(!showCurentPassword)
                }
                type={showCurentPassword ? "text" : "password"}
                onChange={(e: any) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div
              className={classNames(
                commonstyle.flx,
                commonstyle.flxWrap,
                commonstyle.flxBetween
              )}
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                gap: "18px",
                // alignItems: "center",
                marginTop: "24px",
                // width: "100%",
              }}
            >
              <div
                className={classNames(
                  commonstyle.col5,
                  commonstyle.colmd12,
                  commonstyle.colsm12
                )}
              >
                <InputField
                  id="newPassword"
                  password={true}
                  placeholder="New Password"
                  showPassword={showPassword}
                  togglePassword={() => setShowPassword(!showPassword)}
                  type={showPassword ? "text" : "password"}
                  onChange={(e: any) => setNewPassword(e.target.value)}
                />
              </div>
              <div
                className={classNames(
                  commonstyle.col5,
                  commonstyle.colmd12,
                  commonstyle.colsm12
                )}
              >
                <InputField
                  id="confirmPassword"
                  password={true}
                  placeholder="Confirm Password"
                  showPassword={showConfirmPassword}
                  togglePassword={() =>
                    setShowConfirmPassword(!showConfirmPassword)
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className={style.saveButton} onClick={saveChange}>
                Save Changes
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default HospitalSetting;
