import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import RingLoader from "../RingLoader";
import style from "./DeleteModalStyle.module.css";
import { IoClose } from "react-icons/io5";

interface Props {
  modalVisible?: any;
  handleDelete?: any;
  handleCancel?: any;
  loading?: any;
  title?: any;
  content?: any;
  confirmText?: any;
}

const DeleteModal = (props: Props) => {
  const {
    modalVisible,
    handleDelete,
    handleCancel,
    loading,
    title,
    content,
    confirmText,
  } = props;
  return (
    modalVisible && (
      <div className={classNames(style.modalBackdrop)}>
        <div className={classNames(style.modalContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IoClose className={style.closeicon} onClick={handleCancel} />
          </div>
          <div className={classNames(commonstyle.flx, commonstyle.flxCol)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              Are You sure?
            </p>
            <p
              className={classNames(
                commonstyle.colorGray,
                commonstyle.fs16,
                commonstyle.semiBold
              )}
            >
              {content
                ? content
                : `You want to delete this ${title ? title : "Request"}`}
            </p>
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <button className={style.cancelbtn} onClick={handleCancel}>
                No Cancel
              </button>
              <button className={style.dltbtn} onClick={handleDelete}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : confirmText ? (
                  confirmText
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DeleteModal;
