import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Physioappointment.module.css";
import { IoArrowBack, IoClose, IoVideocam } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle, FaRegClock } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LuCalendarDays } from "react-icons/lu";
import Typography from "@mui/material/Typography";
import commonStyles from "shared/utils/common.module.css";
import {
  CustomInput,
  CustomMenu,
  CustomModal,
  DeleteModal,
  Medicine_Table,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonstyle from "shared/utils/common.module.css";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Checkbox } from "@mui/material";
import {
  doctorAppointmentAddTestSchema,
  doctorAppointmentAddmedicineSchema,
  doctorAppointmentHistory,
} from "shared/utils";
import {
  DoctorAddAppointmentHistory,
  DoctorAddAppointmentPrescription,
  DoctorGetSingleAppointment,
  ReferToDoctor,
  closeAppointment,
  doctorSearchProduct,
  getAllHospitals,
  getAllSpecialities,
  getDoctorSearch,
} from "shared/services/DoctorService";
import ZegoCloudComponent from "shared/components/Zegocloud";
import DoctorReferModel from "shared/components/DoctorEmpty/DoctorReferModel";
import { MdDeleteForever } from "react-icons/md";
import { getAllTests } from "shared/services";
import dayjs from "dayjs";
import NewPagination from "shared/components/NewPagination/NewPagination";
import Room from "pages/Room";
import { setPateintData, setStartCall } from "shared/redux";
import { RxCross2 } from "react-icons/rx";

const dosageData = [
  { title: "As Directed", value: "As Directed" },
  { title: "1/2 Tablet", value: "1/2 Tablet" },
  { title: "1/4  Tablet", value: "1/4  Tablet" },
  { title: "1 Tablet", value: "1 Tablet" },
  { title: "2 Tablet", value: "2 Table" },
  { title: "3 Tablet", value: "3 Table" },
  { title: "4 Tablet", value: "4 Table" },
  { title: "1 Capsule", value: "1 Capsule" },
  { title: "2 Capsule", value: "2 Capsule" },
];

const routeData = [
  { title: "As Directed", value: "As Directed" },
  { title: "Oral (PO)", value: "Oral (PO)" },
  { title: "Intravenous (IV)", value: "Intravenous (IV)" },
  { title: "Intramuscular (IM)", value: "Intramuscular (IM)" },
  { title: "Subcutaneous (SC)", value: "Subcutaneous (SC)" },
  { title: "Topical", value: "Topical" },
  { title: "Transdermal", value: "Transdermal" },
  { title: "Inhalation", value: "Inhalation" },
  { title: "Sublingual (SL)", value: "Sublingual (SL)" },
  { title: "Buccal", value: "Buccal" },
  { title: "Rectal", value: "Rectal" },
  { title: "Vaginal", value: "Vaginal" },
  { title: "Ophthalmic", value: "Ophthalmic" },
  { title: "Otic", value: "Otic" },
  { title: "Nasal", value: "Nasal" },
];

const frequencyData = [
  { title: "Morning", value: "Morning", count: 1 },
  { title: "Noon", value: "Noon", count: 1 },
  { title: "Evening", value: "Evening", count: 1 },
  { title: "Night", value: "Night", count: 1 },

  { title: "Morning + Noon", value: "Morning + Noon", count: 2 },
  { title: "Morning + Evening", value: "Morning + Evening", count: 2 },
  { title: "Morning + Night", value: "Morning + Night", count: 2 },
  { title: "Noon + Evening", value: "Noon + Evening", count: 2 },
  { title: "Evening + Night", value: "Evening + Night", count: 2 },

  {
    title: "Morning + Noon + Evening",
    value: "Morning + Noon + Evening",
    count: 3,
  },
  {
    title: "Morning + Noon + Night",
    value: "Morning + Noon + Night",
    count: 3,
  },

  {
    title: "Morning + Evening + Night",
    value: "Morning + Evening + Night",
    count: 3,
  },
];

const daysData = Array.from({ length: 20 }, (_, i) => ({
  title: `${i + 1}`,
  value: `${i + 1}`,
}));

const istructionsData = [
  { title: "Before Breakfast", value: "Before Breakfast" },
  { title: "After Breakfast", value: "After Breakfast" },
  { title: "Before Lunch", value: "Before Lunch" },
  { title: "After Lunch", value: "After Lunch" },
  { title: "Before Dinner", value: "Before Dinner" },
  { title: "After Dinner", value: "After Dinner" },
  { title: "Before Bedtime", value: "Before Bedtime" },
];

export default function DoctorAppointmentDetails() {
  const [showPrescriptionModel, setShowPrescriptionModel] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [doctorid, setDoctorid] = useState<string | undefined>();
  const [pateintid, setPateintid] = useState<string | undefined>();
  const [medicinedata, setMedicineData] = useState<any>([]);
  const [testdata, setTestData] = useState<any>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isMedicineOpen, setMedicineOpen] = useState(false);
  const [isTestOpen, setTestOpen] = useState(false);
  const [error, setError] = React.useState("");
  const [referMenu, setReferMenu] = useState(false);
  const [selectedReferType, setSelectedReferType] = useState("");
  const [selectedFrequesncyCount, setSelectedFrequesncyCount] = useState(0);
  interface FullPatient {
    _id: string;
    name: string;
    email: string;
    gender: string;
    dateOfBirth: string;
    userImage: string;
  }
  const [tags, setTags] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [fullpatient, setFullpatient] = useState<FullPatient | null>(null);
  const [successmodal, setSuccessmodal] = useState<any>(false);
  const [selectedMed, setSelectedMed] = useState<any>({});
  const { state } = useLocation();
  let id = state.id;
  let index = state.index;

  interface Appointment {
    appointmentDateAndTime?: string;
    date?: string;
    appointmentType?: string;
    _id?: string;
    history?: any;
    createdAt?: any;
    ePrescription?: any;
  }
  interface Medicine {
    medicineName: string;
    medicineBrand: string;
    medicineStrength: string;
    dosage: string;
  }
  interface Test {
    testName: string;
  }
  const [appointment, setAppointment] = useState<Appointment>({});

  const navigate = useNavigate();
  const { systemType, user } = useSelector((state: any) => state.root.common);
  useEffect(() => {
    if (appointment?.ePrescription) {
      setMedicineData(appointment?.ePrescription?.medicines);
      setTestData(appointment?.ePrescription?.test);
    }
  }, [appointment]);

  const handleGoBack = () => {
    navigate(`/${systemType}/appointment`);
  };

  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }

  useEffect(() => {
    if (successmodal) {
      const timer = setTimeout(() => {
        setSuccessmodal(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successmodal]);

  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setIsAccordionOpen(isExpanded);
    setReferMenu(false);
  };

  const handleMedicineClick = () => {
    setMedicineOpen(!isMedicineOpen);
    setTestOpen(false);
  };

  const handleTestClick = () => {
    setTestOpen(!isTestOpen);
    setMedicineOpen(false);
  };

  const fetchSingleAppointment = (id: string) => {
    setLoading(true);
    DoctorGetSingleAppointment(id)
      .then((res) => {
        setAppointment(res.data.appointment);
        setDoctorid(res.data.appointment.doctorId);
        setPateintid(res.data.appointment.patientId._id);
        setFullpatient(res.data.appointment.patientId);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      symptoms: "",
      description: "",
      diastolicPressure: "",
      systolicPressure: "",
      weight: "",
      diseases: [],
      temperature: "",
      heartRate: "",
      sugar: "",
    },
    validationSchema: Yup.object(doctorAppointmentHistory),
    onSubmit: (values, { resetForm }) => {
      handleSubmit();
      resetForm();
    },
  });
  const handleSubmit = () => {
    let currentdata = formik.values;

    setLoading(true);

    let params = {
      patientId: pateintid,
      appointmentId: appointment?._id,
    };
    let body = {
      symptoms: currentdata.symptoms,
      description: currentdata.description,
      bloodPressure: {
        diastolicPressure: Number(currentdata.diastolicPressure) || 0,
        systolicPressure: Number(currentdata.systolicPressure) || 0,
      },
      weight: currentdata.weight,
      diseases: currentdata?.diseases,
      temperature: currentdata?.temperature,
      heartRate: currentdata?.heartRate,
      sugar: currentdata?.sugar,
    };

    DoctorAddAppointmentHistory(body, params)
      .then((res) => {
        fetchSingleAppointment(id);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const formikAddMedicine: any = useFormik({
    initialValues: {
      medicineId: "",
      medicineName: "",
      dosage: "",
      route: "",
      frequency: "",
      instruction: "",
      days: "",
      quantity: 1,
    },
    validationSchema: Yup.object({
      medicineId: Yup.string(),
      medicineName: Yup.string(),
      dosage: Yup.string().required("Required"),
      route: Yup.string().required("Required"),
      frequency: Yup.string().required("Required"),
      instruction: Yup.string().required("Required"),
      days: Yup.number().required("Required").min(1, "At least 1 day"),
      quantity: Yup.number().required("Required").min(1, "At least 1"),
    }),
    onSubmit: (values: any) => {
      // setEntries((prevEntries: any) => [...prevEntries, values]);
      handleSubmittwo();
      formikAddMedicine?.resetForm(); // Reset the form after submission
    },
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      handleSearchProduct(query);
      // const filteredSuggestions = allMedicines.filter((item) =>
      //   item.toLowerCase().includes(query.toLowerCase())
      // );
      // setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleTestSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = event.target.value;
    setTestSearchQuery(query);

    if (query) {
      fetchAllTests(query);
    } else {
      setTestSuggestions([]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        formik.setFieldValue("diseases", [
          ...formik.values.diseases,
          trimmedValue,
        ]);
        setInputValue("");
      }
    }
  };
  const removeTag = (index: number) => {
    formik.setFieldValue(
      "diseases",
      formik.values.diseases.filter((_, i) => i !== index)
    );
  };
  const handleTestSuggestionClick = (suggestion: any) => {
    setTestSearchQuery(suggestion?.name);
    formikAddTest.setFieldValue("testName", suggestion?.name);
    formikAddTest.setFieldValue("testId", suggestion?._id);
    setTestSuggestions([]);
  };

  const fetchAllTests = (search: string) => {
    getAllTests(1, search)
      .then((res: any) => {
        setTestSuggestions(res?.data?.data);
      })
      .catch((err: any) => {});
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchQuery(suggestion?.productName);
    setSelectedMed(suggestion);
    formikAddMedicine.setFieldValue("medicineId", suggestion._id);
    formikAddMedicine.setFieldValue("medicineName", suggestion?.productName);
    if (suggestion?.productType == "Syrup") {
      formikAddMedicine.setFieldValue("quantity", 1);
    }
    setSuggestions([]);
  };

  const handleSearchProduct = (keyword: any) => {
    doctorSearchProduct(keyword)
      .then((res: any) => {
        setSuggestions(res?.data?.products);
      })
      .catch((err: any) => {});
  };

  const handleSubmittwo = () => {
    const newMedicine = formikAddMedicine.values;

    let med_clone = JSON.parse(JSON.stringify(medicinedata));

    med_clone = [...med_clone, newMedicine];
    setSearchQuery("");
    setMedicineData(med_clone);
  };

  const formikAddTest = useFormik({
    initialValues: {
      testName: "",
      testId: "",
    },
    validationSchema: Yup.object(doctorAppointmentAddTestSchema),
    onSubmit: (values, { resetForm }) => {
      handleSubmitAddTest();
      resetForm();
    },
  });
  const deleteEntry = (index: number) => {
    const newItems = medicinedata.filter((_: any, i: number) => i !== index);
    setMedicineData(newItems);
  };

  const handleDeleteTest = (index: any) => {
    const newItems = testdata.filter((_: any, i: number) => i !== index);
    setTestData(newItems);
  };

  //add test
  const handleSubmitAddTest = () => {
    const { testName, testId } = formikAddTest.values;

    const newTest = {
      testName,
      testId,
    };
    let test_clone = JSON.parse(JSON.stringify(testdata));
    test_clone = [...test_clone, newTest];
    setTestData(test_clone);
    setTestSearchQuery("");
  };

  interface PrescriptionParams {
    medicines?: Medicine[];
    test?: Test[];
  }

  // add perscription
  const AddPrescription = () => {
    let apt_clone = JSON.parse(JSON.stringify(appointment));

    if ((medicinedata?.length ?? 0) === 0 && (testdata?.length ?? 0) === 0) {
      setError("Required Test or Medicine");
      return;
    }
    let params: PrescriptionParams = {};
    if (medicinedata?.length > 0) {
      params.medicines = medicinedata;
    }
    if (testdata?.length > 0) {
      params.test = testdata;
    }

    if (id && pateintid) {
      setLoading(true);

      DoctorAddAppointmentPrescription(id, pateintid, params)
        .then((res) => {
          setError("");
          handleOpenModel();
          setMedicineData([]);
          setTestData([]);
          apt_clone.ePrescription = res?.data?.prescription;
          setAppointment(apt_clone);
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const [goBack, setGoBack] = useState(false);
  const handleCloseAppointment = () => {
    setCloseLoading(true);
    closeAppointment(id)
      .then((res: any) => {
        setGoBack(true);
        navigate(`/${systemType}/appointment`);
      })
      .catch((error: any) => {
        setError(error?.response?.data?.message);
      })
      .finally(() => setCloseLoading(false));
  };
  useEffect(() => {
    if (id) {
      fetchSingleAppointment(id);
    }
  }, []);
  const handleOpenModel = () => {
    setShowPrescriptionModel(true);
  };
  useEffect(() => {
    if (showPrescriptionModel) {
      const timer = setTimeout(() => {
        setShowPrescriptionModel(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showPrescriptionModel]);
  interface Entry {
    id: number;
    category: string;
    dosage: string;
    route: string;
    frequency: string;
    instruction: string;
    days: number;
    quantity: number;
  }
  const handleReferItemClick = (item: any) => {
    setSelectedReferType(item);
    setReferMenu(false);
    setShowAddModal(true);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [testSearchQuery, setTestSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [testSuggestions, setTestSuggestions] = useState<string[]>([]);
  const [showCustomDosageModal, setShowCustomDosageModal] = useState(false);
  const [showCustomInstructionModal, setShowCustomInstructionModal] =
    useState(false);
  const [videomodel, setVideomodel] = useState(false);
  const [showRoom, setShowRoom] = useState<any>(false);
  const dispatch = useDispatch();
  const handleVideoModal = (item: any) => {
    setVideomodel(true);
    // setPateintDta({ name: fullpatient?.name, id: id });
    dispatch(setPateintData({ name: fullpatient?.name, id: id }));
  };
  const handleSend = () => {
    setVideomodel(false);
    setShowRoom(true);
    dispatch(setStartCall(true));
    // navigate(`/Meeting/Room`, { state: { pateintData } });
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil(
      (scrollTop / (scrollHeight - clientHeight)) * 100
    );
    setScrollPosition(position);
  };
  console.log(appointment, "........appointment");
  return (
    <div
      // ref={ref}
      onScroll={handleScroll}
      style={{
        overflowY: "scroll",
      }}
      className={classNames(commonstyles.col12)}
    >
      {!showRoom && <SearchBar />}

      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          {!showRoom && (
            <div
              className={classNames(
                commonstyles.flx,
                commonStyles.flxWrap,
                commonstyles.mb32
              )}
            >
              <div>
                <IoArrowBack className={styles.back} onClick={handleGoBack} />
              </div>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Appointment Details
              </p>
              {loading && (
                <div className={commonstyles.loader}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              )}

              <div className={classNames(commonstyles.flx, styles.refer)}>
                <BiSolidMessageSquareAdd className={styles.iconBlue} />

                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setReferMenu(true);
                  }}
                >
                  Refer
                </p>
              </div>
              <div>
                <CustomMenu
                  open={referMenu}
                  setOpen={setReferMenu}
                  menuItems={["Doctor", "Specialities", "Hospital"]}
                  onClickItem={handleReferItemClick}
                />
              </div>
            </div>
          )}

          <div className={styles.docInfoCard}>
            <div style={{ textAlign: "center" }}>
              <div className={commonStyles.flx}>
                <Avatar
                  alt=""
                  src={fullpatient?.userImage}
                  className={classNames(commonStyles.mr16)}
                />
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                  style={{ textTransform: "capitalize" }}
                >
                  {fullpatient?.name}
                </p>
              </div>

              <div className={classNames(commonstyles.flxBetween, styles.mt16)}>
                <div className={classNames(commonstyles.flx, styles.mr32)}>
                  <p className={classNames(commonstyles.fs12, styles.mr8)}>
                    Age:
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.medium
                    )}
                  >
                    {calculateAge(fullpatient?.dateOfBirth ?? "")}
                  </p>
                </div>
                <div className={classNames(commonstyles.flx, styles.mr16)}>
                  {" "}
                  <p className={classNames(commonstyles.fs12, styles.mr8)}>
                    Gender:
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.medium
                    )}
                  >
                    {fullpatient?.gender}
                  </p>
                </div>
              </div>
              {!showRoom && (
                <div className={classNames(commonstyles.flxEvenly)}>
                  {appointment?.appointmentType == "video" && (
                    <div className={styles.appCards} onClick={handleVideoModal}>
                      <IoVideocam className={styles.iccons} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <div className={styles.flxIcons}>
                <LuCalendarDays className={styles.iconxx} />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  {dayjs(appointment.appointmentDateAndTime).format(
                    "MM-DD-YYYY"
                  )}
                </p>
              </div>

              <div className={classNames(styles.flxIcons, styles.mt8)}>
                <FaRegClock className={styles.iconxx} />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  {appointment &&
                    appointment.appointmentDateAndTime &&
                    new Date(
                      appointment.appointmentDateAndTime
                    ).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                </p>
              </div>
              <div className={styles.mt16}>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    commonstyles.colorOrange
                  )}
                  style={{ textTransform: "capitalize" }}
                >
                  {appointment?.appointmentType}
                </p>
              </div>
            </div>
          </div>
          {showRoom && (
            <Room
              setShowRoom={setShowRoom}
              goback={goBack}
              isScrolled={scrollPosition}
              setScrollPosition={setScrollPosition}
            />
          )}

          <div>
            <Accordion className={styles.dropdownApp}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.colorBlue,
                        commonstyles.semiBold
                      )}
                    >
                      History
                    </p>
                  </div>
                </Typography>
              </AccordionSummary>
              <div className={styles.border}></div>
              <AccordionDetails>
                <Typography>
                  <div className={commonstyles.colorBlue}>
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={classNames(
                          commonStyles.flx,
                          commonStyles.mt16,
                          commonStyles.flxWrap
                        )}
                      >
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <div className={commonStyles.mr32}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.semiBold
                              )}
                            >
                              Systolic BP (mmhg) *Range(60-250)
                            </p>
                            <CustomInput
                              placeholder="Enter Blood Presure here."
                              id="systolicPressure"
                              name="systolicPressure"
                              type="text"
                              onChange={formik.handleChange}
                              value={formik.values.systolicPressure}
                            />
                            {formik.touched.systolicPressure &&
                            formik.errors.systolicPressure ? (
                              <div className={classNames(commonStyles.error)}>
                                *{formik.errors.systolicPressure}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <p
                            className={classNames(
                              commonstyles.fs16,
                              commonstyles.semiBold
                            )}
                          >
                            Diastolic BP (mmhg) *Range(50-150)
                          </p>
                          <CustomInput
                            placeholder="Enter Blood Presure here."
                            id="diastolicPressure"
                            name="diastolicPressure"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.diastolicPressure}
                          />
                          {formik.touched.diastolicPressure &&
                          formik.errors.diastolicPressure ? (
                            <div className={classNames(commonStyles.error)}>
                              *{formik.errors.diastolicPressure}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <CustomInput
                          placeholder="Enter diseases... (Press Enter to add)"
                          type="text"
                          ondown={handleKeyDown}
                          onChange={(e: any) => setInputValue(e.target.value)}
                          value={inputValue}
                        />
                         {/* <small style={{ color: "gray" }}>Press Enter to add disease</small> */}
                        {formik.touched.diseases && formik.errors.diseases ? (
                          <div className={classNames(commonStyles.error)}>
                            *{formik.errors.diseases}
                          </div>
                        ) : null}
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          {formik?.values?.diseases.map(
                            (tag: any, index: any) => (
                              <div
                                key={index}
                                style={{
                                  padding: "4px 16px",
                                  background: "#007bff",
                                  borderRadius: "16px",
                                  cursor: "pointer",
                                  backgroundColor: "#0E54A3",
                                  color: "#fff",
                                  fontSize: "12px",
                                  display: "flex",
                                  gap: "4px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div>{tag}</div>
                                <RxCross2
                                  size={16}
                                  color="#fff"
                                  onClick={() => removeTag(index)}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          commonStyles.flx,
                          commonStyles.mt16,
                          commonStyles.flxWrap
                        )}
                      >
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <div className={commonStyles.mr32}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.semiBold
                              )}
                            >
                              Temperature
                            </p>
                            <CustomInput
                              placeholder="Enter Temprature here."
                              id="temperature"
                              name="temperature"
                              type="text"
                              onChange={formik.handleChange}
                              value={formik.values.temperature}
                            />
                            {formik.touched.temperature &&
                            formik.errors.temperature ? (
                              <div className={classNames(commonStyles.error)}>
                                *{formik.errors.temperature}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <p
                            className={classNames(
                              commonstyles.fs16,
                              commonstyles.semiBold
                            )}
                          >
                            Heart Rate
                          </p>
                          <CustomInput
                            placeholder="Enter Heart Rate here."
                            id="heartRate"
                            name="heartRate"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.heartRate}
                          />
                          {formik.touched.heartRate &&
                          formik.errors.heartRate ? (
                            <div className={classNames(commonStyles.error)}>
                              *{formik.errors.heartRate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          commonStyles.flx,
                          commonStyles.mt16,
                          commonStyles.flxWrap
                        )}
                      >
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <div className={commonStyles.mr32}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.semiBold
                              )}
                            >
                              Weight (KG)* (2-180)
                            </p>
                            <CustomInput
                              placeholder="Enter your weight here."
                              id="weight"
                              name="weight"
                              type="text"
                              onChange={formik.handleChange}
                              value={formik.values.weight}
                            />
                            {formik.touched.weight && formik.errors.weight ? (
                              <div className={classNames(commonStyles.error)}>
                                *{formik.errors.weight}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <div className={commonStyles.mr32}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.semiBold
                              )}
                            >
                              Sugar
                            </p>
                            <CustomInput
                              placeholder="Enter your Sugar here."
                              id="sugar"
                              name="sugar"
                              type="text"
                              onChange={formik.handleChange}
                              value={formik.values.sugar}
                            />
                            {formik.touched.sugar && formik.errors.sugar ? (
                              <div className={classNames(commonStyles.error)}>
                                *{formik.errors.sugar}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          commonStyles.flx,
                          commonStyles.mt24,
                          commonStyles.flxWrap
                        )}
                      >
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <div className={commonStyles.mr32}>
                            <p
                              className={classNames(
                                commonstyles.fs16,
                                commonstyles.semiBold,
                                commonStyles.mb16
                              )}
                            >
                              Symptoms
                            </p>
                            <textarea
                              placeholder="Write symptoms here"
                              id="symptoms"
                              name="symptoms"
                              onChange={formik.handleChange}
                              value={formik.values.symptoms}
                              style={{
                                width: "100%",
                                overflow: "auto",
                                backgroundColor: "rgb(226, 240, 248)",
                                padding: "5px",
                              }}
                            />
                            {formik.touched.symptoms &&
                            formik.errors.symptoms ? (
                              <div className={classNames(commonStyles.error)}>
                                *{formik.errors.symptoms}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className={classNames(
                            commonStyles.col6,
                            commonStyles.colsm12,
                            commonStyles.mtsm28
                          )}
                        >
                          <p
                            className={classNames(
                              commonstyles.fs16,
                              commonstyles.semiBold,
                              commonStyles.mb16
                            )}
                          >
                            Description about patient
                          </p>
                          <textarea
                            placeholder="Write description here"
                            id="description"
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            style={{
                              padding: "5px",
                              width: "100%",
                              overflow: "auto",
                              backgroundColor: "rgb(226, 240, 248)",
                            }}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classNames(commonStyles.error)}>
                              *{formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "32px",
                          width: "160px",
                          cursor: "pointer",
                        }}
                      >
                        <PrimaryButton
                          children={
                            loading ? (
                              <RingLoader size={35} color={"#fff"} />
                            ) : (
                              "Save"
                            )
                          }
                          colorType={"green"}
                          type="submit"
                        />
                      </div>
                    </form>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className={styles.dropdownApp2}
              style={{ marginBottom: "16px" }}
              expanded={isAccordionOpen}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    width: "100%",
                  }}
                >
                  <div
                    className={classNames(
                      commonstyles.flx,
                      commonStyles.flxBetween,
                      commonStyles.flxWrap
                    )}
                  >
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.colorBlue,
                        commonstyles.semiBold
                      )}
                    >
                      Prescription
                    </p>
                    <div className={classNames(commonStyles.flx)}>
                      {isAccordionOpen && (
                        <>
                          <div className={commonstyles.flx}>
                            <BiSolidMessageSquareAdd
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMedicineClick();
                              }}
                            />
                            <p
                              className={classNames(
                                commonstyles.fs14,
                                commonstyles.colorOrange,
                                commonstyles.pointer
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMedicineClick();
                              }}
                            >
                              Add Medicine
                            </p>
                          </div>
                          <div className={commonstyles.flx}>
                            <BiSolidMessageSquareAdd
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTestClick();
                              }}
                            />

                            <p
                              className={classNames(
                                commonstyles.fs14,
                                commonstyles.colorBlue
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTestClick();
                              }}
                            >
                              Add Test
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <div className={styles.border}></div>
              <AccordionDetails className={styles.PrescriptionColor}>
                <Typography>
                  <div className={commonstyles.colorBlue}>
                    <div className={(styles.flx, commonStyles.mt16)}>
                      <div style={{ width: "100%" }}>
                        {isMedicineOpen && (
                          <form onSubmit={formikAddMedicine.handleSubmit}>
                            <div>
                              <p
                                className={classNames(
                                  commonStyles.fs16,
                                  commonStyles.colorBlue,
                                  commonStyles.semiBold
                                )}
                              >
                                Medicine / Vaccine / Surgical Tools / Injections
                              </p>

                              <div
                                style={{
                                  width: "98%",
                                }}
                              >
                                <input
                                  type="text"
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                                  placeholder="Search Medicine / Vaccine / Surgical Tools / Injections"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    border: "2px solid rgb(0, 39, 109)",
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </div>

                              {suggestions?.length > 0 && (
                                <div
                                  className={styles.searchResults}
                                  style={{
                                    backgroundColor: "white",
                                    zIndex: 10,
                                    marginTop: -10,
                                  }}
                                >
                                  <ul>
                                    {suggestions.map(
                                      (suggestion: any, index) => (
                                        <li
                                          key={index}
                                          className={styles.suggestionItem}
                                          onClick={() =>
                                            handleSuggestionClick(suggestion)
                                          }
                                          style={{
                                            listStyleType: "none",
                                          }}
                                        >
                                          {suggestion?.productName}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1, marginRight: "10px" }}>
                                <div className={classNames(commonstyles.flx)}>
                                  <p
                                    className={classNames(
                                      commonstyles.fs16,
                                      commonstyles.colorBlue,
                                      commonstyles.semiBold,
                                      commonStyles.mr24
                                    )}
                                  >
                                    Dosage
                                  </p>
                                  <p
                                    className={classNames(
                                      commonstyles.fs12,
                                      commonstyles.colorOrange,
                                      commonstyles.medium,
                                      commonStyles.underLine
                                    )}
                                    onClick={() =>
                                      setShowCustomDosageModal(true)
                                    }
                                  >
                                    Add Custom
                                  </p>
                                </div>
                                <CustomModal showModal={showCustomDosageModal}>
                                  <div style={{ width: "420px" }}>
                                    <CustomInput
                                      placeholder="Enter custom dosage"
                                      id="dosage"
                                      name="dosage"
                                      type="text"
                                      onChange={formikAddMedicine.handleChange}
                                      value={formikAddMedicine.values.dosage}
                                    />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.mt16,
                                      commonStyles.col4
                                    )}
                                  >
                                    <PrimaryButton
                                      colorType={"green"}
                                      children={"Close"}
                                      type="button"
                                      onClick={() => {
                                        setShowCustomDosageModal(false);
                                      }}
                                    />
                                  </div>
                                </CustomModal>
                                <select
                                  id="dosage"
                                  name="dosage"
                                  onChange={formikAddMedicine.handleChange}
                                  value={formikAddMedicine.values.dosage}
                                  className={classNames(styles.customSelect)}
                                  style={{
                                    width: "100%",
                                    border: "2px solid rgb(0, 39, 109)",
                                    borderRadius: "3px",
                                    padding: "10px",
                                  }}
                                >
                                  <option value="">
                                    {formikAddMedicine.values.dosage
                                      ? formikAddMedicine.values.dosage
                                      : "Select Dose"}
                                  </option>
                                  {dosageData.map((v: any, ind: number) => (
                                    <option key={ind} value={v.value}>
                                      {v.title}
                                    </option>
                                  ))}
                                </select>
                                {formikAddMedicine.touched.dosage &&
                                formikAddMedicine.errors.dosage ? (
                                  <div
                                    className={classNames(commonStyles.error)}
                                  >
                                    *{formikAddMedicine.errors.dosage}
                                  </div>
                                ) : null}
                              </div>

                              <div style={{ flex: 1 }}>
                                {" "}
                                <p
                                  className={classNames(
                                    commonstyles.fs16,
                                    commonstyles.colorBlue,
                                    commonstyles.semiBold
                                  )}
                                >
                                  Route
                                </p>
                                <select
                                  id="route"
                                  name="route"
                                  onChange={formikAddMedicine.handleChange}
                                  value={formikAddMedicine.values.route}
                                  className={classNames(styles.customSelect)}
                                  style={{
                                    border: "2px solid rgb(0, 39, 109)",
                                    borderRadius: "3px",
                                    padding: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <option value="">Select Route</option>
                                  {routeData.map((v: any, ind: number) => (
                                    <option key={ind} value={v.value}>
                                      {v.title}
                                    </option>
                                  ))}
                                </select>
                                {formikAddMedicine.touched.route &&
                                formikAddMedicine.errors.route ? (
                                  <div
                                    className={classNames(commonStyles.error)}
                                  >
                                    *{formikAddMedicine.errors.route}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div
                              className={classNames(commonstyles.mt16)}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1, marginRight: "10px" }}>
                                {" "}
                                <p
                                  className={classNames(
                                    commonstyles.fs16,
                                    commonstyles.colorBlue,
                                    commonstyles.semiBold
                                  )}
                                >
                                  Frequency
                                </p>
                                <select
                                  id="frequency"
                                  name="frequency"
                                  onChange={(e: any) => {
                                    formikAddMedicine.setFieldValue(
                                      "frequency",
                                      e.target.value
                                    );

                                    if (
                                      selectedMed?.productType == "Tablet" ||
                                      selectedMed?.productType == "Capsule"
                                    ) {
                                      let obj: any = frequencyData.find(
                                        (item: any) =>
                                          item.title == e.target.value
                                      );
                                      let multiple =
                                        obj?.count *
                                        formikAddMedicine?.values?.days;

                                      formikAddMedicine.setFieldValue(
                                        "quantity",
                                        multiple
                                      );
                                    }
                                  }}
                                  value={formikAddMedicine.values.frequency}
                                  className={classNames(styles.customSelect)}
                                  style={{
                                    border: "2px solid rgb(0, 39, 109)",
                                    borderRadius: "3px",
                                    padding: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <option value="">Select Frequency</option>
                                  {frequencyData.map((v: any, ind: number) => (
                                    <option key={ind} value={v.value}>
                                      {v.title}
                                    </option>
                                  ))}
                                </select>
                                {formikAddMedicine.touched.frequency &&
                                formikAddMedicine.errors.frequency ? (
                                  <div
                                    className={classNames(commonStyles.error)}
                                  >
                                    *{formikAddMedicine.errors.frequency}
                                  </div>
                                ) : null}
                              </div>

                              <div style={{ flex: 1 }}>
                                {" "}
                                <p
                                  className={classNames(
                                    commonstyles.fs16,
                                    commonstyles.colorBlue,
                                    commonstyles.semiBold
                                  )}
                                >
                                  Days
                                </p>
                                <select
                                  id="days"
                                  name="days"
                                  onChange={(e: any) => {
                                    formikAddMedicine.setFieldValue(
                                      "days",
                                      e.target.value
                                    );
                                    if (
                                      selectedMed?.productType == "Tablet" ||
                                      selectedMed?.productType == "Capsule"
                                    ) {
                                      let obj: any = frequencyData.find(
                                        (item: any) =>
                                          item.title ==
                                          formikAddMedicine.values.frequency
                                      );
                                      let multiple =
                                        obj?.count * e.target.value;

                                      formikAddMedicine.setFieldValue(
                                        "quantity",
                                        multiple
                                      );
                                    }
                                  }}
                                  value={formikAddMedicine.values.days}
                                  className={classNames(styles.customSelect)}
                                  style={{
                                    border: "2px solid rgb(0, 39, 109)",
                                    borderRadius: "3px",
                                    padding: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <option value="">Select Days</option>
                                  {daysData.map((v: any, ind: number) => (
                                    <option key={ind} value={v.value}>
                                      {v.title}
                                    </option>
                                  ))}
                                </select>
                                {formikAddMedicine.touched.days &&
                                formikAddMedicine.errors.days ? (
                                  <div
                                    className={classNames(commonStyles.error)}
                                  >
                                    *{formikAddMedicine.errors.days}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className={styles.mt16}>
                              <div className={classNames(styles.row)}>
                                <div className={classNames(styles.col12)}>
                                  <div className={classNames(commonstyles.flx)}>
                                    <p
                                      className={classNames(
                                        commonstyles.fs16,
                                        commonstyles.colorBlue,
                                        commonstyles.semiBold,
                                        commonStyles.mr16
                                      )}
                                    >
                                      Instructions
                                    </p>
                                    <p
                                      className={classNames(
                                        commonstyles.fs12,
                                        commonstyles.colorOrange,
                                        commonstyles.medium,
                                        commonStyles.underLine
                                      )}
                                      onClick={() =>
                                        setShowCustomInstructionModal(true)
                                      }
                                    >
                                      Add Custom
                                    </p>
                                  </div>
                                  <CustomModal
                                    showModal={showCustomInstructionModal}
                                  >
                                    <div style={{ width: "420px" }}>
                                      <CustomInput
                                        placeholder="Enter custom Instructions"
                                        id="instruction"
                                        name="instruction"
                                        type="text"
                                        onChange={
                                          formikAddMedicine.handleChange
                                        }
                                        value={
                                          formikAddMedicine.values.instruction
                                        }
                                      />
                                    </div>
                                    <div
                                      className={classNames(
                                        styles.mt16,
                                        commonStyles.col4
                                      )}
                                    >
                                      <PrimaryButton
                                        colorType={"green"}
                                        children={"Close"}
                                        type="button"
                                        onClick={() => {
                                          setShowCustomInstructionModal(false);
                                        }}
                                      />
                                    </div>
                                  </CustomModal>
                                  <select
                                    id="instruction"
                                    name="instruction"
                                    onChange={formikAddMedicine.handleChange}
                                    value={formikAddMedicine.values.instruction}
                                    className={classNames(styles.customSelect)}
                                    style={{
                                      border: "2px solid rgb(0, 39, 109)",
                                      borderRadius: "3px",
                                      padding: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <option value="">
                                      {formikAddMedicine.values.instruction
                                        ? formikAddMedicine.values.instruction
                                        : "Select Instructions"}
                                    </option>
                                    {istructionsData.map(
                                      (v: any, ind: number) => (
                                        <option key={ind} value={v.value}>
                                          {v.title}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {formikAddMedicine.touched.instruction &&
                                  formikAddMedicine.errors.instruction ? (
                                    <div
                                      className={classNames(commonStyles.error)}
                                    >
                                      *{formikAddMedicine.errors.instruction}
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div
                                className={classNames(
                                  styles.row,
                                  commonStyles.mt16
                                )}
                              >
                                <div className={classNames(styles.col12)}>
                                  <p
                                    className={classNames(
                                      commonstyles.fs16,
                                      commonstyles.colorBlue,
                                      commonstyles.semiBold
                                    )}
                                  >
                                    Quantity
                                  </p>
                                  <input
                                    id="quantity"
                                    name="quantity"
                                    type="number"
                                    onChange={formikAddMedicine.handleChange}
                                    value={formikAddMedicine.values.quantity}
                                    className={classNames(styles.customInput)}
                                    min="1"
                                    required
                                    style={{
                                      border: "2px solid rgb(0, 39, 109)",
                                      borderRadius: "3px",
                                      padding: "10px",
                                      width: "98%",
                                    }}
                                  />
                                  {formikAddMedicine.touched.quantity &&
                                  formikAddMedicine.errors.quantity ? (
                                    <div
                                      className={classNames(commonStyles.error)}
                                    >
                                      *{formikAddMedicine.errors.quantity}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div
                              className={classNames(
                                styles.mt32,
                                commonStyles.col4
                              )}
                            >
                              <PrimaryButton
                                colorType={"green"}
                                children={"Save"}
                                type="button"
                                onClick={formikAddMedicine.handleSubmit}
                              />
                            </div>
                          </form>
                        )}

                        {isTestOpen && (
                          <>
                            <div>
                              <form onSubmit={formikAddTest.handleSubmit}>
                                <div>
                                  <p
                                    className={classNames(
                                      commonStyles.fs16,
                                      commonStyles.colorBlue,
                                      commonStyles.semiBold
                                    )}
                                  >
                                    Available Tests
                                  </p>

                                  <div
                                    style={{
                                      width: "98%",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={testSearchQuery}
                                      onChange={handleTestSearchChange}
                                      placeholder="Search Test Here"
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        border: "2px solid rgb(0, 39, 109)",
                                        borderRadius: "3px",
                                        boxShadow:
                                          "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      }}
                                    />
                                  </div>
                                  {formikAddTest.touched.testName &&
                                  formikAddTest.errors.testName ? (
                                    <div
                                      className={classNames(commonstyles.error)}
                                    >
                                      *{formikAddTest.errors.testName}
                                    </div>
                                  ) : null}

                                  {testSuggestions?.length > 0 && (
                                    <div
                                      className={styles.searchResults}
                                      style={{
                                        backgroundColor: "white",
                                        zIndex: 10,
                                        marginTop: -10,
                                      }}
                                    >
                                      <ul>
                                        {testSuggestions.map(
                                          (suggestion: any, index) => (
                                            <li
                                              key={index}
                                              className={styles.suggestionItem}
                                              onClick={() =>
                                                handleTestSuggestionClick(
                                                  suggestion
                                                )
                                              }
                                              style={{
                                                listStyleType: "none",
                                              }}
                                            >
                                              {suggestion?.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className={classNames(
                                    commonstyles.mt56,
                                    commonstyles.BtnWidth
                                  )}
                                >
                                  <PrimaryButton
                                    colorType={"green"}
                                    children={"Save"}
                                    type="submit"
                                  />
                                </div>
                              </form>
                            </div>
                          </>
                        )}
                        <div className={styles.logboxx}>
                          {appointment?.history && (
                            <>
                              <p
                                className={classNames(
                                  commonstyles.f20,
                                  commonStyles.semiBold,
                                  commonStyles.mt16,
                                  commonStyles.mb8
                                )}
                              >
                                Added History:
                              </p>
                              <div
                                style={{
                                  border: "1px solid #00276d",
                                  padding: "10px",
                                }}
                              >
                                <div className={classNames(commonstyles.flx)}>
                                  <div
                                    className={classNames(commonstyles.mr48)}
                                  >
                                    <p
                                      className={classNames(
                                        commonstyles.fs16,
                                        commonStyles.medium
                                      )}
                                    >
                                      Blood Pressure
                                    </p>
                                    <p
                                      className={classNames(
                                        commonstyles.fs14,
                                        commonStyles.normal,
                                        styles.mt8
                                      )}
                                    >
                                      {
                                        appointment?.history?.bloodPressure
                                          ?.systolicPressure
                                      }{" "}
                                      mmhg -{" "}
                                      {
                                        appointment?.history?.bloodPressure
                                          ?.diastolicPressure
                                      }{" "}
                                      mmhg
                                    </p>
                                  </div>
                                  <div style={{ marginLeft: "100px" }}>
                                    <p
                                      className={classNames(
                                        commonstyles.fs16,
                                        commonStyles.medium
                                      )}
                                    >
                                      Weight
                                    </p>
                                    <p
                                      className={classNames(
                                        commonstyles.fs14,
                                        commonStyles.normal,
                                        styles.mt8
                                      )}
                                    >
                                      {appointment?.history?.weight} kg
                                    </p>
                                  </div>
                                </div>

                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Symptoms
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.normal,
                                    styles.mt8
                                  )}
                                >
                                  {appointment?.history?.symptoms}
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Description
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f14,
                                    commonStyles.normal,
                                    styles.mt8
                                  )}
                                >
                                  {appointment?.history?.description}
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Temprature
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f14,
                                    commonStyles.normal,
                                    styles.mt8
                                  )}
                                >
                                  {appointment?.history?.temperature}
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Heart Rate
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f14,
                                    commonStyles.normal,
                                    styles.mt8
                                  )}
                                >
                                  {appointment?.history?.heartRate}
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Sugar
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f14,
                                    commonStyles.normal,
                                    styles.mt8
                                  )}
                                >
                                  {appointment?.history?.sugar}
                                </p>
                                <p
                                  className={classNames(
                                    commonstyles.f16,
                                    commonStyles.medium,
                                    styles.mt16
                                  )}
                                >
                                  Diseases
                                </p>
                                {appointment?.history?.diseases.map((i:any) => (
                                  <p
                                    className={classNames(
                                      commonstyles.f14,
                                      commonStyles.normal,
                                      styles.mt8
                                    )}
                                  >
                                    {i}
                                  </p>
                                ))}
                              </div>
                            </>
                          )}

                          {testdata?.length > 0 && (
                            <p
                              className={classNames(
                                commonstyles.fs24,
                                commonStyles.semiBold,
                                styles.mt16,
                                commonStyles.mb16
                              )}
                            >
                              Tests:
                            </p>
                          )}

                          {testdata?.map((test: any, index: number) => (
                            <div
                              key={index}
                              className={classNames(
                                commonstyles.flx,
                                commonStyles.mb16
                              )}
                            >
                              <p>{test?.testName}</p>
                              <MdDeleteForever
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  marginLeft: "24px",
                                  color: "red",
                                }}
                                onClick={() => handleDeleteTest(index)}
                              />
                            </div>
                          ))}
                          {medicinedata?.length > 0 && (
                            <>
                              <p
                                className={classNames(
                                  commonstyles.fs24,
                                  commonStyles.semiBold,
                                  styles.mt16
                                )}
                              >
                                Medicines
                              </p>

                              <Medicine_Table
                                data={medicinedata}
                                type={"Bid"}
                                handleDelete={deleteEntry}
                              />
                            </>
                          )}
                        </div>
                        {error && (
                          <div className={classNames(commonStyles.error)}>
                            *{error}
                          </div>
                        )}
                        <div>
                          <div
                            className={classNames(
                              commonStyles.flx,
                              commonStyles.flxEnd,
                              styles.mt16
                            )}
                          >
                            <div
                              className={classNames(
                                commonStyles.mr16,
                                commonStyles.col3,
                                commonStyles.colsm12
                              )}
                            >
                              <PrimaryButton
                                children={
                                  closeLoading ? (
                                    <RingLoader size={35} color={"#fff"} />
                                  ) : (
                                    "Close Appointment"
                                  )
                                }
                                colorType={"blue"}
                                onClick={() => {
                                  handleCloseAppointment();
                                }}
                              />
                            </div>

                            <div
                              className={classNames(
                                commonStyles.mr16,
                                commonStyles.col3,
                                commonStyles.colsm12
                              )}
                            >
                              <PrimaryButton
                                children={
                                  loading ? (
                                    <RingLoader size={35} color={"#fff"} />
                                  ) : (
                                    "Save Prescription"
                                  )
                                }
                                colorType={"green"}
                                onClick={() => {
                                  AddPrescription();
                                }}
                              />
                            </div>
                          </div>

                          <CustomModal showModal={showPrescriptionModel}>
                            <div style={{ width: "420px" }}>
                              <FaCheckCircle className={styles.done} />
                              <p
                                className={classNames(
                                  commonStyles.fs16,
                                  commonStyles.semiBold,
                                  commonStyles.colorBlue,
                                  styles.textcenter
                                )}
                              >
                                Prescription has been successfully added.
                              </p>
                            </div>
                          </CustomModal>
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <CustomModal
          showModal={showAddModal}
          children={
            <ReferDoctor
              fullpatient={fullpatient}
              selectedReferType={selectedReferType}
              setShowAddModal={setShowAddModal}
              pateintid={pateintid}
              appointmentId={appointment?._id}
              setSuccessmodal={setSuccessmodal}
              handleGoBack={handleGoBack}
            />
          }
        />

        <CustomModal showModal={successmodal}>
          <div style={{ width: "420px" }}>
            <FaCheckCircle className={styles.done} />
            <p
              className={classNames(
                commonStyles.fs16,
                commonStyles.semiBold,
                commonStyles.colorBlue,
                styles.textcenter
              )}
            >
              Your patient has been successfully referred
            </p>
          </div>
        </CustomModal>
      </div>
      <DeleteModal
        handleDelete={handleSend}
        handleCancel={() => setVideomodel(false)}
        content={`You want to send the link to ${fullpatient?.name}`}
        modalVisible={videomodel}
        confirmText={"Yes Send"}
      />
      {/* <CustomModal showModal={showVideoModel} children={<VideoCall />} /> */}
    </div>
  );
}

interface RefProps {
  setShowAddModal: any;
  pateintid: any;
  fullpatient: any;
  selectedReferType?: any;
  appointmentId?: any;
  setSuccessmodal?: any;
  handleGoBack?: any;
}

const ReferDoctor = (props: Partial<RefProps>) => {
  const [error, setError] = React.useState("");
  const [confirmmodal, setConfirmmodal] = useState(false);
  const {
    setShowAddModal,
    pateintid,
    fullpatient,
    selectedReferType,
    appointmentId,
    setSuccessmodal,
    handleGoBack,
  } = props;

  const [loading, setLoading] = useState(false);
  const [selectedDoctorName, setSelectedDoctorName] = useState<string | null>(
    null
  );
  const [selectedDoctorImage, setSelectedDoctorImage] = useState<string | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const [length, setLength] = useState(0);
  const totalItems = length;
  const [refdoctor, setReferdoctor] = useState<any>([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState<string | null>(null);

  const [searchInput, setSearchInput] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //
    setSearchInput(event.target.value);
  };
  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleReferalSelection = (referal: any) => {
    setSelectedDoctorId(referal?._id);
  };

  const getDoctorSearching = (searchInput: string, pageno: any) => {
    setLoading(true);
    getDoctorSearch(searchInput, pageno)
      .then((res: any) => {
        setReferdoctor(res.data.doctors);
        setLength(res?.data?.totalDoctors);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getSpecialities = (searchInput: string, pageno: any) => {
    setLoading(true);
    getAllSpecialities(pageno, searchInput)
      .then((res: any) => {
        setReferdoctor(res.data.specialities);
        setLength(res?.data?.totalSpecialities);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getHospitals = (searchInput: string, pageno: any) => {
    setLoading(true);
    getAllHospitals(pageno, searchInput)
      .then((res: any) => {
        setReferdoctor(res.data.hospitals);
        setLength(res?.data?.totalHospitals);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedReferType === "Doctor") {
      getDoctorSearching(searchInput, currentPage);
    } else if (selectedReferType === "Specialities") {
      getSpecialities(searchInput, currentPage);
    } else if (selectedReferType === "Hospital") {
      getHospitals(searchInput, currentPage);
    }
  }, [searchInput, selectedReferType, currentPage]);

  const ReferTheDoctor = () => {
    if (selectedDoctorId && pateintid) {
      setConfirmmodal(true);
      setError("");
    } else {
      if (!selectedDoctorId) {
        setError("Please select Doctor");
      }
    }
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;
    const nextPage = currentPage + 1;
    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      if (selectedReferType === "Doctor") {
        getDoctorSearching(searchInput, nextPage);
      } else if (selectedReferType === "Specialities") {
        getSpecialities(searchInput, nextPage);
      } else if (selectedReferType === "Hospital") {
        getHospitals(searchInput, nextPage);
      }
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      setPageno(pageno - 10);

      if (selectedReferType === "Doctor") {
        getDoctorSearching(searchInput, prevPage);
      } else if (selectedReferType === "Specialities") {
        getSpecialities(searchInput, prevPage);
      } else if (selectedReferType === "Hospital") {
        getHospitals(searchInput, prevPage);
      }
    }
  };
  return (
    <div style={{ width: "400px" }}>
      <div className={styles.DoctorSearch}>
        <input
          type="Search by Name"
          placeholder="Search"
          value={searchInput}
          onChange={handleInputChange}
        />
        <IoClose className={styles.close} onClick={handleCloseModal} />
      </div>

      {loading ? (
        <DoctorReferModel showModal={loading} />
      ) : (
        <div className={styles.loader}>
          {refdoctor.map((doctor: any) => {
            return (
              <div>
                <div
                  className={classNames(commonStyles.flx, styles.doctorcard)}
                >
                  <Avatar src={doctor.doctorImage} className={styles.avatar} />
                  <div>
                    <p
                      className={classNames(
                        commonStyles.fs14,
                        commonStyles.medium,
                        commonStyles.colorBlue
                      )}
                      style={{ textTransform: "capitalize" }}
                    >
                      {selectedReferType === "Doctor" ||
                      selectedReferType === "Hospital"
                        ? doctor?.name
                        : doctor?.specialityTitle}
                    </p>

                    {selectedReferType === "Specialities" && (
                      <p
                        className={classNames(
                          commonStyles.fs14,
                          commonStyles.medium,
                          commonStyles.colorBlue
                        )}
                        style={{ textTransform: "capitalize" }}
                      >
                        {doctor?.doctorsCount} Doctors Available
                      </p>
                    )}
                  </div>
                  <div className={styles.end}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      }
                      onChange={() => handleReferalSelection(doctor)}
                      checked={selectedDoctorId === doctor._id}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        <NewPagination
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          startItem={(currentPage - 1) * itemsPerPage + 1}
          endItem={Math.min(currentPage * itemsPerPage, length)}
          totalItems={totalItems}
        />
      </div>
      <div className={styles.mt16}>
        <PrimaryButton
          children={"Refer"}
          colorType={"green"}
          onClick={ReferTheDoctor}
        />
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
      </div>

      <CustomModal
        showModal={confirmmodal}
        children={
          <Confirm
            selectedReferType={selectedReferType}
            selectedDoctorName={selectedDoctorName}
            selectedDoctorImage={selectedDoctorImage}
            fullpatient={fullpatient}
            setConfirmmodal={setConfirmmodal}
            setShowAddModal={setShowAddModal}
            pateintid={pateintid}
            selectedDoctorId={selectedDoctorId}
            appointmentId={appointmentId}
            setSuccessmodal={setSuccessmodal}
            handleGoBack={handleGoBack}
          />
        }
      />
    </div>
  );
};

interface ConfirmProps {
  fullpatient: any;
  setConfirmmodal: any;
  setShowAddModal: any;
  selectedDoctorName: string | null;
  selectedDoctorImage: string | null;
  pateintid: any;
  selectedDoctorId: any;
  selectedReferType?: any;
  appointmentId?: any;
  setSuccessmodal?: any;
  handleGoBack?: any;
}

const Confirm = (props: Partial<ConfirmProps>) => {
  const {
    fullpatient,
    setConfirmmodal,
    setShowAddModal,
    pateintid,
    selectedDoctorId,
    selectedReferType,
    appointmentId,
    setSuccessmodal,
    handleGoBack,
  } = props;

  const [referLoading, setReferLoading] = useState(false);

  const ReferAPI = () => {
    let body = {
      referType: selectedReferType,
      patientId: pateintid,
      appointmentId: appointmentId,
      ...(selectedReferType === "Doctor" && {
        doctorId: selectedDoctorId,
      }),
      ...(selectedReferType === "Specialities" && {
        specialityId: selectedDoctorId,
      }),
      ...(selectedReferType === "Hospital" && {
        hospitalId: selectedDoctorId,
      }),
    };

    setReferLoading(true);
    ReferToDoctor(body)
      .then((res: any) => {
        setSuccessmodal(true);
        setConfirmmodal(false);
        setShowAddModal(false);
        handleGoBack();
      })
      .catch((err: any) => {})
      .finally(() => setReferLoading(false));
  };

  return (
    <>
      <div style={{ width: "400px" }}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IoClose
            className={styles.closefinal}
            onClick={() => setConfirmmodal(false)}
          />
        </div>

        <p
          className={classNames(
            commonstyle.fs16,
            commonstyle.semiBold,
            styles.mt24,
            commonstyle.colorBlue,
            styles.textcenter
          )}
        >
          Do you want to refer {fullpatient.name} ?
        </p>
        <div className={classNames(commonstyle.flxEvenly, styles.mt32)}>
          <div style={{ width: "104px" }}>
            <PrimaryButton
              disabled={referLoading}
              children={"No"}
              colorType={"Red"}
              onClick={() => {
                setConfirmmodal(false);
              }}
            />
          </div>
          <div style={{ width: "104px" }}>
            <PrimaryButton
              disabled={referLoading}
              children={
                referLoading ? <RingLoader size={35} color={"#fff"} /> : "Yes"
              }
              colorType={"green"}
              onClick={() => {
                ReferAPI();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// export const VideoCall = () => {
//   return (
//     <div>
//       <ZegoCloudComponent />
//     </div>
//   );
// };

// <button onClick={scrollToTop} style={buttonStyle}>
// Scroll to Top
// </button>

// const ref = useRef(null);
// const scrollToTop = () => {
//   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
// };
// const buttonStyle: any = {
//   // position: "fixed",
//   // bottom: "20px",
//   // right: "20px",
//   padding: "10px 20px",
//   backgroundColor: "#007bff",
//   color: "white",
//   border: "none",
//   borderRadius: "5px",
//   cursor: "pointer",
// };
