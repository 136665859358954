import { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import CartStyles from "./Cart.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setObj } from "shared/redux";
import { LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PHARMACY_CART } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";

const PharmacyCart = () => {
  const { cart } = useSelector((state: any) => state.root.common);
  const dispatch: any = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleValue = (value: any, item: any) => {
    const myArray = JSON.parse(JSON.stringify(cart));
    let objIndex = myArray.findIndex((obj: any) => obj?._id == item?._id);
    myArray[objIndex].quantity = value;
    const filteredCart = myArray.filter((item: any) => item?.quantity > 0);
    dispatch(setCart(filteredCart));
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cart?.forEach((item: any) => {
      totalPrice += item?.quantity * item?.tpPrice;
    });
    return totalPrice;
  };

  useEffect(() => {
    const calculatedTotalPrice = calculateTotalPrice();
    setTotalPrice(calculatedTotalPrice);
  }, [cart]);

  const navigate = useNavigate();

  const confirmation = () => {
    setLoading(true);
    setTimeout(async () => {
      await dispatch(setObj(cart));
      setLoading(false);
      navigate("/services/paymentDetail", {
        state: {
          serviceName: "pharmacy",
          actualAmount: totalPrice,
        },
      });
    }, 2000);
  };

  return (
    <div>
      <NavBreadCrumbs {...PHARMACY_CART} />

      {cart?.length ? (
        <div className={classNames(commonstyles.container, commonstyles.mb32)}>
          <div
            className={classNames(
              CartStyles.card,
              commonstyles.col6,
              commonstyles.colsm11
            )}
          >
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.colorBlue,
                commonstyles.semiBold
              )}
            >
              Cart Summary
            </p>

            {cart.map((item: any, index: any) => (
              <div key={index} className={classNames(commonstyles.col12)}>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <div className={classNames(commonstyles.col6)}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.productName}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.Bold
                      )}
                    >
                      {item?.brand}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.Bold
                      )}
                    >
                      {item?.strength}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <span>Rs.{item?.tpPrice}</span> <span>/-</span>
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                  >
                    <span>Quantity</span>
                  </p>
                  <Incriment
                    CartStyles={CartStyles}
                    value={item?.quantity}
                    setValue={handleValue}
                    item={item}
                  />
                </div>

                {/* Separator Line */}
                <hr
                  style={{
                    margin: "1rem 0",
                    color: "#DCDCDC",
                  }}
                />
              </div>
            ))}

            <div
              className={classNames(commonstyles.flx, commonstyles.flxBetween)}
            >
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs16,
                  commonstyles.semiBold
                )}
              >
                Total:
              </p>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs16,
                  commonstyles.semiBold
                )}
              >
                Rs.{totalPrice.toFixed(2)}
              </p>
            </div>
          </div>

          <div className={CartStyles.showMoreContainer}>
            <button
              onClick={confirmation}
              className={CartStyles.showMoreButton}
            >
              Checkout
              <span className={CartStyles.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div>
          {/* <p className={commonstyles.fs24}>No Records Found</p> */}
          <PhysiotheristsEmpty />
        </div>
      )}
      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
};
interface Props {
  setValue?: any;
  value?: any;
  item?: any;
  CartStyles?: any;
}
const Incriment = (props: Props) => {
  const { setValue, value, item, CartStyles } = props;

  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setValue(value + 1, item);
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1, item);
      setCount(count - 1);
    }
  };
  return (
    <>
      <div className={classNames(CartStyles.viewQuantitybox)}>
        <button
          className={CartStyles.decrementButton}
          onClick={handleDecrement}
        >
          -
        </button>
        <span className={CartStyles.quantity}>{value}</span>
        <button
          className={CartStyles.incrementButton}
          onClick={handleIncrement}
        >
          +
        </button>
      </div>
    </>
  );
};
export default PharmacyCart;
