import React from "react";
import classNames from "classnames";
import CardStyless from "./newFilter.module.css";
import { Box, Checkbox, Modal, Typography } from "@mui/material";
import Filter from "assets/images/filterHos.png";
import { GoDotFill } from "react-icons/go";
import InputField from "../InputField";
import { IoClose, IoSearch } from "react-icons/io5";

export default function New_Filter() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className={CardStyless.flxEnd}>
      <div className={classNames(CardStyless.flxendfilter, CardStyless.main)}>
        <div className={classNames(CardStyless.filterWrapper)}>
          <img
            src={Filter}
            className={CardStyless.filterImage}
            onClick={handleOpen}
          />
          <p className={CardStyless.filterText}>Filters</p>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={CardStyless.ModalContent}>
            <div className={CardStyless.mdlwrp}>
              <p className={CardStyless.modelHeading}>Filters</p>
              <IoClose
                size={16}
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <div
              style={{
                padding: "15px 0 10 0",
                display: "flex",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <GoDotFill />
              <p className={CardStyless.headingModel}>Sort By</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Checkbox
                  sx={{
                    padding: "0px",
                    color: "#FF9500",
                    "&.Mui-checked": {
                      color: "#FF9500",
                    },
                  }}
                  size="small"
                />
                <p className={CardStyless.filterText}>Near by</p>
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Checkbox
                  sx={{
                    padding: "0px",
                    color: "#FF9500",
                    "&.Mui-checked": {
                      color: "#FF9500",
                    },
                  }}
                  size="small"
                />

                <p className={CardStyless.filterText}>Recommended</p>
              </div>
            </div>
            <div
              style={{
                padding: "15px 0 10 0",
                display: "flex",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <div className={CardStyless.w50}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <GoDotFill />
                  <p className={CardStyless.headingModel}>Select Country</p>
                </div>
                {/* Replace the InputField with  Selector */}
                <InputField />
              </div>
              <div className={CardStyless.w50}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <GoDotFill />
                  <p className={CardStyless.headingModel}>Select Specialty</p>
                </div>
                {/* Replace the InputField with  Selector */}

                <InputField />
              </div>
            </div>
            <div className={CardStyless.flxbtwen}>
              <button className={CardStyless.Reset}>Reset</button>
              <button className={CardStyless.Apply}>Apply</button>
            </div>
          </div>
        </Modal>
      </div>
      <div className={classNames(CardStyless.filterWrapper)}>
        <IoSearch className={CardStyless.SearchIcon} />

        <input placeholder="Search" className={CardStyless.SearchInput} />
      </div>
    </div>
  );
}
