import React from "react";
import header from "./servicesheader.module.css";

interface ServiceHeaderProps {
  headingBlue?: string;
  headingOrange?: string;
  content?: string;
  desc_width?: string;
}

const ServiceHeader: React.FC<ServiceHeaderProps> = ({
  headingBlue,
  headingOrange,
  content,
  desc_width,
}) => {
  return (
    <div className={header.warpper}>
      <div className={header.outerer}>
        <p className={header.heading}>
          <span className={header.blue}>{headingBlue}</span>{" "}
          <span className={header.orange}>{headingOrange}</span>
        </p>
        <p
          className={header.content}
          style={{ width: desc_width ? desc_width : "100%" }}
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default ServiceHeader;
