import React, { useState } from "react";
import styles from "./order.module.css";
import { FaChevronDown } from "react-icons/fa6";
import { labStatusChange } from "shared/services";
import Toast from "shared/components/Toastify/toastify";
import { ToastNotification } from "shared/components";

const statusOptions = [
  { label: "Pending", value: "pending", color: "#F0D5D1" },
  { label: "In Process", value: "In Process", color: "#FEE6DA" },
  { label: "Complete", value: "complete", color: "#F0F8E8" },
];

// Define props type
interface Props {
  orderId: string;
  initialStatus: string;
  onStatusUpdate: (orderId: string, newStatus: string) => void;
}

const CustomSelectOrder: React.FC<Props> = ({
  orderId,
  initialStatus,
  onStatusUpdate,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(initialStatus);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    setLoading(true);

    try {
      await labStatusChange(orderId, { status: newStatus });
      onStatusUpdate(orderId, newStatus);
    } catch (error) {
      console.error("Failed to update status:", error);
    } finally {
      setLoading(false);
    }
  };

  const selectedColor =
    statusOptions.find((option) => option.value === selectedStatus)?.color ||
    "#D96F79";

  return (
    <div className={styles.container}>
      <div className={styles.selectWrapper}>
        <select
          className={styles.select}
          value={selectedStatus}
          onChange={handleChange}
          disabled={loading}
          style={{
            backgroundColor: selectedColor,
            color:
              selectedStatus === "pending"
                ? "#D96F79"
                : selectedStatus === "complete"
                ? "#5BD32A"
                : "#FF7631",
          }}
        >
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <FaChevronDown
          className={styles.icon}
          style={{
            color:
              selectedStatus === "pending"
                ? "#D96F79"
                : selectedStatus === "complete"
                ? "#5BD32A"
                : "#FF7631",
          }}
        />
      </div>
    </div>
  );
};

export default CustomSelectOrder;
