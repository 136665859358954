import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import classNames from "classnames";
import { TravelMenu } from "shared/utils";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import TravelSetting from "pages/TravelTourism/Travel agency/TravelSetting";
import TravelDashBoard from "pages/TravelTourism/Travel agency/TravelDashBoard";
import TravelPayment from "pages/TravelTourism/Travel agency/TravelPayment";
import Booking from "pages/TravelTourism/Travel agency/Booking/index";
import PaymentDescritionTravel from "pages/TravelTourism/Travel agency/TravelPayment/PaymentDescritionTravel";
import Chat from "shared/components/Chat";
import TourPakages from "pages/TravelTourism/Travel agency/TravelFlight/Tour";
import TourPakageDetail from "pages/TravelTourism/Travel agency/TravelFlight/Tour/TourDetail";
import TicketDetail from "pages/TravelTourism/Travel agency/Booking/BookingTickets/TicketDetail";
import TourDetail from "pages/TravelTourism/Travel agency/Booking/BookingTour/TourDetail";
import TicketRequest from "pages/TravelTourism/Travel agency/TicketRequest";
import TicketRequestDetail from "pages/TravelTourism/Travel agency/TicketRequest/TicketRequestDetail";
import AddFlight from "pages/TravelTourism/Travel agency/TicketRequest/BidTicketFormTravel";
import BidsDetail from "pages/TravelTourism/Travel agency/TicketRequest/BidsDetail";
import TravelTicketShare from "pages/TravelTourism/Travel agency/TicketRequest/TravelTicketshare";
import { BookingDetailsFlight, TravelAgencyProfile } from "pages";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";

const TravelAgencyMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/travelAgency/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={TravelMenu} />
      </div>
      <Routes>
        <Route path="travelAgency/dashboard" element={<TravelDashBoard />} />
        <Route path="/travelAgency/tourPakages" element={<TourPakages />} />
        <Route
          path="/travelAgency/tourPakagesDetail/:id"
          element={<TourPakageDetail />}
        />
        <Route path="travelAgency/ticketRequests" element={<TicketRequest />} />
        <Route
          path="travelAgency/ticketRequests/Detail/:id"
          element={<TicketRequestDetail />}
        />
        <Route
          path="travelAgency/ticketRequests/BidsDetail/:id"
          element={<BidsDetail />}
        />
        <Route
          path="travelAgency/ticketRequests/TravelTicketshare"
          element={<TravelTicketShare />}
        />

        <Route path="travelAgency/Booking" element={<Booking />} />
        <Route
          path="travelAgency/Booking/BookingDetailsFlight"
          element={<BookingDetailsFlight />}
        />
        <Route
          path="/travelAgency/Booking/TicketDetail/:id"
          element={<TicketDetail />}
        />
        <Route
          path="/travelAgency/Booking/TourDetail/:id"
          element={<TourDetail />}
        />
        <Route path="travelAgency/payment" element={<TravelPayment />} />
        <Route
          path="travelAgency/paymentDetails"
          element={<PaymentDescritionTravel />}
        />
        <Route path="travelAgency/setting" element={<TravelSetting />} />
        <Route path="travelAgency/privacy" element={<PrivacyPolicy />} />
        <Route path="/flights/Bid" element={<AddFlight />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="travelAgency/profile" element={<TravelAgencyProfile />} />
        <Route
          path={`/travelAgency/paymentDetail`}
          element={<PaymentNavbarRoutes />}
        />
      </Routes>
    </div>
  );
};

export default TravelAgencyMainRoutes;
