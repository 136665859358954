import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  add_Appointment_Doctors,
  confirm_Booking,
  bookingTours,
  addPharmacyOrder,
  acceptAmbulanceReq,
  remaining_Appointment_Doctors,
  postDonationAmount,
  postDetails,
  addBookingRoom,
  confirmInsurance,
  remainingPayment_RentCr,
  remainingPayment_Hotel,
  remaining_TourPayment,
  acceptFlightBid,
  acceptTestMedi,
} from "../UserService";

export const function_API = ({
  serviceName,
  paymentId,
  paymentParams,
  paidAmount,
  confirmStripePayment,
  setModalVisible,
  setLoading,
  remainingAmount,
  hospitalId,
  hotelDetails,
  secret,
  event,
  location,
  user,
  arryObj,
  userAge,
  treatmentId,
  systemType,
}: {
  serviceName?: any;
  paymentId?: any;
  paymentParams?: any;
  paidAmount?: any;
  confirmStripePayment?: any;
  hotelDetails: any;
  setModalVisible?: any;
  setLoading?: any;
  hospitalId: any;
  dispatch: any;
  secret?: any;
  event?: any;
  remainingAmount?: any;
  location?: any;
  user?: any;
  arryObj?: any;
  userAge?: any;
  treatmentId?: any;
  systemType?: any;
}) => {
  if (systemType === "user") {
    if (
      serviceName === "doctor" ||
      serviceName === "physiotherapist" ||
      serviceName === "nutritionist" ||
      serviceName === "Hospital" ||
      serviceName === "departmentdoc"
    ) {
      let params = {
        paymentId: paymentId,
        totalAmount: paymentParams?.actualAmount,
        isPaidFull:
          paymentParams?.paymentType == "Partial Payment" ? false : true,
        paidByUserAmount: paidAmount,
        remainingAmount: remainingAmount,
        processingFee: paymentParams?.processingFee,
        ...(paymentParams?.speciality && {
          treatmentId: treatmentId,
        }),
        isTreatment: paymentParams?.speciality ? true : false,

        ...(paymentParams?.appointmentType === "hospital" && {
          hospital: hospitalId,
        }),
        appointmentType: paymentParams?.appointmentType,
        gatewayName: "stripe",
      };

      add_Appointment_Doctors(params, paymentParams?.docId)
        .then(() => {
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "remainingDoctor") {
      let params: any = {
        appointmentId: paymentParams?.appointmentId,
        paymentId: paymentId,
        remainingAmount: Number(paymentParams?.pendingPayment),
        paidByUserAmount: paymentParams?.paidByUserAmount,
        processingFee: Number(paymentParams?.processingFee),
        gatewayName: "stripe",
      };

      remaining_Appointment_Doctors(params)
        .then((res: any) => {
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => setLoading(false));
    } else if (serviceName == "donation") {
      setLoading(true);
      let body = {
        paymentId: paymentId,
        paidByUserAmount: paidAmount,
        donationAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
        gatewayName: "stripe",
      };
      let params = {
        packageId: arryObj?._id,
        companyId: arryObj?.donationId?._id,
      };
      postDonationAmount(body, params)
        .then(() => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "Lab") {
      let params = {
        paymentId: paymentId,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
        MR_NO: user?.mrNo,
        customerName: user?.name,
        prescription: "",
        currentLocation: {
          lat: location?.latitude,
          lng: location?.longitude,
          address: user?.address?.address,
          city: user?.address?.city,
        },
        vendorId: paymentParams?.labId,
        items: paymentParams?.item,
        // items: [
        //   {
        //     itemId: "66de8eac9638d0cbb02f7612",
        //     quantity: 1,
        //   },
        // ],
        preference: paymentParams?.selectedPreference,
        gatewayName: "stripe",
      };

      confirm_Booking(params)
        .then((res: any) => {
          setModalVisible(true);
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => setLoading(false));
    } else if (serviceName == "Ambulance") {
      let body = {
        paymentId: paymentId,
        paidByUserAmount: paidAmount,
        name: user?.name,
        email: user?.email,
        age: userAge,
        phone: user?.phone,
        processingFee: paymentParams?.processingFee,
        gatewayName: "stripe",
        address: user?.address?.address,
      };
      acceptAmbulanceReq(paymentParams?.ambulanceId, body)
        .then((res: any) => {
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "pharmacy" || serviceName == "prescription") {
      let items: any = [];

      arryObj?.map((i: any) => {
        items.push({
          id: i?._id,
          quantity: i?.quantity,
        });
      });

      setLoading(true);
      let params = {
        medicineIds: items,
        paymentId: paymentId,
        customerName: user?.name,
        totalAmount: paymentParams?.actualAmount,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
        isPaidFull: "true",
        gatewayName: "stripe",
      };

      addPharmacyOrder(params)
        .then(() => {
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
          // setRefreshing(false);
        });
    } else if (serviceName == "tour") {
      let params = {
        tourId: arryObj?.params?.tourId,
      };
      let body = {
        paymentId: paymentId,
        agencyId: arryObj?.item?.agencyId?._id,
        from: arryObj?.item?.from,
        to: arryObj?.item?.to,
        email: user?.email,
        remainingAmount: remainingAmount,
        totalAmount: paymentParams?.actualAmount,
        packageName: arryObj?.item?.packageName,
        totalUser: Number(arryObj?.body?.totalUser),
        name: user?.name,
        age: arryObj?.body?.age?.toString(),
        address: user?.address?.address,
        gatewayName: "stripe",
        // amount: paymentParams?.actualAmount,
        isPaidFull:
          paymentParams?.paymentType == "Partial Payment" ? false : true,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
      };

      bookingTours(params, body)
        .then((res: any) => {
          confirmStripePayment(secret, event);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "rentacar") {
      let requestData = {
        vehicleName: arryObj?.vehicleName,
        vehicleId: arryObj?.vehicleId,
        name: arryObj?.customerName,
        rentACarId: arryObj?.rentACarId,
        remainingAmount: remainingAmount,
        pickupLocation: arryObj?.pickupLocation,
        dropoffLocation: arryObj?.dropoffLocation,
        pickupDateTime: arryObj?.pickupDateTime,
        dropoffDateTime: arryObj?.dropoffDateTime,
        cnic: arryObj?.cnic,
        vehicleModel: arryObj?.vehicleModel,
        totalAmount: paymentParams?.actualAmount,
        phone: arryObj?.phone,
        age: arryObj?.age,
        withDriver: arryObj?.withDriver,
        paymentId: paymentId,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
        isPaidFull:
          paymentParams?.paymentType == "Partial Payment" ? false : true,
        gatewayName: "stripe",
      };

      postDetails(requestData)
        .then(() => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "insurance") {
      let params = {
        insuranceCompanyId: arryObj?.item?.insuranceId?._id,
        insuranceId: arryObj?.item?._id,
        userId: user?._id,
        userName: user?.name,
        mrNo: user?.mrNo,
        phone: user?.phone,
        location: {
          lat: user?.address.lat,
          lng: user?.address.lng,
          address: user?.address.address,
          city: user?.address.city,
        },
        cnic: arryObj?.cnic,
        insuranceKind: arryObj?.type?.toLowerCase(),
        insuranceFor: arryObj?.type?.toLowerCase(),
        cnicFile: arryObj?.cnicUrl,
        gatewayName: "stripe",
        paymentId: paymentId,
        totalAmount: paymentParams?.actualAmount,
        isPaidFull:
          paymentParams?.paymentType == "Partial Payment" ? false : true,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
      };

      confirmInsurance(params)
        .then(() => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (serviceName == "Remaining_RentCar") {
      setLoading(true);
      let body = {
        paymentId: paymentId,
        paidByUserAmount: paymentParams?.paidByUserAmount,
        processingFee: paymentParams?.processingFee,
        gatewayName: "stripe",
      };
      let params = {
        bookingId: paymentParams?.bookingID,
      };

      remainingPayment_RentCr(params, body)
        .then((res?: any) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })

        .catch((err: any) => {})
        .finally(() => setLoading(false));
    } else if (serviceName == "Hotel") {
      const params = {
        paymentId: paymentId,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
        isPaidFull:
          paymentParams?.paymentType == "Partial Payment" ? false : true,
        gatewayName: "stripe",
        remainingAmount: remainingAmount,
        address: user?.address.address,
        age: arryObj?.params?.age,
        email: user?.email,
        hotelId: arryObj?.params.hotelId,
        name: user?.name,
        purpose: arryObj?.params?.purpose,
        ...(arryObj?.params?.serviceType == "apartment"
          ? { apartments: arryObj?.params?.rooms }
          : { rooms: arryObj?.params?.rooms }),
        arrivalDate: {
          from: hotelDetails?.fromDate,
          to: hotelDetails?.todate,
        },
        noOfGuest: hotelDetails.noOfGuest,
        serviceId: arryObj?.params?.serviceId,
        serviceType: arryObj?.params?.serviceType,
        totalAmount: paymentParams?.actualAmount,
      };

      addBookingRoom(params)
        .then((res: any) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((error: any) => {});
    } else if (serviceName == "hotelRemaining") {
      setLoading(true);

      let body = {
        bookingId: paymentParams?.bookingID,
        paymentId: paymentId,
        paidByUserAmount: paymentParams?.paidByUserAmount,
        processingFee: paymentParams?.processingFee,
        gatewayName: "stripe",
      };

      remainingPayment_Hotel(body)
        .then((res: any) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => setLoading(false));
    } else if (serviceName == "TourRemaing") {
      let params: any = {
        bookingId: paymentParams?.bookingID,
        paymentId: paymentId,
        paidByUserAmount: paymentParams?.paidByUserAmount,
        processingFee: paymentParams?.processingFee,
        gatewayName: "stripe",
      };
      remaining_TourPayment(params)
        .then((res: any) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => {});
    } else if (serviceName == "flights") {
      let params = {
        ...arryObj?.params,
        gatewayName: "stripe",
        paymentId: paymentId,
        totalAmount: paymentParams?.actualAmount,
        paidByUserAmount: paidAmount,
        processingFee: paymentParams?.processingFee,
      };
      acceptFlightBid(arryObj?.data, params)
        .then((res: any) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err: any) => {})
        .finally(() => {});
    } else if (serviceName === "labTestPharmacy") {
      let medi: any = [];
      let items: any = [];

      arryObj?.selectedCards?.tests?.forEach((i: any) => {
        items.push({
          itemId: i?._id,
          quantity: 1,
        });
      });

      arryObj?.medicines?.forEach((i: any) => {
        medi.push({
          id: i?.medicineId?._id,
          quantity: i?.quantity,
        });
      });

      let params = {};
      if (arryObj?.prescription === "bothTest") {
        params = {
          labOrder: {
            paymentId: paymentId,
            paidByUserAmount: arryObj?.selectedCards?.totalUserAmount,
            processingFee: paymentParams?.processingFee,
            MR_NO: user?.mrNo,
            currentLocation: {
              lat: location?.latitude,
              lng: location?.longitude,
              address: user?.address?.address,
              city: user?.address?.city,
            },
            items: items,
            preference: arryObj?.selectedPreference,
            appointmentId:arryObj?.appointmentId,
            prescription: "",
            customerName: user?.name,
            vendorId: arryObj?.selectedCards?._id,
            gatewayName: "stripe",
          },
          medicineRequest: {
            medicineIds: medi,
            doctorId: arryObj?.data?.doctorId?._id,
            processingFee: paymentParams?.processingFee,
            paymentId: paymentId,
            paidByUserAmount: arryObj?.itemPrices,
            totalAmount: arryObj?.itemPrices,
            gatewayName: "stripe",
          },
        };
      } else if (arryObj?.prescription === "labtest") {
        params = {
          labOrder: {
            paymentId: paymentId,
            paidByUserAmount: arryObj?.selectedCards?.totalUserAmount,
            processingFee: paymentParams?.processingFee,
            MR_NO: user?.mrNo,
            currentLocation: {
              lat: location?.latitude,
              lng: location?.longitude,
              address: user?.address?.address,
              city: user?.address?.city,
            },
            items: items,
            appointmentId:arryObj?.appointmentId,
            preference: arryObj?.selectedPreference,
            prescription: "labtest",
            customerName: user?.name,
            vendorId: arryObj?.selectedCards?._id,
            gatewayName: "stripe",
          },
        };
      } else if (arryObj?.prescription === "medicine") {
        params = {
          medicineRequest: {
            medicineIds: medi,
            processingFee: paymentParams?.processingFee,
            doctorId: arryObj?.data?.doctorId?._id,
            paymentId: paymentId,
            totalAmount: arryObj?.itemPrices,
            gatewayName: "stripe",
          },
        };
      }

      acceptTestMedi(params)
        .then((res) => {
          setModalVisible(true);
          setTimeout(() => {
            setModalVisible(false);
            confirmStripePayment(secret, event);
          }, 2000);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  } else {
    // let params = {
    //   paymentId: paymentId,
    //   gatewayName: "stripe",
    // };
    //
    // dispatch(setPaymentParams(params));
    // confirmStripePayment(secret, event);
    // activationAccount(params)
    //   .then((res: any) => {
    //     // toast?.success(res?.data?.message);
    //   })
    //   .catch((err: any) => {
    //
    //   })
    //   .finally(() => setLoading(false));
  }
};
