import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import styles from "./test.module.css";
import SearchBar from "shared/components/Searchbar/index";
import commonstyle from "shared/utils/common.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import style from "./test.module.css";
import { useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import {
  CustomInput,
  CustomModal,
  InputField,
  RingLoader,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import {
  LabTestDELETE,
  LabTestDetails,
  updateTestCategory,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { setTestsss } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
import { RxCross2 } from "react-icons/rx";
import { useFormik } from "formik";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import TextAreaField from "shared/components/A_New_Components/NewTextArea";
interface Props {
  setShowAddModal: any;
  id: any;
}
const TestConfirmDelete = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { tests } = useSelector((state: any) => state.root.lab);

  const dispatch = useDispatch();

  const deleteTest = () => {
    let test_clone = JSON.parse(JSON.stringify(tests));

    setLoading(true);
    const testId = id || "";
    LabTestDELETE(testId)
      .then((res: any) => {
        if (res?.status === 200) {
          test_clone?.map((item: any, ind: any) => {
            if (id == item?._id) {
              test_clone.splice(ind, 1);
            }
          });
          dispatch(setTestsss(test_clone));
          navigate("/laboratory/test");
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const { setShowAddModal, id } = props;
  console.log("🚀 ~ TestConfirmDelete ~ id:", id);

  return (
    <>
      <div className={classNames(style.modalBackdrop)}>
        <div className={classNames(style.modalContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IoClose
              className={style.closeicon}
              onClick={() => setShowAddModal(false)}
            />
          </div>
          <div className={classNames(commonstyle.flx, commonstyle.flxCol)}>
            <p className={classNames(commonstyle.fs24, commonstyle.semiBold)}>
              Are you sure ?
            </p>
            <p className={classNames(commonstyle.colorGray, commonstyle.fs16)}>
              You want to delete this test?
            </p>
            <div
              style={{ gap: "18px" }}
              className={classNames(
                commonstyle.flx,
                commonstyle.flxBetween,
                style.mt24
              )}
            >
              <button
                className={style.cancelbtn}
                onClick={() => setShowAddModal(false)}
              >
                No Cancel
              </button>
              <button className={style.dltbtn} onClick={deleteTest}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TestEdit = ({ TestDetails, id, setShowEditModel }: any) => {
  const [loading, setLoading] = useState(false);
  console.log(TestDetails, "........TestDetails?.TestDetails");
  const formik = useFormik({
    initialValues: {
      SpecimenType: TestDetails?.duration || "",
      testDescription: TestDetails?.testDescription || "",
      actualPrice: TestDetails?.price || "",
      meditourPrice: TestDetails?.priceForMeditour || "",
    },
    onSubmit: (values) => {
      update(values);
    },
  });
  const navigate = useNavigate();
  const update = (values: any) => {
    setLoading(true);
    let params = {
      ...(values?.testDescription && {
        testDescription: values?.testDescription,
      }),
      ...(values?.testDescription && { price: values?.actualPrice }),

      priceForMeditour: values?.meditourPrice,
    };
    console.log(params, id, "...params");
    updateTestCategory(id, params)
      .then((res: any) => {
        console.log(res.data, ".....");
        notifySuccess("Update Successfully");
        setShowEditModel(false);
        navigate("/laboratory/test");
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const Close = () => {
    setShowEditModel(false);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.end}>
        <p className={styles.textTest}>Update Test</p>
        <RxCross2 style={{ cursor: "pointer" }} size={24} onClick={Close} />
      </div>
      <div
        style={{
          width: "50vw",
          marginTop: "24px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <label>Actual Price</label>
          <InputField
            id="actualPrice"
            name="actualPrice"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.actualPrice}
          />
        </div>
        <div>
          <label>Meditour Price</label>
          <InputField
            id="meditourPrice"
            name="meditourPrice"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.meditourPrice}
          />
        </div>{" "}
        <div>
          <label>Test Description</label>
          <TextAreaField
            id="testDescription"
            name="testDescription"
            onChange={formik.handleChange}
            value={formik.values.testDescription}
          />
        </div>
        <button
          type="submit"
          className={styles.cancelbutton}
          disabled={loading}
        >
          {loading ? <RingLoader color={"#ffff"} size={24} /> : "Update"}
        </button>
      </div>
    </form>
  );
};

export default function TestDetail() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const handleBackToTestTable = () => {
    navigate("/laboratory/test");
  };
  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleEditModel = () => {
    setShowEditModel(true);
  };
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  console.log("🚀 ~ TestDetail ~ id:", id);
  const [TestDetails, setTestDetails] = useState<any>(null);
  const TestDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      //

      LabTestDetails(id)
        .then((res: any) => {
          console.log(res?.data?.test, ".......test");
          setTestDetails(res?.data.test);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    TestDetail();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyle.col12)}>
          {showAddModal && (
            <div>
              <TestConfirmDelete setShowAddModal={setShowAddModal} id={id} />
            </div>
          )}
          <CustomModal showModal={showEditModel}>
            <TestEdit
              setShowEditModel={setShowEditModel}
              TestDetails={TestDetails}
              id={id}
            />
          </CustomModal>
          <div style={{ marginTop: "16px" }}>
            <p className={classNames(style.heading)}>Test Detail</p>
          </div>
          <div className={style.Detail_Card}>
            <p>Details</p>
            <div
              style={{ marginBottom: "8px" }}
              className={classNames(style.outerflx, style.mt32)}
            >
              <div className={style.w15}>
                <p className={style.outerheading}>Test Code</p>
              </div>{" "}
              <div className={style.w20}>
                <p className={style.outerheading}>Test Name</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.outerheading}>Test Category</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.outerheading}>Specimen Type</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.outerheading}>Meditour Price</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.outerheading}>Actual Price</p>
              </div>
            </div>
            <div
              style={{
                padding: "10px 0",
                borderTop: "0.5px solid #7d7d7d",
                borderBottom: "0.5px solid #7d7d7d",
              }}
              className={classNames(style.outerflx)}
            >
              <div className={style.w15}>
                <p className={style.text}>{TestDetails?.testCode}</p>
              </div>{" "}
              <div className={style.w20}>
                <p className={style.text}>{TestDetails?.testNameId?.name}</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.text}>
                  {TestDetails?.testNameId?.categoryName}
                </p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.text}>{TestDetails?.specimen}</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.text}>{TestDetails?.priceForMeditour}</p>
              </div>{" "}
              <div className={style.w15}>
                <p className={style.text}>{TestDetails?.price}</p>
              </div>
            </div>
            <div className={style.textWraaper}>
              <p className={style.text}>{TestDetails?.testDescription}</p>
            </div>
          </div>{" "}
          <div className={classNames(style.flxBetween, style.mt16, style.pr36)}>
            <button className={style.Delete} onClick={handleOpenModal}>
              Delete
            </button>{" "}
            <button className={style.Edit} onClick={handleEditModel}>
              Edit
            </button>
          </div>
        </div>
      )}
    </>
  );
}
