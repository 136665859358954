import React, { useEffect, useState } from "react";
import styles from "./freeopd.module.css";
import Footerr from "../HomeNavBar/Footer";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import DynamicCard from "shared/components/DynamicCard";
import { opdDoctors } from "shared/services";
import { useNavigate } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { Free_Opd } from "shared/utils/mainHeaderQuery";

export default function FreeOpd() {
  const [activeTab, setActiveTab] = useState("Free OPD");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    document.title = "MediTour Global | Free Opd";
    window.scrollTo(0, 0);
  });

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    OPD();
  }, [activeTab, currentPage]);

  const OPD = () => {
    setLoading(true);
    let params = {
      searchValue: "",
      isMeditour: activeTab === "Free OPD" ? "true" : "false",
      page: currentPage,
      limit: itemsPerPage,
    };

    opdDoctors(params)
      .then((res: any) => {
        setData(res?.data || []);
        setTotalItems(res?.data?.totalDocs || 0);
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };
  const handleViewMoreClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName: "doctor", opdType: activeTab, doc },
    });
  };
  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  return (
    <div>
      <NavBreadCrumbs {...Free_Opd(activeTab)} />

      <div className={styles.container}>
        <DoubleButton
          tab1Label="Free OPD"
          tab2Label="Paid OPD"
          shift={activeTab}
          onTabChange={handleTabChange}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10x ",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>
        <DynamicCard data={data} onPressDetail={handleViewMoreClick} />
      </div>

      {loading && <CustomLoader />}

      <Footerr />
    </div>
  );
}
