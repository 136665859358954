import classNames from "classnames";
import toast from "react-hot-toast";
import { FaHeart } from "react-icons/fa";
import { useEffect, useState } from "react";
import lstyle from "./Labdetail.module.css";
import { FaRegHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Footerr from "pages/Home/HomeNavBar/Footer";
import CardStyless from "./DetailCards.module.css";
import LaboratoriyProduct from "./LaboratoriyProduct";
import { useDispatch, useSelector } from "react-redux";
import commonstyles from "shared/utils/common.module.css";
import { FaRegClock, FaRegCheckCircle } from "react-icons/fa";
import {
  AddRemovedFev,
  getAll_AdminTestCategories,
  getAll_Tests,
  getSingle_UserLaboratory,
} from "shared/services/UserService";
import { set_User } from "shared/redux";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { LABORATORY_DETAILS } from "shared/utils/mainHeaderQuery";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";

const LabortoryDetail = () => {
  const [activeButton, setActiveButton] = useState("About");
  const [searchText, setSearchText] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [productData, setProductData] = useState<any>([]);
  const [test, setTest] = useState<any>([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const itemsPerPage = 10;

  const { location, user } = useSelector((state: any) => state?.root?.common);

  const { state }: any = useLocation();

  useEffect(() => {
    setLoading(true);
    getSingleLab(currentPage);
  }, [currentPage, activeButton]);

  const getSingleLab = (page: number) => {
    const params = {
      labId: state?.id,
      lat: location?.latitude,
      long: location?.longitude,
      page: page,
    };

    getSingle_UserLaboratory(params)
      .then((res: any) => {
        setData(res?.data);
        allAdmin_Categories();
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };

  const allAdmin_Categories = () => {
    getAll_AdminTestCategories()
      .then((res: any) => {
        setProductData(res?.data?.testCategories);
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };

  const get_Test = (page: number) => {
    let params = {
      testName: searchText,
      labId: state?.id,
      page: page, // Add page parameter for pagination
    };
    getAll_Tests(params)
      .then((res: any) => {
        setTest(res?.data?.tests);
        setTotalItems(res?.data?.totalTests);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlefavourite = () => {
    const params = {
      type: "laboratory",
      itemId: state?.id,
    };
    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {})
      .finally(() => {});
  };

  const isFavorite = user?.favourites?.some(
    (fav: any) => fav.itemId === state?.id && fav.favModel === "laboratory"
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      get_Test(currentPage);
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...LABORATORY_DETAILS} />

      <div className={classNames(commonstyles.container, commonstyles.mb16)}>
        <div className={classNames(commonstyles.mb16)}>
          <DoubleButton
            tab1Label="About"
            tab2Label="Product"
            shift={activeButton}
            onTabChange={(tab) => setActiveButton(tab)}
          />
        </div>

        {activeButton === "About" && (
          <div style={{ display: "flex" }}>
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={data?.lab?.logo}
                  alt="card img"
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <div
                    className={classNames(
                      CardStyless.cardName,
                      commonstyles.colorBlue
                    )}
                  >
                    {data?.lab?.name}
                  </div>
                  <div onClick={handlefavourite}>
                    {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
                  </div>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegCheckCircle />
                  </span>
                  <span>Avaliable</span>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegClock />
                  </span>
                  <span>
                    {data?.lab?.openTime} - {data?.lab?.closeTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeButton === "Product" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></div>

            <LaboratoriyProduct
              productData={productData}
              get_Test={get_Test}
              test={test}
              labId={state?.id}
              data={data}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              searchtext={searchText}
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
            />
          </>
        )}
      </div>

      {loading && <CustomLoader />}

      <Footerr />
    </div>
  );
};

export default LabortoryDetail;
