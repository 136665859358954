import React, { useEffect, useRef, useState } from "react";
import styles from "./MyProfileNavbar.module.css";
import Footerr from "../Footer";
import MainHeader from "shared/components/MainScreen/Index";
import { FaChevronRight } from "react-icons/fa";
import { useFormik } from "formik";
import Styles from "./MyProfileNavbar.module.css";
import { useNavigate } from "react-router-dom";
import {
  add_File,
  blockUser,
  passwordReset,
  updateProfile,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  InputField,
  LoadingModal,
  PhoneNumberInputNew,
  RingLoader,
} from "shared/components";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import toast from "react-hot-toast";
import { setIsLoggedIn, setToken, set_User } from "shared/redux";
import { FiArrowRight } from "react-icons/fi";
import image1 from "../../../../assets/images/ProfileDownload.png";
import ProfileModal from "./ProfileModel";
import ProfileDataBlock from "shared/components/ProfileDataBlock";
import { bankInfo, socialInfo, userInfo } from "shared/utils";
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import LocationInput from "shared/components/LocationInput";
import { finalValue, initialValue } from "./UpdateProfileQuery";

import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PROFILE } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import DatepickerNew from "shared/components/DatePicker/DatePickerNew";
import dayjs from "dayjs";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";

const MyProfileNavbar: React.FC = () => {
  const { user } = useSelector((state: any) => state?.root?.common);
  const [profileImage, setProfileImage] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmhowPassword, setconfirmShowPassword] = useState(false);
  const [ringLoading, setRingLoading] = useState(false);
  const [formType, setFormType] = useState<
    "basic" | "social" | "bank" | "changePassword"
  >("basic");
  const [url, setUrl] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const dispatch = useDispatch();
  const handleSaveClick = (values: any) => {
    if (
      formType === "changePassword" &&
      (!values.currentPassword ||
        !values.newPassword ||
        !values.confirmPassword)
    ) {
      toast?.error("Please fill all fields");
      return;
    }
    if (values?.newPassword !== values?.confirmPassword) {
      toast?.error("Passwords do not match");
      return;
    }
    const { countryCode, ...otherValues } = values;
    const hasOtherValue = Object.values(otherValues).some((value) => value);
    if (!hasOtherValue) {
      toast.error("Please enter at least one field");
      return;
    }
    setLoading(true);
    let params: any = finalValue(values, url);
    updateProfile(params)
      .then((res: any) => {
        setModalOpen(false);
        toast?.success(res?.data?.message);
        dispatch(set_User(res?.data?.user));
      })
      .catch((err: any) => {
        toast?.error(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const formik: any = useFormik({
    initialValues: initialValue(user),

    onSubmit: (values) => {
      handleSaveClick(values);
    },
  });

  useEffect(() => {
    finalValue();
  }, [isModalOpen]);

  const handleFileChange = (e: any) => {
    setLoading(true);
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setProfileImage(reader.result as string);

        try {
          const formData = new FormData();
          if (file) {
            formData.append("file", file);
          }
          const response = await add_File(formData);
          setUrl(response?.data?.fileUrl);
          let params = {
            userImage: response?.data?.fileUrl,
          };

          updateProfile(params)
            .then((res: any) => {
              toast?.success("Profile Updated Successfully");
              dispatch(
                set_User({
                  ...user,
                  userImage: response?.data?.fileUrl,
                })
              );
            })
            .catch((err: any) => {});
          setLoading(false);
        } catch (error) {
          console.error("Upload error:", error);
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLocationChange = async (newLocation: any) => {
    const labelParts: any = newLocation?.label.split(", ");
    const country = labelParts[labelParts.length - 1];
    formik?.setFieldValue("address", newLocation?.label);
    formik?.setFieldValue("city", newLocation?.city);
    formik?.setFieldValue("country", country);
    const address = newLocation?.label;
    const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A"; // Replace with your actual API key
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        formik?.setFieldValue("lat", location.lat);
        formik?.setFieldValue("long", location.lng);
      } else {
        console.error("Geocoding error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data: ", error);
    }
  };
  const formatDate = (dob: string | null) => {
    if (dob) {
      return dayjs(dob); // Return a dayjs object instead of a formatted string
    }
    return null; // Return null if no date is provided
  };

  useEffect(() => {
    if (isModalOpen) {
      formik.resetForm({ values: initialValue(user) });
    }
  }, [isModalOpen, user]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.handleSubmit();
    }
  };
  const formikUsed = useFormik({
    initialValues: { webpassword: "", webconfirmPassword: "" },
    validationSchema: Yup.object({
      webpassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      webconfirmPassword: Yup.string()
        .oneOf([Yup.ref("webpassword"), undefined], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      handleSetPassowird();
    },
  });
  const handleSetPassowird = () => {
    let params = {
      email: user?.email,
      password: formikUsed?.values?.webconfirmPassword,
    };
    setRingLoading(true);
    console.log(params, "....params");
    passwordReset(params)
      .then((res: any) => {
        dispatch(set_User(res.data.user));
        dispatch(setToken(res.data.token));
        notifySuccess("Password Set Successfully");
      })
      .catch((err: any) => {
        console.log(err, ".....error");
      })
      .finally(() => {
        setRingLoading(false);
      });
  };
  return (
    <div>
      <NavBreadCrumbs {...PROFILE} />

      <div className={styles.profilePage}>
        {/* Centered Profile Image */}

        {user?.password ? (
          <>
            <div className={styles.profilePage__imageWrapper}>
              <img
                src={
                  user?.userImage ||
                  profileImage ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                }
                alt={user?.name}
                className={styles.profilePage__image}
              />
              <div className={styles.profilePage__smallImageWrapper}>
                <img
                  src={image1}
                  alt="Edit"
                  className={styles.profilePage__editIcon}
                  onClick={() => document.getElementById("fileInput")?.click()}
                />
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <ProfileDataBlock
              data={userInfo(user)}
              heading={"Basic Info"}
              setModalOpen={setModalOpen}
              setFormType={() => setFormType("basic")}
            />

            <ProfileDataBlock
              data={socialInfo(user)}
              heading={"Social Media"}
              setModalOpen={setModalOpen}
              setFormType={() => setFormType("social")}
            />

            <ProfileDataBlock
              data={bankInfo(user)}
              heading={"Bank Details"}
              setModalOpen={setModalOpen}
              setFormType={() => setFormType("bank")}
            />

            <div className={styles.profilePage__socialMediaContainer}>
              <div className={styles.profilePage__socialMediaHeader}>
                <h2 className={styles.profilePage__socialMediaHeading}>
                  Change Password
                </h2>
                <button
                  className={styles.profilePage__editButton}
                  onClick={() => {
                    setModalOpen(true);
                    setFormType("changePassword");
                  }}
                >
                  <FiArrowRight size={20} />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.cardContainer}>
            <p className={styles.title}>Set Your Password</p>
            <div className={styles.inputContainer}>
              <InputField
                id="webpassword"
                password={true}
                formik={formikUsed}
                placeholder="New Password"
                showPassword={showPassword}
                togglePassword={() => setShowPassword(!showPassword)}
                onChange={formikUsed.handleChange("webpassword")}
              />
              <InputField
                id="webconfirmPassword"
                password={true}
                formik={formikUsed}
                placeholder="Confirm Password"
                showPassword={confirmhowPassword}
                togglePassword={() =>
                  setconfirmShowPassword(!confirmhowPassword)
                }
                onChange={formikUsed.handleChange("webconfirmPassword")}
              />
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="submit"
                className={styles.submitting}
                onClick={(e) => {
                  e.preventDefault();
                  formikUsed.handleSubmit();
                }}
              >
                {ringLoading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Set Password"
                )}
              </button>
            </div>
          </div>
        )}
      </div>

      <ProfileModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <form
          className={Styles.basicInfoContainer}
          onSubmit={formik.handleSubmit}
        >
          {/* Conditional Rendering Based on Form Type */}
          {formType === "basic" && (
            <>
              <h2 className={Styles.sectionTitle}>Basic Info</h2>
              <div className={Styles.rowBasic}>
                <div className={Styles.w50}>
                  <FormikField
                    id={"fullName"}
                    formik={formik}
                    placeHolder={"Full Name *"}
                  />
                </div>
                <div className={Styles.w50}>
                  <FormikField
                    id={"fatherName"}
                    formik={formik}
                    placeHolder={"fatherName *"}
                  />
                </div>
              </div>

              <div className={Styles.rowBasic}>
                <div className={Styles.inputGroupBasic}>
                  <select
                    style={{ color: "#000", height: "48px" }}
                    id="gender"
                    className={Styles.wideSelect}
                    value={formik.values.gender}
                    onChange={formik.handleChange("gender")}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      Select Gender *
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="confidential">Confidential</option>
                  </select>
                </div>

                <div className={Styles.inputGroupBasic}>
                  <div
                    className={Styles.dateInput}
                    style={{
                      width: "100%",
                    }}
                  >
                    <DatepickerNew
                      value={
                        formik.values.dob ? formatDate(formik.values.dob) : null
                      }
                      onChange={(date: any) =>
                        formik.setFieldValue("dob", date?.format("YYYY-MM-DD"))
                      }
                      placeholder="Date Of Birth"
                    />
                  </div>
                </div>
              </div>

              <div className={Styles.rowBasic}>
                <div className={Styles.w50}>
                  <FormikField
                    editable={true}
                    id={"email"}
                    formik={formik}
                    placeHolder={"Email"}
                  />
                </div>

                <div className={Styles.inputGroupBasic}>
                  <div className={Styles.phoneInputBasic}>
                    <PhoneNumberInputNew
                      value={formik.values.phoneNumber}
                      setValue={(newValue: string) => {
                        formik.setFieldValue("phoneNumber", newValue);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"passport"}
                  formik={formik}
                  placeHolder={"NIC/Passport"}
                />
                <FormikField
                  id={"bloodGroup"}
                  formik={formik}
                  placeHolder={"Blood Group *"}
                />
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"qualification"}
                  formik={formik}
                  placeHolder={"Qualification *"}
                />

                <FormikField
                  id={"childrenNumber"}
                  formik={formik}
                  placeHolder={"Number of Children"}
                />
              </div>
              <div className={Styles.addressInputWidthManage}>
                <LocationInput
                  placeholder={"Address *"}
                  type={"box"}
                  setData={handleLocationChange}
                  defaultValue={{
                    label:
                      formik?.values?.address || user?.address?.address || "",
                    value: { place_id: null },
                  }} // Or similar structure
                />
              </div>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"city"}
                  formik={formik}
                  placeHolder={"City *"}
                />
                <FormikField
                  id={"country"}
                  formik={formik}
                  placeHolder={"Country *"}
                />
              </div>
            </>
          )}

          {formType === "social" && (
            <>
              <h2 className={Styles.sectionTitle}>Social Details</h2>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"facebook"}
                  formik={formik}
                  placeHolder={"Facebook Profile URL (Optional)"}
                />

                <FormikField
                  id={"instagram"}
                  formik={formik}
                  placeHolder={"Instagram Profile URL (Optional)"}
                />
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"linkedin"}
                  formik={formik}
                  placeHolder={"LinkedIn Profile URL (Optional)"}
                />

                <FormikField
                  id={"youtube"}
                  formik={formik}
                  placeHolder={"Youtube Profile URL (Optional)"}
                />
              </div>
            </>
          )}

          {formType === "bank" && (
            <>
              <h2 className={Styles.sectionTitle}>Bank Details</h2>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"bankName"}
                  formik={formik}
                  placeHolder={"Bank Name (Optional)"}
                />
                <FormikField
                  id={"accountNumber"}
                  formik={formik}
                  placeHolder={"IBAN / ACC Number (Optional)"}
                />
              </div>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"accountTitle"}
                  formik={formik}
                  placeHolder={"Account Title (Optional)"}
                />
                <FormikField
                  id={"ntn"}
                  formik={formik}
                  placeHolder={"NTN (Optional)"}
                />
              </div>
            </>
          )}
          {formType === "changePassword" && (
            <>
              <h2 className={Styles.sectionTitle}>Update Password</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormikField
                  id={"currentPassword"}
                  formik={formik}
                  placeHolder={"Current Password *"}
                  type="password"
                  onKeyPress={handleKeyPress}
                />

                <FormikField
                  id={"newPassword"}
                  formik={formik}
                  placeHolder={"New Password *"}
                  type="password"
                />
                <FormikField
                  id={"confirmPassword"}
                  formik={formik}
                  placeHolder={"Confirm Password *"}
                  type="password"
                />
              </div>
            </>
          )}

          <button type="submit" className={Styles.submitButton}>
            Save <FaChevronRight style={{ marginLeft: "8px" }} />
          </button>
        </form>
      </ProfileModal>
      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
};

export default MyProfileNavbar;

const FormikField = ({
  id,
  placeHolder,
  formik,
  editable,
  type = "text",
  onKeyPress,
}: {
  id: any;
  placeHolder: any;
  formik: any;
  editable?: any;
  type?: string;
  onKeyPress?: any;
}) => {
  let value = formik?.values;
  let touched = formik?.touched;
  let error = formik.errors;
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={Styles.inputGroupBasic}>
      <div className={Styles.inputWrapper}>
        <input
          disabled={editable}
          style={{ color: "#000", height: "48px" }}
          type={type === "password" && !showPassword ? "password" : "text"}
          id={id}
          onKeyDown={onKeyPress}
          value={value[id]}
          onChange={formik?.handleChange(id)}
          placeholder={placeHolder}
        />
        {type === "password" && (
          <div className={Styles.passwordToggleIcon}>
            <IconButton onClick={handleTogglePassword} edge="end">
              {showPassword ? (
                <BsEyeFill className={Styles.eyeIcon} />
              ) : (
                <BsEyeSlashFill className={Styles.eyeIcon} />
              )}
            </IconButton>
          </div>
        )}
      </div>
      {touched[id] && error[id] && (
        <div className={Styles.error}>{error[id]}</div>
      )}
    </div>
  );
};
