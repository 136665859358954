import React, { useState, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./booking.module.css";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import { fetchBook } from "shared/services";
import { AdminTable } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { useNavigate } from "react-router-dom";

function Booking() {
  const [activeTab, setActiveTab] = useState("Flight");
  const [data, setData] = useState<any[][]>([]);
  const [sendData, setsendData] = useState<any[][]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [bookingTable, setBookingTable] = useState<any[][]>([]);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  const fetchBooking = (page = 1) => {
    setLoading(true);
    const params = {
      requestType: activeTab?.toLowerCase(),
      page,
    };
    fetchBook(params)
      .then((res: any) => {
        const requests = res?.data?.requests || [];
        setsendData(requests)
        setTotalItems(res?.data?.totalRequests || 0);
        if (activeTab === "Flight") {
          handleTableData(requests);
        } else {
          handleTour(requests);
        }
      })
      .catch((err: any) => {
        console.error("Error fetching booking data:", err);
        setData([]);
        setBookingTable([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableData = (data: any[]) => {
    const tempData = data.map((v) => [
      v?.userId?.mrNo,
      v?.userId?.name,
      v?.bookingId,
      v?.bidRequestId?.flightType || "N/A",
    ]);
    setBookingTable(tempData);
  };

  const handleTour = (data: any[]) => {
    const tempData = data.map((v) => [
      v?.userId?.mrNo,
      v?.userId?.name,
      v?.tourId?.packageName,
      v?.isPaidFull ? "Booked" : "Reserved",
      v?.totalUser,
    ]);
    setData(tempData);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    fetchBooking(currentPage);
  }, [activeTab, currentPage]);

  const titles = [
    { id: 1, title: "Mr Id" },
    { id: 2, title: "User Name" },
    { id: 3, title: "Booking Id" },
    { id: 4, title: "Flight Type" },
  ];

  const titles2 = [
    { id: 1, title: "Mr Id" },
    { id: 2, title: "User Name" },
    { id: 3, title: "Package Name" },
    { id: 4, title: "Booking" },
    { id: 5, title: "No. of Seats" },
  ];

  const handleGoToDetail = (index: any) => {
    const item = sendData[index];
    navigate("/travelAgency/Booking/BookingDetailsFlight", {
      state: { item, activeTab },
    });
  };

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div style={{ height: "20px" }} />
      <DoubleButton
        tab1Label="Flight"
        tab2Label="Tour"
        shift={activeTab}
        onTabChange={handleTabChange}
      />
      <div
        className={classNames(commonstyles.flx, commonstyles.flxBetween)}
        style={{
          marginRight: "10px",
          marginTop: "10px",
        }}
      >
        <p></p>
        <NewPagination
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          startItem={(currentPage - 1) * itemsPerPage + 1}
          endItem={Math.min(currentPage * itemsPerPage, totalItems)}
          totalItems={totalItems}
        />
      </div>
      {activeTab === "Flight" ? (
        <AdminTable
          titles={titles}
          data={bookingTable}
          handleGoToDetail={handleGoToDetail}
          headerWidth={"24.66%"}
          itemWidth={"13.66%"}
        />
      ) : (
        <AdminTable
          titles={titles2}
          data={data}
          handleGoToDetail={handleGoToDetail}
          headerWidth={"20.66%"}
          itemWidth={"13.66%"}
        />
      )}

      {loading && <CustomLoader />}
    </div>
  );
}

export default Booking;
