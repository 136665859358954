import DoctorServices from "pages/Services/DoctarServices";
import DoctorAppoinmentPay from "pages/Services/DoctarServices/DoctorAppoinment";
import DoctorDetails from "pages/Services/DoctarServices/DoctorDetails";
import Nav_SelectionCards from "pages/Services/Nav_SelectionCard/Nav_SelectionCard";
import { Routes, Route } from "react-router-dom";
import DoctorIcon from "assets/images/DoctorIconselection.png";
import HospitalIcon from "assets/images/hospitalIconselection.png";
import DoctorCover from "assets/images/etactics-inc-2qiXVelOgyw-unsplash copy 1.png";
import HospitalCover from "assets/images/6631734499226eaa1afc891b_25 copy 1.png";
import Hospitalbg from "assets/images/Group 1597883599.png";
import Doctorbg from "assets/images/Group4567890098.png";
const Doc_Cards = [
  {
    color: "#13a89e",
    title: "Doctors",
    content:
      "Meditour stands out as a premier online doctor platform, connecting patients with highly qualified and experienced physicians across various specialties.",
    icon: DoctorIcon,
    infoBg: Doctorbg,
    top: "-140px",
    coverImg: DoctorCover,
  },

  {
    title: "Hospital",
    content:
      "From initial consultation to post-operative care arrangements, we manage the entire journey. Meditour ensures patients receive personalized care at reputable hospitals, making medical travel stress-free and accessible.",
    color: "#13a89e",
    icon: HospitalIcon,
    top: "-95px",
    infoBg: Hospitalbg,
    coverImg: HospitalCover,
  },
];

const DoctorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DoctorServices />} />
      <Route
        path="/doctor-services/:serviceName"
        element={<DoctorServices />}
      />
      <Route path="/DoctorDetail" element={<DoctorDetails />} />
      <Route
        path="/Selection"
        element={<Nav_SelectionCards data={Doc_Cards} />}
      />
      {/* <Route path="doctor/:type" element={<DoctorServices />} /> */}
      {/* <Route path="/DoctorDetail" element={<DoctorDetail />} /> */}
      <Route
        path="/DoctorAppoinmentPay/:id"
        element={<DoctorAppoinmentPay />}
      />
    </Routes>
  );
};

export default DoctorRoutes;
