import { useEffect, useState, ChangeEvent } from "react";
import commonStyles from "shared/utils/common.module.css";
import commonstyle from "shared/utils/common.module.css";
import style from "./mySelf.module.css";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Typography } from "@mui/material";
import { CustomInput, PrimaryButton } from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import FilePicker from "shared/components/FilePickeInsurance";
import { insuranceMedicalBenefits } from "shared/utils";
import { setInsuranceMySelfPackage } from "shared/redux";
const ICUCCU = ["No, Actuals", "Yes, Actuals"];
const EmergencyEvacuation = [
  "Emergency Evacuation Sub Limit",
  "N/A",
  "1k - 5k",
  "5k - 10k",
  "10k - 15k",
];
const Ambulance = ["Yes, Actual", "No"];
const Reimbursement = [
  "Outside Network Reimbursement ",
  "With 80:20 Co payment",
  "With 70:30 Co payment",
  "With 60:40 Co payment",
  "With 50:50 Co payment",
];
const AccidentalEmergencie = ["5k pkr", " 10k pkr", "15k pkr"];
const Maternity = ["Pay additional Amount", "get covered for up to Amount"];
const Investigations = [
  "Covered (Sub limit - Rs. 10,000)",
  " Covered (Sub limit - Rs. 15,000)",
  "Covered (Sub limit - Rs. 20,000)",
];
const Freelook = ["N/A", " 10 Days", "20 Days", "30 Days"];
const WaitingPeriod = ["2 weeks", "4 weeks", "6 weeks", "8 weeks", "2 weeks"];
interface Props {
  handleClickNext: any;
}
function MYselfBenefitsPolices(props: Partial<Props>) {
  const { handleClickNext } = props;
  const [filePickers, setFilePickers] = useState([<FilePicker key={0} />]);
  const { insuranceMySelfPackage } = useSelector(
    (state: any) => state.root.insurance
  );

  const handleSelect = (selectedOption: string) => {};
  const addMoreFilePicker = () => {
    const newFilePickers = [
      ...filePickers,
      <FilePicker key={filePickers.length} />,
    ];
    setFilePickers(newFilePickers);
  };
  const deleteLastFilePicker = () => {
    if (filePickers.length > 0) {
      const newFilePickers = [...filePickers];
      newFilePickers.pop();
      setFilePickers(newFilePickers);
    }
  };
  const handleSelectICU = (selectedOption: string) => {
    formik.setFieldValue("icu", selectedOption);
  };
  const handleSelectLimit = (selectedOption: string) => {
    formik.setFieldValue("additionalLimit", selectedOption);
  };
  const handleSelectAmbulance = (selectedOption: string) => {
    formik.setFieldValue("ambulanceService", selectedOption);
  };
  const handleSelectCoverage = (selectedOption: string) => {
    formik.setFieldValue("coverageSpecializedInvestigation", selectedOption);
  };
  const handleSelectPeriods = (selectedOption: string) => {
    formik.setFieldValue("weeks", selectedOption);
  };
  const handleSelectMaternity = (selectedOption: string) => {
    formik.setFieldValue("maternity", selectedOption);
  };
  const PolicyDocumentURL = (url: any) => {
    // setLogo(false);
    formik.setFieldValue("policyDocument", url);
  };
  const PolicyClaimURL = (url: any) => {
    // setLogo(false);
    formik.setFieldValue("claimProcess", url);
  };
  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    formik.setFieldValue("description", value);
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      icu: "",
      additionalLimit: "",
      ambulanceService: "",
      coverageSpecializedInvestigation: "",
      weeks: "",
      maternity: "",
      policyDocument: "",
      claimProcess: "",
      heading: "",
      description: "",
    },
    validationSchema: Yup.object(insuranceMedicalBenefits),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(
      setInsuranceMySelfPackage({ ...insuranceMySelfPackage, ...formik.values })
    );
    handleClickNext();
  };
  // useEffect(() => {
  //   formik.setValues(insuranceMySelfPackage);
  //
  // }, []);
  return (
    <div className={commonstyle.col12}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Accordion
            className={style.dropdown}
            style={{ borderRadius: "16px" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", paddingRight: "25px" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600" }}>
                <div>
                  <p
                    className={classNames(
                      commonstyle.fs16,
                      commonstyle.colorBlue
                    )}
                  >
                    Medical Benefits
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <div style={{ borderTop: "2px solid #00276D" }}></div>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonStyles.col6)}>
                  <div
                    className={classNames(
                      commonStyles.flx,
                      commonStyles.col12,
                      commonStyles.mb32
                    )}
                  >
                    <div
                      className={classNames(
                        commonStyles.col6,
                        commonStyles.mr24
                      )}
                    >
                      <CustomSelect
                        placeholder="ICU/CCU"
                        onSelect={handleSelectICU}
                        options={ICUCCU}
                      />
                      {formik.touched.icu && formik.errors.icu ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.icu}
                        </div>
                      ) : null}
                    </div>
                    <div className={classNames(commonStyles.col6)}>
                      <CustomSelect
                        placeholder="Additional Limits for Accidental Emergencies"
                        onSelect={handleSelectLimit}
                        options={AccidentalEmergencie}
                      />
                      {formik.touched.additionalLimit &&
                      formik.errors.additionalLimit ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.additionalLimit}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      commonStyles.flx,
                      commonStyles.col12,
                      commonStyles.mb32
                    )}
                  >
                    <div
                      className={classNames(
                        commonStyles.col6,
                        commonStyles.mr24
                      )}
                    >
                      <CustomSelect
                        placeholder="Ambulance Service Coverage"
                        onSelect={handleSelectAmbulance}
                        options={Ambulance}
                      />
                      {formik.touched.ambulanceService &&
                      formik.errors.ambulanceService ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.ambulanceService}
                        </div>
                      ) : null}
                    </div>
                    <div className={classNames(commonStyles.col6)}>
                      <CustomSelect
                        placeholder="Coverage of Specialized Investigations"
                        onSelect={handleSelectCoverage}
                        options={Investigations}
                      />
                      {formik.touched.coverageSpecializedInvestigation &&
                      formik.errors.coverageSpecializedInvestigation ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.coverageSpecializedInvestigation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      commonStyles.flx,
                      commonStyles.col12,
                      commonStyles.mb32
                    )}
                  >
                    <div
                      className={classNames(
                        commonStyles.col6,
                        commonStyles.mr24
                      )}
                    >
                      <CustomSelect
                        placeholder="Waiting Period"
                        onSelect={handleSelectPeriods}
                        options={WaitingPeriod}
                      />
                      {formik.touched.weeks && formik.errors.weeks ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.weeks}
                        </div>
                      ) : null}
                    </div>
                    <div className={classNames(commonStyles.col6)}>
                      <CustomSelect
                        placeholder="Maternity"
                        onSelect={handleSelectMaternity}
                        options={Maternity}
                      />
                      {formik.touched.maternity && formik.errors.maternity ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.maternity}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={style.dropdown}
            style={{ borderRadius: "16px" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", paddingRight: "25px" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600" }}>
                <div>
                  <p
                    className={classNames(
                      commonstyle.fs16,
                      commonstyle.colorBlue
                    )}
                  >
                    Policy Documents
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <div style={{ borderTop: "2px solid #00276D" }}></div>
            <AccordionDetails>
              <Typography>
                <div
                  className={commonStyles.col4}
                  style={{ marginTop: "16px" }}
                >
                  <FilePicker setData={PolicyDocumentURL} />
                </div>
                {formik.touched.policyDocument &&
                formik.errors.policyDocument ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.policyDocument}
                  </div>
                ) : null}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={style.dropdown}
            style={{ borderRadius: "16px" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", paddingRight: "25px" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600" }}>
                <div>
                  <p
                    className={classNames(
                      commonstyle.fs16,
                      commonstyle.colorBlue
                    )}
                  >
                    Claim Process
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <div style={{ borderTop: "2px solid #00276D" }}></div>

            <AccordionDetails>
              <Typography>
                <div style={{ marginTop: "16px" }}>
                  <div
                    style={{ marginLeft: "auto", marginBottom: "32px" }}
                    className={commonStyles.flx}
                  >
                    {/* <div style={{ marginLeft: "auto", width: "150px" }}>
                      <PrimaryButton
                        children={"Add More"}
                        colorType={"blue"}
                        onClick={addMoreFilePicker}
                      />{" "}
                    </div> */}
                    {/* <div>
                      <button
                        style={{
                          width: "100px",
                          marginLeft: "16px",
                          padding: "10px 0",
                          backgroundColor: "red",
                          borderRadius: "8px",
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "600",
                          border: "1px solid red",
                        }}
                        onClick={deleteLastFilePicker}
                      >
                        Delete
                      </button>
                    </div> */}
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        margin: "16px",
                        width: "30%",
                      }}
                    >
                      <FilePicker setData={PolicyClaimURL} />
                      {formik.touched.claimProcess &&
                      formik.errors.claimProcess ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.claimProcess}
                        </div>
                      ) : null}
                    </div>

                    {/* {filePickers.map((filePicker, index) => (
                      <div key={index} style={{ margin: "16px", width: "30%" }}>
                        {filePicker}
                      </div>
                    ))} */}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={style.dropdown}
            style={{ borderRadius: "16px" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", paddingRight: "25px" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600" }}>
                <div>
                  <p
                    className={classNames(
                      commonstyle.fs16,
                      commonstyle.colorBlue
                    )}
                  >
                    More Features
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <div style={{ borderTop: "2px solid #00276D" }}></div>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonStyles.col12)}>
                  <div
                    className={classNames(commonStyles.col6, commonStyles.mb32)}
                  >
                    <CustomInput
                      placeholder="Heading"
                      id="heading"
                      name="heading"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.heading}
                    />
                    {formik.touched.heading && formik.errors.heading ? (
                      <div className={classNames(commonStyles.error)}>
                        *{formik.errors.heading}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonStyles.col6)}>
                    <textarea
                      style={{
                        borderRadius: "8px",
                        width: "100%",
                        resize: "none",
                        border: "2px solid #00276D",
                        padding: "10px",
                        fontSize: "16px",
                        color: "#00276D",
                        boxSizing: "border-box",
                        height: "150px",
                      }}
                      placeholder="Description"
                      onChange={handleDescriptionChange}
                    ></textarea>
                    {formik.touched.description && formik.errors.description ? (
                      <div className={classNames(commonStyles.error)}>
                        *{formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={commonStyles.BtnWidth}>
          <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}

export default MYselfBenefitsPolices;
