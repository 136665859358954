import React, {  useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { creatPaymentIntent, striptPaid } from "../UserService";
import classNames from "classnames";
import styles from "./stripeStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import ThankyouModel from "shared/components/ThankyouModel";
import { function_API } from "./function";
import { set_User, setCart,  setObj } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
interface Props {
  serviceName?: any;
  convertedAmount?: any;
  paidAmount?: any;
  remainingAmount?: any;
}
export const CheckoutForm = (props: Props) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { serviceName, convertedAmount, paidAmount, remainingAmount } = props;
  const {
    user,
    paymentParams,
    hospitalId,
    location,
    obj,
    hotelDetail,
    userAge,
    systemType,
  } = useSelector((state: any) => state?.root?.common);
  const { treatmentId } = useSelector((state: any) => state?.root?.doctor);
  const dispatch = useDispatch();
  const confirmStripePayment = async (clientSecret: any, event: any) => {
    event.preventDefault();
    dispatch(setCart([]));
    const { error }: any = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url:
          systemType === "user"
             ? `https://meditour.global/ourServices`
              : `https://meditour.global/congratsScreen`,
            //   ?`https://staging.meditour.global/ourServices`
            // : `https://staging.meditour.global/${systemType}/dashboard`,
      },
      paymentMethodType: "Card",
    });
    if (error) {
      setErrorMessage(error?.message);
    } else {
    }
  };
  const modalFunction = (secret: any, event: any) => {
    setModalVisible(true);
    dispatch(setObj(null));
    setTimeout(() => {
      setModalVisible(false);
      confirmStripePayment(secret, event);
    }, 2000);
  };

  let arryObj = obj;
  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    if (elements == null || stripe == null) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }
    const roundedAmount = Math.floor(convertedAmount);
    let params = {
      email: user?.email,
      amount: roundedAmount * 100,
    };
    creatPaymentIntent(params)
      .then((res: any) => {
        if (systemType === "user") {
          function_API({
            serviceName: serviceName,
            paymentId: res?.data?.paymentIntent || "",
            paymentParams: paymentParams,
            paidAmount: paidAmount || "",
            confirmStripePayment: modalFunction,
            user: user,
            arryObj: arryObj || "",
            dispatch: dispatch,
            setModalVisible: setModalVisible,
            setLoading: setLoading,
            hospitalId: hospitalId || "",
            remainingAmount: remainingAmount,
            secret: res?.data?.paymentIntent,
            event: event,
            location: location,
            hotelDetails: hotelDetail,
            userAge: userAge,
            treatmentId: treatmentId,
            systemType: systemType,
          });
        } else {
          payActivation(res?.data?.paymentIntent, event);
        }
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };
  const allin =
    systemType === "doctor" ||
    systemType === "physiotherapist" ||
    systemType === "nutritionist" ||
    systemType === "psychologist";
  const payActivation = (paymentId: any, event: any) => {
    let params = {
      vendorType:
        systemType === "greentourism"
          ? "travel company"
          : allin
          ? "doctor"
          : systemType,
      paymentId: paymentId,
      vendorId: user?._id,
      gatewayName: "stripe",
    };
    striptPaid(params)
      .then((res:any) => {
        modalFunction(paymentId, event);
        dispatch(set_User(res?.data?.vendor));
      })
      .catch(() => {})
      .finally(() => {});
  };
  return (
    <div className={classNames(styles.mainCard)}>
      <form onSubmit={handleSubmit} className="px-4">
        <PaymentElement />
        <button
          type="submit"
          disabled={!stripe || !elements}
          className={classNames(styles.payButton)}
        >
          Pay
        </button>
        {errorMessage && <div>{errorMessage}</div>}
      </form>

      {loading && <CustomLoader />}

      {modalVisible && (
        <ThankyouModel
          mainMessageLine1="Your Payment has been successful"
          mainMessageLine2="Thank you"
        />
      )}
    </div>
  );
};
