import React, { useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import logo from "assets/images/HospitalDashboard/sidebarlogo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { set_User, setIsLoggedIn, setObj, setToken } from "shared/redux";
import { genericLogout } from "shared/services";
import { TbLogout } from "react-icons/tb";
import { GetColorCode } from "shared/utils";

const SideBar_New = ({
  data,
  setActive,
  activeTab,
}: {
  data?: any;
  setActive?: any;
  activeTab?: any;
}) => {
  const dispatch = useDispatch();
  const { logoutGeneric } = GetColorCode();
  const { systemType } = useSelector((state: any) => state.root.common);
  const [activeMenuItem, setActiveMenuItem] = useState(
    `${systemType}/dashboard`
  );
  const navigate = useNavigate();
  const handleDataDispatch = (returnUrl: any) => {
    dispatch(setToken(null));
    dispatch(setIsLoggedIn(false));
    dispatch(set_User({}));
    dispatch(setObj(null));
    navigate(returnUrl);
  };
  const handleLogoutComapy = () => {
    console.log(
      "🚀 ~ handleLogoutComapy ~ handleLogoutComapy:",
      handleLogoutComapy
    );

    genericLogout(logoutGeneric)
      .then((res: any) => {
        handleDataDispatch(`${systemType}/login`);
      })
      .catch((err: any) => {
        console.log(err, "...error");
      })
      .finally(() => {});
  };
  const handleMenuItemClick = (path: string) => {
    setActiveMenuItem(path);
    navigate(path);
    if (systemType === "greentourism") {
      setActive(path);
    }
  };
  const active = systemType == "greentourism" ? activeTab : activeMenuItem;
  return (
    <div className={style.endApp}>
      <div>
        <div>
          <img src={logo} alt="logo" className={style?.imageContainer} />
          <div>
            {data?.map((i: any) => (
              <div
                className={classNames(
                  style.tab,
                  active === i.path ? style.activeTab : style.inactiveTab
                )}
                onClick={() => handleMenuItemClick(i.path)}
              >
                <img
                  src={i?.img}
                  className={classNames(
                    style.srcStyle,
                    active === i?.path ? style.activeImg : style.inactiveImg
                  )}
                />
                <div
                  className={classNames(
                    style.text,
                    active === i?.path ? style.activeText : style.inactiveText
                  )}
                >
                  {i?.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.logoutContainer} onClick={handleLogoutComapy}>
        <TbLogout
          size={16}
          className={style.logouticon}
          style={{ color: "#b01212" }}
        />
        <span className={style.logoutText}>Logout</span>
      </div>
    </div>
  );
};

export default SideBar_New;
