import React, { useEffect, useState } from "react";
import TableResult from "../../../shared/components/Tables/TableResult/index";
import styles from "./result.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "../../../shared/utils/common.module.css";
import { LoadingModal, RingLoader } from "shared/components";
import { LabGetAllResults } from "shared/services";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setRenderResultFlag,
  setResults,
  setResultslength,
} from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import TableNew from "shared/components/A_New_Components/Table_new";
import dayjs from "dayjs";
import { HiOutlineDownload, HiOutlineUpload } from "react-icons/hi";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
interface Item {
  testName: string;
  testCode: number;
  price: number;
}

const ResultList = [
  "Order ID ",
  "Patient Name",
  "MR No.",
  "Order Date & Time",
  "Results",
];

const Results = () => {
  const { results, renderResultFlag, resultslength } = useSelector(
    (state: any) => state.root.lab
  );
  const [itemAmount, setItemAmount] = useState<Item[]>([]);
  const [showResult, setShowResult] = useState<Item[]>([]);
  const [resultData, setResulData] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = resultslength;

  const navigate = useNavigate();
  const handleGoToOrderDeatil = (ind: any) => {
    const selectedObject: any = resultData[ind];
    console.log("🚀 ~ handleGoToOrderDeatil ~ selectedObject:", selectedObject);
    navigate(`/laboratory/order/Detail/${selectedObject?._id}`);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (resultslength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setRenderResultFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setRenderResultFlag(true));
    }
  };

  const fetchResults = (pageno: number) => {
    setLoading(true);
    LabGetAllResults(pageno)
      .then((res: any) => {
        dispatch(setResults(res?.data?.orders));
        let result = res?.data?.orders;
        setResulData(result);
        console.log("🚀 ~ .then ~ result:", result);
        let tempData: any = [];
        result?.map((v: any, ind: any) => {
          tempData.push([
            v?.orderId,
            v?.customerName,
            v?.MR_NO,
            dayjs(v?.createdAt).format("DD-MM-YYYY"),
            <div
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              onClick={(e) => {
                e.stopPropagation();
                if (v?.results) {
                  window.open(v.results, "_blank"); // Opens the file in a new tab
                } else {
                  console.error("No file available to open.");
                }
              }}
            >
              <HiOutlineDownload className={styles.Icon} />
              <p className={styles.Download}>Download</p>
            </div>,
          ]);
        });

        setShowResult(tempData);
      })

      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchResults(currentPage);
    dispatch(setRenderResultFlag(false));
  }, [renderResultFlag, currentPage]);

  return (
    <div className={classNames(commonstyles.col12)}>
      <div className={classNames(commonstyles.pr36)}>
        <div className={commonstyles.mb24}>
          <div className={classNames(commonstyles.flx)}>
            <p className={classNames(styles.heading)}>All Result</p>
            {loading ? (
              <div
                className={styles.outerRefresh}
                style={{ marginLeft: "16px" }}
              >
                <RingLoader color={"#0D47A1"} size={30} />
              </div>
            ) : (
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  onClick={() => fetchResults(pageno)}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.TableOuter}>
          <div className={commonstyles.flxBetween}>
            <div>
              <SearchFilter title={"Search "} />
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>{" "}
          <div className={classNames(commonstyles.mt24)}>
            {results && results.length > 0 ? (
              <TableNew
                titles={ResultList}
                data={showResult}
                headerWidth="20%"
                itemWidth="20%"
                height="315px"
                handleGoToDetail={handleGoToOrderDeatil}
              />
            ) : (
              <>
                <PhysiotheristsEmpty />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
