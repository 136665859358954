import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

interface Props {
  placeholder?: string;
  setData?: (time: string) => void;
  onTimeSelect?: (time: string) => void;
  onTimeChange?: (newTime: string) => void;
  defaultValue?: string;
  value?: string;
  type?: any;
  AM?: any;
}

const CheckIn = (props: Props) => {
  const { placeholder, type, AM, setData, onTimeChange, defaultValue, value } =
    props;

  const handleTimeChange = (newTime: any) => {
    if (newTime) {
      const formattedTime = dayjs(newTime).format(AM ? AM : "HH:mm");
      setData && setData(formattedTime);
      onTimeChange && onTimeChange(formattedTime);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        value={
          value
            ? dayjs(value, "HH:mm")
            : defaultValue
              ? dayjs(defaultValue, "HH:mm")
              : null
        }
        ampm={AM ? true : false}
        label={placeholder}
        sx={{
          width: "100%",
          "& .MuiStack-root": {
            overflow: "visible",
          },
          "& .MuiOutlinedInput-notchedOutline":
            type == "box"
              ? {
                alignItems: "center",
                border: "0.5px solid #ccc",
                borderRadius: "8px",
                // width: "101%",
                height:"45px",
                padding: "0px 8px",
                fontSize: "14px",
                fontStyle: "italic",
                color: "#999",
                fontFamily: '"Poppins", sans-serif',
              }
              : {
                width: "100%",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "0px",
                borderBottom: "1px solid",
                padding: "10px 0px",
                fontStyle: "italic",
                fontSize: "14px",
                fontFamily: '"Poppins", sans-serif',
              },
          "& .MuiIconButton-root": {
            padding: "0px",
            color: "#7D7D7D",
            right: "10px",
            top:"4px"
          },
          "& .MuiOutlinedInput-input": {
            height: type == "box" ? "0px" : "14px",
            color: "#00276d",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "14px",
            paddingLeft: "0px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "14px",
            fontStyle: "italic",
            top:"-6px",
            fontFamily: '"Poppins", sans-serif',
            color: type == "box" ? "#999" : "#00276d",
            position: "absolute",
            left: type == "box" ? "" : "-13px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
            fontSize: "14px",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            paddingLeft: type == "box" ? "10px" : "0px",
            "&:hover": {
              borderColor: "#6F6F72 !important",
            },
          },
        }}
        onChange={handleTimeChange}
      />
    </LocalizationProvider>
  );
};

export default CheckIn;
