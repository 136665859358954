import React, { useEffect, useState } from "react";
import styles from "./AmblulanceRoutes.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { PiDownloadSimpleBold } from "react-icons/pi";
import ImgPicker from "shared/components/Img-picker";
import empty from "assets/images/empty2.png";
import DoctorEmpty from "shared/components/DoctorEmpty";
import CustomSelectOrder from "pages/Laboratory/Orders/CustomSelectOrder";
import { PharmacyChangeStatus } from "shared/services";
import CustomOnRouteSelect from "pages/HomeServices/AmbulanceServices/OnRoute/CustomOnRouteSelect";
import { AmbulanceOnRouteStatusChange } from "shared/services/Ambulance";
import AmblanceLineChart from "shared/components/AmblanceCharts/AmblanceLineChart";
import AmblanceEmpty from "shared/components/AmblanceEmpty";

interface Props {
  data: any;
  onStatusChange: any;
}

function AmblulanceRoutes(props: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);
  const { data, onStatusChange } = props;

  const uploadStatus = (id: any, status: string, index: any) => {
    setSelectedIndex(index);

    AmbulanceOnRouteStatusChange(id, status)
      .then((res: any) => {
        onStatusChange();
      })
      .catch((err: any) => {
        setError(err?.response?.data?.message);
        // setTimeout(() => {
        //   onStatusChange();
        // }, 1000);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.payment}>
      <>
        <div className={styles.headerOuter}>
          <p className={styles.headerclass}>Date</p>
          <p className={styles.headerclass}>Time</p>
          <p className={styles.headerclass}>Ambulnace Name</p>
          <p className={styles.headerclass}>Ambulance No.</p>
          <p className={styles.headerclass}>Price</p>
          <p className={styles.headerclass}>Status</p>
        </div>

        <div className={styles.tableData}>
          <table style={{ margin: "0px" }}>
            <tbody className={styles.wapper}>
              {data.map((val: any, key: any) => (
                <tr className={styles.tableRow} key={key}>
                  <td className={styles.w20}>
                    {" "}
                    {new Date(val?.createdAt).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </td>

                  <td className={styles.w20}>
                    {" "}
                    {val.createdAt
                      ? new Date(val.createdAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "__"}
                  </td>
                  <td className={styles.w20}>
                    {val?.bidRequestId?.ambulanceName
                      ? val?.bidRequestId?.ambulanceName
                      : "__"}
                  </td>
                  <td className={styles.w20}>
                    {val?.bidRequestId ? val.bidRequestId.ambulanceNo : "__"}
                  </td>
                  <td className={styles.w20}>{val?.bidRequestId?.price}</td>
                  <td className={styles.w20}>
                    {" "}
                    {
                      <CustomOnRouteSelect
                        error={error}
                        setSelectedValue={(d: any) =>
                          uploadStatus(val._id, d, key)
                        }
                        initialValue={val.status}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
}

export default AmblulanceRoutes;
