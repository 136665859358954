import InsuranceCards from "pages/Services/Insurance/InsuranceCards";
import InsuranceServices from "pages/Services/Insurance/InsuranceServices";

import React from "react";
import { Routes, Route } from "react-router-dom";
import InsuranceDetails from "pages/Services/Insurance/InsuranceDetails";
import InsuracePaydetail from "pages/Services/Insurance/Insurancetravel/InsuraceDetail";
import InsuranceBookingDetail from "pages/Home/HomeNavBar/NavBarr/BookingMenu/InsuranceBookingDetail";

const InsuranceRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<InsuranceServices />} />
      <Route path="/InsuranceCards" element={<InsuranceCards />} />
      <Route path="/InsuranceDetails" element={<InsuranceDetails />} />
      <Route path="/Insurancepay" element={<InsuracePaydetail />} />

      <Route
        path="/insuranceBookingDetail"
        element={<InsuranceBookingDetail />}
      />
    </Routes>
  );
};

export default InsuranceRoutes;
