import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Hotel_sidebarData } from "shared/utils";
import SideBar_New from "shared/components/A_New_Components/SideBar_New";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ActivationCard from "shared/components/ActivationCard";
import { IoMdNotifications } from "react-icons/io";
import HotelDashboard from "pages/TravelTourism/Hotels/HotelDashboard";
import HotalPayment from "pages/TravelTourism/Hotels/HotalPayment";
import PaymentDescription from "pages/TravelTourism/Hotels/HotalPayment/PaymentDescription";
import { AddProperty,  HotelsBooking, Property } from "pages";
import HotelReserve from "pages/TravelTourism/Hotels/HotelReserve";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";
import { Notifications } from "shared/components";
const HotelMainAuth = () => {
  const navigate = useNavigate();
  const { systemType, user, token } = useSelector(
    (state: any) => state.root.common
  );
  useEffect(() => {
    navigate(`/${systemType}/dashboard`);
  }, []);
  return (
    <div className={style.parents}>
      <div className={classNames(style.sidebarContainer)}>
        <SideBar_New data={Hotel_sidebarData(systemType)} />
      </div>
      {/* Main content */}
      <div className={style.contentContainer}>
        <div className={style.tabbarCard} style={{}}>
          <div className={style.tabbar}>
            <div
              style={{
                marginBottom: "16px",
                gap: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={style.welcomeText}>Welcome</div>
              <div className={style.mainText}>{user?.name}</div>
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Notifications />
              <div className={style.greenTourism}>
                <img
                  src={user?.logo}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
          {user?.paidActivation === true ? null : <ActivationCard />}
        </div>
        <Routes>
          <Route
            path={`/${systemType}/dashboard`}
            element={<HotelDashboard />}
          />
           <Route
          path={`/${systemType}/paymentDetail`}
          element={<PaymentNavbarRoutes />}
        />
          <Route path="hotel/payment" element={<HotalPayment />} />
          <Route path="hotel/paymentDetails" element={<PaymentDescription />} />
          <Route path="hotel/Property" element={<Property />} />
          <Route path="hotel/AddProperty" element={<AddProperty />} />
          <Route path="hotel/reserve" element={<HotelReserve />} />
          <Route path="hotel/booking" element={<HotelsBooking />} />
        </Routes>
      </div>
    </div>
  );
};

export default HotelMainAuth;
