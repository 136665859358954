import { useSelector } from "react-redux";
import { ENDPOINTS } from "./endpoints";
export const GetColorCode = () => {
  const { systemType } = useSelector((state: any) => state?.root?.common);

  let sendLink: any = "";
  let resetPassword: any = "";
  let placeHolder = null;
  let forgotRout: any = "";
  let signUpRout: any = "";
  let loginEndpoint: any = "";
  let loginNavigate: any = "";
  let signUpEndPoint: any = "";
  let headerText: any = "";
  let newPasswordRout: any = "";
  let loginScreen: any = "";
  let logoutGeneric: any = "";
  switch (systemType) {
    case "user":
      forgotRout = `/${systemType}/forgot-password`;
      newPasswordRout = `/${systemType}/ResetPassword`;
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      loginScreen = `/${systemType}/login`;
      signUpRout = `/${systemType}/signup`;
      break;

    // .............................................doctor
    case "doctor":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.DOC_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginEndpoint = ENDPOINTS.DOC_LOGIN;
      loginNavigate = `/${systemType}/dashboard`;
      signUpEndPoint = ENDPOINTS.DOC_SIGNUP;
      loginScreen = `/${systemType}/login`;
      headerText = "Doctor";
      break;

    // /.......................................physiotherapist................../;
    case "physiotherapist":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.DOC_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginEndpoint = ENDPOINTS.DOC_LOGIN;
      loginNavigate = `/${systemType}/dashboard`;
      signUpEndPoint = ENDPOINTS.DOC_SIGNUP;
      loginScreen = `/${systemType}/login`;
      headerText = "Physiotherapist";
      break;

    // /.......................................nutritionist................../;
    case "nutritionist":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.DOC_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      loginEndpoint = ENDPOINTS.DOC_LOGIN;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginNavigate = `/${systemType}/dashboard`;
      loginScreen = `/${systemType}/login`;
      signUpEndPoint = ENDPOINTS.DOC_SIGNUP;
      headerText = "Nutritionist";
      break;
    // /.......................................psychologist................../;

    case "psychologist":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.DOC_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginEndpoint = ENDPOINTS.DOC_LOGIN;
      loginNavigate = `/${systemType}/dashboard`;
      loginScreen = `/${systemType}/login`;
      signUpEndPoint = ENDPOINTS.DOC_SIGNUP;
      headerText = "Psychologist";
      break;
    // /.......................................psychologist................../;
    case "paramedic":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.DOC_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      loginEndpoint = ENDPOINTS.DOC_LOGIN;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginNavigate = "/paramedicStaff/dashboard";
      loginScreen = `/${systemType}/login`;
      signUpEndPoint = ENDPOINTS.DOC_SIGNUP;
      headerText = "Paramedic";
      break;
    // /.......................................Hospital................../;

    case "hospital":
      placeHolder = {
        name: "Hospital Name*",
        logo: "Hospital Logo*",
        licenseNo: "Hospital Registration Number",
        licenseExpiry: "Hospital Registration Expiry",
        description: "Hospital Description*",
        openTime: "Hospital Open Time*",
        closeTime: "Hospital Close Time*",
        address: "Hospital Address*",
        licenseImage: "Hospital Registration Image",
      };
      signUpRout = `/${systemType}/signup`;
      logoutGeneric = ENDPOINTS.LOGOUT_HOSPITAL;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginScreen = `/${systemType}/login`;
      loginEndpoint = ENDPOINTS.HOS_LOGIN;
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      resetPassword = ENDPOINTS.HOS_RESET_PASSWORD;
      signUpEndPoint = ENDPOINTS.HOS_SIGNUP;
      headerText = "Hospital";
      break;

    // .......................greenTourism
    case "greentourism":
      logoutGeneric = ENDPOINTS.TRAVEL_COMPANY_LOGOUT;
      break;

    // /.......................................Laboratory................../;
    case "laboratory":
      placeHolder = {
        name: "Lab Name*",
        logo: "Lab Logo*",
        licenseNo: "Lab License Number",
        licenseExpiry: "Lab License Expiry",
        description: "Lab Description*",
        openTime: "Lab Open Time*",
        closeTime: "Lab Close Time*",
        address: "Laboratory Address*",
        licenseImage: "Lab License Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = `/${systemType}/signup`;
      logoutGeneric = ENDPOINTS.LAB_LOGOUT;
      forgotRout = `/${systemType}/forgot-password`;
      newPasswordRout = `/${systemType}/ResetPassword`;
      resetPassword = ENDPOINTS.LAB_RESET_PASSWORD;
      loginScreen = `/${systemType}/login`;
      loginEndpoint = ENDPOINTS.LOGIN;
      loginNavigate = "/laboratory/dashboard";
      signUpEndPoint = ENDPOINTS.LAB_SIGNUP;
      headerText = "Laboratory";
      break;

    // /.......................................Pharmacy................../

    case "pharmacy":
      placeHolder = {
        name: "Pharmacy Name*",
        logo: "Pharmacy Logo*",
        licenseNo: "Pharmacy License Number",
        licenseExpiry: "Pharmacy License Expiry",
        description: "Pharmacy Description*",
        openTime: "Pharmacy Open Time*",
        closeTime: "Pharmacy Close Time*",
        address: "Pharmacy Address*",
        licenseImage: "Pharmacy License Image",
      };

      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = `/${systemType}/signup`;
      loginScreen = `/${systemType}/login`;
      loginEndpoint = ENDPOINTS.PH_LOGIN;
      forgotRout = `/${systemType}/forgot-password`;
      resetPassword = ENDPOINTS.PH_RESET_PASSWORD;
      loginNavigate = "/pharmacy/dashboard";
      newPasswordRout = `/${systemType}/ResetPassword`;
      signUpEndPoint = ENDPOINTS.PH_SIGNUP;
      headerText = "Pharmacy";
      break;

    // /....................................Pharmecutical................./
    case "pharmaceutical":
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.PH_RESET_PASSWORD;
      forgotRout = `/${systemType}/forgot-password`;
      signUpRout = `/${systemType}/signup`;
      loginScreen = `/${systemType}/login`;
      loginEndpoint = ENDPOINTS.PHARMACEUTICAL_LOGIN;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginNavigate = `${systemType}/dashboard`;
      signUpEndPoint = ENDPOINTS.PHARMACEUTICAL_REGISTER;
      headerText = "Pharmaceutical";
      break;

    // /.......................................Ambulance................../

    case "ambulance":
      placeHolder = {
        name: "Company Name*",
        logo: "Company Logo*",
        licenseNo: "Company Registration Number",
        licenseExpiry: "Company Registration Expiry",
        description: "Company Description*",
        address: "Company Address*",
        licenseImage: "Company Registration Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = "/homeservices/ambulanceservices/signup";
      loginScreen = "/homeservices/ambulanceservices/login";
      forgotRout = "/homeservices/ambulanceservices/forgot-password";
      resetPassword = ENDPOINTS.AMB_RESET_PASSWORD;
      loginEndpoint = ENDPOINTS.AMB_LOGIN;
      loginNavigate = "ambulance/dashboard";
      newPasswordRout = "/homeservices/ambulanceservices/ResetPassword";
      headerText = "Ambulance";
      signUpEndPoint = ENDPOINTS.AMB_SIGNUP;
      break;

    // /.......................................Insurance................../

    case "insurance":
      placeHolder = {
        name: "Company Name*",
        logo: "Company Logo*",
        licenseNo: "Company License Number",
        licenseExpiry: "License Expiry",
        description: "Company Description*",
        address: "Company Address*",
        licenseImage: "Company Registration Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      resetPassword = ENDPOINTS.INSURANCE_NEWPASSWORD;
      signUpRout = `/${systemType}/signup`;
      loginScreen = `/${systemType}/login`;
      forgotRout = `/${systemType}/forgot-password`;
      loginEndpoint = ENDPOINTS.INSURANCE_LOGIN;
      newPasswordRout = `/${systemType}/ResetPassword`;
      loginNavigate = "/insurance/dashboard";
      signUpEndPoint = ENDPOINTS.INSURANCE_SIGNUP;
      headerText = "Insurance";
      break;
    case "donation":
      placeHolder = {
        name: "Company Name*",
        logo: "Company Logo*",
        licenseNo: "Company License Number",
        licenseExpiry: "License Expiry",
        description: "Company Description*",
        address: "Company Address*",
        licenseImage: "Company Registration Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = `/${systemType}/signup`;
      resetPassword = ENDPOINTS.DONATION_NEWPASSWORD;
      newPasswordRout = `/${systemType}/ResetPassword`;
      forgotRout = `/${systemType}/forgot-password`;
      loginEndpoint = ENDPOINTS.DONATION_LOGIN;
      signUpEndPoint = ENDPOINTS.DONATION_SIGNUP;
      headerText = "Donation";
      break;
    case "rentacar":
      placeHolder = {
        name: "Company Name*",
        logo: "Company Logo*",
        licenseNo: "Company License Number",
        licenseExpiry: "License Expiry",
        description: "Company Description*",
        address: "Company Address*",
        licenseImage: "Company Registration Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = "/traveltourism/rentAcar/signup";
      loginScreen = "/traveltourism/rentAcar/login";
      forgotRout = "/traveltourism/rentAcar/forgot-password";
      newPasswordRout = "/traveltourism/rentAcar/ResetPassword";
      resetPassword = ENDPOINTS.RENTCAR_NEWPASSWORD;
      loginEndpoint = ENDPOINTS.RENTCAR_LOGIN;
      loginNavigate = "/rentacar/dashboard";
      signUpEndPoint = ENDPOINTS.RENTCAR_SIGNUP;
      headerText = "Rent A Car";
      break;

    // /.......................................Travel Agency................../

    case "travelagency":
      placeHolder = {
        name: "Company Name*",
        logo: "Company Logo*",
        licenseNo: "Company License Number",
        licenseExpiry: "License Expiry",
        address: "Company Address*",
        licenseImage: "License Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      forgotRout = "/traveltourism/travelAgency/forgot-password";
      resetPassword = ENDPOINTS.TRAVELAGENCY_NEWPASSWORD;
      signUpRout = "/traveltourism/travelAgency/signup";
      newPasswordRout = "/traveltourism/travelAgency/ResetPassword";
      loginEndpoint = ENDPOINTS.TRAVELAGENCY_LOGIN;
      loginScreen = "/traveltourism/travelAgency/login";
      loginNavigate = "/travelAgency/dashboard";
      signUpEndPoint = ENDPOINTS.TRAVELAGENCY_SIGNUP;
      headerText = "Travel Agency";
      break;
    // /.......................................Hotel................../
    // ..............................greentourism

    case "hotel":
      placeHolder = {
        name: "Hotel Name*",
        logo: "Hotel Image*",
        licenseNo: "Hotel License Number",
        licenseExpiry: "License Expiry",
        address: "Hotel Address*",
        licenseImage: "License Image",
      };
      sendLink = ENDPOINTS.FORGET_VERIFICATION_PASSWORD;
      signUpRout = "/traveltourism/hotel/signup";
      forgotRout = "/traveltourism/hotel/forgot-password";
      loginScreen = "/traveltourism/hotel/login";
      newPasswordRout = "/traveltourism/hotel/ResetPassword";
      resetPassword = ENDPOINTS.HOTEL_NEWPASSWORD;
      logoutGeneric = ENDPOINTS.HOTEL_LOGOUT;
      loginEndpoint = ENDPOINTS.HOTEL_LOGIN;
      loginNavigate = "/hotel/dashboard";
      signUpEndPoint = ENDPOINTS.HOTEL_SIGNUP;
      headerText = "Hotel";
      break;
    default:
      break;
  }

  return {
    resetPassword,
    loginScreen,
    sendLink,
    signUpRout,
    signUpEndPoint,
    forgotRout,
    placeHolder,
    headerText,
    loginEndpoint,
    newPasswordRout,
    loginNavigate,
    logoutGeneric,
  };
};
