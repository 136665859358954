import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getOrderDetails } from "shared/services";
import { LabOrderItems, LoadingModal, Order_Details } from "shared/components";

interface UserDetails {
  phone: number;
}

interface Item {
  testName: string;
  testCode: number;
  price: number;
}
export default function OrderDetail() {
  const [orderdetail, setOrderdetail] = useState<any>(null);
  console.log("🚀 ~ OrderDetail ~ orderdetail:", orderdetail);
  const [user, setuser] = useState<UserDetails | null>(null);
  const [itemAmount, setItemAmount] = useState<Item[]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState<any>([]);
  const handleGoToBack = () => {
    navigate("/laboratory/order");
  };
  const orderDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      getOrderDetails(id)
        .then((res: any) => {
          let orders = res?.data?.order;
          let tempData: any = [];

          setOrderdetail(orders);
          setuser(orders.userId);
          const itemIds = orders.items.map((v: any, item: any) => {
            tempData.push([
              v?.itemId?.testCode,
              v?.itemId?.testNameId?.name,
              v?.itemId?.price,
              v?.itemId?.userAmount,
            ]);
          });

          // orders?.map((v: any, index: number) => {
          //   console.log("🚀 ~ data?.map ~ v:", v);
          // });
          setTableData(tempData);

          const quantitiesArray = orders.items.map(
            (item: any) => item.quantity
          );
          setQuantities(quantitiesArray);
        })

        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    orderDetail();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <Order_Details
        tableData={tableData}
        data={orderdetail}
        handleGoToBack={handleGoToBack}
        title={"Order"}
      />
    </div>
  );
}
