import React, { useState, useEffect } from "react";
import style from "./DonationDashBoard.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import User from "assets/images/Userr2.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import CustomLineChartDonation from "shared/components/DonationLineChar/LineChart";
import PieChartComponent from "shared/components/DonationLineChar/DonationPieChart";
import {
  donationDONORLIST,
  donationGRAPH,
  donationGraphDETAILSUpperPortion,
  donationTOPDONORS,
} from "shared/services/Donation";
import { LoadingModal } from "shared/components";
import {
  setDonationGraphDetails,
  setGraphdata,
  setTodayDonations,
  setTopDonorsArray,
  setYesterdayDonations,
} from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";

const data = [
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
  { name: "", value: 0 },
];
function DonationDashBoard() {
  const [loading, setLoading] = useState(false);
  const {
    todayDonations,
    yesterdayDonations,
    topDonorsArray,
    graphdata,
    donationGraphDetails,
  } = useSelector((state: any) => state.root.donation);

  const dispatch = useDispatch();
  // const [graphDetails, setGraphDetails] = useState({
  //   totalDonations: "",
  //   donatedPeople: "",
  //   totalPackages: "",
  // });

  graphdata.forEach(
    (item: { day: string; totalAmount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value = item.totalAmount;
    }
  );

  const GraphDetails = () => {
    setLoading(true);
    donationGraphDETAILSUpperPortion()
      .then((res: any) => {
        if (res?.data) {
          dispatch(
            setDonationGraphDetails({
              totalDonations: res.data.totalAmount,
              donatedPeople: res.data.totalDonors,
              totalPackages: res.data.totalPackages,
            })
          );
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    totalAmount: number;
  }
  const GraphOnly = () => {
    setLoading(true);

    donationGRAPH()
      .then((res: any) => {
        if (res?.status === 200) {
          const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
          // Use map to convert date to day in currentWeekData
          const dayArray = currentWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, totalAmount: item.totalAmount };
          });
          let popped = dayArray.pop();
          dispatch(setGraphdata(dayArray));
          // Set values in the data array based on dayArray
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDonorLists = () => {
    donationDONORLIST()
      .then((res: any) => {
        dispatch(setTodayDonations(res?.data?.todayDonations?.donations));
        dispatch(
          setYesterdayDonations(res?.data?.yesterdayDonations?.donations)
        );
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTopDonors = () => {
    donationTOPDONORS()
      .then((res: any) => {
        dispatch(setTopDonorsArray(res?.data?.donations));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // GraphOnly();
    GraphDetails();
    GraphOnly();
    fetchDonorLists();
    fetchTopDonors();
  }, []);

  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />
        <div className={classNames(style.flx, commonstyles.mr87)}>
          <div className={style.w70}>
            <div
              className={classNames(style.flx, style.mt32, commonstyles.col12)}
            >
              <div>
                <div className={style.Carda}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold
                    )}
                  >
                    {donationGraphDetails?.totalDonations}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.mt8
                    )}
                  >
                    Total Donations
                  </p>
                </div>
              </div>
              <div>
                <div className={style.Cardb}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold
                    )}
                  >
                    {donationGraphDetails?.donatedPeople}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.mt8
                    )}
                  >
                    Donated People
                  </p>
                </div>
              </div>
              <div>
                <div className={style.Cardc}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold
                    )}
                  >
                    {donationGraphDetails?.totalPackages}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      style.mt8
                    )}
                  >
                    Total Packages
                  </p>
                </div>
              </div>
            </div>
            <div className={style.graph}>
              <CustomLineChartDonation data={data} />
            </div>
            <div className={commonstyles.flx}>
              <div className={style.graph2}>
                <p className={classNames(commonstyles.fs20)}>
                  Donation By Types
                </p>
                <PieChartComponent />
              </div>
              <div className={style.graph3}>
                <p
                  style={{ marginBottom: "8px" }}
                  className={classNames(commonstyles.fs20)}
                >
                  Top Donors
                </p>
                <div className={style.flagTop}>
                  {topDonorsArray.map((data: any) => (
                    <div className={classNames(commonstyles.flx, style.mt24)}>
                      <Avatar
                        src={data?.userId?.userImage}
                        className={style.Avatar}
                      />
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        {data?.donorName}
                      </p>
                      <p className={classNames(commonstyles.fs14, style.end)}>
                        {data?.donationAmount}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={style.w30}>
            <div className={style.activites}>
              <p className={classNames(commonstyles.fs16)}>Recent Activities</p>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    style.my16,
                    commonstyles.semiBold
                  )}
                >
                  Today
                </p>
              </div>
              <div>
                <div className={style.flagToday}>
                  {todayDonations.map((donation: any) => (
                    <div className={classNames(commonstyles.flx, style.mt24)}>
                      <Avatar
                        src={donation?.userId?.userImage}
                        className={style.Avatar}
                      />
                      <div>
                        <p
                          className={classNames(
                            commonstyles.fs16,
                            commonstyles.semiBold
                          )}
                        >
                          {donation?.userId?.name}
                        </p>
                        <p className={classNames(commonstyles.fs12, style.mt8)}>
                          {donation?.donationAmount}
                        </p>
                      </div>
                      <p className={classNames(commonstyles.fs8, style.end)}>
                        {donation &&
                          donation.createdAt &&
                          new Date(donation.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold,
                    style.my16
                  )}
                >
                  Yesterday
                </p>
              </div>
              <div className={style.flagyesterday}>
                {yesterdayDonations.map((donation: any) => (
                  <div className={classNames(commonstyles.flx, style.mt24)}>
                    <Avatar
                      src={donation?.userId?.userImage}
                      className={style.Avatar}
                    />
                    <div>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        {donation?.userId?.name}
                      </p>
                      <p className={classNames(commonstyles.fs12, style.mt8)}>
                        {donation?.donationAmount}
                      </p>
                    </div>
                    <p className={classNames(commonstyles.fs8, style.end)}>
                      {donation &&
                        donation.createdAt &&
                        new Date(donation.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default DonationDashBoard;
