import React from "react";
import styles from "./SocialInfo.module.css";

interface Props {
  formik?: any;
}

const BankDetails = (props: Props) => {
  const { formik } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.socialInfoContainer}>
        <div className={styles.rowSocial}>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              value={formik?.values?.bankName}
              onChange={formik?.handleChange("bankName")}
              type="text"
              id="bankName"
              placeholder="Bank Name (Optional)"
            />
          </div>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              value={formik?.values?.accountNumber}
              onChange={formik?.handleChange("accountNumber")}
              type="text"
              id="accountNumber"
              placeholder="IBAN / ACC Number (Optional)"
            />
          </div>
        </div>
        <div className={styles.rowSocial}>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              value={formik?.values?.accountTitle}
              onChange={formik?.handleChange("accountTitle")}
              type="text"
              id="accountTitle"
              placeholder="Account Title (Optional)"
            />
          </div>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              value={formik?.values?.ntn}
              onChange={formik?.handleChange("ntn")}
              type="text"
              id="ntn"
              placeholder="NTN (Optional)"
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.continueButton} type="submit">
            Next →
          </button>
        </div>
      </div>
    </form>
  );
};

export default BankDetails;
