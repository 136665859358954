import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import drimg from "assets/images/HospitalDashboard/drimg.png";
import { TiTick, TiTimes } from "react-icons/ti";
import { features } from "process";
import { getTreatmentDetail } from "shared/services";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import RingLoader from "shared/components/RingLoader";
import { TbRefresh } from "react-icons/tb";
import TreatmentDetails from "./TreatmentandDetails";

const SurgeryData = ({ item }: any) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [itemset, setItem] = useState("");
  // console.log("🚀 ~ SurgeryData ~ data:", data);

  const [showModel, setShowModel] = useState(false);
  const handleshowModel = (item: any) => {
    setItem(item);
    setShowModel((prev) => !prev);
  };

  useEffect(() => {
    fetchdetail("");
  }, [item]);

  const fetchdetail = (keyword: any) => {
    setLoading(true);
    getTreatmentDetail(item, keyword)
      .then((res: any) => {
        // console.log("🚀 ~ .then ~ res:", res?.data);
        console.log(res?.data?.doctors, "....");
        setData(res?.data?.doctors);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearch = () => {
    fetchdetail(search);
  };
  const handleRefresh = () => {
    setSearch("");
    fetchdetail("");
  };
  return (
    <>
      <div className={classNames(commonstyle.col12, style.maincontainer)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {loading ? (
            <div style={{ marginLeft: "32px" }}>
              <RingLoader color={"#0D47A1"} size={30} />
            </div>
          ) : (
            <TbRefresh className={style.refresh} onClick={handleRefresh} />
          )}
          <SearchFilter
            vender={false}
            search={search}
            title={"Search"}
            setSearch={setSearch}
            handleSearch={handleSearch}
          />
        </div>
        {data?.length > 0 ? (
          <div className={style.cardcontainer}>
            {data.map((item: any) => {
              console.log("🚀 ~ {data.map ~ item:", item);
              return (
                <div className={style.card}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={style.drimgcontainer}>
                        <img
                          src={
                            item?.doctor?.doctorImage ||
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                          }
                          alt=""
                          className={style.drimg}
                        />
                      </div>
                      <div className={style.textdata}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <p className={style.drname}>{item.doctor?.name}</p>

                          <div className={style.featureconatiner}>
                            <p className={style.feature}>Featured Doctor</p>
                          </div>
                        </div>

                        <p className={style.pmdc}>{item.Verified}</p>
                        <div>
                          <p className={style.qualification}>
                            {item.doctor?.qualifications}
                          </p>
                        </div>
                        <div className={classNames(style.bottom)}>
                          <div>
                            <p className={style.bottomtitle}>
                              {item.doctor?.clinicExperience}
                            </p>
                            <p className={style.bottomlabel}>Experience</p>
                          </div>
                          <div className={style.bottomseprator}> </div>
                          <div>
                            <p className={style.bottomtitle}>
                              {item.doctor?.satisfiedPatientCount}
                            </p>
                            <p className={style.bottomlabel}>
                              Satisfied Patient
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={style.innercardcontainer}>
                      <div className={style.innercard}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p>Basic</p>
                          <p>
                            <span>RS</span> <span>{item?.totalAmount}</span>
                          </p>
                        </div>
                        <div>
                          <ul
                            style={{
                              listStyle: "none",
                              padding: 0,
                              marginTop: "12px",
                            }}
                          >
                            {Object.entries(item?.treatment).map(
                              ([key, value]: any) => {
                                // console.log("🚀 ~ {data.map ~ key:", key);
                                console.log(
                                  "🚀 ~ {Object.entries ~ value:",
                                  value
                                );
                                return (
                                  // <></>
                                  <li
                                    key={key}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {value ? (
                                      <TiTick
                                        color="green"
                                        style={{ marginRight: "8px" }}
                                      />
                                    ) : (
                                      <TiTimes
                                        color="red"
                                        style={{ marginRight: "8px" }}
                                      />
                                    )}
                                    <p className={style.basic}>{key}</p>
                                    {/* {key !== "other" && (
                                  )}
                                  {value !== Boolean && (
                                    <p className={style.basic}>{value}</p>
                                  )} */}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                        <div
                          onClick={() => handleshowModel(item)}
                          style={{
                            marginTop: "15px",
                          }}
                        >
                          <button className={style.innerbtn}>View</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <PhysiotheristsEmpty />
          </div>
        )}
      </div>
      {showModel && (
        <TreatmentDetails showModel={showModel} setShowModel={setShowModel} data={itemset} />
      )}
    </>
  );
};

export default SurgeryData;
