import classNames from "classnames";
import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  CustomInput,
  CustomModal,
  InputField,
  PrimaryButton,
} from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import { addTestToLab, getAllTests } from "shared/services";
import { labAddTestInfo } from "shared/utils";
import CustomModalCustom from "./CustomModalCustom";
import toast from "react-hot-toast";
import TextAreaField from "shared/components/A_New_Components/NewTextArea";
import New_CustomSelect from "shared/components/A_New_Components/NewCustomSelect";

const options = [
  { label: "Blood", value: "Blood" },
  { label: "Bone Marrow", value: "Bone Marrow" },
  { label: "Breath", value: "Breath" },
  { label: "Cerebrospinal Fluid ", value: "Cerebrospinal Fluid" },

  { label: "Feces", value: "Feces" },

  { label: "Hair and Nails", value: "Hair and Nails" },

  { label: "Saliva", value: "Saliva" },
  { label: "Seminal Fluid", value: "Seminal Fluid" },

  { label: "Sputum", value: "Sputum" },
  { label: "Swabs", value: "Swabs" },
  { label: "Sweat", value: "Sweat" },

  { label: "Tissue", value: "Tissue" },

  { label: "Urine", value: "Urine" },
];
function AddTest() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [newTestName, setNewTestName] = useState("");
  const formik = useFormik({
    initialValues: {
      testName: "",
      //   testCategory: "",
      // testAddTest_TestCode: "",
      SpecimenType: "",
      testDescription: "",
      testPrice: "",
      priceForMeditour: "",
    },
    validationSchema: Yup.object({
      testName: newTestName
        ? Yup.string()
        : Yup.string()
            .trim()
            .min(2, "Please enter at least 2 characters.")
            .max(64, "Test name should not exceed 64 characters.")
            .required("Required"),
      testDescription: Yup.string()
        .trim()
        .min(2, "Please enter at least 2 characters.")
        .max(1000, "Description should not exceed 1000 characters.")
        .required("Required"),
      testPrice: Yup.string()
        // .min(2, "Must be at least 2 characters long")
        .matches(/^\d+$/, "Please enter a valid number")
        .required("Required"),
      SpecimenType: Yup.string()
        // .min(2, "Must be at least 2 characters long")
        .required("Required"),
      priceForMeditour: Yup.string()
        .required("Required")
        .test(
          "is-less-than-actualPrice",
          "MediTour price should be less than the Actual Price",
          function (value) {
            const { testPrice } = this.parent;
            const mediTourPrice = Number(value);
            const actualPrice = Number(testPrice);

            if (isNaN(mediTourPrice) || isNaN(actualPrice)) {
              return false;
            }
            return mediTourPrice < actualPrice;
          }
        ),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const currentData = formik.values;
    setLoading(true);

    let params = {
      testDescription: currentData?.testDescription,
      price: currentData?.testPrice,
      specimen: currentData?.SpecimenType,
      priceForMeditour: currentData?.priceForMeditour,
      ...(newTestName
        ? { name: newTestName }
        : { testNameId: currentData?.testName }),
    };

    addTestToLab(params)
      .then((res: any) => {
        if (res.data.auth) {
          navigate("/laboratory/test");
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGoback = () => {
    navigate(`/laboratory/test`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    setNewTestName("");
    if (query) {
      fetchAllTests(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchQuery(suggestion?.name);
    formik.setFieldValue("testName", suggestion?._id);

    setSuggestions([]);
  };
  // useEffect(() => {
  //   if (newTestName) {
  //     setSearchQuery(newTestName);
  //     setSuggestions([]);
  //   }
  // }, [newTestName]);
  // useEffect(() => {
  //   if (newTestName && newTestName !== searchQuery) {
  //     setSearchQuery(newTestName);
  //     setSuggestions([]);
  //     formik.setFieldTouched("testName", false);
  //     formik.setFieldError("testName", undefined);
  //   }
  // }, [newTestName]);

  const handleValueChange = (name: string, value?: string) => {
    // setCost(text);
    formik.setFieldValue(name, value);
  };

  const fetchAllTests = (search: string) => {
    getAllTests(1, search)
      .then((res: any) => {
        //
        setSuggestions(res?.data?.data);
      })
      .catch((err: any) => {});
  };
  const handleNewTestName = (testName: any) => {
    setNewTestName(testName);
    setSearchQuery(testName);
  };
  return (
    <div className={classNames(commonstyles.col12)}>
      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <p className={classNames(styles.heading)}>Add Test</p>
        </div>
      </div>
      <div className={styles.outer}>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "16px",
          }}
        >
          <div className={classNames(commonstyles.col6, commonstyles.colsm10)}>
            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(styles.subHeading)}>Available Tests</p>
            </div>

            <div className={styles.mmmmk} style={{ position: "relative" }}>
              <InputField
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search Test"
              />

              {suggestions?.length > 0 && (
                <div
                  className={styles.searchResults}
                  style={{
                    position: "absolute",
                    top: "90%",
                    left: "0",
                    backgroundColor: "white",
                    zIndex: 10,
                    maxHeight: "200px",
                    overflow: "auto",
                    padding: "16px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    margin: "0",
                    width: "100%",
                    borderRadius: "18px",
                  }}
                >
                  <ul
                    style={{
                      padding: "0",
                      margin: "0",
                      listStyleType: "none",
                    }}
                  >
                    {suggestions.map((suggestion: any, index) => (
                      <li
                        key={index}
                        className={styles.suggestionItem}
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{
                          padding: "2px",
                        }}
                      >
                        {suggestion?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {formik.touched.testName && formik.errors.testName && (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testName}
                </div>
              )}
            </div>
          </div>

          <div className={classNames(commonstyles.col6, commonstyles.colsm10)}>
            <New_CustomSelect
              label="Select an option"
              id="SpecimenType"
              height="46px"
              selectedOption={formik.values.SpecimenType}
              setSelectedOption={(value) =>
                formik.setFieldValue("SpecimenType", value)
              }
              options={options}
            />

            {formik.touched.SpecimenType && formik.errors.SpecimenType ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.SpecimenType}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonstyles.col6, commonstyles.colsm5)}>
            <InputField
              placeholder="Actual Price"
              id="testPrice"
              name="testPrice"
              type="text"
              onChange={(e: any) =>
                handleValueChange("testPrice", e.target.value)
              }
              value={formik.values.testPrice}
            />

            {formik.touched.testPrice && formik.errors.testPrice ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.testPrice}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonstyles.col6, commonstyles.colsm5)}>
            <InputField
              placeholder="MediTour Price"
              id="priceForMeditour"
              name="priceForMeditour"
              type="text"
              onChange={(e: any) =>
                handleValueChange("priceForMeditour", e.target.value)
              }
              value={formik.values.priceForMeditour}
            />

            {formik.touched.priceForMeditour &&
            formik.errors.priceForMeditour ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.priceForMeditour}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonstyles.col6, commonstyles.colsm10)}>
            <TextAreaField
              placeholder="Test Description"
              id="testDescription"
              name="testDescription"
              onChange={(e: any) =>
                handleValueChange("testDescription", e.target.value)
              }
              value={formik.values.testDescription}
            />

            {formik.touched.testDescription && formik.errors.testDescription ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.testDescription}
              </div>
            ) : null}
          </div>

          <button className={styles.SaveBtn} disabled={loading} type="submit">
            {loading ? "loading..." : "Save"}
          </button>
        </form>
      </div>
      <CustomModal
        showModal={showAddModal}
        children={
          <CustomModalCustom
            onClose={() => setShowAddModal(false)}
            fetchAllTests={fetchAllTests}
            setloading={setLoading}
            loading={loading}
            onAddTestName={handleNewTestName}
          />
        }
      />
    </div>
  );
}

export default AddTest;
