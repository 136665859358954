import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./main.module.css";
import logo from "assets/images/logoMeditour.png";
import iconDashe from "assets/images/iconDashe.png";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import GreenFlagDasheBoard from "./GreenFlagDasheBoard";
import ManageTravelAgency from "./ManageTravelAgency";
import ManageHotels from "./ManageHotels";
import DetailTravelAgency from "./DetailTravelAgency";
import { useDispatch, useSelector } from "react-redux";
import { TbLogout } from "react-icons/tb";
import { travelComayLogout } from "shared/services";
import { set_User, setFcmToken, setIsLoggedIn, setToken } from "shared/redux";
import HotelDetails from "./HotelDetails";
import { IoMdNotifications } from "react-icons/io";
import airoplain from "assets/images/airoplain.png";
import hotel from "assets/images/hotel.png";
import ActivationCard from "shared/components/ActivationCard";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";
import SideBar_New from "shared/components/A_New_Components/SideBar_New";
import { Notifications } from "shared/components";
import AddProperty from "pages/TravelTourism/Hotels/AddProperty";
const MainGreenFlagPanelRoutes = () => {
  const location = useLocation();
  const { user, systemType } = useSelector((state: any) => state.root.common);
  const [activeMenuItem, setActiveMenuItem] = useState(
    "/greentourism/dashboard"
  );
  const flagTitle = [
    {
      id: 1,
      label: "Dashboard",
      path: "/greentourism/dashboard",
      img: iconDashe,
    },
    {
      id: 2,
      label: "Manage Travel Agencies",
      path: "/greenFlag/ManageTravelAgency",
      img: airoplain,
    },
    {
      id: 3,
      label: "Manage Hotels",
      path: "/greenFlag/ManageHotels",
      img: hotel,
    },
  ];
  useEffect(() => {
    if (location.pathname.startsWith("/greenFlag/ManageTravelAgency/detail")) {
      setActiveMenuItem("/greenFlag/ManageTravelAgency");
    } else if (location.pathname.startsWith("/greenFlag/ManageHotels/detail")) {
      setActiveMenuItem("/greenFlag/ManageHotels");
    } else {
      setActiveMenuItem(location.pathname);
    }
  }, [location.pathname]);
  return (
    <div className={style.parents}>
      <div className={classNames(style.sidebarContainer)}>
        <SideBar_New
          data={flagTitle}
          setActive={setActiveMenuItem}
          activeTab={activeMenuItem}
        />
      </div>
      <div className={style.contentContainer}>
        <div className={style.tabbarCard} style={{}}>
          <div className={style.tabbar}>
            <div
              style={{
                gap: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={style.welcomeText}>Welcome</div>
              <div className={style.mainText}>{user?.name}</div>
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Notifications />
              <div className={style.greenTourism}>
                <img
                  src={user?.logo}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
          {user?.paidActivation === true ? null : <ActivationCard />}
        </div>
        <Routes>
          <Route
            path={"/greentourism/dashboard"}
            Component={GreenFlagDasheBoard}
          />
          <Route
            path="/greenFlag/ManageTravelAgency"
            Component={ManageTravelAgency}
          />

          <Route path="/greenFlag/ManageHotels" Component={ManageHotels} />

          <Route
            path="/greenFlag/ManageTravelAgency/detail"
            Component={DetailTravelAgency}
          />
          <Route
            path="/greenFlag/ManageHotels/detail"
            Component={HotelDetails}
          />
          <Route path="/greenFlag/AddProperty" element={<AddProperty />} />
          <Route
            path={`/${systemType}/paymentDetail`}
            element={<PaymentNavbarRoutes />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default MainGreenFlagPanelRoutes;
