import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NewTermCondition,
  OfferHotel,
  PropertySelection,
} from "shared/components";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import { addHotel } from "shared/services";
import styles from "./style.module.css";
import { useFormik } from "formik";
import { setHotelInfo } from "shared/redux";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";
const formikvalidationSchema = Yup.object({
  property: Yup.string().required("Property is required"),
  propertyName: Yup.string()
    .required("Property Name is required")
    .min(3, "Property Name must be at least 3 characters"),
  contactNumber: Yup.string().required("Contact Number is required"),
  numberOfBeds: Yup.number()
    .required("Number of Beds is required")
    .min(1, "Number of Beds must be at least 1")
    .integer("Number of Beds must be a whole number"),
  noOfGuests: Yup.number()
    .required("Number of Guests is required")
    .min(1, "Number of Guests must be at least 1")
    .integer("Number of Guests must be a whole number"),
  bathroomscount: Yup.number()
    .required("Number of Bathrooms is required")
    .min(1, "Number of Bathrooms must be at least 1")
    .integer("Number of Bathrooms must be a whole number"),
  bathroomstype: Yup.string().required("Bathroom Type is required"),
  propertyDetails: Yup.string().required("Bathroom Type is required"),
  address: Yup.string().when("property", {
    is: (value: any) => value === "suite" || value === "rooms",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Address is required"),
  }),
  city: Yup.string().when("property", {
    is: (value: any) => value === "suite" || value === "rooms",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("City is required"),
  }),
});
const validationSchema = Yup.object({
  features: Yup.array()
    .min(1, "Please select at least one feature")
    .required("Features are required"),
  parking: Yup.string().required("Parking option is required"),
  propertyCount: Yup.string().required("Property count is required"),
  checkInTime: Yup.string().required("Check-in time is required"),
  checkOutTime: Yup.string().required("check-out time is required"),
  propertyRent: Yup.string().required("Property rent is required"),
  meditourFee: Yup.string().required("Meditour fee is required"),
  smoking: Yup.string().required("Smoking option is required"),
  pets: Yup.string().required("Pets option is required"),
  extraBeds: Yup.string().required("Extra beds option is required"),
  safetyDetails: Yup.array()
    .min(1, "Please select at least one safety detail")
    .required("Safety details are required"),
  sorroundingProperty: Yup.string().required(
    "Surrounding property is required"
  ),
  propertyphotos: Yup.array()
    .min(1, "Please upload at least 1 photos")
    .required("Property photos are required"),
});
const termvalidationSchema = Yup.object({
  paymentMethods: Yup.array()
    .min(1, "At least one payment method must be selected")
    .required("Payment method is required"),
  cancellationWindow: Yup.string()
    .oneOf(["24hr", "36hr", "48hr"], "Invalid cancellation window selected")
    .required("Cancellation window is required"),
});
const AddProperty = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hotelInfo, user } = useSelector((state: any) => state?.root?.common);
  const location = useLocation();
  const { id } = location?.state;
  console.log("🚀 ~ AddProperty ~ id:", id);
  const hotelAdd = (val: any) => {
    setloading(true);
    const params = {
      // hotelId: id ? id : user?._id,
      property: hotelInfo?.property,
      propertyName: hotelInfo?.propertyName,
      contactNumber: hotelInfo?.contactNumber,
      numberOfBeds: hotelInfo?.numberOfBeds,
      noOfGuests: hotelInfo?.noOfGuests,
      noOfBathrooms: {
        count: hotelInfo?.bathroomscount,
        type: hotelInfo?.bathroomstype?.toLowerCase(),
      },
      propertyDetails: hotelInfo?.propertyDetails,
      features: hotelInfo?.data?.features,
      propertyCount: hotelInfo?.data?.propertyCount,
      checkInTime: hotelInfo?.data?.checkInTime,
      propertyRent: hotelInfo?.data?.propertyRent,
      meditourFee: hotelInfo?.data?.meditourFee,
      smoking: hotelInfo?.data?.smoking === "Not Allowed" ? false : true,
      pets: hotelInfo?.data?.pets === "Not Allowed" ? false : true,
      extraBeds: hotelInfo?.data?.extraBeds === "Not Allowed" ? false : true,
      safetyDetails: hotelInfo?.data?.safetyDetails,
      sorroundingProperty: hotelInfo?.data?.sorroundingProperty,
      propertyphotos: hotelInfo?.data?.propertyphotos,
      paymentMethods: val?.paymentMethods,
      checkOutTime: hotelInfo?.data?.checkOutTime,
      cancellationWindow: val?.cancellationWindow,
      parking: hotelInfo?.data?.parking == "Allowed" ? true : false,
      ...(hotelInfo?.property !== "suite" && hotelInfo?.property !== "rooms"
        ? {
            location: {
              lng: hotelInfo?.lng ?? 0,
              lat: hotelInfo?.lat ?? 0,
              address: hotelInfo?.address ?? "",
              city: hotelInfo?.city ?? "",
            },
          }
        : {}),
    };
    console.log(params, "....params");
    let hID = id ? id : user?._id;
    addHotel(hID, params)
      .then(() => {
        notifySuccess("Property Added Successfully");
        dispatch(setHotelInfo({}));

        let url = id ? "/greenFlag/ManageHotels" : "/hotel/Property";

        navigate(url);
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      property: "",
      propertyName: "",
      contactNumber: "",
      numberOfBeds: 1,
      noOfGuests: 1,
      bathroomscount: 1,
      bathroomstype: "",
      propertyDetails: "",
      address: "",
      lng: "",
      lat: "",
      city: "",
    },
    validationSchema: formikvalidationSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
  });
  const handleNext = (data: any) => {
    dispatch(setHotelInfo(data));
    setCurrentStep(1);
  };
  // .............secound.....................................................................................
  const offer = useFormik({
    initialValues: {
      features: [],
      parking: "",
      propertyCount: "",
      checkInTime: "",
      propertyRent: "",
      meditourFee: "",
      smoking: "",
      pets: "",
      checkOutTime: "",
      extraBeds: "",
      safetyDetails: [],
      sorroundingProperty: "",
      propertyphotos: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleValues(values);
    },
  });
  const handleValues = (data: any) => {
    const features = {
      ...hotelInfo,
      data,
    };
    dispatch(setHotelInfo(features));
    setCurrentStep(2);
  };
  //  ..................................////////////////.........................................................................
  const termFormik: any = useFormik({
    initialValues: {
      paymentMethods: [],
      cancellationWindow: "24hr",
    },
    validationSchema: termvalidationSchema,
    onSubmit: (values) => {
      hotelAdd(values);
    },
  });

  const handleMove = () => {
    if (currentStep == 0) {
      formik.handleSubmit();
    } else if (currentStep == 1) {
      offer.handleSubmit();
    } else {
      termFormik.handleSubmit();
    }
  };
  return (
    <div className={styles.container}>
      {currentStep === 0 ? (
        <PropertySelection formik={formik} />
      ) : currentStep === 1 ? (
        <OfferHotel formik={offer} />
      ) : (
        <NewTermCondition formik={termFormik} />
      )}
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${(currentStep / 2) * 100}%` }}
        ></div>
      </div>
      <div className={styles.buttonRow}>
        <button
          className={styles.backButton}
          onClick={() => {
            if (currentStep === 0) {
              let url = id ? "/greenFlag/ManageHotels" : "/hotel/Property";

              navigate(url);
              // navigate("/hotel/Property");
            } else {
              setCurrentStep((prev) => prev - 1);
            }
          }}
        >
          Back
        </button>
        <button
          type="submit"
          className={styles.nextButton}
          onClick={handleMove}
          disabled={loading}
        >
          {currentStep === 2 ? "Finish" : "Next"}
        </button>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default AddProperty;
