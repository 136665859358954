import React from "react";
import styles from "../ExploreAll/style.module.css";
import { useNavigate } from "react-router-dom";
import { data } from "./props";

const ExploreAll = () => {
  const navigate = useNavigate();
  const handlePress = (item: any) => {
    let data = { title: item.title, details: item?.details };
    navigate("/tourism", { state: data });
  };

  const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className={styles.container}>
      {sortedData?.map((item: any, index: any) => (
        <RectangleCard
          item={item}
          index={index}
          handlePress={() => handlePress(item)}
        />
      ))}
    </div>
  );
};
export default ExploreAll;
const RectangleCard = ({ item, index, handlePress }: any) => {
  return (
    <div onClick={handlePress} key={index} className={styles.rectangleCard}>
      <img src={item?.img} className={styles.image} />
      <div className={styles.absoluteContainer}>
        <p className={styles.text}>{item?.title}</p>
        <p className={styles.text2}>EXPLORE MORE</p>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};
