import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "../../Footer";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import Ambulance from "./AmbulanceRequest/Ambulance";
import TravelRequest from "./TravelandTour";
import {
  allBidRequest,
  getAllFlights,
  getUserAmbulanceReq,
} from "shared/services";
import { LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { MY_REQUEST } from "shared/utils/mainHeaderQuery";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";

const RequestAllow = () => {
  const [activeTab, setActiveTab] = useState<string>("Flight");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  // Handle tab changes and reset the loading state
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // Fetch data when the activeTab changes
  useEffect(() => {
    setLoading(true); // Set loading to true when data is being fetched
    if (activeTab === "Ambulance") {
      getRequestUser();
    } else {
      flightsRequest();
    }
  }, [activeTab, currentPage]);

  // Fetch ambulance request data
  const getRequestUser = () => {
    let data = {
      page: 1,
    };
    getUserAmbulanceReq(data)
      .then((res: any) => {
        setData(res.data.userRequests);
        setTotalPages(res.data.totalPages);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const flightsRequest = () => {
    let data = {
      page: 1,
    };
    getAllFlights(data)
      .then((res: any) => {
        setData(res.data.flightRequests || []);
        setTotalPages(res.data.totalPages);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false); // Set loading to false after data fetch
      });
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <div>
      <NavBreadCrumbs {...MY_REQUEST} />
      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <DoubleButton
          tab1Label="Flight"
          tab2Label="Ambulance"
          shift={activeTab}
          onTabChange={handleTabChange}
        />
        <div
          className={classNames(commonstyle.container)}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, data.length)}
            totalItems={data.length}
          />
        </div>
        {loading ? (
          <CustomLoader />
        ) : data && data.length > 0 ? (
          activeTab === "Ambulance" ? (
            <Ambulance data={data} />
          ) : (
            <TravelRequest data={data} flightsRequest={flightsRequest} />
          )
        ) : (
          <PhysiotheristsEmpty />
        )}
      </div>
      <Footerr />
    </div>
  );
};

export default RequestAllow;
