import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./ticketRequest.module.css";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import { BsClock } from "react-icons/bs";
import { LuCalendarDays, LuUser2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { travelFLIGHTGETREQUEST } from "shared/services/TravelAgency";
import {
  setTicketRequestLength,
  setTicketRequestRenderFlag,
  setTicketRequests,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal, RingLoader } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
export default function TicketRequest() {
  const { ticketRequests, ticketRequestRenderFlag, ticketRequestLength } =
    useSelector((state: any) => state.root.travelagency);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = ticketRequestLength;

  const navigate = useNavigate();
  const handleGoToDetail = (id: string, val?: any) => {
    if (val?.status === "bidSent") {
      navigate(`/travelAgency/ticketRequests/BidsDetail/${id}`, {
        state: { val },
      });
    } else if (val?.status === "approved") {
      navigate(`/travelAgency/ticketRequests/BidsDetail/${id}`, {
        state: { val },
      });
    } else {
      navigate(`/travelAgency/ticketRequests/Detail/${id}`, { state: { val } });
    }
  };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchticketRequest = (pageno: number) => {
    setLoading(true);
    travelFLIGHTGETREQUEST(pageno)
      .then((res: any) => {
        dispatch(setTicketRequests(res?.data?.requests));
        dispatch(setTicketRequestLength(res?.data?.totalRequests));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchticketRequest(currentPage);
  }, []);

  useEffect(() => {
    if (ticketRequestRenderFlag) {
      setLoading(true);
      fetchticketRequest(currentPage);
      dispatch(setTicketRequestRenderFlag(false));
    }
  }, [ticketRequestRenderFlag, currentPage]);

  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchticketRequest(currentPage);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (ticketRequestLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setTicketRequestRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setTicketRequestRenderFlag(true));
    }
  };
  useEffect(() => {
    fetchticketRequest(currentPage);
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div
            className={classNames(
              commonstyles.flxBetween,
              commonstyles.mb32,
              commonstyles.flxWrap
            )}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Ticket Request
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={styles.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          <div>
            {ticketRequests && ticketRequests.length > 0 ? (
              <>
                {/* Render Header only if there is data */}
                <div className={styles.card}>
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      <p>Name</p>
                    </div>

                    <div
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      <p>Date</p>
                    </div>

                    <div
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      <p>Time</p>
                    </div>

                    <div
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      <p>Flight Type</p>
                    </div>

                    <div
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      <p>Status</p>
                    </div>
                  </div>
                </div>

                {/* Render ticket request data */}
                {ticketRequests.map((val: any) => (
                  <div
                    className={styles.card}
                    onClick={() => handleGoToDetail(val._id, val)}
                    key={val.id}
                    style={{
                      backgroundColor:
                        val?.status === "approved"
                          ? "#FDCB2E"
                          : val?.status === "bidSent"
                          ? "#006838"
                          : "#f5f5f5",
                    }}
                  >
                    <div className={classNames(commonstyles.flx)}>
                      <div
                        style={{
                          width: "25%",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                        className={commonstyles.flx}
                      >
                        <LuUser2 className={styles.icon} />
                        <p
                          className={classNames(commonstyles.fs16)}
                          style={{
                            color:
                              val?.status === "bidSent" ? "#fff" : "#1A3D7C",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {val?.userId?.name}
                        </p>
                      </div>

                      <div
                        style={{
                          width: "25%",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <LuCalendarDays className={styles.icon} />
                          <span
                            className={classNames(commonstyles.fs16)}
                            style={{
                              marginLeft: "8px",
                              color:
                                val?.status === "bidSent" ? "#fff" : "#1A3D7C",
                            }}
                          >
                            {val?.createdAt
                              ? new Date(val.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  }
                                )
                              : "__"}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "25%",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <BsClock className={styles.icon} />
                          <span
                            className={classNames(commonstyles.fs16)}
                            style={{
                              marginLeft: "8px",
                              color:
                                val?.status === "bidSent" ? "#fff" : "#1A3D7C",
                            }}
                          >
                            {val &&
                              val.createdAt &&
                              new Date(val.createdAt).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "25%",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <p
                          className={classNames(commonstyles.fs16)}
                          style={{
                            textTransform: "capitalize",
                            color:
                              val?.status === "bidSent" ? "#fff" : "#1A3D7C",
                          }}
                        >
                          {val?.requestType}
                        </p>
                      </div>

                      <div
                        style={{
                          width: "25%",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <p
                          className={classNames(commonstyles.fs16)}
                          style={{
                            textTransform: "capitalize",
                            color:
                              val?.status === "bidSent" ? "#fff" : "#1A3D7C",
                          }}
                        >
                          {val?.status}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={classNames(styles.emptyOuter)}>
                <PhysiotheristsEmpty />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
