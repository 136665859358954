import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./InsuranceDashboard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import InsurancePieChart from "shared/components/InsuranceCharts/InsurancePieChart";
import InsuranceBarChart from "shared/components/InsuranceCharts/InsuranceBarChart";
import Request from "assets/images/InsuranceRequestIcon.png";
import Customer from "assets/images/insuranceCustomer.png";
import Revenu from "assets/images/InsuranceRevenu.png";
import InsuranceLineChart from "shared/components/InsuranceCharts/InsuranceStackChart";
import {
  insuranceDashDetails,
  insuranceMonthsGraph,
} from "shared/services/Insurance";
import {
  setDashboardMonthsArray,
  setInsuranceDashDetails,
  setInsuranceDashboardRenderFlag,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";

const data = [
  { name: "Mon", Health: 10, Travel: 75 },
  { name: "Tue", Health: 75, Travel: 100 },
  { name: "Wed", Health: 100, Travel: 125 },
  { name: "Thu", Health: 125, Travel: 150 },
  { name: "Fri", Health: 75, Travel: 100 },
  { name: "Sat", Health: 65, Travel: 25 },
  { name: "Sun", Health: 75, Travel: 72 },
];
const data2 = [
  { name: "Jan", PreviousCustomer: 90, NewCustomer: 0 },
  { name: "Feb", PreviousCustomer: 300, NewCustomer: 10 },
  { name: "Mar", PreviousCustomer: 20, NewCustomer: 350 },
  { name: "Apr", PreviousCustomer: 340, NewCustomer: 15 },
  { name: "May", PreviousCustomer: 20, NewCustomer: 250 },
  { name: "Jun", PreviousCustomer: 360, NewCustomer: 35 },
  { name: "Jul", PreviousCustomer: 15, NewCustomer: 350 },
  { name: "Aug", PreviousCustomer: 15, NewCustomer: 350 },
  { name: "Sep", PreviousCustomer: 300, NewCustomer: 350 },
  { name: "Oct", PreviousCustomer: 15, NewCustomer: 350 },
  { name: "Nov", PreviousCustomer: 100, NewCustomer: 150 },
  { name: "Dec", PreviousCustomer: 200, NewCustomer: 250 },

  // ... (other data points)
];
function InsuranceDashboard() {
  const {
    insuranceDashDetailss,
    insuranceDashboardRenderFlag,
    dashboardMonthsArray,
    insurance,
  } = useSelector((state: any) => state.root.insurance);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  //

  insuranceDashDetailss.healthPayments.forEach(
    (item: { Days: string; totalAmount: number }, index: number) => {
      data[index].name = item.Days;
      data[index].Health = item.totalAmount;
    }
  );

  insuranceDashDetailss.travelPayments.forEach(
    (item: { totalAmount: number }, index: number) => {
      data[index].Travel = item.totalAmount;
    }
  );

  dashboardMonthsArray.forEach(
    (
      item: { month: string; newCustomers: number; previousCustomers: number },
      index: number
    ) => {
      data2[index].name = item.month;
      data2[index].PreviousCustomer = item.previousCustomers;
      data2[index].NewCustomer = item.newCustomers;
    }
  );

  interface healthPaymentsArray {
    totalAmount: number;
    _id: string;
  }
  const GraphDetails = () => {
    setLoading(true);
    insuranceDashDetails()
      .then((res: any) => {
        const healthPayments: healthPaymentsArray[] = res.data.healthPayments;
        const healthArray = healthPayments.map((item: healthPaymentsArray) => {
          const dateObject = new Date(item._id);
          const dayName = dateObject.toLocaleDateString("en-US", {
            weekday: "short",
          });
          return {
            Days: dayName,
            totalAmount: item.totalAmount,
          };
        });

        const travelPayments: healthPaymentsArray[] = res.data.travelPayments;
        const travelArray = travelPayments.map((item: healthPaymentsArray) => {
          const dateObject = new Date(item._id);
          const dayName = dateObject.toLocaleDateString("en-US", {
            weekday: "short",
          });
          return {
            Days: dayName,
            totalAmount: item.totalAmount,
          };
        });

        dispatch(
          setInsuranceDashDetails({
            todayRequestCount: res.data.todayRequestCount,
            todayCustomerCount: res.data.todayCustomerCount,
            healthPayments: healthArray,
            travelPayments: travelArray,
            totalTravelPayments: res.data.totalTravelPayments,
            totalHealthPayments: res.data.totalHealthPayments,
            totalRevenue: res.data.totalRevenue,
          })
        );
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  interface MonthDataItem {
    month: string;
    newCustomers: number;
    previousCustomers: number;
  }
  const fetchMonthGraph = () => {
    setLoading(true);
    insuranceMonthsGraph()
      .then((res: any) => {
        const currentTodayRequest: MonthDataItem[] = res.data;
        const monthArray = currentTodayRequest.map((item: MonthDataItem) => {
          const dateObject = new Date(item.month);
          const Months = dateObject.toLocaleDateString("en-US", {
            month: "short",
          });
          return {
            month: Months,
            newCustomers: item.newCustomers,
            previousCustomers: item.previousCustomers,
          };
        });
        dispatch(setDashboardMonthsArray(monthArray));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (insuranceDashboardRenderFlag) {
      setLoading(true);

      GraphDetails();

      dispatch(setInsuranceDashboardRenderFlag(false));
    }
  }, [insuranceDashboardRenderFlag]);

  useEffect(() => {
    GraphDetails();
    fetchMonthGraph();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div
          className={classNames(
            style.bkground,
            commonstyles.flxWrap,
            style.mt5
          )}
        >
          <div
            className={classNames(
              commonstyles.col8,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
          >
            <div className={classNames(commonstyles.flx)}>
              <div className={classNames(commonstyles.col4, style.requestCard)}>
                <div className={style.requestCardiCon}>
                  {" "}
                  <img src={Request} className={style.iconss} />
                </div>
                <p
                  className={classNames(
                    commonstyles.fs36,
                    commonstyles.semiBold,
                    style.mt16
                  )}
                >
                  {insuranceDashDetailss?.todayRequestCount}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Today Request
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    style.mt8,
                    style.primaryCOlor
                  )}
                >
                  +8% from Last Week
                </p>
              </div>
              <div
                className={classNames(
                  commonstyles.col4,
                  style.TotalCustomerCard
                )}
              >
                <div className={style.TotalCustomerCardiCon}>
                  <img src={Customer} className={style.iconss} />
                </div>
                <p
                  className={classNames(
                    commonstyles.fs36,
                    commonstyles.semiBold,
                    style.mt16
                  )}
                >
                  {insuranceDashDetailss?.todayCustomerCount}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Total Customer
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    style.mt8,
                    style.primaryCOlor
                  )}
                >
                  +5% from Last Week
                </p>
              </div>
              <div
                className={classNames(commonstyles.col4, style.TotalRevenuCard)}
              >
                <div className={style.TotalRevenuiCon}>
                  <img src={Revenu} className={style.iconss} />
                </div>
                <p
                  className={classNames(
                    commonstyles.fs36,
                    commonstyles.semiBold,
                    style.mt16
                  )}
                >
                  25K
                </p>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    style.mt8
                  )}
                >
                  Total Revenu
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    style.mt8,
                    style.primaryCOlor
                  )}
                >
                  +1,2% from Last Week
                </p>
              </div>
            </div>
            <div className={classNames(style.CustomerGraph)}>
              <InsuranceLineChart data2={data2} />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col4,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
          >
            <div className={style.RevenueCard}>
              <InsuranceBarChart
                data={data}
                totalRevenue={insuranceDashDetailss?.totalRevenue}
              />
            </div>
            {/* <div className={style.VisitorGraph}>
              <InsurancePieChart />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsuranceDashboard;
