import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userTreatments } from "shared/services";
import { CircleLoader } from "shared/components";
import { TREATMENTS } from "shared/utils/mainHeaderQuery";
import Footerr from "../Footer";
import toast from "react-hot-toast";
import classNames from "classnames";
import style from "./treatment.module.css";
import commonstyles from "shared/utils/common.module.css";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import ServiceHeader from "shared/components/ServicesHeaders";

export default function Treatment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "MediTour Global | Treatments";
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const handleGoDetails = (item: any, mainTitile: any, mainIndex: number) => {
    navigate("/treatment/Details", {
      state: {
        item: item,
        mainIndex: mainIndex,
        mainTitle: mainTitile,
        data: data,
      },
    });
  };

  useEffect(() => {
    getAllTreatments();
  }, []);

  const getAllTreatments = () => {
    setLoading(true);
    userTreatments()
      .then((res: any) => {
        setData(res?.data?.data);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <ServiceHeader
        headingBlue="Medical"
        desc_width="60%"
        headingOrange="Treaments"
        content="Experience world-class medical care with personalized treatment plans and expert guidance. From consultation to recovery, we ensure a smooth and stress-free journey."
      />
      <div className={style.container}>
        <p
          className={classNames(
            commonstyles.fs24,
            style.colorBlue,
            commonstyles.semiBold,
            style.textCenter
          )}
        >
          MediTour offers the best hospital facilities at Affordable costs for
          treatment.
        </p>
        <div className={classNames(style.flx, commonstyles.mt56, style.row)}>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CircleLoader color={"#00276D"} width={40} height={40} />
            </div>
          ) : (
            <>
              {data?.map((item: any, index: number) => (
                <div key={index} className={classNames(style.colmn3)}>
                  <p
                    className={classNames(
                      commonstyles.fs18,
                      style.colorBlue,
                      commonstyles.semiBold
                    )}
                  >
                    {item?.categoryName}
                  </p>
                  <ul className={style.list}>
                    {item?.treatments.map((cat: any, ind: any) => (
                      <li
                        key={ind}
                        onClick={() =>
                          handleGoDetails(cat, item?.categoryName, index)
                        }
                      >
                        {cat?.subCategory}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <Footerr />
    </div>
  );
}
