import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./ticketRequest.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";
import { PrimaryButton } from "shared/components";
import dayjs from "dayjs";
export default function TicketRequestDetail() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const data = state?.val;

  const navigate = useNavigate();

  const handleAddTicket = () => {
    navigate("/flights/Bid", { state: { data } });
  };
  return loading ? (
    <CustomLoader />
  ) : (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Request detail
            </p>
          </div>
          <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
            <div>
              <p
                className={classNames(
                  commonstyles.fs18,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                User
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.regular,
                  styles.colorGray
                )}
              >
                {data?.userId?.name}
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  commonstyles.fs18,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Phone Number
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.regular,
                  styles.colorGray
                )}
              >
                {data?.userId?.phone}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              {data?.requestType}
            </p>
          </div>
          <div className={styles.DetailCard}>
            {data?.flights?.map((user: any, index: any) => (
              <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    From
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.from}
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    To
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.to}
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    Departure
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.departure
                      ? new Date(user?.departure).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : "__"}
                  </p>
                </div>
              </div>
            ))}
            <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Class
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {data?.flightClass}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Traveler
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  Adults: {data?.adult} children: {data?.children} Infant:{" "}
                  {data?.infant}
                </p>
              </div>
              {data?.requestType === "round" && (
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    Return
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {dayjs(data?.returnFlight).format("DD/MM/YYYY")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "56px", width: "210px" }}>
              <PrimaryButton
                onClick={handleAddTicket}
                colorType={"Linear"}
                type={"button"}
              >
                Add Ticket
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
