import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import * as Yup from "yup";
import style from "./Myself.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import User from "assets/images/Userr2.jpeg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setInsuranceMySelfPackage } from "shared/redux";
import { insuranceMySelfPackageSchema } from "shared/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { insuranceGetAllIndividualINSURANCE } from "shared/services/Insurance";
import CustomLoader from "shared/components/New_Loader/Loader";

export default function MySeflMain() {
  const [showMySelfModal, setShowMySelfModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Health");
  };
  const handleGoToMyselfDetail = (item: any) => {
    navigate("/insurance/MySelf/Detail", { state: { item } });
  };

  const handleCardClick = () => {
    // Set the state to true to show the modal
    setShowMySelfModal(true);
  };

  const fetchIndividualInsurances = () => {
    setLoading(true);
    insuranceGetAllIndividualINSURANCE()
      .then((res: any) => {
        setdata(res?.data?.insurances);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchIndividualInsurances();
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyles.col12)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={classNames(commonstyles.outerContainer)}>
              <div className={commonstyles.flx}>
                <div className={commonstyles.flx}>
                  <IoArrowBack className={style.back} onClick={handleGoBack} />
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      commonstyles.colorBlue,
                      style.mr56
                    )}
                  >
                    Back{" "}
                  </p>
                </div>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  Health / MySelf
                </p>
                <div className={style.outerRefresh} onClick={handleCardClick}>
                  <BiSolidMessageSquareAdd className={style.RefreshIcon} />
                </div>
                <CustomModal
                  showModal={showMySelfModal}
                  children={<MySelf setShowMySelfModal={setShowMySelfModal} />}
                />
              </div>

              <div className={style.flxWrap}>
                {data.map((item: any) => (
                  <div
                    className={style.myselfIncCard}
                    onClick={() => handleGoToMyselfDetail(item)}
                  >
                    <Avatar src={item?.packageLogo} className={style.profile} />
                    <p
                      className={classNames(
                        commonstyles.fs20,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.packageName}
                    </p>
                    <p className={classNames(commonstyles.fs15, style.mt16)}>
                      Hospitalization Limit
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.hospitalizationLimit?.startLimit} -{" "}
                      {item?.hospitalizationLimit?.endLimit}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt16,
                        commonstyles.semiBold
                      )}
                    >
                      Claim Payout Ratio
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.claimPayoutRatio}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const MySelf = (props: any) => {
  const { insuranceMySelfPackage } = useSelector(
    (state: any) => state.root.insurance
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleGoMyselfflow = () => {
  //   navigate("/insurance/MySelf");
  // };
  const { setShowMySelfModal } = props;
  const handleCheckboxChange = (option: string) => {
    setSelectedOption(option === selectedOption ? null : option);
    formik.setFieldValue("gender", option);
  };
  const handleCloseModal = () => {
    setShowMySelfModal(false);
  };

  const formik = useFormik({
    initialValues: {
      insuranceAgestart: "",
      insuranceAgeend: "",
      hospitalizationStartLimit: "",
      hospitalizationEndLimit: "",
      gender: "",
    },
    validationSchema: Yup.object(insuranceMySelfPackageSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    dispatch(setInsuranceMySelfPackage({ ...formik.values }));
    navigate("/insurance/MySelf");
  };
  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className={style.header}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Myself Package
          </p>
          <IoClose className={style.close} onClick={handleCloseModal} />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", transform: "rotate(0deg)" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Age criteria
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Insurance age start"
                      id="insuranceAgestart"
                      name="insuranceAgestart"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.insuranceAgestart}
                    />
                    {formik.touched.insuranceAgestart &&
                    formik.errors.insuranceAgestart ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.insuranceAgestart}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="Insurance age end "
                      id="insuranceAgeend"
                      name="insuranceAgeend"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.insuranceAgeend}
                    />
                    {formik.touched.insuranceAgeend &&
                    formik.errors.insuranceAgeend ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.insuranceAgeend}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ color: "#00276D", border: "none" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Select Hospitalization Limit (PKR)
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Start Limit"
                      id="hospitalizationStartLimit"
                      name="hospitalizationStartLimit"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.hospitalizationStartLimit}
                    />
                    {formik.touched.hospitalizationStartLimit &&
                    formik.errors.hospitalizationStartLimit ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.hospitalizationStartLimit}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="End Limit "
                      id="hospitalizationEndLimit"
                      name="hospitalizationEndLimit"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.hospitalizationEndLimit}
                    />
                    {formik.touched.hospitalizationEndLimit &&
                    formik.errors.hospitalizationEndLimit ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.hospitalizationEndLimit}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Gender
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <Checkbox
                    className={style.checkbox}
                    checked={selectedOption === "male"}
                    onChange={() => handleCheckboxChange("male")}
                  />
                  <p>Male</p>
                </div>
                <div className={classNames(commonstyles.flx, style.mt16)}>
                  <Checkbox
                    className={style.checkbox}
                    checked={selectedOption === "female"}
                    onChange={() => handleCheckboxChange("female")}
                  />
                  <p>Female</p>
                </div>
                {formik.touched.gender && formik.errors.gender ? (
                  <div className={classNames(commonstyles.error)}>
                    *{formik.errors.gender}
                  </div>
                ) : null}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{ margin: "24px" }}>
          <PrimaryButton
            children={"Save & Continue"}
            colorType={"greenOutline"}
            // onClick={handleGoMyselfflow}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
