import React from "react";
import styles from "./footer.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { treatmentsData } from "shared/utils";
import { FaArrowUp, FaLocationDot } from "react-icons/fa6";
import Logo from "../../../../assets/images/whitelogonew.png";

export default function Footerr() {
  const navigate = useNavigate();
  const handleGoContactus = () => {
    navigate("/contactUs");
  };
  const handleGoUserLogin = () => {
    navigate("/UserLogin");
  };
  const handleGoAboutUs = () => {
    navigate("/aboutUs");
  };

  const handleGoTotreatment = () => {
    navigate("/treatment");
  };
  const handleGoToprivactpolicys = () => {
    navigate("/privactpolicys");
  };
  const handleGoDetails = (title: any, ind: number) => {
    if (title == "more") {
      navigate("/treatment", {});
      return;
    }
    treatmentsData?.map((item: any, index: any) => {
      if (item?.mainTitle === title) {
        navigate("/treatment/Details", {
          state: {
            item: item?.list[ind],
            mainIndex: index,
            mainTitle: item.mainTitle,
          },
        });
      }
    });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={styles.container2}>
      <div className={styles.mianFooterOuter}>
        <div
          className={classNames(styles.flxBetween, styles.gap24, styles.mb24)}
        >
          <div className={classNames(styles.col5, styles.footerMflx)}>
            {/* <div
              className={classNames(
                styles.textstart,
                styles.col5,
                styles.mtsm20
              )}
            >
              <p
                className={classNames(styles.footerHeadings)}
                onClick={handleGoTotreatment}
              >
                Treatments
              </p>
              <p
                className={classNames(styles.footertext)}
                onClick={() => handleGoDetails("AESTHETIC TREATMENTS", 0)}
              >
                Aesthetic
              </p>
              <p
                className={classNames(styles.footertext)}
                onClick={() => handleGoDetails("TRANSPLANTS", 3)}
              >
                Hair Transplant
              </p>
              <p
                className={classNames(styles.footertext)}
                onClick={() => handleGoDetails("STEM CELL", 0)}
              >
                Stem Cell
              </p>
              <p
                className={classNames(styles.footertext)}
                onClick={() => handleGoDetails("FERTILITY", 0)}
              >
                Fertility
              </p>
              <p
                className={classNames(styles.mt8, styles.cursor, styles.fs14)}
                style={{ color: "#0e54a3" }}
                onClick={() => handleGoDetails("more", 0)}
              >
                See More...
              </p>
            </div> */}
            <div
              className={classNames(
                styles.textstart,
                styles.col5,
                styles.mtsm20
              )}
            >
              <p className={classNames(styles.footerHeadings)}>Company</p>
              <p
                className={classNames(styles.footertext)}
                onClick={handleGoAboutUs}
              >
                About Us
              </p>
              <p
                className={classNames(styles.footertext)}
                onClick={handleGoContactus}
              >
                Contact us
              </p>
              <p className={classNames(styles.footertext)}>Careers</p>
              {/* <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Culture
            </p> */}
              <p
                className={classNames(styles.footertext)}
                onClick={handleGoToprivactpolicys}
              >
                Privacy Policy
              </p>
            </div>
            <div
              className={classNames(
                styles.textstart,
                styles.col5,
                styles.mtsm20
              )}
            >
              <p className={classNames(styles.footerHeadings)}>Support</p>
              <p className={classNames(styles.footertext)}>Getting started</p>
              <p className={classNames(styles.footertext)}>Help center</p>
              <p
                className={classNames(styles.footertext)}
                onClick={handleGoContactus}
              >
                Support
              </p>
            </div>
            {/* <div
            className={classNames(
              styles.textstart,
              commonstyles.col2,
              styles.mtsm20
            )}
          >
            <p
              className={classNames(
                styles.fs18,
                styles.cursor,
                commonstyles.bold
              )}
            >
              Downloads
            </p>{" "}
            <p className={classNames(styles.mt32, styles.fs16)}>iOS</p>
            <p className={classNames(styles.mt16, styles.cursor, styles.fs16)}>
              Android
            </p>
          </div> */}
          </div>

          <div
            className={classNames(
              styles.col5,
              styles.mtsm20,
              commonstyles.colsm12
            )}
          >
            <p className={classNames(styles.newsletter)}>Newsletter</p>{" "}
            {/* <p
              className={classNames(
                styles.mt32,
                commonstyles.fs16,
                styles.textsmCenter
              )}
            >
              Stay updated with exclusive offers and news by subscribing to our
              newsletter today. Don't miss out, join our community now!
            </p> */}
            <div className={styles.inputContainer}>
              <input
                placeholder="Enter Your Email"
                className={styles.inputField}
              />
              <button className={styles.SubscribeBtn}>
                <p>Subscribe</p>
                <IoArrowForwardCircleSharp className={styles.aRROW} />{" "}
              </button>
            </div>
            <p className={classNames(styles.NewsletterText)}>
              Stay updated with exclusive offers and news!
            </p>
          </div>
        </div>
      </div>{" "}
      <div style={{ borderTop: "1px solid white" }}>
        {" "}
        <div className={styles.mianFooterOuter}>
          <MediTourAddress />
        </div>
      </div>
      <div style={{ borderTop: "1px solid white" }}>
        {" "}
        <div className={styles.mianFooterOuter}>
          <div className={classNames(styles.FooterFlx)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img src={Logo} className={styles.FooterLogo} />
              </div>
              <p className={classNames(styles.Copyright)}>
                Copyright © 2025 MediTour | All Rights Reserved
              </p>
            </div>
            <div
              className={classNames(
                styles.flxIcons,
                styles.mtsm20,
                commonstyles.colsm12
              )}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  border: "0.67px solid white",
                  borderRadius: "6px",
                  padding: "4px",
                }}
              >
                <a
                  className={styles.iconOuter}
                  href="https://www.facebook.com/profile.php?id=61560461618333&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className={styles.FooterICONS} />
                </a>
              </div>
              <div
                style={{
                  border: "0.67px solid white",
                  borderRadius: "6px",
                  padding: "4px",
                }}
              >
                <a
                  className={styles.iconOuter}
                  href="https://twitter.com/MediTourglobal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter className={styles.FooterICONS} />
                </a>
              </div>
              <div
                style={{
                  border: "0.67px solid white",
                  borderRadius: "6px",
                  padding: "4px",
                }}
              >
                <a
                  className={styles.iconOuter}
                  href="https://www.instagram.com/theMediTour.global/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PiInstagramLogoFill className={styles.FooterICONS} />
                </a>{" "}
              </div>
              <div
                style={{
                  border: "0.67px solid white",
                  borderRadius: "6px",
                  padding: "4px",
                }}
              >
                <a
                  className={styles.iconOuter}
                  href="https://www.linkedin.com/company/theMediTourglobal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn className={styles.FooterICONS} />
                </a>{" "}
              </div>
              <div
                style={{
                  border: "0.67px solid white",
                  borderRadius: "6px",
                  padding: "4px",
                }}
              >
                <a
                  className={styles.iconOuter}
                  href="https://www.youtube.com/@theMediTour.global"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoYoutube className={styles.FooterICONS} />
                </a>{" "}
              </div>
            </div>
            <div className={styles.upernavigation} onClick={scrollToTop}>
              <div className={styles.upernavigationinner}>
                <FaArrowUp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MediTourAddress = () => {
  const addressData = [
    {
      id: 0,
      country: "United States",
      address: "37 Alexander Avenue Staten Island, NY, 10312, USA",
      contact: "+1(813)550-4999",
    },
    {
      id: 1,
      country: "Canada",
      address: "181 Dundas Street East, Toranto Ontario M5A 0N5, Canada",
      contact: "+1(437)259-5662",
    },
    {
      id: 2,
      country: "Pakistan",
      address: "154-D Architects Society Lahore, Punjab, Pakistan",
      contact: "+92-42-37885101-4 / +92-42-35191168",
    },
    {
      id: 2,
      country: "United Kingdom",
      address:
        "Warlies Park House, Upshire,Waltham Abbey EN9 3SL, United Kingdom",
      contact: "+44-7710083013",
    },
  ];
  return (
    <>
      {
        <div className={styles.addressContainer} style={{ gap: 16 }}>
          {addressData.map((item: any, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.circle}>
                <FaLocationDot fill="#FF7631" size={20} />
              </div>
              <div className={styles.address}>
                <p className={classNames(styles.addressHeading)}>
                  {item?.country}
                </p>
                <p className={classNames(styles.addresstext)}>
                  {item?.address}
                </p>
                <p className={classNames(styles.addresstext)}>
                  {item?.contact}
                </p>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  );
};
