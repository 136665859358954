import React, { useEffect, useState } from "react";
import classNames from "classnames";
import mStyle from "./moreDetail.module.css";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { CustomInput, OtherForm } from "shared/components";
import LocationInput from "shared/components/LocationInput";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import Datepicker from "shared/components/DatePicker";
import { Checkbox } from "@mui/material";
import CardStyless from "../DoctarServices/Cards.module.css";
import { IoMdArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { getSingle_CarDetail } from "shared/services";
import MainHeader from "shared/components/MainScreen/Index";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { RENT_A_CAR_DETAIL } from "shared/utils/mainHeaderQuery";

const RentaCarmoreDetail = () => {
  const [showForm, setShowForm] = useState("");
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { state } = useLocation();
  let items = state.items;

  const onOther = (type: any) => {
    setShowForm(type);
  };
  useEffect(() => {
    getSinglecar_Detail();
  }, []);

  useEffect(() => {
    if (data?.vehicleImages?.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === data.vehicleImages.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [data?.vehicleImages]);

  const getSinglecar_Detail = () => {
    let params = {
      vehicleId: items?._id,
    };
    getSingle_CarDetail(params)
      .then((res: any) => {
        setData(res?.data?.vehicle);
      })
      .catch((err: any) => {})
      .finally(() => {});
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBreadCrumbs {...RENT_A_CAR_DETAIL} />
      <div className={classNames(commonstyles.container)}>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap,
            commonstyles.mb32,
            commonstyles.mt32
          )}
        >
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12,
              mStyle.displayimgcontainer
            )}
          >
            <img
              src={data?.vehicleImages?.[currentIndex]}
              alt="card img"
              className={mStyle.displayimg}
            />
            <div className={mStyle.dotsContainer}>
              {data?.vehicleImages?.map((_: any, index: number) => (
                <span
                  key={index}
                  className={classNames(mStyle.dot, {
                    [mStyle.activeDot]: index === currentIndex,
                  })}
                ></span>
              ))}
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
          >
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.flxBetween,
                commonstyles.flxColumn
              )}
            >
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  mStyle.nameHeading
                )}
              >
                {data?.vehicleName}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorGray
                )}
              >
                {`${data?.actualPricePerDay} / per day`}
              </p>
            </div>
            <div
              className={classNames(commonstyles.mt16, mStyle.rightcontainer)}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <ul className={mStyle.list}>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Vehicle Type</span>
                    <span className={mStyle.value}>{data?.vehicleType}</span>
                  </li>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Vehicle Model</span>
                    <span className={mStyle.value}>{data?.vehicleModel}</span>
                  </li>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Vehicle Year</span>
                    <span className={mStyle.value}>{data?.vehicleYear}</span>
                  </li>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Vehicle Color</span>
                    <span className={mStyle.value}>{data?.vehicleColour}</span>
                  </li>

                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Vehicle Id No.</span>
                    <span className={mStyle.value}>{data?.vehicleVinNo}</span>
                  </li>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Registration No.</span>
                    <span className={mStyle.value}>
                      {data?.vehicleRegisterationNo}
                    </span>
                  </li>
                  <li className={mStyle.listItem}>
                    <span className={mStyle.label}>Registration Date.</span>
                    <span className={mStyle.value}>
                      {data?.vehicleRegisterationDate}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={mStyle.bookingbuttoncontainer}>
                <div className={mStyle.bookingbuttoncontainer}>
                  {items?.requestSent === true ? (
                    <button className={classNames(mStyle.activeButton)}>
                      Booked
                    </button>
                  ) : (
                    <div>
                      <button
                        className={classNames(
                          showForm === "Book Now"
                            ? mStyle.activeButton
                            : mStyle.bookingbutton
                        )}
                        onClick={() => onOther("Book Now")}
                        style={{ marginRight: "10px" }}
                      >
                        Book Now
                      </button>

                      <button
                        className={classNames(
                          showForm === "For Other"
                            ? mStyle.activeButton
                            : mStyle.bookingbutton
                        )}
                        onClick={() => onOther("For Other")}
                      >
                        For Other
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {showForm === "For Other" && (
                <OtherForm items={items} type="otherPerson" />
              )}
              {showForm === "Book Now" && (
                <OtherForm items={items} type="BookNow" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default RentaCarmoreDetail;
