import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { addParamedic } from "shared/services";
import styles from "./paramedicStaff.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "shared/components/DatePicker";
import DownloadImagelogo from "../../../assets/images/DownloadImagelogo.png";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";

import ThankyouModel from "shared/components/ThankyouModel";
import dayjs from "dayjs";
import { LoadingModal } from "shared/components";
import { PARAMEDIC_STAFF } from "shared/utils/mainHeaderQuery";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import CustomLoader from "shared/components/New_Loader/Loader";
import classNames from "classnames";

type FormErrors = {
  name?: string;
  email?: string;
  contact?: string;
  address?: string;
  preferredDate?: string;
  preferredTime?: string;
  userArea?: string;
  city?: string;
  remarks?: string;
  schedule?: string;
  customSchedule?: string;
  gender?: any;
};

const ParamedicStaff: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [preferredTime, setAptTime] = useState<any>("");

  const [contact, setContact] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [gender, setGender] = useState<string>("male");
  const [preferredDate, setPreferredDate] = useState<any>("");
  const [userArea, setUserArea] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");
  const [schedule, setSchedule] = useState<string>("");
  const [customSchedule, setCustomSchedule] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const resetForm = () => {
    setName("");
    setEmail("");
    setContact("");
    setAddress("");
    setGender("male");
    setPreferredDate("");
    setAptTime("");
    setUserArea("");
    setCity("");
    setRemarks("");
    setSchedule("");
    setCustomSchedule("");
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};

    if (!name) errors.name = "Name is required.";
    if (!email) errors.email = "Email is required.";
    if (!contact) errors.contact = "Contact is required.";
    if (!address) errors.address = "Address is required.";
    if (!preferredDate) errors.preferredDate = "Please select a valid date.";
    if (!preferredTime) errors.preferredTime = "Please select a valid time.";
    if (!userArea) errors.userArea = "Area is required.";
    if (!city) errors.city = "City is required.";
    if (!remarks) errors.remarks = "Remarks are required.";
    if (!schedule) errors.schedule = "Schedule is required.";
    if (!gender) errors.schedule = "Select Preferred gender.";

    if (schedule === "other" && !customSchedule) {
      errors.customSchedule = "Please specify your schedule.";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submitForm = () => {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const data = {
      name,
      email,
      contact,
      gender,
      preferredDate,
      preferredTime,
      userArea: {
        area: userArea,
        address: address,
        city: city,
      },
      schedule,
      customSchedule: schedule === "other" ? customSchedule : undefined,
      remarks,
    };

    addParamedic(data)
      .then((res: any) => {
        setIsModalVisible(true);
        setTimeout(() => {
          resetForm();
          setIsModalVisible(false);
        }, 2000);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchedule(event.target.value);
  };

  //
  useEffect(() => {
    const newErrors: FormErrors = { ...formErrors };

    if (name) delete newErrors.name;
    if (email) delete newErrors.email;
    if (contact) delete newErrors.contact;
    if (address) delete newErrors.address;
    if (preferredDate) delete newErrors.preferredDate;
    if (preferredTime) delete newErrors.preferredTime;
    if (userArea) delete newErrors.userArea;
    if (city) delete newErrors.city;
    if (remarks) delete newErrors.remarks;
    if (schedule) delete newErrors.schedule;
    if (gender) delete newErrors.gender;
    if (schedule === "other" && customSchedule) delete newErrors.customSchedule;

    setFormErrors(newErrors);
  }, [
    name,
    email,
    contact,
    address,
    preferredDate,
    preferredTime,
    userArea,
    city,
    remarks,
    schedule,
    gender,
    customSchedule,
  ]);
  return (
    <div>
      <NavBreadCrumbs {...PARAMEDIC_STAFF} />

      <div className={styles.paramedicFormContainer}>
        {/* <h2 className={styles.paramedicFormHeading}>Request Form</h2> */}
        <div
          className={styles.paramedicForm}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <div
            className={styles.formSectionsContainer}
            // style={{
            //   border: "2px solid black",
            // }}
          >
            <div className={classNames(commonstyle.col6, commonstyle.colsm12)}>
              <h3 className={styles.patientDetailsHeading}>
                Add Patient Details
              </h3>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Name</label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {formErrors.name && (
                  <span className={commonstyle.error}>{formErrors.name}</span>
                )}

                {/* {formErrors.name && (
                  <span
                    className={`${commonstyle.error} ${styles.inlineError}`}
                  >
                    {formErrors.name}
                  </span>
                )} */}
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Email</label>
                <input
                  type="email"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {formErrors.email && (
                  <span className={commonstyle.error}>{formErrors.email}</span>
                )}
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Contact
                </label>
                <input
                  type="tel"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your contact number"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />
                {formErrors.contact && (
                  <span className={commonstyle.error}>
                    {formErrors.contact}
                  </span>
                )}
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Address
                </label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                {formErrors.address && (
                  <span className={commonstyle.error}>
                    {formErrors.address}
                  </span>
                )}
              </div>
            </div>

            <div className={classNames(commonstyle.col6, commonstyle.colsm12)}>
              <div className={styles.inputGroupContainerParamedicForm}>
                <h4 className={styles.requirementsHeading}>Requirements</h4>
                <label className={styles.inputLabelParamedicForm}>Gender</label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={gender === "male"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    Male
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    Female
                  </label>
                </div>
                {formErrors.gender && (
                  <span className={commonstyle.error}>{formErrors.gender}</span>
                )}
              </div>

              <div className={styles.inputGroupContainerParamedicFormDate}>
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Preferred Date
                  </label>
                  <Datepicker
                    className={styles.timeInputParamedicForm}
                    placeholder="Select a date"
                    setData={setPreferredDate}
                  />
                  {formErrors.preferredDate && (
                    <span className={commonstyle.error}>
                      {formErrors.preferredDate}
                    </span>
                  )}
                </div>

                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Appointment Time
                  </label>
                  <CustomTimePicker
                    placeholder="Time"
                    value={preferredTime}
                    setData={setAptTime}
                  />
                  {formErrors.preferredTime && (
                    <span className={commonstyle.error}>
                      {formErrors.preferredTime}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Area</label>
                <div className={styles.areaCityContainerParamedicForm}>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your area"
                    value={userArea}
                    onChange={(e) => setUserArea(e.target.value)}
                    required
                  />

                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {formErrors.userArea && (
                    <span className={commonstyle.error}>
                      {formErrors.userArea}
                    </span>
                  )}
                  {formErrors.city && (
                    <span className={commonstyle.error}>{formErrors.city}</span>
                  )}
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Schedule
                </label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="day"
                      checked={schedule === "day"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Day
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="night"
                      checked={schedule === "night"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Night
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="other"
                      checked={schedule === "other"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Other
                  </label>
                </div>
                {formErrors.schedule && (
                  <span className={commonstyle.error}>
                    {formErrors.schedule}
                  </span>
                )}
              </div>

              {schedule === "other" && (
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Specify Schedule
                  </label>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your schedule"
                    value={customSchedule}
                    onChange={(e) => setCustomSchedule(e.target.value)}
                    required
                  />
                </div>
              )}
              {formErrors.customSchedule && (
                <span className={commonstyle.error}>
                  {formErrors.customSchedule}
                </span>
              )}
            </div>
          </div>

          <div className={styles.reviewContainerParamedicForm}>
            <label className={styles.remarks}>Remarks</label>
            <textarea
              className={styles.reviewTextAreaParamedicForm}
              placeholder="Write your remarks here..."
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              required
            />
            {formErrors.remarks && (
              <span className={commonstyle.error}>{formErrors.remarks}</span>
            )}
          </div>

          <button
            type="submit"
            className={styles.submitButtonParamedicForm}
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            Submit
            <img
              src={DownloadImagelogo}
              alt="Logo"
              className={styles.downloadButtonLogo}
            />
          </button>
        </div>

        {loading && <CustomLoader />}
        {isModalVisible && (
          <ThankyouModel
            mainMessageLine1="Your request has been"
            mainMessageLine2="successfully shared"
            subMessage="We will notify you in a few minutes."
            footerMessage="Thank you."
            onClose={handleCloseModal}
          />
        )}
      </div>
      <Footerr />
    </div>
  );
};

export default ParamedicStaff;
