import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./donors.module.css";
import DonorsTable from "shared/components/DonationTables/Donors";
import { donationGETALLDonations } from "shared/services/Donation";
import { RingLoader } from "shared/components";
import NewPagination from "shared/components/NewPagination/NewPagination";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { TbRefresh } from "react-icons/tb";

function Donors() {
  const [loading, setLoading] = useState(false);
  const [donors, setDonors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDonations, setTotalDonations] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Define the number of items per page
  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;

  const FetchAllDonations = (page = 1) => {
    setLoading(true);
    donationGETALLDonations(currentPage)
      .then((res: any) => {
        if (res.data.auth === true) {
          setDonors(res.data.donations);
          setTotalDonations(res.data.totalDonations);
          setTotalPages(Math.ceil(res.data.totalDonations / itemsPerPage));
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchAllDonations(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.alignItemsCenter
              )}
            >
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Listing
              </p>

              {loading ? (
                <div style={{ marginLeft: "8px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={() => {
                      FetchAllDonations(currentPage);
                      setRotation((prev) => prev + rotationIncrement);
                    }}
                  />
                </div>
              )}
            </div>

            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalDonations)}
              totalItems={totalDonations}
            />
          </div>

          {donors && donors.length > 0 ? (
            <DonorsTable donors={donors} />
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>
    </div>
  );
}

export default Donors;
