import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CardStyles from "./Cards.module.css";
import CommonStyles from "shared/utils/common.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import { CiShoppingCart } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { getMedicineNear } from "shared/services";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "shared/redux";
import toast from "react-hot-toast";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { RingLoader } from "shared/components";
import { useNavigate } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
const PharmacyCards = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantities, setQuantities] = useState<any>(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>("");
  const [refreshing, setRefreshing] = useState(false);
  const { isLoggedIn } = useSelector((state: any) => state.root.common);
  const [count, setCount] = useState(0);
  const { cart } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [medicineData, setMedicineData] = useState<any>(null);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const handleIncrement = () => {
    setQuantities(quantities + 1);
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (quantities > 0) {
      setQuantities(quantities - 1);
      setCount(count - 1);
    }
  };

  const openModal = (i: any) => {
    setMedicineData(i);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelect = () => {
    if (isLoggedIn) {
      if (!medicineData) return;

      const quantity = quantities;
      const updatedCart = Array.isArray(cart) ? [...cart] : [];
      const existingIndex = updatedCart.findIndex(
        (item) => item._id === medicineData._id
      );

      if (existingIndex !== -1) {
        // Create a new object with the updated quantity
        updatedCart[existingIndex] = {
          ...updatedCart[existingIndex],
          quantity: updatedCart[existingIndex].quantity + quantity,
        };
      } else {
        updatedCart.push({ ...medicineData, quantity });
      }

      dispatch(setCart(updatedCart));
      // toast.success("Added to cart successfully!");
      notifySuccess("Added to cart successfully!");
      closeModal();
    } else {
      navigate("/user/login");
    }
  };

  useEffect(() => {
    getMedicine();
  }, [currentPage, searchText]);

  const getMedicine = (keyword = "") => {
    setLoading(true);
    const params = { keyword, page: currentPage, limit: itemsPerPage };
    getMedicineNear(params)
      .then((res: any) => {
        setData(res?.data?.products || []);
        setTotalItems(res?.data?.totalProducts || 0);
        setCurrentPage(res?.data?.currentPage || 1);
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debouncedSearch = _.debounce((value: string) => {
    getMedicine(value);
  }, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };
  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
      getMedicine(searchText);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      getMedicine(searchText);
    }
  };
  return (
    <div className={classNames(CommonStyles.container)}>
      <div>
        {/* Tabs Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.flxWrap,
            CommonStyles.flxBetween
            // CardStyles.main
          )}
        >
          <div
            className={classNames(
              CardStyles.tabContainer,
              CommonStyles.flx,
              CommonStyles.col6,
              CommonStyles.colsm12
            )}
          ></div>
          <div
            className={classNames(
              CardStyles.searchBarContainer,
              CommonStyles.col2,
              CommonStyles.colsm12
            )}
          >
            <IoSearchSharp className={CardStyles.searchIcon} />
            <input
              type="text"
              placeholder="Search..."
              className={CardStyles.searchInput}
              value={searchText}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>
        {data && data?.length > 0 ? (
          <div className={CardStyles.cardContainer}>
            {data.map((card: any) => (
              <div key={card.id} className={CardStyles.cardWrapper}>
                <div
                  className={CardStyles.cardBody}
                  onClick={() => openModal(card)}
                >
                  <p
                    className={`${CommonStyles.fs16} ${CommonStyles.semiBold}`}
                    style={{
                      color: "#0E54A3",
                    }}
                  >
                    {card.productName}
                  </p>
                  <div className={CardStyles.cardDetails}>
                    <div>{card.brand}</div>
                    <div>{card.strength}</div>
                  </div>
                  <div className={CardStyles.rightAlign}>
                    <div>Price: {card.tpPrice}-/</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : <PhysiotheristsEmpty />}
      </div>
      {loading && <CustomLoader />}
      {/* Modal */}
      {isModalOpen && (
        <div className={CardStyles.modal} onClick={closeModal}>
          <div
            className={CardStyles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <p className={CardStyles.viewCardTitle}>
              {medicineData?.productName}
            </p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.fs12,
                CommonStyles.colorGray
              )}
            >
              <p className={CardStyles.viewCardBrand}>{medicineData?.brand}</p>
              <p className={CardStyles.viewCardSize}>
                {medicineData?.strength}
              </p>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.fs12
              )}
            ></div>
            <p className={CardStyles.viewCardDosage}>
              Price: {medicineData?.tpPrice}/-
            </p>

            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.fs16,
                CommonStyles.colorBlue,
                CardStyles.viewCardQuantity
              )}
            >
              <p className={CardStyles.viewCardQuantityTitle}> Quantity</p>
              <div className={classNames(CardStyles.viewQuantitybox)}>
                <button
                  className={CardStyles.decrementButton}
                  onClick={handleDecrement}
                >
                  -
                </button>
                <span className={CardStyles.quantity}>{quantities}</span>
                <button
                  className={CardStyles.incrementButton}
                  onClick={handleIncrement}
                >
                  +
                </button>
              </div>
            </div>
            <div className={CardStyles.viewCardButtons}>
              <button
                className={CardStyles.viewCancelButton}
                onClick={closeModal}
              >
                CANCEL
              </button>
              <button
                className={CardStyles.viewSelectButton}
                onClick={handleSelect}
              >
                SELECT
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PharmacyCards;
