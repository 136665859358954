import React from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import CustomSelect from "shared/components/CustomSelect";
import { PrimaryButton } from "shared/components";
import { insuranceTravelIndividualMedicalInfoSchema } from "shared/utils";
import { setInsuranceTravelIndividualPackage } from "shared/redux";
const Accidental = ["Karachi", "Multan", "Islambad"];
const Repatriation = ["No, Actual", "Yes, Actual"];
const MedicalExpenses = ["No, Actual", "Yes, Actual"];
const Return = ["No, Actual", "Yes, Actual"];
const Repatriationillnes = ["No, Actual", "Yes, Actual"];
const Emergency = ["No, Actual", "Yes, Actual"];
const Deliveryofmedicine = ["No, Actual", "Yes, Actual"];
const Covid = ["Covid - 19", "Karachi", "Multan", "Islambad"];
interface Props {
  handleClickNext: any;
}
export default function MedicalBenefits(props: Partial<Props>) {
  const { insuranceTravelIndividual } = useSelector(
    (state: any) => state.root.insurance
  );

  const handleSelect = (selectedOption: string) => {};
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      medicalExpense: "",
      repatriationofMortalRemains: "",
      repatriationInCaseIllness: "",
      returnofDependentChildren: "",
      deliveryOfMedicine: "",
      emergencyReturnHome: "",
    },
    validationSchema: Yup.object(insuranceTravelIndividualMedicalInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleMedicalExpense = (selectedOption: string) => {
    formik.setFieldValue("medicalExpense", selectedOption);
  };
  const handleMortalRemains = (selectedOption: string) => {
    formik.setFieldValue("repatriationofMortalRemains", selectedOption);
  };
  const handleIllness = (selectedOption: string) => {
    formik.setFieldValue("repatriationInCaseIllness", selectedOption);
  };
  const handleDependentChildren = (selectedOption: string) => {
    formik.setFieldValue("returnofDependentChildren", selectedOption);
  };
  const handleDeliveryMedicine = (selectedOption: string) => {
    formik.setFieldValue("deliveryOfMedicine", selectedOption);
  };
  const handleEmergencyReturnHome = (selectedOption: string) => {
    formik.setFieldValue("emergencyReturnHome", selectedOption);
  };

  const handleSubmit = () => {
    dispatch(
      setInsuranceTravelIndividualPackage({
        ...insuranceTravelIndividual,
        ...formik.values,
      })
    );
    handleClickNext();
  };
  return (
    <div className={classNames(commonStyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={handleMedicalExpense}
              options={MedicalExpenses}
              placeholder="Accidental Death & Disability:"
            />
            {formik.touched.medicalExpense && formik.errors.medicalExpense ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.medicalExpense}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              onSelect={handleMortalRemains}
              options={Repatriation}
              placeholder="Repatriation of mortal remains:"
            />
            {formik.touched.repatriationofMortalRemains &&
            formik.errors.repatriationofMortalRemains ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.repatriationofMortalRemains}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={handleIllness}
              options={Repatriationillnes}
              placeholder="Medical Expenses & Hospitalization"
            />
            {formik.touched.repatriationInCaseIllness &&
            formik.errors.repatriationInCaseIllness ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.repatriationInCaseIllness}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              onSelect={handleDependentChildren}
              placeholder="Emergency return home:"
              options={Return}
            />
            {formik.touched.returnofDependentChildren &&
            formik.errors.returnofDependentChildren ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.returnofDependentChildren}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomSelect
              onSelect={handleDeliveryMedicine}
              options={Deliveryofmedicine}
              placeholder="Delivery Of Medicine"
            />
            {formik.touched.deliveryOfMedicine &&
            formik.errors.deliveryOfMedicine ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.deliveryOfMedicine}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <CustomSelect
              placeholder="Emergency Return Home"
              onSelect={handleEmergencyReturnHome}
              options={Emergency}
            />
            {formik.touched.emergencyReturnHome &&
            formik.errors.emergencyReturnHome ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.emergencyReturnHome}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
