import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import TableNew from "shared/components/A_New_Components/Table_new";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import { getLatestBookings, reserve } from "shared/services";
import dayjs from "dayjs";
import { RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import style from "./reserve.module.css";
const HotelReserve = () => {
  const [appointment, setAppointment] = useState<any>();
  const [length, setLength] = useState(0);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const totalItems = length;
  const titles = [
    "Reservation ID",
    "Guest Name",
    "Contact Email",
    "Reservation Date & Time",
    "Reservation Expire In",
    "Space Type",
    "No of Guest",
  ];
  const handleTableData = (data: any) => {
    let tempData: any = [];
    if (data?.length > 0) {
      data.map((v: any, ind: any) => {
        let date = dayjs(v?.createdAt).format("MM-DD-YYYY h:mm a");
        tempData.push([
          v?.requestId,
          v?.user?.name,
          v?.user?.email,
          date,
          `${v?.reservationExpiresIn}hrs` ,
          v?.spaceType,
          v?.noOfGuest,
          ,
        ]);
      });

      setAppointment(tempData);
    } else {
      setAppointment([]);
    }
  };
  useEffect(() => {
    allLatest(1, "");
  }, []);
  const allLatest = (pageno: number, keyWord: any) => {
    setLoading(true);
    reserve(pageno, keyWord)
      .then((res: any) => {
        console.log(res, "....res");
        handleTableData(res?.data?.bookings);
        setLength(res?.data?.bookingCount);
      })
      .catch((err: any) => {
        console.log(err, "...error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      allLatest(currentPage + 1, search);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      allLatest(currentPage - 1, search);
    }
  };
  const handleRefresh = () => {
    setPageno(1);
    setCurrentPage(1);
    allLatest(1, "");
  };
  const handleSearch = () => {
    setCurrentPage(1);
    setPageno(1);
    allLatest(1, search);
  };
  return (
    <>
      <div>
        <div className={classNames(commonstyles.mt16, style.tablecontainer)}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "8px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className={classNames(commonstyles.flx, commonstyles.flxWrap)}>
              <p
                className={classNames(
                  commonstyles.colorBlack,
                  commonstyles.fs14,
                  commonstyles.semiBold
                )}
              >
                Reservation
              </p>
              {loading ? (
                <div style={{ marginLeft: "32px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <TbRefresh className={style.refresh} onClick={handleRefresh} />
              )}

              <div>
                <SearchFilter
                  vender={false}
                  search={search}
                  title={"Search"}
                  setSearch={setSearch}
                  handleSearch={handleSearch}
                />
              </div>
            </div>

            <div>
              <NewPagination2
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                startItem={(currentPage - 1) * itemsPerPage + 1}
                endItem={Math.min(currentPage * itemsPerPage, totalItems)}
                totalItems={totalItems}
              />
            </div>
          </div>
          {appointment?.length > 0 ? (
            <TableNew
              titles={titles}
              data={appointment}
              headerWidth="17%"
              itemWidth="17%"
            />
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>
    </>
  );
};
export default HotelReserve;
