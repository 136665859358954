import React, { useEffect } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "./aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../Footer";
import Facilities from "assets/aboutUsImages/World-Class Medical.jpg";
import Professionals from "assets/aboutUsImages/Highly Skilled Medical Professionals.jpg";
import Treatments from "assets/aboutUsImages/Cost-Effective Treatments.jpg";
import Services from "assets/aboutUsImages/Comprehensive Range of Services.jpg";
import Times from "assets/aboutUsImages/Shorter Waiting Times.jpg";
import Language from "assets/aboutUsImages/Cultural and Language Compatibility.jpg";
import Destinations from "assets/aboutUsImages/Stunning Travel Destinations.jpg";
import Attention from "assets/aboutUsImages/Personalized Care and Attention.jpg";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { ABOUTUS } from "shared/utils/mainHeaderQuery";
import ServiceHeader from "shared/components/ServicesHeaders";

const AboutUs = React.memo((props) => {
  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <NavBreadCrumbs {...ABOUTUS} /> */}
      <ServiceHeader
        headingBlue="About"
        headingOrange="US"
        content="MediTour Global is a premier medical tourism company connecting patients worldwide to top-tier healthcare. We provide access to advanced hospitals, cutting-edge treatments, and internationally qualified doctors, ensuring high-quality, affordable medical care. Our seamless, patient-focused approach combines medical excellence with personalized care for a stress-free experience."
      />{" "}
      <div style={{ width: "300px", margin: "0 auto" }}>
        <div style={{ borderBottom: "1px dashed #7d7d7d" }}></div>
      </div>
      <div className={classNames(style.outer)}>
        <div className={style.col6}>
          <img alt="" src={Facilities} className={style.IMages} />
        </div>
        <div className={style.col6}>
          <p>
            <span className={style.colorBlue}>World-Class Medical</span>{" "}
            <span className={style.colorOrange}> Facilities</span>{" "}
          </p>
          <p className={classNames(style.aboutext)}>
            MediTour Global is home to state-of-the-art hospitals and medical
            centers worldwide that adhere to international standards. Equipped
            with the latest medical technologies and staffed by highly trained
            healthcare professionals, these facilities provide top-notch medical
            care comparable to any leading global institution.
          </p>
        </div>
      </div>
      <div className={classNames(style.container)}>
        <div className={classNames(style.outer, style.flxreverse)}>
          <div className={style.col6}>
            <p>
              <span className={style.colorBlue}>Highly Skilled Medical</span>
              <span className={style.colorOrange}> Professionals</span>{" "}
            </p>
            <p className={classNames(style.aboutext)}>
              Facilities and services at MediTour Global are renowned for their
              expertise and experience, many of whom have received training and
              certifications from prestigious institutions worldwide. Our
              dedication to excellence ensures that you receive the best
              possible care and treatment outcomes
            </p>
          </div>{" "}
          <div className={style.col6}>
            <img alt="" src={Professionals} className={style.IMages} />
          </div>
        </div>
      </div>
      <div className={classNames(style.outer)}>
        <div className={style.col6}>
          <img alt="" src={Treatments} className={style.IMages} />
        </div>
        <div className={style.col6}>
          <p>
            <span className={style.colorBlue}>Cost-Effective</span>
            <span className={style.colorOrange}> Treatments</span>{" "}
          </p>
          <p className={classNames(style.aboutext)}>
            One of the most significant advantages of choosing MediTour Global
            for medical tourism is the cost savings. Medical treatments in
            MediTour Global can be up to 70% more affordable compared to Western
            countries, without compromising on quality. This cost-effectiveness
            extends across a wide range of medical services, including
            surgeries, diagnostics, and rehabilitation.
          </p>
        </div>{" "}
      </div>{" "}
      <div className={classNames(style.container)}>
        <div className={classNames(style.outer, style.flxreverse)}>
          <div className={style.col6}>
            <p>
              <span className={style.colorBlue}> Comprehensive Range of</span>
              <span className={style.colorOrange}> Services</span>{" "}
            </p>
            <p className={classNames(style.aboutext)}>
              MediTour Global offers a diverse array of medical treatments and
              procedures, from routine health check-ups and dental care to
              complex surgeries and advanced fertility treatments. The country's
              healthcare system is well-equipped to handle various medical
              needs, ensuring you can find the right care for your condition.
            </p>
          </div>{" "}
          <div className={style.col6}>
            <img alt="" src={Services} className={style.IMages} />
          </div>
        </div>
      </div>
      <div className={classNames(style.outer)}>
        <div className={style.col6}>
          <img alt="" src={Times} className={style.IMages} />
        </div>
        <div className={style.col6}>
          <p>
            <span className={style.colorBlue}> Shorter Waiting </span>
            <span className={style.colorOrange}> Times</span>{" "}
          </p>
          <p className={classNames(style.aboutext)}>
            Patients at MediTour Global don’t face long waiting periods for
            medical procedures. MediTour Global provides timely access to
            treatments. This promptness is crucial for those seeking urgent care
            or looking to avoid prolonged waiting times for elective surgeries.
          </p>
        </div>{" "}
      </div>
      <div className={classNames(style.container)}>
        <div className={classNames(style.outer, style.flxreverse)}>
          <div className={style.col6}>
            <p>
              <span className={style.colorBlue}> Cultural and Language </span>
              <span className={style.colorOrange}> Compatibility</span>{" "}
            </p>
            <p className={classNames(style.aboutext)}>
              MediTour Global's hospitable culture and multilingual population
              make it an inviting destination for international patients.
              English is widely spoken in medical settings, ensuring clear
              communication between patients and healthcare providers.
              Additionally, MediTour Global's rich cultural heritage and
              friendly locals enhance the overall experience for medical
              tourists.
            </p>
          </div>{" "}
          <div className={style.col6}>
            <img alt="" src={Language} className={style.IMages} />
          </div>
        </div>
      </div>
      <div className={classNames(style.outer)}>
        <div className={style.col6}>
          <img alt="" src={Destinations} className={style.IMages} />
        </div>
        <div className={style.col6}>
          <p>
            <span className={style.colorBlue}> Stunning Travel </span>
            <span className={style.colorOrange}> Destinations</span>{" "}
          </p>
          <p className={classNames(style.aboutext)}>
            Beyond medical care, MediTour offers breathtaking travel
            destinations that can turn your medical trip into a memorable
            experience. From the majestic mountains of the country side to the
            historic sites and vibrant cities, there is much to explore and
            enjoy during your stay.
          </p>
        </div>{" "}
      </div>
      <div className={classNames(style.container)}>
        <div className={classNames(style.outer, style.flxreverse)}>
          <div className={style.col6}>
            <p>
              <span className={style.colorBlue}>Personalized Care and</span>
              <span className={style.colorOrange}> Attention</span>{" "}
            </p>
            <p className={classNames(style.aboutext)}>
              Service Providers enlisted on MediTour Global, prioritize
              personalized care and attention for patients across the globe.
              From the initial consultation to post-treatment recovery,
              dedicated teams ensure that every aspect of your journey is
              tailored to your needs, providing comprehensive support and
              assistance throughout.
              <br /> <br />
              Choosing MediTour Global for medical tourism means accessing
              high-quality, affordable healthcare while experiencing the warmth
              and beauty of a culturally rich country. At MediTour, we are
              committed to making your medical journey smooth, comfortable, and
              successful, offering you the best of both healthcare and
              hospitality.
            </p>
          </div>{" "}
          <div className={style.col6}>
            <img alt="" src={Attention} className={style.IMages} />
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
});

export default AboutUs;
