import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import HelpCards from "shared/components/DonationServices/HelpCards";
import Explore from "shared/components/DonationServices/Explore";
import DonnersCards from "shared/components/DonationServices/DonnersCards";
import style from "./Help.module.css";
import {
  getDonation_Companies,
  getPeople_Donation,
  getRecentDonor,
} from "shared/services";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { DONATION } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import NewPagination from "shared/components/NewPagination/NewPagination";

const DonationServices: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [donorData, setDonor] = useState<any>([]);

  const [companyData, setCompanyData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsLength, setTotalItemsLength] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchAllData();
  }, [currentPage]);

  const fetchAllData = () => {
    setLoading(true);
    Promise.all([
      getDonation_Companies(),
      getPeople_Donation(),
      getRecentDonor({ page: currentPage }),
    ])
      .then((responses: any) => {
        const [companiesRes, criterionRes, donorsRes] = responses;

        setData(companiesRes?.data?.companies || []);
        setCompanyData(criterionRes?.data?.criteria || []);
        setDonor(donorsRes?.data?.donations || []);
        setTotalItemsLength(companiesRes?.data?.totalCompanies || 0);
        setTotalPages(donorsRes?.data?.totalPages || 1);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...DONATION} />

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <div>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              commonstyles.flxWrap
            )}
          >
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Need to Help
            </p>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <NewPagination
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                startItem={(currentPage - 1) * itemsPerPage + 1}
                endItem={Math.min(currentPage * itemsPerPage, totalItemsLength)}
                totalItems={totalItemsLength}
              />
            </div>
          </div>

          {loading ? (
            <CustomLoader />
          ) : !data || data.length === 0 ? (
            <PhysiotheristsEmpty />
          ) : (
            <HelpCards cardsData={data} />
          )}

          {data && data.length > 0 && (
            <>
              <p
                className={classNames(
                  commonstyles.fs32,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Explore Packages
              </p>

              {loading ? (
                <CustomLoader />
              ) : !companyData || companyData.length === 0 ? (
                <PhysiotheristsEmpty />
              ) : (
                <Explore companyData={companyData} />
              )}

              <p
                className={classNames(
                  commonstyles.fs32,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Recent Donors
              </p>

              {loading ? (
                <CustomLoader />
              ) : !donorData || donorData.length === 0 ? (
                <PhysiotheristsEmpty />
              ) : (
                <DonnersCards donorData={donorData} />
              )}
            </>
          )}
          <div className={style.marginTopDonation}></div>
        </div>

        {loading && <CustomLoader />}
      </div>

      <Footerr />
    </div>
  );
};

export default DonationServices;
