import React from "react";
import style from "./Policy.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
export default function PrivactPolicy2() {
  return (
    <div>
      <div>
        {/* <div>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Privacy</span>{" "}
            <span className={style.colorOrange}>Policy</span>{" "}
          </p>

          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            MediTour is always committed to safeguarding our Customer's privacy
            by taking care of our member’s information. This policy will guide
            you in understanding the way how we protect your data. This Privacy
            Policy does not apply to information collected from other platforms
            either online or offline. This Privacy Policy also does not apply to
            any third-party web content to which the Sites may link with each
            other. MediTour does not regulate the privacy contents of
            third-party websites.{" "}
          </p>
        </div> */}
        <div>
          <p className={classNames(style.colorBlue)}>Introduction</p>
          <p className={classNames(style.mt24, style.textcolor)}>
            MediTour is always committed to having serious concern about the
            privacy rights of our customers, visitors, and other users at our
            Mobile App/ Site and related websites, mobile Apps and services
            provided by MediTour and on/in which this Privacy Policy is
            displayed or referenced (collectively, the “Services”). We created
            this Privacy Policy to provide you confidence in using our Services
            and to establish our commitment to the protection of privacy. This
            Privacy Policy applies to the Services only. Your use of the
            services is administered by this Privacy Policy and the Agreement
            defined in our Terms of Use.
            <br />
            <p className={classNames(commonstyle.colorOrange)}>
              BY USING THE SERVICES, YOU AGREE TO THE PRACTICES AND POLICIES
              OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY CONSENT TO THE
              COLLECTION, USE, AND SHARING OF YOUR INFORMATION AS DESCRIBED IN
              THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY,
              YOU CANNOT USE THE SERVICES. IF YOU USE THE SERVICES ON BEHALF OF
              SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR
              EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORIZED BY SUCH
              INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH
              INDIVIDUAL’S OR ENTITY’S BEHALF.
            </p>
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Privacy Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>

          <p className={classNames(style.mt24, style.textcolor)}>
            Personal Information” means any information that may be used to
            recognize an individual. Personal information contains your personal
            name, address, email address, telephone number or Mobile Number, and
            other relevant information.
          </p>
        </div>

        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Collection Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            To access updated features of the Mobile Application/ Sites, you
            must first Fill out a brief registration Application/ form. During
            registration, you are required to provide certain personal
            information (such as User’s name, address, phone number, fax number,
            e-mail address, CNIC/ Passport Number, age, insurance data, Medical
            Data, etc). By registering with MediTour, you confirm to receive
            communications from MediTour in the shape of email, Promotions, etc.
            After getting registered, you may get an option as to whether or not
            you wish to receive these types of communication from MediTour. You
            may visit portions of the Mobile App and Sites without sharing your
            personal information where necessary information will be needed The
            system will ask you to provide the same
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Use Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            Personal information collected through our Mobile Application/ Sites
            is used to process and manage registrations, operate and improve our
            Sites track the Sites’ usage policies and statistics, making the
            Mobile App/ Sites or services are easier to manage by modifying the
            need for you to repeatedly enter the same information, and for some
            other purposes as per your consent. We also use your personal
            information to communicate with you in other ways. For instance,
            when you register on this site, we may use your personal information
            to send you an email confirming your registration. We also use
            personal information to communicate important changes to our Privacy
            Policy, update you about multiple resources or Healthcare
            Personnel’s, communicate about MediTour products and Services and
            using statistical data that we collect from multiple sources
            permitted by law.
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Disclosure Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p className={classNames(commonstyle.fs20, commonstyle.bold)}></p>
          <p className={classNames(style.textcolor)}>
            We may provide personal information to other vendors engaged in
            Contracts who provide services on our behalf, such as Registering
            your account, data processing and delivery of communications,
            hosting the websites, and content writing and services provided by
            the Mobile App/ Sites. We may access, use, or disclose your personal
            information for other reasons, including if we believe such action
            is necessary to comply with the law or legal Regulations process,
            protect and defend our legal rights or property, protect the
            personal safety of members and users of our website or members of
            the general public, agreements, investigate or take action with
            respect to illegal or suspected illegal activities, enforce our
            Terms of Use, in connection with a corporate transaction or
            otherwise according to your consent.
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Public</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>

          <p className={classNames(style.mt24, style.textcolor)}>
            Any information that you may disclose in feedback, reviews online
            discussions, blogs or multiple forums is open to the public and is
            not in any way private. We recommend that you should carefully
            examine before disclosing such Information in any public forum.
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Access To Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            To update or change the personal information you have provided
            MediTour, or to unsubscribe from MediTour, Email
            info@meditour.global
          </p>
        </div>

        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Location</span>{" "}
            <span className={style.colorOrange}>Data</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            Our app uses approximate location data to help users search for
            nearby healthcare providers (e.g., doctors, clinics, and hospitals)
            via Google Maps integration. The app may also collect concise
            location information to display stakeholders' office locations for
            ease of access. Users have the choice to provide location data, and
            it is not mandatory.
          </p>
        </div>

        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Payment</span>{" "}
            <span className={style.colorOrange}>Gateway</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            We use third-party payment gateways to process transactions. We do
            not store sensitive payment information (e.g., credit card details).
            However, we retain transaction history related to services for
            customer reference. All payments are securely handled through
            trusted external providers, ensuring compliance with relevant data
            protection standards.
          </p>
        </div>

        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Storage And Security Of</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>

          <p className={classNames(style.mt24, style.textcolor)}>
            Our priority is to secure your Personal Information which is much
            important to us. We attempt to follow proper rules and regulations
            defined under the law to protect the Personal Information provided
            to us. This policy will properly guide you on how we protect your
            information and data. This Privacy Policy does not apply to any
            information which is gathered from any other platforms even linked
            websites.
          </p>
        </div>
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Privacy Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            Personal Information means any information that may be used to
            recognize an individual. Personal information contains your personal
            name, address, email address, telephone number or Mobile Number and
            other relevant information.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.colorBlue,
              commonstyle.bold
            )}
          >
            Introduction
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            MediTour (“us,” “we,” or “Meditour”) is always committed to having
            serious concern about the privacy rights of our customers, visitors,
            and other users at our Mobile App/ Site and related websites, mobile
            Apps and services provided by MediTour and on/in which this Privacy
            Policy is displayed or referenced (collectively, the “Services”). We
            created this Privacy Policy to provide you confidence in using our
            Services and to establish our commitment to the protection of
            privacy. This Privacy Policy applies to the Services only. Your use
            of the Services is governed by this Privacy Policy and the Agreement
            (defined in our Terms of Use). BY USING THE SERVICES, YOU AGREE TO
            THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND YOU
            HEREBY CONSENT TO THE COLLECTION, USE, AND SHARING OF YOUR
            INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT AGREE
            WITH THIS PRIVACY POLICY, YOU CANNOT USE THE SERVICES. IF YOU USE
            THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN
            ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE
            AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY
            POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Collection Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            To access updated features of the Mobile Application/ Sites, you
            must first Fill out a brief registration Application/ form. During
            registration, you are required to provide certain personal
            information (such as User’s name, address, phone number, fax number,
            e-mail address, CNIC/ Passport Number, age, insurance data, Medical
            Data, etc). After Registration, you accepted to receive
            communications from MEDITOUR through email or Newsletters, etc.
            After getting registered, you may be asked whether you want to
            receive these types of communication or not from us. You may search
            out the portions of Mobile Applications and websites without
            providing any information where necessary MEDITOUR may ask for
            necessary information.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Use Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            Personal information collected through our Mobile Application/ Sites
            is used to process and manage registrations, operate and improve our
            Sites track the Sites’ usage policies and statistics, making the
            Mobile App/ Sites or services are easier to manage by modifying the
            need for you to repeatedly enter the same information, and for some
            other purposes as per your consent. We also use your personal
            information to communicate with you in other ways. For instance,
            when you register on this site, MEDITOUR may use personal
            information to send Acknowledgment email your registration. We also
            use personal information to communicate important changes to our
            Privacy Policy, update you about multiple resources or Healthcare
            Personnel’s, communicate about MEDITOUR products and Services and
            using statistical data that we collect from multiple sources
            permitted by law.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Disclosure Of Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            We may provide personal information to other vendors engaged in
            Contracts who provide services on our behalf, such as Registering
            your account, data processing and delivery of communications,
            hosting the websites, and content writing and services provided by
            the Mobile App/ Sites. We may access your personal information for
            some necessary reasons, including if we believe that such thing is
            under compulsion of law or legal Regulations.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Public</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            Any information that you may disclose in posting a review on Mobile
            App or Website on online discussion, Blog, or forum is deliberately
            open to the public and is not in any way private. We recommend that
            you before giving any comments, reviews, or feedback carefully
            consider whether to post such Information in any public posting or
            not.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Access To Personal</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>
          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            To update or change the personal information you have provided
            MEDITOUR, or to unsubscribe from MEDITOUR, email
            ContactUs@MEDITOUR.pk.
          </p>
        </div> */}
        {/* <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Storage And Security Of</span>{" "}
            <span className={style.colorOrange}>Information</span>{" "}
          </p>

          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            MEDITOUR warrants and represents that its access, collection,
            storage, and disposal of Personal Information does and shall comply
            with applicable federal and state statutes and regulations. The data
            security of your Personal Information is very crucial to us. Without
            limiting Medtour’s obligations according to this Agreement, we shall
            implement administrative, physical, and technical safeguards for the
            protection of Personal Information that are no less rigorous than
            acceptable industry practices and shall ensure that all such
            safeguards comply with applicable data protection and privacy laws,
            statutes and regulations.
          </p>
        </div> */}
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Updates And Changes To</span>{" "}
            <span className={style.colorOrange}>Privacy Policy</span>{" "}
          </p>

          <p className={classNames(style.mt24, style.textcolor)}>
            We will notify you about any changes to this Privacy Policy by
            sending a message through the email/ SMS/ Circular or by placing a
            notification on the Mobile App / Sites. We always encourage you to
            time to time review this page for updates on our privacy policy.
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.colorBlue)}>Copyrights</p>
          <p className={classNames(style.mt24, style.textcolor)}>
            All the material in the shape of images, videos, articles, graphics,
            blogs, content, and articles available on MediTour Mobile App/ Sites
            are the solely owned by MediTour
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.mt16)}>
            <span className={style.colorBlue}>Global Pvt</span>{" "}
            <span className={style.colorOrange}>Limited</span>{" "}
          </p>
          <p className={classNames(style.mt24, style.textcolor)}>
            Such content is prohibited under copyright laws prevailing Globally.
          </p>
        </div>
        <div className={style.mt40}>
          <p className={classNames(style.colorBlue)}>Contacts</p>
          <p className={classNames(style.mt24, style.textcolor)}>
            If you have any comments, Feedback, concerns, or queries about our
            Privacy Policy, please do write us at{" "}
            {/* <span className={style.colorOrange}>info@meditour.global</span>{" "} */}
            <span className={style.colorOrange}>
              themeditourglobal@gmail.com
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
