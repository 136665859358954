import { useFormik } from "formik";
import { fieldMap_Data } from "./props";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSub, postConsultancyForm } from "shared/services";
import { PrimaryButton } from "shared/components";
import { FreeCosultancy_Form } from "shared/utils";
import styles from "./treatmentDetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import CustomLoader from "shared/components/New_Loader/Loader";
import Footerr from "../../Footer";
import toast from "react-hot-toast";
import * as Yup from "yup";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { treatmentDetails } from "shared/utils/mainHeaderQuery";

const TreatmentDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [subCat, setSubCat] = useState([]);
  const { user } = useSelector((state: any) => state?.root?.common);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state?.type === "main speciality") {
      getsubAll();
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: user?.email || "",
      message: "",
    },
    validationSchema: Yup.object(FreeCosultancy_Form),
    onSubmit: () => {
      setLoading(true);
      postConsultancyForm({
        ...formik?.values,
        treatment: state?.item?.subCategory,
      })
        .then(() => {
          toast.success("Form Submit sucessfully");
          formik.resetForm();
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message);
        })
        .finally(() => setLoading(false));
    },
  });

  const getsubAll = () => {
    setLoading(true);
    let params = {
      categoryName: state?.mainTitle,
    };
    getSub(params)
      .then((res: any) => {
        setSubCat(res?.data);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoDetails = (item: any) => {
    navigate("/treatment/Details", {
      state: { ...state, item: item },
    });
  };

  const onPress = () => {
    if (state?.item?.subCategory) {
      navigate("/services/doctor", {
        state: {
          serviceName: "doctor",
          type: "speciality",
          mainTitle: state?.mainTitle,
          item: state?.item,
        },
      });
    } else {
      toast.error("Please Select treatment");
    }
  };

  // console.log("state?.mainIndex", state?.mainIndex);

  let categoryMap =
    state?.type === "main speciality"
      ? subCat
      : state?.data[state?.mainIndex]?.treatments;

  // console.table(treatmentDetails(state));

  return (
    <div>
      <NavBreadCrumbs {...treatmentDetails(state)} />

      <div className={styles.container}>
        <div className={styles.flxBetween}>
          <div className={styles.col7}>
            <p
              className={classNames(
                styles.fs36,
                commonstyles.semiBold,
                styles.colorBlue
              )}
            >
              About {state?.item?.subCategory}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.colorGray,
                styles.mt8
              )}
              style={{ textAlign: "justify" }}
            >
              {state?.item?.description}
            </p>

            <div className={commonstyles.mt56}>
              <p
                className={classNames(
                  commonstyles.fs1,
                  commonstyles.semiBold,
                  styles.colorBlue
                )}
              >
                {state?.mainTitle}
              </p>

              <ul className={styles.list}>
                {categoryMap?.map((d: any) => {
                  console.log(
                    "🚀 ~ {categoryMap?.map ~ categoryMap:",
                    categoryMap
                  );
                  // if (d.subCategory !== state?.item?.subCategory) {
                  return (
                    <li
                      onClick={() => handleGoDetails(d)}
                      style={{
                        color:
                          d.subCategory !== state?.item?.subCategory
                            ? "#0e54a3"
                            : "#ff7631",
                      }}
                    >
                      {d?.subCategory}
                    </li>
                  );
                  // }
                })}
              </ul>

              <div style={{ marginTop: "50px" }}>
                <PrimaryButton
                  children={"Book Now"}
                  colorType={"blue"}
                  onClick={onPress}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.col4)}>
            <div className={classNames(styles.ConsultancyBox, styles.mtsm32)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.medium,
                  styles.colorBlue
                )}
              >
                Get Free Consultancy
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.colorGray,
                  styles.mt8
                )}
              >
                Please fill out the form below and we will contact you shortly
              </p>
              <form onSubmit={formik.handleSubmit}>
                {fieldMap_Data.map((field: any) => (
                  <FormField
                    formik={formik}
                    id={field?.idName}
                    placeHolder={field?.placeHolder}
                    fieldValue={state?.item?.subCategory}
                  />
                ))}

                <div className={classNames(styles.flexCol, styles.mt24)}>
                  <label className={styles.labels}>Description:</label>
                  <textarea
                    className={styles.textaera}
                    id="message"
                    value={formik?.values?.message}
                    onChange={formik?.handleChange("message")}
                    placeholder={"Enter Description"}
                  />
                </div>
                <button className={styles.btnSubmit} type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}

      <Footerr />
    </div>
  );
};

const FormField = ({
  id,
  placeHolder,
  formik,
  editable,
  fieldValue,
}: {
  id: any;
  placeHolder: any;
  formik: any;
  editable?: any;
  fieldValue?: any;
}) => {
  let value = formik?.values;
  let touched = formik?.touched;
  let error = formik.errors;
  //

  return (
    <div className={classNames(styles.flexCol, styles.mt24)}>
      <label className={styles.labels}>
        {id.charAt(0).toUpperCase() + id.slice(1)}:
      </label>
      <input
        disabled={id == "treatment" ? true : false}
        id={id}
        type="text"
        placeholder={placeHolder}
        className={styles.inputs}
        value={id === "treatment" ? fieldValue : value[id]}
        onChange={formik?.handleChange(id)}
      />
      {touched[id] && error[id] && (
        <div
          className={classNames(commonstyles.error)}
          style={{ alignSelf: "flex-start" }}
        >
          *{error[id]}
        </div>
      )}
    </div>
  );
};

export default TreatmentDetails;
