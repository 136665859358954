import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const travelAgencySignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_SIGNUP, params);
};

export const travelAgencySendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_SEND_CODE_TO_EMAIL, params);
};

export const travelAgencyConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_CONFIRM_EMAIL, params);
};
// shared/services/TravelAgency.ts

export const deletetourbooking = (tourId: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DELETE_TOUR_BOOKING}?tourId=${tourId}`
  );
};

export const travelAgencyCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.TRAVELAGENCY_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};
export const travelAgencyGetAllOneWayFlights = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TRAVELAGENCY_GETALLONEWAYTRIP);
};

export const travelAgencyLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_LOGIN, params);
};

export const resetLinkTravelAgency = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_RESET_LINK, params);
};

export const newPasswordTravelAgency = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.TRAVELAGENCY_NEWPASSWORD}?token=${token}`,
    params
  );
};

// Example of what the API function might look like
export const updateDoctorProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.UPDATE_DOCTOR_PROFILE, params);
};
export const updateLabProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.UPDATE_LABORTERY, params);
};
export const updateTravelAgency = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.UPDATE_TRAVELAGENCY, params);
};


export const travelAgencyLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_LOGOUT);
};
export const travelAgencyGetallOneWayFlight = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TRAVELAGENCY_GETALLONEWAYTRIP);
};

export const travelAgencyUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.TRAVELAGENCY_UPDATEPROFILE, params);
};

export const travelAgencyDASHDETAILS = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TRAVELAGENCY_DASHDETAILS);
};

export const travelAgencyGRAPHBYWEEK = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TRAVELAGENCY_GRAPHBYWEEK);
};

export const travelAgencyGRAPHBYMONTH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TRAVELAGENCY_GRAPHBYMONTH);
};

export const travelFLIGHTGETREQUEST = (pageno: number) => {
  
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_FLIGHTGETREQUEST}?page=${pageno}`
  );
};
export const addBidFlight = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_FLIGHT_BID, data);
};
export const get_requestFetch = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GETALL_DETAILS_REQUEST}?requestId=` + data.requestId
  );
};
export const fetchTravel = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_TRAVELERS}?bidRequestId=` + data?.bidRequestId
  );
};
export const pushTicket_To = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.PUSH_IMAGE_TICKET}?bidRequestId=` + data?.bidRequestId,
    params
  );
};
export const travelAgencyGETALLTOURS = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_GETALLTOURS}?page=${pageno}`
  );
};

export const travelADDTOUR = (params: any) => {
  
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_ADDTOUR, params);
};

export const travelAgencyADDBID = (params: any) => {
  
  return HTTP_CLIENT.post(ENDPOINTS.TRAVELAGENCY_ADDBID, params);
};

export const travelGETTICKETBOOKINGS = (requestType: string, page: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_GETTICKETBOOKINGS}?requestType=${requestType}&page=${page}`
  );
};

export const travelGETRequestDetails = (requestId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_GETUSERREQUESTDETAILS}?requestId=${requestId}`
  );
};

export const travelBookingDetails = (
  requestType: string,
  requestId: string
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_BOOKINGDETAILS}?requestType=${requestType}&bookingId=${requestId}`
  );
};

export const travelGETTOURDETAILS = (tourId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TRAVELAGENCY_GETTOURDETAILS}?tourId=${tourId}`
  );
};
