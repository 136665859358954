import React, { useEffect, useRef, useState } from "react";
import styles from "./order.module.css";
import commonstyles from "../../../shared/utils/common.module.css";
import commomstyles from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import {
  add_File,
  getAllOrders,
  labStatusChange,
  labUploadResult,
  uploadFile,
} from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal, RingLoader } from "shared/components";
import { setOrder, setOrderLength, setrenderLabOrderFlag } from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import CustomLoader from "shared/components/New_Loader/Loader";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import TableNew from "shared/components/A_New_Components/Table_new";
import dayjs from "dayjs";
import { HiOutlineUpload } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import CustomSelectOrder from "./CustomSelectOrder";
import FilePicker from "shared/components/FilePickeInsurance";
import { useFilePicker } from "use-file-picker";

const OrderList = [
  "Order ID ",
  "Patient Name",
  "No of Test",
  "Date",
  "Status",
  "Results",
];
const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showOrders, setShowOrders] = useState<any[]>([]);
  const [ordersData, setOrdersData] = useState<any[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  console.log("🚀 ~ Orders ~ selectedOrderId:", selectedOrderId);

  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const { orders, orderLength } = useSelector((state: any) => state.root.lab);
  const itemsPerPage = 10;
  const totalItems = orderLength;

  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    multiple: true,

    onFilesRejected: ({ errors }) => {},
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      uploadImage(plainFiles[0]);
    },
  });

  const uploadImage = async (imagedata: any) => {
    setUploading(true);

    const data: any = new FormData();
    await data.append("file", imagedata);

    uploadFile(data)
      .then((res: any) => {
        if (res.status === 200 && res.statusText === "OK") {
          // setSelectedFileName(imagedata.name);

          console.log("🚀 ~ uploadImage ~ res", res?.data?.fileUrl);
          uploadResult(res?.data?.fileUrl, selectedOrderId);
        }
      })
      .catch((err: any) => {})
      .finally(() => {});
  };

  const uploadResult = (url: any, id: any) => {
    let params = {
      resultUrl: url,
    };
    labUploadResult(id, params)
      .then((res: any) => {
        // onStatusChange();
        handleStatusChange();
      })
      .catch((err: any) => {})
      .finally(() => {
        setUploading(false);
      });
  };

  const triggerFileSelect = async (item: any) => {
    await setSelectedOrderId(item._id);
    openFilePicker();
  };

  const handleGoToOrderDeatil = (ind: any) => {
    const selectedObject: any = ordersData[ind];
    console.log("🚀 ~ handleGoToOrderDeatil ~ selectedObject:", selectedObject);
    navigate(`/laboratory/order/Detail/${selectedObject?._id}`);
  };
  const fetchOrders = (pageno: number) => {
    setIsLoading(true);
    getAllOrders(pageno)
      .then((res: any) => {
        dispatch(setOrder(res?.data?.orders));
        let order = res?.data?.orders;
        setOrdersData(order);
        console.log("🚀 ~ .then ~ order:", order);
        let tempData: any = [];
        order?.map((v: any, ind: any) => {
          tempData.push([
            v?.orderId,
            v?.customerName,
            v?.itemsCount,
            dayjs(v?.createdAt).format("DD-MM-YYYY"),
            <div className={styles.Status} onClick={(e) => e.stopPropagation()}>
              <CustomSelectOrder
                orderId={v._id}
                initialStatus={v.status}
                onStatusUpdate={handleStatusChange}
              />
            </div>,
            <div onClick={(e) => e.stopPropagation()}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                // onChange={handleFileUpload(v._id)}
              />

              {/* Upload Icon */}
              <HiOutlineUpload
                className={styles.upload}
                onClick={() => triggerFileSelect(v)}
              />
              {/* <FilePicker setData={handleUrl} /> */}
            </div>,
          ]);
        });

        setShowOrders(tempData);
      })
      .catch((err: any) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (orderLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchOrders(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchOrders(currentPage - 1);
    }
  };

  const handleStatusChange = () => {
    fetchOrders(currentPage);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchOrders(1);
    setCurrentPage(1);
    dispatch(setrenderLabOrderFlag(false));
  }, []);

  return (
    <div className={classNames(commomstyles.col12)}>
      <div className={classNames(commomstyles.pr36)}>
        <div className={classNames(styles.flxBetween, commomstyles.mb24)}>
          <div className={classNames(commomstyles.flx)}>
            <p className={classNames(styles.heading)}>All Order</p>

            {isLoading ? (
              <div
                className={styles.outerRefresh}
                style={{ marginLeft: "16px" }}
              >
                <RingLoader color={"#0D47A1"} size={30} />
              </div>
            ) : (
              <div className={styles.outerRefresh}>
                <TbRefresh
                  className={styles.RefreshIcon}
                  onClick={() => {
                    fetchOrders(1);
                    setCurrentPage(1);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.TableOuter}>
          <div className={commomstyles.flxBetween}>
            <div>
              <SearchFilter title={"Search "} />
            </div>

            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          <div className={commomstyles.mt24}>
            {orders && orders.length > 0 ? (
              // <Tableorder orders={orders} onStatusChange={handleStatusChange} />
              <TableNew
                titles={OrderList}
                data={showOrders}
                headerWidth="16%"
                itemWidth="16%"
                height="315px"
                handleGoToDetail={handleGoToOrderDeatil}
              />
            ) : (
              <>
                <PhysiotheristsEmpty />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Orders;
