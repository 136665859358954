import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import DoubleButton from "shared/components/Buttons/DoubleButton";
import toast from "react-hot-toast";
import { getAllOrders_History } from "shared/services";
import { LoadingModal } from "shared/components";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "../../Footer";
import styles from "./orderHistory.module.css";
import loginLogo from "../../../../../assets/images/loginLogo.png";
import DownloadImagelogo from "../../../../../assets/images/DownloadImagelogo.png";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import commonstyle from "shared/utils/common.module.css";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { MY_ORDER_HISTORY } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import dayjs from "dayjs";
const MyOrderHome = React.memo((props) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("Laboratory");
  const [list, setList] = useState<any>([]);
  //
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchOrders();
  }, [selected]);

  const handleTabChange = (tab: string) => {
    setSelected(tab);
  };

  const fetchOrders = () => {
    let params: any = {
      page: 1,
      type: selected.toLowerCase(),
    };

    getAllOrders_History(params)
      .then((res: any) => {
        setList(res?.data?.orders || []);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoToOrderHistoryDetail = (item: any) => {
    navigate("/services/orderHistoryDetail", {
      state: { item: item },
    });
  };

  return (
    <div>
      <NavBreadCrumbs {...MY_ORDER_HISTORY} />

      <div className={classNames(commonstyle.mb32)}>
        <div className={styles.margin}>
          <DoubleButton
            tab1Label="Laboratory"
            tab2Label="Pharmacy"
            onTabChange={handleTabChange}
            shift={selected}
          />
        </div>

        <div className={styles.orderContainer}>
          {loading ? (
            <CustomLoader />
          ) : list.length > 0 ? (
            list.map((order: any, index: any) => {
              let CURRENCY = order?.gatewayName === "blinq" ? "PKR " : "$ ";
              let lab =
                order?.gatewayName === "blinq"
                  ? order?.totalAmount
                  : order?.dollarAmount;
              let pharm =
                order?.gatewayName === "blinq"
                  ? order?.amount
                  : order?.dollarAmount;
              let handleAmount = selected === "Laboratory" ? lab : pharm;

              return (
                <div key={index} className={styles.orderCard}>
                  <div className={styles.logoContainer}>
                    <img
                      src={order?.vendorId?.logo || loginLogo}
                      alt={order?.vendorId?.name}
                      className={styles.orderLogo}
                    />
                  </div>
                  <div className={styles.orderDetails}>
                    <h3 className={styles.orderTitle}>
                      {order?.vendorId?.name}
                    </h3>
                    <p className={styles.orderQuantity}>
                      Quantity:{" "}
                      {order?.items?.length || order?.medicineIds?.length}
                    </p>
                    <p className={styles.orderPrice}>
                      {CURRENCY + handleAmount}
                    </p>
                    <p
                      className={classNames(styles.orderStatus, {
                        [styles.statusInProgress]:
                          order?.status === "In-Progress",
                        [styles.statusPending]: order?.status === "pending",
                      })}
                    >
                      {order?.status}
                    </p>
                  </div>
                  <div className={styles.orderMeta}>
                    <p className={styles.orderTime}>
                      {dayjs(order?.createdAt).format("DD-MM-YYYY, h:mm a")}
                    </p>
                    <button
                      onClick={() => handleGoToOrderHistoryDetail(order)}
                      className={styles.detailsButton}
                    >
                      View Details
                      <img
                        src={DownloadImagelogo}
                        alt="Logo"
                        className={styles.downloadButtonLogo}
                      />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>

      <Footerr />
    </div>
  );
});

export default MyOrderHome;
