import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./Detailstyle.module.css";
import parklane1 from "assets/images/parklane1.png";
import parklane2 from "assets/images/parklane2.png";
import parklane3 from "assets/images/parklane3.png";
import parklane4 from "assets/images/parklane4.png";
import parklane5 from "assets/images/parklane4.png";
import parklane6 from "assets/images/parklane4.png";
import hstyle from "./Hotel.module.css";
import { IoIosWifi } from "react-icons/io";
import { FaHotTub, FaSpa, FaThermometerHalf, FaWater } from "react-icons/fa";
import { LuFlower2, LuClub, LuSofa } from "react-icons/lu";
import {
  FaUmbrellaBeach,
  FaPersonSwimming,
  FaBanSmoking,
  FaTv,
  FaBath,
  FaJugDetergent,
  FaToiletPaper,
  FaFan,
  FaPumpSoap,
  FaCouch,
} from "react-icons/fa6";
import { CgGym, CgSmartHomeRefrigerator } from "react-icons/cg";
import { PiMosqueDuotone } from "react-icons/pi";
import ReviewComponent from "shared/components/DonationServices/ReviewComponent";
import { useLocation, useNavigate } from "react-router-dom";
import MainHeader from "shared/components/MainScreen/Index";
import { DetailsInfoHotel } from "shared/services";
import { useSelector } from "react-redux";
import { TbAirConditioning, TbMicrowave } from "react-icons/tb";
import {
  MdBalcony,
  MdDining,
  MdKitchen,
  MdOutlineBalcony,
  MdOutlineHouse,
} from "react-icons/md";
import { GiKitchenKnives, GiToaster, GiTowel } from "react-icons/gi";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { HOTEL_DETAILS } from "shared/utils/mainHeaderQuery";

const images = [
  parklane1,
  parklane2,
  parklane3,
  parklane4,
  parklane5,
  parklane6,
];

interface Place {
  name: string;
  value: string;
}

const places: Place[] = [
  { name: "Vogue Towers", value: "0.6 mi" },
  { name: "Pace Shopping Mall", value: "0.6 mi" },
  { name: "Gaddafi Stadium", value: "0.6 mi" },
  { name: "Fortress Square", value: "0.6 mi" },
  { name: "Lahore Gymkhana", value: "0.6 mi" },
  { name: "Packages Mall", value: "0.6 mi" },
];

const amenityIcons: any = {
  Beach: <FaUmbrellaBeach />,
  "Night Club": <LuClub />,
  Microwave: <TbMicrowave />,
  "Flat Screen TV": <FaTv />,
  Balcony: <MdBalcony />,
  Wifi: <IoIosWifi />,
  Spa: <FaSpa />,
  Flower: <LuFlower2 />,
  Mosque: <PiMosqueDuotone />,
  Swimming: <FaPersonSwimming />,

  "Bath Tub": <FaBath />,
  "Spa Tub": <FaHotTub />,
  "Electric Kettle": <FaJugDetergent />,
  Refrigerator: <CgSmartHomeRefrigerator />,
  Kitchenware: <GiKitchenKnives />,
  Heating: <FaThermometerHalf />,
  Towels: <GiTowel />,
  Kitchenette: <MdKitchen />,
  "Toilet Paper": <FaToiletPaper />,
  Toaster: <GiToaster />,
  Fan: <FaFan />,
  Detached: <MdOutlineHouse />,
  Bidet: <FaBath />,
  "Hand Sanitizer": <FaPumpSoap />,
  Sofa: <LuSofa />,
  "Dining Table": <MdDining />,
  "Air Conditioning": <TbAirConditioning />,
};

// const icons = [
//   { icon: <IoIosWifi />, name: "Wifi" },
//   { icon: <FaSpa />, name: "Spa" },
//   { icon: <LuFlower2 />, name: "Flower" },
//   { icon: <FaUmbrellaBeach />, name: "Beach" },
//   { icon: <LuClub />, name: "Club" },
//   { icon: <CgGym />, name: "Gym" },
//   { icon: <PiMosqueDuotone />, name: "Mosque" },
//   { icon: <FaWater />, name: "Water" },
//   { icon: <FaPersonSwimming />, name: "Swimming" },
//   { icon: <FaBanSmoking />, name: "No Smoking" },
// ];
const HotelDetails = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { hotelDetail, isLoggedIn } = useSelector(
    (state: any) => state.root.common
  );
  let item = state ? state.item : null;

  const handledetails = () => {
    if (isLoggedIn) {
      navigate("/services/hotel/HotelAvability", { state: { data } });
    } else {
      navigate("/user/login");
    }
  };
  useEffect(() => {
    getHotelDetailsInfo();
  }, []);
  const getHotelDetailsInfo = () => {
    setLoading(true);
    const params = {
      id: item._id,
      serviceType: hotelDetail?.selectedOption?.toLowerCase(),
    };

    DetailsInfoHotel(params)
      .then((res: any) => {
        //
        setData(res.data.hotel);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div>
      <NavBreadCrumbs {...HOTEL_DETAILS} />

      <div
        className={classNames(
          commonstyles.container,
          commonstyles.flx,
          commonstyles.flxWrap,

          commonstyles.flxBetween
        )}
      >
        {/* Images Section */}
        <div className={classNames(commonstyles.col7, commonstyles.colsm12)}>
          {/* First Row - 2 images */}
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.colorBlue,
              commonstyles.semiBold
            )}
          >
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.colorBlue,
                commonstyles.semiBold
              )}
            >
              {/* Park Lane Hotel Lahore */}
              {/* {item?.hotelId?.name} */}
              {item?.propertyName}
            </p>
          </p>
          <div
            className={dstyle.imgContainer}
            style={{
              marginBottom: "10px",
            }}
          >
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className={dstyle.displayimg}
            />
            <div className={dstyle.dotsContainer}>
              {images.map((_, index) => (
                <span
                  key={index}
                  className={classNames(dstyle.dot, {
                    [dstyle.activeDot]: index === currentIndex,
                  })}
                ></span>
              ))}
            </div>
          </div>

          {/* <div className={classNames(dstyle.imgRow, dstyle.threeImagesRow)}>
            {images.slice(2, 5).map((imgSrc, index) => (
              <div
                key={index}
                className={classNames(dstyle.imgContainer, {
                  [dstyle.viewMoreContainer]: index === 2 && !showMore,
                })}
              >
                <img
                  src={imgSrc}
                  alt={`Parklane ${index + 3}`}
                  className={classNames(dstyle.img, {
                    [dstyle.viewMoreImg]: index === 2 && !showMore, // Hide 3rd image behind overlay
                  })}
                  onClick={() => setShowMore(true)} // Show more images on click
                />
                {index === 2 && !showMore && (
                  <div className={dstyle.viewMoreText}>View More</div> // Display "View More"
                )}
              </div>
            ))}
          </div> 

          {/* Additional images (shown after clicking "View More") */}
          {/* {showMore && (
            <div className={classNames(dstyle.imgRow, dstyle.threeImagesRow)}>
              {images.slice(5).map((imgSrc, index) => (
                <div key={index} className={dstyle.imgContainer}>
                  <img
                    src={imgSrc}
                    alt={`Parklane ${index + 6}`}
                    className={dstyle.img}
                  />
                </div>
              ))}
            </div>
          )} */}
          <div className={dstyle.iconRow}>
            {data.amenities?.map((item: any, index: any) => (
              <div key={index} className={dstyle.iconCard}>
                <div className={dstyle.iconContainer}>{amenityIcons[item]}</div>
                <span className={dstyle.iconName}>{item}</span>
              </div>
            ))}
          </div>
          <div>
            <p
              className={classNames(commonstyles.fs14, commonstyles.colorGray)}
            >
              {data?.location?.address}
            </p>
          </div>
          <div className={classNames(commonstyles.mt16)}>
            <p
              className={classNames(
                commonstyles.fs14,
                commonstyles.colorBlue,
                commonstyles.semiBold
              )}
            >
              Staff Speaking
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.colorGray)}
            >
              {data?.language}
            </p>
            <div className={classNames(commonstyles.mt16)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Property surroundings
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <div className={dstyle.placesContainer}>
                    {data.propertySurroundings?.map((place: any) => (
                      <div
                        key={place.value}
                        className={classNames(dstyle.placeItem)}
                      >
                        <span className={dstyle.placeName}>
                          {place?.propertyName}
                        </span>
                        <span className={dstyle.placeValue}>
                          {place?.propertyDistance}
                        </span>
                      </div>
                    ))}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/*  Content Section */}
        <div className={classNames(commonstyles.col4, commonstyles.colsm12)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <p
              className={classNames(
                commonstyles.colorGreen,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Starting Price
            </p>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs18,
                commonstyles.semiBold
              )}
            >
              PKR{" "}
              {item?.minRoomPrice
                ? item?.minRoomPrice
                : item?.minApartmentPrice
                ? item?.minApartmentPrice
                : item?.minHomePrice}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Your Plan
            </p>
            <div
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorGreen
              )}
            >
              <p>
                <span
                  style={{
                    width: "30%",
                    textAlign: "start",
                  }}
                >
                  Check-In Date
                </span>
                {`  ${hotelDetail?.fromDate}`}
              </p>
              <p>
                <span
                  style={{
                    width: "30%",
                    textAlign: "start",
                  }}
                >
                  Check-Out Date
                </span>
                <span>{`  ${hotelDetail?.fromDate}`}</span>
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorGreen
              )}
            >
              {`${hotelDetail?.roomsQuantity} room, ${hotelDetail?.adultsQuantity} adult ,${hotelDetail?.childrenQuantity} children`}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                // marginTop: "210px ",
              }}
            >
              <div>
                <div
                  className={hstyle.showMoreContainer}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.colorBlue,
                      commonstyles.semiBold
                    )}
                  >
                    Parking
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs18,
                      commonstyles.semiBold,
                      commonstyles.colorGreen
                    )}
                  >
                    {`${data?.parkingAvailability}`}
                  </p>
                  <button
                    className={hstyle.showMoreButton}
                    onClick={handledetails}
                  >
                    See Availability
                    <span className={hstyle.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "32px",
              }}
            >
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Policies
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorGray
                )}
              >
                Check In From{" "}
                {`${data.policies?.checkInFrom} Until ${data.policies?.checkInTo}`}{" "}
                <br />
                Check Out From{" "}
                {`${data.policies?.checkOutFrom} Until ${data.policies?.checkOutTo}`}
              </p>

              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorGray,
                  commonstyles.mt16
                )}
              >
                <span
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorBlue,
                    commonstyles.semiBold
                  )}
                  style={{ margin: "0 5px" }}
                >
                  Cancellation Policy:
                </span>

                <span>{data?.advanceCancelfreeofCharge}</span>
              </p>

              <div
                className={classNames(
                  commonstyles.flx,
                  commonstyles.flxBetween
                )}
              >
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue,
                    commonstyles.semiBold
                  )}
                >
                  Pets
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue
                  )}
                >
                  {data?.pets} Allowed
                </p>
              </div>
            </div>
            {data?.chargesOfPets && (
              <div className={classNames(commonstyles.flx)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorGray,
                    commonstyles.mt16
                  )}
                  style={{
                    margin: "0 5px",
                  }}
                >
                  {data?.chargesOfPets}
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorGray,
                    commonstyles.mt16
                  )}
                  style={{
                    margin: "0 5px",
                  }}
                >
                  per Pet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames(
          commonstyles.container,
          commonstyles.flx,
          commonstyles.flxWrap,

          commonstyles.flxBetween
        )}
      >
        {/* <ReviewComponent /> */}
      </div>

      <Footerr />
    </div>
  );
};

export default HotelDetails;
