import * as React from "react";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Datem from "assets/images/datepicker.png";
import style from "./datePicker.module.css";
import { DesktopDatePicker, DatePickerProps } from "@mui/x-date-pickers";

// Extend dayjs with UTC and Timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface DatepickerProps extends DatePickerProps<dayjs.Dayjs> {
  setData?: (date: dayjs.Dayjs | null) => void;
  className?: string;
  placeholder?: string;
  futureDisable?: boolean;
  value?: dayjs.Dayjs | null | undefined;
}

function MuiIcon() {
  return (
    <img src={Datem} alt="Date picker opening icon" className={style.DateImg} />
  );
}

export default function DatepickerNew({
  setData,
  className,
  placeholder,
  futureDisable,
  value,
  ...rest
}: DatepickerProps) {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (futureDisable && date && date.isAfter(dayjs(), "day")) {
      return;
    }
    const adjustedDate = date ? date.startOf("day").utc(true) : null;

    setSelectedDate(adjustedDate);
    if (setData) {
      setData(adjustedDate);
    }
  };

  const maxDate = futureDisable ? dayjs() : undefined;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        defaultValue={dayjs(new Date())}
        label={placeholder}
        value={selectedDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        slots={{ openPickerIcon: MuiIcon }}
        sx={{
          width: "100%",
          height: "15px",
          "& .MuiStack-root": {
            overflow: "visible",
            textAlign: "left",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
          },
          "& .MuiIconButton-root": {
            color: "gray",
            "&:hover": {
              color: "darkgray",
            },
          },
          "& .MuiOutlinedInput-input": {
            height: "16px",
            color: "#000000",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "16px",
            marginLeft: "15px",
            paddingLeft: "0px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            border: "none",
            marginLeft: "15px",
            fontFamily: '"Poppins", sans-serif',
            color: "#6F6F72",
            position: "absolute",
            left: "-13px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            color: "#333",
            border: "1px solid #ccc",
            fontSize: "10px",
            "&:hover": {
              borderColor: "#ccc",
            },
            "&.Mui-focused": {
              borderColor: "#ccc",
            },
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
