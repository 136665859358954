import React, { useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SwitchButton = (props: any) => {
  const { active, setActive } = props
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);

  const handleSwitch = (option: any) => {
    setActive(option);
  };
  return (
    <div className={classNames(style.capsule_container)}>
      <div
        className={classNames(style.capsule_active)}
        style={{ left: active === "login" ? "0" : "50%" }}
      ></div>
      <div
        className={classNames(style.capsule_button, {
          [style.active]: active === "login",
        })}
        onClick={() => handleSwitch("login")}
        style={{ color: active === "login" ? "white" : "#555" }}
      >
        Login
      </div>
      <div
        className={classNames(style.capsule_button, {
          [style.active]: active === "signup",
        })}
        onClick={() => handleSwitch("signup")}
        style={{ color: active === "signup" ? "white" : "#555" }}
      >
        Signup
      </div>
    </div>
  );
};

export default SwitchButton;
