import React, { useEffect, useRef, useState } from "react";
import styles from "./homeNavBar.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import MainDoctor from "assets/images/doctorgroup.png";
import FAQ from "assets/images/FAQSImg.png";

import cosmetic from "assets/images/CosmeticandPlasticSurgery.png";
import Aesthetic from "assets/images/Aesthetic.png";
import Hair from "assets/images/HairTransplant.png";
import StemCell from "assets/images/STEMCell.png";
import Fertility from "assets/images/Fertility.png";
import BariatricSurgery from "assets/images/BariatricSurgery.png";
import Erectile from "assets/images/ErectileDysfunction.png";
import GenderSelection from "assets/images/GenderSelection.png";
import DentalIcon from "assets/images/DentalIcon.png";
import Cardio from "assets/images/Cardio.png";
import Ortho from "assets/images/Ortho.png";
import Organ from "assets/images/Organ.png";
import FAQS from "shared/components/FAQs";
import { useNavigate } from "react-router-dom";
import Footerr from "./Footer";
import ContactUs from "./ContactUs";
import medi1 from "assets/images/medi.png";
import medi2 from "assets/images/medi2.png";
import Laptop from "assets/images/Laptop.png";

import WorldClass from "assets/images/worldClass.png";
import NavBarr from "./NavBarr";
import HomeMianSlider from "./MainSlider/index";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import TopTreatment from "./HomeSlider/TopTreatments";
import TopPlaces from "./HomeSlider/TopPlaces";
import { IoMdArrowRoundForward } from "react-icons/io";
import { logos_data, treatmentsData } from "shared/utils";
import LogoSlider from "shared/components/A_New_Components/LogoSlider";
import TourismSlider from "shared/components/A_New_Components/TourismSlider";
import ParallaxCarousel from "shared/components/A_New_Components/ParralelXSlider";

const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState({
    id: 1,
    heading: "World-class Quality",
    icon: WorldClass,

    description:
      "MediTour is dedicated to consistently delivering healthcare services of international standards, ensuring high quality and excellence in patient care.",
  });

  const handletabs = (item: any) => {
    setSelectedTab(item);
  };

  const navigate = useNavigate();

  const handleGoAboutUs = () => {
    navigate("/ExploreAll");
  };

  const HandleGoFAQS = () => {
    navigate("/FAQs");
  };

  const handleGoDetails = (title: any, ind: number) => {
    treatmentsData?.map((item: any, index: any) => {
      if (item?.mainTitle === title) {
        navigate("/treatment/Details", {
          state: {
            item: item?.list[ind],
            mainIndex: index,
            mainTitle: item.mainTitle,
            type: "main speciality",
          },
        });
      }
    });
  };

  const treatmentData = [
    {
      id: 0,
      title: "Aesthetic",
      indexes: 0,
      img: Aesthetic,
      itemTitle: "AESTHETIC TREATMENTS",
    },
    {
      id: 1,
      title: "Bariatric Surgery",
      indexes: 0,

      img: BariatricSurgery,
      itemTitle: "BARIATRIC SURGERY",
    },
    {
      id: 2,
      title: "Cardiology",
      img: Cardio,
      itemTitle: "CARDIOVASCULAR & METABOLIC",
      indexes: 0,
    },
    {
      id: 3,
      title: "Cosmetic & Plastic Surgery",
      indexes: 0,
      img: cosmetic,
      itemTitle: "COSMETIC SURGERY",
    },
    {
      id: 4,
      title: "Dental",
      img: DentalIcon,
      itemTitle: "DENTAL",
      indexes: 0,
    },
    {
      id: 5,
      title: "Erectile Dysfunction",
      img: Erectile,
      indexes: 0,
      itemTitle: "STEM CELL",
    },
    {
      id: 6,
      title: "Fertility",
      img: Fertility,
      itemTitle: "FERTILITY",
      indexes: 4,
    },
    {
      id: 7,
      title: "Gender Selection",
      img: GenderSelection,
      itemTitle: "FERTILITY",
      indexes: 4,
    },
    {
      id: 8,
      title: "Hair Transplant",
      img: Hair,
      itemTitle: "TRANSPLANTS",
      indexes: 3,
    },
    {
      id: 9,
      title: "Orthopedic",
      img: Ortho,
      itemTitle: "ORTHOPEDICS",
      indexes: 0,
    },
    {
      id: 10,
      title: "Organ Transplant",
      img: Organ,
      itemTitle: "TRANSPLANTS",
      indexes: 0,
    },
    {
      id: 11,
      title: "STEM Cell",
      img: StemCell,
      itemTitle: "STEM CELL",
      indexes: 0,
    },
  ];

  return (
    <div className={classNames(styles.scrollbar, styles.mainouter)}>
      {" "}
      <HomeMianSlider />
      <div className={styles.TreatmentContainer}>
        <div className={styles.medicalCardsOuter}>
          {treatmentData?.map((i: any, index: number) => (
            <div
              key={index}
              className={classNames(styles.medicalCard)}
              onClick={() => handleGoDetails(i?.itemTitle, i?.indexes)}
            >
              <img alt="" src={i?.img} className={styles.medicalIcons} />
              <p
                className={classNames(
                  styles.mt16,
                  styles.itemTitle,
                  styles.textCenter
                )}
              >
                {i?.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(styles.TourismPakContainer)}>
        {/* Left Column - Image/Slider */}
        <div className={styles.sliderMiancontainer}>
          <TourismSlider />
        </div>

        {/* Right Column - Content */}
        <div className={styles.TourismTextContainer} style={{ flexGrow: 1 }}>
          <h1>
            <span className={styles.tourtext}>Medical</span>{" "}
            <span className={styles.pakistantext}>Tourism</span>
          </h1>

          <p style={{ lineHeight: "25px" }} className={styles.tourparagraph}>
            Rest assured, we specialize in curating exceptional medical tourism
            experiences tailored to your needs. From cosmetic enhancements to
            complex medical procedures, we connect you with top-tier healthcare
            providers globally. Count on us to facilitate your journey to
            optimal health and well-being with meticulous attention to detail
            and unparalleled expertise. We meticulously manage every aspect of
            your trip including travel logistics, accommodation arrangements,
            and post-operative care, ensuring a seamless and stress-free
            recovery. Our commitment extends beyond medical excellence to
            encompass your overall comfort and cultural immersion.
          </p>

          <button className={styles.WhyPakistan} onClick={handleGoAboutUs}>
            <span>Explore</span>
            <div className={styles.iconOuter}>
              <IoMdArrowRoundForward className={styles.Iconm2} />
            </div>
          </button>
        </div>
      </div>
      {/* <div style={{ margin: "64px 0" }}>
        <div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              styles.textcenter
            )}
          >
            <span style={{ color: "#0e54a3" }}>Top </span>
            <span className={styles.colororange}> Tourist Attractions</span>
          </p>
          <div className={styles.center}>
            <p
              className={classNames(
                styles.SevicesText,
                styles.textcolor,
                commonstyles.fs16,
                styles.mxsm20
              )}
            >
              Pakistan is home to a wealth of beautiful tourist attractions that
              offer a blend of natural beauty, historical significance, and
              cultural richness. Here are some highlights. These attractions
              showcase the diverse beauty and cultural heritage of Pakistan,
              making it a captivating destination for travelers.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "50px", height: "350px" }}>
        <TopPlaces />
        <ParallaxCarousel />
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "-56px",
          // paddingBottom: "64px",
          margin: "96px 0",
        }}
      >
        <LogoSlider clientsContent={logos_data} />
      </div>
      <div>
        <div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              styles.textcenter
            )}
          >
            <span className={styles.colorBlue}>Top </span>
            <span className={styles.colororange}>Treatments</span>
          </p>
          <div className={styles.center}>
            <p
              className={classNames(
                styles.whychoose,
                commonstyles.col10,
                commonstyles.colsm12,
                styles.mxsm20
              )}
            >
              Our comprehensive treatment options cover a wide range of medical
              needs, from routine check-ups to complex surgeries. Your health
              and well-being are our top priorities, and we are committed to
              providing you with a seamless and comfortable medical journey.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <TopTreatment />
        </div>
      </div>
      <div className={styles.peopleContainer22}>
        <p
          className={classNames(
            commonstyles.fs32,
            commonstyles.semiBold,
            commonstyles.mb24
          )}
          style={{
            textAlign: "center",
            marginTop: "80px",
          }}
        >
          <span className={styles.colorBlue}>Why People </span>
          <span className={styles.colororange}>Choose Us</span>
        </p>
        <p className={classNames(styles.whychoose, styles.mt8)}>
          Your Gateway to Comprehensive Online Healthcare Tours. Experience
          Seamless Access to Medical Expertise, Wellness Retreats, and Cultural
          Explorations from Anywhere. Empowering Your Health and Journey, One
          Click Away.
        </p>
        <div className={classNames(styles.flxBetween, commonstyles.mt56)}>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              25000+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Patient’s Consultation
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              24/7
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Medical Support
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              70+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Accredited Hospitals
            </p>
          </div>
          <div className={styles.PeoplesCardDots}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              3500+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Happy Clients
            </p>
          </div>
          <div className={styles.PeoplesCard}>
            <p
              className={classNames(
                commonstyles.fs32,
                commonstyles.semiBold,
                styles.colororange
              )}
            >
              200+
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.medium,
                styles.mt8
              )}
            >
              Doctors on panel
            </p>
          </div>
        </div>
      </div>
      <div className={styles.peopleContainer} style={{ marginTop: "80px" }}>
        <div className={styles.Frequently}>
          <p className={classNames(commonstyles.fs32, commonstyles.semiBold)}>
            <span className={styles.colorBlue}>Frequently </span>
            <span className={styles.colororange}> Asked Question</span>
          </p>
        </div>
        <div className={styles.flxxcenter}>
          <p
            className={classNames(
              styles.tourparagraph,
              styles.mt24,
              commonstyles.col8,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
            style={{ textAlign: "center" }}
          >
            Unlocking Healthcare Excellence: MediTour's 5 Key Advantages.
            Seamlessly Connect with Top Medical Experts, Discover Wellness
            Retreats, and Explore Cultural Treasures. Elevate Your Health
            Journey with MediTour Today!
          </p>
        </div>
        <div className={classNames(styles.flxFAQ, commonstyles.mt56)}>
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12,
              styles.wmdd100
            )}
          >
            <img alt="" src={FAQ} className={styles.FAQIMG} />
          </div>{" "}
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12,
              styles.wmdd100
            )}
          >
            <FAQS />
            <button className={styles.About} onClick={HandleGoFAQS}>
              {" "}
              <p>See All..</p>
              <IoArrowForwardCircleSharp className={styles.Iconnns} />
            </button>
          </div>
        </div>
      </div>{" "}
      <div className={styles.bgcolor}>
        <ContactUs />
        <div>
          <div
            className={classNames(
              styles.flxBetween,
              styles.mt100,
              styles.textsmCenter
            )}
          >
            <div
              className={classNames(
                commonstyles.col5,
                commonstyles.colmd12,
                commonstyles.colsm12
              )}
            >
              <p className={classNames(styles.allViewtext)}>
                <span className={styles.colorBlue}>We Are on</span>
                <span className={classNames(styles.colororange)}>
                  {" "}
                  All View
                </span>
              </p>
              <p
                className={classNames(
                  styles.mt24,
                  styles.whychoose22,
                  styles.allViewtext
                )}
              >
                Find us on the Play Store and App Store for seamless access to
                our services. Download now to experience convenience at your
                fingertips
              </p>
              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.col10,
                  commonstyles.colsm12,
                  commonstyles.mt56
                )}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.meditourapp&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mediBox}
                >
                  <img alt="" src={medi1} className={styles.medi} />
                  <p className={commonstyles.fs16}>MediTour</p>
                </a>
                <a
                  href="https://apps.apple.com/us/app/meditour-global/id6738271103"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mediBox}
                >
                  <img alt="" src={medi2} className={styles.medi} />
                  <p className={commonstyles.fs16}>MediTour</p>
                </a>
              </div>
            </div>
            <div
              className={classNames(
                commonstyles.col5,
                commonstyles.colmd12,
                commonstyles.colsm12
              )}
            >
              <img alt="" src={Laptop} className={styles.Laptop} />
            </div>
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default HomePage;
