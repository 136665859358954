import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./payment.module.css";
import commonstyles from "shared/utils/common.module.css";
import TablePay from "shared/components/Tables/tablePay";
import { getPaymentComplete } from "shared/services";
import { useSelector } from "react-redux";
import RingLoader from "../RingLoader";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "../NewPagination/NewPagination";
import { useNavigate } from "react-router-dom";
import PhysiotheristsEmpty from "../PhsiotheristEmpty";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";

interface Props {
  type: string;
  id?: string;
}
const Vendor_Payments = (props: Props) => {
  const { type, id } = props;
  const { systemType } = useSelector((state: any) => state.root.common);

  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const totalItems = length;
  useEffect(() => {
    fetchPayments(1, "");
  }, []);
  console.log(id, ".......id");
  const fetchPayments = (pageno: number, keyword: any) => {
    setLoading(true);
    getPaymentComplete(type, "", id, pageno, keyword)
      .then((res: any) => {
        setPayments(res?.data?.payments);
        setLength(res?.data?.paymentsLength);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setSearch("");
    setCurrentPage(1);
    fetchPayments(1, "");
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;
    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      fetchPayments(currentPage + 1, search);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchPayments(currentPage - 1, search);
    }
  };

  const onPressItem = (item: any) => {
    navigate(`/${systemType}/paymentDetails`, {
      state: item,
    });
  };
  const handleSearch = () => {
    setCurrentPage(1);
    fetchPayments(1, search);
  };
  return (
    <div>
      <div
        className={classNames(commonstyles.flxBetween, commonstyles.flxWrap)}
        style={{ marginBottom: "24px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className={classNames(commonstyles.fs22, commonstyles.semiBold)}>
            Received Payments
          </p>
          {loading ? (
            <div className={style.outerRefresh} style={{ marginLeft: "16px" }}>
              <RingLoader color={"#0D47A1"} size={30} />
            </div>
          ) : (
            <div className={style.outerRefresh}>
              <TbRefresh
                className={style.RefreshIcon}
                onClick={handleRefresh}
              />
            </div>
          )}
          <SearchFilter
            vender={false}
            search={search}
            title={"Search"}
            setSearch={setSearch}
            handleSearch={handleSearch}
          />
        </div>
        <div className={classNames(commonstyles.flx)}>
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, length)}
            totalItems={totalItems}
          />
        </div>
      </div>

      {payments && payments.length > 0 ? (
        <TablePay data={payments} onPressItem={onPressItem} />
      ) : (
        <PhysiotheristsEmpty />
      )}
    </div>
  );
};

export default Vendor_Payments;
