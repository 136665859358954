import React, { useState } from "react";
import style from "../travelFamily.module.css";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import { useDispatch } from "react-redux";
import { insuranceTravelIndividualSchema } from "shared/utils";
import { Checkbox } from "@mui/material";
import { setInsuranceTravelFamily } from "shared/redux";
const Medical = ["10,000", "20,000", "30,000", "40,000", "50,000"];
const Covering = [
  "1 Day",
  "2 Days",
  "3 Days",
  "4 Days",
  "5 Days",
  "6 Days",
  "7 Days",
  "8 Days",
  "9 Days",
  "10 Days",
];
const PackageCategory = ["Silver", "Gold", "Platinum"];
interface Props {
  handleClickNext: any;
}

export default function FamilyBasicInfoCovering(props: Partial<Props>) {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const [isSpecificCountryChecked, setIsSpecificCountryChecked] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      packageName: "",
      packageLogo: "",
      medicalCover: "",
      coveringUpTo: "",
      packageDescription: "",
      CountryName: "",
      packageCategory: "",
    },
    validationSchema: Yup.object(insuranceTravelIndividualSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleCheckboxChange = (checked: boolean) => {
    setIsSpecificCountryChecked(checked);
    if (!checked) {
      formik.setFieldValue("CountryName", "package for world wide");
    } else {
      formik.setFieldValue("CountryName", formik.values.CountryName); // Set the current value of the CustomInput
    }
  };
  const handleCountryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSpecificCountryChecked) {
      formik.setFieldValue("CountryName", e.target.value); // Set the value of CustomInput only if Specific Country is checked
    }
  };

  const handleLogoUrl = (url: any) => {
    formik.setFieldValue("packageLogo", url);
  };
  const handleMedicalCover = (selectedOption: string) => {
    formik.setFieldValue("medicalCover", selectedOption);
  };
  const packageCategoryhandle = (selectedOption: string) => {
    formik.setFieldValue("packageCategory", selectedOption);
  };
  const handleCovering = (selectedOption: string) => {
    formik.setFieldValue("coveringUpTo", selectedOption);
  };

  const handleSubmit = () => {
    dispatch(setInsuranceTravelFamily({ ...formik.values }));
    handleClickNext();
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col6, commonStyles.mr24)}>
            <CustomInput
              placeholder="Package Name"
              id="packageName"
              name="packageName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageName}
            />
            {formik.touched.packageName && formik.errors.packageName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageName}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonStyles.col6)}>
            <ImgPicker placeholder="Package Logo" setData={handleLogoUrl} />
            {formik.touched.packageLogo && formik.errors.packageLogo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageLogo}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={handleMedicalCover}
              placeholder="Medical cover"
              options={Medical}
            />
            {formik.touched.medicalCover && formik.errors.medicalCover ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.medicalCover}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={handleCovering}
              placeholder="Covering up to"
              options={Covering}
            />
            {formik.touched.coveringUpTo && formik.errors.coveringUpTo ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.coveringUpTo}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomInput
              placeholder="Package Description"
              id="packageDescription"
              name="packageDescription"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packageDescription}
            />
            {formik.touched.packageDescription &&
            formik.errors.packageDescription ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageDescription}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col10,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col12)}>
            <CustomSelect
              onSelect={packageCategoryhandle}
              placeholder="Package Category"
              options={PackageCategory}
            />
            {formik.touched.packageCategory && formik.errors.packageCategory ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packageCategory}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <p className={style.bold}>Traveling</p>
        </div>
        <div className={commonstyle.flx}>
          <div>
            <Checkbox
              className={style.checkbox}
              checked={!isSpecificCountryChecked}
              onChange={(e) => handleCheckboxChange(!e.target.checked)}
            />
          </div>
          <p>Package for Worldwide(multi tour)</p>
        </div>
        <div className={commonstyle.flx}>
          <div>
            <Checkbox
              className={style.checkbox}
              checked={isSpecificCountryChecked}
              onChange={(e) => handleCheckboxChange(e.target.checked)}
            />
          </div>
          <p>Specific Country</p>
        </div>
        {isSpecificCountryChecked && (
          <div className={classNames(commonStyles.col10)}>
            <CustomInput
              placeholder="Country Name"
              id="CountryName"
              name="CountryName"
              type="text"
              onChange={(e: any) => {
                formik.handleChange(e);
                handleCountryNameChange(e);
              }}
              value={formik.values.CountryName}
            />
            {formik.touched.CountryName && formik.errors.CountryName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.CountryName}
              </div>
            ) : null}
          </div>
        )}

        {/* <div
        className={classNames(
          commonStyles.flx,
          commonStyles.col10,
          commonStyles.mb32
        )}
      >
        <div className={classNames(commonStyles.col12)}>
          <CustomSelect
            onSelect={handleSelect}
            options={Covid}
            placeholder="Package Name"
          />
        </div>
      </div> */}
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
