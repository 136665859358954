import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const user_login = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGIN, params);
};

export const getAll_Doctors = (data: any) => {
  let queryParams = `${ENDPOINTS.USER_DOCTOR}?doctorType=${data?.doctorType}`;

  if (data?.lat) queryParams += `&lat=${data.lat}`;
  if (data?.long) queryParams += `&long=${data.long}`;
  if (data?.search) queryParams += `&search=${data.search}`;
  if (data?.city) queryParams += `&city=${data.city}`;
  if (data?.speciality) queryParams += `&speciality=${data.speciality}`;
  if (data?.filter) queryParams += `&filter=${data.filter}`;
  if (data?.page !== undefined) queryParams += `&page=${data.page}`;

  return HTTP_CLIENT.get(queryParams);
};

export const getSignle_Doctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_DOCTOR}?doctorId=` +
      data.doctorId +
      "&type=" +
      data.type +
      "&patientId=" +
      data.patientId
  );
};

export const getAvailability_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_AVAILABILITY_DOCTOR}?doctorId=` +
      data.doctorId +
      "&type=" +
      data.type +
      "&hospitalId=" +
      data.hospitalId
  );
};
export const getUpcoming_Doctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.UPCOMING_APPOINTMENT}?page=` + data?.page
  );
};

export const GetAll_Records = (data?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_RECORDS}?page=` + data?.page);
};

export const getMedicineNear = (data?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SEARCHDOCTOR_KEYWORD}?keyword=` + data?.keyword
  );
};

export const getAll_RentACar = (data: any, pagenoo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_RENT_A_CAR}?page=${pagenoo}` +
      "&lat=" +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&name=" +
      data.name +
      "&search=" +
      data?.search +
      "&filter=" +
      data?.filter
  );
};
export const getAll_CarsDetails = (data: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_CARS_DETAILS}?id=` + data.id);
};
export const getSingle_CarDetail = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_SINGLE_CARS_DETAILS}?vehicleId=` + data.vehicleId
  );
};
export const GetAllRentACarReview_Rating = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_RENT_A_CAR_RATINGS_REVIEWS}?vendorId=` +
      data?.vendorId,
    data
  );
};
export const flightsRequest = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_ALL_FLIGHTS_REQUEST, data);
};
export const getUpcomingTours = (page: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.ALL_UPCOMING_TOURS}?page=` + page);
};
export const userLogoutPerson = () => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGOUT);
};
export const getDonation_Companies = () => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_COMPANIES}`);
};
export const getDonation_Package = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PACKAGE_DETAILS}?packageId=` + data?.packageId
  );
};
export const getPackages = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PACKAGES_DONATION}?criteriaName=` +
      data?.criteriaName +
      "&params" +
      data?.page
  );
};
export const getRecentDonor = (data: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_RECENT_DONORS}?page=` + data?.page);
};
export const getPeople_Donation = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_NEED_PEOPLE);
};
export const get_Hospital_Price = (id: any, doc_Id?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_HOSPITAL_PRICE}?hospitalId=${id}` + "&doctorId=" + doc_Id
  );
};

export const getAll_Hospitals = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_HOSPITAL}?lat=` +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&search=" +
      data.search
  );
};

export const getSingle_UserHospital = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_HOSPITAL}?hospitalId=` + data.hospitalId
  );
};

export const getHospital_Departments = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_DEPARTMENTS}?hospitalId=` + data.hospitalId
  );
};

export const getHospital_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_HOSPITAL_DOCTORS}?hospitalId=` + data.hospitalId
  );
};

export const getDepartment_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_DEPARTMENT_DOCTORS}?departmentId=` + data.departmentId
  );
};
export const add_Appointment_Doctors = (data: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_BOOK_APPOINTMENT}?doctorId=${id}`,
    data
  );
};

export const remaining_Appointment_Doctors = (body: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.REMAIN_DOCTOR_PAYMENT, body);
};

export const Preception_Details = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PATIENT_DATA}?appointmentId=` + data?.appointmentId
  );
};

export const getReferralDoctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_REFERRAL}?appointmentId=` + data?.appointmentId
  );
};

export const postDonationAmount = (body: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_DONATION}?packageId=${params.packageId}&companyId=${params.companyId}`,
    body
  );
};
export const addPharmacyOrder = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_MEDICINE_ORDER, data);
};
export const bookingTours = (params?: any, body?: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.ADD_TOUR_BOOKING}?tourId=` + params?.tourId,
    body
  );
};

export const acceptFlightBid = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.ACCEPT_BID_REQUEST_FLIGHT}?bidRequestId=` + data?.bidRequestId,
    params
  );
};
export const flightDecline = (data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DECLINE_FLIGHT_REQUEST}?requestId=` + data?.requestId
  );
};
export const saveUploadResulsUser = (id: any, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.SAVE_RESULTS}?appointmentId=${id}`,
    params
  );
};

export const confirmInsurance = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CONFIRM_INSURANCE, data);
};
export const acceptTestMedi = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ACCEPTMEDICINELAB, data);
};
//..................USer OPD................./
export const opdDoctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.OPD_GET_DOCTOR}?isMeditour=` +
      data.isMeditour +
      "&searchValue=" +
      data.searchValue
  );
};
export const fetchBook = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_BOOKING_COMMON}?requestType=` +
      data?.requestType +
      "&page=" +
      data?.page
  );
};
export const fetchSingleBooking = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_SINGLE_DETAILS}?requestType=` +
      data?.requestType +
      "&bookingId=" +
      data?.bookingId
  );
};
//...............Treatments.............../
export const userTreatments = () => {
  return HTTP_CLIENT.get(ENDPOINTS.TREATMENTS_BY_CATEGORY);
};
export const postConsultancyForm = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_CONSULTACY_FORM, body);
};
export const topViewTreatments = (data?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TOP_TREATMENTS}?name=` + data?.name + "&page=" + data?.page
  );
};

export const getAllDoctor_SubCategory = (params: any, data: any) => {
  const treatmentId = data?.treatmentId;
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_DOCS}?page=${params?.page}&treatmentId=${treatmentId}`
  );
};

export const getSub = (data?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_SUB_CATEGORY}?categoryName=` + data?.categoryName
  );
};

// .........................User-Laboratory Flow.........................../
export const getUser_Laboratory = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_LABORATORY}?lat=` +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&search=" +
      data.search +
      "&filter=" +
      data?.filter
  );
};
export const add_File = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_ADD_FILE, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const remainingPayment_Hotel = (body?: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.REMAIN_PAYMENT_HOTEL, body);
};
export const INSURANCE_SINGLE_Delete = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.INSURANCE_DEL_INSURANCE}?insuranceHealthId=${id}`
  );
};
export const insruanceFamilyDeletefun = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.INSURANCE_DEL_INSURANCE_FAMILY_TRAVELERW}?insuranceTravelId=${id}`
  );
};
export const INSURANCE_FAMILY_Delete = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.INSURANCE_DEL_INSURANCE_FAMILY}?insuranceHealthId=${id}`
  );
};
export const INSURANCE_INDIVIDUAL_DELETE = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.INSURANCE_DEL_INSURANCE_INDIVIDUL_TRAVELER}?insuranceTravelId=${id}`
  );
};
export const INSURANCE_FAMILY_TRAVELER_DELETE = (id: string) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.INSURANCE_DEL_INSURANCE_FAMILY_TRAVELER}?insuranceHealthId=${id}`
  );
};
export const remaining_TourPayment = (params?: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.REMAIN_TOUR_PAYMENT}?bookingId=` +
      params?.bookingId +
      "&paidByUserAmount=" +
      params?.paidByUserAmount +
      "&paymentId=" +
      params?.paymentId +
      "&gatewayName=" +
      params?.gatewayName +
      "&processingFee=" +
      params?.processingFee
  );
};
export const delFlight = (data: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.ADD_DELETER_FLIGHT}?flightRequestsId=` + data?.flightRequestsId
  );
};
export const remainingPayment_RentCr = (params: any, body?: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.REMAIN_PAYMENT_RENTCAR}?acceptedRequestId=` +
      params?.bookingId,
    body
  );
};
export const getAllBookings = (type: any, page: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_BOOKINGS}?type=` + type + "&page=" + page
  );
};
export const getSingle_UserLaboratory = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_LABORATORY}?labId=` +
      data.labId +
      "&lat=" +
      data.lat +
      "&long=" +
      data.long
  );
};

export const getAll_AdminTestCategories = () => {
  return HTTP_CLIENT.get(ENDPOINTS.ADMIN_TEST_CATEGORIES);
};

export const getAllOrders_History = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_ORDER_HISTORY}?page=` +
      data.page +
      "&type=" +
      data?.type
  );
};

export const getAll_Tests = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_TESTS}?testName=` + data.testName + "&labId=" + data?.labId
  );
};

export const confirm_Booking = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CONFIRM_BOOKING, data);
};
export const addAllSearchHotel = (parmas: any, data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.SEARCH_HOTEL_FILTER}?page=` + parmas?.page,
    data
  );
};
export const addBookingRoom = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.BOOKING_ROOM_DETAILS, data);
};

export const DetailsInfoHotel = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DETAILS_INFO_HOTEL}?id=` +
      data.id +
      "&serviceType=" +
      data.serviceType
  );
};

export const getAllFlights = (data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.GET_ALL_FLIGHTS_REQUEST}?page=` + data?.page
  );
};

export const allBidRequest = (params: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GER_ALL_BID_REQUEST}?requestId=` + params?.requestId
  );
};

export const postInsuranceFlight = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_TRAVEL_INSURANCE}?page=` + data?.page,
    params
  );
};
export const addParamedic = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SUBMIT_PARAMEDIC, params);
};
export const postInsuranceFamily = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.INSURANCE_FAMILY}?page=` + data.page,
    params
  );
};
export const getInsuranceDetails = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_INSURANCE_DETAILS}?insuranceId=` +
      data.insuranceId +
      "&type=" +
      data.type
  );
};
// .........................User-Ambulance Flow.........................../

export const Ambulance_Flow = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_AMBULANCE_PUSH, data);
};
export const postDetails = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_RENTACAR_DETAIL, data);
};

export const getUserAmbulanceReq = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_USER_AMBULANCE_REQUEST}?page=` + data?.page
  );
};

export const bidReqGet = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.FETCH_BID_REQUEST}?requestId=` + data?.requestId
  );
};

export const deleteRequest = (data: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DELETE_USER_REQUEST}?requestId=` + data?.requestId
  );
};

export const acceptAmbulanceReq = (id: any, body?: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.ACCEPT_BID_REQUEST}?bidRequestId=` + id,
    body
  );
};
export const declineAmbulance = (data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DECLINE_REQUEST}?requestId=` + data?.requestId
  );
};

export const logoutAll = () => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGOUT);
};

export const creatPaymentIntent = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CREATE_PAYMENT_INTENT, params);
};

export const AddRemovedFev = (data: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.FVT_REMOVED_ADD, data);
};

// ................//User Profile..........
export const updateProfile = (body: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.UPDATE_USER_PROFILE, body);
};
export const blockUser = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.BLOCK_ALL, data);
};

//............User Favourites............
export const fvt_All = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.FVRT_MUTUAL_API}?favType=` +
      data?.favType +
      "&page=" +
      data.page
  );
};

//..............Blinq..........
export const createBlinq = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CREATE_BLINQ, body);
};

//............USer Signup.........
export const user_SendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_SEND_CODE_TO_EMAIL, params);
};

export const confirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.confirmEmail, params);
};

export const userSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SIGNUP, params);
};

export const resetPassword = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RESET_LINK, params);
};

export const updatePassword = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.updatePassword, params);
};
export const createSubBranch = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.REGISTER_HOPITAL_SUBBRANCH, params);
};

export const getVersion = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_VERSION);
};

export const updateVersion = (body: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.UPDATE_VERSION, body);
};
export const getCountDasheboard = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DASHEBOARD_COUNT);
};
export const getHospitalAppointment = (
  pagenoo?: any,
  appointmentDateAndTime?: any,
  nameOrEmail?: any
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_HOSPITAL_APPOINTMENTS}?page=${pagenoo}&appointmentDateAndTime=${appointmentDateAndTime}&query=${nameOrEmail}`
  );
};
export const getAllPatientHospital = (status?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_HOSPITAL_APPOINTMENTS}?status=${status}`
  );
};
export const getHospitalBranches = (
  hospitalId?: any,
  page?: any,
  query?: any
) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_HOSPITAL_BRANCHES}?hospitalId=${hospitalId}&page=${page}&query=${query}`
  );
};
export const getBookingAppointment = (
  pagenoo?: any,
  appointmentDateAndTime?: any,
  name?: any,
  query?: any
) => {
  console.log(
    `${ENDPOINTS.BOOKING_APPOINTMENT}?page=${pagenoo}&appointmentDateAndTime=${appointmentDateAndTime}&name=${name}&query=${query}`,
    ".    `${ENDPOINTS.BOOKING_APPOINTMENT}?page=${pagenoo}&appointmentDateAndTime=${appointmentDateAndTime}&name=${name}&query=${query}`"
  );
  return HTTP_CLIENT.get(
    `${ENDPOINTS.BOOKING_APPOINTMENT}?page=${pagenoo}&appointmentDateAndTime=${appointmentDateAndTime}&name=${name}&query=${query}`
  );
};
export const getAllDoctorHospital = (query?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_DOCTOR}?query=${query}`);
};
export const codeToEmail = (params?: any, endpoint?: any) => {
  return HTTP_CLIENT.post(endpoint, params);
};
export const codeTomail = (params?: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SEND_CODE_TOEMAIL, params);
};
export const verifyCode = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SEND_EMAIL_TO, params);
};
export const getPatientAll = (status?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_PATIENT}?status=${status}`);
};
export const avaialBility = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOSPITAL_AVAILABILITY, params);
};
export const availPrice = (params: any) => {
  return HTTP_CLIENT.patch(ENDPOINTS.AVAILABILITY_PRICE, params);
};
export const companyLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.COMAPNY_LOGIN, params);
};
export const companyDoctor = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_COMPANYDOC);
};
export const companyCodeTo = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SEND_COMPANY_CODE, params);
};
export const verifyCompanyCode = (params: any, endpoint: any) => {
  return HTTP_CLIENT.post(endpoint, params);
};
export const companyPatient = (status?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_PATIENT_COMPANY}?status=${status}`);
};
export const logoutCompany = () => {
  return HTTP_CLIENT.post(ENDPOINTS.LOGOUT_COMPANY);
};
export const hospitalLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.LOGOUT_HOSPITAL);
};
export const genericLogout = (endpoint: any) => {
  return HTTP_CLIENT.post(endpoint);
};
export const confirmDateAndTime = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.DATEANDR_TIME, params);
};
export const getTreatment = () => {
  return HTTP_CLIENT.get(`${ENDPOINTS.TREATMENT_CATEGORY}`);
};
export const addTreatment = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_TREATMENT, body);
};
export const getHospital_Treatments = (id?: any) => {
  let check = id && `${ENDPOINTS.GET_ALL_HOSPITAL_TREATMENTS}?hospitalId=${id}`;
  return HTTP_CLIENT.get(check || ENDPOINTS.GET_ALL_HOSPITAL_TREATMENTS);
};

export const getTreatmentDetail = (treatmentId: any, query?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.TREATMENT_DETAIL}?treatmentId=${treatmentId}&query=${query}`
  );
};
export const getManagerLab = (page: any, type?: any, search?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.MANAGE_LAB}?page=${page}&search=${search}&type=${type}`
  );
};
export const acceptInvitationHospital = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ACCEPET_INVITATION, params);
};
export const sendCodeCompany = (params: any, endpoint: any) => {
  return HTTP_CLIENT.post(endpoint, params);
};
export const getDepartment = (page?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_DEPHOSPITAL}?page=${page}`);
};
export const departmentName = () => {
  return HTTP_CLIENT.get(ENDPOINTS.DEPARTMENT_NAME);
};
export const getDepartmentDirector = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_DIRECTOR);
};
export const depatmentStatus = (params: any) => {
  return HTTP_CLIENT.patch(
    `${ENDPOINTS.IS_ACTIVE}?id=${params?.id}&type=${params?.type}&isActive=${params?.isActive}`
  );
};
export const editDepartmentHospital = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.EDIT_DEPARTMENT_HOSPITAL, params);
};

export const addDepartmentHospital = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_DEPARTMENT_HOSPITAL, params);
};
export const fetchDepartmentHospital = (page?: any, query?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.FETCH_DEPART}?page=${page}&query=${query}`
  );
};
export const getDepartmentDoctors = (id?: any, page?: any, query?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOSPITAL_DEPARTMENT_DOC}?id=${id}&page=${page}&query=${query}`
  );
};
export const getHospital = (status: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_HOSPITAL_APPOINTMENTS}?status=${status}`
  );
};

export const createInvoice = (body: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CREATE_INVOICE, body);
};

export const getDetails = (patientId: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_PATIENT_DETAIL}?patientId=${patientId}`
  );
};

export const patientListdata = (patientId: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GETCOMPANY_PATIENTS}?patientId=${patientId}`
  );
};
export const travelPanelLogin = (params?: any) => {
  return HTTP_CLIENT.post(ENDPOINTS?.LOGIN_PANEL, params);
};
export const getHotelData = (page: any, hotelId: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GETDASHEBOARD_API}?page=${page}&hotelId=${hotelId}`
  );
};
export const getAgencyBooking = (page: any, agencyId: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GETDASHEBOARD_API}?page=${page}&agencyId=${agencyId}`
  );
};
export const getAgencyList = (keyword?: any, page?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_AGENCY_LIST}?page=${page}&keyword=${keyword}`
  );
};
export const travelComayLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.TRAVEL_COMPANY_LOGOUT);
};
export const agencyDetails = (agencyId: any, page: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.AGENCY_DETAIL}?agencyId=${agencyId}&page=${page}`
  );
};
export const hotelList = (keyword?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.LIST_HOTEL}?keyword=${keyword}`);
};

export const add_hotel_branch = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_HOTEL_BRANCH, params);
};

export const get_hotel_branches = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_HOTEL_BRANCHES);
};

export const acceptGen = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ACCEPT_GEN, params);
};
export const getDetailHotel = (hotelId: any, page: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DETAIL_HOTEL}?hotelId=${hotelId}&page=${page}`
  );
};
export const gethotelCompany = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_COMPANYIES);
};
export const getNewList = (status?: any, type?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_NEW_REQUEST}?status=${status}&type=${type}`
  );
};
export const acceptRequestCompany = (requestId?: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.ACCEPT_REQUEST}?requestId=${requestId}`);
};
export const rejectCaseCompany = (requestId?: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.REJECT_CASE}?requestId=${requestId}`);
};
export const reSecdualIng = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.RESECDULA_APPOINTMENT, params);
};
export const deleteAppointment = (id?: any, type?: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DEL_APPOINTMENT}?id=${id}&type=${type}`
  );
};
export const settignUPdate = (params?: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.SETTING_URL, params);
};
export const fetchLimit = (type?: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.LIMIT_API}?type=${type}`);
};
export const fetchtreatment = () => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_TREATMENT_DOC}`);
};
export const addTreatmentdoctor = (params?: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_TREATMENT_DOC, params);
};
export const fetchingHospital = () => {
  return HTTP_CLIENT.get(ENDPOINTS.FETCH_ALL_HOSPITAL);
};
export const getonlyId = (hospitalId: any) => {
  console.log(
    `${ENDPOINTS.GET_ONLYHOSPITAL_TREATMENT}?hospitalId=${hospitalId}`,
    ".......`${ENDPOINTS.GET_ONLYHOSPITAL_TREATMENT}?hospitalId=${hospitalId}`"
  );
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ONLYHOSPITAL_TREATMENT}?hospitalId=${hospitalId}`
  );
};
export const delTre = (treatmentId: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DELETE_TREATMENT}?treatmentId=${treatmentId}`
  );
};
export const genList = (status: any) => {
  console.log(
    `${ENDPOINTS.GET_GENERIC_PATIENT}?status=${status}`,
    "....`${ENDPOINTS.GET_GENERIC_PATIENT}?status=${status}`"
  );
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_GENERIC_PATIENT}?status=${status}`);
};
export const getDetailsPatientId = (
  patientId: any,
  query?: any,
  page?: any
) => {
  console.log(
    `${ENDPOINTS?.GET_PATIENT_DETAIL}?patientId=${patientId}&query=${query}&page=${page}`,
    "...`${ENDPOINTS?.GET_PATIENT_DETAIL}?patientId=${patientId}&query=${query}&page=${page}`"
  );
  return HTTP_CLIENT.get(
    `${ENDPOINTS?.GET_PATIENT_DETAIL}?patientId=${patientId}&query=${query}&page=${page}`
  );
};
export const addForm = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.QUESTION, params);
};
export const getProperty = (page: any, search: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOTEL_PROPERTY}?page=${page}&search=${search}`
  );
};
export const addHotel = (id: any, params: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.ADD_PROPERTY}?hotelId=${id}`, params);
};
export const getHotelProperties = (id: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_HOTEL_PROPERTIES}?branchId=${id}`);
};
export const getHotelCount = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOTEL_DASHEBOARD_COUNT);
};
export const getLatestBookings = (page: any, search: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.LATEST_BOOKINGS}?page=${page}&search=${search}`
  );
};
export const reserve = (page: any, search: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.RESERVE_BOOKING}?page=${page}&search=${search}`
  );
};
export const chnageAvailable = (propertyId: any, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.CHANGE_STATUS}?propertyId=${propertyId}`,
    params
  );
};
export const selection = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GEN_ALL_COUNTRY);
};
export const rates = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GEN_GET_RATES);
};
export const striptPaid = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.STRIPE_PAID, params);
};
export const updateTestCategory = (testId: any, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.TEST_UPDATE}?testId=${testId}`, params);
};
export const notify = (id: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.NOTIFICATUONS_ALL}?id=${id}`);
};
export const medicineId = (name?: any, page?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GENRIC_MEDICINE}?name=${name}&page=${page}`
  );
};
export const googleLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.GOOGLE_LOGIN, params);
};
export const passwordReset = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.SET_PASSWORD, params);
};

export const upload_multi_files = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.UPLOAD_MULTI_FILES, params);
};
