export const formData_Values = (user: any, dayjs: any) => {
  return {
    name: user?.name || "",
    cnicOrPassportNo: user?.cnicOrPassportNo || "",
    companyLicenseNo: user?.companyLicenseNo || "",
    location: {
      lng: user?.location?.lng || "",
      lat: user?.location?.lat || "",
      address: user?.location?.address || "",
      city: user?.location?.city || "",
    },
    licenseExpiry: user?.licenseExpiry ? dayjs(user.licenseExpiry) : "",
    ownerFirstName: user?.ownerFirstName || "",
    ownerLastName: user?.ownerLastName || "",
    emergencyNo: user?.emergencyNo || "",
    cnicOrPassportExpiry: user?.cnicOrPassportExpiry
      ? dayjs(user.cnicOrPassportExpiry)
      : "",
    ntn: user?.ntn || "",
    taxFileImage: user?.taxFileImage || "",
    cnicImage: user?.cnicImage || "",
    logo: user?.logo || "",
    comapnyLicenseImage: user?.comapnyLicenseImage || "",
    accountTitle: user?.accountTitle || "",
    accountNumber: user?.accountNumber || "",
    bankName: user?.bankName || "",
    youtube: user?.youtube || "",
    linkedIn: user?.linkedIn || "",
    instagram: user?.instagram || "",
    facebook: user?.facebook || "",
    password: user?.password || "",
  };
};

export const editableSections_Values = {
  ProfileInfo: false,
  OpenTime: false,
  BankDetails: false,
  SocialMedia: false,
  Security: false,
  AboutUS: false,
};
export const allowedValues = [
  "name",
  "cnicOrPassportNo",
  "companyLicenseNo",
  "licenseExpiry",
  "location",
  "lng",
  "ntn",
  "lat",
  "address",
  "salesTaxNo",
  "logo",
  "city",
  "taxFileImage",
  "ownerFirstName",
  "ownerLastName",
  "emergencyNo",
  "accountHolderName",
  "accountTitle",
  "accountNumber",
  "comapnyLicenseImage",
  "website",
  "bankName",
  "instagram",
  "twitter",
  "youtube",
  "linkedIn",
  "facebook",
  "cnicImage",
  "cnicOrPassportExpiry",
  "doctorImage",
];
