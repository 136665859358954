import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import LoginModel from "shared/components/HospitalBranch";
import HospitalEditDr from "shared/components/HospitalBranch/EditDoctor";
import { fetchLimit, getAllDoctorHospital } from "shared/services";
import { useSelector } from "react-redux";
import { notifyError } from "shared/components/A_New_Components/ToastNotification";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import { BranchCard, RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import LimitModel from "./LimitModel";

const ManageDoctor = () => {
  const [open, setOpen] = useState(false);
  const [editmodel, setEdirmodel] = useState(false);
  const [item, setItem] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const { user } = useSelector((state: any) => state?.root?.common);
  const [limit, setLimit] = useState<any>();
  const [showModel, setShowModel] = useState(false);
  const { systemType } = useSelector((state: any) => state.root.common);
  const handleClick = () => {
    if (user?.paidActivation === false) {
      notifyError("Please pay the activation fee to activate your account!");
      return;
    }
    setOpen((prevOpen) => !prevOpen);
    // if (limit?.allowed === true) {
    //   setOpen((prevOpen) => !prevOpen);
    // } else {
    // setShowModel(true);
    // }
  };
  const handleClickedit = (i: any) => {
    setEdirmodel((prevOpen) => !prevOpen);
    setItem(i);
  };
  useEffect(() => {
    getDoctorList("");
    // limitApi();
  }, [editmodel, open]);
  const getDoctorList = (keyword: any) => {
    setLoading(true);
    getAllDoctorHospital(keyword)
      .then((res: any) => {
        if (systemType === "company") {
          setData(res?.data?.companyDocs);
        } else {
          setData(res?.data?.doctors);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const limitApi = () => {
    fetchLimit("doctor")
      .then((res: any) => {
        setLimit(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "....error");
      })
      .finally(() => {});
  };
  const handleRefresh = () => {
    setSearch("");
    getDoctorList("");
  };
  const handleSearch = () => {
    getDoctorList(search);
  };
  return (
    <>
      <div className={style.maincontainer}>
        <div className={style.innerContent}>
          <div className={style.headerContainer}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <p className={classNames(style.heading)}>Doctor List</p>
              {loading ? (
                <div style={{ marginLeft: "32px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <TbRefresh className={style.refresh} onClick={handleRefresh} />
              )}
              <SearchFilter
                vender={false}
                search={search}
                title={"Search"}
                setSearch={setSearch}
                handleSearch={handleSearch}
              />
            </div>
            <button className={style.addbtn} onClick={handleClick}>
              + Add
            </button>
          </div>
          {data?.length > 0 ? (
            data.map((item: any) => {
              return (
                <BranchCard
                  data={item}
                  onclickDetail={() => handleClickedit(item)}
                />
              );
            })
          ) : (
            <PhysiotheristsEmpty />
          )}

          <LoginModel
            setOpen={setOpen}
            showModal={open}
            hitApi={getDoctorList}
            type={"doctor"}
          />

          {editmodel && (
            <div style={{ marginTop: "20px" }}>
              <HospitalEditDr
                item={item}
                setEdirmodel={setEdirmodel}
                hitApi={getDoctorList}
              />
            </div>
          )}
        </div>
      </div>

      {showModel && (
        <LimitModel showModel={showModel} setShowModel={setShowModel} />
      )}
    </>
  );
};

export default ManageDoctor;
