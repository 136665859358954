import React, { useEffect } from "react";
import InsuranceForm from "shared/components/Insuranceuser/Insuranceform";

const InsuranceTravel = () => {
  const handleSelectPlan = (selectedOption: string) => {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const insuranceOptions = ["Single Trip", "Multiple Trips"];

  return (
    <div>
      <InsuranceForm
        insuranceOptions={insuranceOptions}
        onSelectPlan={handleSelectPlan}
        title="Travel Insurance Plan"
        subtitle="Please Select Best Plan"
        formtitle="Travel"
        id={1}
        formType="travel"
      />
    </div>
  );
};

export default InsuranceTravel;
