import React, { useState } from "react";
import styles from "./input.module.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import classNames from "classnames";

type TextInputProps = {
  label?: string;
  password?: boolean;
  error?: string;
  togglePassword?: any;
  showPassword?: any;
  formik?: any;
  type?: any;
  value?: any;
  id?: any;
  height?: any;
  borderRadius?: any;
  border?: any;
  borderColor?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputField: React.FC<TextInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  password,
  togglePassword,
  showPassword,
  formik,
  error,
  id,
  height,
  borderRadius,
  borderColor,

  ...rest
}) => {
  let touched = formik?.touched;
  let errorToShow = formik ? formik?.errors : error;
  return (
    <>
      <div
        className={styles.container}
        style={{
          borderRadius: borderRadius || "4px",
        }}
      >
        {label && <label className={styles.label}>{label}</label>}
        <input
          type={type ? type : "text"}
          className={password ? styles.password_field : styles.field}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...rest}
          style={{
            height: height || "6vh",
            minHeight: "48px",
            borderRadius: borderRadius || "4px",
          }}
        />
        {password && (
          <div className={styles.showIcon} onClick={togglePassword}>
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </div>
        )}
      </div>
      {formik ? (
        touched[id] &&
        errorToShow[id] && (
          <div className={classNames(styles.errorMessage)}>
            {errorToShow[id]}
          </div>
        )
      ) : (
        <div className={styles.errorMessage}>{errorToShow}</div>
      )}
      {/* <span className={styles.errorMessage}>This is error</span> */}
    </>
  );
};

export default InputField;
