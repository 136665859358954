import React, { useState } from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./TravelStyle.module.css";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { allBidRequest, delFlight } from "shared/services";
import { DeleteModal, RingLoader } from "shared/components";
import dayjs from "dayjs";

const TravelRequest = ({
  data,
  flightsRequest,
}: {
  data?: any;
  flightsRequest: any;
}) => {
  const [showBids, setShowBids] = useState(false);
  const [selectFlightBid, setSelectedAmbulanceIndex] = useState<any>(null);
  const [bidData, setBidData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const handleBidsClick = () => {
    setShowBids(true);
  };
  const navigate = useNavigate();
  const handledetail = (travel: any, totalTravelers: any) => {
    navigate("/services/myRequest/TravellDetail", {
      state: {
        data: travel,
        totalTravelers: totalTravelers,
      },
    });
  };

  const getAllBid = async (id: any, index: any) => {
    await setBidData([]);
    setSelectedAmbulanceIndex(index);
    setLoading(true);
    const params = {
      requestId: id,
    };
    allBidRequest(params)
      .then((res: any) => {
        setBidData(res?.data?.bidRequests);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const openModal = (requestId: any) => {
    setSelectedRequestId(requestId);
    setModalVisible(true);
  };
  const onCanelModal = () => {
    setModalVisible(false);
  };
  const deleteFlight = () => {
    setLoading(true);
    let params = {
      flightRequestsId: selectedRequestId,
    };
    delFlight(params)
      .then(() => {
        //
        // setFlightData(
        //   flightData.filter((item: any) => item._id !== selectedRequestId),
        // );
        setModalVisible(false);
        flightsRequest();
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames()}>
      {/* <div className={classNames(commonstyle.container)}> */}
      <div className={classNames(style.parentcont, commonstyle.mb32)}>
        {data?.map((flight: any, index: any) => {
          let totalTravelers =
            flight?.children + flight?.infant + flight?.adult;

          return (
            <>
              <div
                className={classNames(style.mainconatiner)}
                style={{
                  backgroundColor:
                    selectFlightBid === index ? "#FDCB2E" : "transparent",
                }}
              >
                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween
                  )}
                  style={{
                    padding: "10px",
                  }}
                >
                  <div>
                    <p className={classNames(style.pickdrop)}>Flight Type</p>
                    <p className={classNames(style.pickupdetail)}>
                      {flight?.requestType}
                    </p>
                  </div>

                  <div
                    className={classNames(style.icon)}
                    onClick={() => openModal(flight._id)}
                  >
                    <MdDeleteOutline />
                  </div>
                </div>
                {flight?.flights?.map((item: any) => {
                  return (
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween,
                        commonstyle.flxWrap
                      )}
                      style={{
                        padding: "10px",
                      }}
                    >
                      <div>
                        <p className={classNames(style.pickdrop)}>From</p>
                        <p className={classNames(style.pickupdetail)}>
                          {item?.from}
                        </p>
                      </div>
                      <div>
                        <p className={classNames(style.pickdrop)}>To</p>
                        <p className={classNames(style.pickupdetail)}>
                          {item?.to}
                        </p>
                      </div>
                      <div>
                        <p className={classNames(style.pickdrop)}>Departure</p>
                        <p className={classNames(style.pickupdetail)}>
                          {dayjs(item?.departure).format("MM-DD-YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
                <div
                  className={classNames(commonstyle.flx)}
                  style={{
                    padding: "10px",
                  }}
                >
                  <div
                    className={classNames(
                      commonstyle.col4,
                      commonstyle.colsm12
                    )}
                  >
                    <p className={classNames(style.pickdrop)}>Class</p>
                    <p className={classNames(style.pickupdetail)}>
                      {flight?.flightClass}
                    </p>
                  </div>

                  <div
                    className={classNames(
                      commonstyle.col4,
                      commonstyle.colsm12
                    )}
                  >
                    <p className={classNames(style.pickdrop)}>Traveler</p>
                    <p className={classNames(style.pickupdetail)}>
                      {totalTravelers} Traveler
                    </p>
                  </div>
                </div>

                {/* Add Return Flight Information Below */}
                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween,
                    style.returnFlightContainer
                  )}
                  style={{ marginTop: "15px" }}
                >
                  <div style={{ padding: "10px" }}>
                    {flight?.requestType === "round" && (
                      <>
                        <p className={classNames(style.pickdrop)}>Return </p>
                        <p className={classNames(style.pickupdetail)}>
                          {dayjs(flight?.returnFlight).format("DD-MM-YYYY")}
                        </p>
                      </>
                    )}
                  </div>
                </div>

                {!showBids && (
                  <div className={classNames(style.bidcontainer)}>
                    <button
                      className={classNames(style.bidsBtn)}
                      onClick={() => getAllBid(flight?._id, index)}
                    >
                      {loading && selectFlightBid === index ? (
                        <RingLoader color={"#fff"} size={30} />
                      ) : (
                        " Bids"
                      )}
                    </button>
                  </div>
                )}
                {selectFlightBid === index && (
                  <div>
                    {bidData?.map((travel: any, index: any) => (
                      <div key={index}>
                        <div className={classNames(style.detailconatiner)}>
                          <div>
                            {/* Header: Travel Image, Name, and Price */}
                            <div
                              className={classNames(
                                commonstyle.flx,
                                commonstyle.flxBetween
                              )}
                            >
                              <div className={classNames(commonstyle.flx)}>
                                {/* Travel Image */}
                                <div className={classNames(style.imgcontainer)}>
                                  <img
                                    src={travel?.agencyId?.logo}
                                    alt={travel?.agencyId?.name}
                                    className={style.travelimg}
                                  />
                                </div>
                                {/* Travel Name */}
                                <div>
                                  <p
                                    className={classNames(
                                      style.pickupdetailLogo
                                    )}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {travel?.agencyId?.name}
                                  </p>
                                </div>
                              </div>
                              {/* Travel Price */}
                              <div>
                                <p
                                  className={classNames(
                                    style.pickupdetailPrice
                                  )}
                                >
                                  {travel?.ticketPrice} PKR
                                </p>
                              </div>
                            </div>

                            {/* Travel Information Section */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "30vh",
                                maxHeight: "200vh",
                              }}
                            >
                              {/* Outbound Flights */}
                              {travel?.flightDetails?.map(
                                (bids: any, index: any) => (
                                  <div
                                    key={`outbound-${index}`}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      marginBottom: "20px",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className={classNames(commonstyle.flx)}
                                      style={{
                                        margin: "15px 0",
                                      }}
                                    >
                                      {/* Travel Company Logo */}
                                      {/* <div
                                        className={classNames(
                                          style.imgcontainer
                                        )}
                                      >
                                        <img
                                          src={bids?.companyLogo}
                                          alt={`${bids?.companyName} logo`}
                                          style={{
                                            width: "33px",
                                            // marginTop: "20px",
                                            // marginBottom: "20px",
                                            height: "33px",
                                          }}
                                        />
                                      </div> */}
                                      {/* Travel Name */}
                                      <p
                                        className={classNames(
                                          style.pickupdetail
                                        )}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {bids?.companyName}
                                      </p>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        maxWidth: "600px",
                                        position: "relative",
                                        color: "#0e54a3",
                                      }}
                                    >
                                      {/* Left City */}
                                      <div
                                        style={{
                                          marginRight: "10px",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {bids.from}
                                      </div>

                                      {/* Plane Icon and Time */}
                                      <div
                                        style={{
                                          position: "relative",
                                          flexGrow: 1,
                                          height: "1px",
                                          borderTop: "2px dotted #0e54a3",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <BiSolidPlaneAlt
                                          style={{
                                            position: "absolute",
                                            top: "-33px",
                                            fontSize: "24px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "15px",
                                            fontSize: "14px",
                                            color: "#0e54a3",
                                          }}
                                        >
                                          {bids?.flightTime}
                                        </div>
                                      </div>

                                      {/* Right City */}
                                      <div
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {bids?.to}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}

                              {flight?.requestType === "round" && (
                                <>
                                  <p
                                    className={classNames(style.pickupdetail)}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Return Flight
                                  </p>
                                  {travel?.returnFlights?.map(
                                    (bids: any, index: any) => (
                                      <div
                                        key={`return-${index}`}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          marginBottom: "20px",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          className={classNames(
                                            commonstyle.flx
                                          )}
                                        >
                                          {/* Travel Company Logo */}
                                          <div
                                            className={classNames(
                                              style.imgcontainer
                                            )}
                                          >
                                            <img
                                              src={bids?.companyLogo}
                                              alt={`${bids?.companyName} logo`}
                                              style={{
                                                width: "24px",
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                height: "33px",
                                              }}
                                            />
                                          </div>
                                          {/* Travel Name */}
                                          <p
                                            className={classNames(
                                              style.pickupdetail
                                            )}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {bids?.companyName}
                                          </p>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            maxWidth: "600px",
                                            position: "relative",
                                            color: "#0e54a3",
                                          }}
                                        >
                                          {/* Left City */}
                                          <div
                                            style={{
                                              marginRight: "10px",
                                              fontSize: "18px",
                                            }}
                                          >
                                            {bids.from}
                                          </div>

                                          {/* Plane Icon and Time */}
                                          <div
                                            style={{
                                              position: "relative",
                                              flexGrow: 1,
                                              height: "1px",
                                              borderTop: "2px dotted #0e54a3",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <BiSolidPlaneAlt
                                              style={{
                                                position: "absolute",
                                                top: "-33px",
                                                fontSize: "24px",
                                              }}
                                            />
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "15px",
                                                fontSize: "14px",
                                                color: "#0e54a3",
                                              }}
                                            >
                                              {bids?.flightTime}
                                            </div>
                                          </div>

                                          {/* Right City */}
                                          <div
                                            style={{
                                              marginLeft: "10px",
                                              fontSize: "18px",
                                            }}
                                          >
                                            {bids?.to}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              )}

                              {/* Travel Route and Time */}
                              <div></div>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              commonstyle.flx,
                              commonstyle.flxCenter,
                              commonstyle.mt16
                            )}
                          >
                            <button
                              className={classNames(
                                commonstyle.colorBlue,
                                commonstyle.fs16,
                                commonstyle.semiBold
                              )}
                              style={{
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                color: "#0e54a3",
                                borderBottom: "2px solid #0e54a3",
                                padding: "4px 8px",
                                transition: "color 0.3s, border-color 0.3s",
                              }}
                              onClick={() =>
                                handledetail(travel, totalTravelers)
                              }
                              onMouseOver={(e) => {
                                e.currentTarget.style.color = "#08427d";
                                e.currentTarget.style.borderBottomColor =
                                  "#08427d";
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.color = "#0e54a3";
                                e.currentTarget.style.borderBottomColor =
                                  "#0e54a3";
                              }}
                            >
                              <a>View Details</a>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {loading || bidData?.length !== 0 ? null : "No Bids Found"}
                  </div>
                )}
              </div>
            </>
          );
        })}
        <DeleteModal
          modalVisible={isModalVisible}
          handleCancel={onCanelModal}
          handleDelete={deleteFlight}
          loading={loading}
        />
        {/* Display travel data when the Bids button is clicked */}
      </div>
    </div>
  );
};

export default TravelRequest;
