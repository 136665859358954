import React, { useState, useEffect } from "react";
import style from "./phyhospital.module.css";
import styles from "../PhyAvailability.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import { useLocation, useNavigate } from "react-router-dom";
import { physiotherapistGetAllHospitals } from "shared/services/Physiotherapist";
import { RingLoader } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import commomstyles from "shared/utils/common.module.css";
import { IoArrowBack } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import { useSelector } from "react-redux";
import { doctorGetAllHospitals } from "shared/services/DoctorService";
import { FaHospitalSymbol } from "react-icons/fa";

export default function DoctorHospitalAvailability() {
  const [loading, setLoading] = useState(false);
  interface Hospital {
    name: string;
    _id: string;
  }
  const [hospitalarray, setHospitalarray] = useState<Hospital[]>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { systemType } = useSelector((state: any) => state.root.common);

  const handleGoHospital = (id: any, name: any) => {
    let type = "hospital";

    navigate(`/${systemType}/availability/${type}/detail`, {
      state: {
        availabilityType: type,
        availabilityTitle: name,
        hospitalId: id,
      },
    });
  };
  const fetchAllHospitals = () => {
    setLoading(true);
    doctorGetAllHospitals()
      .then((res) => {
        setHospitalarray(res.data.hospitals);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllHospitals();
  }, []);

  const handleGoback = () => {
    navigate(`/${systemType}/availability`);
  };

  return (
    <div className={classNames(commomstyles.col12, style.doctorss)}>
      <SearchBar />
      <div className={commomstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={commomstyles.flx}>
            <IoArrowBack className={style.back} onClick={handleGoback} />
            <p
              className={classNames(
                commomstyles.fs24,
                commomstyles.semiBold,
                commomstyles.colorBlue
              )}
            >
              Availablitiy {state.availabilityTitle}
            </p>
            {loading ? (
              <div style={{ marginLeft: "16px" }}>
                <RingLoader color={"#0D47A1"} size={24} />
              </div>
            ) : (
              <TbRefresh
                className={style.Refresh}
                onClick={fetchAllHospitals}
              />
            )}
          </div>
          {hospitalarray && hospitalarray.length > 0 ? (
            <div className={style.flxWrap}>
              {hospitalarray.map((hospital: any, index: any) => (
                <div
                  key={index}
                  className={styles.cardContainer}
                  onClick={() => handleGoHospital(hospital._id, hospital.name)}
                >
                  <div className={styles.cardIconContainer}>
                    <FaHospitalSymbol className={styles.cardIcon} />
                  </div>

                  <div className={styles.textContainer}>
                    <p
                      className={classNames(
                        commomstyles.fs28,
                        commomstyles.semiBold,
                        styles.cardHeading
                      )}
                    >
                      {hospital.name}
                    </p>
                    <p
                      className={classNames(
                        commomstyles.fs16,
                        commomstyles.medium,
                        styles.cardSubHeading
                      )}
                    >
                      Add availability for this hospital
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>{!loading && <PhysiotheristsEmpty />}</div>
          )}{" "}
        </div>
      </div>
    </div>
  );
}
