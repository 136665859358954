import * as React from "react";
import styles from "./Insuredperson.module.css";
import commonstyles from "../../../utils/common.module.css";
import classNames from "classnames";

import { useNavigate } from "react-router-dom";
import InsuranceEmpty from "shared/components/InsuranceEmpty";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

interface Props {
  insurance: any;
}

function InsuredpersonTable(props: Partial<Props>) {
  const navigate = useNavigate();
  const handleGoToInsurancedDetail = (id: string) => {
    navigate(`/insuredPerson/Detail/${id}`);
  };
  const { insurance } = props;

  return (
    <div className={classNames(styles.App)}>
      <div className={styles.payment}>
        {insurance && insurance.length > 0 ? (
          <>
            <div className={styles.headerOuter}>
              <p className={styles.headerclass}>Date</p>
              <p className={styles.headerclass}>Insured Person</p>
              <p className={styles.headerclass}>Package Name</p>
              {/* <p className={styles.headerclass}>Duration</p> */}
              <p className={styles.headerclass}>Inusrance Amount</p>
            </div>
            <div className={styles.tableData}>
              <table
                style={{
                  margin: "0px",
                }}
              >
                <tbody className={styles.wapper}>
                  {insurance.map((val: any, key: any) => {
                    return (
                      <tr
                        className={styles.tableRow}
                        key={key}
                        onClick={() => handleGoToInsurancedDetail(val._id)}
                      >
                        <td className={styles.w20}>
                          {" "}
                          {val?.createdAt
                            ? new Date(val.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              )
                            : "__"}
                        </td>
                        <td className={styles.w20}>{val?.userId?.name}</td>
                        <td className={styles.w20}>
                          {val?.insuranceId?.packageName}
                        </td>
                        {/* <td className={styles.w20}>{val?.maternity}</td> */}
                        <td className={styles.w20}>{val?.amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <PhysiotheristsEmpty />
        )}
      </div>
    </div>
  );
}

export default InsuredpersonTable;
