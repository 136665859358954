import React, { useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Style from "./payment.module.css";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
const Vendor_PaymentDetails = () => {
  const { state } = useLocation();
  const { systemType } = useSelector((state: any) => state.root.common);
  let date = dayjs(state?.createdAt).format("MM-DD-YYYY h:mm a");
  const handleDownload = () => {
    if (state?.receiptImage) {
      window.open(state.receiptImage, "_blank");
    } else {
      alert("No receipt available.");
    }
  };
  return (
    <div className={classNames(commonstyles.col12)}>
     <div className={classNames({ [commonstyles.mr87]: systemType !== "hospital" })}>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
          <p className={classNames(commonstyles.fs24)}>Payment Details</p>
          <p className={classNames(commonstyles.fs18, Style.colorGreen)}>
            Payment ID: {state?.paymentId}
          </p>
        </div>

        <div className={Style.outerContainer}>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              commonstyles.flxWrap
            )}
          >
            <div
              className={classNames(
                commonstyles.col6,
                commonstyles.mt16,
                commonstyles.colsm12
              )}
            >
              <div>
                <div
                  className={classNames(
                    commonstyles.flxBetween,
                    commonstyles.mb24
                  )}
                >
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.medium
                    )}
                  >
                    Payment At
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    {date}
                  </p>
                </div>
              </div>

              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb8
                )}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Total Orders
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  {state?.noOfitems}
                </p>
              </div>

              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb8
                )}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Total Amount
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  {state?.totalAmount}
                </p>
              </div>

              <div
                className={classNames(
                  commonstyles.flxBetween,
                  commonstyles.mb24
                )}
              >
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  Tax.
                </p>
                <p
                  className={classNames(commonstyles.fs14, commonstyles.medium)}
                >
                  {state?.totalTax}
                </p>
              </div>

              <div className={classNames(commonstyles.flxBetween)}>
                <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
                  Paid Amount
                </p>
                <p className={classNames(commonstyles.fs14, commonstyles.bold)}>
                  {state?.payableAmount}
                </p>
              </div>
            </div>
            <div
              className={classNames(
                commonstyles.col6,
                commonstyles.mt16,
                commonstyles.colsm12
              )}
              onClick={handleDownload}
            >
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.medium,
                  commonstyles.clickable,
                  commonstyles.flxEnd
                )}
              >
                Download Receipt
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor_PaymentDetails;
