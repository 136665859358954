import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DocGetAllTreatments } from "shared/services/DoctorService";
import { AdminTable, CustomModal, DeleteModal, RingLoader } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import edit from "assets/images/edit.png";
import { delTre } from "shared/services";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import { MdDeleteOutline } from "react-icons/md";
const titles = [
  { id: 1, title: "Category" },
  { id: 2, title: "Name" },
  { id: 3, title: "Cost" },
  { id: 4, title: "Clinic/Hospital" },
  { id: 4, title: "Action" },
];
const DoctorTreatments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const itemsPerPage = 10;
  const totalItems = length;
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treatmentsForTable, setTreatmentsForTable] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(null);
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (totalItems > itemTorender) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchTreatments(1);
  }, []);

  const handleRefresh = () => {
    fetchTreatments(1);
  };
  const handleEditClick = (treatment: any) => {
    navigate(`/${systemType}/addTreatment`, { state: { treatment } });
  };
  const handleDeleteClick = (treatmentId: any) => {
    setSelectedTreatmentId(treatmentId);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (!selectedTreatmentId) return;
    console.log(selectedTreatmentId,'......selectedTreatmentId')
    console.log(selectedTreatmentId)
    delTre(selectedTreatmentId)
      .then((res) => {
        notifySuccess(res?.data?.message);
        fetchTreatments(1);
      })
      .catch((err:any) => {
        console.log(err,'.....errr')
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedTreatmentId(null);
      });
  };

  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data.map((v: any, ind: any) => {
        console.log(v,'....v')
        tempData.push([
          v?.treatmentId?.categoryId?.categoryName,
          v?.treatmentId?.subCategory,
          v?.totalAmount,
          v?.isPersonal == true ? "Clinic" : v?.hospitalId?.name,
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <img
              src={edit}
              style={{ width: "24px", height: "24px" }}
              onClick={() => handleEditClick(v)}
            />
            <MdDeleteOutline
              size={20}
              color="red"
              onClick={() => handleDeleteClick(v?._id)}
            />
          </div>,
        ]);
      });

      setTreatmentsForTable(tempData);
    } else {
      setTreatmentsForTable([]);
    }
  };

  const fetchTreatments = (pageno: number) => {
    setLoading(true);
    DocGetAllTreatments(pageno)
      .then((res: any) => {
        handleTableData(res?.data?.treatments);
        setTreatments(res?.data?.treatments);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoToDetail = () => {};
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.outerContainer)}>
        <div
          className={classNames(
            commonstyles.flxBetween,
            commonstyles.flxWrap,
            commonstyles.mb32
          )}
        >
          <div className={classNames(commonstyles.flx)}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Your Treatment Packages
            </p>

            <div className={styles.outerRefresh}>
              <BiSolidMessageSquareAdd
                className={styles.addIcon}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/${systemType}/addTreatment`)}
              />
            </div>

            <div className={styles.outerRefresh}>
              {loading ? (
                <div className={styles.loader}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <TbRefresh
                  className={styles.refresh}
                  style={{ cursor: "pointer" }}
                  onClick={handleRefresh}
                />
              )}
            </div>
          </div>
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>

        {/* <div className={styles.tabless}>
              <Tabletest tests={tests} setShowModal={setShowAddModal} />
            </div> */}
        {treatmentsForTable && treatmentsForTable.length > 0 ? (
          <AdminTable
            titles={titles}
            data={treatmentsForTable}
            handleGoToDetail={handleGoToDetail}
            headerWidth={"16.66%"}
            itemWidth={"13.66%"}
          />
        ) : (
          <div>
            <PhysiotheristsEmpty />
          </div>
        )}
      </div>
      <DeleteModal 
      modalVisible={showDeleteModal}
       handleDelete={confirmDelete}
       loading={loading}
       content={"Are you sure you want to delete this treatment?"}
      //  confirmText,
      handleCancel={() => setShowDeleteModal(false)} />
     
    </div>
  );
};

export default DoctorTreatments;
