import React, { useEffect, useState } from "react";
import styles from "../PharmcyOrder/order.module.css";
import classNames from "classnames";
import commomstyles from "shared/utils/common.module.css";
import PhrmacyTableorder from "../../../shared/components/PharmcyTables/phrarmayorder/";
import SearchBar from "../../../shared/components/Searchbar/index";
import commonstyle from "../../../shared/utils/common.module.css";
import { getAllPharmOrders } from "shared/services";
import { colors } from "@mui/material";
import { LoadingModal, RingLoader } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import {
  setPharmacyOrder,
  setPharmacyOrderLength,
  setrenderpharmacyOrderFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
function PharmcyOrder() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const { orders, renderpharmacyOrderFlag, orderLength } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const totalItems = orderLength;
  const fetchPharOrders = (pageno: number) => {
    setLoading(true);
    getAllPharmOrders(pageno)
      .then((res: any) => {
        dispatch(setPharmacyOrder(res?.data?.orders));
        dispatch(setPharmacyOrderLength(res?.data?.totalPharms));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleStatusChange = () => {
    fetchPharOrders(currentPage);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (orderLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setrenderpharmacyOrderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setrenderpharmacyOrderFlag(true));
    }
  };
  useEffect(() => {
    fetchPharOrders(1);
  }, []);
  const handleRefresh = () => {
    setLoading(true);
    setCurrentPage(1);
    fetchPharOrders(1);
  };
  useEffect(() => {
    if (renderpharmacyOrderFlag) {
      setLoading(true);
      fetchPharOrders(currentPage);
      dispatch(setrenderpharmacyOrderFlag(false));
    }
  }, [renderpharmacyOrderFlag, currentPage]);

  return (
    <div className={classNames(commonstyle.col12, styles.outer)}>
      <SearchBar />
      <div className={commonstyle.mr87}>
        <div className={styles.outerContainer}>
          <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commonstyle.fs24,
                  commonstyle.semiBold,
                  commonstyle.colorBlue
                )}
              >
                All Medicines Order
              </p>
              {loading ? (
                <div className={styles.loader}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <TbRefresh className={styles.refresh} onClick={handleRefresh} />
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {orders && orders.length > 0 ? (
            <PhrmacyTableorder
              orders={orders}
              onStatusChange={handleStatusChange}
            />
          ) : (
            <>
              <PhysiotheristsEmpty />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PharmcyOrder;
