import React, { useEffect, useState } from "react";
import styles from "./tableorder.module.css";
import classNames from "classnames";
// import tableorder from "./tableorder.module.css";
import commonstyles from "../../../utils/common.module.css";
import { PiUploadSimpleFill } from "react-icons/pi";
import empty from "assets/images/empty2.png";
import CustomSelectOrder from "pages/Laboratory/Orders/CustomSelectOrder";
import { useNavigate } from "react-router-dom";
import { PharmacyChangeStatus } from "shared/services";
import PharmacyEmpty from "shared/components/PharmacyEmpty";
import CustomRentCarOrder from "shared/components/RentacarTables/RentacarOrder/CustomRenCarSelectOrder";

interface Props {
  orders: any;
  setShowModal: any;
  onStatusChange: any;
}
function PhrmacyTableorder(props: Partial<Props>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);
  const { orders, onStatusChange } = props;
  const navigate = useNavigate();

  const handleGoToOrderDeatil = (id: string) => {
    navigate(`/pharmacy/orderDetails/${id}`);
  };

  const uploadStatus = (id: any, status: string, index: any) => {
    setSelectedIndex(index);

    let params = {
      status: status,
    };
    PharmacyChangeStatus(id, params)
      .then((res: any) => {
        onStatusChange();
      })
      .catch((err: any) => {
        setError(err?.response?.data?.message);
        // setTimeout(() => {
        //   onStatusChange();
        // }, 1000);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames()}>
        <div className={styles.headerOuter}>
          <p className={styles.headerclass}>Request ID</p>
          <p className={styles.headerclass}>Date</p>
          <p className={styles.headerclass}>Status</p>
          <p className={styles.headerclass}>Total Amount</p>
          <p className={styles.headerclass}>Details</p>
        </div>
        <div className={classNames(styles.ss, commonstyles.fs14)}>
          <div className={styles.tableData}>
            <table
              style={{
                margin: "0px",
              }}
            >
              <tbody className={styles.wapper}>
                {orders.map((val: any, key: any) => {
                  return (
                    <tr className={styles.tableRow} key={key}>
                      <td className={styles.w20}>{val?.requestId}</td>

                      <td
                        className={styles.w20}
                        // onClick={() => handleGoToOrderDeatil(val._id)}
                      >
                        {/* {val.updatedAt}  */}
                        {val &&
                          val.updatedAt &&
                          new Date(val.updatedAt).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })}
                      </td>
                      <td className={styles.w20}>
                        {/* {val.status} */}
                        {
                          <CustomRentCarOrder
                            error={error}
                            setSelectedValue={(d: any) =>
                              uploadStatus(val._id, d, key)
                            }
                            initialValue={val.status}
                          />
                        }
                      </td>
                      <td className={styles.w20}>
                        {val.paidByUserAmount?.toFixed(2)}
                      </td>
                      <td
                        className={styles.w20}
                        onClick={() => handleGoToOrderDeatil(val._id)}
                      >
                        View Details
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhrmacyTableorder;
