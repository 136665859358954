import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import CardStyless from "./Cards.module.css";
import CommonStyless from "shared/utils/common.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import dstyle from "../DonationServices/DonationPackeg.module.css";
import { cityData } from "shared/utils";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import {
  getAllDoctor_SubCategory,
  getAll_Doctors,
} from "shared/services/UserService";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
import DoctorCard from "shared/components/A_New_Components/DoctorCard";
import {
  GetAllDoctorsAvailable,
  getSpecialtiesDoctor,
} from "shared/services/DoctorService";
import toast from "react-hot-toast";
import { ALL_DOC_PARAMAS } from "./functionProps";
import { useDispatch } from "react-redux";
import { setTreatmentId } from "shared/redux";
import Filter from "assets/images/filterHos.png";
import { CustomModal, InputField } from "shared/components";
import { Box, Checkbox, Modal, Typography } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { CheckBox } from "@mui/icons-material";
import New_Filter from "shared/components/A_New_Components/NewFilter";

const DoctorCards: React.FC<{ serviceName: string; speciality: any }> = ({
  serviceName,
  speciality,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [modelVisible, setModelVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("ALL");
  const [activeSpeciality, setActiveSpeciality] = useState<string>("Fertility");
  const [doctors, setDoctors] = useState<any>([]);
  const [splecialist, setSpecialist] = useState<any>([]);
  const [applyCity, setApplyCity] = useState<any>(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const locations = useLocation();
  const dispatch = useDispatch();
  const showTreatment = locations.state?.showTreatment || false;
  //
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetch_All_Doc(currentPage);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  }, []);

  useEffect(() => {
    if (state?.type === "speciality") {
      allTreatments();
    } else if (activeTab === "Speciality") {
      fetch_speciality();
    } else {
      fetch_All_Doc(currentPage);
    }
  }, [activeTab, applyCity]);

  const allTreatments = () => {
    let paging = {
      page: 1,
    };
    let params = {
      treatmentId: state?.item?._id,
    };
    getAllDoctor_SubCategory(paging, params)
      .then((res: any) => {
        setDoctors(res.data?.doctors);
        setTotalItems(res?.data?.doctorCount);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetch_All_Doc = async (pagenum: any) => {
    try {
      const res = await getAll_Doctors(
        ALL_DOC_PARAMAS(
          location,
          activeTab,
          searchValue,
          serviceName,
          selectedIndex
        )
      );
      setDoctors(res?.data?.doctors);
      setTotalItems(res?.data?.totalDocs);
    } catch (err: any) {
    } finally {
      setLoading(false);
      setApplyCity(false);
    }
  };

  const handleViewMoreClick = (doc: any) => {
    dispatch(setTreatmentId(doc?.doctor?._id));
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName, speciality, doc },
    });
  };

  const handleTabClick = (tab: string) => {
    if (tab == "Search by city") {
      setModelVisible(true);
    }
    setActiveTab(tab);
  };
  const handleApply = () => {
    if (selectedIndex) {
      setApplyCity(true);
      setModelVisible(false);
      setSearchTerm("");
    } else {
      toast.error("Please select city");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      fetch_All_Doc(currentPage);
    }
  };

  const fetch_speciality = async () => {
    setLoading(true);
    getSpecialtiesDoctor("")
      .then((res: any) => {
        setSpecialist(res?.data?.specialities);
        setTotalItems(res?.data?.specialities[0]?.doctorsCount);
        getAllDocAvailable();
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };
  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
      fetch_All_Doc(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetch_All_Doc(currentPage - 1);
    }
  };
  useEffect(() => {
    if (activeTab === "Speciality") {
      getAllDocAvailable();
    }
  }, [activeSpeciality]);

  const getAllDocAvailable = () => {
    // setLoading(true);

    let params = {
      speciality: activeSpeciality,
    };

    GetAllDoctorsAvailable(params)
      .then((res: any) => {
        setDoctors(res?.data?.doctors);
      })

      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const filteredCities = cityData?.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSpeciality = (item: any, index: number) => {
    setActiveSpeciality(item?.specialityTitle);
    setTotalItems(item?.doctorsCount);
  };
  const onClose = () => {
    setModelVisible(false);
    setSearchTerm("");
    setActiveTab("ALL");
  };

  return (
    <div style={{ backgroundColor: "#FDFDFD", paddingBottom: "32px" }}>
      <div className={classNames(CommonStyless.container)}>
        <div className={CommonStyless.mt32}>
          {" "}
          <New_Filter />
          <div
            className={classNames(CardStyless.flxendfilter, CardStyless.main)}
          >
            {/* {state.type !== "speciality" ? (
            <div
              className={classNames(
                CardStyless.tabContainer,
                CommonStyless.flx
              )}
            >
              {[
                "ALL",
                "Near by me",
                "Search by city",
                "Recommended",
                !state?.type ? "Speciality" : null,
              ]
                ?.filter(Boolean)
                .map((tab: any) => (
                  <button
                    key={tab}
                    className={classNames(
                      CardStyless.tab,
                      activeTab === tab && CardStyless.activeTab
                    )}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </button>
                ))}
            </div>
          ) : (
            <div
              className={classNames(
                CommonStyless.colorBlue,
                CommonStyless.fs24,
                CommonStyless.semiBold
              )}
            >
              Available Doctors
            </div>
          )}

          {modelVisible && (
            <div className={classNames(dstyle.modal)} style={{ zIndex: 1000 }}>
              <div className={classNames(dstyle.drmodalContent)}>
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  >
                    Search by city
                  </p>
                  <IoClose
                    onClick={onClose}
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  />
                </div>
                <div>
                  <input
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid black",
                      outline: "none",
                      padding: "8px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Search here"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <ul>
                  {filteredCities?.map((item, index) => {
                    const isSelected = item === selectedIndex;
                    return (
                      <li
                        key={index}
                        onClick={() => setSelectedIndex(item)}
                        style={{
                          color: isSelected ? "white" : "#1e5da3",
                          backgroundColor: isSelected
                            ? "#1e5da3"
                            : "transparent",
                          padding: "10px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <div className={dstyle.showMoreContainer}>
                  <button
                    onClick={() => {
                      handleApply();
                    }}
                    className={dstyle.showMoreButton}
                    style={{ marginTop: "10px" }}
                  >
                    Apply
                    <span className={dstyle.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )} */}

            {showTreatment && (
              <div
                className={classNames(
                  CommonStyless.colorBlue,
                  CommonStyless.fs24,
                  CommonStyless.semiBold
                )}
              >
                Available Doctors
              </div>
            )}
            {/* 
          {!modelVisible && (
            <div
              className={classNames(
                CommonStyless.col2,
                CommonStyless.colsm12,
                CardStyless.searchBarContainer
              )}
            >
              <IoSearchSharp className={CardStyless.searchIcon} />
              <input
                type="text"
                placeholder="Search...."
                className={CardStyless.searchInput}
                value={searchValue}
                onChange={(event: any) => setSearchValue(event?.target?.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          )} */}
          </div>
          {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div> */}
          {activeTab === "Speciality" && (
            <div
              className={classNames(
                CardStyless.tabContainer_2,
                CommonStyless.flx
              )}
            >
              {splecialist.map((item: any, index: any) => (
                <button
                  key={index}
                  className={classNames(
                    CardStyless.tab,
                    activeSpeciality === item?.specialityTitle &&
                      CardStyless.activeTab
                  )}
                  onClick={() => handleSpeciality(item, index)}
                >
                  <span>{item?.specialityTitle}</span>
                </button>
              ))}
            </div>
          )}
          <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
            <div className={CardStyless.cardContainer}>
              {doctors && doctors.length > 0 ? (
                doctors?.map((docs: any, index: number) => {
                  return (
                    <DoctorCard
                      key={index}
                      item={docs}
                      onClick={handleViewMoreClick}
                      type={state?.type}
                    />
                  );
                })
              ) : (
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxCenter
                  )}
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {" "}
                  {!loading && (
                    <div className={classNames(CommonStyless.flx)}>
                      <PhysiotheristsEmpty />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {loading && <CustomLoader />}
      </div>
    </div>
  );
};

export default DoctorCards;
