import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm";

type Props = {
  serviceName?: any;
  convertedAmount?: any;
  paidAmount?: any;
  remainingAmount?: any;
};

const options: any = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const VITE_STRIPE_PK =
  "pk_live_51PU65d1rwEL9hE4ZV9FaaL3UN2uOHgGNbk8GeTc9cLMWUcS34ktFieSFaEfxnWvvQpja7KB1ePRPTZBkyXOrIqK500pMrvLeiz";
// const VITE_STRIPE_PK =
//   "pk_test_51PU65d1rwEL9hE4Z37XFQSQJrlvyhcozuGtFvME323uUZIK2rObxR4Mz3yYAxrmhChWZNec5qp0aC7wlMUqZwNK000NK1G3fyj";

const Checkout = (props: Props) => {
  // const VITE_STRIPE_PK: any = process.env.REACT_APP_VITE_STRIPE_PK;
  console.log("🚀 ~ VITE_STRIPE_PK:", VITE_STRIPE_PK);

  const stripePromise = loadStripe(VITE_STRIPE_PK);
  const { serviceName, convertedAmount, paidAmount, remainingAmount } = props;

  return (
    <div className="flex container mt-8">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          serviceName={serviceName}
          convertedAmount={convertedAmount}
          paidAmount={paidAmount}
          remainingAmount={remainingAmount}
        />
      </Elements>
    </div>
  );
};

export default Checkout;
