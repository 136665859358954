import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import AmblulanceRoutes from "shared/components/AmbulanceTables/AmblulanceRoutes";
import PerviousNextButton from "shared/components/Pagination";
import { getAllAmbulanceOnRoute } from "shared/services/Ambulance";
import { LoadingModal, RingLoader } from "shared/components";
import style from "./ambulanceRoutes.module.css";
import Pagination from "shared/components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { TbRefresh } from "react-icons/tb";
import {
  setAmbulanceOnroute,
  setAmbulanceOnrouteLength,
  setAmbulanceOnrouteRenderFlag,
} from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import CustomLoader from "shared/components/New_Loader/Loader";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

export default function AmbulancefetchRoutes() {
  const {
    ambulanceOnroute,
    ambulanceOnrouteLength,
    ambulanceOnrouteRenderFlag,
  } = useSelector((state: any) => state.root.ambulance);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = ambulanceOnrouteLength;

  const fechAllAmbulanceRoute = (pageno: number) => {
    setLoading(true);
    getAllAmbulanceOnRoute(pageno)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(setAmbulanceOnroute(res?.data?.bookings));
          dispatch(setAmbulanceOnrouteLength(res?.data?.totalBookings));
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    fechAllAmbulanceRoute(1);
    setCurrentPage(1);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (ambulanceOnrouteLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      fechAllAmbulanceRoute(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setAmbulanceOnrouteRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fechAllAmbulanceRoute(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setAmbulanceOnrouteRenderFlag(true));
    }
  };
  const handleStatusChange = () => {
    fechAllAmbulanceRoute(currentPage);
  };
  useEffect(() => {
    setLoading(true);
    fechAllAmbulanceRoute(1);
    dispatch(setAmbulanceOnrouteRenderFlag(false));
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(commonstyles.flxBetween, style.mb24)}>
            <div className={classNames(commonstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                On Route Ambulance
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    onClick={handleRotate}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          {ambulanceOnroute && ambulanceOnroute.length > 0 ? (
            <AmblulanceRoutes
              data={ambulanceOnroute}
              onStatusChange={handleStatusChange}
            />
          ) : (
            <PhysiotheristsEmpty />
          )}
          {/* )} */}
          {/* <PerviousNextButton /> */}
        </div>
      </div>
    </div>
  );
}
