import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import groupimg from "assets/images/HospitalDashboard/groupDr.png";
import { FaHospital } from "react-icons/fa6";
import PatientListData from "shared/components/HospitalNewComponents/DrConsultantPatient";
import { companyPatient, genList, getHospital } from "shared/services";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const Doctor_Patient = (props: any) => {
  const [selected, setSelected] = useState<string>("All");
  const [data, setData] = useState<any>([]);
  const [loading, setloading] = useState(false);
  const options = ["All", "UpComing", "Completed"];
  const [listData, setListData] = useState<any>(null);
  const handleSelect = (option: string) => {
    setSelected(option);
  };
  const hnadlePateintData = (i?: any) => {
    console.log(i, "...sjsj.");
    setListData(i);
  };
  useEffect(() => {
    fetchPatient();
  }, [selected]);
  const fetchPatient = () => {
    setloading(true);
    genList(selected?.toLowerCase())
      .then((res: any) => {
        const patients = res?.data?.patients;
        console.log("........", patients);
        if (patients && patients.length > 0) {
          setData(patients);
          setListData(patients[0]?.patient?._id);
        } else {
          setData([]);
          setListData(null);
        }
      })
      .catch((err: any) => {
        setData([]);
        console.log(err, "....errorlisr");
        setListData(null);
      })
      .finally(() => {
        setloading(false);
      });
  };
  const activePosition = options.indexOf(selected) * 33.33 + "%";
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  return (
    <div className={classNames(style.container)}>
      <div className={classNames(commonstyle.col12)}>
        <p
          className={classNames(
            style.heading,
            commonstyle.mt16,
            commonstyle.mb32
          )}
        >
          Patient List
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{ flexGrow: 1 }}
            className={classNames(commonstyle.colmd12, commonstyle.colsm12)}
          >
            <div className={classNames(classNames(style.leftside))}>
              <div className={classNames(style.bar)}>
                <div
                  className={classNames(style.active)}
                  style={{ left: activePosition }}
                >
                  <span className={classNames(style.activetext)}>
                    {selected}
                  </span>
                </div>

                {options.map((option) => (
                  <div
                    key={option}
                    onClick={() => handleSelect(option)}
                    className={classNames(
                      option === selected ? "" : style.nonactive
                    )}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
            <div className={classNames(style.cardcontainer)}>
              {data.map((card: any, index: any) => (
                <div
                  className={`${style.card} ${
                    listData === card?.patient?._id ? style.selectedCard : ""
                  }`}
                  key={index}
                  onClick={() => hnadlePateintData(card?.patient?._id)}
                >
                  <div className={style.cardheader}>
                    <img
                      src={
                        card?.patient?.userImage ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                      }
                      alt=""
                      className={style.cardprofile}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <FaHospital />
                      <span className={style.cardtitle}>Hospital</span>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <p className={style.cardname}>{card?.patient?.name}</p>
                  </div>
                  <p className={style.cardid}>
                    <span>ID:</span>
                    <span>{card?.patient?.mrNo}</span>
                  </p>
                  <div className={style.cardbottom}>
                    <p className={style.carddate}>
                      Age: {calculateAge(card.patient?.dateOfBirth)} years
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={classNames(
              style.maincontainer
            )}
          >
            <div
            >
              {listData ? (
                <>
                  <PatientListData patientId={listData} />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "auto 0",
                  }}
                >
                  <img
                    src={groupimg}
                    alt=""
                    className={classNames(style.drimg)}
                  />
                  <p className={style.Patient} style={{ marginTop: "16px" }}>
                    No Patient
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doctor_Patient;
