import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import tr from "./tr.json";

const resources = {
  en: {
    translation: en,
  },
  tr: {
    translation: tr,
  },
};

i18n
  .use(initReactI18next) // Pass the i18next instance to the react-i18next module
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // If language is not found, use this
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
