import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper/modules"; // Import Autoplay
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/autoplay"; // Ensure autoplay styles are included
import "./ImageSwiper.css";
import { countryData } from "shared/utils";

const ImageSwiper = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  return (
    <div className="image-swiper">
      <Swiper
        modules={[Navigation, Thumbs, Autoplay]} // Add Autoplay module
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          // delay: 2500000000000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={false}
        pagination={false}
        thumbs={{ swiper: thumbsSwiper }}
        className="main-swiper"
      >
        {countryData.map((i, index) => (
          <SwiperSlide
            key={index}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={i?.img} alt={`Slide ${index}`} className="main-image" />
            {/* <div className="App"> */}
            {/* <div className="gradient-banner">{i?.country}</div> */}
            {/* </div> */}
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        modules={[Thumbs]}
        spaceBetween={10}
        slidesPerView={6}
        watchSlidesProgress
        className="thumbs-swiper"
      >
        {countryData.map((i, index) => (
          <SwiperSlide key={index}>
            <img
              src={i?.img}
              alt={`Thumbnail ${index}`}
              className="thumbnail"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSwiper;
