import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import styles from "./VehicleDetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import {
  CustomInput,
  CustomModal,
  CustomStepper,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { useParams } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BiSolidEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import Uplaod from "assets/images/uil_image-upload.png";
import {
  rentCarAddVahicle,
  rentCarDeleteVahicle,
  rentCarGetVahicleDetail,
  rentCarVahicleEdit,
} from "shared/services/RentaCar";
import Cross from "../../../../assets/images/cross.png";
import CustomSelect from "shared/components/CustomSelect";
import { rentACarAddVahicleSchema, rentCarPriceSchema } from "shared/utils";
import { setVehicleRenderFlag } from "shared/redux";
import { useDispatch } from "react-redux";
import Datepicker from "shared/components/DatePicker";
import PropertyPhotoImgPicker from "pages/TravelTourism/Hotels/HotelProperty/Breadcrum/PropertyPhoto/PropertyPhotoImgPicker";
import dayjs from "dayjs";
import { FaEdit } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import CustomLoader from "shared/components/New_Loader/Loader";

const Vehicletype = ["Vehicle type", "SUV", "Sedan", "HatchBacks", "Truck"];
interface VahicleDetailss {
  vehicleType: string;
  vehicleName: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleColour: string;
  _id: string;
  vehicleRegisterationNo: string;
  vehicleRegisterationDate: string;
  actualPricePerHour: number;
  meditourPricePerHour: number;
  vehicleVinNo: number;
  vehicleImages: string[];
  actualPricePerDay: number;
}
interface VehicleImagesProps {
  vahicledetails: VahicleDetailss;
}

const steps = [
  {
    id: "1",
    lable: "Vehicle",
  },
  {
    id: "2",
    lable: "Price",
  },
];
export default function VehicleDescription() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [vahicledetails, setVahicleDetails] = useState<VahicleDetailss | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleGoBack = () => {
    navigate("/rentacar/vehicleDetail");
  };
  const handleDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };
  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleEditModel = () => {
    setShowEditModel(true);
  };
  const VahicleDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      //

      rentCarGetVahicleDetail(id)
        .then((res: any) => {
          setVahicleDetails(res?.data.vehicle);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    VahicleDetail();
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyles.col12, styles.colorBlue)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
              <div className={commonstyles.flx}>
                <IoArrowBack className={styles.back} onClick={handleGoBack} />
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Vehicle Details
                </p>
              </div>
              <div className={classNames(commonstyles.flx, styles.end)}>
                <div className={styles.buttoons}>
                  <BiSolidEdit
                    className={styles.reset}
                    onClick={handleEditModel}
                  />
                </div>
                <div className={styles.buttoons}>
                  <MdDeleteOutline
                    className={styles.reset}
                    onClick={handleDeleteConfirmation}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(commonstyles.flx, styles.my32)}>
              <div className={classNames(commonstyles.flx, commonstyles.col6)}>
                <div
                  className={classNames(
                    commonstyles.col5,
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  <p className={styles.mt8}>Vehicle Type:</p>
                  <p className={styles.mt8}>Vehicle Name:</p>

                  <p className={styles.mt8}>Vehicle Model:</p>
                  <p className={styles.mt8}>Vehicle color:</p>
                  <p className={styles.mt8}>VIN:</p>
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.fs20)}
                >
                  <p className={styles.mt8}>{vahicledetails?.vehicleType}</p>
                  <p className={styles.mt8}>{vahicledetails?.vehicleName}</p>

                  <p className={styles.mt8}>{vahicledetails?.vehicleModel}</p>
                  <p className={styles.mt8}>{vahicledetails?.vehicleColour}</p>
                  <p className={styles.mt8}>{vahicledetails?.vehicleVinNo}</p>
                </div>
              </div>
              <div
                className={classNames(
                  commonstyles.col6,
                  commonstyles.fs20,
                  styles.end,
                  commonstyles.flx
                )}
              >
                <div
                  className={classNames(
                    commonstyles.col5,
                    commonstyles.fs20,
                    commonstyles.semiBold
                  )}
                >
                  {/* <p className={styles.mt8}>Vehicle ID. NO:</p> */}
                  <p className={styles.mt8}>Registration Number:</p>
                  <p className={styles.mt8}>Vehicle Year: </p>
                  <p className={styles.mt8}>Registeration Date: </p>
                  <p className={styles.mt8}>Price Per Day: </p>
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.fs20)}
                >
                  {/* <p>{vahicledetails?._id}</p> */}
                  <p className={styles.mt8}>
                    {vahicledetails?.vehicleRegisterationNo}
                  </p>
                  <p className={styles.mt8}>{vahicledetails?.vehicleYear}</p>
                  <p className={styles.mt8}>
                    {vahicledetails?.vehicleRegisterationDate}
                  </p>
                  <p className={styles.mt8}>
                    {vahicledetails?.actualPricePerDay} {"/-"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {vahicledetails?.vehicleImages?.slice(0, 3).map((image, index) => (
            <img
              key={index}
              src={image || "default-image-url"}
              alt={`Vehicle ${index + 1}`}
              style={{
                width: "150px",
                height: "auto",
                border: "none",
                boxShadow: "none",
                marginRight: "10px",
              }}
            />
          ))}
        </div>
      )}

      {showDeleteModal && (
        <div>
          <VahicleConfirmDelete setShowDeleteModal={setShowDeleteModal} />
        </div>
      )}

      <CustomModal
        showModal={showEditModel}
        children={
          <VahicleEdit
            setShowEditModel={setShowEditModel}
            vahicledetails={vahicledetails}
            fetchData={VahicleDetail}
            setVahicleDetails={setVahicleDetails}
          />
        }
      />
    </>
  );
}

interface Props {
  setShowAddModal: any;
  setShowDeleteModal: any;
}
const VahicleConfirmDelete = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { setShowDeleteModal } = props;
  const deleteMedicine = () => {
    setLoading(true);
    const vahicleID = id || "";
    rentCarDeleteVahicle(vahicleID)
      .then((res: any) => {
        //
        if (res?.status === 200) {
          navigate("/rentacar/vehicleDetail");
          dispatch(setVehicleRenderFlag(true));
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={classNames(styles.modalBackdrop)}>
        <div className={classNames(styles.modalContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IoClose
              className={styles.closeicon}
              onClick={() => setShowDeleteModal(false)}
            />
          </div>
          <div className={classNames(commonstyles.flx, commonstyles.flxCol)}>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs24,
                commonstyles.semiBold
              )}
            >
              Are you sure ?
            </p>
            <p
              className={classNames(
                commonstyles.colorGray,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              You want to delete this vehicle?
            </p>
            <div
              className={classNames(commonstyles.flx, commonstyles.flxBetween)}
            >
              <button
                className={styles.cancelbtn}
                onClick={() => setShowDeleteModal(false)}
              >
                No Cancel
              </button>
              <button className={styles.dltbtn} onClick={deleteMedicine}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface EditProp {
  setShowEditModel: any;
  vahicledetails?: any;
  setVahicleDetails: any;
  fetchData: any;
}
const VahicleEdit = (props: Partial<EditProp>) => {
  const { setShowEditModel, vahicledetails, setVahicleDetails, fetchData } =
    props;
  const [imagearray, setImagearray] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const ImageSelector = (url: any) => {
    setImagearray((prevImages) => [...prevImages, url]);
  };

  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("vahicleType", selectedOption);
  };
  const formik = useFormik({
    initialValues: {
      vahicleType: "",
      vehicleName: "",
      vehicleModal: "",
      vehicleYear: "",
      vehicleColour: "",
      vehicleVIN: "",
      perDayRent: "",
      vehicleRegistrationNum: "",
      vahicleRegistrationDate: "",
      vehicleImages: [],
    },
    validationSchema: Yup.object(rentACarAddVahicleSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    let obj = {
      vahicleType: vahicledetails?.vehicleType,
      vehicleName: vahicledetails?.vehicleName,
      vehicleModal: vahicledetails?.vehicleModel,
      vehicleYear: vahicledetails?.vehicleYear,
      vehicleColour: vahicledetails?.vehicleColour,
      vehicleVIN: vahicledetails?.vehicleVinNo,
      perDayRent: vahicledetails?.actualPricePerDay,
      vehicleRegistrationNum: vahicledetails?.vehicleRegisterationNo,
      vahicleRegistrationDate: vahicledetails?.vehicleRegisterationDate,
      vehicleImages: vahicledetails?.vehicleImages,
    };
    setImagearray(vahicledetails?.vehicleImages);

    formik.setValues(obj);
    setImagearray(vahicledetails?.vehicleImages);
  }, [vahicledetails]);
  const handleSubmit = () => {
    const currentData = formik.values;
    let veh_clone = JSON.parse(JSON.stringify(vahicledetails));

    let params = {
      vehicleType: currentData.vahicleType,
      vehicleImages: imagearray,
      vehicleName: currentData.vehicleName,
      vehicleModel: currentData.vehicleModal,
      vehicleYear: currentData.vehicleYear,
      vehicleColour: currentData.vehicleColour,
      vehicleVinNo: currentData.vehicleVIN,
      vehicleRegisterationNo: currentData.vehicleRegistrationNum,
      vehicleRegisterationDate: currentData.vahicleRegistrationDate,
      actualPricePerDay: currentData.perDayRent,
    };

    setLoading(true);

    rentCarAddVahicle(params)
      .then((res: any) => {
        veh_clone = res?.data?.vehicle;
        setVahicleDetails(veh_clone);
        setShowEditModel(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState<{ vehicleImages?: string }>({});
  const [imageToDeleteIndex, setImageToDeleteIndex] = useState<number | null>(
    null
  );

  const handleLicenseExpiry = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    formik.setFieldValue("vahicleRegistrationDate", formattedDate);
  };
  const addImages = (image: string[]) => {
    const updatedImages = [...formik.values.vehicleImages];
    if (updatedImages.length >= 3) {
      setErrors({ vehicleImages: "Maximum 3 images allowed" });
    } else {
      const newImages = Array.isArray(image) ? image : [image];
      formik.setFieldValue("vehicleImages", [...updatedImages, ...newImages]);
      setErrors({});
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedItems = [...formik.values.vehicleImages];
    updatedItems.splice(index, 1);
    formik.setFieldValue("vehicleImages", updatedItems);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            alignItems: "center",
          }}
          onClick={() => setShowEditModel(false)}
        >
          <img src={Cross} style={{ height: 30, width: 30 }} />
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomSelect
              onSelect={handleSelect}
              options={Vehicletype}
              placeholder="Select Type"
              value={formik.values.vahicleType}
            />
            {formik.touched.vahicleType && formik.errors.vahicleType ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vahicleType}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Vehicle Name "
              id="vehicleName"
              name="vehicleName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleName}
            />

            {formik.touched.vehicleName && formik.errors.vehicleName ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleName}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Vehicle Model "
              id="vehicleModal"
              name="vehicleModal"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleModal}
            />

            {formik.touched.vehicleModal && formik.errors.vehicleModal ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleModal}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="Vehicle Year"
              id="vehicleYear"
              name="vehicleYear"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleYear}
            />

            {formik.touched.vehicleYear && formik.errors.vehicleYear ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleYear}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Registration Number"
              id="vehicleRegistrationNum"
              name="vehicleRegistrationNum"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleRegistrationNum}
            />

            {formik.touched.vehicleRegistrationNum &&
            formik.errors.vehicleRegistrationNum ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleRegistrationNum}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <Datepicker
              placeholder="Registration Date"
              setData={handleLicenseExpiry}
              value={dayjs(formik.values.vahicleRegistrationDate)}
            />
            {formik.touched.vahicleRegistrationDate &&
            formik.errors.vahicleRegistrationDate ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vahicleRegistrationDate}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Per Day Rent"
              id="perDayRent"
              name="perDayRent"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.perDayRent}
            />

            {formik.touched.perDayRent && formik.errors.perDayRent ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.perDayRent}
              </div>
            ) : null}
          </div>
          <div className={commonstyles.col6}>
            <CustomInput
              placeholder="VIN"
              id="vehicleVIN"
              name="vehicleVIN"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleVIN}
            />

            {formik.touched.vehicleVIN && formik.errors.vehicleVIN ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleVIN}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
          <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
            <CustomInput
              placeholder="Vehicle Color"
              id="vehicleColour"
              name="vehicleColour"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.vehicleColour}
            />

            {formik.touched.vehicleColour && formik.errors.vehicleColour ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.vehicleColour}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonstyles.col10, styles.upload)}>
          <div className={classNames(commonstyles.flxCenter)}>
            <img src={Uplaod} className={styles.imgs} alt="Upload" />
          </div>
          <div
            className={classNames(
              commonstyles.fs14,
              styles.mb16,
              commonstyles.medium,
              styles.justifycenter
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PropertyPhotoImgPicker
              setData={(images: string[]) => {
                addImages(images);

                // formik.validateField("vehicleImages");
              }}
            />
          </div>

          <div
            className={styles.center}
            style={{
              marginBottom: "12px",
            }}
          >
            {formik.values.vehicleImages.map((imageUrl, index) => (
              <div
                key={index}
                className={styles.imageWrapper}
                style={{ position: "relative" }}
              >
                <RxCrossCircled
                  className={styles.editIcon}
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    color: "red",
                    position: "absolute",
                    top: "-1px",
                    right: "2px",
                    cursor: "pointer",
                    zIndex: 10,
                  }}
                />
                <img
                  src={imageUrl}
                  className={styles.Picss}
                  alt={`Vehicle ${index}`}
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                />
              </div>
            ))}
          </div>

          {errors?.vehicleImages && errors?.vehicleImages ? (
            <div className={classNames(commonstyles.error)}>
              *{errors.vehicleImages}
            </div>
          ) : null}
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "32px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              width: "234px",
            }}
          >
            <PrimaryButton
              children={
                loading ? <RingLoader size={35} color={"#fff"} /> : "Submit"
              }
              colorType={"blue"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

// interface Props {
//   handleClickNext: any;
//   setAddData: any;
//   vahicledetails?: VahicleDetailss | null;
// }
// const Vehicle = (props: Partial<Props>) => {
//   const [selectedStay, setSelectedStay] = useState<string | null>(null);

//   const handleSelect = (selectedOption: string) => {
//
//     formik.setFieldValue("vahicleType", selectedOption);
//     setSelectedStay(selectedOption);
//   };
//   const { handleClickNext, setAddData, vahicledetails } = props;
//   const formik = useFormik({
//     initialValues: {
//       vahicleType: vahicledetails?.vehicleType || "",
//       vehicleName: vahicledetails?.vehicleName || "",
//       vehicleModal: vahicledetails?.vehicleModel || "",
//       vehicleYear: vahicledetails?.vehicleYear || "",
//       vehicleColour: vahicledetails?.vehicleColour || "",
//       vehicleVIN: vahicledetails?.vehicleVinNo || "",
//       vehicleRegistrationNum: vahicledetails?.vehicleRegisterationNo || "",
//       vahicleRegistrationDate: vahicledetails?.vehicleRegisterationDate || "",
//     },
//     validationSchema: Yup.object(rentACarAddVahicleSchema),
//     onSubmit: (values) => {
//       handleSubmit();
//     },
//   });
//   const handleSubmit = () => {
//     const currentData = formik.values;
//     handleClickNext();
//     setAddData({
//       vehicleType: currentData.vahicleType,
//       vehicleName: currentData.vehicleName,
//       vehicleModel: currentData.vehicleModal,
//       vehicleYear: currentData.vehicleYear,
//       vehicleColour: currentData.vehicleColour,
//       vehicleVinNo: currentData.vehicleVIN,
//       vehicleRegisterationNo: currentData.vehicleRegistrationNum,
//       vehicleRegisterationDate: currentData.vahicleRegistrationDate,
//     });
//   };
//   return (
//     <div>
//       <form onSubmit={formik.handleSubmit}>
//         <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
//           <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
//             <CustomSelect
//               onSelect={handleSelect}
//               options={Vehicletype}
//               value={formik.values.vahicleType}
//               placeholder="Vahicle"
//             />
//             {formik.touched.vahicleType && formik.errors.vahicleType ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vahicleType}
//               </div>
//             ) : null}
//           </div>
//           <div className={commonstyles.col6}>
//             <CustomInput
//               placeholder="Vehicle Name "
//               id="vehicleName"
//               name="vehicleName"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleName}
//             />

//             {formik.touched.vehicleName && formik.errors.vehicleName ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleName}
//               </div>
//             ) : null}
//           </div>
//         </div>
//         <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
//           <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
//             <CustomInput
//               placeholder="Vehicle Model "
//               id="vehicleModal"
//               name="vehicleModal"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleModal}
//             />

//             {formik.touched.vehicleModal && formik.errors.vehicleModal ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleModal}
//               </div>
//             ) : null}
//           </div>
//           <div className={commonstyles.col6}>
//             <CustomInput
//               placeholder="Vehicle Year"
//               id="vehicleYear"
//               name="vehicleYear"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleYear}
//             />

//             {formik.touched.vehicleYear && formik.errors.vehicleYear ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleYear}
//               </div>
//             ) : null}
//           </div>
//         </div>
//         <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
//           <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
//             <CustomInput
//               placeholder="Vehicle Colour"
//               id="vehicleColour"
//               name="vehicleColour"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleColour}
//             />

//             {formik.touched.vehicleColour && formik.errors.vehicleColour ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleColour}
//               </div>
//             ) : null}
//           </div>
//           <div className={commonstyles.col6}>
//             <CustomInput
//               placeholder="VIN"
//               id="vehicleVIN"
//               name="vehicleVIN"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleVIN}
//             />

//             {formik.touched.vehicleVIN && formik.errors.vehicleVIN ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleVIN}
//               </div>
//             ) : null}
//           </div>
//         </div>
//         <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
//           <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
//             <CustomInput
//               placeholder="Registration Number"
//               id="vehicleRegistrationNum"
//               name="vehicleRegistrationNum"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vehicleRegistrationNum}
//             />

//             {formik.touched.vehicleRegistrationNum &&
//             formik.errors.vehicleRegistrationNum ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vehicleRegistrationNum}
//               </div>
//             ) : null}
//           </div>
//           <div className={commonstyles.col6}>
//             <CustomInput
//               placeholder="Registration Date"
//               id="vahicleRegistrationDate"
//               name="vahicleRegistrationDate"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.vahicleRegistrationDate}
//             />

//             {formik.touched.vahicleRegistrationDate &&
//             formik.errors.vahicleRegistrationDate ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.vahicleRegistrationDate}
//               </div>
//             ) : null}
//           </div>
//         </div>
//         <div style={{ width: "204px", marginTop: "32px" }}>
//           <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
//         </div>
//       </form>
//     </div>
//   );
// };

// interface Props {
//   handleClickNext: any;
//   addData: any;
//   setShowEditModel: any;
//   vahicle: any;
//   setVahicle: any;
//   vahicledetails?: VahicleDetailss | null;
//   VahicleDetail?: any;
// }
// const Price = (props: Partial<Props>) => {
//   const [loading, setLoading] = useState(false);
//   const handleSelect = (selectedOption: string) => {
//
//   };
//   const {
//     handleClickNext,
//     vahicle,
//     addData,
//     setShowEditModel,
//     setVahicle,
//     vahicledetails,
//     VahicleDetail,
//   } = props;
//   const ID = vahicledetails?._id || "";
//   const formik = useFormik({
//     initialValues: {
//       actualPrice: vahicledetails?.actualPricePerHour || "",
//       priceForMediTour: vahicledetails?.meditourPricePerHour || "",
//     },
//     validationSchema: Yup.object(rentCarPriceSchema),
//     onSubmit: (values) => {
//       handleSubmit();
//     },
//   });
//   const handleSubmit = async () => {
//
//     const curr_data = formik.values;
//     handleClickNext();
//     setLoading(true);
//     let tempVahicle = Array.isArray(vahicle) ? [...vahicle] : [];

//     let params = {
//       ...addData,
//       actualPricePerHour: curr_data.actualPrice,
//       meditourPricePerHour: curr_data.priceForMediTour,
//     };
//     rentCarVahicleEdit(ID || "", params)
//       .then((res: any) => {
//         handleClickNext();
//         if (res.status === 200) {
//
//           VahicleDetail();
//         }
//         setShowEditModel(false);
//       })
//       .catch((err: any) => {
//
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   return (
//     <div>
//       <form onSubmit={formik.handleSubmit}>
//         <div className={classNames(commonstyles.flx, commonstyles.mb28)}>
//           <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
//             <CustomInput
//               placeholder="Actual Price Per hr"
//               id="actualPrice"
//               name="actualPrice"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.actualPrice}
//             />
//             {formik.touched.actualPrice && formik.errors.actualPrice ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.actualPrice}
//               </div>
//             ) : null}
//           </div>
//           <div className={commonstyles.col6}>
//             <CustomInput
//               placeholder="Price For Meditour pr hr"
//               id="priceForMediTour"
//               name="priceForMediTour"
//               type="text"
//               onChange={formik.handleChange}
//               value={formik.values.priceForMediTour}
//             />
//             {formik.touched.priceForMediTour &&
//             formik.errors.priceForMediTour ? (
//               <div className={classNames(commonstyles.error)}>
//                 *{formik.errors.priceForMediTour}
//               </div>
//             ) : null}
//           </div>
//         </div>
//         <div style={{ width: "204px", marginTop: "32px" }}>
//           <PrimaryButton children={"Add"} colorType={"blue"} type="submit" />
//         </div>
//       </form>
//     </div>
//   );
// };
