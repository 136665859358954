import PaymentNavbar from "pages/Home/HomeNavBar/NavBarr/PaymentNavbar";
import React from "react";
import { Routes, Route } from "react-router-dom";
import New_Payment from "shared/components/A_New_Components/NewPayment";

const PaymentNavbarRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<New_Payment />} /> */}
      <Route path="/" element={<PaymentNavbar />} />
    </Routes>
  );
};

export default PaymentNavbarRoutes;
