import React, { useState } from "react";
import styles from "./Appointment.module.css";
import Footerr from "../Footer";
import { useLocation } from "react-router-dom";
import DownloadImagelogo from "../../../../assets/images/DownloadImagelogo.png";
import { useDispatch, useSelector } from "react-redux";
import Checkout from "shared/services/stripe/checkout";
import { setPaymentParams } from "shared/redux";
import toast from "react-hot-toast";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { MY_APPOINTMENT_DETAILS } from "shared/utils/mainHeaderQuery";
import dayjs from "dayjs";

const MyAppointmentDetail = React.memo((props) => {
  const [stripeOpen, setStripeOpen] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user } = useSelector((state: any) => state?.root?.common);

  let localGateway = state?.data?.gatewayName === "blinq" ? true : false;
  let minusPartial_PrcessFee = state?.data?.paidByUserAmount?.toFixed(2);
  let processingFee = state?.data?.processingFee?.toFixed(2);

  let minus_ProcessingFee_InRemainingfAmount =
    minusPartial_PrcessFee - processingFee;
  let remainingAmount = Number(
    state?.data?.totalAmount - minus_ProcessingFee_InRemainingfAmount
  );

  let totalAmount_withFee: number = Number(
    state?.data?.processingFee + remainingAmount
  );

  let total = localGateway
    ? `PKR ${remainingAmount}`
    : totalAmount_withFee?.toFixed(2);

  const handlePayment = async () => {
    if (state?.data?.gatewayName == "stripe") {
      await dispatch(
        setPaymentParams({
          appointmentId: state?.data?._id,
          paidByUserAmount: totalAmount_withFee,
          processingFee: state?.data?.processingFee?.toFixed(2),
          pendingPayment: remainingAmount?.toFixed(2),
        })
      );
      setStripeOpen(true);
    } else {
      toast.error("Payment with Pakistan is coming Soon");
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...MY_APPOINTMENT_DETAILS(stripeOpen)} />
      <div style={{ marginTop: "40px" }}></div>

      {stripeOpen ? (
        <Checkout
          serviceName={"remainingDoctor"}
          convertedAmount={totalAmount_withFee}
          remainingAmount={remainingAmount?.toFixed(2)}
        />
      ) : (
        <div className={styles.containerDetail}>
          <div className={styles.appointmentSectionDetail}>
            <h1 className={styles.headingMainDetail}>Your Appointment ID </h1>
            <span className={styles.appointmentIdDetail}>APT1234</span>
            <div className={styles.dashedLineDetail}></div>
            <div className={styles.infoDetail}>
              <div className={styles.rowDetail}>Patient: {user?.name}</div>
              <div className={styles.rowDetail}>
                Doctor: {state?.data?.doctorId?.name}
              </div>
              <div className={styles.rowDetail}>
                Clinic: {state?.data?.doctorId?.clinicName}
              </div>
              <div className={styles.rowDetail}>
                Address: {state?.data?.doctorId?.location?.address}
              </div>
              <div className={styles.rowDetail}>
                Date:{" "}
                {dayjs(state?.data?.appointmentDateAndTime).format(
                  "DD/MM/YYYY, hh:mm A"
                )}
              </div>
              <div className={styles.rowDetail}>
                Payment:{" "}
                <span
                  style={{
                    color: state?.data?.isPaidFull == false ? "red" : "green",
                    marginLeft: "5px",
                  }}
                >
                  {state?.data?.isPaidFull == false
                    ? "Outstanding"
                    : "Completed"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.paymentSectionDetail}>
            {state?.data?.isPaidFull == false && (
              <>
                <div className={styles.dashedLineDetailPayment}></div>
                <h2 className={styles.paymentHeading}>Payment</h2>
                <div className={styles.paymentInfoDetail}>
                  <div className={styles.rowDetailAmount}>
                    <span>Due Date:</span>
                    <span className={styles.amountRightAmount}>
                      {dayjs(state?.data?.appointmentDateAndTime).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>

                  <div className={styles.rowDetail}>
                    <span>Total Amount:</span>
                    <span className={styles.amountRight}>
                      {`${localGateway ? "PKR" : "$"
                        } ${state?.data?.totalAmount?.toFixed(2)}`}
                    </span>
                  </div>

                  <div className={styles.rowDetail}>
                    <span>Partial Amount:</span>
                    <span className={styles.amountRight}>
                      {`${localGateway ? "PKR" : "$"
                        } ${minus_ProcessingFee_InRemainingfAmount?.toFixed(2)}`}
                    </span>
                  </div>
                  <div className={styles.rowDetail}>
                    <span>Remaining Amount:</span>
                    <span className={styles.amountRight}>
                      {localGateway
                        ? `PKR ${remainingAmount}`
                        : `$ ${remainingAmount?.toFixed(2)}`}
                    </span>
                  </div>
                  {!localGateway && (
                    <>
                      <div className={styles.rowDetailAmount}>
                        <span> Processing Fee:</span>
                        <span
                          className={styles.amountRightAmount}
                        >{`$ ${state?.data?.processingFee?.toFixed(2)}`}</span>
                      </div>
                      <div className={styles.rowDetailAmount}>
                        <span> Payable Amount:</span>
                        <span className={styles.amountRightAmount}>
                          {`${"$" + " " + total}`}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div>
              <button
                className={styles.paymentButtonDetail}
                style={{
                  backgroundColor:
                    state?.data?.isPaidFull == false ? "#0056b3" : "green",
                }}
                disabled={state?.data?.isPaidFull == false ? false : true}
                onClick={handlePayment}
              >
                {state?.data?.isPaidFull == false ? "Payment" : "Completed"}

                {state?.data?.isPaidFull == false && (
                  <img
                    src={DownloadImagelogo}
                    alt="Logo"
                    className={styles.downloadButtonLogo}
                  />
                )}
              </button>
              {/* <PaymentMyAppointMent
                isOpen={isModalOpen}
                onClose={handlePayment}
              /> */}
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "40px" }}></div>
      <Footerr />
    </div>
  );
});

export default MyAppointmentDetail;
