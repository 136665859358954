import { DoctorForgetPassword, DoctorLogin, DoctorSignup, DoctorUpdatePassword } from "pages";
import ResetPassword from "pages/Home/Userlogin/ResetPassword";
import TravelagencyForget from "pages/TravelTourism/Travel agency/Forgetpassword/TravelagencyForget";
import TravelagencyUpdatepass from "pages/TravelTourism/Travel agency/Forgetpassword/TravelagencyUpdatepass";
import AgencyLogin from "pages/TravelTourism/Travel agency/Login/Index";
import AgencySignup from "pages/TravelTourism/Travel agency/Signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const TravelAgencyauth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<DoctorLogin />} />
      <Route path="/signup" element={<DoctorSignup />} />
      <Route path="/ResetPassword" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<DoctorForgetPassword />} />
      <Route path="/update-password" element={<DoctorUpdatePassword />} />
    </Routes>
  );
};

export default TravelAgencyauth;
