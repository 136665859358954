import React from "react";
import { Step, StepLabel, Stepper, Box } from "@mui/material";
import styles from "./stepper.module.css";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";


interface Props {
  steps: any;
  selectedStep: number;
}
const CustomStepper = (props: Partial<Props>) => {
  const { steps, selectedStep } = props;

  return (
    <Box sx={{ width: "100%", marginLeft: "20px" }}>
      <Stepper
        activeStep={selectedStep}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& .MuiStepIcon-root.Mui-completed": {
            color: "green",
          },
          "& .MuiStepIcon-root.Mui-active": {
            color: "#00276d",
          },
          "& .MuiStep-root": {
            //   marginRight: "20px",
            marginBottom: "5px",
          },
        }}
        alternativeLabel
      >
        {steps.map((obj: any) => (
          <Step sx={{ fontFamily: '"Poppins", sans-serif' }} key={obj.id}>
            <StepLabel color="red">{obj.lable}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
