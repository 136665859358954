import React from "react";
import classNames from "classnames";
import style from "./DonationPayment.module.css";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";

const DonationPayment = () => {

  const { user, systemType } = useSelector((state: any) => state.root.common);
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <Vendor_Payments type={"Donation Company"} id={user?._id} />
    </div>
  );
};

export default DonationPayment;
