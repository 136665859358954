import React, { useEffect, useRef, useState } from "react";
import style from "./manageHotel.module.css";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setDoctorFormData } from "shared/redux";
import { useNavigate } from "react-router-dom";
import {
  acceptRequestCompany,
  add_hotel_branch,
  get_hotel_branches,
  getNewList,
  hotelList,
  rejectCaseCompany,
  upload_multi_files,
} from "shared/services";
import {
  CustomModal,
  GreenCard,
  InputField,
  RingLoader,
} from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
import toast from "react-hot-toast";

import {
  notifyError,
  notifySuccess,
} from "shared/components/A_New_Components/ToastNotification";
import Picker from "assets/images/imagePPicker.png";
import { useFormik } from "formik";
import * as Yup from "yup";

import LocationInput from "shared/components/LocationInput";
import NewMultiSelector from "shared/components/A_New_Components/NewMultiSelector";
import { features } from "process";
import { IoMdClose } from "react-icons/io";

const ManageHotels = () => {
  const [addHotel, setAddHotel] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [showModal, setShowModel] = useState(false);
  const [data, setData] = useState<any>([]);
  const [ringLoading, setRingLoading] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [search, setSearch] = useState("");
  const { user } = useSelector((state: any) => state.root.common);
  useEffect(() => {
    dispatch(setDoctorFormData(""));
  }, []);

  useEffect(() => {
    fetchAgencyList();
    fetchList();
  }, []);
  const fetchAgencyList = () => {
    setLoading(true);
    get_hotel_branches()
      .then((res: any) => {
        setList(res?.data?.branches);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchList = () => {
    getNewList("pending", "hotel")
      .then((res: any) => {
        setData(res.data?.data);
      })
      .catch((err: any) => {
        console.log(err, "....error drom ");
      })
      .finally(() => {});
  };

  const acceptCase = () => {
    if (selectedRequest) {
      setRingLoading(true);
      acceptRequestCompany(selectedRequest)
        .then(() => {
          setShowModel(false);
          toast.success("Request accepted successfully!");
          fetchAgencyList();
          fetchList();
        })
        .catch(() => {
          setRingLoading(false);
        })
        .finally(() => {
          setRingLoading(false);
          setSelectedRequest(null);
        });
    }
  };

  const rejectCase = () => {
    if (selectedRequest) {
      setRingLoading(true);
      rejectCaseCompany(selectedRequest)
        .then(() => {
          setShowModel(false);
          toast.success("Request rejected successfully!");
          fetchAgencyList();
          fetchList();
        })
        .catch(() => {
          setRingLoading(false);
        })
        .finally(() => {
          setSelectedRequest(null);
          setIsRejecting(false);
          setRingLoading(false);
        });
    }
  };
  const handleAceept = (id: any) => {
    setSelectedRequest(id);
    setIsRejecting(false);
    setShowModel(true);
  };
  const handleReject = (id: any) => {
    setSelectedRequest(id);
    setIsRejecting(true);
    setShowModel(true);
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const openaddHotel = () => {
    setAddHotel(true);
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.customcontainer}>
        <div className={style.rowSearch}>
          <div className={style.text}>
            {data?.length > 0 ? "New Hotel Request" : "Hotel List"}
          </div>
          {/* <div>
            <SearchFilter
              vender={false}
              search={search}
              title={"Search"}
              setSearch={setSearch}
              handleSearch={handleSearch}
            />
          </div> */}
        </div>
        <div className={style.button} onClick={openaddHotel}>
          <RiAddFill size={24} color={"#fff"} />
          <p className={style.btnText}>Add Hotels</p>
        </div>
      </div>
      {data?.length > 0 &&
        data?.map((d: any, index: number) => (
          <GreenCard
            key={index}
            data={d?.vendorId}
            request={d}
            acceptCase={() => handleAceept(d?._id)}
            rejectCase={() => handleReject(d?._id)}
            type={"request"}
            onclickDetail={() =>
              navigate("/greenFlag/ManageHotels/detail", {
                state: { id: d?._id },
              })
            }
          />
        ))}
      {data?.length > 0 && (
        <div
          className={style.text}
          style={{ marginTop: "32px", marginBottom: "16px" }}
        >
          Hotel List
        </div>
      )}
      {list?.length > 0 ? (
        list?.map((d: any, index: number) => (
          <GreenCard
            key={index}
            data={d}
            onclickDetail={() =>
              navigate("/greenFlag/ManageHotels/detail", {
                state: { id: d?._id },
              })
            }
          />
        ))
      ) : (
        <PhysiotheristsEmpty />
      )}
      <CustomModal showModal={showModal}>
        <div className={style.modalContent}>
          <p>
            Are you sure you want to {isRejecting ? "reject" : "accept"} this
            vendor request?
          </p>
          <div className={style.modalActions}>
            <button
              className={style.cancelButton}
              onClick={() => setShowModel(false)}
            >
              {isRejecting ? "No" : "Cancel"}
            </button>
            <button
              className={style.acceptButton}
              onClick={isRejecting ? rejectCase : acceptCase}
            >
              {ringLoading ? (
                <RingLoader size={35} color={"#fff"} />
              ) : isRejecting ? (
                "Yes"
              ) : (
                "Accept"
              )}
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal showModal={addHotel}>
        <AddHotel setAddHotel={setAddHotel} fetchList={fetchAgencyList} />
      </CustomModal>

      {loading && <CustomLoader />}
    </div>
  );
};

export default ManageHotels;

const AddHotel = ({ setAddHotel, fetchList }: any) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const feacturelist = [
    "Room Service",
    "Restaurant",
    "Internet",
    "Parking",
    "Outdoor",
    "Activities",
  ];

  const validation_Schema = {
    hotelName: Yup.string().required("Name is required"),
    UAN: Yup.string(),
    phone: Yup.string().required("Phone is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    images: Yup.array().min(1, "At least one image is required"),
    lat: Yup.string().required("lat is required"),
    lng: Yup.string().required("lng is required"),
  };
  const formik = useFormik({
    initialValues: {
      hotelName: "",
      UAN: "",
      phone: "",
      address: "",
      city: "",
      images: [],
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object(validation_Schema),
    onSubmit: (values) => {
      console.log("🚀 ~ AddHotel ~ values:", values);
      handleSaveHotel(values);
    },
  });

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      processFiles(Array.from(files));
    }
  };

  const processFiles = async (files: File[]) => {
    console.log("🚀 ~ processFiles ~ files:", files);
    setUploading(true);

    const data: FormData = new FormData();

    // Append each file individually to FormData
    files.forEach((file, index) => {
      data.append("files", file);
    });

    console.log("🚀 ~ processFiles ~ data:", data);

    try {
      const res = await upload_multi_files(data);

      formik.setFieldValue("images", [
        ...formik.values.images,
        ...res?.data?.fileUrls,
      ]);
    } catch (err: any) {
      console.log("🚀 ~ processFiles ~ err:", err?.response?.data);
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      processFiles(Array.from(files));
    }
  };

  const closeaddHotel = () => {
    setAddHotel(false);
  };
  const handleRemoveImage = (index: number) => {
    formik.setFieldValue(
      "images",
      formik.values.images.filter((_: any, i: number) => i !== index)
    );
  };
  const handleSaveHotel = (values: any) => {
    setAddLoading(true);
    let loc = {
      lng: values.lng,
      lat: values.lat,
      address: values.address,
      city: values.city,
    };
    let params: any = {
      propertyName: values.hotelName,
      phone: values.phone,
      uan: values.UAN,
      location: loc,
      images: values.images,
      features: selectedOptions,
    };

    add_hotel_branch(params)
      .then((res) => {
        fetchList();
        notifySuccess("Hotel added successfully!");
        closeaddHotel();
      })
      .catch((err) => {
        console.log("🚀 ~ handleSaveHotel ~ err:", err?.response?.data);
      })
      .finally(() => setAddLoading(false));
  };

  const handleLocation = (location: any) => {
    formik.setFieldValue("address", location.label);
    formik.setFieldValue("city", location.city);
    formik.setFieldValue("state", location.state);
    formik.setFieldValue("lat", location.lat);
    formik.setFieldValue("lng", location.lng);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={style.modalouter}>
        <p className={style.textHeadingModel}>Add a New Hotel</p>
        <InputField
          height={20}
          placeholder="Hotel Name"
          id="hotelName"
          value={formik.values.hotelName}
          onChange={formik?.handleChange("hotelName")}
        />
        {formik.touched.hotelName && formik.errors.hotelName && (
          <p className={style.errorText}>{formik.errors.hotelName}</p>
        )}
        <div className={style.flxBtwn}>
          {" "}
          <div style={{ width: "100%" }}>
            <InputField
              height={20}
              placeholder="UAN"
              id="UAN"
              onChange={formik?.handleChange("UAN")}
              value={formik.values.UAN}
            />
            {formik.touched.UAN && formik.errors.UAN && (
              <p className={style.errorText}>{formik.errors.UAN}</p>
            )}
          </div>{" "}
          <div style={{ width: "100%" }}>
            {" "}
            <InputField
              height={20}
              placeholder="Phone"
              value={formik.values.phone}
              id="phone"
              type="string"
              onChange={formik?.handleChange("phone")}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className={style.errorText}>{formik.errors.phone}</p>
            )}
          </div>
        </div>
        <LocationInput
          type={"box"}
          placeholder={formik.values.address || "Address"}
          setData={handleLocation}
          defaultValue={formik.values.address}
        />

        {formik.touched.address && formik.errors.address && (
          <p className={style.errorText}>{formik.errors.address}</p>
        )}
        <div className={style.flxBtwn}>
          <div style={{ width: "100%" }}>
            <InputField
              height={20}
              placeholder="City"
              value={formik.values.city}
              id="city"
              onChange={formik?.handleChange("city")}
            />
            {formik.touched.city && formik.errors.city && (
              <p className={style.errorText}>{formik.errors.city}</p>
            )}
          </div>{" "}
        </div>
        <div style={{ marginBottom: "16px" }}>
          <label>Features</label>
          <NewMultiSelector
            options={feacturelist}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
        <div>
          <div
            aria-disabled={uploading}
            className={style.imagePicker}
            onClick={handleImageClick}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            style={{ cursor: "pointer" }}
          >
            <img src={Picker} className={style.pickIMAGE} />
            <p className={style.pickerText}>
              <strong style={{ color: "#F69A1D" }}>Upload Photos</strong>
            </p>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>
        </div>
        {formik.touched.images && formik.errors.images && (
          <p className={style.errorText}>{formik.errors.images}</p>
        )}

        <div className={style.selectedImagesContainer}>
          {uploading && <RingLoader />}
          {formik?.values?.images.map((image: any, index: number) => (
            <div className={style.imageWrapper} key={index}>
              <img
                src={image}
                alt={`Selected ${index + 1}`}
                className={style.selectedImages}
              />
              <div
                className={style.outerclose}
                onClick={() => handleRemoveImage(index)}
              >
                <IoMdClose className={style.iconclose} />
              </div>
            </div>
          ))}
        </div>

        <div className={style.flxBtwn}>
          <button className={style.BackBtn} onClick={closeaddHotel}>
            Back
          </button>
          <button type="submit" className={style.SaveBtn} disabled={addLoading}>
            {addLoading ? <RingLoader color="#fff" /> : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};
