export const PlaceHolderProps = (type?: any) => {
  let placeHolderNew = null;
  switch (type) {
    case "labs":
      placeHolderNew = {
        name: "Lab Name",
        logo: "Lab Logo",
        licenseNo: "Lab License Number",
        licenseExpiry: "Lab License Expiry",
        description: "Lab Description",
        openTime: "Lab Open Time",
        closeTime: "Lab Close Time",
        address: "Laboratory Address",
        licenseImage: "Lab License Image",
      };
      break;
    case "pharmacy":
      placeHolderNew = {
        name: "Pharmacy Name",
        logo: "Pharmacy Logo",
        licenseNo: "Pharmacy License Number",
        licenseExpiry: "Pharmacy License Expiry",
        description: "Pharmacy Description",
        openTime: "Pharmacy Open Time",
        closeTime: "Pharmacy Close Time",
        address: "Pharmacy Address",
        licenseImage: "Pharmacy License Image",
      };

      break;
    case "travel":
      placeHolderNew = {
        name: "Company Name",
        logo: "Company Logo",
        licenseNo: "Company License Number",
        licenseExpiry: "License Expiry",
        address: "Company Address",
      };
      break;
    case "hotel":
      placeHolderNew = {
        name: "Hotel Name",
        logo: "Hotel Image",
        licenseNo: "Hotel License Number",
        licenseExpiry: "License Expiry",
        address: "Hotel Address",
        licenseImage: "License Image",
      };
      break;
    default:
      break;
  }

  return {
    placeHolderNew,
  };
};
