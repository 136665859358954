import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./ambulanceRequest.module.css";
import commonStyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import { FaCheckCircle } from "react-icons/fa";
import { ambulanceAddbidSchema } from "shared/utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  ambulanceADDBIDREQUEST,
  getAmbulanceGETSINGLEREQUEST,
} from "shared/services/Ambulance";
import { IoArrowBack } from "react-icons/io5";
import { setAmbulanceRequestFlag } from "shared/redux";
import { useDispatch } from "react-redux";
import CustomLoader from "shared/components/New_Loader/Loader";

interface RecentRequest {
  dropOff: {
    address: string;
  };
  pickUp: {
    address: string;
  };
  userId: {
    name: string;
    phone: string;
  };
}
interface BID {
  ambulanceName: string;
  ambulanceNo: string;
  price: number;
}

export default function RequestDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [acceptmodal, setAcceptModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [request, setRequest] = useState<RecentRequest | null>(null);
  const [bid, setBid] = useState<BID | null>(null);

  const handleOpenModel = () => {
    setAcceptModal(true);
    setTimeout(() => {
      setAcceptModal(false);
    }, 1000);
  };

  const handleaddDetail = () => {
    setShowDetail(!showDetail);
  };
  const handleGoback = () => {
    navigate("/ambulance/request");
  };

  const fetchSingle = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      getAmbulanceGETSINGLEREQUEST(id)
        .then((res: any) => {
          setRequest(res?.data?.userRequest);
          setBid(res?.data?.bid);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      ambulancename: "",
      ambulanceno: "",
      price: "",
    },
    validationSchema: Yup.object(ambulanceAddbidSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    let currentData = formik.values;
    let params = {
      requestId: id,
      ambulanceName: currentData.ambulancename,
      ambulanceNo: currentData.ambulanceno,
      price: currentData.price,
    };

    ambulanceADDBIDREQUEST(params)
      .then((res: any) => {
        handleOpenModel();
        dispatch(setAmbulanceRequestFlag(true));
        setTimeout(() => {
          navigate("/ambulance/request");
        }, 1020);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSingle();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      {loading ? (
        <CustomLoader />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={commonstyles.outerContainer}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoback} />
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                User
              </p>
            </div>
            <div className={style.detailCard}>
              <div className={classNames(commonstyles.flx, style.mt16)}>
                <div className={classNames(commonstyles.fs12)}>
                  <p className={commonstyles.colorBlue}>Name</p>
                  <p>{request?.userId?.name}</p>
                </div>
                <div className={classNames(commonstyles.fs12, style.contact)}>
                  <p className={commonstyles.colorBlue}>Contact</p>
                  <p>{request?.userId?.phone}</p>
                </div>
              </div>
              <div className={classNames(style.mt16, commonstyles.fs12)}>
                <p className={commonstyles.colorBlue}>Pickup</p>
                <p>{request?.pickUp?.address}</p>
              </div>
              <div className={classNames(style.mt16, commonstyles.fs12)}>
                <p className={commonstyles.colorBlue}>Drop Off</p>
                <p>{request?.dropOff?.address}</p>
              </div>
            </div>

            {bid ? (
              <div>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    commonstyles.colorBlue,
                    style.mt24
                  )}
                >
                  Ambulance
                </p>
                <div className={style.detailCard2}>
                  <div style={{ width: "30%", marginRight: "14px" }}>
                    <p>Ambulance Name</p>
                    <p>{bid?.ambulanceName}</p>
                  </div>
                  <div style={{ width: "30%", marginRight: "14px" }}>
                    <p>Ambulance No</p>
                    <p>{bid?.ambulanceNo}</p>
                  </div>
                  <div style={{ width: "30%", marginRight: "14px" }}>
                    <p>Price</p>
                    <p>{bid?.price}</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    commonstyles.colorBlue,
                    style.mt24
                  )}
                >
                  Ambulance
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <div className={style.detailCard2}>
                    <div style={{ width: "30%", marginRight: "14px" }}>
                      <CustomInput
                        placeholder="Ambulance Name"
                        id="ambulancename"
                        name="ambulancename"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.ambulancename}
                      />
                      {formik.touched.ambulancename &&
                      formik.errors.ambulancename ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.ambulancename}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ width: "30%", marginRight: "14px" }}>
                      <CustomInput
                        placeholder="Ambulance No"
                        id="ambulanceno"
                        name="ambulanceno"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.ambulanceno}
                      />
                      {formik.touched.ambulanceno &&
                      formik.errors.ambulanceno ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.ambulanceno}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ width: "30%", marginRight: "14px" }}>
                      <CustomInput
                        placeholder="Price"
                        id="price"
                        name="price"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.price}
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <div className={classNames(commonStyles.error)}>
                          *{formik.errors.price}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "210px",
                      marginTop: "32px",
                      marginLeft: "auto",
                    }}
                  >
                    <PrimaryButton
                      children={"Bid"}
                      colorType={"green"}
                      type="submit"
                    />
                  </div>
                </form>

                <CustomModal showModal={acceptmodal}>
                  <div style={{ width: "420px" }}>
                    <FaCheckCircle className={style.done} />
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold,
                        commonstyles.colorBlue,
                        style.textcenter
                      )}
                    >
                      User Bid has been successfully booked.
                    </p>
                  </div>
                </CustomModal>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
