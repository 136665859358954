import React, { useEffect, useState } from "react";
import style from "./manage.module.css";
import { RiAddFill } from "react-icons/ri";
import LoginModel from "shared/components/HospitalBranch";
import { useDispatch, useSelector } from "react-redux";
import { setDoctorFormData } from "shared/redux";
import { CustomModal, GreenCard, RingLoader } from "shared/components";
import { useNavigate } from "react-router-dom";
import {
  acceptRequestCompany,
  getAgencyList,
  getNewList,
  rejectCaseCompany,
} from "shared/services";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
import toast from "react-hot-toast";
import { LuSearch } from "react-icons/lu";
import { notifyError } from "shared/components/A_New_Components/ToastNotification";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
const ManageTravelAgency = () => {
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ringLoading, setRingLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [isRejecting, setIsRejecting] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const { user } = useSelector((state: any) => state.root.common);
  useEffect(() => {
    dispatch(setDoctorFormData(""));
  }, []);

  useEffect(() => {
    fetchList();
    fetchAgencyList("");
  }, []);
  const fetchAgencyList = (searchText: any) => {
    setLoading(true);
    getAgencyList(searchText)
      .then((res) => {
        console.log(res?.data?.agencies);
        setList(res?.data?.agencies);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchList = () => {
    getNewList("pending", "agency")
      .then((res: any) => {
        console.log(res?.data, ".......daattatxxxx");
        setData(res.data?.data);
      })
      .catch((err: any) => {
        console.log(err, "....error drom ");
      })
      .finally(() => {});
  };

  const acceptCase = () => {
    if (selectedRequest) {
      setRingLoading(true);
      acceptRequestCompany(selectedRequest)
        .then(() => {
          setShowModel(false);
          toast.success("Request accepted successfully!");
          fetchList();
          fetchAgencyList("");
        })
        .catch((err: any) => {
          console.log(err, "...error");
          setRingLoading(false);
        })
        .finally(() => {
          setSelectedRequest(null);
          setRingLoading(false);
        });
    } else {
      toast.error("No selected Id");
    }
  };

  const rejectCase = () => {
    if (selectedRequest) {
      setRingLoading(true);
      rejectCaseCompany(selectedRequest)
        .then(() => {
          setShowModel(false);
          toast.success("Request rejected successfully!");
          fetchList();
          fetchAgencyList("");
        })
        .catch((err: any) => {
          setRingLoading(false);
          console.log(err, "..error");
        })
        .finally(() => {
          setSelectedRequest(null);
          setIsRejecting(false);
          setRingLoading(false);
        });
    } else {
      toast.error("No selected Id");
    }
  };
  const handleAceept = (id: any) => {
    console.log(id, "....");
    setSelectedRequest(id);
    setIsRejecting(false);
    setShowModel(true);
  };
  const handleReject = (id: any) => {
    setSelectedRequest(id);
    setIsRejecting(true);
    setShowModel(true);
  };
  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const handleSearch = () => {
    fetchAgencyList(search);
  };
  const openFase = () => {
    if (user?.paidActivation === false) {
      notifyError("Please pay the activation fee to activate your account!");
      return;
    }
    setOpenModel(true);
  };
  return (
    <div className={style.mainContainer}>
      <div className={style.customcontainer}>
        <div className={style.rowSearch}>
          <div className={style.text}>
            {data?.length > 0 ? "New Agencies Request" : "Travel Agencies List"}
          </div>

          <div>
            <SearchFilter
              vender={false}
              search={search}
              title={"Search"}
              setSearch={setSearch}
              handleSearch={handleSearch}
            />
          </div>
        </div>

        <div className={style.button} onClick={openFase}>
          <RiAddFill size={24} color={"#fff"} /> Add
        </div>
      </div>
      {data?.length > 0 &&
        data?.map((data: any) => (
          <GreenCard
            data={data?.vendorId}
            request={data}
            acceptCase={() => handleAceept(data?._id)}
            rejectCase={() => handleReject(data?._id)}
            type={"request"}
            tourRequestType={true}
          />
        ))}
      {data?.length > 0 && (
        <div
          className={style.text}
          style={{ marginTop: "32px", marginBottom: "16px" }}
        >
          Travel Agencies List
        </div>
      )}
      {list?.length > 0 ? (
        list?.map((data: any) => (
          <GreenCard
            data={data}
            type={"travel"}
            onclickDetail={() =>
              navigate("/greenFlag/ManageTravelAgency/detail", {
                state: { id: data?._id },
              })
            }
          />
        ))
      ) : (
        <PhysiotheristsEmpty />
      )}

      <CustomModal showModal={showModal}>
        <div className={style.modalContent}>
          <p>
            Are you sure you want to {isRejecting ? "reject" : "accept"} this
            vendor request?
          </p>
          <div className={style.modalActions}>
            <button
              className={style.cancelButton}
              onClick={() => setShowModel(false)}
            >
              {isRejecting ? "No" : "Cancel"}
            </button>
            <button
              className={style.acceptButton}
              onClick={isRejecting ? rejectCase : acceptCase}
            >
              {ringLoading ? (
                <RingLoader size={35} color={"#fff"} />
              ) : isRejecting ? (
                "Yes"
              ) : (
                "Accept"
              )}
            </button>
          </div>
        </div>
      </CustomModal>
      <LoginModel
        setOpen={setOpenModel}
        type={"travel"}
        showModal={openModel}
        hitApi={fetchAgencyList}
      />
      {loading && <CustomLoader />}
    </div>
  );
};

export default ManageTravelAgency;
