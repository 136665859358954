import React from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import style from "./ambulancePayment.module.css";
import commonstyles from "shared/utils/common.module.css";
import { Vendor_Payments } from "shared/components";
import { useSelector } from "react-redux";

const AmbulancePayment = () => {
  const { ambulance } = useSelector((state: any) => state.root.ambulance);

  const { user, systemType } = useSelector((state: any) => state.root.common);
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <Vendor_Payments type={"Ambulance Company"} id={user?._id} />
    </div>
  );
};
export default AmbulancePayment;
