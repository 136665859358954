import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import "./shareTicket.css";
import CustomLoader from "shared/components/New_Loader/Loader";
import paper from "assets/images/upload.png";
import del from "assets/images/del.png";
import { add_File } from "shared/services";
import { fetchTravel, pushTicket_To } from "shared/services/TravelAgency";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton, TravelerInformation } from "shared/components";
import toast from "react-hot-toast";
import {
  notifyError,
  notifySuccess,
} from "shared/components/A_New_Components/ToastNotification";
const TravelTicketshare = () => {
  const [url, setUrl] = useState<any>("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [travelers, setTraveler] = useState([]);
  const { state } = useLocation();
  const [dataName, setName] = useState<any>("");
  const navigate = useNavigate();
  const val = state?.data;
  const onUploadImage = (event: any) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      add_File(formData)
        .then((response: any) => {
          setName(file.name);
          setUrl(response.data.fileUrl);
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    funGetTravels();
  }, []);
  const funGetTravels = () => {
    const data = {
      bidRequestId: val?.bidIds?.[0]?._id,
    };
    fetchTravel(data)
      .then((res: any) => {
        setTraveler(res?.data?.travellers);
      })

      .catch((err: any) => {})
      .finally(() => {});
  };
  const deleteFile = () => {
    setUrl(null);
    setName(null);
  };

  const handleDownload = (file: string | Blob | null) => {
    if (file) {
      const url = typeof file === "string" ? file : URL.createObjectURL(file);

      window.open(url, "_blank");

      if (typeof file !== "string") {
        URL.revokeObjectURL(url);
      }
    } else {
      alert("No policy document available.");
    }
  };

  const handleDownloading = (file: string | Blob | null) => {
    if (file) {
      const url = typeof file === "string" ? file : URL.createObjectURL(file);

      window.open(url, "_blank");

      if (typeof file !== "string") {
        URL.revokeObjectURL(url);
      }
    } else {
      alert("No policy document available.");
    }
  };

  const submitFile = async () => {
    if (!url) {
      // toast.error("Please upload your ticket.");
      notifyError("Please upload your ticket");
      // setError("Please Upload Ticket");
      return;
    }
    setLoading(true);

    try {
      const data = {
        bidRequestId: val?.bidIds?.[0]?._id,
      };
      const params = {
        eTicket: url,
      };
      const response = await pushTicket_To(data, params);

      setLoading(false);

      // Show success message
      // toast.success("Your file has been submitted successfully!");
      notifySuccess("Your file has been submitted successfully!");

      // Navigate to ticket requests
      navigate("/travelAgency/ticketRequests");
    } catch (error) {
      setLoading(false);
      console.error("Error uploading file:", error);

      // Show error message using toast
      toast.error(
        "There was an error submitting the file. Please try again later."
      );
    }
  };
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div style={{ flex: 1, paddingBottom: "100px" }}>
        <p className="label">E-Ticket</p>
        <p> To proceed, please upload tickets of all Passenger.</p>
        <div>
          <div className="parentContainer">
            {!url && (
              <label className="uploadContainer">
                <img src={paper} className="uploadImage" alt="Upload" />
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: "SFmedium",
                    color: "#0000FF",
                  }}
                >
                  <span style={{ color: "#FF7631" }}>Choose file</span> to
                  upload
                </span>
                <span
                  style={{
                    marginLeft: "8px",
                    color: "#00276D",
                    fontSize: "12px",
                  }}
                >
                  Select jpeg, png, or pdf up to 20MB.
                </span>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={onUploadImage}
                />
              </label>
            )}
          </div>
          <div className="parentContainer">
            {url && (
              <div
                className="fileContainer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "10px" }}>{dataName}</span>
                <button onClick={deleteFile} className="deleteButton">
                  <img src={del} className="deleteIcon" alt="Delete" />
                </button>
              </div>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
          <div className="travelerInfo">
            <p>Traveler Information</p>
            <div className="mainView">
              {travelers.map((traveler: any, index: any) => (
                <TravelerInformation
                  traveler={traveler}
                  index={index}
                  onPressVise={() => handleDownload(traveler.visaFile)}
                  onPressPass={() => handleDownloading(traveler.passportFile)}
                />
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "56px", width: "210px" }}>
              <PrimaryButton
                onClick={submitFile}
                colorType={"Linear"}
                type={"button"}
                // disabled={!url}
              >
                Share Ticket
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default TravelTicketshare;
