import classNames from "classnames";
import cardStyle from "./highlightCard.module.css";
import { FaChevronRight } from "react-icons/fa";

const ServiceHighlightCard = ({
  data,
  onPress,
}: {
  onPress?: any;
  data?: any;
}) => {
  const emptyData = [
    {
      title: "Title ",
      content: "content.........",
      color: "#FF8A8A",
      icon: "",
      coverImg: "",
      infoBg: "",
      BgColor: "",
    },
  ];
  const Data = data ? data : emptyData;

  return (
    <>
      <div className={cardStyle.cardContainer}>
        {Data.map((card: any, index: any) => (
          <>
            <div
              key={index}
              onClick={() => onPress(card?.title)}
              className={classNames(cardStyle.card)}
            >
              <div className={cardStyle.h75}></div>
              <div
                className={cardStyle.cardCover}
                style={{
                  backgroundColor: card.color ? card.color : "white",
                }}
              >
                <div
                  className={cardStyle.outerCoverImg}
                  style={{ top: card.top }}
                >
                  <img src={card.coverImg} className={cardStyle.coverImg} />
                </div>
              </div>
              <div>
                <div
                  className={cardStyle.cardInfo}
                  style={{
                    backgroundColor: card.BgColor ? card.BgColor : "#e9f4f4",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <img src={card.icon} className={cardStyle.icon} />{" "}
                    <h3>{card.title}</h3>
                  </div>

                  <p className={cardStyle.CardTexxt}>{card.content}</p>
                  <div style={{ marginLeft: "auto" }}>
                    <div
                      className={cardStyle.navigationIcon}
                      style={{
                        backgroundColor: card.color ? card.color : "white",
                      }}
                    >
                      <FaChevronRight className={cardStyle.NavIcon} />
                    </div>
                  </div>
                  <img src={card.infoBg} className={cardStyle.infoBg} />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default ServiceHighlightCard;
