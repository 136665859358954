import React, { useEffect, useRef, useState } from "react";
import style from "./QuestionnaireForm.module.css";
import mediLogo from "assets/images/logoMeditour.png";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Radio } from "@mui/material";
import { Checkbox } from "@mui/material";
import { CustomModal } from "shared/components";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import * as Yup from "yup";
import { IoLogoTiktok } from "react-icons/io5";
import { add_File, addForm } from "shared/services";
import CustomLoader from "shared/components/New_Loader/Loader";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
import { useFormik } from "formik";
import done from "assets/images/sucessDone.png";
const area = [
  { id: 1, title: "Healthcare" },
  { id: 2, title: "Business" },
  { id: 3, title: "Marketing" },
  { id: 4, title: "IT(Information Technology)" },
  { id: 5, title: "Media" },
  { id: 6, title: "Social Work" },
  { id: 7, title: "Other" },
];
const title = [
  { id: 1, title: "First Year" },
  { id: 2, title: "Second Year" },
  { id: 3, title: "Third Year" },
  { id: 4, title: "Fourth Year" },
  { id: 5, title: "Graduate" },
  { id: 6, title: "Other" },
];
const prefer = [
  { id: 1, title: "Whatsapp" },
  { id: 2, title: "Email" },
  { id: 3, title: "Call" },
  { id: 4, title: "Social Media" },
];
const marketing = [
  { id: 1, title: "Marketing & Social Media" },
  { id: 2, title: "Business Development & Sales" },
  { id: 3, title: "Healthcare & Patient Support" },
  { id: 4, title: "IT & App Development" },
  { id: 5, title: "Graphic Design & Content Creation" },
  { id: 6, title: "Social Work" },
  { id: 7, title: "Other (please specify)" },
];
const Questionnaire = () => {
  const [loading, setLoading] = useState<any>(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [showModal, SetShowModal] = useState(false);
  const onUploadImage = (event: any) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      add_File(formData)
        .then((response: any) => {
          setName(file.name);
          setUrl(response.data.fileUrl);
          notifySuccess("Uploaded Successfully");
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      contactNumber: "",
      email: "",
      department: "",
      currentYearOfStudy: "",
      areaOfInterest: "",
      medicalTourismHeardBefore: "",
      medicalTourism: "",
      knowsMediTourGlobal: "",
      opinionOnMedicalTourism: "",
      familyMedicalTravelExperience: "",
      exploreInternationalHospitals: "",
      onlineMedicalServices: "",
      medicalTourismBenefit: "",
      awarenessCampaignInterest: "",
      internshipJobOpportunities: "",
      areaOfWorkingWithMediTour: "",
      careerUpdatesSubscription: "",
      skillsContribution: "",
      volunteerForCampaign: "",
      improvementsSuggestions: "",
      stayConnected: "",
      contactMethod: [] as string[],
      additionalComments: "",
      otherInterestedArea: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      contactNumber: Yup.string().required("Contact Number is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      department: Yup.string().required("Department is required"),
      currentYearOfStudy: Yup.string().required(
        "Current Year of Study is required"
      ),
      areaOfInterest: Yup.string().required("Area of Interest is required"),
      knowsMediTourGlobal: Yup.string().required(
        "Know MediTour Global is required"
      ),
      familyMedicalTravelExperience: Yup.string().required(
        "Family Medical Travel Experience is required"
      ),
      exploreInternationalHospitals: Yup.string().required(
        "Explore International Hospitals is required"
      ),
      onlineMedicalServices: Yup.string().required(
        "Online Medical Services is required"
      ),
      medicalTourismBenefit: Yup.string().required(
        "Medical Tourism Benefit is required"
      ),
      awarenessCampaignInterest: Yup.string().required(
        "Awareness Campaign Interest is required"
      ),
      internshipJobOpportunities: Yup.string().required(
        "Internship/Job Opportunities are required"
      ),
      areaOfWorkingWithMediTour: Yup.string().when(
        "internshipJobOpportunities",
        {
          is: "Yes",
          then: (schema) =>
            schema.required("Area of Working with MediTour is required"),
          otherwise: (schema) => schema.notRequired().nullable(),
        }
      ),
      otherInterestedArea: Yup.string().when(
        ["internshipJobOpportunities", "areaOfWorkingWithMediTour"], 
        {
          is: (internshipJobOpportunities:any, areaOfWorkingWithMediTour:any) =>
            internshipJobOpportunities === "Yes" && areaOfWorkingWithMediTour === "Other (please specify)",
          then: (schema) => schema.required("*Details are required"),
          otherwise: (schema) => schema.notRequired().nullable(),
        }
      ),
      careerUpdatesSubscription: Yup.string().required(
        "Career Updates Subscription is required"
      ),
      skillsContribution: Yup.string().required(
        "Skills Contribution is required"
      ),
      volunteerForCampaign: Yup.string().required(
        "Volunteer for Campaign is required"
      ),
      stayConnected: Yup.string().required("Stay Connected is required"),
      contactMethod: Yup.array().min(
        1,
        "At least one contact method is required"
      ),
    }),
    onSubmit: (values) => {
      questionsAdd(values);
      console.log("Form Submitted", values);
    },
  });
  console.log(
    formik?.values?.internshipJobOpportunities,
    ".....internshipJobOpportunities"
  );
  useEffect(() => {
    formik.validateField("areaOfWorkingWithMediTour");
  }, [formik.values.internshipJobOpportunities]);
  const handleCheckboxChange = (title: string) => {
    const updatedContactMethod = formik.values.contactMethod?.includes(title)
      ? formik.values.contactMethod.filter((item) => item !== title)
      : [...formik.values.contactMethod, title];

    formik.setFieldValue("contactMethod", updatedContactMethod);
  };
  const questionsAdd = (values: any) => {
    setLoading(true);
    let params = {
      fullName: values?.fullName,
      contactNumber: values?.contactNumber?.toString(),
      email: values?.email,
      department: values?.department,
      currentYearOfStudy: values?.currentYearOfStudy,
      areaOfInterest: values?.areaOfInterest,
      medicalTourismHeardBefore:
        values?.medicalTourismHeardBefore === "Yes" ? true : false,
      ...(values?.medicalTourism && { medicalTourism: values?.medicalTourism }),
      knowsMediTourGlobal: values?.knowsMediTourGlobal === "Yes" ? true : false,
      ...(values?.opinionOnMedicalTourism && {
        opinionOnMedicalTourism: values?.opinionOnMedicalTourism,
      }),
      familyMedicalTravelExperience:
        values?.familyMedicalTravelExperience === "Yes" ? true : false,
      exploreInternationalHospitals:
        values?.exploreInternationalHospitals === "Yes" ? true : false,
      onlineMedicalServices:
        values?.onlineMedicalServices === "Yes" ? true : false,
      medicalTourismBenefit:
        values?.medicalTourismBenefit === "Yes" ? true : false,
      awarenessCampaignInterest:
        values?.awarenessCampaignInterest === "Yes" ? true : false,
      internshipJobOpportunities:
        values?.internshipJobOpportunities === "Yes" ? true : false,
      ...(values?.internshipJobOpportunities === "Yes" && {
        areaOfWorkingWithMediTour: values?.areaOfWorkingWithMediTour,
      }),
      careerUpdatesSubscription:
        values?.careerUpdatesSubscription === "Yes" ? true : false,
      skillsContribution: values?.skillsContribution === "Yes" ? true : false,
      volunteerForCampaign:
        values?.volunteerForCampaign === "Yes" ? true : false,
      ...(values.improvementsSuggestions && {
        improvementsSuggestions: values?.improvementsSuggestions,
      }),
      stayConnected: values?.stayConnected === "Yes" ? true : false,
      contactMethod: values?.contactMethod,
      ...(values?.additionalComments && {
        additionalComments: values?.additionalComments,
      }),
      ...(formik?.values.internshipJobOpportunities === "Yes" &&values?.areaOfWorkingWithMediTour === "Other (please specify)" && {
        otherInterestedArea: values?.otherInterestedArea,
      }),
      ...(url && { cvUploadUrl: url }),
    };
    addForm(params)
      .then((res: any) => {
        SetShowModal(true);
        setTimeout(() => {
          SetShowModal(false);
          // notifySuccess(res?.data?.message);
          formik?.resetForm();
          setUrl("");
          setName("")
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err, "...error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={style.container}>
      <div className={style.main}>
        <div className={style.mainContent}>
          <div className={style.logomedi}>
            <img src={mediLogo} className={style.logo} alt="MediTour Logo" />
            <div className={style.heading}>
              <span className={style.mediword}>MediTour </span>is the{" "}
              <span className={style.highlight}>1st</span> Medical Tourism
              company in Pakistan working Globally.
            </div>
          </div>
          <p className={style.description}>
            MediTour Global is Pakistan’s first innovative health & medical
            tourism platform, designed to revolutionize healthcare accessibility
            & bring foreign revenue to the country. MediTour Global App works on
            both iOS and Android. Our Web Portal is designed for all service
            providers to get direct access to patients from Pakistan and the
            world.
          </p>

          {/* 🆕 FORM PLACED HERE */}
          <div className={style.formContainer}>
            <form className={style.form} onSubmit={formik.handleSubmit}>
              <div className={style.formGroup}>
                <label>Full Name</label>
                <input
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  className={style.input}
                  name="fullName"
                  type="text"
                  placeholder="Enter your full name"
                />
                {formik?.errors?.fullName && formik?.touched?.fullName && (
                  <div className={style.error}>{formik?.errors?.fullName}</div>
                )}
              </div>
              <div className={style.formGroup}>
                <label>Contact Number</label>
                <input
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  className={style.input}
                  name="contactNumber"
                  type="number"
                  placeholder="Enter your contact number"
                />
                {formik?.errors?.contactNumber &&
                  formik?.touched?.contactNumber && (
                    <div className={style.error}>
                      {formik?.errors?.contactNumber}
                    </div>
                  )}
              </div>
              <div className={style.formGroup}>
                <label>Email</label>
                <input
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={style.input}
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                />
                {formik?.errors?.email && formik?.touched?.email && (
                  <div className={style.error}>{formik?.errors?.email}</div>
                )}
              </div>
              <div className={style.formGroup}>
                <label>Department</label>
                <input
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  className={style.input}
                  name="department"
                  type="text"
                  placeholder="Enter your department"
                />
                {formik?.errors?.department && formik?.touched?.department && (
                  <div className={style.error}>
                    {formik?.errors?.department}
                  </div>
                )}
              </div>
              <Selection
                name="currentYearOfStudy"
                value={formik.values.currentYearOfStudy}
                onChange={formik.setFieldValue}
                data={title}
                error={formik?.errors?.currentYearOfStudy}
                touched={formik?.touched?.currentYearOfStudy}
                label={"What is your current year of study?"}
              />

              <Selection
                name="areaOfInterest"
                value={formik.values.areaOfInterest}
                onChange={formik.setFieldValue}
                data={area}
                error={formik?.errors?.areaOfInterest}
                touched={formik?.touched?.areaOfInterest}
                label={"What is your area of interest?"}
              />
              <CheckBoxSelection
                name="medicalTourismHeardBefore"
                value={formik.values.medicalTourismHeardBefore}
                onChange={formik.setFieldValue}
                title={"Have you ever heard about medical tourism before?"}
                error={formik?.errors?.medicalTourismHeardBefore}
                touched={formik?.touched?.medicalTourismHeardBefore}
              />
              <TextArea
                name="medicalTourism"
                value={formik.values.medicalTourism}
                onChange={formik.handleChange}
                title={"What do you think medical tourism means?"}
              />
              <CheckBoxSelection
                title={
                  "Do you know that MediTour Global is Pakistan’s first medical tourism company?"
                }
                error={formik?.errors?.knowsMediTourGlobal}
                touched={formik?.touched?.knowsMediTourGlobal}
                name="knowsMediTourGlobal"
                value={formik.values.knowsMediTourGlobal}
                onChange={formik.setFieldValue}
              />
              <TextArea
                name="opinionOnMedicalTourism"
                value={formik.values.opinionOnMedicalTourism}
                onChange={formik.handleChange}
                title={
                  "What is your opinion about Pakistan becoming a hub for medical tourism?"
                }
              />
              <CheckBoxSelection
                name="familyMedicalTravelExperience"
                value={formik.values.familyMedicalTravelExperience}
                onChange={formik.setFieldValue}
                error={formik?.errors?.familyMedicalTravelExperience}
                touched={formik?.touched?.familyMedicalTravelExperience}
                title={
                  "Do you or anyone in your family know someone who has traveled abroad for medical Treatment?"
                }
              />
              <CheckBoxSelection
                name="exploreInternationalHospitals"
                value={formik.values.exploreInternationalHospitals}
                onChange={formik.setFieldValue}
                error={formik?.errors?.exploreInternationalHospitals}
                touched={formik?.touched?.exploreInternationalHospitals}
                title={
                  "Would you like to explore how MediTour connects patients to international hospitals and doctors?"
                }
              />
              <CheckBoxSelection
                name="onlineMedicalServices"
                value={formik.values.onlineMedicalServices}
                onChange={formik.setFieldValue}
                error={formik?.errors?.onlineMedicalServices}
                touched={formik?.touched?.onlineMedicalServices}
                title={
                  "Are you interested in remote online medical services (e.g., telemedicine, online, consultations)?"
                }
              />
              <CheckBoxSelection
                name="medicalTourismBenefit"
                value={formik.values.medicalTourismBenefit}
                onChange={formik.setFieldValue}
                error={formik?.errors?.medicalTourismBenefit}
                touched={formik?.touched?.medicalTourismBenefit}
                title={
                  "Do you think medical tourism can benefit Pakistan’s economy?"
                }
              />
              <CheckBoxSelection
                name="awarenessCampaignInterest"
                value={formik.values.awarenessCampaignInterest}
                onChange={formik.setFieldValue}
                error={formik?.errors?.awarenessCampaignInterest}
                touched={formik?.touched?.awarenessCampaignInterest}
                title={
                  "Would you be interested in being part of an awareness campaign about healthcare innovations in Pakistan?"
                }
              />
              <CheckBoxSelection
                name="internshipJobOpportunities"
                value={formik.values.internshipJobOpportunities}
                error={formik?.errors?.internshipJobOpportunities}
                touched={formik?.touched?.internshipJobOpportunities}
                onChange={formik.setFieldValue}
                title={
                  "Are you looking for internship or job opportunities in a growing startup?"
                }
              />
              {formik.values.internshipJobOpportunities === "Yes" && (
                <Selection
                  name="areaOfWorkingWithMediTour"
                  value={formik.values.areaOfWorkingWithMediTour}
                  onChange={formik.setFieldValue}
                  error={formik?.errors?.areaOfWorkingWithMediTour}
                  touched={formik?.touched?.areaOfWorkingWithMediTour}
                  data={marketing}
                  label={
                    "Which area would you be interested in working with MediTour?"
                  }
                />
              )}
              {formik?.values.internshipJobOpportunities === "Yes" &&
                formik.values.areaOfWorkingWithMediTour ===
                  "Other (please specify)" && (
                  <>
                    <TextArea
                      name="otherInterestedArea"
                      value={formik.values.otherInterestedArea}
                      onChange={formik.handleChange}
                      title={"Please specify?"}
                    />
                    {formik?.errors?.otherInterestedArea &&
                      formik?.touched?.otherInterestedArea && (
                        <div className={style.error}>
                          {formik?.errors?.otherInterestedArea}
                        </div>
                      )}
                  </>
                )}
              <CheckBoxSelection
                name="careerUpdatesSubscription"
                value={formik.values.careerUpdatesSubscription}
                onChange={formik.setFieldValue}
                error={formik?.errors?.careerUpdatesSubscription}
                touched={formik?.touched?.careerUpdatesSubscription}
                title={
                  "Would you like to receive updates about career and internship opportunities at Meditour?"
                }
              />
              <CheckBoxSelection
                name="skillsContribution"
                value={formik.values.skillsContribution}
                onChange={formik.setFieldValue}
                error={formik?.errors?.skillsContribution}
                touched={formik?.touched?.skillsContribution}
                title={
                  "Do you have any skills that you think can contribute to Pakistan’s first medical tourism company?"
                }
              />
              <CheckBoxSelection
                name="volunteerForCampaign"
                value={formik.values.volunteerForCampaign}
                onChange={formik.setFieldValue}
                error={formik?.errors?.volunteerForCampaign}
                touched={formik?.touched?.volunteerForCampaign}
                title={
                  "Would you be interested in volunteering for a MediTour awareness campaign at your university?"
                }
              />
              <TextArea
                name="improvementsSuggestions"
                value={formik.values.improvementsSuggestions}
                onChange={formik.handleChange}
                title={
                  "What improvements or additional features would you like to see in Pakistan’s first medical tourism platform?"
                }
              />
              <CheckBoxSelection
                name="stayConnected"
                value={formik.values.stayConnected}
                onChange={formik.setFieldValue}
                error={formik?.errors?.stayConnected}
                touched={formik?.touched?.stayConnected}
                title={
                  "Would you like to stay connected with MediTour for future updates and events?"
                }
              />
              <div>
                <div className={style.checkboxGroup}>
                  <FaAngleDoubleRight size={16} color="#FF9500" />
                  <div className={style.labletype}>
                    How do you prefer to be contacted for more information?
                  </div>
                </div>
                <div className={style.multiStyle}>
                  {prefer?.map((item: any) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        name="contactMethod"
                        checked={formik.values.contactMethod?.includes(
                          item.title
                        )}
                        onChange={() => handleCheckboxChange(item.title)}
                        className={style.radioMark}
                      />
                      <div
                        style={{
                          color: "#7D7D7D",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {item?.title}
                      </div>
                    </div>
                  ))}
                </div>
                {formik?.errors?.contactMethod &&
                  formik?.touched?.contactMethod && (
                    <div className={style.error}>
                      {formik?.errors?.contactMethod}
                    </div>
                  )}
              </div>
              <TextArea
                name="additionalComments"
                value={formik.values.additionalComments}
                onChange={formik.handleChange}
                title={
                  "Any additional comments or questions you have about MediTour Global?"
                }
              />
              {name && <div className={style.fileName}>{name}</div>}
              <div className={style.buttonstyle}>
                <label className={style.submitBtn}>
                  Upload your CV
                  <input
                    type="file"
                    className={style.fileInput}
                    onChange={onUploadImage}
                    style={{ display: "none" }}
                  />
                </label>
                <button type="submit" className={style.submitBtn}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {loading && <CustomLoader />}

        <CustomModal showModal={showModal}>
          <div className={style.modelContent}>
            <img src={done} className={style.imgModel} />
            <div className={style.modelWord}>
              <p className={style.submitText}>WOOHOO!</p>
              <p className={style.submitSuccess}>Submitted Successfully!</p>
            </div>
            <p className={style.contact}>We’ll contact you shortly</p>
            <a
              href="https://meditour.global/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={style.visitWebsite}>Visit our website</button>
            </a>
          </div>
        </CustomModal>
      </div>
      <Footer />
    </div>
  );
};

const Selection = ({
  label,
  data,
  wid,
  value,
  onChange,
  name,
  error,
  touched,
}: any) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropPosition, setDropPosition] = useState<"down" | "up">("down");

  useEffect(() => {
    if (open && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (dropdownRect.bottom > viewportHeight) {
        setDropPosition("up");
      } else {
        setDropPosition("down");
      }
    }
  }, [open]);

  return (
    <div className={style.formGroup} style={{ position: "relative" }}>
      <label>{label}</label>
      <div
        className={style.rowSelection}
        style={{ width: wid ? wid : "99.8%" }}
        onClick={() => setOpen(!open)}
      >
        <div className={value ? style.highlightedText : style.placeholderText}>
          {value || "Please Select"}
        </div>
        {open ? (
          <MdKeyboardArrowUp size={16} color="#7D7D7D" />
        ) : (
          <MdKeyboardArrowDown size={16} color="#7D7D7D" />
        )}
      </div>
      {error && touched && <div className={style.error}>{error}</div>}
      {open && (
        <div
          ref={dropdownRef}
          className={style.dropdown}
          style={{
            top: dropPosition === "down" ? "100%" : "auto",
            bottom: dropPosition === "up" ? "100%" : "auto",
          }}
        >
          {data.map((i: any) => (
            <div
              key={i?.title}
              style={{
                backgroundColor: value === i?.title ? "#073E7F" : "#fff",
                padding: "12px 22.5px",
                color: value === i?.title ? "#fff" : "#7d7d7d",
              }}
              onClick={() => {
                onChange(name, i?.title);
                setOpen(false);
              }}
            >
              {i?.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CheckBoxSelection = ({
  title,
  value,
  onChange,
  name,
  error,
  touched,
}: {
  title?: any;
  value?: any;
  onChange?: any;
  name?: any;
  error?: any;
  touched?: any;
}) => {
  return (
    <div>
      <div className={style.checkboxGroup}>
        <FaAngleDoubleRight size={16} color="#FF9500" />
        <div className={style.labletype}>{title}</div>
      </div>
      <div className={style.checkboxGrouplable}>
        <label
          style={{ color: "#7D7D7D", fontSize: "16px", fontWeight: "400" }}
        >
          <Radio
            name={name}
            value="Yes"
            style={{ color: "#FF9500" }}
            checked={value === "Yes"}
            onChange={(e) => {
              onChange(name, e.target.value);
            }}
          />
          Yes
        </label>
        <label
          style={{ color: "#7D7D7D", fontSize: "16px", fontWeight: "400" }}
        >
          <Radio
            name={name}
            value="No"
            style={{ color: "#FF9500" }}
            checked={value === "No"}
            onChange={(e) => {
              onChange(name, e.target.value);
            }}
          />
          No
        </label>
      </div>
      {error && touched && <div className={style.error}>{error}</div>}
    </div>
  );
};

const TextArea = ({ title, value, onChange, name }: any) => {
  return (
    <>
      <div className={style.medicalword}>{title}</div>
      <div className={style.textArea}>
        <textarea
          name={name}
          className={style.input_container}
          placeholder="Write Here"
          rows={4}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

const Footer = () => {
  return (
    <div className={style.footer}>
      {/* Left Section: Logo & Copyright */}
      <div className={style.left}>
        <img src={mediLogo} alt="MediTour Logo" className={style.footer_logo} />
        <p>Copyright © 2025 Meditour | All Rights Reserved</p>
      </div>

      {/* Right Section: Social Media Icons */}
      <div className={style.right}>
        <a
          href="https://www.facebook.com/themeditour.global"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className={style.icon} />
        </a>
        <a
          href="https://twitter.com/meditourglobal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className={style.icon} />
        </a>
        <a
          href="https://www.linkedin.com/company/themeditourglobal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn className={style.icon} />
        </a>
        <a
          href="https://www.instagram.com/themeditour.global/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className={style.icon} />
        </a>
        <a
          href="https://www.youtube.com/@themeditour.global"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube className={style.icon} />
        </a>
        <a
          href="https://www.tiktok.com/@themeditour.global"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IoLogoTiktok className={style.icon} />
        </a>
      </div>
    </div>
  );
};

export default Questionnaire;
