import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import style from "./CustomerDetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { rentcarCUSTOMERDETAIL } from "shared/services/RentaCar";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const CustomerDescription = () => {
  interface USER {
    age?: number;
    cnic?: string;
    createdAt?: string;
    dropoffDateTime?: string;
    dropoffLocation?: string;
    name?: string;
    phone?: string;
    pickupDateTime?: string;
    pickupLocation?: string;
    rentACarId?: string;
    status?: string;
    totalAmount?: number;
    updatedAt?: string;
    userId?: {
      _id?: string;
      name?: string;
      email?: string;
      gender?: string;
      mrNo?: string;
      phone?: string;
      dateOfBirth?: string;
      password?: string;
      blocked?: boolean;
      fcmToken?: string;
      addresses?: any[];
      favourites?: any[];
      carRentalDetails?: any[];
      createdAt?: string;
      updatedAt?: string;
      __v?: number;
      userImage?: string;
    };
    vehicleModel?: string;
    orderId?: string;
    withDriver?: boolean;
  }
  interface VEHICLE {
    vehicleName?: string;
    vehicleModel?: string;
    vehicleVinNo?: string;
    vehicleColour?: string;
  }

  const { state } = useLocation();
  const id = state?.id;
  const type = state?.type;
  const [Data, setData] = useState<USER>({});
  const [vehicle, setVehicle] = useState<VEHICLE>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const textToShow = currentPath.includes("rentacar/history")
    ? "History"
    : "Order Details";

  const handleGoBack = () => {
    if (type === "order") {
      navigate("/rentacar/order");
    } else {
      navigate("/rentacar/history");
    }
  };

  const fetchDetails = () => {
    if (id) {
      rentcarCUSTOMERDETAIL(id)
        .then((res: any) => {
          setData(res?.data?.customerDetail || {});
          setVehicle(res?.data?.customerDetail?.vehicleId || {});
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
          <div className={commonstyles.flx}>
            <IoArrowBack
              className={style.back}
              onClick={handleGoBack}
              style={{ marginRight: 20 }}
            />
            <p className={classNames(commonstyles.fs24, commonstyles.semiBold)}>
              {textToShow}
            </p>
          </div>

          <div className={classNames(commonstyles.flx)}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.mr8
              )}
            >
              Order Id:
            </p>
            <p className={classNames(commonstyles.fs24)}>{Data?.orderId}</p>
          </div>
        </div>

        {/* ,............ */}
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap
          )}
        >
          <div
            className={classNames(
              commonstyles.col5,
              commonstyles.colsm12,
              style.card
            )}
          >
            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Customer Phone No:
              </p>
              <p className={classNames(style.detail)}>{Data?.phone}</p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Id Card No:
              </p>
              <p className={classNames(style.detail)}>{Data?.cnic}</p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Vehicle Name:
              </p>
              <p className={classNames(style.detail)}>{vehicle?.vehicleName}</p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Vehicle Model:
              </p>
              <p className={classNames(style.detail)}>
                {vehicle?.vehicleModel}
              </p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Vehicle No:
              </p>
              <p className={classNames(style.detail)}>
                {vehicle?.vehicleVinNo}
              </p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                Vehicle Color:
              </p>
              <p className={classNames(style.detail)}>
                {vehicle?.vehicleColour}
              </p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.medium,
                  commonstyles.fs20,
                  style.heading
                )}
              >
                With Driver:
              </p>
              <p className={classNames(style.detail)}>
                {Data?.withDriver ? "Yes" : "No"}
              </p>
            </div>
          </div>

          {/* .................. */}
          <div
            className={classNames(
              commonstyles.col5,
              commonstyles.colsm12,
              style.card
            )}
          >
            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Pick up Date:
              </p>

              <p>
                {Data?.pickupDateTime
                  ? new Date(Data.pickupDateTime).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : "__"}
              </p>
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Pick up Time:
              </p>

              {Data?.pickupDateTime ? (
                <p>{dayjs.utc(Data?.pickupDateTime).format("h:mm a")}</p>
              ) : null}
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Pick up Location:
              </p>

              <p className={classNames(commonstyles.flxEnd)}>
                {Data?.pickupLocation}
              </p>
            </div>
            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Drop Off Date:
              </p>
              <p>
                {Data?.dropoffDateTime
                  ? new Date(Data.dropoffDateTime).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : "__"}
              </p>
            </div>
            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Drop Off Time:
              </p>

              {Data?.dropoffDateTime ? (
                <p>{dayjs.utc(Data?.dropoffDateTime).format("h:mm a")}</p>
              ) : null}
            </div>

            <div className={classNames(commonstyles.flxBetween)}>
              <p className={classNames(commonstyles.medium, commonstyles.fs20)}>
                Drop Off Location:
              </p>

              <p>{Data?.dropoffLocation}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDescription;
