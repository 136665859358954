const BASE_URL = "https://medi-tour-86152928b53d.herokuapp.com/"; //stagging

// const BASE_URL="https://meditour-production-9a75e265b52a.herokuapp.com"  //live
const ENDPOINTS = {
  //...........Laboratory..........
  SEND_CODE_TO_EMAIL: "lab/sendCodeToEmail",
  LAB_SIGNUP: "lab/register",
  CONFIRM_EMAIL: "lab/confirmEmail",
  COMPLETE_SIGNUP: "lab/completeSignup",
  LOGIN: "lab/login",

  LAB_RESET_LINK: "/lab/ResetLink",
  LAB_RESET_PASSWORD: "/lab/resetPassword",
  UPLOAD_FILE: "lab/uploadFile",
  GET_ALL_TEST: "lab/getAllTests",
  GET_TEST_DETAILS: "/lab/getTest",
  LAB_DELETE_TEST: "/lab/deletetest",
  LAB_EDIT_TEST: "/lab/editTest",
  LAB_ADD_TEST: "/lab/addTest",
  GET_ALL_ORDER: "lab/getLabOrders",
  GET_LAB_ORDERDETAIL: "lab/getLabOrder",
  GET_ALL_REQ: "/doc/getParamedicRequests",
  GET_USER: "user/get-single-user",
  POST_ADD_TEST: "lab/addTest",
  LAB_LOGOUT: "lab/logout",
  LAB_DASHBOARDDETAILS: "/lab/dashDetails",
  LAB_GRAPH: "/lab/graph",
  LAB_UPDATEPROFILE: "/lab/updateProfile",
  LAB_UPLOADRESULT: "/lab/saveResult",
  LAB_COMPLETEORDERS: "lab/getCompletedOrders",
  LAB_STATUSCHANGE: "/lab/changeStatus",
  PARA_STATUSCHANGE: "/doc/changeRequestStatus",
  LAB_ADMIN_GETTEST: "/admin/getAllTestCategories",
  LAB_COMPLETEDRESULTS: "/lab/getCompletedOrders",
  LAB_NOTIFICATION: "lab/getNotifications",
  ADD_Test_To_LAB: "lab/addTestToLabs",
  GET_ALL_LAB_TESTS: "lab/getAllLabTest",
  GET_BRANCH: "lab/getBranches",
  ADD_LAB_BRANCH: "lab/addBranch",
  //..........Pharmacy..........
  PH_LOGIN: "pharm/login",
  PH_SIGNUP: "pharm/register",
  PH_SEND_CODE_TO_EMAIL: "pharm/sendCodeToEmail",
  PH_CONFIRM_EMAIL: "pharm/confirmEmail",
  PH_COMPLETE_SIGNUP: "pharm/completeSignup",
  PH_RESET_LINK: "/pharm/ResetLink",
  PH_RESET_PASSWORD: "/pharm/resetPassword",
  RESET_PASSWORD: "reward_app/auth/reset-password",
  FORGOT_PASSWORD: "reward_app/auth/forgot-password",
  GET_INTEREST: "reward_app/user/intrest-list",
  GET_SIGNUP_BRANDS: "reward_app/user/intrest-list-brands",
  VERIFY_SIGNUP_OTP: "reward_app/auth/verify-otp-code",
  SEND_SIGNUP_OTP: "reward_app/auth/send-otp-code",
  GET_ALL_MEDICINES: "pharm/getAllMeds",
  ADD_FLIGHT_BID: "/agency/addBidRequest",
  GETALL_DETAILS_REQUEST: "/agency/getUserRequest",
  GET_TRAVELERS: "/agency/getTravellers",
  PUSH_IMAGE_TICKET: "/agency/addTicket",
  GET_ALL_ORDERS: "pharm/getAllOrders",
  GET_ADD_MEDICINE: "pharm/addMed",
  PH_GETMEDICINE_DETAILS: "/pharm/getMed",
  PH_DELETE_MEDICINE: "/pharm/deleteMed",
  PH_EDIT_MEDICINE: "/pharm/editMed",
  PH_LOGOUT: "pharm/logout",
  PH_DASHBOARDDETAILS: "/pharm/dashDetails",
  PH_GRAPH: "/pharm/graph",
  PH_UPDATEPROFILE: "/pharm/updateProfile",
  PH_CHANGESTATUS: "pharm/changeStatus",
  PH_GETORDERDETAILS: "pharm/getOrder",
  PH_NOTIFICATION: "pharm/getNotifications",
  PH_GET_REQUESTS: "pharm/getMedicineRequests",
  PH_ADD_BID: "pharm/addBidRequest",
  GET_ALL_ORDER_HISTORY: "/user/getAllOrders",

  //..........Doctor..........

  DOC_SIGNUP: "doc/register",
  DOC_SEND_CODE_TO_EMAIL: "doc/sendCodeToEmail",
  GENERCIC_EMAIL: "/gen/sendCodeToEmailGen",
  DOC_VERIFY_EMAIL: "doc/confirmEmail",
  VERIFY_GENERIC: "/gen/confirmEmail",
  DOC_COMPLETE_SIGNUP: "doc/completeSignup",
  DOC_LOGIN: "doc/login",
  UPDATE_DOCTOR_PROFILE: "/doc/updateProfile",
  GET_ALL_APPOINTMENT: "doc/getAllAppointments",
  GET_ALL_PATIENTS: "doc/getAllPatients",
  GET_REQUESTS: "/doc/getRequests",
  DOC_RESET_LINK: "/doc/ResetLink",
  DOC_RESET_PASSWORD: "/doc/resetPassword",
  DOC_LOGOUT: "doc/logout",
  DOC_AVAILABILITY: "/doc/getAvailability",
  DOC_ACCEPTREQUEST: "/doc/acceptRequest",
  DOC_REJECTREQUEST: "/doc/rejectRequest",
  DOC_GETALLPATIENTS: "/doc/getAllPatients",
  DOC_PATIENTHISTORY: "/doc/patientHistory",
  DOC_GRAPHHHH: "/doc/graph",
  DOC_DASHBOARDDETAILS: "/doc/dashDetails",
  DOC_ADDAVAILABILITY: "doc/addAvailability",

  DOC_APPOINTMENT_LINK: "/doc/appointmentLink",
  DOC_AVAILABILITY_PRICE: "doc/addAvailabilityPrice",
  DOC_GETAVAILIBILITY: "/doc/getAvailability",
  DOC_UPDATEPROFILE: "/doc/updateProfile",
  DOC_GETSINGLEAPPOINMENT: "/doc/getAppointment",
  DOC_ADDHISTORY: "doc/addHistory",
  DOC_ADDPRESCRIPTION: "doc/addPrescription",
  DOC_SEARCHDOCTOR: "doc/searchDoctor",
  DOC_REFERDOCTOR: "doc/referDoctor",
  DOC_GETALLHOSPITALS: "doc/getDocHospitals",
  DOC_GETSINGLEHOSPITALAVAILABILITY: "doc/getSingleHospAvailability",
  DOC_GETPRESCRIPTION: "/doc/getPrescription",
  DOC_SEARCH_PRODUCTS: "/doc/searchProduct",
  GET_ALL_SPECIALITIES: "admin/getAllSpecialities",
  REFER_DOCTOR: "doc/referDoctor",
  GET_ALL_HOSPITAL: "doc/searchHospital",
  CLOSE_APPOINTMENT: "doc/closeAppointment",
  DOC_ADD_SPECIALITY: "admin/addSpeciality",
  DOC_GET_ALL_TREATMENT: "doc/getAllTreatment",
  DOC_GET_TREATMENT_CATEGORIES: "doc/getTreatmentCategories",
  DOC_ADD_TREATMENT: "doc/addTreatment",
  DOC_GET_CATEGORIES: "doc/getTreatmentMainCategories",
  ALL_SPECIALTIES: "/user/getSpecialities",
  ALL_DOCTORS_AVAILABLE: "/user/searchSpecialityDoctors",
  ALL_TREATMENT_DOCS: "/user/getTreatmentDocs",

  //............Hospital...............
  HOS_SIGNUP: "/hosp/register",
  HOS_COMPLETE_SIGNUP: "/hosp/completeSignup",
  HOS_SEND_CODE_TO_EMAIL: "/hosp/sendCodeToEmail",
  HOS_CONFIRM_EMAIL: "/hosp/confirmEmail",
  HOS_LOGIN: "/hosp/login",
  HOS_RESET_LINK: "/hosp/ResetLink",
  HOS_RESET_PASSWORD: "/hosp/resetPassword",
  HOS_GET_ALL_DEPARTMENTS: "/hosp/getAllDeparts",
  HOS_LOGOUT: "hosp/logout",
  HOS_ADDDEPARMENT: "/hosp/addDepart",
  HOS_GETDEPARTMENT: "/hosp/getDepart",
  HOS_EDITDEPARTMENT: "/hosp/editDepart",
  HOS_UPDATEPROFILE: "/hosp/updateProfile",
  HOS_GETALLAPPOINTMENTSREQUEST: "/hosp/getRecentPatients",
  HOS_TODAYSAPPOINTMENTREQUEST: "/hosp/getAllTodaysAppointment",
  HOS_GETAPPOINTSMENTSTATS: "/hosp/getAppointmentStats",
  HOS_GETSINGLEAPPOINMENT: "/hosp/getAppointment",
  HOS_ADDHISTORY: "/hosp/addHistory",
  HOS_ADDPRESCRIPTION: "/hosp/addPrescription",
  HOS_GETPRESCRIPTION: "/hosp/getPrescription",

  HOS_GETCOUNTS: "/hosp/getCounts",
  HOS_GETHOSDOCTORS: "/hosp/getHospitalDoctors",
  HOS_SEARCHDOCTOR: "/hosp/searchDoc",
  HOS_SENDREQUESTTODOCTOR: "/hosp/sendCodeToDocEmail",
  HOS_GETACCEPTEDAPPOINTMENTS: "/hosp/getAllHospAppointments",
  HOS_GETALLPATIENTS: "/hosp/getAllPatients",
  HOS_PATIENTDETAILS: "/hosp/getHospPatientData",
  HOS_DELETE_DEPART: "/hosp/deleteDepart",
  HOS_CONFIRMCODE: "/hosp/confirmDocEmail",

  //.............Ambulance............
  AMB_SIGNUP: "/ambulance/register",
  AMB_COMPLETE_SIGNUP: "/ambulance/completeSignup",
  AMB_SEND_CODE_TO_EMAIL: "/ambulance/sendCodeToEmail",
  AMB_CONFIRM_EMAIL: "/ambulance/confirmEmail",
  AMB_LOGIN: "/ambulance/login",
  AMB_GET_ALL_AMBULANCES: "/ambulance/getAllAmbulances",
  AMB_ADD_AMBULANCE: "/ambulance/addAmbulance",
  AMB_RESET_LINK: "/ambulance/ResetLink",
  AMB_RESET_PASSWORD: "/ambulance/resetPassword",
  AMB_LOGOUT: "/ambulance/logout",
  AMB_GETAMBULANCE: "/ambulance/getAmbulance",
  AMB_DELETEAMBULANCE: "/ambulance/deleteAmbulance",
  AMB_GETALLREQUESTS: "/ambulance/getAllRequests",
  AMB_ADDBIDREQUEST: "/ambulance/addBidRequest",
  AMB_GETSINGLEREQUEST: "/ambulance/getRequest",
  AMB_ACCEPTBOOKINGREQUEST: "/ambulance/acceptRequest",
  AMB_REJECTBOOKINGREQUEST: "/ambulance/rejectRequest",
  AMB_ALLONROUTES: "/ambulance/getAmbulanceBookings",
  AMB_ACCEPTREQUEST: "/ambulance/acceptRequest",
  AMB_DASHBOARD: "/ambulance/dashboard",
  AMB_EDIT_AMBULANCE: "/ambulance/editAmbulance",
  AMB_UPDATEPROFILE: "/ambulance/updateProfile",
  AMB_RECENTBOOKINGS: "ambulance/getAllRecentRequests",
  AMB_STATUSCHANGE: "/ambulance/changeBookingStatus",

  //.............Physiotherapist.......
  PHYSIO_SIGNUP: "/physio/register",
  PHYSIO_COMPLETE_SIGNUP: "/physio/completeSignup",
  PHYSIO_SEND_CODE_TO_EMAIL: "/physio/sendCodeToEmail",
  PHYSIO_CONFIRM_EMAIL: "/physio/confirmEmail",
  PHYSIO_LOGIN: "/physio/login",
  PHYSIO_RESET_LINK: "/physio/ResetLink",
  PHYSIO_NEWPASSWORD: "/physio/resetPassword",
  PHYSIO_LOGOUT: "/physio/logout",
  PHYSIO_GETAVAILIBILITY: "/physio/getAvailability",
  PHYSIO_GETALLAPPOINTMENTS: "/physio/getAllAppointments",
  PHYSIO_GETSINGLEAPPOINMENT: "/physio/getAppointment",
  PHYSIO_ADDHISTORY: "physio/addHistory",
  PHYSIO_ADDPRESCRIPTION: "physio/addPrescription",
  PHYSIO_REFERDOCTOR: "physio/referDoctor",
  PHYSIO_SEARCHDOCTOR: "physio/searchDoctor",

  PHYSIO_GETALLPATIENTS: "/physio/getAllPatients",
  PHYSIO_ACCEPTREQUEST: "/physio/acceptRequest",
  PHYSIO_REJECTREQUEST: "/physio/rejectRequest",
  PHYSIO_GETALLREQUESTS: "/physio/getRequests",
  PHYSIO_PATIENTHISTORY: "/physio/patientHistory",
  PHYSIO_GRAPHHHH: "/physio/graph",
  PHYSIO_GRAPHDETAILS: "/physio/dashDetails",
  PHYSIO_ADDAVAILABILITY: "physio/addAvailability",
  PHYSIO_GETAVAILABILITY: "physio/getAvailability",
  PHYSIO_UPDATEPROFILE: "/physio/updateProfile",
  PHYSIO_GETALLHOSPITALS: "physio/getAvailabilityHospitals",
  PHYSIO_GETSINGLEHOSPITALAVAILABILITY: "/physio/getSingleHospAvailability",
  PHYSIO_GETPRESCRIPTION: "/physio/getPrescription",

  //....................Nutritionist.......
  NUTRI_SIGNUP: "/nutritionist/register",
  NUTRI_COMPLETE_SIGNUP: "/nutritionist/completeSignup",
  NUTRI_SEND_CODE_TO_EMAIL: "/nutritionist/sendCodeToEmail",
  NUTRI_CONFIRM_EMAIL: "/nutritionist/confirmEmail",
  NUTRI_LOGIN: "/nutritionist/login",
  NUTRI_RESET_LINK: "/nutritionist/ResetLink",
  NUTRI_NEWPASSWORD: "/nutritionist/resetPassword",
  NUTRI_LOGOUT: "/nutritionist/logout",
  NUTRI_GETALLREQUESTS: "/nutritionist/getRequests",
  NUTRI_ACCEPTREQUEST: "/nutritionist/acceptRequest",
  NUTRI_REJECTREQUEST: "/nutritionist/rejectRequest",
  NUTRI_GETALLAPPOINTMENTS: "/nutritionist/getAllAppointments",
  NUTRI_GETALLPATIENTS: "/nutritionist/getAllPatients",
  NUTRI_GRAPHDETAILS: "/nutritionist/dashDetails",
  NUTRI_ADDAVAILABILITY: "/nutritionist/addAvailability",
  NUTRI_GETAVAILIBILITY: "/nutritionist/getAvailability",
  NUTRI_GRAPHHHH: "/nutritionist/graph",
  NUTRI_PATIENTHISTORY: "/nutritionist/patientHistory",
  NUTRI_UPDATEPROFILE: "/nutritionist/updateProfile",
  NUTRI_GETALLHOSPITALS: "/nutritionist/getAvailabilityHospitals",
  NUTRI_REFERNUTRI: "/nutritionist/referDoctor",
  NUTRI_GETSINGLEAPPOINMENT: "/nutritionist/getAppointment",
  NUTRI_ADDHISTORY: "/nutritionist/addHistory",
  NUTRI_SEARCHNUTRI: "/nutritionist/searchDoctor",
  NUTRI_ADDPRESCRIPTION: "/nutritionist/addPrescription",
  NUTRI_GETSINGLEHOSPITALAVAILABILITY:
    "/nutritionist/getSingleHospAvailability",

  //...............paramedic.........
  PARAMEDIC_SIGNUP: "/doc/register",
  PARAMEDIC_COMPLETE_SIGNUP: "/paramedic/completeSignup",
  PARAMEDIC_SEND_CODE_TO_EMAIL: "/paramedic/sendCodeToEmail",
  PARAMEDIC_CONFIRM_EMAIL: "/paramedic/confirmEmail",
  PARAMEDIC_LOGIN: "/doc/login",
  PARAMEDIC_RESET_LINK: "/paramedic/ResetLink",
  PARAMEDIC_NEWPASSWORD: "/paramedic/resetPassword",
  PARAMEDIC_LOGOUT: "/doc/logout",
  PARAMEDIC_GETALLREQUEST: "/paramedic/getRequests",
  PARAMEDIC_ACCEPTREQUEST: "/paramedic/acceptRequest",
  PARAMEDIC_REJECTREQUEST: "/paramedic/rejectRequest",
  PARAMEDIC_GETALLAPPOINTMENT: "/paramedic/getAllAppointments",
  PARAMEDIC_GETALLPATIENTS: "/paramedic/getAllPatients",
  PARAMEDIC_PATIENTHISTORY: "/paramedic/patientHistory",
  PARAMEDIC_GRAPHDETAILS: "/paramedic/dashDetails",
  PARAMEDIC_GRAPHHHH: "/paramedic/graph",
  PARAMEDIC_GETAVAILIBILITY: "/paramedic/getAvailability",
  PARAMEDIC_ADDAVAILABILITY: "/paramedic/addAvailability",
  PARAMEDIC_UPDATEPROFILE: "/paramedic/updateProfile",
  PARAMEDIC_GETSINGLEAPPOINMENT: "/paramedic/getAppointment",
  PARAMEDIC_ADDHISTORY: "paramedic/addHistory",
  PARAMEDIC_ADDPRESCRIPTION: "paramedic/addPrescription",
  PARAMEDIC_REFERDOCTOR: "paramedic/referDoctor",
  PARAMEDIC_SEARCHDOCTOR: "paramedic/searchDoctor",
  PARAMEDIC_GETPRESCRIPTION: "/paramedic/getPrescription",
  PARAMEDIC_GETALLHOSPITALS: "/paramedic/getAvailabilityHospitals",
  PARAMEDIC_GETSINGLEHOSPITALAVAILABILITY:
    "/paramedic/getSingleHospAvailability",
  //...............Psychologist.......
  PSYCHOLOGIST_SIGNUP: "/psychologist/register",
  PSYCHOLOGIST_COMPLETE_SIGNUP: "/psychologist/completeSignup",
  PSYCHOLOGIST_SEND_CODE_TO_EMAIL: "/psychologist/sendCodeToEmail",
  PSYCHOLOGIST_CONFIRM_EMAIL: "/psychologist/confirmEmail",
  PSYCHOLOGIST_LOGIN: "/psychologist/login",
  PSYCHOLOGIST_RESET_LINK: "/psychologist/ResetLink",
  PSYCHOLOGIST_NEWPASSWORD: "/psychologist/resetPassword",
  PSYCHOLOGIST_LOGOUT: "/psychologist/logout",
  PSYCHOLOGIST_GETALLAPPOINTMENT: "/psychologist/getAllAppointments",
  PSYCHOLOGIST_GETALLPATIENTS: "/psychologist/getAllPatients",
  PSYCHOLOGIST_GETREQUEST: "/psychologist/getRequests",
  PSYCHOLOGIST_ACCEPTREQUEST: "/psychologist/acceptRequest",
  PSYCHOLOGIST_REJECTREQUEST: "/psychologist/rejectRequest",
  PSYCHOLOGIST_PATIENTHISTORY: "/psychologist/patientHistory",
  PSYCHOLOGIST_GRAPHHHH: "/psychologist/graph",
  PSYCHOLOGIST_DASHBOARDDETAILS: "/psychologist/dashDetails",
  PSYCHOLOGIST_ADDAVAILABILITY: "/psychologist/addAvailability",
  PSYCHOLOGIST_GETAVAILIBILITY: "/psychologist/getAvailability",
  PSYCHOLOGIST_UPDATEPROFILE: "/psychologist/updateProfile",
  PSYCHOLOGIST_GETSINGLEAPPOINMENT: "/psychologist/getAppointment",
  PSYCHOLOGIST_ADDHISTORY: "/psychologist/addHistory",
  PSYCHOLOGIST_ADDPRESCRIPTION: "/psychologist/addPrescription",
  PSYCHOLOGIST_SEARCHDOCTOR: "/psychologist/searchDoctor",
  PSYCHOLOGIST_REFERDOCTOR: "/psychologist/referDoctor",
  PSYCHOLOGIST_GETALLHOSPITALS: "/psychologist/getAvailabilityHospitals",
  PSYCHOLOGIST_GETSINGLEHOSPITALAVAILABILITY:
    "/psychologist/getSingleHospAvailability",

  //..............Hotel.........
  HOTEL_SIGNUP: "/hotel/register",
  HOTEL_COMPLETE_SIGNUP: "/hotel/completeSignup",
  HOTEL_SEND_CODE_TO_EMAIL: "/hotel/sendCodeToEmail",
  HOTEL_CONFIRM_EMAIL: "/hotel/confirmEmail",
  HOTEL_LOGIN: "/hotel/login",
  HOTEL_RESET_LINK: "/hotel/ResetLink",
  HOTEL_NEWPASSWORD: "/hotel/resetPassword",
  HOTEL_LOGOUT: "/hotel/logout",
  HOTEL_UPDATEPROFILE: "/hotel/updateProfile",
  HOTEL_ADD_ROOM: "/hotel/addHome",
  HOTEL_ADD_BNB: "/hotel/addBnb",
  HOTEL_DASHBOARDGRAPH: "/hotel/hotelGraph",
  HOTEL_LASTESTRESERVATION: "/hotel/getAllLatestReservations",
  HOTEL_PROPERTYCOUNTS: "/hotel/getTotalPropertyCounts",
  HOTEL_GETLATESTBOOKINGS: "/hotel/getLatestBookings",
  HOTEL_GETALLRESERVATION: "/hotel/getAllReservations",
  HOTEL_ADDAPPARTMENT: "/hotel/addAppartment",
  HOTEL_GETALLBOOKINGS: "/hotel/getConfirmedBookings",
  HOTEL_GETALLAPPARTMENT: "/hotel/getAllAppartments",
  HOTEL_GETALLHOMES: "/hotel/getAllHomes",
  HOTEL_GETALLBNB: "/hotel/getAllBnb",

  //..............Rent A Car.........
  RENTCAR_SIGNUP: "/rentCar/register",
  RENTCAR_COMPLETE_SIGNUP: "/rentCar/completeSignup",
  RENTCAR_SEND_CODE_TO_EMAIL: "/rentCar/sendCodeToEmail",
  RENTCAR_CONFIRM_EMAIL: "/rentCar/confirmEmail",
  RENTCAR_LOGIN: "/rentCar/login",
  RENTCAR_RESET_LINK: "/rentCar/ResetLink",
  RENTCAR_NEWPASSWORD: "/rentCar/resetPassword",
  RENTCAR_LOGOUT: "/rentCar/logout",
  RENTCAR_GETALLVAHICLE: "/rentCar/getAllVehicles",
  RENTCAR_GETVAHICLEDETAIL: "/rentCar/getVehicle",
  RENTCAR_DELETEVAHICLE: "/rentCar/deleteVehicle",
  RENTCAR_ADDVAHICLE: "/rentCar/addVehicle",
  RENTCAR_GETALLREQUESTS: "/rentCar/getAllRequests",
  RENTCAR_ACCEPTREQUEST: "/rentCar/acceptRequest",
  RENTCAR_REJECTREQUEST: "/rentCar/rejectRequest",
  RENTCAR_EDITVAHICLE: "/rentCar/editVehicle",
  RENTCAR_UPDATEPROFILE: "/rentCar/updateProfile",
  RENTCAR_DASHBOARDDETAILS: "/rentCar/dashDetails",
  RENTCAR_GRAPH: "/rentCar/graph",
  RENTCAR_REQUESTDETAIL: "/rentCar/getRequest",
  RENTCAR_CUSTOMERLIST: "/rentCar/getCustomersList",
  RENCAR_CUSTOMERDETAIL: "/rentCar/getCustomerDetails",
  RENTCAR_CHANGESTATUS: "/rentCar/changeOrderStatus",
  RENTCAR_COMPLETEDORDER: "/rentCar/getCompletedCustomers",
  RENTACAR_HISTORY: "/rentCar/getCompletedCustomers",
  //..............Travel Agency........//
  TRAVELAGENCY_SIGNUP: "/agency/register",
  TRAVELAGENCY_COMPLETE_SIGNUP: "/agency/completeSignup",
  TRAVELAGENCY_SEND_CODE_TO_EMAIL: "/agency/sendCodeToEmail",
  TRAVELAGENCY_CONFIRM_EMAIL: "/agency/confirmEmail",
  TRAVELAGENCY_LOGIN: "/agency/login",
  TRAVELAGENCY_RESET_LINK: "/agency/ResetLink",
  TRAVELAGENCY_NEWPASSWORD: "/agency/resetPassword",
  TRAVELAGENCY_LOGOUT: "/agency/logout",
  TRAVELAGENCY_GETALLONEWAYTRIP: "/agency/getAllOneWayFlight",
  TRAVELAGENCY_UPDATEPROFILE: "/agency/updateProfile",
  TRAVELAGENCY_DASHDETAILS: "/agency/dashDetails",
  TRAVELAGENCY_GRAPHBYWEEK: "/agency/graph",
  TRAVELAGENCY_GRAPHBYMONTH: "/agency/graphByMonth",
  TRAVELAGENCY_FLIGHTGETREQUEST: "/agency/getUserRequests",
  TRAVELAGENCY_GETALLTOURS: "/agency/getAllTour",
  TRAVELAGENCY_ADDTOUR: "/agency/addTour",
  TRAVELAGENCY_ADDBID: "/agency/addBidRequest",
  TRAVELAGENCY_GETTICKETBOOKINGS: "/agency/getAllBookings",
  TRAVELAGENCY_GETUSERREQUESTDETAILS: "/agency/getUserRequest",
  TRAVELAGENCY_BOOKINGDETAILS: "/agency/getAllBookingDetails",
  TRAVELAGENCY_GETTOURDETAILS: "/agency/getTour",

  //..............Donation........//
  DONATION_SIGNUP: "/donation/register",
  DONATION_COMPLETE_SIGNUP: "/donation/completeSignup",
  DONATION_SEND_CODE_TO_EMAIL: "/donation/sendCodeToEmail",
  DONATION_CONFIRM_EMAIL: "/donation/confirmEmail",
  DONATION_LOGIN: "/donation/login",
  DONATION_RESET_LINK: "/donation/ResetLink",
  DONATION_NEWPASSWORD: "/donation/resetPassword",
  DONATION_LOGOUT: "/donation/logout",
  DONATION_DASHBOARDDETAILS: "/donation/dashDetails",
  DONATION_GRAPH: "/donation/graph",
  DONATION_GETALLPACKAGES: "/donation/getAllPackages",
  DONATION_GET_ALL_PACKAGES: "/donation/getAllPackagesWithoutCriteria",
  DONATION_PACKAGEDETAILS: "/donation/getPackage",
  DONATION_GETALLDONATIONS: "/donation/getAllDonations",
  DONATION_DONORDETAILS: "/donation/getDonor",
  DONATION_DELETEPACKAGE: "/donation/deletePackage",
  DONATION_GETALLCRITERIA: "/donation/getAllCriterion",
  DONATION_GETCRITERIA: "/donation/getCriteria",
  DONATION_DELETECRITERIA: "/donation/deleteCriteria",
  DONATION_ADDCRITERIA: "/donation/addCriteria",
  DONATION_EDITCRITERIA: "/donation/editCriteria",
  DONATION_ADDPACKAGE: "/donation/addPackage",
  DONATION_UPDATEPROFILE: "/donation/updateProfile",
  DONATION_DONORLIST: "/donation/donorsList",
  DONATION_TOPDONORS: "/donation/topDonors",

  //...........Insurance............//
  INSURANCE_SIGNUP: "/insurance/register",
  INSURANCE_COMPLETE_SIGNUP: "/insurance/completeSignup",
  INSURANCE_SEND_CODE_TO_EMAIL: "/insurance/sendCodeToEmail",
  INSURANCE_CONFIRM_EMAIL: "/insurance/confirmEmail",
  INSURANCE_LOGIN: "/insurance/login",
  INSURANCE_RESET_LINK: "/insurance/ResetLink",
  INSURANCE_NEWPASSWORD: "/insurance/resetPassword",
  INSURANCE_LOGOUT: "/insurance/logout",
  INSURANCE_GETHOSPITAL: "/insurance/getHospitals",
  INSURANCE_GETLABS: "/insurance/getLabs",
  INSURANCE_ADDINDIVIDUALHEALTH: "/insurance/addIndividualHealth",
  INSURANCE_ADDFAMILYHEALTH: "/insurance/addFamilyHealth",
  INSURANCE_ADDPARENTSHEALTH: "/insurance/addParentHealth",
  INSURANCE_GETALLINDIVIDUALINSURANCE: "/insurance/getAllIndividualHealth",
  INSURANCE_GETALLREQUEST: "/insurance/getAllRequests",
  INSURANCE_UPDATEPROFILE: "/insurance/updateProfile",

  INSURANCE_GETREQUEST: "/insurance/getRequest",
  INSURANCE_INSURREDPERSOSN: "/insurance/getAcceptedInsuredPersons",
  INSURANCE_DASHDETAILS: "/insurance/dashDetails",
  INSURANCE_GRAPH: "/insurance/customerStats",
  INSURANCE_ACCEPTREQUEST: "/insurance/acceptRequest",
  INSURANCE_ADDINDIVIDUALTRAVEL: "/insurance/addIndividualTravel",
  INSURANCE_GETNDIVIDUALTRAVEL: "/insurance/getAllIndividualTravel",
  INSURANCE_ADDFAMILYTRAVEL: "/insurance/addFamilyTravel",
  INSURANCE_GETFAMILYTRAVEL: "/insurance/getAllFamilyTravel",
  INSURANCE_GETFAMILYINSURANCES: "/insurance/getAllFamilyHealth",
  INSURANCE_GETPARENTSINSURANCES: "/insurance/getAllParentHealth",
  INSURANCE_GETINSUREDPERSONDETAILS: "/insurance/getInsuredPerson",

  //.....................................Admin................................................//
  ADMIN_LOGIN: "admin/login",
  ADMIN_LOGOUT: "admin/logout",
  AD_GET_APT_REQUEST: "admin/getRequests",
  AD_GET_APT: "/admin/getAppointments",
  AD_GET_Single_APT: "/admin/getAppointment",
  AD_GET_SINGLE_REQUEST: "admin/getRequest",
  AD_GET_ORDER: "admin/getOrders",
  AD_ACCEPT_APPOINTMENT_REQUEST: "admin/acceptRequest",
  AD_GET_BOOKING_RENTACAR: "/admin/getBookingsRentCar",
  AD_GET_REQUESTS_Hotel: "/admin/getBookingsRequests",
  AD_GET_BOOKING_Hotel: "/admin/getBookingsHotels",
  AD_GET_BOOKING_INSURANCE: "/admin/getBookingsInsurance",
  AD_GET_BOOKING_Flight: "/admin/getBookingsFlight",
  AD_GET_DONATIONS: "/admin/getDonors",
  AD_GET_BOOKING_Tour: "/admin/getBookingsTours",
  DELETE_TOUR_BOOKING: "/agency/deleteTour",
  AD_GET_BOOKING_Ambulance: "/admin/getBookingsAmbulance",
  AD_GET_BOOKED_BID: "/admin/getBookedBid",
  AD_GET_PAYMENT_ORDER: "/admin/getOrders",
  AD_GET_PAYMENT_APPOINTMENT: "/admin/getAppointments",
  AD_GET_PAYMENT_DONATION: "/admin/getDonors",
  AD_GET_PAYMENT_RENTCAR: "/admin/getBookingsRentCar",
  AD_GET_PAYMENT_FLIGHT: "/admin/getFlightPaymentsBooking",
  AD_GET_PAYMENT_TOUR: "/admin/getBookingsTours",
  AD_GET_PAYMENT_INSURANCE: "/admin/getBookingsInsurance",
  AD_GET_PAYMENT_HOTEL: "/admin/getBookingsHotels",
  AD_GET_PAYMENT_AMBULANCE: "/admin/getBookingsAmbulance",
  AD_GET_PAYMENT_COMPLETE: "/admin/getpaymentToVendors",
  GET_ALL_USERS: "admin/getAllVendors",
  Users_Block: "/admin/blockVendor",
  AD_ACCEPT_HOTEL_BOOKING_REQUEST: "/admin/acceptHotelRequest",
  AD_PAY_TO_VENDOR: "/admin/payToVendor",
  GET_MEDICINE_REQUESTS: "admin/getMedicineRequests",
  AD_ACCEPT_MED_BID: "admin/acceptBidRequest",
  AD_GET_MED_BID: "admin/getBids",

  //..............Vendor Activation...................
  ACTIVATE_ACCOUNT: "/gen/sendActivationRequest",
  INCREASE_LIMIT: "/hosp/increaseLimit",
  //.............Pharmaceutical.....................
  PHARMACEUTICAL_REGISTER: "/pharmaceu/register",
  PHARMACEUTICAL_SEND_CODE_EMAIL: "/pharmaceu/sendCodeToEmail",
  PHARMACEUTICAL_CONFIRM_CODE: "/pharmaceu/confirmEmail",
  PHARMACEUTICAL_COMPLETE_SIGNUP: "/pharmaceu/completeSignup",
  PHARMACEUTICAL_LOGIN: "/pharmaceu/login",
  PHARMACEUTICAL_LOGOUT: "/pharmaceu/logout",
  PHARMACEUTICAL_GET_ALL_MEDS: "/pharmaceu/getAllMeds",
  PHARMACEUTICAL_DELETE_MED: "pharmaceu/deleteMed",
  PHARMACEUTICAL_ADD_MEDS: "/pharmaceu/addMed",

  //.............User.....................
  USER_LOGIN: "/user/login",
  USER_DOCTOR: "/user/getNearbyDocs",
  SINGLE_USER_DOCTOR: "/user/getDoc",
  GET_AVAILABILITY_DOCTOR: "/user/getAvailability",
  GET_HOSPITAL_PRICE: "/user/getHospitalAvailabilityPrice",

  //.........User OPD.......//
  OPD_GET_DOCTOR: "/user/getOpdDoc",
  //.............Treatments......//
  TREATMENTS_BY_CATEGORY: "/user/treatmentsByCategory",
  ADD_CONSULTACY_FORM: "/user/addConsultancyForm",
  TOP_TREATMENTS: "/user/getCategoryDocs",
  ALL_BOOKING_COMMON: "/agency/getAllBookings",
  ALL_DOCS: "/user/getTreatmentDocs",
  GET_SINGLE_DETAILS: "/agency/getAllBookingDetails",
  ALL_SUB_CATEGORY: "/user/getSubCategories",

  //  ................... Rent A Car..............
  USER_RENT_A_CAR: "/user/getNearbyRentCars",
  GET_CARS_DETAILS: "/user/rentACarDetail",
  GET_ALL_RENT_A_CAR_RATINGS_REVIEWS: "/user/getAllRatingReviews",
  GET_SINGLE_CARS_DETAILS: "/user/getVehicle",

  // ..............userLogout...
  USER_LOGOUT: "/user/logout",

  // /..........................Donation..................
  GET_COMPANIES: "/user/getRegisteredCompanies",
  GET_NEED_PEOPLE: "/user/getAllCriterion",
  GET_RECENT_DONORS: "/user/getRecentDonors",
  GET_PACKAGES_DONATION: "/user/getCategoryPackageDetails",
  GET_PACKAGE_DETAILS: "/user/getPackageDetails",
  USER_HOSPITAL: "/user/getNearbyHospitals",
  SINGLE_USER_HOSPITAL: "/user/getHospital",
  GET_ALL_DEPARTMENTS: "/user/getAllDepartments",
  GET_HOSPITAL_DOCTORS: "/user/getHospitalDocs",
  GET_DEPARTMENT_DOCTORS: "/user/getDepartDocs",
  POST_BOOK_APPOINTMENT: "/user/addAppointmentRequest",
  REMAIN_DOCTOR_PAYMENT: "/user/remainingDocPayment",
  GET_PATIENT_DATA: "/user/getPatientData",
  GET_REFERRAL: "/user/getRefferal",
  UPCOMING_APPOINTMENT: "/user/getAllUpcomingAppointments",
  GET_ALL_RECORDS: "user/getAllRecords",
  ADD_MEDICINE_ORDER: "/user/addMedicineRequest",

  // ...........User-Laboratory.................
  USER_LABORATORY: "/user/getNearbyLabs",
  SINGLE_USER_LABORATORY: "/user/getLab",
  ALL_TESTS: "/user/getAllTests",
  ADMIN_TEST_CATEGORIES: "/admin/getAllTestCategories",
  CONFIRM_BOOKING: "/user/addLabOrder",
  POST_ADD_FILE: "/user/uploadFile",
  SAVE_RESULTS: "/user/saveTestResult",
  ACTIVATE_VENDOR: "/admin/activateVendor",
  ADMIN_ACTIVATION: "/admin/getActivationRequest",
  REMAIN_PAYMENT_RENTCAR: "/user/rentCarRemainingPayment",
  //..............Flights............
  GET_ALL_FLIGHTS_REQUEST: "/user/getAllFlightRequests",
  GER_ALL_BID_REQUEST: "/user/getBidRequests",

  //  ................... Rent A Car..............
  REMAIN_PAYMENT_HOTEL: "/user/hotelRemainingPayment",
  REMAIN_TOUR_PAYMENT: "/user/payRemainingTourAmount",
  INSURANCE_DEL_INSURANCE: "/insurance/deleteIndividualHealth",
  // ..............userLogout...

  ADD_AMBULANCE_PUSH: "/user/addAmbulanceRequest",
  GET_USER_AMBULANCE_REQUEST: "/user/getUserRequests",
  FETCH_BID_REQUEST: "/user/getAmbBidRequests",
  DELETE_USER_REQUEST: "/user/deleteUserRequest",
  ACCEPT_BID_REQUEST: "/user/acceptAmbBidRequest",
  DECLINE_REQUEST: "/user/rejectAmbBidRequest",

  // ..............userLogout...
  // USER_LOGOUT: '/user/logout',

  // /..........................Donation..................
  POST_DONATION: "/user/addDonation",
  // ....................Pharmacy
  SEARCHDOCTOR_KEYWORD: "/doc/searchProduct",
  // ............../////////////Tour
  ALL_UPCOMING_TOURS: "/user/getAllUpcomingSchedules",

  ADD_TOUR_BOOKING: "/user/addBookingsTour",
  CONFIRM_INSURANCE: "/user/sendInsuranceRequest",

  //.................. .FLight......
  ADD_ALL_FLIGHTS_REQUEST: "/user/addFlightRequest",
  ADD_DELETER_FLIGHT: "/user/deleteFlightRequest",
  DECLINE_FLIGHT_REQUEST: "/user/rejectBidRequest",
  ACCEPT_BID_REQUEST_FLIGHT: "/user/acceptBidRequest",
  //................Stripe.......................
  CREATE_PAYMENT_INTENT: "/stripe/createCheckoutSession",

  ACCEPTMEDICINELAB: "/user/handleLabAndMedicineRequests",
  //  ...........
  POST_RENTACAR_DETAIL: "/user/sendVehicleBooking",
  //////insurance
  POST_TRAVEL_INSURANCE: "/user/searchTravelInsurance",
  GET_INSURANCE_DETAILS: "/user/getInsurance",
  INSURANCE_FAMILY: "user/searchHealthInsurance",

  //Favourites
  FVT_REMOVED_ADD: "/user/addRemoveAllFav",

  // ...................Booking.....
  GET_ALL_BOOKINGS: "/user/getAllBooking",
  // Hotel.................................///
  SEARCH_HOTEL_FILTER: "/user/searchHotel",
  BOOKING_ROOM_DETAILS: "/user/addHotelBooking",
  DETAILS_INFO_HOTEL: "/user/getHotelInfo",

  // ..........USer Profile...............
  UPDATE_USER_PROFILE: "/user/updateProfile",
  BLOCK_ALL: "/gen/blockVendor",

  //............User Favourites............
  FVRT_MUTUAL_API: "/user/getAllFavourites",

  //..............Blinq..........
  CREATE_BLINQ: "/gen/blink",

  //............USer Signup.........
  USER_SEND_CODE_TO_EMAIL: "/user/sendCodeToEmail",
  confirmEmail: "/gen/updatePasswordGen",
  SIGNUP: "/user/register",
  RESET_LINK: "user/forgotPassword",
  updatePassword: "user/updatePassword",
  // .....................paramedic////////
  SUBMIT_PARAMEDIC: "/user/addParamedicRequest",
  INSURANCE_DEL_INSURANCE_FAMILY: "/insurance/deleteFamilyHealth",
  INSURANCE_DEL_INSURANCE_FAMILY_TRAVELER: "/insurance/deleteParentHealth",

  INSURANCE_DEL_INSURANCE_INDIVIDUL_TRAVELER:
    "/insurance/deleteIndividualTravel",
  INSURANCE_DEL_INSURANCE_FAMILY_TRAVELERW: "/insurance/deleteFamilyTravel",

  //..........................UPDATE_PASSWORD_VENDORS.............
  UPDATE_PASSWORD_VENDORS: "/gen/updateAllUsersPassword",

  // .....................// Version................
  GET_VERSION: "/gen/getVersion",
  UPDATE_VERSION: "/gen/updateVersion",

  // ..................//
  FORGET_VERIFICATION_PASSWORD: "/gen/forgotPasswordGen",
  ///////HospitalNewFlow.......
  DASHEBOARD_COUNT: "/hosp/getCounts",
  ALL_HOSPITAL_APPOINTMENTS: "/hosp/getAllHospAppointments",
  ALL_HOSPITAL_BRANCHES: "/hosp/getAllBranches",
  BOOKING_APPOINTMENT: "/hosp/getAllAppointmentsRequests",
  REGISTER_HOPITAL_SUBBRANCH: "/hosp/addBranchHosp",
  GET_ALL_DOCTOR: "/hosp/getHospitalDoctors",
  SEND_CODE_TOEMAIL: "/hosp/sendCodeToDocEmail",
  SEND_EMAIL_TO: "/hosp/confirmDocEmail",
  GET_PATIENT: "/hosp/getAllPatients",
  HOSPITAL_AVAILABILITY: "/hosp/addAvailability",
  AVAILABILITY_PRICE: "/hosp/addAvailabilityPrice",
  LOGOUT_HOSPITAL: "/hosp/logout",
  DATEANDR_TIME: "/hosp/confirmAppointment",
  TREATMENT_CATEGORY: "/hosp/treatmentsByCategory",
  ADD_TREATMENT: "/hosp/addHospTreatment",
  GET_ALL_HOSPITAL_TREATMENTS: "/hosp/getTreatmentList",

  TREATMENT_DETAIL: "/hosp/getTreatmentDocs",
  MANAGE_LAB: "/hosp/getHospitalLabsAndPharms",
  SEND_CODE_EMAIL: "/hosp/sendCodeToLabEmail",
  HOSPITAL_CONFIRM_LAB: "/hosp/confirmLabAndPharmEmail",
  ACCEPET_INVITATION: "/doc/acceptInvitation",
  GET_ALL_DEPHOSPITAL: "/hosp/getAllDepartments",
  DEPARTMENT_NAME: "/hosp/getTreatmentCategories",
  GET_DIRECTOR: "/hosp/getHospitalDoctors",
  IS_ACTIVE: "/hosp/changeActiveStatus",
  ADD_DEPARTMENT_HOSPITAL: "/hosp/addDepartment",
  EDIT_DEPARTMENT_HOSPITAL: "/hosp/editDepartment",
  RESECDULA_APPOINTMENT: "/hosp/rescheduleAppointment",
  FETCH_DEPART: "/hosp/getAllDepartments",
  HOSPITAL_DEPARTMENT_DOC: "/hosp/getHospDepartDocs",
  DEL_APPOINTMENT: "/hosp/deleteAppointment",
  SETTING_URL: "/hosp/updateProfile",

  GET_ALL_HOSPITAL_APPOINTMENTS: "/hosp/getAllHospAppointments",
  GET_ALL_PATIENT_DETAIL: "/hosp/getAllPatients",
  CREATE_INVOICE: "/hosp/patientInvoice",

  // Comapany panel
  COMAPNY_LOGIN: "/docCompany/login",
  GET_COMPANYDOC: "/docCompany/getCompanyDocs",
  SEND_COMPANY_CODE: "/docCompany/sendCodeToDocEmail",
  VERIFY_COMAPNY_CODE: "/docCompany/confirmEmail",
  GET_PATIENT_COMPANY: "/docCompany/getCompanyPatientList",
  LOGOUT_COMPANY: "/docCompany/logout",
  SENDCOMAY_CODE: "docCompany/sendCodeToDocEmail",
  GETCOMPANY_PATIENTS: "/docCompany/getCompanyPatients",
  LIMIT_API: "hosp/checkWhileAdding",

  // .....TravelAgency Login
  LOGIN_PANEL: "/travComp/login",
  GET_TREATMENT_DOC: "/doc/treatmentsByCategory",
  COMPANYTRAVEL_SEND: "/travComp/sendCodeToAgencyEmail",
  CONFIRM_COMAPNY_CODE: "/travComp/confirmEmail",
  CONFIRM_HOTEL_CODE: "/travComp/sendCodeToHotelEmail",
  GETDASHEBOARD_API: "/travComp/dashboard",
  GET_AGENCY_LIST: "/travComp/listAgency",
  TRAVEL_COMPANY_LOGOUT: "/travComp/logout",
  AGENCY_DETAIL: "/travComp/getAgencyDetails",
  LIST_HOTEL: "/travComp/listhotels",
  ADD_HOTEL_BRANCH: "travComp/addBranch",
  GET_HOTEL_BRANCHES: "travComp/getBranches",
  GET_HOTEL_PROPERTIES: "/travComp/getProperties",
  ACCEPT_GEN: "/gen/acceptInvitation",
  DETAIL_HOTEL: "/travComp/getHotelDetails",
  GET_COMPANYIES: "/gen/getTravelCompanies",
  GET_NEW_REQUEST: "/travComp/getRequests",
  ACCEPT_REQUEST: "/travComp/acceptRequest",
  REJECT_CASE: "/travComp/rejectRequest",
  ADD_TREATMENT_DOC: "/doc/addTreatment",
  FETCH_ALL_HOSPITAL: "/doc/getHospitals",

  //
  GET_ONLYHOSPITAL_TREATMENT: "/doc/getTreatmentList",
  ALL_STATUS: "/hosp/changeActiveStatus",
  DELETE_TREATMENT: "/doc/deleteTreatment",
  GET_GENERIC_PATIENT: "/hosp/getPatientList",
  GET_PATIENT_DETAIL: "/hosp/getPatientAppointmentDetail",
  QUESTION: "/gen/submitQuestionnaire",
  // .......hotel
  ADD_PROPERTY: "/hotel/addProperty",
  HOTEL_PROPERTY: "/hotel/getProperties",
  HOTEL_DASHEBOARD_COUNT: "/hotel/dashboard",
  LATEST_BOOKINGS: "/hotel/getLatestBookings",
  CHANGE_STATUS: "/hotel/updateAvailability",
  RESERVE_BOOKING: "/hotel/getAllReservations",
  GEN_ALL_COUNTRY: "/gen/getCountries",
  GEN_GET_RATES: "/gen/getActivationRates",
  STRIPE_PAID: "/gen/sendActivationRequest",
  PAID_ACTIVATION: "/gen/sendActivationStatus",
  TEST_UPDATE: "/lab/editTest",
  NOTIFICATUONS_ALL: "/user/getNotifications",
  GENRIC_MEDICINE: "/pharmaceu/searchGeneric",
  GOOGLE_LOGIN: "/user/googleAuth",
  SET_PASSWORD: "/user/addPassword",
  UPDATE_LABORTERY: "/lab/updateProfile",
  UPDATE_TRAVELAGENCY: "/agency/updateProfile",

  // ...............................Generic.................................................

  UPLOAD_MULTI_FILES: "gen/uploadMultipleFiles",
};

export { BASE_URL, ENDPOINTS };
