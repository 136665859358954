import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./userSignUpMain.module.css";
import logoImage from "../../../../assets/images/smallLogo.png";
import buttonImage from "../../../../assets/images/BackSignUpButton.png";
import BasicInfo from "./BasicInfo";
import SocialInfo from "./SocialInfo";
import BankDetails from "./BankDetails";
import Password from "./Password";
import { useLocation, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { userSignup } from "shared/services";
import {
  setIsLoggedIn,
  setSystemType,
  setToken,
  setUserAge,
  set_User,
} from "shared/redux";
import { LoadingModal } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
import {
  notifyError,
  notifySuccess,
} from "shared/components/A_New_Components/ToastNotification";
interface FormValues {
  fullName: string;
  fatherName: string;
  gender: string;
  dob: Date | null;
  childrenNumber: string;
  phoneNumber: string;
  email: string;
  qualification: string;
  address: string;
  city: string;
  country: string;
  bloodGroup: string;
  passport: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  youtube?: string;
  bankName?: string;
  accountNumber: string;
  accountTitle: string;
  ntn: string;
  // lat: any;
  // long: any;
}

const SignupLogin: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const { fcmToken } = useSelector((state: any) => state.root.common);
  const { state } = useLocation();

  const formik = useFormik<FormValues>({
    initialValues: {
      fullName: "",
      fatherName: "",
      gender: "",
      dob: null,
      childrenNumber: "",
      phoneNumber: "",
      email: state?.email,
      qualification: "",
      address: "",
      city: "",
      country: "",
      bloodGroup: "",
      passport: "",
      facebook: "",
      instagram: "",
      linkedIn: "",
      youtube: "",
      bankName: "",
      accountNumber: "",
      accountTitle: "",
      ntn: "",
      // lat: "",
      // long: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      fatherName: Yup.string().required("Father Name is required"),
      gender: Yup.string().required("Gender is required"),
      dob: Yup.date().required("Date of Birth is required"),
      childrenNumber: Yup.number()
        .nullable()
        .transform((value) => (value === "" ? undefined : value))
        .min(0, "Children number cannot be negative"),
      phoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(/^\+?\d{1,3}\s?\d{3}\s?\d{7}$/, "Phone number is not valid"),

      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      qualification: Yup.string().required("Qualification is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      bloodGroup: Yup.string(),
      passport: Yup.string().required("CNIC is required"),
    }),
    onSubmit: (values) => {
      handleContinue();
    },
  });

  const handleAge = async (daetOfBirth: any) => {
    const dobString = daetOfBirth;
    const dob = new Date(
      dobString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
    );
    const now = new Date();
    const diffMilliseconds = now.getTime() - dob.getTime();
    const calculatedAge = Math.floor(
      diffMilliseconds / (1000 * 60 * 60 * 24 * 365)
    );
    const ageConvert_InString = calculatedAge?.toLocaleString();
    await dispatch(setUserAge(ageConvert_InString));
  };

  const formatDate = (date: any | string | null) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const year = parsedDate.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const handleContinue = async () => {
    if (activeStep < 3) {
      await formik.validateForm();

      // Mark fields as touched for validation purposes
      formik.setTouched({
        fullName: true,
        fatherName: true,
        gender: true,
        dob: true,
        childrenNumber: true,
        phoneNumber: true,
        email: true,
        qualification: true,
        address: true,
        city: true,
        country: true,
        bloodGroup: true,
        passport: true,
      });

      const isLastStep = activeStep === 2;
      const isValid = formik.isValid && formik.dirty;

      if (!isAgreed) {
        notifyError("You must agree to the Terms and Conditions.");

        return;
      }

      if (isLastStep || isValid) {
        setActiveStep(activeStep + 1);
      }
    } else {
      if (password) {
        if (password !== confirmPassword) {
          notifyError("Password does not match");
        } else {
          setLoading(true);

          const formattedDob = formik.values.dob
            ? formatDate(formik.values.dob)
            : "";
          let phoneNumber = formik.values.phoneNumber; // Corrected line
          // const dateStr = formik.values.dob
          // if (dateStr) {
          //   const date = new Date(dateStr);
          //   const month = String(date.getMonth() + 1).padStart(2, '0');
          //   const day = String(date.getDate()).padStart(2, '0');
          //   const year = date.getFullYear();
          //   const formattedDate = `${month}/${day}/${year}`;

          //   // Setting formatted date back to formik field
          //   formik.setFieldValue('dob', formattedDate);
          // }

          let params = {
            name: formik.values.fullName,
            email: formik.values.email,
            gender: formik.values.gender,
            spouseOrGuardianName: formik.values.fatherName,
            childCount: String(formik.values.childrenNumber),
            cnicOrPassNo: formik.values.passport,
            bloodGroup: formik.values.bloodGroup,
            city: formik.values.city,
            country: formik.values.country,
            qualification: formik.values.qualification,
            bankName: formik.values.bankName,
            accountHolderName: formik.values.accountTitle,
            accountNumber: formik.values.accountNumber,
            ntnNo: formik.values.ntn,
            facebook: formik.values.facebook,
            instagram: formik.values.instagram,
            linkedin: formik.values.linkedIn,
            youtube: formik.values.youtube,
            dateOfBirth: formattedDob,
            phone: phoneNumber,
            password: password,
            fcmToken: fcmToken,
            address: {
              city: formik?.values?.city,
              address: formik?.values?.address,
            },
          };

          userSignup(params)
            .then((res: any) => {
              if (res?.data?.auth) {
                dispatch(set_User(res?.data?.user));
                dispatch(setIsLoggedIn(true));
                dispatch(setToken(res?.data?.token));
                dispatch(setSystemType("user"));
                handleAge(res?.data?.user?.dateOfBirth);
                notifySuccess("Register Successful");
                navigate("/");
              }
            })
            .catch((err: any) => {
              // toast.error(err?.response?.data?.message);
            })
            .finally(() => setLoading(false));
        }
      } else {
        notifyError("Password is required");
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/user/login");
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleAgreementChange = (agreed: boolean) => {
    setIsAgreed(agreed);
  };
  const getHeaderText = () => {
    switch (activeStep) {
      case 0:
        return "Basic Info";
      case 1:
        return "Social Info";
      case 2:
        return "Bank Details";
      case 3:
        return "Password";
      default:
        return "Register";
    }
  };

  return (
    <div className={styles.containerSignUpLogin}>
      <div
        className={styles.header}
        onClick={handleBack}
        style={{ cursor: "pointer" }}
      >
        <img src={buttonImage} alt="Button" className={styles.imageButton} />
        <h2 className={styles.registerHeading}>{getHeaderText()}</h2>
      </div>
      <Link to="/">
        <img src={logoImage} alt="Logo" className={styles.logo} />
      </Link>
      <hr className={styles.horizontalLine} />
      <div className={styles.roundButtonContainer}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className={`${styles.roundButton} ${
              index === activeStep
                ? styles.activeButton
                : index < activeStep
                ? styles.completedButton
                : ""
            }`}
          >
            {index < activeStep ? "✓" : index + 1}{" "}
          </div>
        ))}
      </div>
      <div className={styles.buttonLabels}>
        <div className={styles.label}>Basic Info</div>
        <div className={styles.label}>Social Info</div>
        <div className={styles.label}>Bank Details</div>
        <div className={styles.label}>Password</div>
      </div>
      <div className={styles.stepContent}>
        {activeStep === 0 && (
          <div className={styles.basicInfoStepForm}>
            <BasicInfo
              formik={formik}
              onAgreementChange={handleAgreementChange}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div className={styles.socialInfoStepForm}>
            <SocialInfo formik={formik} />
          </div>
        )}
        {activeStep === 2 && (
          <div className={styles.bankDetailsStepForm}>
            <BankDetails formik={formik} />
          </div>
        )}
        {activeStep === 3 && (
          <div className={styles.passwordStepForm}>
            <Password
              formik={formik}
              setPassword={setPassword}
              password={password}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
            />
          </div>
        )}
      </div>
      {/* <div className={styles.stepContentPage}> */}
      {/* Your step content here */}
      {/* </div> */}

      {/* <button className={styles.continueButton} onClick={handleContinue}>
        Continue
      </button> */}

      {loading && <CustomLoader />}
    </div>
  );
};

export default SignupLogin;
