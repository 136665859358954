import style from "./style.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailability_Doctors, getSignle_Doctor } from "shared/services";
import CustomLoader from "shared/components/New_Loader/Loader";
import Priority from "assets/images/streamline_customer-support-1.png";
import Private from "assets/images/material-symbols_privacy-tip-outline-rounded.png";
import Encryption from "assets/images/solar_key-broken.png";
import Experience from "assets/images/tabler_briefcase-filled.png";
import location from "assets/images/tdesign_location-filled.png";
import { daysOfWeek } from "shared/utils";
import dayjs from "dayjs";
import { notifyError } from "shared/components/A_New_Components/ToastNotification";
import New_CustomSelect from "shared/components/A_New_Components/NewCustomSelect";
import { setSystemType } from "shared/redux";

const DoctorDetails = () => {
  const { state }: any = useLocation();
  let serviceName = state?.serviceName;
  let doctor = state?.doc;
  // let doc = state?.speciality
  //   ? state?.doc?.doctorId || state?.doc?.doctor
  //   : state?.doc;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [docAvail, setDocAvail] = useState<any>([]);
  const [selectedDay, setSelectedDay] = useState<any>("");
  const [selectedPlace, setSelectedPlace] = useState<any>("clinic");
  console.log("🚀 ~ DoctorDetails ~ selectedPlace:", selectedPlace);
  const [priceToSend, setPriceToSend] = useState<any>(0);

  var tempHos: any = [];

  if (docAvail[0]?.hospitalAvailability?.length > 0) {
    docAvail[0]?.hospitalAvailability?.map((item: any) => {
      if (item?.price?.actualPrice !== 0 && item?.price?.actualPrice != null) {
        tempHos.push({
          label: item?.hospitalId?.name,
          value: item?.hospitalId?._id,
          price: item?.price?.actualPrice,
        });
      }
    });
  }

  useEffect(() => {
    const defaultPrice = docAvail[0]?.clinicAvailability?.price?.actualPrice;
    if (defaultPrice) {
      setPriceToSend(defaultPrice);
    }
  }, [docAvail]);

  const navigate = useNavigate();

  const handleSelectPlace = (value: any) => {
    let availability = docAvail[0];
    console.log("🚀 ~ handleSelectPlace ~ availability:", availability);
    if (value === "clinic") {
      setPriceToSend(availability?.clinicAvailability?.price?.actualPrice || 0);
    }
    if (value === "online") {
      setPriceToSend(availability?.videoAvailability?.price?.actualPrice || 0);
    }

    if (value === "inhouse") {
      setPriceToSend(
        availability?.inHouseAvailability?.price?.actualPrice || 0
      );
    }

    if (value === "hospital") {
      setPriceToSend(tempHos[0]?.price || 0);
    }

    setSelectedPlace(value);
  };

  const handleSelectHospital = (value: any) => {
    let availability = docAvail[0];
    console.log("🚀 ~ handleSelectHospital ~ value:", value);

    const result = availability?.hospitalAvailability?.find(
      (obj: any) => obj?.hospitalId?._id === value
    );
    console.log("🚀 ~ handleSelectHospital ~ result:", result);
    setPriceToSend(result?.price?.actualPrice || 0);
  };
  const handelGoPayment = (item: any) => {
    dispatch(setSystemType("user"));

    // enum: ["clinic", "in-house", "hospital", "video"],
    let apptType =
      selectedPlace === "clinic"
        ? "clinic"
        : selectedPlace === "online"
        ? "video"
        : selectedPlace === "inhouse"
        ? "in-house"
        : "hospital";
    if (isLoggedIn) {
      if (docAvail?.length > 0 && selectedDay) {
        if (priceToSend) {
          navigate("/services/paymentDetail", {
            state: {
              serviceName: "doctor",
              actualAmount: priceToSend,
              doctorId: doctor?._id,
              appointmentType: apptType,
            },
          });
        } else {
          notifyError(
            "Doctor is not available on selected place, Select another place"
          );
        }
      } else {
        notifyError("Doctor is not available on this day, Select another day");
        return;
      }
    } else {
      navigate("/user/login");
    }
  };

  const [ratings, setRatings] = useState<any>([]);
  const { isLoggedIn, user } = useSelector((state: any) => state.root.common);
  // const video_Availability = docAvail && docAvail[0]?.videoAvailability;
  // const clinic_Availability = docAvail && docAvail[0]?.clinicAvailability;
  // const inHouse_Availablity = docAvail && docAvail[0]?.inHouseAvailability;
  // const hospital_Availablity = docAvail && docAvail[0]?.hospitalAvailability;
  // const availableHospitals = hospital_Availablity?.filter(
  //   (hospital: any) => hospital.availability.length > 0
  // );

  //

  useEffect(() => {
    setLoading(true);
    signle_Doctor();
    // getreviewDoc();
    doctor_Availability();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const signle_Doctor = () => {
    const params = {
      doctorId: doctor?._id,
      type: serviceName == "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
      patientId: user?._id || "",
    };

    getSignle_Doctor(params)
      .then((res: any) => {
        setData(res?.data);
        console.log("Single Doctor", data);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const doctor_Availability = async () => {
    const params = {
      doctorId: doctor?._id,
      type: serviceName === "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
    };

    try {
      const res = await getAvailability_Doctors(params);
      setDocAvail(res?.data?.availability);
      console.log("availability", res?.data?.availability);
    } catch (err) {
      console.error("Error fetching availability:", err);
    } finally {
      setLoading(false);
    }
  };

  // const handleViewMoreClick = async () => {
  //   if (isLoggedIn) {
  //     if (data?.appointmentExists == true) {
  //       toast.error("Hey Already Requested");
  //     } else {
  //       if (serviceName === "Hospital") {
  //         if (availableHospitals === 0) {
  //           toast?.error("No availability added by doctor");
  //         } else {
  //           await dispatch(setObj(data?.doctor));
  //           navigate("/services/paymentDetail", {
  //             state: {
  //               serviceName,
  //               actualAmount: hospital_Availablity[0]?.price?.actualPrice,
  //               appointmentType: "hospital",
  //               doctorId: doctor?._id,
  //             },
  //           });
  //         }
  //       } else {
  //         navigate(`/services/doctor/DoctorAppoinmentPay/${doctor?._id}`, {
  //           state: {
  //             serviceName,
  //             opdType: state?.opdType,
  //             speciality: state?.speciality,
  //             paidOpdAmount: video_Availability?.price?.actualPrice,
  //             data,
  //           },
  //         });
  //       }
  //     }
  //   } else {
  //     navigate("/user/login");
  //     return;
  //   }
  // };
  // const getreviewDoc = () => {
  //   let params = {
  //     vendorId: doctor?._id,
  //   };
  //   GetAllRentACarReview_Rating(params)
  //     .then((res: any) => {
  //       setRatings(res?.data?.reviewsWithTimeAgo);
  //     })
  //     .catch((err: any) => {})
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const [isExpanded, setIsExpanded] = useState(false);

  const text = doctor?.about;

  const wordsArray = text?.split(" ");
  const previewText = wordsArray?.slice(0, 100).join(" ") + "...";

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      {/* <NavBreadCrumbs {...DOCTOR_DETAILS()} /> */}

      {/* <div> */}
      {/* <div> */}
      <div className={classNames(style.container)}>
        <div className={classNames(style.flxouter, style.gap)}>
          <div className={classNames(style.w70)}>
            <div className={classNames(style.flx, style.gap20, style.h350)}>
              <div className={style.DocImgOuter}>
                <img src={doctor?.doctorImage} className={style.DocImg} />
              </div>{" "}
              <div className={classNames(style.DocInfo, style.flexColumn)}>
                {/* Top Section - Stays at the Top */}
                <div>
                  <div className={classNames(style.flx, style.gap20)}>
                    <p className={classNames(style.DcoName)}>{doctor?.name}</p>
                    {/* <p className={classNames(style.Featured)}>Featured</p> */}
                  </div>
                  <p className={classNames(style.Verified, style.mt8)}>
                    PMDC Verified
                  </p>
                  <p className={classNames(style.texts, style.mt16)}>
                    {doctor?.qualifications}
                  </p>
                </div>

                {/* Bottom Section - Stays at the Baseline */}
                <div>
                  {/* <div className={classNames(style.flx, style.mt8)}>
                        <img src={star} className={style.iconss} />
                        <p className={style.text}>98% Rating</p>
                      </div> */}
                  {/* <div className={classNames(style.flx, style.mt8)}>
                        <img src={Satisfied} className={style.iconss} />
                        <p className={style.text}>660 Satisfied Patient</p>
                      </div> */}
                  <div className={classNames(style.flx, style.mt8)}>
                    <img src={Experience} className={style.iconss} />
                    <p className={style.text}>
                      {" "}
                      {doctor?.clinicExperience} Years
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className={classNames(style.mt8)}
                  >
                    <img src={location} className={style.iconss} />
                    <p className={style.text}>{doctor?.location?.address}</p>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div>
              <p className={classNames(style.headings, style.mt24)}>About</p>
              <div>
                {/* Clicking on text expands it */}
                <p
                  className={classNames(
                    style.text,
                    style.mt24,
                    !isExpanded && style.truncateText
                  )}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? text : previewText}
                </p>

                {/* Read More / Read Less Button (Optional) */}
                {wordsArray?.length > 100 && (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      className={style.readMoreButton}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </button>
                  </div>
                )}
              </div>
              <p className={classNames(style.headings, style.mt24)}>
                Practice Address and Timings{" "}
              </p>
              {/* ...........................................Availability .................................. */}
              {docAvail?.length > 0 ? (
                <>
                  {docAvail[0]?.clinicAvailability?.availability?.length > 0 &&
                    docAvail[0]?.clinicAvailability?.price?.actualPrice && (
                      <div className={classNames(style.timeTable)}>
                        <div
                          className={classNames(style.flxBetween, style.row)}
                        >
                          <p className={classNames(style.tableHeading11)}>
                            Clinic{" "}
                          </p>
                          <p className={classNames(style.tableHeading11)}>
                            Rs{" "}
                            {docAvail[0]?.clinicAvailability?.price
                              ?.actualPrice || 0}
                          </p>
                        </div>
                        {daysOfWeek.map((item: any, index: number) => {
                          const dayAvailability =
                            docAvail[0]?.clinicAvailability?.availability.find(
                              (item: any) => item.dayOfWeek === index
                            );

                          if (dayAvailability) {
                            return (
                              <AvailabilityDays
                                key={index}
                                day={item?.day}
                                data={dayAvailability}
                              />
                            );
                          }
                        })}
                      </div>
                    )}
                  {docAvail[0]?.videoAvailability?.availability?.length > 0 &&
                    docAvail[0]?.videoAvailability?.price?.actualPrice && (
                      <div className={classNames(style.timeTable)}>
                        <div
                          className={classNames(style.flxBetween, style.row)}
                        >
                          <p className={classNames(style.tableHeading11)}>
                            Video Consultancy{" "}
                          </p>
                          <p className={classNames(style.tableHeading11)}>
                            Rs{" "}
                            {docAvail[0]?.videoAvailability?.price
                              ?.actualPrice || 0}
                          </p>
                        </div>
                        {daysOfWeek.map((item: any, index: number) => {
                          const dayAvailability =
                            docAvail[0]?.videoAvailability?.availability.find(
                              (item: any) => item.dayOfWeek === index
                            );

                          if (dayAvailability) {
                            return (
                              <AvailabilityDays
                                day={item?.day}
                                data={dayAvailability}
                              />
                            );
                          }
                        })}
                      </div>
                    )}
                  {docAvail[0]?.inHouseAvailability?.availability?.length > 0 &&
                    docAvail[0]?.inHouseAvailability?.price?.actualPrice && (
                      <div className={classNames(style.timeTable)}>
                        <div
                          className={classNames(style.flxBetween, style.row)}
                        >
                          <p className={classNames(style.tableHeading11)}>
                            In-House
                          </p>
                          <p className={classNames(style.tableHeading11)}>
                            Rs{" "}
                            {docAvail[0]?.inHouseAvailability?.price
                              ?.actualPrice || 0}
                          </p>
                        </div>
                        {daysOfWeek.map((item: any, index: number) => {
                          const dayAvailability =
                            docAvail[0]?.inHouseAvailability?.availability.find(
                              (item: any) => item.dayOfWeek === index
                            );

                          if (dayAvailability) {
                            return (
                              <AvailabilityDays
                                day={item?.day}
                                data={dayAvailability}
                              />
                            );
                          }
                        })}
                      </div>
                    )}

                  {docAvail[0]?.hospitalAvailability?.filter(
                    (hospitalItem: any) => hospitalItem?.price?.actualPrice
                  )?.length > 0 && (
                    <>
                      {docAvail[0]?.hospitalAvailability
                        ?.filter(
                          (hospitalItem: any) =>
                            hospitalItem?.price?.actualPrice
                        )
                        .map((hospitalItem: any, hospitalIndex: number) => (
                          <div
                            key={hospitalIndex}
                            className={classNames(style.timeTable)}
                          >
                            <div
                              className={classNames(
                                style.flxBetween,
                                style.row
                              )}
                            >
                              <p className={classNames(style.tableHeading11)}>
                                {hospitalItem?.hospitalId?.name}
                              </p>
                              <p className={classNames(style.tableHeading11)}>
                                Rs {hospitalItem?.price?.actualPrice || 0}
                              </p>
                            </div>

                            {daysOfWeek.map((item: any, index: number) => {
                              const dayAvailability =
                                hospitalItem?.availability.find(
                                  (availItem: any) =>
                                    availItem.dayOfWeek === index
                                );

                              if (dayAvailability) {
                                return (
                                  <AvailabilityDays
                                    key={index}
                                    day={item?.day}
                                    data={dayAvailability}
                                  />
                                );
                              }
                              return null;
                            })}
                          </div>
                        ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className={style.availabilityContainer}>
                    <p className={style.availabilityText}>
                      This Doctor hasn’t updated their availability yet.
                      <br />
                      Please check back soon
                    </p>
                  </div>
                </>
              )}
              {/* ...........................................Availability .................................. */}
              <div>
                {/* <p className={classNames(style.mt24, style.headings)}>
                      Qualification
                    </p>
                    <p className={classNames(style.mt24, style.text)}>
                      {doctor?.qualifications}
                    </p> */}
                <p className={classNames(style.mt16, style.headings)}>
                  Specialties
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                  className={classNames(style.mt16)}
                >
                  {doctor?.speciality && doctor?.speciality?.length > 0 ? (
                    doctor?.speciality.map(
                      (specialty: string, index: number) => (
                        <button key={index} className={style.SpecialtiesBTN}>
                          {specialty
                            ? specialty.charAt(0).toUpperCase() +
                              specialty.slice(1)
                            : ""}
                        </button>
                      )
                    )
                  ) : (
                    <p>No Specialties Available</p>
                  )}
                </div>
              </div>
              <p className={classNames(style.mt24, style.headings)}>
                Experience
              </p>{" "}
              <div className={style.lists}>
                {doctor?.experience && doctor?.experience?.length > 0 ? (
                  doctor?.experience.map(
                    (experience: string, index: number) => (
                      <ul key={index}>
                        <li>{experience}</li>
                      </ul>
                    )
                  )
                ) : (
                  <p>No Specialties Available</p>
                )}
              </div>
              <p className={classNames(style.mt24, style.headings)}>
                Awards & Achievements
              </p>
              {/* <div className={style.lists}>
                    {doctor?.awardsAndAchievements &&
                    doctor?.awardsAndAchievements.length > 0 ? (
                      doctor?.awardsAndAchievements.map(
                        (awardsAndAchievements: string, index: number) => (
                          <ul>
                            <li>{awardsAndAchievements}</li>
                          </ul>
                        )
                      )
                    ) : (
                      <p>No Specialties Available</p>
                    )}
                  </div> */}
            </div>
          </div>
          <div className={style.w30}>
            <div className={classNames(style.Consult)}>
              <p className={classNames(style.headings)}>Request a Consult</p>
              <p className={classNames(style.text, style.mt8)}>
                Send request & our customer support will book an appointment for
                you.
              </p>
              <p className={classNames(style.headings, style.mt16)}>
                Select your preferred place
              </p>

              <div style={{ marginTop: "16px" }}>
                <New_CustomSelect
                  label="Select"
                  // selectedOption={selectedPlace}
                  setSelectedOption={() => {}}
                  id={"place"}
                  onChange={(e: any) => handleSelectPlace(e?.target?.value)}
                  options={[
                    { label: "Clinic", value: "clinic" },
                    { label: "Hospital", value: "hospital" },
                    { label: "Online", value: "online" },
                    { label: "InHouse", value: "inhouse" },
                  ]}
                />
              </div>

              {selectedPlace === "hospital" && (
                <div style={{ marginTop: "16px" }}>
                  <New_CustomSelect
                    id={"hospital"}
                    setSelectedOption={() => {}}
                    label="Select Hospital"
                    // selectedOption={""}
                    onChange={(e: any) =>
                      handleSelectHospital(e?.target?.value)
                    }
                    options={tempHos}
                  />
                </div>
              )}
              <p className={classNames(style.headings, style.mt16)}>
                Select your preferred day
              </p>
              <div className={classNames(style.FlxClum, style.mt24)}>
                {daysOfWeek.map((item: any, index: number) => (
                  <button
                    key={index}
                    style={{
                      border:
                        selectedDay === item.day
                          ? "1px solid #ff7631"
                          : "0.5px solid #7d7d7d",
                      color: selectedDay === item.day ? "#ff7631" : "#7d7d7d",
                    }}
                    className={style.dayBtn}
                    onClick={() => setSelectedDay(item.day)}
                  >
                    {item.day}
                  </button>
                ))}

                <button className={style.BookingBtn} onClick={handelGoPayment}>
                  {" "}
                  Book Appointment
                </button>
              </div>
            </div>
            <div className={classNames(style.Consult, style.mt24)}>
              <p className={style.headings}>Why Us?</p>
              <div className={classNames(style.flx, style.mt16)}>
                {" "}
                <img src={Priority} className={style.iconss} />
                <p className={style.bluetext}>Priority Customer Support</p>
              </div>
              <div className={classNames(style.flx, style.mt8)}>
                {" "}
                <img src={Private} className={style.iconss} />
                <p className={style.bluetext}>100% Private & Secure</p>
              </div>
              <div className={classNames(style.flx, style.mt8)}>
                <img src={Encryption} className={style.iconss} />
                <p className={style.bluetext}>End-to-end Encryption</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={classNames(style.banner)}>
            <div className={classNames(style.maincontainer)}>
              <div className={classNames(style.col1)}>
                <DoctorProfile_Card doc={doc} data={data} />
              </div>

              <div className={style.col2}>
                <div>
                  <p className={style.heading}>
                    {data?.name || doc?.name}
                    <p className={style.experience}>
                      ({data?.doctor?.clinicExperience || doc?.clinicExperience}{" "}
                      years)
                    </p>
                  </p>
                  <div className={classNames(commonstyle.mt12)}>
                    <p className={style.title}>
                      {data?.doctor?.qualifications || doc?.qualifications}
                    </p>
                  </div>
                  {data?.doctor?.about ||
                    (doc?.about && (
                      <p className={style.experience}>
                        {data?.doctor?.about || doc?.about}
                      </p>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div className={style.showMoreContainer}>
                    <button
                      className={style.showMoreButton}
                      disabled={loading}
                      onClick={handleViewMoreClick}
                    >
                      Appointment
                      <span className={style.icon}>
                        <IoMdArrowForward />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

      {/* <div className={style.detailarea}>
            <div className={style.areaconatiner}>
              <div className={style.detailcol1}>
                <div className={style.flx}>
                  <p className={style.icons}>
                    <RiCalendarCheckFill />
                  </p>
                  <p className={style.deatilheading}>Appointment Schedules</p>
                </div>

                {state?.speciality && (
                  <div className={classNames(CommonStyless.mt24)}>
                    <div
                      className={classNames(
                        CommonStyless.mb32,
                        CommonStyless.mt16
                      )}
                    >
                      <div className={classNames(style.tcard)}>
                        <p
                          className={classNames(
                            CommonStyless.colorBlue,
                            CommonStyless.fs18,
                            CommonStyless.semiBold
                          )}
                        >
                          Package Include :
                        </p>
                        <p
                          className={classNames(
                            CommonStyless.colorBlue,
                            CommonStyless.fs16
                          )}
                        >
                          Procedure
                        </p>
                        <p
                          className={classNames(
                            CommonStyless.colorBlue,
                            CommonStyless.fs16
                          )}
                        >
                          Medicines
                        </p>
                        <div
                          className={classNames(
                            CommonStyless.flx,
                            CommonStyless.flxBetween
                          )}
                        >
                          <p
                            className={classNames(
                              CommonStyless.colorBlue,
                              CommonStyless.fs18,
                              CommonStyless.semiBold
                            )}
                          >
                            Total Cost:
                          </p>
                          <p
                            className={classNames(
                              CommonStyless.colorBlue,
                              CommonStyless.fs16
                            )}
                          >
                            {state?.doc?.totalAmount}
                            <span> / -</span>
                          </p>
                        </div>

                    <div>
                    
                   
                
                  </div>
                      </div>
                    </div>

                    <p
                      className={classNames(
                        CommonStyless.colorBlue,
                        CommonStyless.fs24,
                        CommonStyless.semiBold
                      )}
                    >
                      Availability
                    </p>
                  </div>
                )}

                {state?.opdType == "Free OPD" ||
                state?.opdType == "Paid OPD" ? (
                  video_Availability?.price?.actualPrice &&
                  video_Availability && (
                    <Avail_Card
                      subTitle="Video"
                      state={state}
                      source={<IoIosVideocam />}
                      data={video_Availability}
                    />
                  )
                ) : (
                  <>
                    {clinic_Availability?.availability?.length > 0 &&
                      clinic_Availability?.price?.actualPrice &&
                      clinic_Availability && (
                        <Avail_Card
                          subTitle="Clinic"
                          source={<FaClinicMedical />}
                          data={clinic_Availability}
                        />
                      )}
                    {video_Availability?.availability?.length > 0 &&
                      state?.speciality === false &&
                      video_Availability?.price?.actualPrice &&
                      video_Availability &&
                      state?.type !== "speciality" && (
                        <Avail_Card
                          subTitle="Video"
                          source={<IoIosVideocam />}
                          data={video_Availability}
                        />
                      )}

                    {inHouse_Availablity?.availability?.length > 0 &&
                      state?.speciality === false &&
                      inHouse_Availablity?.price?.actualPrice &&
                      inHouse_Availablity &&
                      state?.type !== "speciality" && (
                        <Avail_Card
                          subTitle="In House"
                          source={<FaHouseChimneyWindow />}
                          data={inHouse_Availablity}
                        />
                      )}

                    {availableHospitals?.length > 0 &&
                      hospital_Availablity?.map((item: any) => (
                        <Avail_Card
                          subTitle={item?.hospitalId?.name}
                          source={<FaHospital />}
                          data={item}
                        />
                      ))}
                  </>
                )}
              </div>
              <div className={style.detailcol2}>
                <div className={style.flx}>
                  <p className={style.icons}>
                    <RiGraduationCapFill />
                  </p>
                  <p className={style.deatilheading}>Degrees</p>
                </div>

                <Details
                  title={[
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                  ]}
                  detail={[
                    "Medical degree",
                    "Bachelor of Science",
                    "Bachelor of Science",
                  ]}
                />

                <div className={classNames(commonstyle.mt76)}>
                  <div className={style.flx}>
                    <p className={style.icons}>
                      <FaBriefcase />
                    </p>
                    <p className={style.deatilheading}>Experiences</p>
                  </div>
                  <Details
                    title={[
                      "University of California, San Francisco",
                      "University of California, San Francisco",
                      "University of California, San Francisco",
                    ]}
                    detail={[
                      "Medical degree",
                      "Bachelor of Science",
                      "Bachelor of Science",
                    ]}
                  />
                </div>

                <div className={classNames(commonstyle.mt72)}>
                  <div className={style.flx}>
                    <p>
                       <SlBadge
                      size={24}
                      style={{ fontWeight: "bold", strokeWidth: 2 }}
                    /> 
                      <img
                        src={award}
                        alt=""
                        style={{
                          width: "18px",
                          height: "28px",
                          marginRight: "15px",
                        }}
                      />
                    </p>
                    <p className={style.deatilheading}>Awards/Achievements</p>
                  </div>
                  <Details
                    title={[
                      "University of California, San Francisco",
                      "University of California, San Francisco",
                      "University of California, San Francisco",
                    ]}
                  />
                </div>
              </div>
            </div>
        <div className={classNames(commonstyle.conatiner)}>
              <ReviewComponent
                ratings={ratings}
                name={data?.name || doc?.name}
              />
            </div> 
          </div>  */}
      {loading && <CustomLoader />}
      <Footerr />
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default DoctorDetails;

const AvailabilityDays = ({ day, data }: any) => {
  function formatToAmPm(time: string) {
    return dayjs(time, "HH:mm").format("hh:mm A");
  }

  return (
    <div className={classNames(style.flxBetween, style.row)}>
      <div className={style.tableSection2}>
        <p className={style.tableHeading}>{day}</p>
      </div>
      <div className={style.tableSection}>
        <p className={style.tabletext}>
          {data?.morning?.startTime
            ? `${formatToAmPm(data.morning.startTime)} To ${formatToAmPm(
                data.morning.endTime
              )}`
            : "--"}
        </p>
      </div>
      <div className={style.tableSection}>
        <p className={style.tabletext}>
          {data?.evening?.startTime
            ? `${formatToAmPm(data.evening.startTime)} To ${formatToAmPm(
                data.evening.endTime
              )}`
            : "--"}
        </p>
      </div>
    </div>
  );
};

// const DoctorProfile_Card = ({ doc, data }: any) => {
//   return (
//     <div className={classNames(style.profilcard)}>
//       <div className={classNames(style.profileimgcontainer)}>
//         <img
//           src={
//             data?.doctor?.doctorImage ||
//             doc?.doctorImage ||
//             "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
//           }
//           alt={data?.doctor?.doctorImage}
//           className={classNames(style.profileImage)}
//         />
//       </div>
//       <div className={classNames(style.textarea)}>
//         <p>
//           {data?.doctorKind
//             ? data.doctorKind.charAt(0).toUpperCase() + data.doctorKind.slice(1)
//             : doc?.doctorKind
//             ? doc.doctorKind.charAt(0).toUpperCase() + doc.doctorKind.slice(1)
//             : ""}
//         </p>
//       </div>
//     </div>
//   );
// };

// const Avail_Card = ({ data, subTitle, source, priceAvail, state }: any) => {
//   return (
//     <div className={classNames(commonstyle.mt24)}>
//       <div className={style.flx}>
//         <p className={style.dicons}>{source}</p>
//         <div
//           className={classNames(commonstyle.flx, commonstyle.flxBetween)}
//           style={{
//             width: "74%",
//           }}
//         >
//           <p className={style.deatiltitle}>{subTitle || "undefined"}</p>
//           {state?.opdType == "Free OPD" ? null : (
//             <p className={style.deatiltitle}>
//               {" "}
//               Fee: {data?.price?.actualPrice || 0}/-
//             </p>
//           )}
//         </div>
//       </div>
//       <Cards days={data} />
//     </div>
//   );
// };
