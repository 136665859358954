import Footerr from "pages/Home/HomeNavBar/Footer";
import { useNavigate } from "react-router-dom";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import ServiceHighlightCard from "shared/components/ServiceHighlightCard";
import { DOCTOR } from "shared/utils/mainHeaderQuery";
import styles from "./styles.module.css";
import ServiceHeader from "shared/components/ServicesHeaders";

const Nav_SelectionCards = ({ data }: { data?: any }) => {
  const navigate = useNavigate();

  const handleNavigate = (name: any) => {
    if (name === "Travel Agency") {
      navigate("/services/travel");
    } else if (name === "Rent A Car") {
      navigate("/services/rentacar");
    } else if (name === "Ambulance") {
      navigate("/services/ambulance");
    } else if (name === "Nurses") {
      navigate("/services/paramedicstaff");
    } else {
      navigate("/services/doctor", {
        state: { serviceName: name == "Doctors" ? "Doctor" : name },
      });
    }
  };

  return (
    <>
      <div>
        {/* <NavBreadCrumbs {...DOCTOR} /> */}
        <ServiceHeader
          headingBlue="How Can We "
          headingOrange="Assist You?"
          desc_width="70%"
          content="Explore our trusted network of doctors and hospitals, ensuring you receive the best medical care tailored to your needs. Let us guide you on your healthcare journey with ease and confidence."
        />
      </div>
      <div style={{ backgroundColor: "#fdfdfd" }}>
        <div className={styles.container}>
          <ServiceHighlightCard data={data} onPress={handleNavigate} />
        </div>
      </div>
      <Footerr />
    </>
  );
};

export default Nav_SelectionCards;
