import classNames from "classnames";
import React, { useState, useEffect, ChangeEvent } from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import CustomLoader from "shared/components/New_Loader/Loader";
import styles from "./completed.module.css";
// import commomstyles from "../../../../../shared/utils/common.module.css";
import commomstyles from "../../../.././shared/utils/common.module.css";
import { getallParamedicRequest, paramedicStatus } from "shared/services";
import LabEmpty from "shared/components/LabEmpty";
import { RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
function AvailabilityCategory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  useEffect(() => {
    requestFetch();
  }, []);
  const requestFetch = () => {
    setLoading(true);
    let data = {
      status: "completed",
    };
    getallParamedicRequest(data)
      .then((res?: any) => {
        setData(res?.data?.paramedicRequests);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    requestFetch();
  };
  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />
        <div className={commomstyles.mr87}>
          <div className={styles.outerContainer}>
            <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
              <div className={classNames(commomstyles.flx)}>
                <p
                  className={classNames(
                    commomstyles.fs24,
                    commomstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  All Request
                </p>
                {loading ? (
                  <div style={{ marginLeft: "16px" }}>
                    <RingLoader color={"#ee7e37"} size={30} />
                  </div>
                ) : (
                  <div className={styles.outerRefresh}>
                    <TbRefresh
                      className={styles.RefreshIcon}
                      style={{ transform: `rotate(${rotation}deg)` }}
                      onClick={handleRotate}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* {loading ? (
                <CustomLoader />
              ) : ( */}
            <div className={commomstyles.mb32}>
              <>
                <div className={styles.outerContainer}>
                  <div className={styles.payment}>
                    {data.length > 0 && (
                      <div className={styles.headerOuter}>
                        <p className={styles.headerclass}>name</p>
                        <p className={styles.headerclass}>Email</p>
                        <p className={styles.headerclass}>Contact</p>
                        <p className={styles.headerclass}>Preferred</p>
                        <p className={styles.headerclass}>Preferred Date</p>
                        <p className={styles.headerclass}>Preferred Time</p>
                        <p className={styles.headerclass}>Schedule</p>
                        <p className={styles.headerclass}>Status</p>
                      </div>
                    )}
                    <div className={styles.tableData}>
                      {data.length > 0 ? (
                        <table
                          style={{
                            margin: "0px",
                          }}
                        >
                          <tbody className={styles.wapper}>
                            {data.map((val: any, key: any) => {
                              return (
                                <>
                                  <tr
                                    className={styles.tableRow}
                                    style={{ marginBottom: "24px" }}
                                  >
                                    <td className={styles.w20}>{val.name}</td>
                                    <td className={styles.w20}>{val.email}</td>
                                    <td className={styles.w20}>
                                      {val.contact}
                                    </td>
                                    <td className={styles.w20}>{val.gender}</td>
                                    <td className={styles.w20}>
                                      {new Date(
                                        val?.preferredDate
                                      ).toLocaleDateString()}
                                    </td>
                                    <td className={styles.w20}>
                                      {val?.preferredTime}
                                    </td>
                                    <td className={styles.w20}>
                                      {val?.schedule}
                                    </td>
                                    <td className={styles.w20}>
                                      {val?.status}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <PhysiotheristsEmpty />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default AvailabilityCategory;
