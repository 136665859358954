import React from "react";
import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Vendor_PaymentDetails } from "shared/components";

const HospitalPaymentDetails = () => {
  return (
    <div style={{marginTop:"24px"}}>
       <Vendor_PaymentDetails />
    </div>
  );
};

export default HospitalPaymentDetails;

const Details = ({ title, value }: { title?: string; value?: string }) => {
  return (
    <div className={classNames(commonstyle.flxBetween)}>
      <p className={style.title}>{title}</p>
      <p className={style.value}>{value}</p>
    </div>
  );
};
