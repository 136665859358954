import React from "react";
import style from "./style.module.css";
import green from "assets/images/grenFLex.png";
const Cultural = ({ data, title }: { data?: any; title?: any }) => {
  return (
    <div className={style.mainContainer}>
      <div className={style.tourism}>
        <img src={data?.img1} className={style.topImage} />
        {title === "PAKISTAN" && (
          <img src={green} className={style.greenImage} />
        )}
        <div className={style.bottomDiv}>
          <div className={style.topView}>
            <div className={style.header}>
              <div className={style.topText}>{data?.Heading}</div>
              <div className={style.bar} />
            </div>
            {/* <div className={style.right}>
              <div className={style.bookNow}>Book Now</div>
            </div> */}
            <p className={style.customTest}>{data?.h1}</p>
            <p className={style.para}>{data?.paragraph}</p>
          </div>
        </div>
      </div>
      <div className={style.tourism}>
        <img src={data?.img2} className={style.topImage} />
        {title === "PAKISTAN" && (
          <img src={green} className={style.greenImage} />
        )}
        <div className={style.bottomDiv}>
          <div className={style.topView}>
            <div className={style.header}>
              <div className={style.topText}>{data?.Heading2}</div>
              <div className={style.bar} />
            </div>
            {/* <div className={style.right}>
              <div className={style.bookNow}>Book Now</div>
            </div> */}
            <p className={style.customTest}>{data?.h2}</p>

            <p className={style.para}>{data?.paragraph2}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cultural;
