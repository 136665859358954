import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import style from "./Donationpackages.module.css";
import commonstyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import empty from "assets/images/empty2.png";
import * as Yup from "yup";
import { Navigate, useNavigate } from "react-router-dom";

import {
  CustomInput,
  CustomModal,
  CustomStepper,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { Avatar, Checkbox } from "@mui/material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import CustomSelect from "shared/components/CustomSelect";
import ImagePickerNew from "shared/components/FilePickeInsurance/ImagePickerNew";

import {
  donation_GET_ALL_Packages,
  donationAddCriteria,
  donationAddPackage,
  donationGETALLCriteria,
  donationGETALLPACK,
} from "shared/services/Donation";
import {
  donationAddCriteriaSchema,
  donationAddPackageSchema,
} from "shared/utils";
import ShortImagePicker from "shared/components/FilePickeInsurance/ShortImageFilePicker";
import { useDispatch, useSelector } from "react-redux";
import { setDonationType, setPackages } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
import { TbRefresh } from "react-icons/tb";
import toast from "react-hot-toast";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

const TotalDays = ["10 Days", "20 Days", "30 Days"];
interface PackageItem {
  donationType: string;
  requiredAmount: number;
  targetAudience: string;
  totalDays: number;
  donationTitle: string;
  _id: string;
  images: string[];
}
const steps = [
  {
    id: "0",
    lable: "Basic Info",
  },
  {
    id: "1",
    lable: "Image Upload",
  },
];
const DonationCategorie = () => {
  const [selectedType, setselectedType] = useState("");
  const [loading, setLoading] = useState(false);
  const [packages, setPackagess] = useState<PackageItem[]>([]);
  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;
  const [showPackageModal, setShowPackageModal] = useState(false);
  const dispatch = useDispatch();
  const { PackagesArray, donationType, donationPackageRenderFlag, donation } =
    useSelector((state: any) => state.root.donation);

  //

  const handleModelOpen = () => {
    setShowPackageModal(true);
  };
  // const handleModelPackageDetailOpen = () => {
  //   setShowPackageDetailModal(true);
  // };

  // const handleModelCriteriaOpen = () => {
  //   setShowCriteriaModal(true);
  // };
  const navigate = useNavigate();
  const handleGoToDetail = (_id: string) => {
    navigate(`/donation/Details/${_id}`);
  };
  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setselectedType(event.target.value);
    dispatch(setDonationType(event.target.value));
  };

  const FetchPackage = () => {
    setLoading(true);
    donation_GET_ALL_Packages()
      .then((res) => {
        dispatch(setPackages(res?.data?.packages));
        setPackagess(res.data.packages);
      })
      .catch((err) => {
        // Safely log the error
        console.error(`Error: ${err?.response?.status ?? "Unknown error"}`);
        // Optionally, log the full error for debugging
        console.error("Full Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (donationPackageRenderFlag === false) {
    FetchPackage();
    // }
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={commonstyles.outerContainer}>
        <div className={classNames(commonstyles.flx)}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Donation Packages
          </p>
          {loading ? (
            <div style={{ marginLeft: "8px" }}>
              {" "}
              {/* Reduce margin for a smaller gap */}
              <RingLoader color={"#0D47A1"} size={30} />
            </div>
          ) : (
            <div className={style.outerRefresh}>
              <TbRefresh
                className={style.RefreshIcon}
                style={{ transform: `rotate(${rotation}deg)` }}
                onClick={() => {
                  FetchPackage();
                }}
              />
            </div>
          )}

          <div style={{ marginLeft: "auto" }} className={commonstyles.flx}>
            {/* <div className={style.endd}>
                  <select onChange={handleSelectionChange} value={donationType}>
                    <option value="Food">Food</option>
                    <option value="Education">Education</option>
                  </select>
                </div> */}

            <div style={{ width: "204px" }}>
              <PrimaryButton
                children={"Add Package"}
                colorType={"blue"}
                onClick={handleModelOpen}
              />
            </div>
          </div>
        </div>
        {/* apply css for responsive */}
        <div
          className={style.flxWrap}
          style={{
            padding: "24px ",
          }}
        >
          {PackagesArray && PackagesArray.length > 0 ? (
            PackagesArray.map((packageItem: any) => (
              <div
                className={classNames(
                  style.ccard,
                  window.innerWidth < 768 && commonstyles.col12
                )}
                onClick={() => handleGoToDetail(packageItem._id)}
                style={{ width: window.innerWidth < 768 ? "100%" : "auto" }}
                key={packageItem._id}
              >
                <div
                  style={{
                    height: "175px",
                  }}
                >
                  <div>
                    {packageItem.images && packageItem.images[0] && (
                      <img
                        src={packageItem.images[0]}
                        className={style.Donation1}
                        alt="Package Image"
                      />
                    )}
                  </div>
                  <div className={commonstyles.flxBetween}>
                    {packageItem.images && packageItem.images[1] && (
                      <img
                        src={packageItem.images[1]}
                        className={style.Donation2}
                        alt="Package Image"
                      />
                    )}
                    {packageItem.images && packageItem.images[2] && (
                      <img
                        src={packageItem.images[2]}
                        className={style.Donation2}
                        alt="Package Image"
                      />
                    )}
                  </div>
                </div>
                <div className={style.mt16}>
                  <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold
                    )}
                  >
                    {packageItem.donationTitle}
                  </p>
                </div>
                <div className={classNames(commonstyles.flx, style.mt32)}>
                  <div className={style.mr50}>
                    <p className={commonstyles.fs14}>Target Audience:</p>
                    <p className={classNames(commonstyles.fs14, style.mt8)}>
                      Total Amount:
                    </p>
                    <p className={classNames(commonstyles.fs14, style.mt8)}>
                      No. Of Days
                    </p>
                  </div>
                  <div>
                    <p className={commonstyles.fs14}>
                      {packageItem.targetAudience}
                    </p>
                    <p className={classNames(commonstyles.fs14, style.mt8)}>
                      {packageItem.requiredAmount}
                    </p>
                    <p className={classNames(commonstyles.fs14, style.mt8)}>
                      {packageItem.totalDays}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>
      {/* )} */}

      <CustomModal
        showModal={showPackageModal}
        children={
          <Package
            setShowPackageModal={setShowPackageModal}
            FetchPackage={FetchPackage}
          />
        }
      />
    </div>
  );
};
export default DonationCategorie;
interface PacProps {
  setShowPackageModal: any;
  FetchPackage: any;
}
const Package = (props: Partial<PacProps>) => {
  const [loading, setLoading] = useState(false);
  const [showcriteria, setShowcriteria] = useState([]);
  const [criteriamodel, setCriteriamodel] = useState(false);
  const [packageDetails, setPackageDetails] = useState(false);
  const [selectedCriterionId, setSelectedCriterionId] = useState<string>("");
  const [error, setError] = useState<any>("");

  const navigate = useNavigate();
  const handleGoToNextscreen = () => {
    navigate("/donation/Categories");
  };
  const { setShowPackageModal, FetchPackage } = props;

  const handleCloseModal = () => {
    setShowPackageModal(false);
  };
  const handleCriteriaOpen = () => {
    setCriteriamodel(true);
  };
  const handlePackageDetailsModel = () => {
    if (!selectedCriterionId) {
      setError("Please select at least one Criteria.");
    } else {
      setError("");
      setPackageDetails(true);
    }
  };

  const handleCheckboxChange = (criterionId: string) => {
    if (criterionId) {
      setSelectedCriterionId((prevId) => {
        const isSameId = prevId === criterionId;
        const newId = isSameId ? "" : criterionId;

        if (newId) {
          setError("");
        }
        return newId;
      });
    }
  };

  const GetAllCriteria = () => {
    setLoading(true);
    donationGETALLCriteria()
      .then((res: any) => {
        if (res?.status === 200) {
          setShowcriteria(res.data.criterion);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetAllCriteria();
  }, []);

  return (
    <div className={style.modelcontainer}>
      <div className={style.modelHeader}>
        <p>Add Package </p>
        <IoClose className={style.end} onClick={handleCloseModal} />
      </div>
      <div className={classNames(style.flxWrap)}>
        {showcriteria.map((criterion: any) => (
          <div
            className={`${style.OrphansCard} ${
              selectedCriterionId === criterion._id ? style.checked : ""
            }`}
            style={{
              background: `url(${criterion.image}) no-repeat center center`,
              backgroundSize: "cover",
            }}
            onClick={() => handleCheckboxChange(criterion._id)}
          >
            <div style={{ display: "flex", alignItems: "end", height: "70%" }}>
              <p className={style.alignend}>{criterion.criteriaName}</p>
            </div>
          </div>
        ))}

        <div className={style.AddCard} onClick={handleCriteriaOpen}>
          <IoMdAddCircleOutline className={style.AddIcon} />

          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              style.AddCriteria
            )}
          >
            Add Critera
          </p>
        </div>
        <div className={style.categoryCard}></div>
      </div>
      <div className={style.mt32}>
        {error && <div className={commonStyles.error}>{error}</div>}

        <PrimaryButton
          children={"Next"}
          colorType={"blue"}
          onClick={handlePackageDetailsModel}
        />
      </div>
      {/* <CustomModal
      showModal={setShowPackageDetailModal}
      children={<Criteria/>}
      /> */}
      <CustomModal
        showModal={criteriamodel}
        children={
          <Criteria
            setCriteriamodel={setCriteriamodel}
            GetAllCriteria={GetAllCriteria}
          />
        }
      />
      <CustomModal
        showModal={packageDetails}
        children={
          <PackageDetail
            setPackageDetails={setPackageDetails}
            selectedCriteriaIds={selectedCriterionId}
            setShowPackageModal={setShowPackageModal}
            FetchPackage={FetchPackage}
          />
        }
      />
    </div>
  );
};

const Criteria = (props: any) => {
  const CriteriaNames = ["Food", "Education"];
  const [showAddModal, setShowAddModal] = useState(false);
  const [image, setImage] = useState(false);
  const navigate = useNavigate();
  const { setShowCriteriaModal, setCriteriamodel, GetAllCriteria } = props;
  const handleCloseModal = () => {
    setCriteriamodel(false);
  };
  const handleCriteriaOpen = () => {
    setCriteriamodel(true);
  };

  const formik = useFormik({
    initialValues: {
      criteriaName: "",
      description: "",
      image: "",
    },
    validationSchema: Yup.object(donationAddCriteriaSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleImageUrl = (url: any) => {
    setImage(false);
    formik.setFieldValue("image", url);
  };
  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("criteriaName", selectedOption);
  };
  const handleCriteriaName = (selectedOption: string) => {
    formik.setFieldValue("criteriaName", selectedOption);
  };
  const handleSubmit = async () => {
    const currentData = formik.values;
    let params = {
      criteriaName: currentData.criteriaName,
      description: currentData.description,
      image: currentData.image,
    };
    donationAddCriteria(params)
      .then((res: any) => {
        setCriteriamodel(false);
        GetAllCriteria();
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.flx}>
          <div className={classNames(style.modelHeader, style.mb32)}>
            <p style={{ textAlign: "center" }}>Add Criteria</p>
            <IoClose className={style.close} onClick={handleCloseModal} />
          </div>
        </div>
        <div className={classNames(style.mb32)}>
          <CustomInput
            placeholder="Criteria Name"
            id="criteriaName"
            name="criteriaName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.criteriaName}
          />

          {formik.touched.criteriaName && formik.errors.criteriaName ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.criteriaName}
            </div>
          ) : null}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <img src={} className={style.imgPick} /> */}
          {/* <img src={} className={style.imgPick} /> */}
        </div>
        <div>
          <div className={classNames(commonstyles.col12, style.ImgPicker)}>
            <ImagePickerNew setData={handleImageUrl} />
            {formik.touched.image && formik.errors.image ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.image}
              </div>
            ) : null}
          </div>
          <div className={classNames(commonstyles.col12, style.description)}>
            <textarea
              placeholder="Description"
              style={{ resize: "none" }}
              onChange={formik.handleChange}
              id="description"
              name="description"
              value={formik.values.description}
            ></textarea>
            {formik.touched.description && formik.errors.description ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.description}
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: "16px" }}>
            <PrimaryButton children={"Add"} type="submit" colorType={"green"} />
          </div>
        </div>
      </form>
    </div>
  );
};

interface Props {
  selectedCriteriaIds: any;
  setShowPackageModal: any;
  setPackageDetails: any;
  FetchPackage: any;
}
const PackageDetail = (props: Partial<Props>) => {
  const [addData, setAddData] = useState({});
  const [image, setImage] = useState(false);

  const navigate = useNavigate();
  const handleGoToNextscreen = () => {
    navigate("/donation/packages");
  };
  const {
    selectedCriteriaIds,
    setShowPackageModal,
    setPackageDetails,
    FetchPackage,
  } = props;

  const handleCloseModal = () => {
    setPackageDetails(false);
  };

  const [screenName, setScreenName] = useState("Basic Info");
  const [selectedStep, setSelectedStep] = useState(0);

  const handleClickNext = () => {
    if (screenName === "Basic Info") {
      setScreenName("Image Upload");
    }
  };
  const handleClicKPrev = () => {
    if (screenName === "Image Upload") {
      setScreenName("Basic Info");
    }
  };

  return (
    <>
      <div>
        <div className={style.modelHeader}>
          <p style={{ textAlign: "center" }}>Add Package details </p>
          <IoClose className={style.close} onClick={handleCloseModal} />
        </div>
        {/* <div
          style={{ marginBottom: "16px", marginTop: "16px" }}
          className={classNames(
            commonstyles.flx,
            commonstyles.col6,
            commonStyles.colorBlue
          )}
        >
          <div
            className={classNames(commonstyles.mr24, commonStyles.colorBlue)}
          >
            <IoArrowBack
              onClick={handleClicKPrev}
              style={{ width: "24px", height: "24px", color: "#00276d" }}
            />
          </div>
          <p className={classNames(commonstyles.semiBold, commonstyles.fs16)}>
            Back
          </p>
        </div> */}
        <div className={classNames(commonstyles.col10, commonstyles.mt16)}>
          <CustomStepper steps={steps} selectedStep={selectedStep} />
        </div>
        <div className={classNames(commonstyles.col12, commonstyles.mt40)}>
          {screenName == "Basic Info" && (
            <BasicInfo
              handleClickNext={handleClickNext}
              setAddData={setAddData}
            />
          )}
          {screenName == "Image Upload" && (
            <ImageUpload
              addData={addData}
              selectedCriteriaIds={selectedCriteriaIds}
              setShowPackageModal={setShowPackageModal}
              FetchPackage={FetchPackage}
            />
          )}
        </div>
      </div>
    </>
  );
};
interface Props {
  handleClickNext: any;
  setAddData: any;
}
const BasicInfo = (props: Partial<Props>) => {
  const handleSelectDays = (selectedOption: string) => {
    formik.setFieldValue("totalDays", selectedOption);
  };
  const { handleClickNext, setAddData } = props;
  const formik = useFormik({
    initialValues: {
      donationTitle: "",
      targetAudience: "",
      totalRequiredAmount: "",
      totalDays: "",
      description: "",
    },
    validationSchema: Yup.object(donationAddPackageSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    const currentData = formik.values;
    setAddData({
      donationTitle: currentData.donationTitle,
      targetAudience: currentData.targetAudience,
      requiredAmount: currentData.totalRequiredAmount,
      totalDays: currentData.totalDays,
      description: currentData.description,
    });

    handleClickNext();
  };
  return (
    <div>
      <div style={{ width: "400px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.mb32,
                style.mt32
              )}
            >
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomInput
                  placeholder="Package Title"
                  id="donationTitle"
                  name="donationTitle"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.donationTitle}
                />
                {formik.touched.donationTitle && formik.errors.donationTitle ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.donationTitle}
                  </div>
                ) : null}
              </div>
              <div className={classNames(commonstyles.col6)}>
                <CustomInput
                  placeholder="Target Audience"
                  id="targetAudience"
                  name="targetAudience"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.targetAudience}
                />
                {formik.touched.targetAudience &&
                formik.errors.targetAudience ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.targetAudience}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classNames(commonstyles.flx, commonstyles.mb32)}>
              <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
                <CustomInput
                  placeholder="Required Amount"
                  id="totalRequiredAmount"
                  name="totalRequiredAmount"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.totalRequiredAmount}
                />
                {formik.touched.totalRequiredAmount &&
                formik.errors.totalRequiredAmount ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.totalRequiredAmount}
                  </div>
                ) : null}
              </div>
              <div className={classNames(commonstyles.col6)}>
                <CustomSelect
                  placeholder="Select Days"
                  onSelect={handleSelectDays}
                  options={TotalDays}
                />
                {formik.touched.totalDays && formik.errors.totalDays ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.totalDays}
                  </div>
                ) : null}
              </div>
            </div>

            <div className={classNames(commonstyles.col12, style.description)}>
              <textarea
                placeholder="Description"
                style={{ resize: "none" }}
                id="description"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ width: "204px", marginTop: "32px" }}>
            <PrimaryButton children={"Next"} colorType={"blue"} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

interface ImageProps {
  addData: any;
  selectedCriteriaIds: any;
  setAddData: any;
  setShowPackageModal: any;
  FetchPackage: any;
}
const ImageUpload = (props: Partial<ImageProps>) => {
  const [loading, setLoading] = useState(false);
  const { addData, selectedCriteriaIds, setShowPackageModal, FetchPackage } =
    props;
  const [error, setError] = React.useState("");
  // const { urlArray, setUrlArray }: any = useState<any>([]);
  const [imageURLs, setImageURLs] = useState<string[]>([]);

  const [imageLoading, setImageLoading] = useState<boolean[]>([]);
  const firstImageURL = (url: any) => {
    if (imageURLs.length < 3) {
      setImageURLs((prevURLs) => {
        const newURLs = [...prevURLs, url];
        setImageLoading([...imageLoading, true]);
        if (newURLs.length > 0) {
          setError("");
        }
        return newURLs;
      });
    } else {
      setError("Maximum limit reached. Can't add more Image.");
    }
  };
  const handleImageLoad = (index: number) => {
    setImageLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false; // Set loading to false when image is loaded
      return newLoading;
    });
  };
  const FinalSubmittion = () => {
    if (imageURLs.length === 0) {
      setError("Please upload at least one image.");
      return;
    }

    let params = {
      criteriaId: selectedCriteriaIds,
      ...addData,
      images: imageURLs,
    };

    setLoading(true);

    donationAddPackage(params)
      .then((res: any) => {
        setShowPackageModal(false);

        FetchPackage();
      })
      .catch((err: any) => {
        // Added optional chaining for safety
        setError("An error occurred. Please try again."); // Set an error message in case of failure
      })
      .finally(() => {
        setLoading(false); // Ensure loading is reset in the finally block
      });
  };
  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div style={{ width: "400px" }}>
        <div className={classNames(commonstyles.col12, style.ImgPicker)}>
          <ShortImagePicker setData={firstImageURL} />
        </div>
        {imageURLs.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: "10px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {imageURLs.map((url: string, index: number) => (
              <div
                key={index}
                style={{ position: "relative", width: "calc(33.33% - 10px)" }}
              >
                {imageLoading[index] && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    Loading...
                  </div>
                )}
                <img
                  src={url}
                  alt={`Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "100px",
                    objectFit: "cover",
                  }}
                  onLoad={() => handleImageLoad(index)} // Triggered when image is loaded
                />
              </div>
            ))}
          </div>
        )}
        {error && <div className={commonStyles.error}>{error}</div>}
        <div style={{ width: "204px", marginTop: "32px" }}>
          <PrimaryButton
            children={"Save"}
            colorType={"blue"}
            onClick={FinalSubmittion}
          />
        </div>
      </div>
      {/* )} */}
    </>
  );
};
