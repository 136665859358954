import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { CustomInput, InputField, RingLoader } from "shared/components";
import { resetPassword } from "shared/services";
import { GetColorCode, user_ResetPassword } from "shared/utils";
import { PrimaryButton } from "shared/components";
import { initialValues, onSumbit } from "./resetProps";
import { useLocation, useNavigate } from "react-router-dom";
import formstyle from "../../Doctor/login/style.module.css";

import * as Yup from "yup";
import toast from "react-hot-toast";
import classNames from "classnames";
import LoginStyles from "./Uselogin.module.css";
import CommonStyles from "shared/utils/common.module.css";
import PasswordInput from "shared/components/PasswordInput";
import CustomLoader from "shared/components/New_Loader/Loader";
import styles from "././SignUplogin/userSignUpMain.module.css";
import style from "../../Doctor/login/style.module.css";
import { FaChevronLeft } from "react-icons/fa";
import commonStyles from "shared/utils/common.module.css";
import logo from "assets/images/logoMed.png";
import { notifyError } from "shared/components/A_New_Components/ToastNotification";
import { __sendLinkToEmail } from "shared/services/DoctorService";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { systemType } = useSelector((state: any) => state.root.common);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { sendLink, loginScreen } = GetColorCode()
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(countdown);
  }, [canResend]);

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(user_ResetPassword),
    onSubmit: (values: any) => onSumbit(setLoading, values, state, navigate, systemType, loginScreen),
  });
  const check =
    systemType === "physiotherapist" ||
    systemType === "doctor" ||
    systemType === "psychologist" ||
    systemType === "paramedic" ||
    systemType === "nutritionist";
  const resendCode = () => {
    let params: any = {
      email: state?.email,
      type: check ? "doctor" : systemType,
    };
    if (
      check
    ) {
      params.doctorKind = systemType;
    }

    setLoading(true);
    __sendLinkToEmail(params, sendLink)
      .then((res: any) => {
        toast.success(res?.data?.message);
        setTimer(60);
        setCanResend(false);
      })
      .catch((err: any) => { })
      .finally(() => setLoading(false));
  };
  const handleGoToHome = () => {
    navigate("/joinVender");
  };

  return (
    <div
      className={styles.containerSignUpLogin}
      style={{ justifyContent: "center" }}
    >
      <div className={style.SwitchButtonConatiner}>
        <div
          className={style.BackToHome}
          style={{ marginTop: "15px" }}
          onClick={handleGoToHome}
        >
          <FaChevronLeft className={style.BackIcon} />
          <p
            className={classNames(
              commonStyles.colorBlue,
              commonStyles.fs14,
              commonStyles.semiBold
            )}
          >
            Back to Home
          </p>
        </div>
      </div>
      {/* <div
        className={classNames(
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12,
          LoginStyles.centerContent
        )}
      > */}
      <form
        className={LoginStyles.loginFormContainer}
        onSubmit={formik?.handleSubmit}
      >
        <div
          className={LoginStyles.logo}
          style={{
            alignSelf: "center",
          }}
        >
          <img src={logo} alt="" style={{ marginBottom: 0 }} />
        </div>

        <p className={styles.head}>
          <span className={styles.colorBlue}>Create</span>{" "}
          <span className={styles.colorOrange}>New Password</span>
        </p>
        <p
          className={classNames(
            CommonStyles.fs16,
            CommonStyles.regular,
            CommonStyles.colorBlue
          )}
          style={{
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          Your new password must be different from previous used password
        </p>
        <div style={{ marginBottom: "16px" }}>
          <InputField
            formik={formik}
            placeholder="Enter Your Desire Password"
            password
            id="newPassword"
            showPassword={showPassword}
            type={showPassword ? "text" : "password"}
            togglePassword={() => setShowPassword(!showPassword)}
            value={formik?.values?.newPassword}
            onChange={formik?.handleChange("newPassword")}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <InputField
            formik={formik}
            password
            placeholder="Confirm your  Password"
            id="confirmPassword"
            showPassword={showConfirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            togglePassword={() => setShowConfirmPassword(!showConfirmPassword)}
            value={formik?.values?.confirmPassword}
            onChange={formik?.handleChange("confirmPassword")}
          />
        </div>

        <InputField
          formik={formik}
          placeholder="Verification Code"
          id="verificationCode"
          value={formik?.values?.verificationCode}
          onChange={formik?.handleChange("verificationCode")}
        />
        {canResend ? (
          <p onClick={resendCode} className={LoginStyles.resendCode}>
            Resend Code
          </p>
        ) : (
          <p style={{ color: "#a0a0a0" }}>Resend Code in {timer} seconds</p>
        )}

        <div
          style={{
            marginTop: "16px",
            width: "100%",
          }}
        >
          <PrimaryButton
            disabled={loading}
            children={
              loading ? <RingLoader size={35} color={"#fff"} /> : "Next"
            }
            type="submit"
            colorType={"blue"}
          />
        </div>

        <div
          className={classNames(
            CommonStyles.mt24,
            CommonStyles.flx,
            CommonStyles.flxBetween
          )}
          style={{ alignSelf: "center" }}
        >
          <p className={classNames(CommonStyles.regular, CommonStyles.fs14)}>
            Already signed up?
          </p>
          <a
            className={classNames(
              CommonStyles.colorBlue,
              CommonStyles.fs16,
              CommonStyles.Bold,
              LoginStyles.cursor
            )}
            onClick={() => navigate("/user/login")}
          >
            Login
          </a>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};

export default ResetPassword;
