import React from "react";
import commonstyle from "shared/utils/common.module.css";
import style from "./style.module.css";
import classNames from "classnames";
import { RequestTable, Vendor_Payments } from "shared/components";
import { useNavigate } from "react-router-dom";
import SearchBar from "shared/components/Searchbar";
import { useSelector } from "react-redux";
import commonstyles from "shared/utils/common.module.css";
const ComapnyPayment = () => {
  const { user } = useSelector((state: any) => state.root.common);
  return (
    <div style={{marginTop:"24px"}}>
      <Vendor_Payments type={"Hospital"} id={user?._id} />
    </div>
  );
};

export default ComapnyPayment;
