import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Ambulancelogin from "./AmbulanceServices/login";
import AmbulanceSignup from "./AmbulanceServices/signup";
const HomeAuthRoutesAuthRoutes = (props: any) => {
  return (
    <Routes>
      {/* <Route path="/" element={<Ambulancelogin />} />
      <Route
        path="/homeservices/Ambulane/signup"
        element={<AmbulanceSignup />}
      /> */}
    </Routes>
  );
};

export default HomeAuthRoutesAuthRoutes;
