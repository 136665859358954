import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { CustomInput, PrimaryButton } from "shared/components";
import { useSelector, useDispatch } from "react-redux";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { insuranceAddHealth_Family } from "shared/services/Insurance";
import { insurancePriceSchema } from "shared/utils";
import toast from "react-hot-toast";

export default function PriceInsurance() {
  const { insuranceHealthFamilyPackage } = useSelector(
    (state: any) => state.root.insurance
  );
  const navigate = useNavigate();
  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("perYear", selectedOption);
  };

  const formik = useFormik({
    initialValues: {
      actualPrice: "",
      // meditourPrice: "",
      perYear: "",
    },
    validationSchema: Yup.object(insurancePriceSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    let currentData = formik.values;

    let params = {
      yourAgeCriteria: {
        startAge: insuranceHealthFamilyPackage.insuranceAgestart,
        endAge: insuranceHealthFamilyPackage.insuranceAgeend,
      },
      spouseAgeCriteria: {
        startAge: insuranceHealthFamilyPackage.spouseAgeStart,
        endAge: insuranceHealthFamilyPackage.spouseAgeEnd,
      },
      kidsAge: {
        startAge: insuranceHealthFamilyPackage.kidsAgeStart,
        endAge: insuranceHealthFamilyPackage.kidsAgeEnd,
      },
      hospitalizationLimit: {
        startLimit: insuranceHealthFamilyPackage.hospitalizationStartLimit,
        endLimit: insuranceHealthFamilyPackage.hospitalizationEndLimit,
      },
      packageName: insuranceHealthFamilyPackage.packageName,
      packageLogo: insuranceHealthFamilyPackage.packageLogo,
      hospitalizationPerPerson:
        insuranceHealthFamilyPackage.hospitalizationPerPerson,
      dailyRoomAndBoardLimit: insuranceHealthFamilyPackage.dailyRoomBoardLimit,
      claimPayoutRatio: insuranceHealthFamilyPackage.claimPayoutRatio,
      hospitals: insuranceHealthFamilyPackage.hospitals,
      laboratories: insuranceHealthFamilyPackage.labs,
      icuCcuLimits: insuranceHealthFamilyPackage.icu,
      accidentalEmergencyLimits: insuranceHealthFamilyPackage.additionalLimit,
      ambulanceCoverage: insuranceHealthFamilyPackage.ambulanceService,
      waitingPeriod: insuranceHealthFamilyPackage.weeks,
      policyDocument: insuranceHealthFamilyPackage.policyDocument,
      claimProcess: insuranceHealthFamilyPackage.claimProcess,
      heading: insuranceHealthFamilyPackage.heading,
      description: insuranceHealthFamilyPackage.description,
      //CurrentData
      actualPrice: currentData.actualPrice,
      // meditourPrice: currentData.meditourPrice,
      perYear: currentData.perYear,
    };

    insuranceAddHealth_Family(params)
      .then((res: any) => {
        navigate("/insurance/MyFamilyMian");
      })
      .catch((err: any) => {
        toast?.error(err.response.data?.message);
      })
      .finally(() => {});
  };
  // useEffect(() => {
  //   formik.setValues(insuranceHealthFamilyPackage);
  //
  // }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col6)}>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Price "
                id="actualPrice"
                name="actualPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.actualPrice}
              />
              {formik.touched.actualPrice && formik.errors.actualPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.actualPrice}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="MediTour Price"
                id="meditourPrice"
                name="meditourPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.meditourPrice}
              />
              {formik.touched.meditourPrice && formik.errors.meditourPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.meditourPrice}
                </div>
              ) : null}
            </div>
          </div> */}
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.col12,
              commonStyles.mb32
            )}
          >
            <div className={classNames(commonStyles.col12)}>
              <CustomInput
                placeholder="Per Year"
                id="perYear"
                name="perYear"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.perYear}
              />
              {formik.touched.perYear && formik.errors.perYear ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.perYear}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={commonStyles.BtnWidth}>
          <PrimaryButton children={"Submit"} colorType={"blue"} type="submit" />
        </div>
      </form>
    </div>
  );
}
