import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./Appointment.module.css";
import Footerr from "../Footer";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../../assets/logo.png";
import DownloadImagelogo from "../../../../assets/images/DownloadImagelogo.png";
import { GetAll_Records, getUpcoming_Doctor } from "shared/services";
import { useSelector } from "react-redux";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import MainHeader from "shared/components/MainScreen/Index";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import { LoadingModal } from "shared/components";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { MY_APPOINTMENTS } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import dayjs from "dayjs";
import NewPagination from "shared/components/NewPagination/NewPagination";

interface Appointment {
  id: number;
  name: string;
  specialization: string;
  qualifications: string[];
  clinic: string;
  date: string;
  status: string;
  imageUrl: string;
}

const appointments: Appointment[] = [
  // Add more appointments as needed
];

const MyAppointment = React.memo((props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [data, setData] = useState([]);
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [pageno, setPageno] = useState(1);
  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "Upcoming") {
      UpcomingDoctor(1);
    } else {
      RecordDoctor(1);
    }
  }, [activeTab, currentPage]);

  const UpcomingDoctor = (pageNumber: any) => {
    setLoading(true);

    let params = {
      page: pageNumber,
    };
    getUpcoming_Doctor(params)
      .then((res: any) => {
        if (res?.data?.latestAppointments) {
          setData(res?.data?.latestAppointments);
          setTotalItems(res?.data?.totalAppointments);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const RecordDoctor = (pageNumber: any) => {
    let params = {
      page: pageNumber,
    };
    GetAll_Records(params)
      .then((res: any) => {
        setRecords(res?.data?.latestRecords);

        setTotalItems(res?.data?.totalRecords);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoToDetailAppointment = (i: any) => {
    if (activeTab == "Upcoming") {
      navigate("/services/Detail", {
        state: { data: i },
      });
    } else {
      navigate("/services/Prescription", {
        state: { id: i?._id },
      });
    }
  };
  let AppointmentData = activeTab === "Upcoming" ? data : records;

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (totalItems > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      if (activeTab === "Upcoming") {
        UpcomingDoctor(currentPage + 1);
      } else {
        RecordDoctor(currentPage + 1);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      if (activeTab === "Upcoming") {
        UpcomingDoctor(currentPage - 1);
      } else {
        RecordDoctor(currentPage - 1);
      }
    }
  };
  return (
    <div>
      <NavBreadCrumbs {...MY_APPOINTMENTS} />

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <DoubleButton
          tab1Label="Upcoming"
          tab2Label="Record"
          shift={activeTab}
          onTabChange={handleTabChange}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>
        <div
          className={styles.containerAppointment}
          style={{ cursor: "pointer" }}
        >
          {AppointmentData?.length > 0
            ? AppointmentData?.map((appointment: any, index: any) => (
                <div key={index}>
                  <div
                    className={styles.cardAppointment}
                    onClick={() => handleGoToDetailAppointment(appointment)}
                  >
                    <img
                      src={appointment?.doctorId?.doctorImage||"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
          }
                      alt={appointment?.doctorId?.name}
                      className={styles.photoAppointment}
                    />
                    <div className={styles.infoAppointment}>
                      <div className={styles.topInfo}>
                        <div className={styles.nameAppointment}>
                          {appointment?.doctorId?.name}
                        </div>
                        <div className={styles.dateAppointment}>
                          {dayjs(appointment?.appointmentDateAndTime).format(
                            "DD/MM/YYYY, hh:mm A"
                          )}
                        </div>
                      </div>
                      <div className={styles.specializationAppointment}>
                        {appointment?.doctorId?.speciality?.join(" ")}
                      </div>
                      <div className={styles.qualificationsAppointment}>
                        {appointment?.doctorId?.qualifications}
                      </div>
                      <div className={styles.clinicAppointment}>
                        {appointment?.doctorId?.clinicName}
                      </div>
                      <div className={styles.statusAppointment}>
                        {appointment?.isPaidFull === false
                          ? "The remaining amount is pending for confirmation."
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div
          style={{
            display: AppointmentData?.length === 0 ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {!loading && <PhysiotheristsEmpty />}
        </div>
      </div>
      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
});

export default MyAppointment;
