import React, { useEffect } from "react";
import style from "./vender.module.css";
import { useNavigate } from "react-router-dom";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setSystemType } from "shared/redux";
import { Vendor_cards } from "shared/utils";
import Footerr from "../Footer";
import ServiceHeader from "shared/components/ServicesHeaders";
const Vender = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "MediTour Global | Vendors";
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const handleNavigation = (type: string | null, route: string | null) => {
    if (!type || !route) {
      console.error("Invalid type or route");
      return;
    }
    dispatch(setSystemType(type));
    navigate(route);
  };
  return (
    <div>
      <ServiceHeader
        headingBlue="Join Our Community Of "
        headingOrange="Trusted Partners"
        desc_width="75%"
        content="Become a part of our trusted provider network and open the door to a world of new business opportunities. By registering today, you'll gain access to a community of customers who prioritize quality and reliability. Expand your reach, showcase your products or services, and build lasting connections that will help grow your business in a competitive market."
      />
      <div className={classNames(commonstyles.container)}>
        <div className={classNames(style.cardcontainer)}>
          {Vendor_cards.map((card) => (
            <div
              key={card.id}
              className={style.CardOuter}
              onClick={() => handleNavigation(card.type, card.route)}
            >
              <div
                className={style.ImgeContainer}
                style={{ backgroundColor: card.color }}
              >
                <img src={card.img} className={style.cardimage} />
              </div>
              <div className={style.ContentContainer}>
                <p className={style.numbers}>{card.no}</p>
                <div>
                  <p className={style.title}>{card.name}</p>
                  <p className={style.content}>{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default Vender;
