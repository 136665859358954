import React, { useState } from "react";
import filter from "./cityfilter.module.css";
import filterImg from "assets/images/filterHos.png";
import { Modal, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";
import CustomSelect from "../CustomSelect";
export default function CityFilter() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <div className={filter.filterouter}>
        <img
          src={filterImg}
          className={filter.imagefilter}
          onClick={openModal}
        />
        <p className={filter.text}>Select City</p>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className={filter.modalContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "16px",
              borderBottom: "0.5px solid #7d7d7d",
            }}
          >
            <p className={filter.modaltext}>Select City</p>
            <IoClose className={filter.close} onClick={closeModal} />
          </div>
          <div style={{ marginTop: "16px" }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <button className={filter.resetbtn}>Reset</button>
            <button className={filter.applyBtn} onClick={closeModal}>
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
