import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import style from "./tour.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import commonstyles from "shared/utils/common.module.css";
import { LoadingModal } from "shared/components";
import { travelGETTOURDETAILS } from "shared/services/TravelAgency";
import CustomLoader from "shared/components/New_Loader/Loader";

interface TOUR {
  packageName?: string;
  returnDate?: string;
  pricePerCouple?: number;
  pricePerHead?: number;
  limitedSeats?: number;
  recentTourPolicy?: string;
  dayByDayPlans?: string;
  breakfastQuantity?: number;
  lunchQuantity?: number;
  dinnerQuantity?: number;
  packageDuration?: number;
  images?: string[];
}

export default function TourPackageDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [tour, setTour] = useState<any>({});
  const [images, setImages] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleGoToBack = () => {
    navigate("/travelAgency/tourPakages");
  };

  const fetchDetails = () => {
    if (id) {
      setLoading(true);
      travelGETTOURDETAILS(id)
        .then((res: any) => {
          setTour(res?.data?.tour);
          setImages(res?.data?.tour?.images || []);
        })
        .catch((err: any) => {
          console.error("Error fetching details:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Error: Invalid tour ID");
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={style.outerContainer2}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoToBack} />
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Booked Tour Seats Details
              </p>
            </div>
            <div
              className={classNames(
                // commonstyles.flxBetween,
                style.imageContainer
              )}
            >
              {images.map((data, index) => (
                <img
                  key={index}
                  src={data}
                  className={style.tourImg}
                  alt={`Tour ${index}`}
                />
              ))}
            </div>

            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.mt56
              )}
            >
              Ticket Allotment
            </p>
            <div className={classNames(style.flx, style.mt16)}>
              <div className={classNames(commonstyles.col6, commonstyles.flx)}>
                <div
                  className={classNames(
                    commonstyles.col6,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  <p className={style.mt8}>Tour Name:</p>
                  <p className={style.mt8}>Return Date & Time: </p>
                  <p className={style.mt8}>Actual Price Per Head:</p>
                  <p className={style.mt8}>Actual Price Per Couple:</p>
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.fs16)}
                >
                  <p className={style.mt8}>{tour?.packageName}</p>
                  <p className={style.mt8}>
                    {tour?.returnDate
                      ? new Date(tour.returnDate).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : "__"}
                    ,{" "}
                    {tour?.returnDate &&
                      new Date(tour.returnDate).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                  </p>
                  <p className={style.mt8}>{tour?.pricePerHead} Rs Per Head</p>
                  <p className={style.mt8}>
                    {tour?.pricePerCouple} Rs Per Couple
                  </p>
                </div>
              </div>
              <div className={classNames(commonstyles.col6, commonstyles.flx)}>
                <div
                  className={classNames(
                    commonstyles.col6,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  <p className={style.mt8}>Meal:</p>
                  <p className={style.mt8}>Days:</p>
                </div>
                <div
                  className={classNames(commonstyles.col6, commonstyles.fs16)}
                >
                  <p className={style.mt8}>
                    Breakfast {tour?.breakfastQuantity} Lunch{" "}
                    {tour?.lunchQuantity} Dinner {tour?.dinnerQuantity}
                  </p>
                  <p className={style.mt8}>{tour?.packageDuration}</p>
                </div>
              </div>
            </div>
            <div className={classNames(commonstyles.fs16)}>
              <div className={classNames(style.planContainer)}>
                <p className={classNames(style.planTitle)}>Plan:</p>
                <p className={classNames(style.planText)}>
                  {tour?.dayByDayPlans || "No plans available"}
                </p>
              </div>
            </div>

            <div className={classNames(commonstyles.fs16, commonstyles.mb32)}>
              <div className={classNames(style.rulesContainer)}>
                <p className={classNames(style.rulesTitle)}>Rules:</p>
                <p className={classNames(style.rulesText)}>
                  {tour?.recentTourPolicy || "No rules available"}
                </p>
              </div>
            </div>
            <div>
              <p className={classNames(commonstyles.fs16)}>Limited Seats</p>
              <p>{tour?.limitedSeats}</p>
              <p className={classNames(commonstyles.fs16)}>Booked Seats</p>
              <p>{tour?.bookedSeats || "No Booked Seats available"}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
