import { useState, useEffect, useRef } from "react";
import commonstyle from "shared/utils/common.module.css";
import style from "./style.module.css";
import classNames from "classnames";
import { RequestTable } from "shared/components";
import { getDepartmentDoctors } from "shared/services";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
const titles_2 = [
  "Doctor ID",
  "Name",
  "Mobile",
  "Email",
  "Experience",
  "Qualification",
];

const ManageDetails = () => {
  const [length, setLength] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const totalItems = length;
  const navigate = useNavigate()
  const { systemType } = useSelector((state: any) => state?.root?.common)
  const location = useLocation();
  const departmentId = location?.state;

  useEffect(() => {
    if (departmentId) {
      departmentDoc(1, "");
    }
  }, []);
  const departmentDoc = (pageno: any, keyword: any) => {
    setLoading(true);
    getDepartmentDoctors(departmentId, pageno, keyword)
      .then(async (res: any) => {
        console.log(
          res?.data.department?.doctorIds,
          "......department?.doctorIds"
        );
        handleTableData(res.data?.department?.doctorIds);
        setLength(res?.data?.totalDoctors);
      })
      .catch((err: any) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableData = (data: any) => {
    let tempData: any = [];
    if (data?.length > 0) {
      data.map((v: any, ind: any) => {
        tempData.push([
          v?.categoryId?.categoryName || v?.vendorId,
          v?.headDocId?.name || v?.name,
          v?.headDocId?.phoneNumber || v?.phoneNumber,
          v?.headDocId?.cnicOrPassportNo || v?.email,
          v?.headDocId?.email || v?.clinicExperience,
          v?.qualifications,
        ]);
      });
      setTableData(tempData);
    } else {
      setTableData([]);
    }
  };
  const handleBackClick = () => {
    navigate(`/${systemType}/Departments`);
  };
  return (
    <div className={classNames(style.maincontainer)}>
      <div className={style.container}>
        <div className={style.backButton} onClick={handleBackClick}>
          <IoMdArrowBack size={24} />
        </div>
        <div className={style.heading}>Departments Details</div>
      </div>

      <div style={{marginTop:"32px"}}>
        <RequestTable
          lab={titles_2}
          appointment={tableData}
          loading={loading}
          length={length}
          allHospital={departmentDoc}
          totalItems={totalItems}
          search={search}
          setSearch={setSearch}
          headTitle={"Departments Details"}
        />
      </div>
    </div>
  );
};

export default ManageDetails;
