import React, { useEffect, useRef, useState } from "react";
import "./TourismCard.css";
import green from "assets/images/grenFLex.png";

export const CardComponent = ({ title, img }: any) => {
  const videoRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, []);
  return (
    <div className="tourism-card">
      <div className="content">
        <p className="heading">Eco & Nature Based Tourism</p>
        <hr className="underline" />
        <p className="subtitle">
          Experience Healing Through Nature with Meditour Global.
        </p>
        <p className="description">
          At Meditour Global, we believe that true wellness extends beyond
          medical treatments—it thrives in nature’s embrace. Our Eco &
          Nature-Based Tourism packages are designed for those who seek
          rejuvenation in the serenity of lush landscapes, pristine mountains,
          and tranquil coastal retreats. Whether you're recovering from a
          medical procedure or simply looking to restore your mind and body, our
          eco-friendly destinations offer the perfect blend of relaxation,
          adventure, and natural healing.
        </p>
        {/* <div className="footer">
          {title === "PAKISTAN" && <img className="logo" src={green} />}
          <button className="book-now">Book Now</button>
        </div> */}
      </div>
      {title === "PAKISTAN" ? (
        <div className="media" onClick={handlePlayPause}>
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            className="video"
            src={require("../../../assets/images/greenVideo.mp4")}
          ></video>

          <button className="play-button">{isPlaying ? "⏸" : "▶"}</button>
        </div>
      ) : (
        <div className="media">
          <img src={img} className="video" />
        </div>
      )}
    </div>
  );
};
