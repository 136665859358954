import React, { useState, useEffect } from "react";
import style from "./branches.module.css";
import TableNew from "shared/components/A_New_Components/Table_new";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import { Modal } from "@mui/material";
import { InputField, RingLoader } from "shared/components";
import { addLabBranch, getBranch } from "shared/services";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CityFilter from "shared/components/CityFilter";
import LocationInput from "shared/components/LocationInput";
import { IoMdSettings } from "react-icons/io";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
export default function Branches() {
  const { user } = useSelector((state: any) => state.root.common);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const Branch = ["Branch Code", "Address", "City", "Phone", "Settings"];

  const [branch, setBranch] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    handleGetBranch();
  }, []);
  const handleGetBranch = () => {
    getBranch(user?._id)
      .then((res: any) => {
        let data = res?.data?.labBranches;
        let tempData: any = [];
        data?.map((v: any, ind: any) => {
          tempData.push([
            v?.branchCode,
            v?.location?.address,
            v?.location?.city,
            v?.phoneNumber,
            <div className={style.dropdownWrapper} style={{ gap: "8px" }}>
              <IoMdSettings size={24} />
            </div>,
          ]);
        });

        setBranches(tempData);
        console.log("branddn.............................", tempData);
      })
      .catch(() => {});
  };

  const formik = useFormik({
    initialValues: {
      branchCode: "",
      address: "",
      city: "",
      phone: "",
      email: "",
      country: "",
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object({
      branchCode: Yup.string().required("Branch Code is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      phone: Yup.string()
        .matches(/^[+0-9]+$/, "Only numbers and '+' are allowed")
        .required("Phone is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      country: Yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      handleSaveLab();
    },
  });
  const handleSaveLab = () => {
    let values = formik.values;
    setLoading(true);
    const params = {
      branchCode: values.branchCode,
      phone: values.phone,
      country: "Pakistan",
      mainLabId: user?._id,
      location: {
        lng: values.lng,
        lat: values.lat,
        address: values.address,
        city: values.city,
      },
    };
    console.log("🚀 ~ handleSaveLab ~ params:", params);

    addLabBranch(params)
      .then((res: any) => {
        console.log("reponse............", res);
        handleGetBranch();
        setIsModalOpen(false);
      })
      .catch((err: any) => {
        console.log("Error............", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
    console.log("param........", params);
  };

  const handleLocationChange = async (newLocation: any) => {
    const labelParts: any = newLocation?.label.split(", ");
    const country = labelParts[labelParts.length - 1];
    formik?.setFieldValue("address", newLocation?.label);
    formik?.setFieldValue("city", newLocation?.city);
    formik?.setFieldValue("country", country);
    const address = newLocation?.label;
    const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A";
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        formik?.setFieldValue("lat", location.lat);
        formik?.setFieldValue("lng", location.lng);
      } else {
        console.error("Geocoding error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data: ", error);
    }
  };

  return (
    <div className={style.pr36}>
      <div className={style.flx}>
        <div className={style.flx} style={{ justifyContent: "start" }}>
          <p className={style.mainHeading}>Branch Management</p>

          {loading ? (
            <div className={style.outerRefresh} style={{ marginLeft: "16px" }}>
              <RingLoader color={"#0D47A1"} size={30} />
            </div>
          ) : (
            <div className={style.outerRefresh}>
              <TbRefresh
                className={style.RefreshIcon}
                onClick={() => {
                  setLoading(true);
                  handleGetBranch();
                }}
              ></TbRefresh>
            </div>
          )}
        </div>
        <div className={style.flexx}>
          <CityFilter />
          <button className={style.AddBtn} onClick={openModal}>
            + Add Branch
          </button>
        </div>
      </div>

      <div className={classNames(commonstyles.mt16, style.tablecontainer)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "8px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{ alignItems: "center" }}
            className={classNames(commonstyles.flx, commonstyles.flxWrap)}
          >
            <p
              className={classNames(
                commonstyles.colorBlack,
                commonstyles.fs14,
                commonstyles.semiBold
              )}
            >
              Branches
            </p>
            <div>
              <SearchFilter title={"Search "} />
            </div>
          </div>
          <div>
            <NewPagination />
          </div>
        </div>

        <div className={classNames(commonstyles.mt16)}>
          <TableNew
            titles={Branch}
            data={branches}
            headerWidth="20%"
            itemWidth="20%"
            show="default"
            height="325px"
          />
        </div>
      </div>

      {/* Modal Component */}
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className={style.modalContent}>
          <p className={style.texts}> Add a New Branch </p>
          <form onSubmit={formik.handleSubmit}>
            <div className={classNames(style.mt32, style.gridContainer)}>
              {/* Branch Code */}
              <div className={style.w100}>
                <InputField
                  borderRadius={4}
                  height="48px"
                  border={0}
                  borderColor="#7D7D7D"
                  placeholder="Branch Code"
                  name="branchCode"
                  value={formik.values.branchCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.branchCode && formik.errors.branchCode && (
                  <p className={style.errorText}>{formik.errors.branchCode}</p>
                )}
              </div>
              {/* Address */}
              <div className={style.w100}>
                <LocationInput
                  placeholder={formik.values.address || "Branch Address*"}
                  type={"box"}
                  setData={handleLocationChange}
                  defaultValue={formik.values.address}
                />
                {/* <div className={Styles.locationButton} onClick={fetchLocation}>
              📍 Get Current Location
            </div> */}
                {formik.touched.address && formik.errors.address ? (
                  <div className={style.error}>{formik.errors.address}</div>
                ) : null}
              </div>
              {/* <div className={style.w100}>
                <InputField
                  borderRadius={30}
                  height={52}
                  borderColor="#7D7D7D"
                  placeholder="Address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address && (
                  <p className={style.errorText}>{formik.errors.address}</p>
                )}
              </div> */}
              {/* City */}
              <div className={style.w100}>
                <InputField
                  borderRadius={4}
                  height="48px"
                  border={0}
                  borderColor="#7D7D7D"
                  placeholder="City"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && formik.errors.city && (
                  <p className={style.errorText}>{formik.errors.city}</p>
                )}
              </div>
              {/* Phone */}
              <div className={style.w100}>
                <InputField
                  borderRadius={4}
                  height="48px"
                  border={0}
                  borderColor="#7D7D7D"
                  placeholder="Phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className={style.errorText}>{formik.errors.phone}</p>
                )}
              </div>
              {/* <div className={style.w100}>
                <InputField
                  borderRadius={4}
                  height="48px"
                  border={0}
                  borderColor="#7D7D7D"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className={style.errorText}>{formik.errors.email}</p>
                )}
              </div> */}
              {/* Country */}
              <div className={style.w100}>
                <InputField
                  borderRadius={4}
                  height="48px"
                  border={0}
                  borderColor="#7D7D7D"
                  placeholder="Country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.country && formik.errors.country && (
                  <p className={style.errorText}>{formik.errors.country}</p>
                )}
              </div>
            </div>
            <div className={classNames(style.mt32, style.flxBetween)}>
              <button className={style.backBtn} onClick={closeModal}>
                Back
              </button>
              <button
                className={style.SaveBtn}
                onClick={handleSaveLab}
                disabled={loading}
              >
                {loading ? (
                  <div>
                    <RingLoader color={"#fff"} size={30} />
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>{" "}
          </form>
        </div>
      </Modal>
    </div>
  );
}
