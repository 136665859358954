import React, { useEffect, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import CardStyless from "../../../pages/Services/DoctarServices/Cards.module.css";
import classNames from "classnames";
import CommonStyless from "shared/utils/common.module.css";
import Vector from "assets/images/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import {
  IoLocationOutline,
  IoSearchSharp,
  IoTimeOutline,
} from "react-icons/io5";
import { getAll_Hospitals } from "shared/services";
import { useSelector } from "react-redux";
import PhysiotheristsEmpty from "../PhsiotheristEmpty";
import RatingStar from "shared/RatingStar";
import CustomLoader from "../New_Loader/Loader";
import Special from "../../../assets/images/treatment 3.png";
import Time from "../../../assets/images/mingcute_time-line.png";
import Services from "../../../assets/images/maki_doctor.png";
import { FaLocationDot } from "react-icons/fa6";
import Filter from "../../../assets/images/filterHos.png";
import { IoSearchOutline } from "react-icons/io5";
import New_Filter from "../A_New_Components/NewFilter";

// Define card data type
interface CardData {
  id: number;
  name: string;
  vector: string; //img
  time: string;
  location: string;
  rating: string;
}

interface MyComponentProps {
  serviceName: any;
}
const MainHospitalCard: React.FC<MyComponentProps> = ({ serviceName }) => {
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState<number>(2);
  const [hospitalData, setHospitalData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 3;
  const totalItems = hospitalData?.length || 0;
  const [showNumber, setShowNumber] = useState(false);
  const { location } = useSelector((state: any) => state.root.common);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setLoading(true);
    all_Hospital(currentPage + 1);
  }, [currentPage, searchValue]);

  // const handleShowMore = () => {
  //   setVisibleCards((prevCount) => prevCount + 2);
  // };

  const handleViewMoreClick = (id: number) => {
    navigate(`/services/hospital/HospitalDetail/${id}`, {
      state: { serviceName },
    });
  };
  const all_Hospital = (pageno: number) => {
    let params = {
      pageno,
      search: searchValue,
      lat: location?.latitude,
      long: location?.longitude,
    };
    getAll_Hospitals(params)
      .then((res: any) => {
        setHospitalData(res?.data?.hospitals);
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      all_Hospital(currentPage + 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  // const displayedHospitals = hospitalData.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );
  const truncateText = (text = "", letterLimit = 10) => {
    if (
      typeof text !== "string" ||
      typeof letterLimit !== "number" ||
      letterLimit <= 0
    ) {
      console.error(
        "Invalid input: text must be a string and letterLimit must be a positive number."
      );
      return text; // Return unchanged text if input is incorrect
    }

    return text.length > letterLimit
      ? text.slice(0, letterLimit) + "..."
      : text;
  };

  return (
    <div
      className={classNames(CommonStyless.container)}
      style={{ marginTop: "24px" }}
    >
      <div style={{ backgroundColor: "FDFDFD" }}>
        <New_Filter />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "90%",
            marginTop: "20px",
          }}
        >
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, hospitalData?.length)}
            totalItems={totalItems}
          />
        </div> */}

        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          {/* <div className={CardStyless.tabContent}> */}

          <div className={CardStyless.cardContainer}>
            {hospitalData && hospitalData.length > 0 ? (
              hospitalData.map((hosp: any, index: any) => (
                // <div key={index} className={CardStyless.cardWrapper}>
                //   <div className={CardStyless.cardImageWrapper}>
                //     <img
                //       src={hosp?.logo}
                //       alt="card img"
                //       className={CardStyless.cardImage}
                //     />
                //   </div>
                //   <div
                //     className={CardStyless.cardBody}
                //     onClick={() => handleViewMoreClick(hosp._id)}
                //   >
                //     <div
                //       className={classNames(
                //         CommonStyless.flx,
                //         CommonStyless.flxBetween
                //       )}
                //     >
                //       <div
                //         className={classNames(
                //           CardStyless.cardName,
                //           CommonStyless.colorBlue
                //         )}
                //       >
                //         {hosp?.name}
                //       </div>
                //       <div>
                //         <img
                //           src={Vector}
                //           alt="Vector icon"
                //           className={CardStyless.vectorIcon}
                //         />
                //       </div>
                //     </div>
                //     <div className={CardStyless.cardtime}>
                //       <span className={CardStyless.timeIcon}>
                //         <IoTimeOutline />
                //       </span>
                //       <span className={CardStyless.specialityTag}>
                //         {hosp?.openTime}
                //       </span>
                //     </div>

                //     <div className={CardStyless.cardtime}>
                //       <span className={CardStyless.timeIcon}>
                //         <IoLocationOutline />
                //       </span>
                //       <span>{hosp?.location?.address}</span>
                //     </div>
                //     <div className={CardStyless.cardFooter}>
                //       <RatingStar rating={2.5} />
                //       {/* {card.rating} */}

                //       <span
                //         className={classNames(
                //           CommonStyless.flx,
                //           CardStyless.viewMore
                //         )}
                //         onClick={() => handleViewMoreClick(hosp._id)}
                //       >
                //         <Link to="#" className={CardStyless.cardViewMore}>
                //           View more
                //         </Link>
                //         <span className={CardStyless.cardArrow}>
                //           <IoMdArrowForward />
                //         </span>
                //       </span>
                //     </div>
                //   </div>
                // </div>
                <div className={CardStyless.cardWrapper}>
                  <div className={CardStyless.cardImageWrapper}>
                    <img
                      src={hosp?.logo}
                      alt="card img"
                      className={CardStyless.cardImage}
                    />
                  </div>
                  <div className={CardStyless.cardBody}>
                    <div className={CardStyless.hospitalInfo}>
                      <div>
                        <p className={CardStyless.hospitalName}>
                          {" "}
                          {hosp?.name}
                        </p>
                        <p
                          style={{ marginTop: "8px" }}
                          className={CardStyless.hospitalDesp}
                        >
                          PMC Verified
                        </p>
                        <div style={{ marginTop: "16px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img src={Special} className={CardStyless.Iconss} />
                            <p className={CardStyless.textIcons}>
                              {hosp?.treatmentDoctorCount} Specialties
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img src={Time} className={CardStyless.Iconss} />
                            <p className={CardStyless.textIcons}>
                              {hosp?.openTime} - {hosp?.closeTime}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src={Services}
                              className={CardStyless.Iconss}
                            />
                            <p className={CardStyless.textIcons}>
                              {hosp?.doctorCount}-Doctors
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={CardStyless.hospitalBottom}>
                        <div>
                          <p className={CardStyless.bed}>32 beds</p>
                          <p className={CardStyless.bedValue}>Total</p>
                        </div>
                        {/* <div
                          style={{
                            border: "0.5px solid #7d7d7d",
                            height: "40px",
                          }}
                        ></div>

                        <div>
                          <p className={CardStyless.bed}>97% (120)</p>
                          <p className={CardStyless.bedValue}>Hotel Rating</p>
                        </div> */}
                      </div>
                    </div>
                    <div style={{ border: "0.5px solid #7d7d7d" }}></div>
                    <div className={CardStyless.hospitalDetail}>
                      <p>Featured Treatments</p>
                      <div className={CardStyless.FeaturedOuter}>
                        <>
                          <ul className={CardStyless.treatmentList}>
                            {hosp?.treatments.length > 0
                              ? hosp?.treatments
                                  .slice(0, 6)
                                  .map((treatment: string, index: number) => (
                                    <li key={index}>{treatment}</li>
                                  ))
                              : null}
                          </ul>
                        </>
                      </div>
                      {hosp?.treatments.length === 0 && (
                        <p style={{ color: "#0e54a3" }}>No Treatment Added</p>
                      )}
                      <div className={CardStyless.hospitalBottom2}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <div style={{ width: "22px " }}>
                            <FaLocationDot className={CardStyless.Iconss} />
                          </div>
                          <p className={CardStyless.Loaction}>
                            {hosp?.location?.address}
                          </p>
                        </div>
                        <div className={CardStyless.fllxx}>
                          <button
                            onClick={() => handleViewMoreClick(hosp._id)}
                            className={CardStyless.Detail}
                          >
                            Details
                          </button>

                          {!showNumber && (
                            <button
                              className={CardStyless.call}
                              onClick={() => setShowNumber(true)}
                            >
                              Call Helpline
                            </button>
                          )}

                          {showNumber && (
                            <p className={CardStyless.Number}>
                              +92-42-37885101-4
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {!loading && (
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxCenter
                    )}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div className={classNames(CommonStyless.flx)}>
                      <PhysiotheristsEmpty />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {hospitalData?.length >= 10 && <></>}
        </div>
      </div>
      {/* </div> */}

      {loading && <CustomLoader />}
    </div>
  );
};

export default MainHospitalCard;
