import React, { useEffect, useState } from "react";
import img from "assets/images/travelcard.png";
import img2 from "assets/images/hotelCard.jpg";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./CardStyle.module.css";
import { IoLocationSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import dayjs from "dayjs";
import NewPagination from "shared/components/NewPagination/NewPagination";
const TravelCards = ({
  data,
  handlefavourite,
  activeTab,
  renderPagination,
}: {
  data?: any;
  handlefavourite: any;
  activeTab: any;
  renderPagination?: any;
}) => {
  const [likedItemId, setLikedItemId] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const handleHeartClick = (id: string) => {
    setLikedItemId((prev) => (prev === id ? null : id));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // const handleNext = () => {
  //   if (currentIndex < cardData.length - 4) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };

  // const handlePrev = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //   }
  // };

  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector((state: any) => state.root.common);

  const handledetaile = (item: any) => {
    navigate("/services/travel/TravelDetail", { state: { item: item } });
  };
  const formatDateRange = (startDate: any, endDate: any) => {
    const start = dayjs(startDate).format("D MMM");
    const end = dayjs(endDate).format("D MMM YYYY");
    return `${start} - ${end}`;
  };
  return (
    <div>
      <div className={classNames()}>
        <div className={classNames(commonstyle.flx, commonstyle.flxBetween)}>
          <p
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.fs24,
              commonstyle.semiBold
            )}
            style={{ marginBottom: "16px" }}
          >
            Upcoming Schedule
          </p>
          {renderPagination && renderPagination}
        </div>

        {/* Container for the cards */}
        {data?.length > 0 ? (
          <div className={classNames(style.cardContainer)}>
            {data.map((item: any) => {
              const isFavorite = user?.favourites?.some(
                (fav: any) =>
                  fav.itemId === item?._id && fav.favModel === activeTab
              );
              return (
                <div
                  key={item.id}
                  className={classNames(style.card, style.roundedBackground)}
                  onClick={() => handledetaile(item)}
                >
                  <div className={style.imgContainer}>
                    <img
                      src={
                        item.images[0] ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                      }
                      alt={item.title}
                      className={style.cardImage}
                    />
                    {/* <div
                      className={style.heartIconContainer}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlefavourite(item?._id);
                      }}
                    >
                      {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
                    </div> */}
                  </div>
                  <div className={classNames(style.textcontainer)}>
                    <p
                      className={classNames(
                        commonstyle.fs16,
                        commonstyle.semiBold
                      )}
                    >
                      <span style={{ color: "#0E54A3" }}>
                        {item.packageName}
                      </span>
                    </p>
                    <div
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16,
                        commonstyle.semiBold
                      )}
                    >
                      <SlCalender
                        className={classNames(
                          commonstyle.fs16,
                          commonstyle.semiBold
                        )}
                        style={{
                          marginRight: "8px",
                          color: "#0E54A3",
                          fontSize: "13px",
                        }}
                      />
                      <span style={{ color: "#0E54A3", fontSize: "13px" }}>
                        {formatDateRange(item.departDate, item.returnDate)}
                      </span>
                    </div>
                    <div>
                      <div
                        className={classNames(
                          commonstyle.flx,
                          commonstyle.flxBetween
                        )}
                      >
                        <div
                          className={classNames(
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                          style={{ color: "#0E54A3", fontSize: "13px" }}
                        >
                          <IoLocationSharp
                            className={classNames(
                              commonstyle.fs16,
                              commonstyle.semiBold
                            )}
                            style={{ color: "#0E54A3", fontSize: "13px" }}
                          />
                          <span style={{ color: "#0E54A3", fontSize: "13px" }}>
                            {item.from}
                          </span>
                        </div>

                        {/* <div
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs16,
                      commonstyle.semiBold
                    )}
                  >
                    {card.duration}
                  </div> */}
                      </div>
                      <div
                        className={style.showMoreContainer}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button className={style.showMoreButton}>
                          Details
                          <span className={style.icon}>
                            <IoMdArrowForward />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>{<PhysiotheristsEmpty />}</div>
        )}
        <div className={style.marginTopContainer}></div>
      </div>
      {/* <Upcoming /> */}
    </div>
  );
};

export default TravelCards;
