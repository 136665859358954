import React, { useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import style from "./detail.module.css";
import dayjs from "dayjs";
import { CiCalendar } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import TableNew from "shared/components/A_New_Components/Table_new";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { confirmDateAndTime } from "shared/services";
import toast from "react-hot-toast";
import { CustomModal, RingLoader } from "shared/components";
import classNames from "classnames";

const DetailBooking = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { systemType } = useSelector((state: any) => state?.root?.common);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedTime, setSelctedTime] = useState<any>(null);
  const [showModel, setShowModel] = useState(false);
  const [ringerLoader, setRingerLoader] = useState(false);
  const [status, setStatus] = useState("");
  const titles = ["ID", "Name", "Phone", "Email", "Availability"];
  const titles2 = ["ID", "Name", "Phone", "Submitted at", "Type", "Status"];
  const titles3 = [
    "ID",
    "Doctor Name",
    "Patient Name",
    "Appointment Date",
    state?.appointmentDateAndTime ? "Re-Date" : "Select Date",
    state?.appointmentDateAndTime ? "Re-Time" : "Select Time",
    "Payment",
  ];

  const handleBackClick = () => {
    navigate(`/${systemType}/AppointmentBooking`);
  };
  const tempData = [
    [
      state?.doctorInfo?.vendorId,
      state?.doctorInfo?.name,
      state?.doctorInfo?.phoneNumber,
      state?.doctorInfo?.email,
      // state?.doctorInfo?.isAvailable,x sir hussain set hard Code
      "Yes",
    ],
  ];
  const tempData2 = [
    [
      state?.patientId?.mrNo,
      state?.patientId?.name,
      state?.patientId?.phone,
      dayjs(state?.createdAt).format("MM-DD-YYYY h:mm a"),
      state?.appointmentType,
      state?.status,
    ],
  ];
  const tempData3 = [
    [
      state?.appointmentId,
      state?.doctorInfo?.name,
      state?.patientId?.name,
      state?.appointmentDateAndTime
        ? dayjs(state?.appointmentDateAndTime).format("MM-DD-YYYY h:mm a")
        : "No Date&Time",
      <div className={style.dropdownWrapper}>
        <CiCalendar size={16} />
        <div
          onClick={() =>
            setOpenDropdown(openDropdown === "dateTime" ? null : "dateTime")
          }
        >
          {selectedDate ? selectedDate.format("MM/DD/YYYY") : "Select"}
        </div>
        {openDropdown === "dateTime" && (
          <div className={style.dropdownContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Select Date"
                format="MM/DD/YYYY"
                value={selectedDate}
                onChange={(newDate) => {
                  setSelectedDate(newDate);
                  setOpenDropdown(null);
                }}
              />
            </LocalizationProvider>
          </div>
        )}
      </div>,
      <div className={style.dropdownWrapper}>
        <IoTimeOutline size={16} />
        <div
          onClick={() =>
            setOpenDropdown(openDropdown === "TimePicker" ? null : "TimePicker")
          }
        >
          {selectedTime ? selectedTime.format("hh:mm A") : "Select"}
        </div>
        {openDropdown === "TimePicker" && (
          <div className={style.dropdownContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                label="Select Time"
                value={selectedTime}
                onChange={(newDate) => {
                  setSelctedTime(newDate);
                  setOpenDropdown(null);
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
        )}
      </div>,
      state?.isPaidFull === true ? "Paid" : "Partial",
    ],
  ];
  const sendRequest = () => {
    let datetime: any;
    if (selectedDate && selectedTime) {
      datetime =
        status === "confirm" &&
        dayjs(selectedDate)
          .hour(dayjs(selectedTime)?.hour())
          .minute(dayjs(selectedTime)?.minute())
          .tz("Asia/Karachi")
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    console.log(datetime, "......datetime");
    setRingerLoader(true);
    let params = {
      appointmentRequestId: state?._id,
      confirmationStatus: status,
      ...(selectedDate &&
        selectedTime && {
          appointmentDateAndTime: datetime,
        }),
    };
    console.log(params, ".....params     bb");
    confirmDateAndTime(params)
      .then((res: any) => {
        console.log(res, "/........");
        setShowModel(false);
        navigate(`/${systemType}/AppointmentBooking`);
      })
      .catch((err: any) => {
        console.log(err, ".....err");
      })
      .finally(() => {
        setRingerLoader(false);
      });
  };
  const handleConfirm = (type: any) => {
    if (type === "cancel") {
      setShowModel(true);
      setStatus(type);
      return;
    }
    if (!state?.appointmentDateAndTime) {
      if (!selectedDate || !selectedTime) {
        toast.error("Please select date and time!");
        return;
      }
    }
    if ((selectedDate && !selectedTime) || (!selectedDate && selectedTime)) {
      toast.error("Please select both date and time!");
      return;
    }
    setShowModel(true);
    setStatus(type);
  };

  return (
    <div className={classNames(style.maincontainer)}>
      <div className={style.container}>
        <div className={style.backButton} onClick={handleBackClick}>
          <IoMdArrowBack size={24} />
        </div>
        <div className={style.heading}>Request Details</div>
      </div>
      <div className={style.card}>
        <div className={style.cardTitle}>Doctor</div>
        <TableNew
          titles={titles}
          data={tempData}
          headerWidth="24.5%"
          itemWidth="24.5%"
        />
      </div>
      <div className={style.card}>
        <div className={style.cardTitle}>Patient</div>
        <TableNew
          titles={titles2}
          data={tempData2}
          headerWidth="20%"
          itemWidth="20%"
        />
      </div>
      <div className={style.card}>
        <div className={style.cardTitle}>Create Appointment</div>
        <TableNew
          titles={titles3}
          data={tempData3}
          headerWidth="17%"
          itemWidth="17%"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // marginRight: "20px",
          marginTop: "48px",
        }}
      >
        <button
          children={"Cancel Appointment"}
          className={style.admin}
          onClick={() => handleConfirm("cancel")}
        />
        <button
          children={"Confirm Appointment"}
          className={style.blue}
          onClick={() => handleConfirm("confirm")}
        />
      </div>
      <CustomModal showModal={showModel}>
        <div className={style.modalContent}>
          {status === "confirm" ? (
            <div className={style.modalText}>
              {selectedDate && selectedTime ? (
                "Confirm Appointment ?"
              ) : (
                <>
                  Proceed with the pre-defined Appointment <br />
                  Date and Time?
                </>
              )}
            </div>
          ) : (
            <div className={style.modalText}>
              Are you sure you want to cancel and send back to
              <br />
              Meditour?
            </div>
          )}
          <div className={style?.modalButtons}>
            <button
              onClick={() => setShowModel(false)}
              className={style?.buttonCancel}
            >
              Cancel
            </button>
            <button onClick={sendRequest} className={style?.buttonProceed}>
              {ringerLoader ? <RingLoader /> : "Proceed"}
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default DetailBooking;
