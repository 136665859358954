import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import { chnageAvailable, getProperty } from "shared/services";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import { PropertyCard, RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import AddPropertyCard from "shared/components/A_New_Components/AddPropertyCard";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
const Property = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [showModel, setShowModel] = useState(false);
  const handleClick = () => {
    setShowModel(true);
  };
  useEffect(() => {
    getDoctorList("");
  }, []);
  const getDoctorList = (keyword: any) => {
    setLoading(true);
    getProperty(1, keyword)
      .then((res: any) => {
        setData(res?.data?.properties);
      })
      .catch((err: any) => {
        console.log(err, "...error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRefresh = () => {
    setSearch("");
    getDoctorList("");
  };
  const handleSearch = () => {
    getDoctorList(search);
  };

  const handleSwitchChange = (e: any, index: number, v: any) => {
    const updatedTableData: any = [...data];

    if (updatedTableData[index]) {
      updatedTableData[index].isAvailable = e.target.checked;
      setData(updatedTableData);
      const isActive = updatedTableData[index].isAvailable;
      let body = {
        isAvailable: isActive,
      };
      chnageAvailable(v?._id,body)
        .then((res: any) => {
          notifySuccess(res?.data?.message);
        })
        .catch((err: any) => {});
    }
  };
  return (
    <>
      {loading && data.length === 0 ? (
        <div className={style.loaderContainer}>
          <div className={style.spinner}></div>
        </div>
      ) : (
        <div>
          {!showModel && (
            <>
              <div className={style.headerContainer}>
                <div className={style.viewRow}>
                  <p className={classNames(style.heading)}>Properties</p>
                  {loading ? (
                    <div style={{ marginLeft: "32px" }}>
                      <RingLoader color={"#0D47A1"} size={30} />
                    </div>
                  ) : (
                    <TbRefresh
                      className={style.refresh}
                      onClick={handleRefresh}
                    />
                  )}
                  <SearchFilter
                    vender={false}
                    search={search}
                    title={"Search"}
                    setSearch={setSearch}
                    handleSearch={handleSearch}
                  />
                </div>
                <button className={style.addbtn} onClick={handleClick}>
                  + Add
                </button>
              </div>

              {data?.length > 0 ? (
                data.map((item: any, index: any) => {
                  return (
                    <PropertyCard
                      data={item}
                      onStatusChange={(e: any) =>
                        handleSwitchChange(e, index, item)
                      }
                      // onclickDetail={() => handleClickedit(item)}
                    />
                  );
                })
              ) : (
                <PhysiotheristsEmpty />
              )}
            </>
          )}
          {showModel && (
            <div style={{ marginTop: "20px" }}>
              <AddPropertyCard />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Property;
