import classNames from "classnames";
import React, { useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import style from "./Myself.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { CustomInput, DeleteModal } from "shared/components";
import File from "assets/images/filePicker.png";
import { FaTrash } from "react-icons/fa";
import {
  insruanceFamilyDeletefun,
  INSURANCE_INDIVIDUAL_DELETE,
} from "shared/services";

const TravelPackageDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const item = state?.item;
  const type = state?.type;
  const handleGoBack = () => {
    if (type == "familyTrip") {
      navigate("/insurance/Travel/Single/singleTrip");
    } else {
      navigate("/insurance/Travel/Family/:value");
    }
  };
  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "insurance_file.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleDownloading = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "insurance_file.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const onCanelModal = () => {
    setModalVisible(false);
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  const onDel = () => {
    setLoading(true);
    let insuranceParams;
    if (type == "familyType") {
      insuranceParams = insruanceFamilyDeletefun(item?._id);
    } else {
      insuranceParams = INSURANCE_INDIVIDUAL_DELETE(item?._id);
    }
    insuranceParams
      .then((res: any) => {
        if (type == "familyTrip") {
          navigate("/insurance/Travel/Single/singleTrip");
        } else {
          navigate("/insurance/Travel/Family/:value");
        }
        setModalVisible(false);
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };
  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flxBetween}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back
              </p>
              <button
                onClick={() => setModalVisible(true)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  marginLeft: "30px",
                }}
              >
                <FaTrash style={{ color: "red", fontSize: "16px" }} />
              </button>
            </div>
          </div>
          <div className={style.mt24}>
            <Avatar
              sx={{ height: "80px", width: "80px" }}
              src={item?.packageLogo}
            />
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              {item?.packageName}
            </p>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Medical Cover"
                value={item?.medicalCover}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Package Discription"
                value={item?.packageDescription}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Covering Upto"
                value={item?.coveringUpto}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Package Category"
                value={item?.packageCategory}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Trip Cancellation"
                value={item?.tripCancellation}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Luggage Arrival Delay"
                value={item?.luggageArrivalDelay}
              />
            </div>
          </div>

          <p
            className={classNames(
              commonstyles.fs20,
              style.mt24,
              commonstyles.medium
            )}
          >
            Medical Benefits
          </p>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Ad & Coverage"
                value={item?.adndCoverage}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Med Expenses Hospitalization Coverage"
                value={item?.medExpensesHospitalizationCoverage}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Emergency Return Home Coverage"
                value={item?.emergencyReturnHomeCoverage}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Repatriation Coverage"
                value={item?.repatriationCoverage}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            {item?.medicineDeliveryCoverage && (
              <div className={commonstyles.col12}>
                <CustomInput
                  placeholder="Medicine Delivery Coverage"
                  value={item?.medicineDeliveryCoverage}
                />
              </div>
            )}
            {item?.repatriationIllnessInjuryCoverage && (
              <div className={commonstyles.col12}>
                <CustomInput
                  placeholder="Repatriation Illness Injury Coverage"
                  value={item?.repatriationIllnessInjuryCoverage}
                />
              </div>
            )}
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Policy Documents
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: "#000",
                borderRadius: "8px",
                padding: "4px",
                // Set cursor to pointer
              }}
              onClick={() => handleDownload(item?.policyDocument)}
            >
              <img
                src={File}
                alt="Document"
                style={{
                  width: "20%",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Police Docoment.pdf
              </p>
            </div>
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Claim Process
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: "#000",
                borderRadius: "8px",
                padding: "4px",
                // Set cursor to pointer
              }}
              onClick={() => handleDownloading(item?.claimProcess)}
            >
              <img
                src={File}
                alt="Document"
                style={{
                  width: "20%",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Claim Process.pdf
              </p>
            </div>
          </div>
          <div className={commonstyles.mt56}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              More Features
            </p>
            <div className={classNames(commonstyles.col4, style.mt24)}>
              <CustomInput
                placeholder="Flight Delay"
                value={item?.flightDelay}
              />
            </div>
            <div className={classNames(commonstyles.col12, style.mt8)}>
              <CustomInput
                placeholder="Baggage Loss"
                value={item?.baggageLoss}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modalVisible={isModalVisible}
        handleCancel={onCanelModal}
        handleDelete={onDel}
        loading={loading}
      />
    </div>
  );
};

export default TravelPackageDetails;
