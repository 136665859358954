import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

//...........Authentication...........

export const labSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.LAB_SIGNUP, params);
};
export const sendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.SEND_CODE_TO_EMAIL, params);
};
export const verifyEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CONFIRM_EMAIL, params);
};
export const completeSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.COMPLETE_SIGNUP}?id=${id}`, params);
};
export const login = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.LOGIN, params);
};
export const resetLinklab = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.LAB_RESET_LINK, params);
};
export const resetPasswordlab = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.LAB_RESET_PASSWORD}?token=${token}`,
    params
  );
};
export const uploadFile = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.UPLOAD_FILE, params);
};
export const getAllTests = (pageno: number, search?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_TEST}?search=${search}&page=${pageno}`
  );
};
export const addTest = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_ADD_TEST, params);
};
export const LabGetTestCategoryList = () => {
  return HTTP_CLIENT.get(ENDPOINTS.LAB_ADMIN_GETTEST);
};
export const LabTestDetails = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_TEST_DETAILS}?testId=${id}`);
};
export const LabTestDELETE = (id: string) => {
  return HTTP_CLIENT.delete(`${ENDPOINTS.LAB_DELETE_TEST}?testId=${id}`);
};
export const LabTestEdit = (id: string, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.LAB_EDIT_TEST}?testId=${id}`, params);
};
export const LabGraphDETAILSUpperPortion = () => {
  return HTTP_CLIENT.get(ENDPOINTS.LAB_DASHBOARDDETAILS);
};
export const LabGRAPH = () => {
  return HTTP_CLIENT.get(ENDPOINTS.LAB_GRAPH);
};
export const getAllOrders = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_ORDER}?page=${pageno}`);
};
export const getallParamedicRequest = (data: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_REQ}?status=` + data?.status);
};
export const getOrderDetails = (orderid: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_LAB_ORDERDETAIL}?orderId=${orderid}`);
};
export const getAddTest = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_ADD_TEST, params);
};
export const labLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.LAB_LOGOUT);
};
export const labUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.LAB_UPDATEPROFILE, params);
};
export const labUploadResult = (id: any, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.LAB_UPLOADRESULT}?orderId=${id}`, params);
};
export const LabGetAllResults = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.LAB_COMPLETEORDERS}?page=${pageno}`);
};
export const labStatusChange = (id: any, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.LAB_STATUSCHANGE}?id=${id}`, params);
};
export const paramedicStatus = (requestId: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.PARA_STATUSCHANGE}?requestId=${requestId}`
  );
};
export const LabGetTestCategory = () => {
  return HTTP_CLIENT.get(ENDPOINTS.LAB_ADMIN_GETTEST);
};
export const LabCompleteResults = () => {
  return HTTP_CLIENT.get(ENDPOINTS.LAB_COMPLETEDRESULTS);
};
export const LabGetNotifications = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.LAB_NOTIFICATION}?id=${id}`);
};

export const addTestToLab = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_Test_To_LAB, params);
};

export const getAllLabTests = (page: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_LAB_TESTS}?page=${page}`);
};
export const getBranch = (labId: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_BRANCH}?mainLab=${labId}`);
};
export const addLabBranch = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_LAB_BRANCH, params);
};
