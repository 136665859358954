import React from "react";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { CustomInput, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import ImgPicker from "shared/components/Img-picker";
import CustomSelect from "shared/components/CustomSelect";
import { insuranceTravelIndividualBenefitsSchema } from "shared/utils";
import { setInsuranceTravelIndividualPackage } from "shared/redux";
const Flight = ["yes", "no"];
const LossofPassport = ["yes", "no"];
const Delay = ["yes", "no"];

const LossofBaggage = ["yes", "no"];
interface Props {
  handleClickNext: any;
}
export default function TravelBenefits(props: Partial<Props>) {
  const { insuranceTravelIndividual } = useSelector(
    (state: any) => state.root.insurance
  );

  const handleSelect = (selectedOption: string) => {};
  const dispatch = useDispatch();
  const { handleClickNext } = props;
  const formik = useFormik({
    initialValues: {
      flghtDelay: "",
      lossofPassport: "",
      delayinArrival: "",
      lossOfBaggage: "",
    },
    validationSchema: Yup.object(insuranceTravelIndividualBenefitsSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = () => {
    dispatch(
      setInsuranceTravelIndividualPackage({
        ...insuranceTravelIndividual,
        ...formik.values,
      })
    );
    handleClickNext();
  };

  const handleFlight = (selectedOption: string) => {
    formik.setFieldValue("flghtDelay", selectedOption);
  };
  const handlelossofPassport = (selectedOption: string) => {
    formik.setFieldValue("lossofPassport", selectedOption);
  };
  const handledelayinArrival = (selectedOption: string) => {
    formik.setFieldValue("delayinArrival", selectedOption);
  };
  const handlelossOfBaggage = (selectedOption: string) => {
    formik.setFieldValue("lossOfBaggage", selectedOption);
  };
  return (
    <div className={classNames(commonStyles.col6)}>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col8)}>
            <CustomSelect
              onSelect={handleFlight}
              placeholder="Flight Delay"
              options={Flight}
            />
            {formik.touched.flghtDelay && formik.errors.flghtDelay ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.flghtDelay}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col8)}>
            <CustomSelect
              placeholder="Loss of Passport"
              onSelect={handlelossofPassport}
              options={LossofPassport}
            />
            {formik.touched.lossofPassport && formik.errors.lossofPassport ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.lossofPassport}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col8)}>
            <CustomSelect
              onSelect={handledelayinArrival}
              placeholder="Delay in the arrival of luggage"
              options={Delay}
            />
            {formik.touched.delayinArrival && formik.errors.delayinArrival ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.delayinArrival}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            commonStyles.flx,
            commonStyles.col12,
            commonStyles.mb32
          )}
        >
          <div className={classNames(commonStyles.col8)}>
            <CustomSelect
              placeholder="Loss of Baggage"
              onSelect={handlelossOfBaggage}
              options={LossofBaggage}
            />
            {formik.touched.lossOfBaggage && formik.errors.lossOfBaggage ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.lossOfBaggage}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "210px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next"}
            colorType={"Insurance"}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
