import React, { useState, useEffect } from "react";
import styles from "../Pharmcydashborad/dashborad.module.css";
import style from "./dashborad.module.css";
import commonstyle from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import Arrow from "../../../assets/images/arrow.png";
import Arrow2 from "../../../assets/images/arrow2.png";
import SearchBar from "shared/components/Searchbar";
import PhrLineChart from "shared/components/PhrLineChart/lindex";
import PhrPieChart from "shared/components/PhrLineChart/PHrPieChart";
import { FaArrowDown } from "react-icons/fa6";
import { FaArrowUp } from "react-icons/fa6";
import {
  PharmacyGRAPH,
  PharmacyGetNotifications,
  PharmacyGraphDETAILSUpperPortion,
} from "shared/services";
import { LoadingModal, RingLoader } from "shared/components";
import {
  setPharmacyGraphDetails,
  setPharmacyNotication,
  setPharmacyRenderFlag,
  setday2Array,
  setdayArray,
} from "shared/redux";
import { useDispatch, useSelector } from "react-redux";
import { GrRefresh } from "react-icons/gr";
import { TbRefresh } from "react-icons/tb";
import CustomLoader from "shared/components/New_Loader/Loader";
const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
];
function PharmcyDashborad() {
  const { phUser_id, pharmacyGraphDetails, dayArray, dayArray2, renderFlag } =
    useSelector((state: any) => state.root.pharmacy);
  const { user } = useSelector((state: any) => state.root.common);
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const pharmacy_ID = user?._id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const parsedNewOrdersPercentageChange = parseFloat(
    pharmacyGraphDetails.newOrdersPercentageChange.replace("%", "")
  );
  const parsedPendingPercentageChange = parseFloat(
    pharmacyGraphDetails.pendingPercentageChange.replace("%", "")
  );
  const parsedComingOrdersPercentageChange = parseFloat(
    pharmacyGraphDetails.comOrdersPercentageChange.replace("%", "")
  );

  dayArray.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value1 = item.ordersCount;
    }
  );
  dayArray2.forEach(
    (item: { day: string; ordersCount: number }, index: number) => {
      data[index + 1].name = item.day;
      data[index + 1].value2 = item.ordersCount;
    }
  );
  const GraphDetails = () => {
    setLoading(true);
    PharmacyGraphDETAILSUpperPortion()
      .then((res: any) => {
        if (res?.data) {
          dispatch(
            setPharmacyGraphDetails({
              comOrdersPercentageChange: res.data.comOrdersPercentageChange,
              completeTodayOrdersCount: res.data.completeTodayOrdersCount,
              newOrdersPercentageChange: res.data.newOrdersPercentageChange,
              pendingPercentageChange: res.data.pendingPercentageChange,
              pendingYesOrdersCount: res.data.pendingYesOrdersCount,
              todayOrdersCount: res.data.todayOrdersCount,
            })
          );
          // setGraphDetails({
          //   comOrdersPercentageChange: res.data.comOrdersPercentageChange,
          //   completeTodayOrdersCount: res.data.completeTodayOrdersCount,
          //   newOrdersPercentageChange: res.data.newOrdersPercentageChange,
          //   pendingPercentageChange: res.data.pendingPercentageChange,
          //   pendingYesOrdersCount: res.data.pendingYesOrdersCount,
          //   todayOrdersCount: res.data.todayOrdersCount,
          // });
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    ordersCount: number;
  }
  const GraphOnly = () => {
    setLoading(true);

    PharmacyGRAPH()
      .then((res: any) => {
        if (res?.status === 200) {
          const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
          const previousWeekData = res.data.previousWeekData;
          // Use map to convert date to day in currentWeekData
          const dayArray = currentWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          let popped = dayArray.pop();
          //
          //
          dispatch(setdayArray(dayArray));
          // Set values in the data array based on dayArray
          // dayArray.forEach(
          //   (item: { day: string; ordersCount: number }, index: number) => {
          //     data[index + 1].name = item.day;
          //     data[index + 1].value1 = item.ordersCount;
          //   }
          // );
          const dayArray2 = previousWeekData.map((item: WeekDataItem) => {
            const dateObject = new Date(item.date);
            const dayOfWeek = dateObject.toLocaleDateString("en-US", {
              weekday: "short",
            });
            return { day: dayOfWeek, ordersCount: item.ordersCount };
          });
          dispatch(setday2Array(dayArray2));
          // dayArray2.forEach(
          //   (item: { day: string; ordersCount: number }, index: number) => {
          //     data[index + 1].name = item.day;
          //     data[index + 1].value2 = item.ordersCount;
          //   }
          // );
          //
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const Notification = () => {
    PharmacyGetNotifications(pharmacy_ID)
      .then((res: any) => {
        dispatch(setPharmacyNotication(res.data.notifications));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRotate = () => {
    setRotation((prevRotation) => prevRotation - rotationIncrement);
    GraphOnly();
    GraphDetails();
    Notification();
  };
  // useEffect(() => {
  //   GraphOnly();
  //   GraphDetails();
  //   Notification();
  // }, []);

  useEffect(() => {
    GraphOnly();
    GraphDetails();
    Notification();
  }, []);
  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={classNames(styles.col12)}>
        <SearchBar />
        <div className={commonstyle.mr87}>
          <div className={classNames(styles.col12)}>
            <div className={classNames(style.mb24, style.flx_coloumn)}>
              <div className={commonstyle.flx}>
                <p
                  className={classNames(
                    commonstyle.fs32,
                    commonstyle.semiBold,
                    style.mb4
                  )}
                >
                  Hello Servaid!
                </p>
                {loading ? (
                  <div style={{ marginLeft: "16px" }}>
                    <RingLoader color={"#0D47A1"} size={30} />
                  </div>
                ) : (
                  <div className={styles.outerRefresh}>
                    <TbRefresh
                      className={styles.RefreshIcon}
                      style={{ transform: `rotate(${rotation}deg)` }}
                      onClick={handleRotate}
                    />
                  </div>
                )}
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyle.fs12,
                    commonstyle.semiBold,
                    style.gray
                  )}
                >
                  This is what we've got for you today
                </p>
              </div>
            </div>
            <div className={classNames(styles.flxp, styles.mt16)}>
              <div
                className={classNames(
                  styles.w20,
                  styles.colsm12,
                  styles.colmd6
                )}
              >
                <div className={classNames(styles.Maincards, styles.mtmd10)}>
                  <p
                    className={classNames(
                      commonstyle.fs14,

                      commonstyle.bold
                    )}
                  >
                    New Medicine Request
                  </p>
                  <div className={classNames(commonstyle.flx, styles.mt24)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs32,
                          commonstyle.semiBold
                        )}
                      >
                        {pharmacyGraphDetails.todayOrdersCount
                          ? pharmacyGraphDetails.todayOrdersCount
                          : 0}
                      </p>
                    </div>
                    <div>
                      {Number(parsedNewOrdersPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs18,
                          commonstyle.semiBold,
                          style.colorOrange
                        )}
                      >
                        {pharmacyGraphDetails.newOrdersPercentageChange
                          ? pharmacyGraphDetails.newOrdersPercentageChange
                          : "0%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.w20,
                  styles.colsm12,
                  styles.colmd6
                )}
              >
                <div className={classNames(styles.Maincards, styles.mrmd10)}>
                  <p
                    className={classNames(
                      commonstyle.fs14,

                      commonstyle.bold
                    )}
                  >
                    Today Medicine Request
                  </p>
                  <div className={classNames(commonstyle.flx, styles.mt24)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs32,
                          commonstyle.semiBold
                        )}
                      >
                        {pharmacyGraphDetails.pendingYesOrdersCount
                          ? pharmacyGraphDetails.pendingYesOrdersCount
                          : 0}
                      </p>
                    </div>
                    <div>
                      {Number(parsedPendingPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs18,
                          commonstyle.semiBold,
                          parseFloat(
                            pharmacyGraphDetails.pendingPercentageChange
                          ) > 0
                            ? style.colorGreen
                            : style.colorOrange
                        )}
                      >
                        {pharmacyGraphDetails.pendingPercentageChange
                          ? pharmacyGraphDetails.pendingPercentageChange
                          : "0%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.w20,
                  styles.colsm12,
                  styles.colmd6
                )}
              >
                <div className={classNames(styles.Maincards, styles.mtmd10)}>
                  <p
                    className={classNames(
                      commonstyle.fs14,

                      commonstyle.bold
                    )}
                  >
                    Complete Request
                  </p>
                  <div className={classNames(commonstyle.flx, styles.mt24)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs32,
                          commonstyle.semiBold
                        )}
                      >
                        {pharmacyGraphDetails.completeTodayOrdersCount
                          ? pharmacyGraphDetails.completeTodayOrdersCount
                          : 0}
                      </p>
                    </div>
                    <div>
                      {Number(parsedComingOrdersPercentageChange) > 0 ? (
                        <div className={style.iconsOuterGreen}>
                          <FaArrowUp className={classNames(style.arrowUp)} />
                        </div>
                      ) : (
                        <div className={style.iconsOuterOrange}>
                          <FaArrowDown
                            className={classNames(style.arrowdown)}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs18,
                          commonstyle.semiBold,
                          style.colorOrange
                        )}
                      >
                        {pharmacyGraphDetails.comOrdersPercentageChange
                          ? pharmacyGraphDetails.comOrdersPercentageChange
                          : "0%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.w20,
                  styles.colsm12,
                  styles.colmd6
                )}
              >
                <div className={classNames(styles.Maincards, styles.mrmd10)}>
                  <p className={classNames(commonstyle.fs14, commonstyle.bold)}>
                    In Progress Request
                  </p>
                  <div className={classNames(commonstyle.flx, styles.mt24)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs32,
                          commonstyle.semiBold
                        )}
                      >
                        239K
                      </p>
                    </div>
                    <div className={style.iconsOuterGreen}>
                      <FaArrowUp className={classNames(style.arrowUp)} />
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs18,
                          commonstyle.semiBold,
                          style.colorGreen
                        )}
                      >
                        +6.08%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.w20,
                  styles.colsm12,
                  styles.colmd6
                )}
              >
                <div className={classNames(styles.Maincards, styles.mtmd10)}>
                  <p className={classNames(commonstyle.fs14, commonstyle.bold)}>
                    Total Earn
                  </p>

                  <div className={classNames(commonstyle.flx, styles.mt24)}>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs32,
                          commonstyle.semiBold
                        )}
                      >
                        239K
                      </p>
                    </div>
                    <div className={style.iconsOuterOrange}>
                      <FaArrowDown className={classNames(style.arrowdown)} />
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.fs18,
                          commonstyle.semiBold,
                          style.colorOrange
                        )}
                      >
                        -6.08%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(styles.between)}>
              <div
                className={classNames(
                  styles.col8,
                  styles.colsm12,
                  styles.colmd12
                )}
              >
                <div className={styles.graph}>
                  <PhrLineChart data={data} />
                </div>
              </div>
              <div
                className={classNames(
                  styles.col4,
                  styles.colsm12,
                  styles.colmd12
                )}
              >
                <div className={styles.graph2}>
                  <p
                    className={classNames(
                      commonstyle.mb32,
                      commonstyle.semiBold,
                      commonstyle.fs18,
                      commonstyle.colorBlue
                    )}
                  >
                    {" "}
                    Most Medicine by Location
                  </p>
                  <PhrPieChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default PharmcyDashborad;
