import classNames from "classnames";
import { useEffect, useState } from "react";
import React from "react";
import style from "./PhysioDashboard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { Avatar } from "@mui/material";
import Vector from "assets/images/doctoVecttors.png";
import { CustomModal, RingLoader } from "shared/components";
import CommonDoctorsChart from "shared/components/DoctorsCommomChart";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import { DoctGraphDETAILSUpperPortion } from "shared/services/DoctorService";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { acceptInvitationHospital } from "shared/services";
import toast from "react-hot-toast";
import { set_query } from "shared/redux";

const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
];

function Doctor_Dashboard() {
  const { user, query, systemType } = useSelector(
    (state: any) => state.root.common
  );

  const [open, setOpen] = useState(false);
  const [dash_data, set_dash_data] = useState<any>({});
  const AuthInputRef = React.useRef<AuthCodeRef>(null);

  const [upcoming_appointment, set_upcoming_appointment] = useState<any>({});
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const [selectedDur, setSelectedDur] = useState("today");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let parsedAppointmentPercentageChange = null;
  let parsedPatientPercentageChange = null;
  let parsedWaitingPercentageChange = null;
  let parsedCuredPercentageChange = null;
  let upcoming_apt_time = new Date(
    upcoming_appointment?.appointmentDateAndTime
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  let upcoming_apt_date = dayjs(
    upcoming_appointment?.appointmentDateAndTime
  ).format("MM-DD-YYYY");

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDur(event.target.value);
    fetch_dashboard_details(event.target.value);
  };

  const handleRotate = () => {
    fetch_dashboard_details(selectedDur);
  };
  const fetch_dashboard_details = (dur: string) => {
    setLoading(true);
    DoctGraphDETAILSUpperPortion(dur)
      .then((res: any) => {
        set_upcoming_appointment(res?.data?.upcomingAppointment);

        set_dash_data(res?.data);
        parsedAppointmentPercentageChange = parseFloat(
          res?.data?.appointmentPercentageChange?.replace("%", "")
        );
        parsedPatientPercentageChange = parseFloat(
          res?.data?.patientPercentageChange?.replace("%", "")
        );
        parsedWaitingPercentageChange = parseFloat(
          res?.data?.waitingPercentageChange?.replace("%", "")
        );
        parsedCuredPercentageChange = parseFloat(
          res?.data?.curedPercentageChange?.replace("%", "")
        );
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    appointmentCount: number;
  }
  useEffect(() => {
    fetch_dashboard_details(selectedDur);
  }, []);
  const hospitalName = searchParams.get("hospitalName");
  const id = searchParams.get("hospitalId");
  const companyId = searchParams.get("companyId");
  const companyName = searchParams.get("companyName");
  const departmentId = searchParams.get("departmentId");
  useEffect(() => {
    const hospitalId = searchParams.get("hospitalId");
    const companyId = searchParams.get("companyId");
    if (companyId || hospitalId || query) {
      setOpen(true);
    }
  }, [query, searchParams]);

  const handleOnChange = (res: string) => {
    setCode(res);
  };
  const onReject = () => {
    setOpen(false);
    dispatch(set_query(null));
    navigate(`/${systemType}/dashboard`);
  };
  const handleAccepet = () => {
    setLoading(true);
    let params = {
      email: user?.email,
      type: user?.doctorKind,
      code: code,
      ...(departmentId && {departmentId:departmentId}),
      ...(query?.departmentId && { departmentId: query?.departmentId }),
      ...(companyId && { docCompanyId: companyId }),
      ...(id && { hospitalId: id }),
      ...(query?.companyId && { docCompanyId: query?.companyId }),
      ...(query?.hospitalId && { hospitalId: query?.hospitalId }),
    };

    acceptInvitationHospital(params)
      .then((res: any) => {
        toast.success("Accepet SuccessFully");
        setOpen(false);
        dispatch(set_query(null));
        navigate(`/${systemType}/dashboard`);
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const name = companyId ? companyName : hospitalName;
  const sanitizedHospitalName = name?.trim()?.replace(/[)}]/g, "");

  return (
    <>
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />
        <div className={commonstyles.mr87}>
          <div className={commonstyles.flx}>
            <select
              className={style.selections}
              onChange={handleSelectionChange}
              value={selectedDur}
            >
              <option value="today">Today</option>
              <option value="week">Week</option>
            </select>
            <div>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30.8} />
                </div>
              ) : (
                <TbRefresh className={style.Refresh} onClick={handleRotate} />
              )}
            </div>
          </div>

          {/* <ActivationCard /> */}

          <div className={classNames(style.Cardcontainer)}>
            <div className={style.Card}>
              <div className={classNames(style.flxBetween)}>
                <div className={classNames(commonstyles.col7)}>
                  <p
                    className={classNames(
                      commonstyles.fs32,
                      commonstyles.semiBold
                    )}
                  >
                    Hello Dr.
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                  >
                    {user?.name}
                  </p>
                </div>
                <div className={classNames(commonstyles.col4)}>
                  <img src={Vector} className={style.vecttors} />
                </div>
              </div>
            </div>
            <div className={style.Card}>
              <div className={classNames(commonstyles.col12)}>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    commonstyles.mb16
                  )}
                >
                  Upcoming Appointment
                </p>
              </div>
              <div className={classNames(commonstyles.flx)}>
                <div
                  className={classNames(commonstyles.col2, commonstyles.colsm4)}
                >
                  <Avatar
                    sx={{ width: "50px", height: "50px" }}
                    src={upcoming_appointment?.patientId?.userImage}
                  />
                </div>
                <div
                  className={classNames(commonstyles.col5, commonstyles.cols8)}
                >
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                    style={{
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {upcoming_appointment?.patientId?.name}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs12,
                      commonstyles.medium,
                      commonstyles.col8,
                      style.mt8
                    )}
                    style={{ textTransform: "capitalize" }}
                  >
                    {upcoming_appointment?.appointmentType}
                  </p>
                </div>
                <div
                  className={classNames(
                    commonstyles.col5,
                    commonstyles.colsm12
                  )}
                  style={{
                    textAlign: "end",
                  }}
                >
                  <p className={classNames(commonstyles.fs16)}>
                    {upcoming_apt_date}
                  </p>
                  <p>
                    {upcoming_apt_time !== "Invalid Date"
                      ? upcoming_apt_time
                      : "00:00:00"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classNames(style.mianCardConatiner)}>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Total Patient
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.patientCount ? dash_data?.patientCount : 0}
                    </p>
                  </div>
                  <div>
                    {Number(parsedPatientPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data?.patientPercentageChange}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Total Appointments
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.appointmentCount
                        ? dash_data?.appointmentCount
                        : 0}
                    </p>
                  </div>

                  <div>
                    {Number(parsedAppointmentPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data?.appointmentPercentageChange
                        ? dash_data?.appointmentPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Waiting Patients
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.waitingPatients}
                    </p>
                  </div>
                  <div className={style.iconsOuterOrange}>
                    {Number(parsedWaitingPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data?.waitingPercentageChange
                        ? dash_data?.waitingPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Cured Patients
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.curedPatientCount}
                    </p>
                  </div>{" "}
                  <div className={style.iconsOuterGreen}>
                    {Number(parsedCuredPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorGreen
                      )}
                    >
                      {dash_data?.curedPercentageChange
                        ? dash_data?.curedPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(style.flx, style.mt24)}>
              <div className={classNames(commonstyles.col9)}>
                <div className={style.graph} style={{ width: "133%" }}>
                  <CommonDoctorsChart data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal showModal={open}>
        <div className={style.modalContainer}>
          <img
            src={
              user?.doctorImage ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
            }
            alt="Doctor"
            className={style.modalImage}
          />
        </div>
        <div
          className={`${style.modalName} ${
            user?.pmdcNumber
              ? style.modalNameWithPMDC
              : style.modalNameWithoutPMDC
          }`}
        >
          {user?.name || "Default Name"}
        </div>
        {user?.pmdcNumber && (
          <div className={style.modalPMDC}>PMDC Verified</div>
        )}
        <div className={style.modalText}>
          {sanitizedHospitalName || query?.name} wants to add you to their
          network
        </div>
        <div className={style.modalVerificationText}>
          Enter 6-digit verification code sent to your Email
        </div>
        <AuthCode
          ref={AuthInputRef}
          allowedCharacters="numeric"
          onChange={handleOnChange}
          containerClassName={classNames(commonstyles.codeInputContainer)}
          inputClassName={classNames(commonstyles.codeInput)}
        />
        <div className={style.modalButtonContainer}>
          <button className={style.modalButtonReject} onClick={onReject}>
            Reject
          </button>
          <button
            className={style.modalButtonAccept}
            onClick={handleAccepet}
            disabled={loading}
          >
            {loading ? <RingLoader size={35} color={"#fff"} /> : "Accept"}
          </button>
        </div>
      </CustomModal>
    </>
  );
}

export default Doctor_Dashboard;
