import React, { useState } from "react";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { LABORATORY_Booking } from "shared/utils/mainHeaderQuery";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import style from "./LabPayment.module.css";
import classNames from "classnames";
import { Radio } from "@mui/material";
import ContinueButton from "shared/components/ContinueButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";

const LabBookingPayment = () => {
  const [selectedPreference, setSelectedPreference] = useState("");
  const [startCalled, setStartCalled] = useState(false);
  const [appliedCalled, setAppliedCalled] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state?.root?.common);
  let selectedCards = state?.selectedCards;
  let labId = state.labId;
  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedPreference(value);

    if (!startCalled) {
      start();
      setStartCalled(true);
    }

    if (!appliedCalled) {
      applied(value);
      setAppliedCalled(true);
    }
  };

  const start = () => {};

  const applied = (preference: string) => {};
  const inputs = [
    {
      label: "Name",
      type: "text",
      placeholder: user?.name,
      style: {},
      readOnly: true,
    },
    {
      label: "Email",
      type: "number",
      placeholder: user?.email,
      style: {},
      readOnly: true,
    },
    {
      label: "Phone",
      type: "number",
      placeholder: user?.phone,
      style: {},
      readOnly: true,
    },
  ];

  const handlePayment = async () => {
    if (!selectedPreference) {
      alert("Please select your preference before proceeding.");
      return;
    }
    const totalMeditourPrice = selectedCards.reduce(
      (total: any, card: any) => total + card.userAmount,
      0
    );
    await dispatch(setObj(selectedCards));
    navigate("/services/paymentDetail", {
      state: {
        actualAmount: totalMeditourPrice,
        serviceName: "Lab",
        labId: labId,
        selectedPreference: selectedPreference,
      },
    });
  };

  return (
    <div>
      <NavBreadCrumbs {...LABORATORY_Booking} />
      <div className={classNames(commonstyles.container)}>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap
          )}
        >
          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{
              marginTop: "10px",
              marginBottom: "16px",
              marginLeft: "40px",
            }}
          >
            <div className={classNames(style.card)}>
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                <span style={{ color: "#0e54a3" }}>Your Info</span>
              </p>
              {inputs.map((input, index) => (
                <div key={index}>
                  {input.label && (
                    <label className={style.label}>{input.label}</label>
                  )}
                  <input
                    className={style.input}
                    type={input.type}
                    placeholder={input.placeholder}
                    style={input.style || {}}
                    readOnly={input.readOnly}
                  />
                </div>
              ))}

              <div>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue,
                    commonstyles.semiBold
                  )}
                >
                  Select your Preference
                </p>
                <div>
                  <Radio
                    sx={{
                      color: "#0D47A1",
                      "&.Mui-checked": { color: "#0D47A1" },
                    }}
                    value="visit"
                    checked={selectedPreference === "visit"}
                    onChange={handlePreferenceChange}
                  />
                  Visit lab
                </div>
                <div>
                  <Radio
                    sx={{
                      color: "#0D47A1",
                      "&.Mui-checked": { color: "#0D47A1" },
                    }}
                    value="homeSample"
                    checked={selectedPreference === "homeSample"}
                    onChange={handlePreferenceChange}
                  />
                  Current Location
                </div>

                <div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue,
                      commonstyles.semiBold
                    )}
                  >
                    {state?.data?.lab?.name}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs12,
                      commonstyles.colorgray
                    )}
                  >
                    {state?.data?.lab?.location?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{ marginTop: "10px", marginRight: "70px" }}
          >
            <div className={classNames(style.card)}>
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                <span style={{ color: "#0e54a3" }}>Test Details</span>
              </p>
              <div>
                {selectedCards.map((detail: any, index: any) => (
                  <div key={index} className="detail-card">
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {detail.testNameId?.name}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.colorGray,
                        commonstyles.fs12,
                        commonstyles.semiBold
                      )}
                    >
                      {detail?.testDescription}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {detail.userAmount} <span>PKR</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <ContinueButton buttonText="Continue" onClick={handlePayment} />
            </div>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default LabBookingPayment;
