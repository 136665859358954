import classNames from "classnames";
import React, { useState, useEffect } from "react";
import SearchBar from "../Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { IoArrowBack } from "react-icons/io5";
import Style from "./styles.module.css";
import LabOrderItems from "../LabOrderItems";
import { useSelector } from "react-redux";
import Medicine_Table from "../Tables/MedicineTable";
import PhysiotheristsEmpty from "../PhsiotheristEmpty";
import { Avatar } from "@mui/material";
import Gender from "assets/images/bi_gender-female (1).png";
import Blood from "assets/images/healthicons_blood-ab-p-outline.png";
import Age from "assets/images/stock-vector-age-icon-design-vector-illustration-arrow-symbol-age-limit-concept-2148368669 1.png";
import Preference from "assets/images/material-symbols_temp-preferences-eco-outline.png";
import Phone from "assets/images/mingcute_birthday-2-line.png";
import Time from "assets/images/mdi_clock-outline.png";
import Address from "assets/images/mi_location.png";
import TableNew from "../A_New_Components/Table_new";
import dayjs from "dayjs";

const resultDetail = [
  "Test Code",
  "Test Name",
  "Actual Price",
  "Discounted Price",
];

const Order_Details = (props: any) => {
  const { data, handleGoToBack, title, tableData } = props;
  const { systemType } = useSelector((state: any) => state.root.common);

  const [showOrders, setShowOrders] = useState<any[]>([]);

  const g_Total =
    systemType === "laboratory" ? data?.grandTotal : data?.amount?.toFixed(2);
  const payable_Amount =
    systemType === "laboratory"
      ? data?.totalAmount
      : data?.paidByUserAmount?.toFixed(2);

  return (
    <div className={commonstyles.pr36}>
      <div
        className={classNames(
          commonstyles.flxBetween,
          commonstyles.flxWrap,
          commonstyles.mb32
        )}
      >
        <div className={classNames(commonstyles.flx)}>
          <p className={Style.heading}>{title} Details</p>
        </div>
      </div>

      <div className={Style.TableOuter}>
        <div className={classNames(commonstyles.flx)} style={{ gap: "12px" }}>
          <Avatar
            src={data?.userId?.userImage}
            sx={{ width: 56, height: 56 }}
          />
          <div>
            <p className={Style.name}>{data?.customerName}</p>
            <p className={Style.ID}>Mr No.: {data?.MR_NO}</p>
          </div>
        </div>

        <div className={Style.InfoCard}>
          <div className={classNames(commonstyles.flxBetween)}>
            <p className={Style.Subheading}>Basic Information</p>
          </div>
          <div className={Style.infoWrapper}>
            {[
              { img: Gender, label: "Gender", value: data?.userId?.gender },
              { img: Blood, label: "Blood", value: data?.userId?.bloodGroup },
              {
                img: Age,
                label: "Age",
                value: data?.userId?.dateOfBirth
                  ? dayjs().diff(
                      dayjs(data.userId.dateOfBirth, [
                        "YYYY-MM-DD",
                        "YYYY/MM/DD",
                        "DD-MM-YYYY",
                        "DD/MM/YYYY",
                      ]),
                      "year"
                    ) + " years"
                  : "N/A",
              },

              { img: Preference, label: "Preference", value: data?.preference },
              { img: Phone, label: "Phone", value: data?.userId?.phone },
              {
                img: Time,
                label: "Order At",
                value: data?.createdAt
                  ? dayjs(data.createdAt).format("DDMMM,YYYY HH:mm") // Example: 31Jan,2024 18:30
                  : "N/A",
              },

              {
                img: Address,
                label: "Address",
                value: data?.currentLocation?.address,
              },
            ].map((item, index) => (
              <div className={Style.w15} key={index}>
                <div
                  className={commonstyles.flx}
                  style={{ gap: "8px", alignItems: "start" }}
                >
                  <img src={item.img} className={Style.Icon} />
                  <div>
                    <p className={Style.tag}>{item.label}</p>
                    <p className={Style.value}>{item.value}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className={Style.AllTests}>All Tests</p>
        <div>
          {systemType === "laboratory" ? (
            data?.items && data?.items.length > 0 ? (
              <TableNew
                titles={resultDetail}
                data={tableData}
                headerWidth="25%"
                itemWidth="25%"
                height="166px"
              />
            ) : (
              <PhysiotheristsEmpty />
            )
          ) : data?.medicineIds && data?.medicineIds.length > 0 ? (
            <Medicine_Table data={data?.medicineIds} />
          ) : (
            <PhysiotheristsEmpty />
          )}
        </div>
      </div>
    </div>
  );
};

export default Order_Details;
