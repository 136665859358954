import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./AddFlight.module.css";
import flightPlane from "../../../../assets/images/Illustration.png";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { addBidFlight } from "shared/services/TravelAgency";
import FlightForm from "shared/components/FlightForm";
import CustomLoader from "shared/components/New_Loader/Loader";
import ThankyouModel from "shared/components/ThankyouModel";
import dayjs from "dayjs";
import { AnyAaaaRecord } from "dns";
import {
  notifyError,
  notifySuccess,
} from "shared/components/A_New_Components/ToastNotification";
import commonstyle from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
const initialValues: any = {
  companyName: "",
  departureDate: "",
  departureTime: "",
  arrivalDate: "",
  arrivalTime: "",
  amenities: [],
  from: "",
  to: "",
  flightNo: "",
  noOfHandbag: "",
  baggageWeight: "",
};

const validationSchema = Yup.object({
  companyName: Yup.string().required("Company Name is required"),
  companyLogo: Yup.string().required("company Logo is required"),
  from: Yup.string().required("Departure is required"),
  to: Yup.string().required("Arrival is required"),
  departureDate: Yup.string().required("Departure Date is required"),
  departureTime: Yup.string().required("Departure Time is required"),
  arrivalDate: Yup.string().required("Arrival Date is required"),
  arrivalTime: Yup.string().required("Arrival Time is required"),
  flightNo: Yup.string().required("Flight No. is required"),
  noOfHandbag: Yup.string().required("No. of Handbag is required"),
  baggageWeight: Yup.string().required("Baggage is required"),
  amenities: Yup.array().min(1, "Select at least one amenities"),
});
interface FormValues {
  departureTime: string;
  arrivalTime: string;
  departureDate: "";
  arrivalDate: "";
}
const AddFlight: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [flightTypeSelected, setFlightTypeSelected] = useState<string>("");

  const [formVisible, setFormVisible] = useState(false);
  const [returnForm, setReturnForm] = useState(false);
  const [flights, setFlights] = useState<FormValues[]>([]);
  const [flightsReturn, setFlightsReturn] = useState<FormValues[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [returnIndex, setReturnIndex] = useState<number | null>(null);
  const [isReturn, SetIsReturn] = useState<string>("");
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Visible, setVisible] = useState(false);
  const [returnEditMode, setReturnEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const val = state?.data;
  const formatTime = (dateTime: string): string => {
    let parsedTime;

    if (dateTime.includes("T")) {
      parsedTime = dayjs(dateTime);
    } else {
      const currentDate = dayjs().format("YYYY-MM-DD");
      parsedTime = dayjs(`${currentDate}T${dateTime}`);
    }

    if (parsedTime.isValid()) {
      return parsedTime.format("HH:mm");
    } else {
      console.error("Invalid date-time format:", dateTime);
      return "Invalid Time";
    }
  };
  const formatDateTime = (date: string, time: string): string => {
    const parsedTime = dayjs(time, "HH:mm").format("HH:mm");
    const parsedDate = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");

    if (!dayjs(parsedDate).isValid() || !dayjs(parsedTime, "HH:mm").isValid()) {
      console.error("Invalid date or time");
      return "";
    }

    return `${parsedDate}T${parsedTime}:00`;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      const formattedDepartureDateTime = formatDateTime(
        values.departureDate,
        values.departureTime
      );
      const formattedArrivalDateTime = formatDateTime(
        values.arrivalDate,
        values.arrivalTime
      );
      const formattedValues = {
        ...values,
        departureTime: formattedDepartureDateTime,
        arrivalTime: formattedArrivalDateTime,
      };

      if (editMode) {
        const updatedFlights: any = flights.map((flight: any, index: any) =>
          index === currentIndex ? formattedValues : flight
        );
        setFlights(updatedFlights);
        setEditMode(false);
      } else {
        setFlights([...flights, formattedValues]);
      }
      setFormVisible(false);
      resetForm();
    },
  });

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      const formattedDepartureDateTime = formatDateTime(
        values.departureDate,
        values.departureTime
      );
      const formattedArrivalDateTime = formatDateTime(
        values.arrivalDate,
        values.arrivalTime
      );
      const formattedValues = {
        ...values,
        departureTime: formattedDepartureDateTime,
        arrivalTime: formattedArrivalDateTime,
      };

      if (returnEditMode) {
        const updatedFlights = flightsReturn.map((flight: any, index: any) =>
          index === returnIndex ? formattedValues : flight
        );
        setFlightsReturn(updatedFlights);
        setReturnEditMode(false);
      } else {
        setFlightsReturn([...flightsReturn, formattedValues]);
      }
      setReturnForm(false);
      resetForm();
    },
  });
  // const handleSave = async () => {
  //   const errors = await formik.validateForm();

  //   if (Object.keys(errors).length > 0) {
  //     toast.error(Object.values(errors)[0]);
  //   } else {
  //     formik.handleSubmit();
  //   }
  // };
  const handleSave = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      // Mark all fields as touched to trigger inline errors
      formik.setTouched(
        Object.keys(errors).reduce((acc: any, key: any) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    } else {
      formik.handleSubmit();
    }
  };
  const handleFlightTypeSelection = (type: string) => {
    setFlightTypeSelected(type);
    setFormVisible(true);
  };

  const handleFlightType = (type: string) => {
    SetIsReturn(type);
    setReturnForm(true);
  };

  const handleEditFlight = (index: number) => {
    const flightToEdit = flights[index];
    formik.setValues(flightToEdit);

    setCurrentIndex(index);
    setEditMode(true);
    setFormVisible(true);
    formik.validateForm();
  };
  const handleDeleteFlight = () => {
    const updatedFlights = flights.filter(
      (_: any, i: any) => i !== currentIndex
    );
    setFlights(updatedFlights);
    //
    setModalVisible(false);
    if (updatedFlights.length === 0) {
      setFlightTypeSelected("");
      formik.resetForm();
    }
  };
  const handleDeleteReturn = () => {
    const updatedFlights = flightsReturn.filter(
      (_: any, i: any) => i !== returnIndex
    );
    setFlightsReturn(updatedFlights);
    setModalVisible(false);
    if (updatedFlights.length === 0) {
      SetIsReturn("");
    }
  };
  const polForm = useFormik({
    initialValues: {
      cancelationDuration: "",
      cancelationDeduction: "",
      TicketPrice: "",
    },
    validationSchema: Yup.object({
      cancelationDuration: Yup.string().required(
        "Cancellation Duration is required"
      ),
      cancelationDeduction: Yup.string().required(
        "Cancellation Deduction is required"
      ),
      TicketPrice: Yup.string().required("Ticket Price is required"),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      addBid(values);
    },
  });
  const addBid = (values: any) => {
    setLoading(true);
    let params: any = {
      requestId: val?._id,
      // flightType: flightTypeSelected.toLowerCase(),
      flightDetails: [...flights],
      flightPolicies: {
        cancelationDuration: values?.cancelationDuration,
        cancelationDeduction: values?.cancelationDeduction,
      },
      ticketPrice: parseInt(values?.TicketPrice, 10),
    };
    if (val?.requestType === "round") {
      params = {
        ...params,
        returnFlights: [...flightsReturn],
      };
    } else {
      params = {
        ...params,
      };
    }

    addBidFlight(params)
      .then(() => {
        // showToast('Success', 'Flight Bid successfully', true);
        setVisible(true);
        // toast.success("Success");
        notifySuccess("Success");
        setTimeout(() => {
          setVisible(false);
          navigate("/travelAgency/ticketRequests");
        }, 3000);
      })
      .catch((err) => {
        // showToast(err, 'error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addForm = () => {
    formik.resetForm();
    setFormVisible(true);
  };
  const addFormReturn = () => {
    form.resetForm();
    setReturnForm(true);
  };
  const handleEditReturn = (index: number) => {
    const flightToEdit = flightsReturn[index];
    form.setValues(flightToEdit);
    setReturnIndex(index);
    setReturnEditMode(true);
    setReturnForm(true);
  };
  // const handleSaveReturn = async () => {
  //   const errors = await form.validateForm();

  //   if (Object.keys(errors).length > 0) {
  //     toast.error(Object.values(errors)[0]);
  //   } else {
  //     form.handleSubmit();
  //   }
  // };
  const handleSaveReturn = async () => {
    const errors = await form.validateForm();

    if (Object.keys(errors).length > 0) {
      form.setTouched(
        Object.keys(errors).reduce((acc: any, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    } else {
      form.handleSubmit();
    }
  };
  // const onPress = async () => {
  //   const errors = await polForm.validateForm();
  //   if (Object.keys(errors).length > 0) {
  //     toast.error(Object.values(errors)[0]);
  //   } else if (val?.requestType === "round" && flightsReturn.length === 0) {
  //     toast.error("Please add a return flight before sharing the bid");
  //   } else {
  //     polForm.handleSubmit();
  //   }
  // };
  const onPress = async () => {
    const errors = await polForm.validateForm();

    if (Object.keys(errors).length > 0) {
      polForm.setTouched(
        Object.keys(errors).reduce((acc: any, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    } else if (val?.requestType === "round" && flightsReturn.length === 0) {
      // Use toast for special-case errors
      toast.error("Please add a return flight before sharing the bid");
      notifyError("Please add a return flight before sharing the bid");
    } else {
      polForm.handleSubmit();
    }
  };

  return (
    <div className={classNames(commonstyle.col12)}>
      <SearchBar />
      <div className={styles.containerFlight}>
        {!flightTypeSelected && (
          <>
            <div className={classNames(styles.flightimageconatiner)}>
              <img
                src={flightPlane}
                alt="Flight"
                className={styles.flightimage}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className={styles.addButton}
                onClick={() => setFormVisible(true)}
              >
                Add Flight
              </button>
            </div>

            {formVisible && (
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.optionButton}`}
                  onClick={() => handleFlightTypeSelection("Direct")}
                >
                  Direct
                </button>
                <button
                  className={`${styles.optionButton}`}
                  onClick={() => handleFlightTypeSelection("Stay")}
                >
                  Stay
                </button>
              </div>
            )}
          </>
        )}
        {flightTypeSelected && (
          <FlightForm
            flightType={flightTypeSelected}
            formik={formik}
            handleSubmit={handleSave}
            flights={flights}
            handleEditFlight={handleEditFlight}
            formVisible={formVisible}
            flightsReturn={flightsReturn}
            addForm={addForm}
            handleDeleteFlight={handleDeleteFlight}
            setCurrentIndex={setCurrentIndex}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            setLoading={setLoading}
          />
        )}
        {flights.length > 0 &&
          val?.requestType === "round" &&
          !isReturn &&
          !formVisible &&
          !editMode && (
            <>
              <label className={styles.returnFlight}>Return Flight:</label>
              <div style={{ display: "flex", alignSelf: "center" }}>
                <img src={flightPlane} alt="Flight" className={styles.image} />
              </div>
              <button
                className={styles.addButton}
                onClick={() => setReturnForm(true)}
              >
                Add Return Flight
              </button>

              {returnForm && (
                <div className={styles.buttonContainer}>
                  <button
                    className={`${styles.optionButton}`}
                    onClick={() => handleFlightType("Direct")}
                  >
                    Direct
                  </button>
                  <button
                    className={`${styles.optionButton}`}
                    onClick={() => handleFlightType("Stay")}
                  >
                    Stay
                  </button>
                </div>
              )}
            </>
          )}
        {isReturn && (
          <FlightForm
            flightType={isReturn}
            formik={form}
            handleSubmit={handleSaveReturn}
            flights={flightsReturn}
            handleEditFlight={handleEditReturn}
            formVisible={returnForm}
            addForm={addFormReturn}
            handleDeleteFlight={handleDeleteReturn}
            showText
            modalVisible={modalVisible}
            setCurrentIndex={setReturnIndex}
            setModalVisible={setModalVisible}
            setLoading={setLoading}
            returnEditMode={returnEditMode}
          />
        )}
        {flights.length > 0 && !editMode && !formVisible && !returnForm && (
          <>
            <label className={styles.returnFlight}>Flight Policies:</label>
            <div className={styles.cancellationInputContainer}>
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  placeholder="Cancellation Duration *"
                  value={polForm.values.cancelationDuration}
                  onChange={(e: any) =>
                    polForm.setFieldValue("cancelationDuration", e.target.value)
                  }
                  className={styles.cancellationInput}
                />
                {polForm.touched.cancelationDuration &&
                  polForm.errors.cancelationDuration && (
                    <div className={commonstyle.error}>
                      {polForm.errors.cancelationDuration}
                    </div>
                  )}
              </div>

              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  placeholder="Cancellation Deduction *"
                  value={polForm.values.cancelationDeduction}
                  onChange={(e: any) =>
                    polForm.setFieldValue(
                      "cancelationDeduction",
                      e.target.value
                    )
                  }
                  className={styles.cancellationInput}
                />
                {polForm.touched.cancelationDeduction &&
                  polForm.errors.cancelationDeduction && (
                    <div className={commonstyle.error}>
                      {polForm.errors.cancelationDeduction}
                    </div>
                  )}
              </div>

              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  placeholder="Total Price *"
                  value={polForm.values.TicketPrice}
                  onChange={(e: any) =>
                    polForm.setFieldValue("TicketPrice", e.target.value)
                  }
                  className={styles.cancellationInput}
                />
                {polForm.touched.TicketPrice && polForm.errors.TicketPrice && (
                  <div className={commonstyle.error}>
                    {polForm.errors.TicketPrice}
                  </div>
                )}
              </div>

              <div className={styles.buttonContainer} onClick={onPress}>
                <button className={styles.addBidButton}>Share Bid</button>
              </div>
            </div>
          </>
        )}

        {/* {Visible && (
       <ThankyouModel
         mainMessageLine1="Your Bid has been successful"
         mainMessageLine2="Thank you"
       />
     )} */}
        {loading && <CustomLoader />}
      </div>
    </div>
  );
};

export default AddFlight;
