import React, { useEffect, useState, useRef } from "react";
import searchBar from "./search.module.css";
import commomstyle from "../../utils/common.module.css";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { notify } from "shared/services";
import medilogo from "assets/images/logoMeditour.png";
import { IoMdNotifications } from "react-icons/io";
import { FaRegBell } from "react-icons/fa6";
function Notifications() {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationdata, setNotification] = useState<any>([]);
  const handleToggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  const { user, systemType } = useSelector((state: any) => state.root.common);
  const notificationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    getNotifications();
  }, [isNotificationOpen]);
  const getNotifications = () => {
    notify(user?._id)
      .then((res: any) => {
        setNotification(res?.data?.notifications);
      })
      .catch((error: any) => {
        console.log(error, "..err");
      })
      .finally(() => {});
  };

  return (
    <div className={searchBar.sticky}>
      <div>
        <div className={classNames(searchBar.col12, searchBar.flxend)}>
          {systemType == "user" ? (
            <>
              <FaRegBell
                size={24}
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={handleToggleNotification}
              />
              {notificationdata.length > 0 && (
                <GoDotFill className={searchBar.notificationDotUser} />
              )}
            </>
          ) : (
            <div
              className={searchBar.notification}
              onClick={handleToggleNotification}
            >
              <IoMdNotifications size={20} color="#fff" />
              {notificationdata.length > 0 && (
                <GoDotFill className={searchBar.notificationDot} />
              )}
            </div>
          )}

          {isNotificationOpen && (
            <div
              className={searchBar.notificationTab}
              ref={notificationRef}
              style={{ top: systemType === "user" ? "100%" : "10%" }}
            >
              {notificationdata.length > 0 ? (
                notificationdata.map((n: any) => (
                  <div className={searchBar.notificationcard} key={n.id}>
                    <div
                      className={classNames(commomstyle.col2, searchBar.Avatar)}
                    >
                      <img src={medilogo} className={searchBar.Round} />
                    </div>
                    <div
                      className={classNames(commomstyle.col8, searchBar.mr16)}
                    >
                      <p
                        className={classNames(
                          commomstyle.fs16,
                          commomstyle.semiBold
                        )}
                      >
                        {n?.message}
                      </p>
                    </div>
                    <div className={commomstyle.col2}>
                      <GoDotFill className={searchBar.dot} />
                      <p>
                        {n?.updatedAt
                          ? new Date(n.updatedAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                          : ""}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className={searchBar.emptynotyification}>
                  <p
                    className={classNames(
                      commomstyle.fs16,
                      commomstyle.semiBold,
                      commomstyle.colorBlue
                    )}
                    style={{ margin: "auto " }}
                  >
                    No Notifications
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
