import React, { useState } from "react";
import classNames from "classnames";
import styles from "./forgetPassword.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "assets/images/logoMed.png";
import { useNavigate } from "react-router-dom";
import commonStyles from "shared/utils/common.module.css";
import { doctorResetSchema, GetColorCode } from "shared/utils";
import { __sendLinkToEmail } from "shared/services/DoctorService";
import backgroundimg from "assets/images/Background.png";
import userBackgroundimg from "assets/images/BackgroundLoginSignUp.png";
import {
  CustomModal,
  InputField,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { CustomInput } from "shared/components";
import { EmailSendModel } from "shared/components/CustomEmailModel/emailSendModel";

import { FaChevronLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import CustomLoader from "shared/components/New_Loader/Loader";
import { notifySuccess } from "shared/components/A_New_Components/ToastNotification";
const DoctorForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { sendLink, newPasswordRout } = GetColorCode();
  const [SuccessModel, setSuccessModel] = useState(false);
  const [error, setError] = React.useState("");
  const { systemType } = useSelector((state: any) => state.root.common);

  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: Yup.object(doctorResetSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const check =
    systemType === "physiotherapist" ||
    systemType === "doctor" ||
    systemType === "psychologist" ||
    systemType === "paramedic" ||
    systemType === "nutritionist";
  const handleSubmit = () => {
    setLoading(true);
    let params: any = {
      email: formik.values.Email,
      type: check ? "doctor" : systemType,
    };
    if (check) {
      params.doctorKind = systemType;
    }

    __sendLinkToEmail(params, sendLink)
      .then((res: any) => {
        if (res.status === 200) {
          // if (systemType === "user") {
          notifySuccess(res?.data?.message);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            navigate(newPasswordRout, {
              state: { email: formik.values.Email },
            });
          }, 1000);
          // }
          // else {
          //   setSuccessModel(true);
          //   setTimeout(() => {
          //     setSuccessModel(false);
          //     navigate(`/${systemType}/login`);
          //   }, 1000);
          // }
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate(`/${systemType}/login`);
  };
  return (
    <div className={styles.container}>
      <img
        src={systemType === "user" ? userBackgroundimg : backgroundimg}
        alt="Background"
        className={styles.backgroundimg}
      />

      <div className={styles.SwitchButtonConatiner}>
        <div className={styles.BackToHome} onClick={handleGoToHome}>
          <FaChevronLeft className={styles.BackIcon} />
          <p
            className={classNames(
              commonStyles.colorBlue,
              commonStyles.fs14,
              commonStyles.semiBold
            )}
          >
            Back to Login
          </p>
        </div>
      </div>
      <div className={styles.formcontainer}>
        <img src={logo} alt="" className={styles.logo} />
        <div style={{ alignSelf: "center", display: "flex" }}>
          <p className={styles.welcomback}>
            <span className={styles.colorBlue}>Forgot your</span>{" "}
            <span className={styles.colorOrange}> password?</span>
          </p>
        </div>
        {error && (
          <div className={classNames(commonStyles.error)}>*{error}</div>
        )}
        {/* <form onSubmit={formik.handleSubmit}>
        )} */}
        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: "80%",
            margin: "0 auto",
          }}
        >
          <div style={{ marginBottom: "8px", marginTop: "50px" }}>
            <div style={{ marginBottom: "16px" }}>
              <InputField
                id="Email"
                formik={formik}
                placeholder="Please Enter Email"
                onChange={formik?.handleChange("Email")}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton
              arrowNext
              children={
                loading ? <RingLoader size={35} color={"#fff"} /> : "Send Code"
              }
              type="submit"
              colorType={systemType === "user" ? "blue" : "MedicalService"}
            />
            {loading && <CustomLoader />}
          </div>
        </form>
      </div>
      <CustomModal
        showModal={SuccessModel}
        children={
          <EmailSendModel
            setSuccessModel={setSuccessModel}
            showText="Link Send to the Email, Please Check Email"
          />
        }
      />
    </div>
  );
};

export default DoctorForgetPassword;
