import React, { useState, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Request.module.css";
import { LuUser2 } from "react-icons/lu";
import { MdPhone } from "react-icons/md";
import { BsClock } from "react-icons/bs";
import { LuCalendarDays } from "react-icons/lu";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import empty from "assets/images/empty2.png";
import { CustomModal } from "shared/components";
import { IoClose } from "react-icons/io5";
import { Avatar } from "@mui/material";
import {
  rentCarAcceptRequest,
  rentCarGetAllRequest,
  rentCarRejectRequest,
  rentcarREQUESTDETAIL,
} from "shared/services/RentaCar";
import DoctorReferModel from "shared/components/DoctorEmpty/DoctorReferModel";
import CustomLoader from "shared/components/New_Loader/Loader";
import dayjs from "dayjs";

function RentAcarRequest() {
  const [loading, setLoading] = useState(false);
  const [vahicle, setVahicle] = useState([]);
  const [Id, setId] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [showRequestDetialModel, setShowRequestDetialModel] = useState(false);
  const handleModelOpen = (id: string) => {
    setId(id);
    setDetailModal(true);
  };
  const fetchAllRequests = () => {
    setLoading(true);
    rentCarGetAllRequest()
      .then((res: any) => {
        if (res?.data?.auth) {
          setVahicle(res?.data?.requests);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const AcceptRequest = (id: string) => {
    setLoading(true);

    rentCarAcceptRequest(id)
      .then((res: any) => {
        fetchAllRequests();
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const RejectRequest = (id: string) => {
    setLoading(true);

    rentCarRejectRequest(id)
      .then((res: any) => {
        if (res?.status === 200) {
          fetchAllRequests();
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAllRequests();
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.selection} style={{ margin: "0px 0 32px 0" }}>
          <p style={{ color: "#00276D", fontWeight: "600", fontSize: "20px" }}>
            Requests
          </p>
        </div>
        {loading ? (
          <CustomLoader />
        ) : vahicle.length > 0 ? (
          vahicle.map((val: any, key: any) => {
            return (
              <div className={styles.card}>
                <div
                  className={classNames(commonstyles.flx)}
                  onClick={() => handleModelOpen(val?._id)}
                >
                  <div className={commonstyles.flx}>
                    <LuUser2 className={styles.icon} />
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {val.name}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx)}>
                    <div
                      style={{ margin: "0px 0  0 111px " }}
                      className={commonstyles.flx}
                    >
                      <LuCalendarDays className={styles.icon} />
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        {val?.createdAt
                          ? new Date(val.createdAt).toISOString().split("T")[0]
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: "32px" }}
                    className={commonstyles.flx}
                  >
                    <BsClock className={styles.icon} />
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {new Date(val?.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </p>
                  </div>
                  <div style={{ marginLeft: "32px" }} className={styles.mt32}>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.semiBold
                      )}
                    >
                      {val.status}
                    </p>
                  </div>
                  <div className={classNames(commonstyles.flx, styles.end)}>
                    <IoMdCloseCircleOutline
                      className={styles.cross}
                      onClick={(e) => {
                        e.stopPropagation();
                        RejectRequest(val._id);
                      }}
                    />
                    <FaRegCheckCircle
                      className={styles.check}
                      onClick={(e) => {
                        e.stopPropagation();
                        AcceptRequest(val._id);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            <img src={empty} className={classNames(styles.empty3)} />
          </div>
        )}

        {/* <div>
          <CustomModal
            showModal={showRequestDetialModel}
            children={
              <RequestDetialModel
                setShowRequestDetialModel={setShowRequestDetialModel}
              />
            }
          />
        </div> */}
      </div>
      <CustomModal
        showModal={detailModal}
        children={
          <RequestDetialModel Id={Id} setDetailModal={setDetailModal} />
        }
      />
    </div>
  );
}

export default RentAcarRequest;
interface RequestDetailss {
  name: string;
  phone: string;
  pickupLocation: string;
  pickupDateTime: string;
  dropoffLocation: string;
  dropoffDateTime: string;
}
interface vehicleDETAILS {
  vehicleColour: string;
  vehicleModel: string;
  vehicleName: string;
  vehicleType: string;
  vehicleRegisterationNo: string;
}
interface userIdDetails {
  userImage: string;
}
const RequestDetialModel = (props: any) => {
  const [data, setData] = useState<RequestDetailss | null>(null);
  const [vehicleId, setVehicleId] = useState<vehicleDETAILS | null>(null);
  const [userId, setUserId] = useState<userIdDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const { setDetailModal, Id } = props;

  const handleCloseModal = () => {
    setDetailModal(false);
  };

  const fetchdetail = () => {
    setLoading(true);

    rentcarREQUESTDETAIL(Id)
      .then((res: any) => {
        setData(res?.data?.request);
        setVehicleId(res?.data?.request?.vehicleId);
        setUserId(res?.data?.request?.userId);
      })
      .catch((err: any) => {
        console.error("Error fetching appointments:", err);
        alert(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchdetail();
  }, []);
  return loading ? (
    <DoctorReferModel showModal={loading} />
  ) : (
    <div style={{ width: "500px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IoClose className={styles.close} onClick={handleCloseModal} />
      </div>
      <div className={styles.profile}>
        <Avatar
          src={userId?.userImage}
          style={{ height: "91px", width: "91px" }}
        />
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>User Name </label>
          <p>{data?.name}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>Phone No.</label>
          <p>{data?.phone}</p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>Vehicle Name</label>
          <p>{vehicleId?.vehicleName}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>Vehicle Reg</label>
          <p>{vehicleId?.vehicleRegisterationNo}</p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>Vehicle color</label>
          <p>{vehicleId?.vehicleColour}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>Vehicle Model</label>
          <p>{vehicleId?.vehicleModel}</p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>
            Pick up location
          </label>
          <p>{data?.pickupLocation}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>
            Drop off Location
          </label>
          <p>{data?.dropoffLocation}</p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>Pick Up Date</label>
          <p>{dayjs(data?.pickupDateTime).format("MM-DD-YYYY")}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>Drop Off Date</label>
          <p>{dayjs(data?.dropoffDateTime).format("MM-DD-YYYY")}</p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          styles.mb16,
          commonstyles.colorBlue
        )}
      >
        <div className={classNames(commonstyles.col6, commonstyles.mr32)}>
          <label className={classNames(commonstyles.fs14)}>Pick Up Time</label>
          <p>{dayjs(data?.pickupDateTime).format("h:mm a")}</p>
        </div>
        <div className={classNames(commonstyles.col6)}>
          <label className={classNames(commonstyles.fs14)}>Drop Off Time</label>
          <p>{dayjs(data?.dropoffDateTime).format("h:mm a")}</p>
        </div>
      </div>
    </div>
  );
};
