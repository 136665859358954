import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import HomePage from "./HomeNavBar";

export default function Home() {
  const { user, paymentParams, systemType } = useSelector(
    (state: any) => state?.root?.common
  );
  useEffect(() => {
    document.title = "MediTour Global | Home";
  });
  return <HomePage />;
}
