import React from "react";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import style from "./style.module.css";
import classNames from "classnames";

interface TableNewProps {
  titles?: any;
  type?: any;
  data?: any[];
  handleGoToDetail?: (id: any) => void;
  headerWidth?: string;
  itemWidth?: string;
  edit?: (id: string) => void;
  onDelete?: (id: string) => void;
  setting?: (id: string) => void;
  height?: any;
  show?: any;
}

const TableNew: React.FC<TableNewProps> = ({
  titles,
  data,
  type,
  handleGoToDetail,
  headerWidth,
  itemWidth,
  height,
  show,
}) => {
  return (
    <div
      className={classNames(style.tableContainer)}
      style={{
        height: height ? height : "325px",
      }}
    >
      <table>
        <thead>
          <tr>
            {titles?.map((title: any, index: any) => (
              <th key={index} style={{ width: headerWidth }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row?: any, index?: any) => {
            //
            return (
              <tr
                style={{ cursor: show ? show : "pointer" }}
                key={index}
                onClick={() =>
                  type === "appoitment"
                    ? handleGoToDetail && handleGoToDetail(row[0])
                    : handleGoToDetail && handleGoToDetail(index)
                }
              >
                {row?.map((title: any, index: any) => {
                  if (title === null) {
                    return null;
                  }

                  return (
                    <td
                      key={index}
                      style={{ width: itemWidth, textTransform: "capitalize" }}
                    >
                      {title}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableNew;
