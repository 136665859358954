import { DoctorLogin } from "pages";
import RentCarForget from "pages/TravelTourism/Rent a Car/Forgetpassword/RentCarForget";
import RentCarUpdatepassword from "pages/TravelTourism/Rent a Car/Forgetpassword/RentCarUpdatepassword";
import RentCarLogin from "pages/TravelTourism/Rent a Car/Login/Index";
import CarRentSignup from "pages/TravelTourism/Rent a Car/Signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const RentACarLoginAuth = (props: any) => {
  return (
    <Routes>
      <Route path="/login" element={<DoctorLogin />} />
      {/* <Route path="/login" element={<RentCarLogin />} /> */}
    </Routes>
  );
};

export default RentACarLoginAuth;
