import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const hospitalSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_SIGNUP, params);
};

export const hospitalSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_SEND_CODE_TO_EMAIL, params);
};

export const hospitalConfirmEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_CONFIRM_EMAIL, params);
};

export const hospitalCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.HOS_COMPLETE_SIGNUP}?id=${id}`, params);
};

export const hospitalLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_LOGIN, params);
};

export const resetLinkhospital = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_RESET_LINK, params);
};

export const resetPasswordhospital = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.HOS_RESET_PASSWORD}?token=${token}`,
    params
  );
};

export const getAllDepartments = (query?:any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOS_GET_ALL_DEPARTMENTS}?query=${query}`);
};

export const hospitalLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_LOGOUT);
};

export const hospitalAddDepartment = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_ADDDEPARMENT, params);
};
export const hospitalGetDepartment = (id: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOS_GETDEPARTMENT}?id=${id}`);
};
export const hospitalEditDepartment = (id: any, params: any) => {
  return HTTP_CLIENT.put(`${ENDPOINTS.HOS_EDITDEPARTMENT}?id=${id}`, params);
};

export const hospitalUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.HOS_UPDATEPROFILE, params);
};

export const hospitalGetAllAppointmentRequest = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOS_GETALLAPPOINTMENTSREQUEST);
};
export const hospitalGetTodayAppointmentRequest = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOS_TODAYSAPPOINTMENTREQUEST);
};

export const hospitaldashobardAppointmentStats = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOS_GETAPPOINTSMENTSTATS);
};

export const hospitalGetSingleAppointment = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOS_GETSINGLEAPPOINMENT}?appointmentId=${id}`
  );
};
export const HospitalAddAppointmentHistory = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.HOS_ADDHISTORY, params);
};

export const HospitalAddAppointmentPrescription = (
  id: string,
  patientid: string,
  params: any
) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.HOS_ADDPRESCRIPTION}?appointmentId=${id}&patientId=${patientid}`,
    params
  );
};

export const hospitalGetPrescription = (appointmentId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOS_GETPRESCRIPTION}?prescriptionId=${appointmentId}`
  );
};

export const hospitaldashobardGetCounts = () => {
  return HTTP_CLIENT.get(ENDPOINTS.HOS_GETCOUNTS);
};
export const hospitalgetDoctors = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOS_GETHOSDOCTORS}?page=${pageno}`);
};

export const hospitalDoctorSearch = (search: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOS_SEARCHDOCTOR}?search=${search}`);
};
export const hospitalSendRequesttoDOCTOR = (id: string) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.HOS_SENDREQUESTTODOCTOR}?id=${id}`);
};

export const hospitalgetAppointments = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOS_GETACCEPTEDAPPOINTMENTS}?page=${pageno}`
  );
};
export const hospitalgetPatients = (pageno: number) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.HOS_GETALLPATIENTS}?page=${pageno}`);
};

export const hospitalgetPatientDetails = (patientId: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.HOS_PATIENTDETAILS}?patientId=${patientId}`
  );
};

export const hospitalDELETEDEPART = (id: string) => {
  return HTTP_CLIENT.delete(`${ENDPOINTS.HOS_DELETE_DEPART}?id=${id}`);
};

export const hospitalConfirmCode = (id: string, params: any) => {
  return HTTP_CLIENT.post(`${ENDPOINTS.HOS_CONFIRMCODE}?id=${id}`, params);
};
