import React, { useState } from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./DetailStyle.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { flightDecline } from "shared/services";
import { DeleteModal } from "shared/components";

import LabDownload from "assets/images/download.png";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { TRAVEL_DETAIL } from "shared/utils/mainHeaderQuery";
import dayjs from "dayjs";

const TravelDetail = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ticketName, setTicketName] = useState<any>(null);
  const navigate = useNavigate();
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  let url = state?.data?.eTicket ? state?.data?.eTicket : "";
  // const fileName: any = url.split("/").pop()?.split("?")[0];
  const urlParts = state?.type === "booking" ? url?.split("/") : "";
  const fileName: any =
    state?.type === "booking"
      ? urlParts[urlParts?.length - 1]?.split("?")[0]
      : null;

  const handleAccept = () => {
    Navigate("/services/myRequest/TravelAccept", {
      state: {
        totalTravelers: state?.totalTravelers,
        item: state?.data,
      },
    });
  };

  const declineRequest = () => {
    setLoading(true);
    let params = {
      requestId: selectedRequestId,
    };
    flightDecline(params)
      .then((res: any) => {
        if (res?.data?.auth === true) {
          navigate(-1);
        }
        setShowModal(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const onCanelModal = () => {
    setShowModal(false);
  };
  const openModal = (requestId: any) => {
    setSelectedRequestId(requestId);
    setShowModal(true);
  };

  const checkType = state?.type === "booking";
  const items = checkType
    ? state?.data?.bidRequestId?.flightDetails
    : state?.data?.flightDetails;
  const returnitem = checkType
    ? state?.data?.bidRequestId?.returnFlights
    : state?.data?.returnFlights;

  const downloadAndSaveImage = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }
    const fileName: string = (fileUrl
      ?.split("/")
      .pop()
      ?.split("?")[0]
      .replace(/%/g, "") || "") as string;
    setTicketName(fileName);

    setTicketName(fileName);
    const downloadLink: any = document?.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "insurance_file.pdf";
    document.body.appendChild(downloadLink);
    // downloadLink.click();
    window.open(downloadLink);

    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <NavBreadCrumbs {...TRAVEL_DETAIL} />

      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <p
          className={classNames(
            commonstyle.colorBlue,
            commonstyle.semiBold,
            commonstyle.fs32
          )}
        >
          {state?.data?.agencyId?.name}
        </p>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
            <>
              {items?.map((flight: any, index: any) => (
                <div key={index} className={classNames(style.flightcontainer)}>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.semiBold,
                      commonstyle.fs24
                    )}
                  >
                    Flight Details
                  </p>

                  <div className={classNames(style.imgconatiner)}>
                    <img
                      src={flight?.companyLogo}
                      alt={flight?.companyName}
                      className={style.flyimg}
                    />
                    <p
                      className={classNames(
                        commonstyle.fs16,
                        commonstyle.colorBlue
                      )}
                    >
                      {flight?.companyName}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      commonstyle.flx,
                      commonstyle.flxCenter
                    )}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "90%",
                        position: "relative",
                        color: "#0e54a3",
                      }}
                    >
                      {/* Left City */}
                      <div
                        style={{
                          marginRight: "10px",
                          fontSize: "14px",
                          width: "25%",
                        }}
                      >
                        <p>{flight?.from}</p>
                        <p>{dayjs(flight?.departureTime).format("h:mm a")}</p>
                        <p>{dayjs(flight?.departureDate).format("DD-MM-YY")}</p>
                      </div>

                      {/* Plane Icon and Time */}
                      <div
                        style={{
                          position: "relative",
                          width: "50%",
                          height: "1px",
                          borderTop: "2px dotted #0e54a3",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BiSolidPlaneAlt
                          style={{
                            position: "absolute",
                            top: "-33px",
                            fontSize: "24px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "15px", // Position below the dotted line
                            fontSize: "14px",
                            color: "#0e54a3",
                          }}
                        >
                          {flight?.flightTime}
                        </div>
                      </div>

                      {/* Right City */}
                      <div
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          width: "25%",
                        }}
                      >
                        <p> {flight?.to}</p>
                        <p>{dayjs(flight?.arrivalTime).format("h:mm a")}</p>
                        <p>{dayjs(flight?.arrivalDate).format("DD-MM-YY")}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        margin: "10px 0",
                      }}
                    >
                      <p
                        className={classNames(
                          commonstyle.fs16,
                          commonstyle.colorBlue,
                          commonstyle.semiBold
                        )}
                      >
                        Amenities
                      </p>
                    </div>
                    <div>
                      {flight?.amenities?.map((amenity: any, i: any) => (
                        <p
                          key={i}
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {amenity}
                        </p>
                      ))}
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        No. of Handbag:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight?.noOfHandbag}
                        </span>
                      </p>
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        Baggage Weight:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight?.baggageWeight}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {returnitem?.length > 0 && (
                <>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.semiBold,
                      commonstyle.fs24
                    )}
                  >
                    Return Flight
                  </p>
                  {returnitem?.map((flight: any, index: any) => (
                    <div
                      key={index}
                      className={classNames(style.flightcontainer)}
                    >
                      <div className={classNames(style.imgconatiner)}>
                        <img
                          src={flight?.companyLogo}
                          alt={flight?.companyName}
                          className={style.flyimg}
                        />
                        <p
                          className={classNames(
                            commonstyle.fs16,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight?.companyName}
                        </p>
                      </div>
                      <div
                        className={classNames(
                          commonstyle.flx,
                          commonstyle.flxCenter
                        )}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "90%",
                            position: "relative",
                            color: "#0e54a3",
                          }}
                        >
                          {/* Left City */}
                          <div
                            style={{
                              marginRight: "10px",
                              fontSize: "14px",
                              width: "25%",
                            }}
                          >
                            <p>{flight?.from}</p>
                            <p>
                              {dayjs(flight?.departureTime).format("h:mm a")}
                            </p>
                            <p>
                              {dayjs(flight?.departureDate).format("DD-MM-YY")}
                            </p>
                          </div>

                          {/* Plane Icon and Time */}
                          <div
                            style={{
                              position: "relative",
                              width: "50%",
                              height: "1px",
                              borderTop: "2px dotted #0e54a3",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <BiSolidPlaneAlt
                              style={{
                                position: "absolute",
                                top: "-33px",
                                fontSize: "24px",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "15px", // Position below the dotted line
                                fontSize: "14px",
                                color: "#0e54a3",
                              }}
                            >
                              {flight?.flightTime}
                            </div>
                          </div>

                          {/* Right City */}
                          <div
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              width: "25%",
                            }}
                          >
                            <p> {flight?.to}</p>
                            <p>{dayjs(flight?.arrivalTime).format("h:mm a")}</p>
                            {dayjs(flight?.arrivalDate).format("DD-MM-YY")}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div
                            style={{
                              margin: "10px 0",
                            }}
                          >
                            <p
                              className={classNames(
                                commonstyle.fs16,
                                commonstyle.colorBlue,
                                commonstyle.semiBold
                              )}
                            >
                              Amenities
                            </p>
                          </div>
                          <div>
                            {flight?.amenities?.map((amenity: any, i: any) => (
                              <p
                                key={i}
                                className={classNames(
                                  commonstyle.fs14,
                                  commonstyle.colorBlue
                                )}
                              >
                                {amenity}
                              </p>
                            ))}
                            <p
                              className={classNames(
                                commonstyle.fs14,
                                commonstyle.colorGray
                              )}
                            >
                              No. of Handbag:{" "}
                              <span
                                className={classNames(
                                  commonstyle.fs14,
                                  commonstyle.colorBlue
                                )}
                              >
                                {flight?.noOfHandbag}
                              </span>
                            </p>
                            <p
                              className={classNames(
                                commonstyle.fs14,
                                commonstyle.colorGray
                              )}
                            >
                              Baggage Weight:{" "}
                              <span
                                className={classNames(
                                  commonstyle.fs14,
                                  commonstyle.colorBlue
                                )}
                              >
                                {flight?.baggageWeight}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}{" "}
                </>
              )}
            </>
          </div>
          {state?.type === "booking" ? (
            <div className={classNames(commonstyle.col4, commonstyle.colsm12)}>
              <div className={classNames(style.ticketCard)}>
                <div
                  style={{
                    borderBottom: "2px dashed #2b6cb0",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.semiBold,
                      commonstyle.fs24
                    )}
                  >
                    Policies
                  </p>

                  <p>
                    <span
                      className={classNames(
                        commonstyle.colorGray,
                        commonstyle.fs12
                      )}
                    >
                      Cancellation Duration
                      {/* {state?.data?.bidRequestId?.cancelationDeduction} */}
                    </span>
                    <br />{" "}
                    <span
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16
                      )}
                    >
                      {/* Before flight 4hr */}

                      {
                        state?.data?.bidRequestId?.flightPolicies
                          ?.cancelationDuration
                      }
                    </span>
                  </p>

                  <p>
                    <span
                      className={classNames(
                        commonstyle.colorGray,
                        commonstyle.fs12
                      )}
                    >
                      Cancellation Deduction
                    </span>
                    <br />{" "}
                    <span
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16
                      )}
                    >
                      {/* 20% */}

                      {
                        state?.data?.bidRequestId?.flightPolicies
                          ?.cancelationDeduction
                      }
                    </span>
                  </p>
                </div>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    display: "flex",
                    // justifyItems: "center",
                    alignItems: "center",
                    color: "#2b6cb0",
                    // marginTop: "16px",
                  }}
                >
                  E-Ticket File
                </span>
                {state?.data?.eTicket ? (
                  <div
                    style={{
                      width: "80%",
                      padding: "5px 16px",
                      borderWidth: "1px",
                      borderStyle: "dashed",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgba(227, 235, 237, 1)",
                      borderRadius: "8px",
                      // marginTop: "8px",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        width: "60%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {fileName}
                    </span>

                    <button
                      onClick={() => downloadAndSaveImage(state?.data?.eTicket)}
                      style={{
                        border: "none",
                        // background: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={LabDownload}
                        // src={state?.data?.bidRequestId?.eTicket}
                        alt="Download"
                        style={{
                          height: "20px",
                          width: "20px",
                          objectFit: "contain",
                          // filter: `brightness(0) saturate(100%) invert(28%) sepia(89%) saturate(361%) hue-rotate(119deg) brightness(93%) contrast(86%)`,
                        }}
                      />
                    </button>
                  </div>
                ) : (
                  <span>No E-ticket found</span>
                )}
              </div>
            </div>
          ) : (
            <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.semiBold,
                  commonstyle.fs24
                )}
              >
                Policies
              </p>
              <div className={classNames(style.flightcontainer)}>
                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween,
                    commonstyle.flxWrap
                  )}
                >
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      commonstyle.colorBlue,
                      commonstyle.semiBold
                    )}
                  >
                    Cancellation Deduction
                  </p>
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      commonstyle.colorBlue
                    )}
                  >
                    {state?.data?.flightPolicies?.cancelationDeduction}
                  </p>
                </div>
                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween,
                    commonstyle.flxWrap
                  )}
                >
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      commonstyle.colorBlue,
                      commonstyle.semiBold
                    )}
                  >
                    Cancellation Duration
                  </p>
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      commonstyle.colorBlue
                    )}
                  >
                    {state?.data?.flightPolicies?.cancelationDuration}
                  </p>
                </div>

                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween,
                    commonstyle.flxWrap,
                    commonstyle.mt16
                  )}
                >
                  <p
                    className={classNames(
                      commonstyle.fs16,
                      commonstyle.colorBlue,
                      commonstyle.semiBold
                    )}
                  >
                    Total Price for Traveler
                  </p>
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      commonstyle.colorBlue
                    )}
                  >
                    {state?.data?.ticketPrice}
                  </p>
                </div>
              </div>
              <div className={classNames(style.btncontainer)}>
                <button
                  className={classNames(style.firstbtn)}
                  onClick={() => openModal(state?.data?._id)}
                >
                  Decline
                </button>
                <button
                  className={classNames(style.secoundbtn)}
                  onClick={handleAccept}
                >
                  Accept
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        modalVisible={showModal}
        handleCancel={onCanelModal}
        handleDelete={declineRequest}
        loading={loading}
      />
      <div style={{ marginTop: "60px" }}></div>
      <Footerr />
    </div>
  );
};

export default TravelDetail;
