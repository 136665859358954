export const formData_Values = (user: any, dayjs: any) => ({
  name: user?.name || "",
  about: user?.about || "",
  cnicOrPassportNo: user?.cnicOrPassportNo || "",
  qualifications: user?.qualifications || "",
  location: {
    lng: user?.location?.lng || "",
    lat: user?.location?.lat || "",
    address: user?.location?.address || "",
    city: user?.location?.city || "",
  },
  speciality: user?.speciality || [],
  pmdcNumber: user?.pmdcNumber || "",
  pmdcImage: user?.pmdcImage || "",
  pmdcExpiry: (user?.pmdcExpiry && dayjs(user.pmdcExpiry)) || "",
  cnicOrPassportExpiry:
    (user?.cnicOrPassportExpiry && dayjs(user.cnicOrPassportExpiry)) || "",
  ntn: user?.incomeTaxNo || "",
  profilePercentage: user?.profilePercentage || "",
  // salesTaxNo: user?.salesTaxNo || "",
  taxFileImage: user?.taxFileImage || "",
  cnicImage: user?.cnicImage || "",
  doctorImage: user?.doctorImage || "",
  accountTitle: user?.accountHolderName || "",
  accountNumber: user?.accountNumber || "",
  bankName: user?.bankName || "",
  youtube: user?.linkedIn || "",
  linkedIn: user?.linkedIn || "",
  instagram: user?.instagram || "",
  facebook: user?.facebook || "",
  password: user?.password || "",
  //   currentPassword: "",
  //   newPassword: "",
  //   confirmPassword: "",
});

export const editableSections_Values = {
  ProfileInfo: false,
  Qualifications: false,
  Specialties: false,
  Certifications: false,
  BankDetails: false,
  SocialMedia: false,
  Security: false,
  AboutUS: false,
};

export const allowedValues = [
  "name",
  "cnicOrPassportNo",
  "location",
  "qualifications",
  "speciality",
  "pmdcNumber",
  "pmdcImage",
  "lng",
  "lat",
  "address",
  "city",
  "pmdcExpiry",
  "incomeTaxNo",
  "salesTaxNo",
  "taxFileImage",
  "accountHolderName",
  "accountNumber",
  "website",
  "bankName",
  "instagram",
  "twitter",
  "facebook",
  "password",
  "cnicImage",
  "cnicOrPassportExpiry",
  "doctorImage",
  "about",
  //   "currentPassword",
  //   "newPassword",
];

export const specialtiesOptions = [
  "Cardiology",
  "Dermatology",
  "Neurology",
  "Orthopedics",
  "Pediatrics",
  "Radiology",
];
