import React, { useEffect, useState } from "react";
import styles from "./order.module.css";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PharmacyGetOrderDetail, getOrderDetails } from "shared/services";
import { Order_Details } from "shared/components";

export default function PharOrderDetail() {
  const [orderdetail, setOrderdetail] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleGoToBack = () => {
    navigate("/pharmacy/order");
  };
  const orderDetail = () => {
    setLoading(true);
    if (id === undefined) {
      setLoading(false);
      return;
    }
    if (id) {
      PharmacyGetOrderDetail(id)
        .then((res: any) => {
          setOrderdetail(res.data.order);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    orderDetail();
  }, []);
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <Order_Details
        data={orderdetail}
        handleGoToBack={handleGoToBack}
        title={"Order"}
      />
    </div>
  );
}
