import { countryData } from "shared/utils";
import pakistan from "assets/images/Country/Pakistan.webp";
import canada from "assets/images/Country/Canada.webp";
import cambodia from "assets/images/Country/Cambodia.webp";
import usa from "assets/images/Country/USA.webp";
import england from "assets/images/Country/England.webp";
import mexico from "assets/images/Country/Mexico.webp";
import spain from "assets/images/Country/Spain.webp";
import turkey from "assets/images/Country/Turkey.webp";
import singapore from "assets/images/Country/Singapore.webp";
import switzerland from "assets/images/Country/Switzerland.webp";
import costaRica from "assets/images/Country/Costa Rica.webp";
import uae from "assets/images/Country/UAE.webp";
import malaysia from "assets/images/Country/Malaysia.webp";
import southKorea from "assets/images/Country/South Korea.webp";
import germany from "assets/images/Country/Germany.webp";
import thailand from "assets/images/Country/Thailand.webp";
import brazil from "assets/images/Country/Brazil.webp";
import SIX from "assets/images/Pakistan/Pakistan7.jpg";
import c1 from "assets/images/TourismImages/icons/c1.png";
import c2 from "assets/images/TourismImages/icons/c2.png";
import c3 from "assets/images/TourismImages/icons/c3.png";
import c4 from "assets/images/TourismImages/icons/c4.png";

import b1 from "assets/images/TourismImages/icons/b1.png";
import b2 from "assets/images/TourismImages/icons/b2.png";

//.................CANADA.................................../
import canada1 from "assets/images/TourismImages/Canada/Canada1.jpg";
import canada2 from "assets/images/TourismImages/Canada/Canada2.jpg";
import canada3 from "assets/images/TourismImages/Canada/Canada3.jpg";
import canada4 from "assets/images/TourismImages/Canada/Canada4.jpg";
import canada5 from "assets/images/TourismImages/Canada/Canada5.jpg";
import canada6 from "assets/images/TourismImages/Canada/Canada6.jpg";
import canada7 from "assets/images/TourismImages/Canada/Canada7.jpg";

//.................BRAZIL.................................../
import image1 from "assets/images/TourismImages/Brazil/Brazil1.jpg";
import image2 from "assets/images/TourismImages/Brazil/Brazil2.jpg";
import image3 from "assets/images/TourismImages/Brazil/Brazil3.jpg";
import image4 from "assets/images/TourismImages/Brazil/Brazil4.jpg";
import image5 from "assets/images/TourismImages/Brazil/Brazil5.jpg";
import image6 from "assets/images/TourismImages/Brazil/Brazil6.jpg";
import image7 from "assets/images/TourismImages/Brazil/Brazil7.jpg";

//.................CAMBODIA.................................../
import cambodia1 from "assets/images/TourismImages/cambodia/Cambodia1.jpg";
import cambodia2 from "assets/images/TourismImages/cambodia/Cambodia2.jpg";
import cambodia3 from "assets/images/TourismImages/cambodia/Cambodia3.jpg";
import cambodia4 from "assets/images/TourismImages/cambodia/Cambodia4.jpg";
import cambodia5 from "assets/images/TourismImages/cambodia/Cambodia5.jpg";
import cambodia6 from "assets/images/TourismImages/cambodia/Cambodia6.jpg";
import cambodia7 from "assets/images/TourismImages/cambodia/Cambodia7.jpg";

//.................COSTA RICA.................................../
import costaRica1 from "assets/images/TourismImages/costaRica/Costa Rica1.jpg";
import costaRica2 from "assets/images/TourismImages/costaRica/Costa Rica2.jpg";
import costaRica3 from "assets/images/TourismImages/costaRica/Costa Rica3.jpg";
import costaRica4 from "assets/images/TourismImages/costaRica/Costa Rica4.jpg";
import costaRica5 from "assets/images/TourismImages/costaRica/Costa Rica5.jpg";
import costaRica6 from "assets/images/TourismImages/costaRica/Costa Rica6.jpg";
import costaRica7 from "assets/images/TourismImages/costaRica/Costa Rica7.jpg";

//.................ENGLAND.................................../
import england1 from "assets/images/TourismImages/england/England1.jpg";
import england2 from "assets/images/TourismImages/england/England2.jpg";
import england3 from "assets/images/TourismImages/england/England3.jpg";
import england4 from "assets/images/TourismImages/england/England4.jpg";
import england5 from "assets/images/TourismImages/england/England5.jpg";
import england6 from "assets/images/TourismImages/england/England6.jpg";
import england7 from "assets/images/TourismImages/england/England7.jpg";

//.................GERMANY.................................../
import germany1 from "assets/images/TourismImages/germany/Germany1.jpg";
import germany2 from "assets/images/TourismImages/germany/Germany2.jpg";
import germany3 from "assets/images/TourismImages/germany/Germany3.jpg";
import germany4 from "assets/images/TourismImages/germany/Germany4.jpg";
import germany5 from "assets/images/TourismImages/germany/Germany5.jpg";
import germany6 from "assets/images/TourismImages/germany/Germany6.jpg";
import germany7 from "assets/images/TourismImages/germany/Germany7.jpg";

//.................MALAYSIA.................................../
import malaysia1 from "assets/images/TourismImages/malaysia/Malaysia01.jpg";
import malaysia2 from "assets/images/TourismImages/malaysia/Malaysia02.jpg";
import malaysia3 from "assets/images/TourismImages/malaysia/Malaysia03.jpg";
import malaysia4 from "assets/images/TourismImages/malaysia/Malaysia04.jpg";
import malaysia5 from "assets/images/TourismImages/malaysia/Malaysia05.jpg";
import malaysia6 from "assets/images/TourismImages/malaysia/Malaysia06.jpg";
import malaysia7 from "assets/images/TourismImages/malaysia/Malaysia07.jpg";

//.................MEXICO.................................../
import mexico1 from "assets/images/TourismImages/mexico/Mexico01.jpg";
import mexico2 from "assets/images/TourismImages/mexico/Mexico02.jpg";
import mexico3 from "assets/images/TourismImages/mexico/Mexico03.jpg";
import mexico4 from "assets/images/TourismImages/mexico/Mexico04.jpg";
import mexico5 from "assets/images/TourismImages/mexico/Mexico05.jpg";
import mexico6 from "assets/images/TourismImages/mexico/Mexico06.jpg";
import mexico7 from "assets/images/TourismImages/mexico/Mexico07.jpg";

//.................Swizland.................................../
import switzerland1 from "assets/images/TourismImages/switzerland/Switzerland01.jpg";
import switzerland2 from "assets/images/TourismImages/switzerland/Switzerland02.jpg";
import switzerland3 from "assets/images/TourismImages/switzerland/Switzerland03.jpg";
import switzerland4 from "assets/images/TourismImages/switzerland/Switzerland04.jpg";
import switzerland5 from "assets/images/TourismImages/switzerland/Switzerland05.jpg";
import switzerland6 from "assets/images/TourismImages/switzerland/Switzerland06.jpg";
import switzerland7 from "assets/images/TourismImages/switzerland/Switzerland07.jpg";

//.................SPAIN.................................../
import spain1 from "assets/images/TourismImages/spain/Spain01.jpg";
import spain2 from "assets/images/TourismImages/spain/Spain02.jpg";
import spain3 from "assets/images/TourismImages/spain/Spain03.jpg";
import spain4 from "assets/images/TourismImages/spain/Spain04.jpg";
import spain5 from "assets/images/TourismImages/spain/Spain05.jpg";
import spain6 from "assets/images/TourismImages/spain/Spain06.jpg";
import spain7 from "assets/images/TourismImages/spain/Spain07.jpg";

//.................SOUTH KOREA.................................../
import southKorea1 from "assets/images/TourismImages/southKorea/Korea01.jpg";
import southKorea2 from "assets/images/TourismImages/southKorea/Korea02.jpg";
import southKorea3 from "assets/images/TourismImages/southKorea/Korea03.jpg";
import southKorea4 from "assets/images/TourismImages/southKorea/Korea04.jpg";
import southKorea5 from "assets/images/TourismImages/southKorea/Korea05.jpg";
import southKorea6 from "assets/images/TourismImages/southKorea/Korea06.jpg";
import southKorea7 from "assets/images/TourismImages/southKorea/Korea07.jpg";

//.................SINGAPORE.................................../
import singapore1 from "assets/images/TourismImages/singapore/singapore1.png";
import singapore2 from "assets/images/TourismImages/singapore/singapore2.png";
import singapore3 from "assets/images/TourismImages/singapore/singapore3.png";
import singapore4 from "assets/images/TourismImages/singapore/singapore4.png";
import singapore5 from "assets/images/TourismImages/singapore/singapore5.png";
import singapore6 from "assets/images/TourismImages/singapore/singapore6.png";
import singapore7 from "assets/images/TourismImages/singapore/singapore7.png";

//.................TURKEY.................................../
import turkey1 from "assets/images/TourismImages/turkey/Turkey01.jpg";
import turkey2 from "assets/images/TourismImages/turkey/Turkey02.jpg";
import turkey3 from "assets/images/TourismImages/turkey/Turkey03.jpg";
import turkey4 from "assets/images/TourismImages/turkey/Turkey04.jpg";
import turkey5 from "assets/images/TourismImages/turkey/Turkey05.jpg";
import turkey6 from "assets/images/TourismImages/turkey/Turkey06.jpg";
import turkey7 from "assets/images/TourismImages/turkey/Turkey07.jpg";

//.................THAILAND.................................../
import thailand1 from "assets/images/TourismImages/thailand/thailand1.png";
import thailand2 from "assets/images/TourismImages/thailand/thailand2.png";
import thailand3 from "assets/images/TourismImages/thailand/thailand3.png";
import thailand4 from "assets/images/TourismImages/thailand/thailand4.png";
import thailand5 from "assets/images/TourismImages/thailand/thailand5.png";
import thailand6 from "assets/images/TourismImages/thailand/thailand6.png";
import thailand7 from "assets/images/TourismImages/thailand/thailand7.png";

//.................USA.................................../
import usa1 from "assets/images/TourismImages/usa/USA01.jpg";
import usa2 from "assets/images/TourismImages/usa/USA02.jpg";
import usa3 from "assets/images/TourismImages/usa/USA03.jpg";
import usa4 from "assets/images/TourismImages/usa/USA04.jpg";
import usa5 from "assets/images/TourismImages/usa/USA05.jpg";
import usa6 from "assets/images/TourismImages/usa/USA06.jpg";
import usa7 from "assets/images/TourismImages/usa/USA07.jpg";




//.................UAE.................................../
import uae1 from "assets/images/TourismImages/uae/UAE01.jpg";
import uae2 from "assets/images/TourismImages/uae/UAE02.jpg";
import uae3 from "assets/images/TourismImages/uae/UAE03.jpg";
import uae4 from "assets/images/TourismImages/uae/UAE04.jpg";
import uae5 from "assets/images/TourismImages/uae/UAE05.jpg";
import uae6 from "assets/images/TourismImages/uae/UAE06.jpg";
import uae7 from "assets/images/TourismImages/uae/UAE07.jpg";

import cultural from "assets/images/Pakistan/Pakistan2.jpg";
import COASTAL from "assets/images/Pakistan/Pakistan3.jpg";
import FESTIVAL from "assets/images/Pakistan/Pakistan4.jpg";
import WILDLIFE from "assets/images/Pakistan/Pakistan5.jpg";
import FIVE from "assets/images/Pakistan/Pakistan6.jpg";

export const data = [
  //.................PAKISTAN.................................../

  {
    title: "PAKISTAN",
    img: pakistan,

    details: {
      h1: "World-Class Healthcare Meets Nature’s Serenity",
      h2: "Pakistan offers a unique blend of advanced medical treatments and breathtaking landscapes, making it an ideal destination for health tourism. From the high-tech hospitals in Islamabad, Lahore, and Karachi to recovery retreats in the serene valleys of Hunza, Swat, and Skardu, Pakistan provides a cost-effective yet high-quality medical tourism experience.",
      topCards: [
        {
          id: 1,
          title: "Affordable yet Advanced Medical Care -",
          img: c1,
          subtitle:
            "State-of-the-art hospitals offering cardiac, orthopedic, STEM CELL treatments, infertility treatments, cosmetic, and dental procedures at competitive prices.",
        },
        {
          id: 2,
          title: "Post-Treatment Recovery in Natural Retreats -",
          img: c2,
          subtitle:
            "Heal in the fresh mountain air of Gilgit-Baltistan, Neelum Valley, and Fairy Meadows.",
        },
        {
          id: 3,
          title: "Traditional Healing & Wellness Therapies –",
          img: c3,
          subtitle:
            "Experience Himalayan salt therapy, herbal treatments, and Sufi healing practices.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: cultural,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: COASTAL,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: FESTIVAL,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: WILDLIFE,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: FIVE,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: SIX,
        },
      ],
    },
  },
  //.................CAMBODIA.................................../

  {
    title: "CAMBODIA",
    img: cambodia,
    details: {
      h1: "WELLNESS IN THE HEART OF ANCIENT WONDERS",
      h2: "Cambodia is emerging as a cost-effective yet high-quality medical tourism destination, offering advanced healthcare at competitive prices. With top hospitals in Phnom Penh and Siem Reap, paired with post-recovery sanctuaries near Angkor Wat, tropical islands, and Mekong River retreats, Cambodia is a hidden gem for healing and relaxation.",
      Eco: cambodia1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: cambodia2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: cambodia3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: cambodia4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: cambodia5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: cambodia6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: cambodia7,
        },
      ],
    },
  },

  //.................CANADA.................................../

  {
    title: "CANADA",
    img: canada,
    details: {
      h1: "Top-Tier Healthcare in a Vibrant, Lush Paradise",
      h2: "Canada is emerging as a premier destination for medical health tourism, offering world-class healthcare facilities, highly skilled medical professionals, and cutting-edge treatments. Combined with its breathtaking natural beauty and multicultural cities, Canada provides a holistic healing experience for international patients.",
      Eco: canada1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: b2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: canada2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: canada3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: canada4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: canada5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: canada6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: canada7,
        },
      ],
    },
  },

  //.................USA.................................../

  {
    title: "USA",
    img: usa,
    details: {
      h1: "Specialized Care, Innovation Hub",
      h2: "The USA attracts medical health tourists seeking highly specialized procedures, cutting-edge treatments, and access to world-renowned medical institutions. Patients can benefit from innovative therapies and advanced technologies, combined with diverse urban and natural settings for recovery and wellness.",
      Eco: usa1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: usa2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: usa3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: usa4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: usa5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: usa6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: usa7,
        },
      ],
    },
  },

  //.................MEXICO.................................../

  {
    title: "MEXICO",
    img: mexico,
    details: {
      h1: "Accessible Care, Vibrant Culture",
      h2: "Mexico is a popular choice for medical health tourism, offering accessible and affordable healthcare alongside vibrant culture and stunning destinations. Patients can receive a range of treatments, from dental work to cosmetic surgery, while experiencing the warmth and hospitality of Mexico.",
      Eco: mexico1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: mexico2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: mexico3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: mexico4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: mexico5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: mexico6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: mexico7,
        },
      ],
    },
  },

  //.................ENGLAND.................................../

  {
    title: "ENGLAND",
    img: england,
    details: {
      h1: "Exceptional Healthcare, Historic Charm",
      h2: "England attracts medical health tourists with its renowned medical expertise, cutting-edge technology, and historic cities. Patients can access specialized treatments and consultations while exploring iconic landmarks and experiencing the country's rich cultural heritage.",
      Eco: england1,
      topCards: [
        {
          id: 1,
          title: "Affordable yet Advanced Medical Care -",
          img: c1,
          subtitle:
            "State-of-the-art hospitals offering cardiac, orthopedic, STEM CELL treatments, infertility treatments, cosmetic, and dental procedures at competitive prices.",
        },
        {
          id: 2,
          title: "Post-Treatment Recovery in Natural Retreats -",
          img: c2,
          subtitle:
            "Heal in the fresh mountain air of Gilgit-Baltistan, Neelum Valley, and Fairy Meadows.",
        },
        {
          id: 3,
          title: "Traditional Healing & Wellness Therapies –",
          img: c3,
          subtitle:
            "Experience Himalayan salt therapy, herbal treatments, and Sufi healing practices.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: england2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: england3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: england4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: england5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: england6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: england7,
        },
      ],
    },
  },

  //.................SPAIN.................................../

  {
    title: "SPAIN",
    img: spain,
    details: {
      h1: "Expert Care, Mediterranean Charm",
      h2: "Spain is increasingly recognized as a destination for medical health tourism, offering a blend of advanced medical expertise, cost-effective treatments, and beautiful coastal regions. Patients can access a range of procedures and therapies while enjoying Spain's vibrant culture, delicious cuisine, and sunny Mediterranean climate.",
      Eco: spain1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: spain2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: spain3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: spain4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: spain5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: spain6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: spain7,
        },
      ],
    },
  },

  //.................TURKEY.................................../

  {
    title: "TURKEY",
    img: turkey,
    details: {
      h1: "Premium Healthcare in a Land of Timeless Beauty",
      h2: "Turkey is one of the top global destinations for medical health tourism, combining cutting-edge healthcare with stunning landscapes. From the advanced medical hubs of Istanbul, Ankara, and Izmir to the tranquil recovery spots in Cappadocia, Pamukkale, and the Aegean coast, Turkey offers a perfect healing environment.",
      Eco: turkey1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: c4,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: turkey2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: turkey3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: turkey4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: turkey5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: turkey6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: turkey7,
        },
      ],
    },
  },

  //.................SWITXERLAND.................................../

  {
    title: "SWITZERLAND",
    img: switzerland,
    details: {
      h1: "Precision Medicine, Alpine Luxury",
      h2: "Switzerland is renowned for its precision medicine, advanced rehabilitation facilities, and luxurious wellness retreats, attracting medical health tourists seeking the highest standards of care. Patients can benefit from cutting-edge treatments amidst stunning alpine scenery and a culture of discreet, personalized service.",
      Eco: switzerland1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: switzerland2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: switzerland3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: switzerland4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: switzerland5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: switzerland6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: switzerland7,
        },
      ],
    },
  },

  //.................COSTA RICA.................................../

  {
    title: "COSTA RICA",
    img: costaRica,
    details: {
      h1: "Combining World-Class Healthcare with Tropical Paradise",
      h2: "Costa Rica offers a compelling blend of high-quality medical care and breathtaking natural beauty, making it a premier destination for medical health tourism. Patients can recover and rejuvenate amidst lush rainforests and pristine beaches, combining their treatment with an unforgettable vacation experience.",
      Eco: costaRica1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: costaRica2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: costaRica3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: costaRica4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: costaRica5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: costaRica6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: costaRica7,
        },
      ],
    },
  },

  //.................UAE.................................../

  {
    title: "UAE",
    img: uae,
    details: {
      h1: "Luxury and Modern Medicine",
      h2: "The UAE is a leading hub for medical health tourism, renowned for its state-of-the-art hospitals, luxury wellness retreats, and highly specialized treatments. With its world-class infrastructure, multicultural environment, and seamless travel connectivity, the UAE offers a premium healthcare experience for international patients.",
      Eco: uae1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: uae2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: uae3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: uae4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: uae5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: uae6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: uae7,
        },
      ],
    },
  },

  //.................MALAYSIA.................................../

  {
    title: "MALAYSIA",
    img: malaysia,
    details: {
      h1: "Affordable Healthcare, Cultural Richness",
      h2: "Malaysia has emerged as a leading destination for medical health tourism, offering a combination of affordable, high-quality healthcare and diverse cultural experiences. Patients can access a wide range of medical specialties while enjoying the country's vibrant cities, pristine beaches, and lush rainforests.",
      Eco: malaysia1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: malaysia2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: malaysia3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: malaysia4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: malaysia5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: malaysia6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: malaysia7,
        },
      ],
    },
  },

  //.................SOUTH KOREA.................................../

  {
    title: "SOUTH KOREA",
    img: southKorea,
    details: {
      h1: "Innovation and Holistic Healing",
      h2: "South Korea has become a global hub for medical health tourism, known for its advanced technologies in cosmetic surgery, cancer treatment, and general health screenings. Patients benefit from innovative medical practices and a blend of traditional and modern healing techniques, all within a dynamic and culturally rich environment.",
      Eco: southKorea1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: southKorea2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: southKorea3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: southKorea4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: southKorea5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: southKorea6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: southKorea7,
        },
      ],
    },
  },
  //.................SINGAPORE.................................../

  {
    title: "SINGAPORE",
    img: singapore,
    details: {
      h1: "Quality and Efficiency in a Global City",
      h2: "Singapore is a leading medical health tourism destination, known for its advanced medical infrastructure, highly skilled specialists, and stringent quality standards. Patients can access a wide range of medical services in a safe, modern, and efficient environment, complemented by the city's vibrant culture and exceptional hospitality.",
      Eco: singapore1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: singapore2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: singapore3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: singapore4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: singapore5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: singapore6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: singapore7,
        },
      ],
    },
  },

  //.................GERMANY.................................../

  {
    title: "GERMANY",
    img: germany,
    details: {
      h1: "Advanced Medicine, Cultural Heritage",
      h2: "Germany attracts medical health tourists with its reputation for advanced medical technology, specialized treatments, and rigorous quality standards. Patients can benefit from world-class healthcare while experiencing the country's rich history, vibrant cities, and picturesque landscapes.",
      Eco: germany1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: c2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: germany2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: germany3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: germany4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: germany5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: germany6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: germany7,
        },
      ],
    },
  },

  //.................THAILAND.................................../

  {
    title: "THAILAND",
    img: thailand,
    details: {
      h1: "Affordable Care, Tropical ParadiseExpert Care, Mediterranean Charm",
      h2: "Thailand is a well-established destination for medical health tourism, offering a combination of affordable healthcare, skilled medical professionals, and stunning tropical scenery. Patients can access a wide range of medical and wellness treatments, while enjoying Thailand's rich culture, delicious cuisine, and world-renowned hospitality.",
      Eco: thailand1,
      topCards: [
        {
          id: 1,
          title: "Affordable & Quality Medical Services –",
          img: c1,
          subtitle:
            "Advanced treatments in dentistry, cosmetic surgery, and wellness therapies at a fraction of Western costs.",
        },
        {
          id: 2,
          title: "Serene Recovery in Cultural & Natural Wonders–",
          img: b2,
          subtitle:
            "Recuperate in the peaceful countryside, wellness resorts, and secluded tropical islands.",
        },
        {
          id: 3,
          title: "Traditional Healing & Spiritual Wellness –",
          img: c3,
          subtitle:
            "Experience Khmer herbal medicine, Buddhist meditation retreats, and natural spa therapies.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: thailand2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: thailand3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: thailand4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: thailand5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: thailand6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: thailand7,
        },
      ],
    },
  },

  //.................BRAZIL.................................../

  {
    title: "BRAZIL",
    img: brazil,
    details: {
      h1: "Top-Tier Healthcare in a Vibrant, Lush Paradise",
      h2: "Brazil is a leader in cosmetic, dental, and orthopedic surgery, offering world-class healthcare at affordable rates. With its modern hospitals in São Paulo and Rio de Janeiro and post-surgery recovery retreats in the Amazon, Iguazu Falls, and Bahia’s pristine beaches, Brazil offers a truly unique medical tourism experience.",
      Eco: image1,
      topCards: [
        {
          id: 1,
          title: "Leading in Aesthetic & Reconstructive Surgery -",
          img: b1,
          subtitle: "Home to top plastic surgeons and wellness centers.",
        },
        {
          id: 2,
          title: "Relax & Heal in Stunning Natural Surroundings –",
          img: b2,
          subtitle: "Recover in rainforests, beaches, and waterfall retreats.",
        },
        {
          id: 3,
          title: "Holistic Healing & Alternative Therapies –",
          img: c3,
          subtitle:
            "Experience Amazonian herbal treatments, indigenous healing traditions, and thalassotherapy.",
        },
      ],
      culturaldata: [
        {
          id: 1,
          Heading: "CULTURAL & HERITAGE TOURISM",
          h1: "Discover the Healing Power of Culture with Meditour Global.",
          paragraph:
            "At Meditour Global, we believe that wellness is not just about medical treatments—it’s also about enriching experiences that nourish the soul. Our Cultural & Heritage Tourism packages offer a unique opportunity to explore the rich traditions, history, and artistry of different civilizations while ensuring a comfortable and well-planned travel experience.Whether you’re seeking spiritual rejuvenation, historical exploration, or cultural immersion, our curated tours provide a seamless blend of health, history, and hospitality.",
          img1: image2,
          Heading2: "COASTAL & MARINE TOURISM",
          h2: "Rejuvenate by the Sea with Meditour Global.",
          paragraph2:
            "The rhythmic sound of waves, the salty breeze, and the endless horizon—there’s something inherently healing about the ocean. At Meditour Global, we bring you Coastal & Marine Tourism experiences that combine the therapeutic power of the sea with luxurious wellness retreats, eco-friendly adventures, and world-class medical facilities.Whether you're recovering from a medical treatment, seeking relaxation, or craving a refreshing escape, our coastal and marine tourism packages are designed to restore your mind, body, and soul in some of the world’s most stunning seaside destinations.",
          img2: image3,
        },
        {
          id: 2,
          Heading: "FESTIVAL & EVENT TOURISM",
          h1: "Celebrate Culture, Tradition, and Wellness with Meditour Global.",
          paragraph:
            "Festivals are more than just celebrations—they are vibrant expressions of culture, unity, and joy. At Meditour Global, we offer exclusive Festival & Event Tourism packages that allow you to experience the world’s most spectacular festivals and events while ensuring a seamless, comfortable, and enriching journey.Whether you want to witness grand cultural festivities, music and arts celebrations, spiritual gatherings, or international sporting events, our tailored packages provide the perfect blend of entertainment, tradition, and wellness.",
          img1: image4,
          Heading2: "WILDLIFE TOURISM",
          h2: "Experience the Wonders of Nature with MeditourGlobal.",
          paragraph2:
            "Step into the wild and immerse yourself in the breathtaking beauty of nature with Meditour Global’s Wildlife Tourism packages. Whether you are a nature enthusiast, an adventure seeker, or simply looking for a serene escape, our curated wildlife experiences connect you with the planet’s most incredible species in their natural habitats.From the snow leopards of Pakistan’s mountains to the Big Five of Africa and the exotic rainforests of South America, our wildlife tours offer unforgettable encounters with the natural world while ensuring responsible and sustainable tourism.",
          img2: image5,
        },
        {
          id: 3,
          Heading: "MOUNTAINEERING",
          h1: "Conquer New Heights with Meditour Global.",
          paragraph:
            "For those who seek adventure, challenge, and breathtaking natural beauty, Mountaineering Tourism with Meditour Global offers an unforgettable experience. Whether you are a seasoned climber or an enthusiast looking to embark on your first high-altitude journey, our expertly curated expeditions provide the perfect blend of adventure, safety, and exploration in the world’s most awe-inspiring mountain ranges.From the majestic peaks of the Karakoram and Himalayas to the iconic summits of Europe and South America, Meditour Global brings you world-class mountaineering experiences with professional guidance, top-tier equipment, and seamless logistics.",
          img1: image6,
          h2: "A Journey of Faith, Healing, and Spiritual Renewal.",
          Heading2: "RELIGIOUS TOURISM",
          paragraph2:
            "At Meditour Global, we believe that true healing goes beyond the physical—it also nurtures the soul. Our Religious Tourism packages offer pilgrims, spiritual seekers, and history enthusiasts the opportunity to visit some of the world's most sacred and revered sites. Whether you seek spiritual enlightenment, religious fulfillment, or a deeper connection with history, our customized tours ensure a safe, comfortable, and enriching experience.",
          img2: image7,
        },
      ],
    },
  },
];
