import React, { useEffect, useState } from "react";
import style from "./dasheboard.module.css";
import plan from "assets/images/plan.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getAgencyBooking, getHotelData } from "shared/services";
import { FaChevronDown } from "react-icons/fa6";
import TableNew from "shared/components/A_New_Components/Table_new";
import dayjs from "dayjs";
import { RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import NewPagination2 from "shared/components/A_New_Components/NewPagination2";
import { useNavigate } from "react-router-dom";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { BsBuildings } from "react-icons/bs";
export default function GreenFlagDasheBoard() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [agencyName, setAgencyName] = useState<any>([]);
  const [selectedAgency, setSelectedAgency] = useState<any>("");
  const [hotelName, setHotelName] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState<any>("");
  const [travelLoading, setTravelLoading] = useState(false);
  const [hoteldata, sethoteldata] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [agencyCount, setAgencyCount] = useState("");
  const [hotelCount, setHotelCount] = useState("");
  const totalItems = length;
  const navigate = useNavigate();
  const [agencyLength, setAgencyLength] = useState<any>(0);
  const totalAgency = agencyLength;
  useEffect(() => {
    fetchAgencyBooking(1);
  }, [selectedAgency]);

  useEffect(() => {
    fetchHotel(1);
  }, [selectedHotel]);
  const fetchAgencyBooking = (pageno: number) => {
    setTravelLoading(true);
    getAgencyBooking(pageno, selectedAgency?._id ? selectedAgency?._id : "")
      .then((res: any) => {
        console.log(res?.data, "...........agencyside");
        setAgencyName(res?.data?.agencyBooking?.agencyNames);
        handleTableData(res?.data?.agencyBooking?.data);
        setAgencyCount(res?.data?.agencyCount);
        setAgencyLength(res?.data?.agencyBooking?.total);
      })
      .catch((err: any) => {
        console.log(err, "error from api ");
      })
      .finally(() => {
        setTravelLoading(false);
      });
  };
  const fetchHotel = (pageno: number) => {
    setLoading(true);
    getHotelData(pageno, selectedHotel?._id ? selectedHotel?._id : "")
      .then((res: any) => {
        setHotelCount(res?.data?.hotelCount);
        setLength(res?.data?.hotelBooking?.total);
        setHotelName(res?.data?.hotelBooking?.hotelNames);
        handleTableHotel(res?.data?.hotelBooking?.data);
      })
      .catch((err: any) => {
        console.log(err, "error from api ");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableData = (data: any) => {
    let tempData: any = [];

    if (data?.length > 0) {
      data?.map((v: any, ind: any) => {
        tempData.push([
          v?.bookingId,
          v?.userId?.name,
          v?.userId?.email,
          `${dayjs(v?.createdAt).format("DD/MM/YYYY hh:mm A")}`,
          v?.status || "Booked",
          v?.requestType,
          v?.numberOfTravelers || v?.totalUser,
        ]);
      });

      setData(tempData);
    } else {
      setData([]);
    }
  };

  const handleTableHotel = (data: any) => {
    let tempData: any = [];
    if (data?.length > 0) {
      data?.map((v: any, ind: any) => {
        tempData.push([
          v?.bookingId,
          v?.userId?.name,
          v?.userId?.email,
          `${dayjs(v?.createdAt).format("DD/MM/YYYY hh:mm A")}`,
          v?.status,
          v?.requestType,
          v?.noOfGuest,
        ]);
      });

      sethoteldata(tempData);
    } else {
      hoteldata([]);
    }
  };

  return (
    <div className={style.mainContainer}>
      <div style={{ display: "flex", gap: "18px" }}>
        <div className={style.customcontainer}>
          <div className={style.contentTab}>
            <div className={style.imgbg}>
              <img src={plan} className={style.plan} />
            </div>
            <div
              style={{ gap: "8px", display: "flex", flexDirection: "column" }}
            >
              <div className={style.text}>{agencyCount}</div>
              <div className={style.subtext}>Travel Agency</div>
            </div>
          </div>
          <div
            className={style.footer}
            onClick={() => navigate("/greenFlag/ManageTravelAgency")}
          >
            Detail <IoIosArrowRoundForward size={24} color="#734A00" />
          </div>
        </div>
        <div className={style.customcontainer}>
          <div className={style.contentTab}>
            <div className={style.imgbg}>
              <BsBuildings size={20} color="#003C9C" />
            </div>
            <div
              style={{ gap: "8px", display: "flex", flexDirection: "column" }}
            >
              <div className={style.text}>{hotelCount}</div>
              <div className={style.subtext}>Hotels</div>
            </div>
          </div>
          <div
            className={style.footer}
            onClick={() => navigate("/greenFlag/ManageHotels")}
          >
            Detail <IoIosArrowRoundForward size={24} color="#734A00" />
          </div>
        </div>
      </div>
      <TableValue
        headTitle="Hotels"
        loading={loading}
        setData={sethoteldata}
        fetchHotel={fetchHotel}
        data={hoteldata}
        listData={hotelName}
        setSelected={setSelectedHotel}
        selected={selectedHotel}
        totalItems={totalItems}
        length={length}
      />
      <TableValue
        headTitle="Travel Agencies"
        loading={travelLoading}
        setData={setData}
        fetchHotel={fetchAgencyBooking}
        data={data}
        listData={agencyName}
        type={"travel"}
        setSelected={setSelectedAgency}
        totalItems={totalAgency}
        length={agencyLength}
        selected={selectedAgency}
      />
    </div>
  );
}

const TableValue = ({
  loading,
  setData,
  fetchHotel,
  data,
  headTitle,
  listData,
  setSelected,
  selected,
  type,
  length,
  totalItems,
}: {
  loading?: any;
  setData?: any;
  fetchHotel?: any;
  data?: any;
  headTitle?: any;
  listData?: any;
  setSelected?: any;
  selected?: any;
  type?: any;
  totalItems?: any;
  length?: any;
}) => {
  const [open, setOpen] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const handleRefresh = () => {
    setData([]);
    fetchHotel(1);
    setPageno(1);
    setCurrentPage(1);
    setSelected(null);
    setOpen(false);
  };
  const titles = [
    "Booking ID",
    "Guest Name",
    "Contact Email",
    "Booking Date & Time",
    "Status",
    "Type",
    "No of Guest",
  ];

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchHotel(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchHotel(currentPage - 1);
    }
  };
  // const filteredList = listData?.filter(
  //   (item: any, index: number, self: any[]) =>
  //     self.findIndex((t) =>
  //       type === "travel"
  //         ? t?.agency?.name === item?.agency?.name
  //         : t?.hotel?.name === item?.hotel?.name
  //     ) === index
  // );
  return (
    <div className={style.mainHotelTable}>
      <div className={style.hotelTable}>
        <div className={style.rowBase}>
          <div className={style.dropdowncontainerMain}>
            <label className={style.dropdownlabel}>{headTitle}</label>
            <div className={style.dropdown} onClick={() => setOpen(!open)}>
              <span className={style.dropdownselected}>
                {selected ? selected?.name : "Select"}
              </span>
              <FaChevronDown size={12} color="#131313" />
            </div>
            {open && (
              <div className={style.dropdownmenu}>
                {listData?.map((i: any) => (
                  <div
                    className={style.dropdowncontainer}
                    style={{
                      backgroundColor:
                        selected?._id === i._id ? "#0E54A3" : "#fff",
                      color: selected?._id === i._id ? "#fff" : "#0E54A3",
                    }}
                    onClick={() => {
                      // if (type === "travel") {
                      //   setSelected(i?.agency);
                      //   setOpen(false);
                      // } else {
                      setSelected(i);
                      setOpen(false);
                      // }
                    }}
                  >
                    {/* {type === "travel" ? i?.agency?.name : i?.name} */}
                    {i?.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={style.loading}>
            {loading ? (
              <RingLoader color={"#0D47A1"} size={30} />
            ) : (
              <TbRefresh className={style.refresh} onClick={handleRefresh} />
            )}
          </div>
        </div>
        <NewPagination2
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          startItem={(currentPage - 1) * itemsPerPage + 1}
          endItem={Math.min(currentPage * itemsPerPage, totalItems)}
          totalItems={totalItems}
        />
      </div>
      {data?.length > 0 ? (
        <TableNew
          titles={titles}
          data={data}
          headerWidth="10%"
          itemWidth="10%"
        />
      ) : (
        <div className={style.emptyContent}>
          <PhysiotheristsEmpty />
        </div>
      )}
    </div>
  );
};
