import * as React from "react";
import tablepay from "./VehicleList.module.css";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import styles from "./VehicleList.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import AmblanceEmpty from "shared/components/AmblanceEmpty";

interface Props {
  ambulance: any;
  setShowModal: any;
}
function VehicleList(props: Partial<Props>) {
  const navigate = useNavigate();
  const handleGoToDetail = (id: string) => {
    navigate(`/ambulanceDetails/${id}`);
  };
  const { ambulance, setShowModal } = props;

  const handleOpenModal = () => {
    setShowModal(true);
  };
  return (
    <div className={styles.payment}>
      {ambulance.length > 0 ? (
        <>
          <div className={styles.headerOuter}>
            <p className={styles.headerclass}>Vehicle Type</p>
            <p className={styles.headerclass}>Vehicle Name</p>
            <p className={styles.headerclass}>Model</p>
            <p className={styles.headerclass}>Year</p>
            <p className={styles.headerclass}>Color</p>
            {/* <p className={styles.headerclass}>Facilities</p> */}
            <p className={styles.headerclass}>Registration No</p>
            <p className={styles.headerclass}>Registration Date</p>
          </div>
          <div className={styles.tableData}>
            <table
              style={{
                margin: "0px",
                cursor: "pointer",
              }}
            >
              <tbody className={styles.wapper}>
                {ambulance.map((val: any, key: any) => {
                  return (
                    <tr
                      className={tablepay.tableRow}
                      key={key}
                      onClick={() => handleGoToDetail(val._id)}
                    >
                      <td className={styles.w20}>{val.vehicleType}</td>
                      <td className={styles.w20}>{val.vehicleName}</td>
                      <td className={styles.w20}>{val.vehicleModel}</td>
                      <td className={styles.w20}>{val.vehicleYear}</td>
                      <td className={styles.w20}>{val.vehicleColor}</td>
                      <td className={styles.w20}>{val.registrationNo}</td>

                      <td className={styles.w20}>
                        {" "}
                        {val?.registrationDate
                          ? new Date(val.registrationDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "__"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>
          <AmblanceEmpty />
        </div>
      )}
    </div>
  );
}

export default VehicleList;
