import React, { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "routes";
import logo from "assets/images/smallLogo.png";
import { interceptorConfig } from "shared/utils";
import {
  setFcmToken,
  setHomeServiceSelectedRoute,
  setIsLoggedIn,
  setMedicalSelectedRoute,
  setTravelSelectedRoute,
  set_User,
  setmainSelectedRoute,
  store,
} from "shared/redux";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal, ToastNotification } from "shared/components";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NoInternetBar from "shared/components/A_New_Components/NoInternetBar";
import "shared/utils/i18n";
import i18n from "shared/utils/i18n"; // Import the i18n configuration
import { I18nextProvider } from "react-i18next";
const clientId =
  "975483364699-puqq3mhr3s9qopv6dov64837lkba0tbh.apps.googleusercontent.com";
function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [logOutFlag, setLogOutFlag] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const dispatch = useDispatch();
  const APP_VERSION = "1.0.3";

  useEffect(() => {
    const checkVersionAndLogout = async () => {
      const storedVersion = await localStorage.getItem("APP_VERSION");
      if (storedVersion !== APP_VERSION) {
        setShowUpdateModal(true);
      }
    };
    checkVersionAndLogout();
  }, []);

  const handleUpdate = () => {
    dispatch(set_User(null));
    dispatch(setIsLoggedIn(false));
    localStorage.clear();
    localStorage.setItem("APP_VERSION", APP_VERSION);
    window.location.reload();
    window.location.replace("/");
  };
  const { fcmToken } = useSelector((state: any) => state.root.common);

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={logo}
            style={{ height: "32px", width: "32px", marginRight: "16px" }}
          />
        </div>
        <div>
          <p style={{ fontWeight: "700" }}>
            <b>{notification?.title}</b>
          </p>
          <p>{notification?.body}</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        //
        resolve(payload);
      });
    });
  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => {});

  useEffect(() => {
    interceptorConfig(setLogOutFlag, setIsOffline);
  }, []);

  useEffect(() => {
    //
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("unload", handleTabClosing);
    };
  });
  useEffect(() => {
    const requestPermission = async () => {
      if (fcmToken) {
        console.log("FCM token already exists:", fcmToken);
        return;
      }
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const token = await getToken(messaging, {
            vapidKey:
              "BIF9ZdobPcwXfIIPSOgr_GCvI60_kchw3CHog3uXNj-RYDlNJFUnEVgW_iip-A6QxyysS8192cJFNT8IpnWoons",
          });
          if (token) {
            store.dispatch(setFcmToken(token));
          }
        } catch (error) {
          console.error("Error retrieving token:", error);
        }
      } else if (permission === "denied") {
        setShowNotificationModal(true);
        // alert("You denied permission for notifications");
      }
    };

    requestPermission();
    return () => {};
  }, []);
  const handleTabClosing = () => {
    store.dispatch(setmainSelectedRoute("/"));
    store.dispatch(setMedicalSelectedRoute("doctor/login"));
    store.dispatch(setHomeServiceSelectedRoute("ambulance/login"));
    store.dispatch(setTravelSelectedRoute("hotel/login"));
  };
  const handleAllowNotifications = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BIF9ZdobPcwXfIIPSOgr_GCvI60_kchw3CHog3uXNj-RYDlNJFUnEVgW_iip-A6QxyysS8192cJFNT8IpnWoons",
        });
        if (token) {
          store.dispatch(setFcmToken(token));
          setShowNotificationModal(false);
        }
      } catch (error) {
        console.error("Error retrieving token:", error);
      }
    } else {
      setShowNotificationModal(false);
      toast.error(
        "Please allow notifications manually from your browser settings."
      );
    }
  };
  const handleCancel = () => {
    setShowUpdateModal(false);
  };
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={clientId}>
        <AppRoutes logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
        <Toaster />
        <ToastNotification />
      </GoogleOAuthProvider>
      <CustomModal showModal={showNotificationModal}>
        <h3 className="text1">Enable Notifications</h3>
        <p className="text2">
          If you'd like to receive notifications related to our services, please
          allow notifications and then log in.
        </p>
        <div className="parent-container">
          <button className="allow-button" onClick={handleAllowNotifications}>
            Allow
          </button>
          <div
            className="closebtn"
            onClick={() => setShowNotificationModal(false)}
          >
            Close
          </div>
        </div>
      </CustomModal>
      <CustomModal showModal={showUpdateModal}>
        <div className="update-modal">
          <p className="textU_pdate">Update Available</p>
          <p>A new version of this website is available. Please update now.</p>
          <div className="rowButton">
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="update-button" onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      </CustomModal>
      {isOffline && <NoInternetBar setIsOffline={setIsOffline} />}
    </I18nextProvider>
  );
}

export default App;
