import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./orderHistory.module.css";
import dayjs from "dayjs";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { ORDER_DETAILS } from "shared/utils/mainHeaderQuery";
import { useSelector } from "react-redux";
import download from "assets/images/admindownload.png";
const MyOrderDetail = React.memo((props) => {
  const { state } = useLocation();
  const { systemType, exchangeRate } = useSelector(
    (state: any) => state.root.common
  );
  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);

  let dataMap = state?.item?.items || state?.item?.medicineIds;
  const gatewayName = state?.item?.gatewayName;
  let removeProcessFee =
    state?.item?.paidByUserAmount - state?.item?.processingFee;
  const price =
    state?.item?.gatewayName === "stripe"
      ? removeProcessFee
      : state?.item?.paidByUserAmount;

  const TotalAmount =
    gatewayName === "stripe" ? `$ ${price?.toFixed(2)}` : `PKR ${price}`;
  const handleDownload = (fileUrl: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "downloaded_file.pdf";
    // downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div>
      <NavBreadCrumbs {...ORDER_DETAILS} />

      <div className={styles.orderDetailContainer}>
        <div className={styles.orderCardDetail}>
          {state?.item?.vendorId?.logo && (
            <div className={styles.logoHeaderDetail}>
              <img
                src={state?.item?.vendorId?.logo}
                alt={state?.item?.vendorId?.name}
                className={styles.logoDetail}
              />
            </div>
          )}

          <div className={styles.orderInfoDetail}>
            <h1>{state?.item?.vendorId?.name}</h1>
            <div className={styles.orderIdTime}>
              <span className={styles.orderIdTimeColor}>
                Order ID:{" "}
                {state?.item?.vendorId?.vendorId || state?.item?.requestId}
              </span>
              <div className={styles.orderDetailsRight}>
                <span>
                  {dayjs(state?.item?.createdAt).format("DD-MM-YYYY, h:mm a")}
                </span>
                <span
                  className={
                    state?.item?.status === "pending"
                      ? styles.statusPending
                      : styles.statusInProgress
                  }
                >
                  {state?.item?.status}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.itemListDetail}>
            <h2 className={styles.itemListDetailHeaderName}>Selected Items</h2>
            {dataMap?.map((item: any, index: any) => {
              const price = item?.itemId?.userAmount || item?.id?.tpPrice;
              //  ||
              // item?.itemId?.actualPrice ||
              // item?.tpPrice ||
              // item?.id?.tpPrice;

              const convertedAmount = exchangeRate * price;
              const multiplyAmount = item?.quantity * convertedAmount;
              const blinkprice = item?.quantity * price;
              const handleAmount =
                gatewayName === "stripe"
                  ? `$ ${multiplyAmount?.toFixed(2)}`
                  : `PKR ${blinkprice}`;
              return (
                <div key={index} className={styles.itemDetail}>
                  <div className={styles.itemHeader}>
                    <div>
                      <h3 className={styles.itemListDetailItemHeaderName}>
                        {`${
                          item.itemId?.testNameId?.name || item?.id?.generic
                        } - ${item.itemId?.testCode || item?.id?.medCode}`}
                      </h3>
                      {item?.id?.brand && (
                        <h3 className={styles.itemListDetailItemHeaderName}>
                          {`Brand - ${item?.id?.brand} `}
                        </h3>
                      )}
                    </div>

                    <span className={styles.price}>{handleAmount}</span>
                  </div>
                  <p className={styles.description}>
                    {item?.itemId?.testDescription}
                  </p>
                </div>
              );
            })}
            {state?.item?.results ? (
              <div
                style={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  width: "40%",
                  borderColor: "blue",
                  borderRadius: "8px",
                  padding: "8px",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => handleDownload(state?.item?.results)}
              >
                <p>Download Result</p>
                <img src={download} style={{ width: "24px", height: "24px" }} />
              </div>
            ) : (
              <p>No Results Uploaded</p>
            )}

            <div className={styles.totalAmountDetail}>
              <h2 className={styles.totalLabel}>Total Amount</h2>
              <p className={styles.totalPrice}>{TotalAmount}</p>
            </div>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
});

export default MyOrderDetail;
