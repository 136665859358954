import { DoctorLogin } from "pages";
import TravelagencyForget from "pages/TravelTourism/Travel agency/Forgetpassword/TravelagencyForget";
import TravelagencyUpdatepass from "pages/TravelTourism/Travel agency/Forgetpassword/TravelagencyUpdatepass";
import AgencyLogin from "pages/TravelTourism/Travel agency/Login/Index";
import AgencySignup from "pages/TravelTourism/Travel agency/Signup";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

const TravelAgencyLoginAuth = (props: any) => {
  return (
    <Routes>
      {/* <Route path="/login" element={<AgencyLogin />} /> */}
      <Route path="/login" element={<DoctorLogin />} />
    </Routes>
  );
};

export default TravelAgencyLoginAuth;
